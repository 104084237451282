import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { WaveContingentOverviewPage } from "./WaveContingentOverviewPage";
import { WaveList } from "./WaveList";

export const WavePage: FC = () => {
	return (
		<Routes>
			<Route index element={<Navigate to="list" replace />} />
			<Route path="list" element={<WaveList />} />
			<Route path="overview" element={<WaveContingentOverviewPage />} />
		</Routes>
	);
};
