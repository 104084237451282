import { useQuery } from "@tanstack/react-query";
import { InvoiceProviderType } from "~shared/types/invoiceProvider";
import { invoiceProviderService } from "./invoiceProviderService";

const initial: InvoiceProviderType[] = [];

export const useInvoiceProviderTypeOptionsQuery = () => {
	const { data, ...rest } = useQuery({
		queryKey: invoiceProviderService.keys.typeOptions,
		queryFn: () => invoiceProviderService.typeOptions().then((r) => r.data.data),
	});

	return {
		typeOptions: data || initial,
		...rest,
	};
};
