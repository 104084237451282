import { FieldArrayRenderProps, useField } from "formik";
import React, { FC } from "react";
import { GuestFilterEntry } from "./GuestFilterEntry";
import { IGuestFilterFormValueEntry } from "./guestFilterTypes";

interface Props {
	arrayHelpers: FieldArrayRenderProps;
}

export const GuestFilterEntries: FC<Props> = ({ arrayHelpers }) => {
	const [{ value }] = useField<IGuestFilterFormValueEntry[]>("filters");

	return (
		<>
			{value.map((entry, index) => (
				<GuestFilterEntry
					namePrefix={`filters.${index}`}
					key={`${entry.guestAttributeId}.${index}`}
					attributeId={entry.guestAttributeId}
					withDivider={index + 1 < value.length}
					onDelete={() => arrayHelpers.remove(index)}
				/>
			))}
		</>
	);
};
