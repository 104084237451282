import { Button, Modal, ModalBody, ModalFooter, PagedSingleSelectControl } from "@dgs/core";
import { useField } from "formik";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { guestFilterKeys } from "~shared/api/guestFilters";
import { guestFilterFetcher } from "~shared/selects/GuestFilterSelect";
import { IGuestFilter } from "~shared/types/guestFilter";
import { IGuestFilterFormValueEntry } from "./guestFilterTypes";

export const GuestFilterDrawerPredefinedFilterSelect: FC = () => {
	const [{ value: filters }, , { setValue: setFilter }] = useField<IGuestFilterFormValueEntry[]>("filters");
	const [guestFilter, setGuestFilter] = useState<IGuestFilter | null>(null);
	const { t } = useTranslation();

	const onClose = () => setGuestFilter(null);

	return (
		<>
			<PagedSingleSelectControl
				name={"predefinedFilter"}
				value={null}
				onChange={(e) => {
					if (filters.length) {
						setGuestFilter(e.target.value);
					} else if (e.target.value) {
						setFilter(e.target.value.filters);
					}
				}}
				queryKey={guestFilterKeys.options}
				fetcher={guestFilterFetcher}
				label={t("Predefined filter")}
				getLabel={(option) => `${option.name}`}
			/>
			{guestFilter && (
				<Modal heading={t("Overwrite filter")} onClose={onClose}>
					<ModalBody>{t("Selecting a predefined filter will overwrite this current filter.")}</ModalBody>
					<ModalFooter>
						<Button
							type="button"
							onClick={(e) => {
								onClose();
								e.stopPropagation();
							}}
						>
							{t("Close")}
						</Button>
						<Button
							type="button"
							color="danger"
							onClick={(e) => {
								setFilter(guestFilter.filters);
								onClose();
								e.stopPropagation();
							}}
						>
							{t("Overwrite")}
						</Button>
					</ModalFooter>
				</Modal>
			)}
		</>
	);
};
