import { Action, ActionTypes } from "~shared/types/actions";

export const getActionTranslation = (action: Action) => {
	switch (action.type) {
		case ActionTypes.ATTACH_GROUP:
			return {
				key: "attach group {{name}}",
				values: { name: action.value.name },
			};
		case ActionTypes.ATTACH_TAG:
			return {
				key: "attach tag {{name}}",
				values: { name: action.value.name },
			};
		case ActionTypes.OVERWRITE_GROUPS:
			return {
				key: "overwrite groups {{name}}",
				values: { name: action.value.map((x) => x.name).join(", ") },
			};
		case ActionTypes.OVERWRITE_TAGS:
			return {
				key: "overwrite tags {{name}}",
				values: { name: action.value.map((x) => x.name).join(", ") },
			};
		case ActionTypes.SHOW_DATA_FIELD:
			return {
				key: "show data field {{name}} of section {{sectionName}}",
				values: { name: action.value.dataField.name, sectionName: action.value.section.name },
			};
		case ActionTypes.SHOW_STEP:
			return {
				key: "show step {{name}}",
				values: { name: action.value.name },
			};
		case ActionTypes.SHOW_SECTION:
			return {
				key: "show section {{name}}",
				values: { name: action.value.name },
			};
		case ActionTypes.SEND_EMAIL:
			return {
				key: "send email {{name}}",
				values: { name: action.value.name },
			};
	}
};
