import { FCC, Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { hotelInquiryService } from "~shared/api/hotelInquiries";
import { importKeys, importService } from "~shared/api/imports";
import {
	AnyGuestImportFormValues,
	AnyGuestImportRequest,
	ImportableAttributeOption,
	ImportableGuestAttributeOption,
	TImportHeader,
	TImportMapping,
} from "~shared/types/guestImport";
import { TImportProviderType } from "~shared/types/importProvider";
import { GuestFileFormValues } from "./ImportGuestFileForm";

interface ImportGuestContextState {
	handleUpload: (formValues: GuestFileFormValues) => void;
	handleImport: (formValues: AnyGuestImportFormValues) => void;
	headerRow: TImportHeader[];
	importType: TImportProviderType | null;
	setHeaderRow: (headerRow: TImportHeader[]) => void;
	setGuestImportId: (id: Id) => void;
	setImportType: (type: TImportProviderType) => void;
	importableGuestAttributeOptions: ImportableGuestAttributeOption[];
}

interface Props {
	importableGuestAttributeOptions: ImportableGuestAttributeOption[];
}

const ImportGuestsContext = React.createContext<ImportGuestContextState | undefined>(undefined);

const mapImportableAttributeOptionToImportMapping = (
	header: TImportHeader,
	option: ImportableAttributeOption,
): TImportMapping => {
	if (option === "guest-id") {
		return {
			dataFieldId: null,
			dgsEntity: "id",
			externalField: header.externalField,
			header: header.label,
		};
	}
	switch (option.attribute.type) {
		case "relation":
		case "column":
			return {
				dataFieldId: null,
				dgsEntity: option.attribute.entityType,
				externalField: header.externalField,
				header: header.label,
			};
		case "dataField":
			return {
				dataFieldId: option.attribute.entityType,
				dgsEntity: "dataField",
				externalField: header.externalField,
				header: header.label,
			};
	}
};

export const ImportGuestProvider: FCC<Props> = ({ importableGuestAttributeOptions, children }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const [importType, setImportType] = useState<TImportProviderType | null>(null);
	const [headerRow, setHeaderRow] = useState<TImportHeader[]>([]);
	const [guestImportId, setGuestImportId] = useState<Id | null>(null);

	const handleUpload = useCallback(
		async (values: GuestFileFormValues) => {
			const formData = new FormData();

			if (values.importProvider?.type === "excel") {
				formData.append("importProviderId", values.importProvider.id.toString());
				values.file && formData.append("file", values.file);
				values.headerRow && formData.append("headerRow", values.headerRow.toString());
				values.headerStartColumn && formData.append("headerStartColumn", values.headerStartColumn);
			} else if (values.importProvider?.type === "zoho") {
				formData.append("importProviderId", values.importProvider.id.toString());
				values.module && formData.append("module", values.module);
			} else if (values.importProvider?.type === "jarvis") {
				formData.append("importProviderId", values.importProvider.id.toString());
			}

			const response = await importService.guests.headerRow(formData);

			if (apiIsOK(response)) {
				setImportType(response.data.data.type);
				setHeaderRow(response.data.data.headers);
				setGuestImportId(response.data.data.id);
				await queryClient.invalidateQueries({ queryKey: importKeys.guests.index });
				navigate("data");
			}
		},
		[navigate, queryClient],
	);

	const handleImport = useCallback(
		async ({ mapping, tags, groups, ...rest }: AnyGuestImportFormValues) => {
			if (guestImportId === null) {
				return;
			}

			const request: AnyGuestImportRequest = {
				mapping: Object.keys(mapping).flatMap((externalField) => {
					const header = headerRow.find((row) => row.externalField === externalField);
					const selectedOption = mapping[externalField];
					if (!header || !selectedOption) {
						return [];
					}
					return [mapImportableAttributeOptionToImportMapping(header, selectedOption)];
				}),
				tagIds: tags?.map((tag) => tag.id),
				groupIds: groups?.map((group) => group.id),
				...rest,
			};

			const response = await importService.guests.post(guestImportId, request);

			if (apiIsOK(response)) {
				showToast({
					body: t("Participant import has been started."),
					title: t("Participant import"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: hotelInquiryService.keys.index });
				await queryClient.invalidateQueries({ queryKey: importKeys.guests.index });
				navigate("overview");
			}
		},
		[guestImportId, headerRow, showToast, t, queryClient, navigate],
	);

	return (
		<ImportGuestsContext.Provider
			value={{
				importType,
				handleUpload,
				handleImport,
				headerRow,
				setHeaderRow,
				setGuestImportId,
				setImportType,
				importableGuestAttributeOptions,
			}}
		>
			{children}
		</ImportGuestsContext.Provider>
	);
};

export const useImportGuests = () => {
	const context = useContext(ImportGuestsContext);

	if (context === undefined) {
		throw new Error(`Context undefined. Are you missing the ImportGuestsProvider?`);
	}

	return context;
};
