import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { DataFieldList } from "~admin/dataFields/DataFieldList";
import { DataFieldContingentOverview } from "~admin/dataFields/dataFieldOptions/DataFieldContingentOverview";
import { DataFieldSettingsPage } from "./DataFieldSettingsPage";

export const DataFieldsPage: FC = () => {
	return (
		<Routes>
			<Route index element={<Navigate to="overview" replace={true} />} />
			<Route path="overview" element={<DataFieldList />} />
			<Route path="contingent-overview" element={<DataFieldContingentOverview />} />
			<Route path="settings" element={<DataFieldSettingsPage />} />
		</Routes>
	);
};
