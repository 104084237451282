import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { LocalGuestViewProvider } from "~shared/providers/LocalGuestViewProvider";
import { LocationsList } from "./locations/LocationsList";
import { LogPage } from "./logs/LogPage";

export const CheckInAppPage: FC = () => {
	return (
		<Routes>
			<Route index element={<Navigate to="locations" replace={true} />} />
			<Route path="locations" element={<LocationsList />} />
			<Route
				path="locations/:locationId/*"
				element={
					<LocalGuestViewProvider guestViewKey="locationLogsList">
						<LogPage />
					</LocalGuestViewProvider>
				}
			/>
		</Routes>
	);
};
