import {
	CheckboxControl,
	DeleteAction,
	EntityDrawerProps,
	I18NCrudDrawer,
	I18nOnlyOnMainForm,
	I18nSlateTextInputField,
	I18nTextField,
	TextField,
	isEmptySlateValue,
	mapErrors,
} from "@dgs/core";
import { useField, useFormikContext } from "formik";
import React, { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ConsentAudienceSelectField } from "~admin/consents/ConsentAudienceSelectField";
import { ConsentTypeSelectField } from "~admin/consents/ConsentTypeSelectField";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { ConsentFormValues, ConsentType } from "~shared/types/consent";

const RequiredCheckbox: FC = () => {
	const [typeSelect] = useField<ConsentType>("type");
	const [checkbox, , helper] = useField("required");
	const { getFieldHelpers } = useFormikContext();
	const { t } = useTranslation();

	useEffect(() => {
		if (typeSelect.value === "notice") {
			getFieldHelpers("required").setValue(false);
		}
	}, [getFieldHelpers, typeSelect.value]);

	return (
		<CheckboxControl
			label={t("Required field")}
			name="required"
			disabled={typeSelect.value === "notice"}
			onChange={(e) => helper.setValue(e.target.value)}
			value={checkbox.value}
		/>
	);
};

type Props = EntityDrawerProps<ConsentFormValues> & { handleDelete?: () => void };

export const ConsentDrawer: FC<Props> = ({ ...props }) => {
	const { t } = useTranslation();
	const { locales, fallbackLocale } = useContentLocale();

	const validate = useCallback(
		(values: ConsentFormValues) => {
			let errors: Record<string, any> = {};
			if (!values.type) {
				errors.type = t("Required field");
			}
			if (!values.audience) {
				errors.audience = t("Required field");
			}
			if (!values.name) {
				errors.name = t("Required field");
			}

			const translations = values.translations[fallbackLocale] || {};

			if (isEmptySlateValue(translations.consent)) {
				errors = mapErrors(errors, fallbackLocale, "consent", t);
			}
			return errors;
		},
		[fallbackLocale, t],
	);

	return (
		<I18NCrudDrawer
			{...props}
			fallbackLocale={fallbackLocale}
			locales={locales}
			validate={validate}
			body={
				<>
					<I18nOnlyOnMainForm>
						<TextField name="name" label="Name" required />
						<ConsentTypeSelectField name="type" label={t("Type")} />
						<ConsentAudienceSelectField name="audience" label={t("Audience")} />
					</I18nOnlyOnMainForm>
					<I18nTextField name="headline" label={t("Headline")} />
					<I18nSlateTextInputField name="consent" label={t("Consent")} required />
					<I18nOnlyOnMainForm>
						<RequiredCheckbox />
					</I18nOnlyOnMainForm>
				</>
			}
			footer={
				props.handleDelete && (
					<DeleteAction
						heading={t("Delete")}
						title={t("Delete")}
						buttonLabel={t("Delete")}
						labels={{ close: t("Close"), confirm: t("Confirm") }}
						action={props.handleDelete}
					>
						{t("Are you sure you want to delete this consent?")}
					</DeleteAction>
				)
			}
		/>
	);
};
