import { CrudDrawer } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ContentLanguageSingleSelect } from "~shared/selects/ContentLanguageSingleSelect";
import { EmailSingleSelect } from "~shared/selects/EmailSingleSelect";
import { SendEmailFormValues } from "~shared/types/email";

interface Props {
	title: string;
	open: boolean;
	handleClose: () => void;
	handleSubmit: (values: SendEmailFormValues) => Promise<boolean>;
}

export const SendEmailCrudDrawer: FC<Props> = ({ title, open, handleClose, handleSubmit }) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: SendEmailFormValues) => {
			const errors: Record<string, string> = {};

			if (!values.locale) {
				errors.locale = t("Required field");
			}
			if (!values.customEmail) {
				errors.customEmail = t("Required field");
			}

			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			open={open}
			onSubmit={handleSubmit}
			body={
				<>
					<EmailSingleSelect name="customEmail" label={t("Email")} required={true} />
					<ContentLanguageSingleSelect name="locale" label={t("Language")} required={true} />
				</>
			}
			initialValues={{ locale: "registrationLocale", customEmail: null }}
			onClose={handleClose}
			heading={title}
			validate={validate}
			translations={{
				save: t("Send"),
			}}
		/>
	);
};
