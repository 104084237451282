import { PagedMultiSelectField } from "@dgs/core";
import React, { FC } from "react";
import { consentKeys, consentService } from "~shared/api/consents";
import { ConsentListItem } from "~shared/types/consent";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
}

const consentFetcher = (search: string, page: number) => consentService.options(search, page);

export const ConsentMultiSelect: FC<Props> = ({ name, label, disabled }) => {
	return (
		<PagedMultiSelectField<ConsentListItem>
			name={name}
			label={label}
			disabled={disabled}
			fetcher={(search, page) => consentFetcher(search, page).then((r) => r.data)}
			queryKey={consentKeys.options}
			getLabel={(option) => option.name}
			clearable={true}
		/>
	);
};
