export const mapFormValueToRequest = (value: any) => {
	if (value === null) {
		return null;
	}

	if (typeof value === "string" || typeof value === "number" || typeof value === "boolean") {
		return value;
	}

	if (value instanceof Date) {
		return value.toISOString();
	}

	if (Array.isArray(value)) {
		return value.map((v) => (typeof v === "number" ? v : v.id));
	}

	return value.id;
};
