import { BaseCrudService, Id, keyToUrl } from "@dgs/core";
import { IRoomFormValues, IRoomIndex } from "~shared/types/room";

export class RoomService extends BaseCrudService<IRoomIndex, IRoomIndex, IRoomFormValues> {
	public keys;
	protected urls;

	constructor(hotelId: string, roomTypeId: string, roomContingentId: string) {
		super();
		const baseKey = ["hotels", hotelId, "room-types", roomTypeId, "contingent", roomContingentId, "rooms"];
		this.keys = {
			index: baseKey,
			list: [...baseKey, "list"],
			details: (id: Id) => [...baseKey, `${id}`],
		};
		this.urls = {
			index: keyToUrl(this.keys.index),
			details: (id: Id) => keyToUrl(this.keys.details(id)),
		};
	}
}
