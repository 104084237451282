import { Drawer, DrawerBody, DrawerHeader, DrawerHeading } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PagedEmailRecipientList } from "~admin/emails/sendOut/PagedEmailRecipientList";

interface Props {
	open: boolean;
	handleClose: () => void;
}

const StyledDrawer = styled(Drawer)`
	display: flex;
	flex-direction: column;
`;
const StyledDrawerBody = styled(DrawerBody)`
	flex: 1;
`;

export const EmailRecipientDrawer: FC<Props> = ({ open, handleClose }) => {
	const { t } = useTranslation();

	return (
		<StyledDrawer onClose={handleClose} open={open}>
			<DrawerHeader onClose={handleClose}>
				<DrawerHeading>{t("Recipients")}</DrawerHeading>
			</DrawerHeader>
			<StyledDrawerBody>
				<PagedEmailRecipientList />
			</StyledDrawerBody>
		</StyledDrawer>
	);
};
