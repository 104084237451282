import { Button, Icon, getAppBar, useAuth } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { config } from "~shared/config";
import { useNominationManagerConfig } from "./useNominationManagerConfig";

const AppBar = getAppBar();

export const NominationManagerAppBar: FC = () => {
	const { t } = useTranslation();
	const { htmlTitle, logo, brand } = useNominationManagerConfig();
	const { logout, isLoggedIn } = useAuth();
	const navigate = useNavigate();
	const logoUrl = config.frontendThemeName === "dgs" ? brand?.logoFile.path ?? logo : logo;

	const handleLogout = async () => {
		await logout();
		navigate("/nomination-manager");
	};

	return (
		<AppBar appIdentifier={htmlTitle} logo={{ logoUrl: logoUrl, logoAltText: htmlTitle }}>
			{isLoggedIn && (
				<Button onClick={handleLogout} prefix={<Icon icon="leave" />}>
					{t("Logout")}
				</Button>
			)}
		</AppBar>
	);
};
