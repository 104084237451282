import { SidebarLayout } from "@dgs/core";
import { ErrorBoundary } from "@sentry/react";
import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { Header } from "~admin/header";
import { ErrorPage } from "~admin/shared/ui/ErrorPage";
import { Sidebar } from "~admin/sidebar";
import { ContentProviders } from "./ContentProviders";

const ContentContainer = styled.div`
	position: relative;
	flex: 1;
	overflow: auto;
	height: 100%;
	padding: 1.5rem;
`;

export const AdminContent: FC = () => {
	return (
		<ContentProviders>
			<SidebarLayout
				header={<Header />}
				sideBar={<Sidebar />}
				content={
					<ContentContainer>
						<ErrorBoundary fallback={<ErrorPage />}>
							<Outlet />
						</ErrorBoundary>
					</ContentContainer>
				}
			/>
		</ContentProviders>
	);
};
