import { Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { registrationFormStructureService } from "~shared/api/registrationForms/registrastionFormStructureService";
import { registrationFormSectionService } from "~shared/api/registrationForms/registrationFormSectionService";
import { getRuleValueFromRequest } from "~shared/registrationForms/getRuleValue";
import { IDataFieldOptionUpdateResource } from "~shared/types/dataFieldOption";
import { IRegistrationFormIndex } from "~shared/types/registrationForm";
import { registrationFormKeys } from "./registrationFormsKeys";
import { registrationFormService } from "./registrationFormsService";

const registrationForms: IRegistrationFormIndex[] = [];
export const useRegistrationFormsQuery = () => {
	const { data, isLoading } = useQuery({
		queryKey: registrationFormKeys.list,
		queryFn: () => registrationFormService.index().then((r) => r.data.data),
	});

	return {
		data: data || registrationForms,
		isLoading,
	};
};

const dataFields: IDataFieldOptionUpdateResource[] = [];
export const useRegistrationFormDataFieldsQuery = (formId: Id) => {
	const { data, isLoading } = useQuery({
		queryKey: registrationFormKeys.dataFieldsOptions(formId),
		queryFn: () => registrationFormService.indexDataFields(formId).then((r) => r.data.data),
	});
	return {
		data: data || dataFields,
		isLoading,
	};
};

const registrationFormDetailsFetcher = (formId: Id) => () =>
	registrationFormService.details(formId).then((r) => ({
		...r.data.data,
		steps: r.data.data.steps.map((x) => ({
			...x,
			children: x.children.map((y) => {
				if (y.entityType === "section") {
					return {
						...y,
						validationRules: y.section.validationRules.map((z) => ({
							...z,
							value: getRuleValueFromRequest(z),
						})),
					};
				}
				return y;
			}),
		})),
	}));

export const useRegistrationFormStepDetailsQuery = (formId: Id, stepId: Id, enabled = true) => {
	const { data, isLoading } = useQuery({
		queryKey: registrationFormKeys.stepDetails(formId, stepId),
		queryFn: () => registrationFormService.showStep(formId, stepId).then((r) => r.data.data),
		enabled,
	});

	return {
		step: data,
		isLoading,
	};
};

export const useRegistrationFormDetailsQuery = (formId: Id) => {
	const { data, isLoading } = useQuery({
		queryKey: registrationFormKeys.details(formId),
		queryFn: registrationFormDetailsFetcher(formId),
	});

	return {
		data,
		isLoading,
	};
};

export const useRegistrationFormSectionDetailsQuery = (formId: Id, stepId: Id, sectionId: Id, enabled = true) => {
	const { data, isLoading } = useQuery({
		queryKey: registrationFormSectionService.keys.details(formId, stepId, sectionId),
		queryFn: () => registrationFormSectionService.show(formId, stepId, sectionId).then((r) => r.data.data),
		enabled,
	});

	return {
		section: data,
		isLoading,
	};
};

export const useRegistrationFormStructureQuery = (registrationFormId: string) => {
	const { data, ...rest } = useQuery({
		queryKey: registrationFormStructureService.keys.structure(registrationFormId),
		queryFn: () => registrationFormStructureService.structure(registrationFormId).then((r) => r.data.data),
	});

	return {
		registrationForm: data,
		...rest,
	};
};

export const useRegistrationFormSectionDataField = (
	formId: Id,
	stepId: Id,
	sectionId: Id,
	dataFieldId: Id,
	enabled = true,
) => {
	const { data, ...rest } = useQuery({
		queryKey: registrationFormSectionService.keys.showValidation(formId, stepId, sectionId, dataFieldId),
		queryFn: () =>
			registrationFormSectionService.showValidation(formId, stepId, sectionId, dataFieldId).then((r) => r.data.data),
		enabled,
	});

	return {
		dataField: data,
		...rest,
	};
};
