import { Card, CardBody, CardHeader, CardHeading, LinkTab, LinkTabs, Loading } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { roomTypeService } from "~admin/shared/api/roomTypes";
import { useShowQuery } from "~shared/api/baseQueries";
import { IRoomTypeResource } from "~shared/types/roomType";
import { FormikHelpersWithRedirect } from "~shared/ui/FormikWithRedirect";
import { RoomTypeBreadcrumb } from "../HotelBreadcrumbs";
import { RoomTypeFormFooter } from "../stepper/HotelStepperRoomTypeForm";
import { RoomContingentCreatePage } from "./contingent/RoomContingentCreatePage";
import { RoomContingentEditPage } from "./contingent/RoomContingentEditPage";
import { RoomContingentList } from "./contingent/RoomContingentList";
import { RoomTypeForm } from "./RoomTypeForm";
import { useRoomTypeForm } from "./useRoomTypeForm";

const CardWrapper = styled.div`
	${({ theme }) => theme.card.container};

	${Card} {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
`;

export const RoomTypeEditPage: FC = () => {
	const { t } = useTranslation();
	const { roomTypeId = "", hotelId = "" } = useParams<{ roomTypeId: string; hotelId: string }>();
	const { data: roomType, isLoading } = useShowQuery(roomTypeId, roomTypeService);
	const { updateRoomType, deleteRoomType } = useRoomTypeForm();
	const navigate = useNavigate();
	const handleDelete = useCallback(async () => {
		const success = await deleteRoomType(hotelId, roomTypeId);
		if (success) {
			navigate("../../room-types");
		}
	}, [deleteRoomType, hotelId, navigate, roomTypeId]);
	const onSubmit = useCallback(
		(roomType: IRoomTypeResource, helpers: FormikHelpersWithRedirect<IRoomTypeResource>) =>
			updateRoomType(roomTypeId, roomType, helpers),
		[roomTypeId, updateRoomType],
	);

	if (isLoading || !roomType) {
		return (
			<Card>
				<CardHeader>
					<CardHeading>{t("Edit room type")}</CardHeading>
				</CardHeader>
				<CardBody>
					<Loading />
				</CardBody>
			</Card>
		);
	}

	return (
		<>
			<RoomTypeBreadcrumb hotelId={hotelId} roomType={roomType} />
			<Routes>
				<Route index element={<Navigate to="details" replace />} />
				<Route
					element={
						<CardWrapper>
							<LinkTabs>
								<LinkTab to="details">{t("Details")}</LinkTab>
								<LinkTab to="contingent">{t("Contingent")}</LinkTab>
							</LinkTabs>
							<Outlet />
						</CardWrapper>
					}
				>
					<Route
						path="details"
						element={
							<RoomTypeForm
								title={t("Edit room type")}
								roomType={roomType}
								onSubmit={onSubmit}
								footer={<RoomTypeFormFooter handleDelete={handleDelete} />}
							/>
						}
					/>
					<Route path="contingent" element={<RoomContingentList />} />
				</Route>
				<Route path="contingent/new" element={<RoomContingentCreatePage roomType={roomType} />} />
				<Route path="contingent/:roomContingentId/*" element={<RoomContingentEditPage roomType={roomType} />} />
			</Routes>
		</>
	);
};
