import { CheckboxControl, CrudDrawer, FormattedDate, Id, Text, useFormatDate } from "@dgs/core";
import { parseISO } from "date-fns";
import { FieldArray, FormikProps } from "formik";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useHotelInquiryQuery } from "~shared/api/hotelInquiries/hotelInquiryQueries";
import { GuestMultiSelectField } from "~shared/selects/GuestMultiSelectField";
import { RoomContingentSingleSelectField } from "~shared/selects/RoomContingentSingleSelectControl";
import { GuestStatus } from "~shared/types/guest";
import { ValueViewer } from "~shared/ui/ValueViewer";
import { getHotelInquiryGuestName } from "./hotelInquiryUtils";
import { ToAdd, useResolveHotelInquiryConflicts } from "./useResolveHotelInquiryConflicts";

interface Props {
	hotelInquiryId: Id;
	onClose: () => void;
}

const Range = styled.div`
	display: flex;
	grid-template-columns: max-content max-content;
	gap: ${({ theme }) => theme.spacing(8)};
`;

const Values = styled.div`
	display: grid;
	gap: ${({ theme }) => theme.spacing(4)};
	padding-bottom: ${({ theme }) => theme.spacing(4)};
	border-bottom: 1px solid ${({ theme }) => theme.colors.palette.grey[30]};
	margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const List = styled.ul`
	margin-left: 1rem;
	font-size: 0.8rem;
`;
const ErrorText = styled.div`
	color: ${({ theme }) => theme.colors.palette.danger.main.base};
`;

interface NonFieldErrors {
	status?: never;
}

export const HotelInquiryConflictDrawer: FC<Props> = ({ hotelInquiryId, onClose }) => {
	type FormValues = NonFieldErrors & { roomAllocation: ToAdd };
	const { data: hotelInquiry } = useHotelInquiryQuery(hotelInquiryId);
	const checkIn = useMemo(
		() => (hotelInquiry?.checkIn ? parseISO(hotelInquiry?.checkIn) : null),
		[hotelInquiry?.checkIn],
	);
	const checkOut = useMemo(
		() => (hotelInquiry?.checkOut ? parseISO(hotelInquiry?.checkOut) : null),
		[hotelInquiry?.checkOut],
	);
	const { getConflicts, resolveConflicts } = useResolveHotelInquiryConflicts();
	const allocation = getConflicts(checkIn, checkOut, hotelInquiry);
	const { t } = useTranslation();
	const formatDate = useFormatDate();
	const [wantsToAddCompanions, setWantsToAddCompanions] = useState(false);

	const validate = useCallback(() => {
		const errors: Record<string, string> = {};
		if (
			hotelInquiry &&
			![
				GuestStatus.REGISTERED,
				GuestStatus.REGISTRATION_CHANGED,
				GuestStatus.CONFIRMATION_SENT,
				GuestStatus.CHECKED_IN,
				GuestStatus.APP_RECEIVED,
			].includes(hotelInquiry.guest.status)
		) {
			errors.status = t(
				"The guest status needs to be one of: Registered, RegistrationChanged, ConfirmationSent, CheckedIn, AppReceived",
			);
		}
		return errors;
	}, [hotelInquiry, t]);

	return (
		hotelInquiry && (
			<CrudDrawer
				open={true}
				enableReinitialize={true}
				onSubmit={(values) => resolveConflicts(hotelInquiryId, values, onClose)}
				initialValues={{ roomAllocation: allocation.toAdd }}
				onClose={onClose}
				heading={t("Resolve conflicts")}
				translations={{ save: t("Resolve conflicts") }}
				validate={validate}
				body={(formik: FormikProps<FormValues>) => (
					<>
						<Values>
							<ValueViewer label={t("Status")}>
								{t(hotelInquiry.guest.status)}
								{formik.errors.status && <ErrorText>{`${formik.errors.status}!`}</ErrorText>}
							</ValueViewer>
							<ValueViewer label={t("Guest")}>{getHotelInquiryGuestName(hotelInquiry)}</ValueViewer>
							<Range>
								<ValueViewer label={t("Check-in")}>
									<FormattedDate date={hotelInquiry.checkIn} placeholder="N/A" />
								</ValueViewer>
								<ValueViewer label={t("Check-out")}>
									<FormattedDate date={hotelInquiry.checkOut} placeholder="N/A" />
								</ValueViewer>
							</Range>
						</Values>
						{allocation.toRemove.length > 0 && (
							<Values>
								<Text>
									{t(
										"The following contingents will be released from the guest, because they are not requested anymore:",
									)}
								</Text>
								<List>
									{allocation.toRemove.map((c) => (
										<li key={c.date.toISOString()}>{`${formatDate(c.date)}: ${c.roomContingent.hotel.name} - ${
											c.roomContingent.roomType.name
										}`}</li>
									))}
								</List>
							</Values>
						)}
						{formik.values.roomAllocation.some(
							(roomAllocation) => roomAllocation.roomContingent && roomAllocation.roomContingent.roomType.capacity > 1,
						) && (
							<CheckboxControl
								label={t("Add more guests to this room")}
								name="wantsToAddCompanions"
								value={wantsToAddCompanions}
								onChange={(e) => setWantsToAddCompanions(e.target.value)}
							/>
						)}
						<FieldArray
							name="roomAllocation"
							render={() =>
								formik.values.roomAllocation.map((allocationEntry, index) => {
									switch (allocationEntry.type) {
										case "ok":
											return (
												<>
													<RoomContingentSingleSelectField
														key={`${allocationEntry.date.toISOString()}_roomContingent`}
														day={allocationEntry.date}
														name={`roomAllocation.${index}.roomContingent`}
														guestId={hotelInquiry.guest.id}
														label={formatDate(allocationEntry.date)}
														placeholder={t("Choose room type")}
													/>
													{wantsToAddCompanions && (
														<GuestMultiSelectField
															key={`${allocationEntry.date.toISOString()}_roomMates`}
															name={`roomAllocation.${index}.roomMates`}
															label={t("Additional guests")}
															exclude={[hotelInquiry.guest.id]}
														/>
													)}
												</>
											);
										case "missingContingent":
											return (
												<>
													<RoomContingentSingleSelectField
														key={`${allocationEntry.date.toISOString()}_roomContingent`}
														day={allocationEntry.date}
														name={`roomAllocation.${index}.roomContingent`}
														label={formatDate(allocationEntry.date)}
														placeholder={t("Choose room type")}
													/>
													{wantsToAddCompanions && (
														<GuestMultiSelectField
															key={`${allocationEntry.date.toISOString()}_roomMates`}
															name={`roomAllocation.${index}.roomMates`}
															label={t("Additional guests")}
															exclude={[hotelInquiry.guest.id]}
														/>
													)}
												</>
											);
										default:
											return null;
									}
								})
							}
						/>
					</>
				)}
			/>
		)
	);
};
