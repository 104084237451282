import { useQuery } from "@tanstack/react-query";
import { dashboardKeys } from "./dashboardKeys";
import { dashboardService } from "./dashboardService";

export const useDashboardQuery = () => {
	const { data, isLoading } = useQuery({
		queryKey: dashboardKeys.index,
		queryFn: () => dashboardService.index().then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};
