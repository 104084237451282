import { BaseCrudOptionService, Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { ModelUsageResource } from "~shared/types/modelUsageResource";
import {
	ITenantIndexResource,
	ITenantOptionResource,
	ITenantRequest,
	ITenantUpdateResource,
} from "~shared/types/tenant";

class TenantService extends BaseCrudOptionService<
	ITenantIndexResource,
	ITenantUpdateResource,
	ITenantOptionResource,
	ITenantRequest
> {
	public keys = {
		index: ["tenants"],
		list: ["tenants", "list"],
		options: ["tenants", "options"],
		details: (id: Id) => ["tenants", `${id}`],
		showUsage: (id: Id) => ["tenants", `${id}`, "usage"],
	};

	protected urls = {
		index: keyToUrl(this.keys.index),
		options: keyToUrl(this.keys.options),
		details: (id: Id) => keyToUrl(this.keys.details(id)),
		showUsage: (id: Id) => keyToUrl(this.keys.showUsage(id)),
	};

	showUsage = (id: Id) => axios.get<LaravelResponse<ModelUsageResource[]>>(this.urls.showUsage(id));
}

export const tenantService = new TenantService();
