import { Id } from "@dgs/core";
import { EmailListResource } from "~shared/types/email";
import { Group } from "~shared/types/group";
import { IRegistrationFormDataField, ISectionDataFieldOption } from "~shared/types/registrationFormChildDataField";
import { IRegistrationFormSection } from "~shared/types/section";
import { IRegistrationFormStep } from "~shared/types/step";
import { Tag } from "~shared/types/tag";

export enum ActionTypes {
	SHOW_DATA_FIELD = "showDataField",
	SHOW_SECTION = "showSection",
	SHOW_STEP = "showStep",
	ATTACH_GROUP = "attachGroup",
	ATTACH_TAG = "attachTag",
	OVERWRITE_GROUPS = "overwriteGroups",
	OVERWRITE_TAGS = "overwriteTags",
	SEND_EMAIL = "sendEmail",
}

interface BaseAction {
	id: Id;
}
export interface SHOW_DATA_FIELD_Action extends BaseAction {
	type: ActionTypes.SHOW_DATA_FIELD;
	value: {
		dataField: IRegistrationFormDataField;
		section: IRegistrationFormSection;
	};
}
export interface SHOW_DATA_FIELD_ActionFormState extends BaseAction {
	type: ActionTypes.SHOW_DATA_FIELD;
	value: ISectionDataFieldOption | null;
}
export interface SHOW_SECTION_Action<TValue = IRegistrationFormSection> extends BaseAction {
	type: ActionTypes.SHOW_SECTION;
	value: TValue;
}
export type SHOW_SECTION_ActionFormState = SHOW_SECTION_Action<null | IRegistrationFormSection>;
export interface SHOW_STEP_Action<TValue = IRegistrationFormStep> extends BaseAction {
	type: ActionTypes.SHOW_STEP;
	value: TValue;
}
export type SHOW_STEP_ActionFormState = SHOW_STEP_Action<null | IRegistrationFormStep>;
export interface ATTACH_GROUP_Action<TValue = Group> extends BaseAction {
	type: ActionTypes.ATTACH_GROUP;
	value: TValue;
}
export type ATTACH_GROUP_ActionFormState = ATTACH_GROUP_Action<null | Group>;
export interface ATTACH_TAG_Action<TValue = Tag> extends BaseAction {
	type: ActionTypes.ATTACH_TAG;
	value: TValue;
}
export type ATTACH_TAG_ActionFormState = ATTACH_TAG_Action<null | Tag>;
export interface OVERWRITE_GROUPS_Action<TValue = Group[]> extends BaseAction {
	type: ActionTypes.OVERWRITE_GROUPS;
	value: TValue;
}
export type OVERWRITE_GROUPS_ActionFormState = OVERWRITE_GROUPS_Action<null | Group[]>;
export interface OVERWRITE_TAGS_Action<TValue = Tag[]> extends BaseAction {
	type: ActionTypes.OVERWRITE_TAGS;
	value: TValue;
}
export type OVERWRITE_TAGS_ActionFormState = OVERWRITE_TAGS_Action<null | Tag[]>;

export interface SEND_EMAIL_Action<TValue = EmailListResource> extends BaseAction {
	type: ActionTypes.SEND_EMAIL;
	value: TValue;
}
export type SEND_EMAIL_ActionFormState = SEND_EMAIL_Action<null | EmailListResource>;

export type Action =
	| SHOW_DATA_FIELD_Action
	| SHOW_SECTION_Action
	| SHOW_STEP_Action
	| ATTACH_GROUP_Action
	| ATTACH_TAG_Action
	| OVERWRITE_GROUPS_Action
	| OVERWRITE_TAGS_Action
	| SEND_EMAIL_Action;

export type ActionFormState =
	| SHOW_DATA_FIELD_ActionFormState
	| SHOW_SECTION_ActionFormState
	| SHOW_STEP_ActionFormState
	| ATTACH_GROUP_ActionFormState
	| ATTACH_TAG_ActionFormState
	| OVERWRITE_GROUPS_ActionFormState
	| OVERWRITE_TAGS_ActionFormState
	| SEND_EMAIL_ActionFormState;
