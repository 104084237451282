import { CrudDrawer, CrudDrawerProps } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useGuestDetails } from "~shared/guests/details/GuestDetailProvider";
import { GuestMultiSelectField } from "~shared/selects/GuestMultiSelectField";
import { Guest } from "~shared/types/guest";

type Companions = {
	companions: Guest[];
};
export const AttachCompanionsDrawer: FC<Omit<CrudDrawerProps<Companions>, "body">> = (props) => {
	const { t } = useTranslation();
	const { guest } = useGuestDetails();

	const validate = useCallback(
		(values: Companions) => {
			const errors: Record<string, string> = {};
			if (!values.companions || values.companions.length < 1) {
				errors.companions = t("Required field");
			}
			if (guest && values.companions.map((companion) => companion.id).includes(guest.id)) {
				errors.companions = t("The Guest cannot be a companion to themself");
			}
			return errors;
		},
		[guest, t],
	);

	return (
		<CrudDrawer
			{...props}
			translations={{ save: t("Add") }}
			validate={validate}
			body={
				<GuestMultiSelectField
					name="companions"
					label={t("Companions")}
					required={true}
					exclude={guest?.id ? [guest.id, ...guest.companionIds] : undefined}
				/>
			}
		/>
	);
};
