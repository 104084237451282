import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { systemMessageService } from "~admin/shared/api/systemMessages";
import { registrationFormService } from "~shared/api/registrationForms";
import { registrationFormStructureService } from "~shared/api/registrationForms/registrastionFormStructureService";
import { registrationFormStepService } from "~shared/api/registrationForms/registrationFormStepService";
import { ruleSetService } from "~shared/api/registrationForms/ruleSets/ruleSetService";
import { IRegistrationFormStep, RegistrationFormStepFormValues } from "~shared/types/step";
import { RegistrationFormContentFormValues } from "./RegistrationFormContentStep";

export const useRegistrationFormStep = (formId: Id) => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const updateSummaryStep = useCallback(
		async ({ block }: RegistrationFormContentFormValues) => {
			const response = await registrationFormStepService.putSummaryStep(formId, block?.id ?? null);

			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form step was saved."),
					title: t("Save registration form step"),
					type: "success",
				});

				void queryClient.invalidateQueries({ queryKey: registrationFormStructureService.keys.structure(formId) });
			}
		},
		[formId, queryClient, showToast, t],
	);

	const updateLandingStep = useCallback(
		async ({ block }: RegistrationFormContentFormValues) => {
			const response = await registrationFormStepService.putLandingStep(formId, block?.id ?? null);

			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form step was saved."),
					title: t("Save registration form step"),
					type: "success",
				});

				void queryClient.invalidateQueries({ queryKey: registrationFormStructureService.keys.structure(formId) });
			}
		},
		[formId, queryClient, showToast, t],
	);

	const createRegistrationFormStep = useCallback(
		async (step: RegistrationFormStepFormValues) => {
			const response = await registrationFormStepService.store(formId, step);

			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form step was saved."),
					title: t("Save registration form step"),
					type: "success",
				});

				void queryClient.invalidateQueries({ queryKey: registrationFormStructureService.keys.structure(formId) });

				return response.data.data.id;
			}

			return false;
		},
		[formId, queryClient, showToast, t],
	);

	const updateRegistrationFormStep = useCallback(
		async (step: IRegistrationFormStep) => {
			const response = await registrationFormStepService.update(formId, step.id, step);

			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form step was saved."),
					title: t("Save registration form step"),
					type: "success",
				});

				void queryClient.invalidateQueries({ queryKey: registrationFormStepService.keys.details(formId, step.id) });
				void queryClient.invalidateQueries({ queryKey: registrationFormStructureService.keys.structure(formId) });
				void queryClient.invalidateQueries({ queryKey: ruleSetService.keys.ruleSets(formId) });

				return true;
			}

			return false;
		},
		[queryClient, formId, showToast, t],
	);

	const deleteRegistrationFormStep = useCallback(
		async (stepId: Id, afterSuccess?: () => void) => {
			const response = await registrationFormStepService.delete(formId, stepId);
			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form step was deleted."),
					title: t("Delete registration form step"),
					type: "success",
				});
				void queryClient.invalidateQueries({ queryKey: registrationFormStructureService.keys.structure(formId) });
				void queryClient.invalidateQueries({ queryKey: ruleSetService.keys.ruleSets(formId) });
				afterSuccess && afterSuccess();
			}
		},
		[showToast, t, queryClient, formId],
	);

	const syncConsents = useCallback(
		async (consentIds: Id[], forLandingPage: boolean) => {
			const response = await registrationFormService.syncConsents(formId, forLandingPage, consentIds);
			if (apiIsOK(response)) {
				showToast({
					body: t("Consents have been successfully updated."),
					title: t("Update registration form consents"),
					type: "success",
				});
				void queryClient.invalidateQueries({ queryKey: registrationFormStructureService.keys.structure(formId) });
			}
		},
		[formId, queryClient, showToast, t],
	);

	const updateShopStep = useCallback(
		async (paymentProviderIds: Id[], shopItemsIds: Id[]) => {
			const response = await registrationFormService.updateShopStep(formId, paymentProviderIds, shopItemsIds);
			if (apiIsOK(response)) {
				showToast({
					body: t("Payment providers and shop items have been successfully updated."),
					title: t("Update registration form shop step"),
					type: "success",
				});
				void queryClient.invalidateQueries({ queryKey: registrationFormStructureService.keys.structure(formId) });
				void queryClient.invalidateQueries({ queryKey: systemMessageService.keys.all });
			}
		},
		[formId, queryClient, showToast, t],
	);

	const validate = (values: Values) => {
		const errors: { [K in keyof Values]?: any } = {};

		if (!values.name) {
			errors.name = t("Required field");
		}

		return errors;
	};

	return {
		createRegistrationFormStep,
		updateRegistrationFormStep,
		deleteRegistrationFormStep,
		updateLandingStep,
		updateSummaryStep,
		syncConsents,
		updateShopStep,
		validate,
	};
};
type Values = RegistrationFormStepFormValues | IRegistrationFormStep;
