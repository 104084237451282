import { TranslationObject } from "./i18nTypes";

export const validateEachLocale = <Values extends Record<string, any>>(
	translations: TranslationObject<Values>[],
	validateFn: (locale: string, values: Values, errors: Values) => Values,
) => {
	const errors = translations.reduce(
		(errors, x, idx) => {
			const localeErrors = validateFn(x.locale, x, errors[idx] ?? ({ locale: x.locale } as TranslationObject<Values>));

			if (Object.keys(localeErrors).length > 1) {
				errors[idx] = localeErrors;
			}
			return errors;
		},
		[] as Array<Values | undefined>,
	);

	return errors.length ? { translations: errors } : {};
};

export const hasTouchedField = (input: Record<string, any>): boolean => {
	for (const value of Object.values(input)) {
		if (typeof value === "boolean" && value) {
			return true;
		} else if (typeof value === "object") {
			return hasTouchedField(value);
		}
	}
	return false;
};
