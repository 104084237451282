import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled, { css } from "styled-components";
import { AnyRulesetFilterItem } from "~shared/types/ruleSet";

const ColorBox = styled.div<{ $color: string | null }>`
	display: flex;
	background-color: ${({ $color }) => $color ?? "transparent"};
	width: 20px;
	height: 20px;
	padding: ${({ theme }) => theme.spacing(1)};
	${({ $color }) =>
		!$color &&
		css`
			border: 1px solid #ccc;
		`};
	${({ theme }) => theme.borderRadius.medium};
	cursor: pointer;
`;
const ColorLabel = styled.span`
	display: flex;
	gap: ${({ theme }) => theme.spacing(1)};
	align-items: center;
`;
export const RuleSetFilterItemLabelContent: FC<{ filterItem: AnyRulesetFilterItem }> = ({ filterItem }) => {
	const { t } = useTranslation();

	if (filterItem.type === "color") {
		return (
			<ColorLabel>
				{t("Color")}
				<span>=</span>
				<ColorBox $color={filterItem.value} />
			</ColorLabel>
		);
	}
	if (filterItem.type === "action") {
		return (
			<ColorLabel>
				{t("Action")}
				<span>-</span>
				{t(`ActionType.${filterItem.filterItem.type}`)}
				<span>=</span>
				{filterItem.filterItem.value.name}
			</ColorLabel>
		);
	}
	if (filterItem.type === "rule") {
		return (
			<ColorLabel>
				{t("Rule")}
				<span>-</span>
				{t("Data field")}
				<span>=</span>
				{filterItem.filterItem.value.name}
			</ColorLabel>
		);
	}
	return null;
};
