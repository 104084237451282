import { Id, PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { roomContingentKeys, roomContingentService } from "~shared/api/contingent";

interface Props {
	name: string;
	label?: string;
	day: Date;
	guestId?: Id;
	disabled?: boolean;
	className?: string;
	placeholder?: string;
}

const fetcher = (day: Date, guestId?: Id) => (search: string, page: number) =>
	roomContingentService.optionsByDay(day, guestId, { params: { search, page } }).then((r) => r.data);

export const RoomContingentSingleSelectField: FC<Props> = ({
	name,
	day,
	guestId,
	label,
	disabled,
	className,
	placeholder,
}) => {
	return (
		<PagedSingleSelectField
			clearable={true}
			placeholder={placeholder}
			name={name}
			queryKey={roomContingentKeys.optionsByDay(day, guestId)}
			fetcher={fetcher(day, guestId)}
			label={label || ""}
			disabled={disabled}
			getLabel={(option) => `${option.hotel.name} - ${option.roomType.name}`}
			className={className}
		/>
	);
};
