import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { dashboardKeys } from "~shared/api/dashboard";
import { guestKeys, guestService } from "~shared/api/guests";
import { SendEmailFormValues } from "~shared/types/email";
import { SendEmailCrudDrawer } from "~shared/ui/SendEmailCrudDrawer";

interface Props {
	handleClose: () => void;
	open: boolean;
	guestId: Id;
}

export const GuestEmailDrawer: FC<Props> = ({ handleClose, open, guestId }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { showToast } = useToasts();

	const sendMail = useCallback(
		async (values: SendEmailFormValues) => {
			const response = await guestService.sendEmail(guestId, values.locale, values.customEmail?.id || "");
			if (apiIsOK(response)) {
				showToast({
					body: t("Email sent successfully."),
					title: t("Send email"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: guestKeys.emails(guestId) });
				await queryClient.invalidateQueries({ queryKey: dashboardKeys.index });
				handleClose();
				return true;
			} else {
				showToast({
					body: response.statusText,
					title: t("Send email"),
					type: "error",
				});
				return false;
			}
		},
		[guestId, handleClose, queryClient, showToast, t],
	);

	return <SendEmailCrudDrawer title={t("Send email")} open={open} handleClose={handleClose} handleSubmit={sendMail} />;
};
