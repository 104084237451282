import { CrudDrawer, IconButton, apiIsOK, useBulkEdit, useOpen, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { dashboardKeys } from "~shared/api/dashboard";
import { importKeys, importService } from "~shared/api/imports";
import { mapFormValueToRequest } from "~shared/utils/mapFormValueToRequest";
import { ImportErrorListBulkEditFormFields } from "./ImportErrorListBulkEditFormFields";
import {
	ImportErrorListBulkEditEntityType,
	ImportErrorListBulkEditOption,
	ImportErrorListBulkEditRequest,
	ImportErrorListBulkEditValues,
} from "./importErrorListBulkEditTypes";

const initialValues: ImportErrorListBulkEditValues = { entity: null, newValue: null };

interface Props {
	options: ImportErrorListBulkEditOption[];
}

export const ImportErrorListBulkEditAction: FC<Props> = ({ options }) => {
	const { t } = useTranslation();
	const { guestImportId = "" } = useParams<"guestImportId">();
	const { open, handleClose, handleOpen } = useOpen();
	const { selectedRows, setSelectedRow, availableItemIds, setBulkEditing, selectedRowsCount } = useBulkEdit();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();

	const validate = (values: ImportErrorListBulkEditValues) => {
		const errors: { entity?: string } = {};

		if (!values.entity) {
			errors["entity"] = t("Field is required");
		}

		return errors;
	};

	const handleEdit = useCallback(
		async (values: ImportErrorListBulkEditValues) => {
			const request = {
				entityType: values.entity?.entityType,
				dataFieldId:
					values.entity?.entityType === ImportErrorListBulkEditEntityType.dataField ? values.entity?.id : null,
				newValue: mapFormValueToRequest(values.newValue),
			} as ImportErrorListBulkEditRequest;

			const response = await importService.guests.errors.bulkCorrection(
				guestImportId,
				Array.isArray(selectedRows) ? selectedRows : null,
				request,
			);

			if (apiIsOK(response)) {
				showToast({
					body: t("{{count}} guest was updated", { count: selectedRows.length }),
					title: t("Guests"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: importKeys.index });
				await queryClient.invalidateQueries({ queryKey: dashboardKeys.index });
				setSelectedRow("none");
				setBulkEditing(false);

				handleClose();
			}
		},
		[selectedRows, showToast, t, queryClient, setSelectedRow, setBulkEditing, handleClose, guestImportId],
	);

	const title = t("Edit {{count}} guests", { count: selectedRowsCount });

	return (
		<>
			<IconButton
				title={title}
				size="small"
				onClick={handleOpen}
				disabled={selectedRows.length === 0 || availableItemIds.length === 0}
				icon="edit"
			/>
			<CrudDrawer
				open={open}
				onSubmit={async (values) => {
					handleEdit(values);
					return true;
				}}
				initialValues={initialValues}
				onClose={handleClose}
				heading={title}
				validate={validate}
				body={
					<>
						<ImportErrorListBulkEditFormFields options={options} />
					</>
				}
			/>
		</>
	);
};
