import React, { FC } from "react";
import { GuestFilterDrawer, GuestFilterProvider } from "~shared/guestFilters";
import { HotelBookingsList } from "./HotelBookingsList";

export const HotelBookingsPage: FC = () => {
	return (
		<GuestFilterProvider listFilterKey="bookingListFilter">
			<HotelBookingsList />
			<GuestFilterDrawer />
		</GuestFilterProvider>
	);
};
