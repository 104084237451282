import { SingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { TImportHeader } from "~shared/types/guestImport";

interface Props {
	label: string;
	name: string;
	options: TImportHeader[];
	required?: boolean;
}

export const ZohoFilterFieldSingleSelect: FC<Props> = ({ label, name, options, required = false }) => {
	return (
		<SingleSelectField<string, TImportHeader>
			options={options}
			label={label}
			name={name}
			getValue={(x) => x.externalField}
			getLabel={(x) => x.label}
			required={required}
		/>
	);
};
