import {
	EntityDrawerProps,
	I18NCrudDrawer,
	I18nOnlyOnMainForm,
	I18nTextareaField,
	TextField,
	mapErrors,
} from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IPlaceholderResource } from "~admin/shared/types/placeholder";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";

export const PlaceholderDrawer: FC<EntityDrawerProps<IPlaceholderResource>> = (props) => {
	const { fallbackLocale, locales } = useContentLocale();
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IPlaceholderResource) => {
			let errors: { [K in keyof IPlaceholderResource]?: any } = {};
			const regex = /^[a-zA-Z]+$/;

			if (!values.key) {
				errors.key = t("Required field");
			} else if (!regex.test(values.key)) {
				errors.key = t("You can only use characters from a-z and A-Z.");
			}

			const fallbackTranslation = values.translations[fallbackLocale] || {};

			if (!fallbackTranslation.value) {
				errors = mapErrors(errors, fallbackLocale, "value", t);
			}

			return errors;
		},
		[fallbackLocale, t],
	);

	return (
		<I18NCrudDrawer
			{...props}
			locales={locales}
			fallbackLocale={fallbackLocale}
			validate={validate}
			body={
				<>
					<I18nOnlyOnMainForm>
						<TextField name="key" label={t("Placeholder key")} />
					</I18nOnlyOnMainForm>
					<I18nTextareaField label={t("Value")} name="value" required="onlyOnMainForm" />
				</>
			}
		/>
	);
};
