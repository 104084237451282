import { MultiSelectField } from "@dgs/core";
import React, { FC, useCallback, useState } from "react";
import { BaseRuleFormState } from "~shared/types/rules";

interface Props {
	name: string;
	rule: BaseRuleFormState;
	label: string;
}

export const ValueCreatableMultiSelect: FC<Props> = ({ name, rule, label }) => {
	const [values, setValues] = useState<string[]>(rule.value !== null ? (rule.value as string[]) : []);

	const createNewOption = useCallback(
		(search: string, selectNewOption: (value: string) => void) => {
			setValues([...values, search]);
			selectNewOption(search);
		},
		[values],
	);

	return (
		<MultiSelectField
			label={label}
			name={name}
			options={values}
			getLabel={(value: string) => value}
			onCreate={createNewOption}
		/>
	);
};
