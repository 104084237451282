import { BaseCrudOptionService, Id, keyToUrl } from "@dgs/core";
import {
	IGuestFileCategoryIndexResource,
	IGuestFileCategoryOptionResource,
	IGuestFileCategoryRequest,
	IGuestFileCategoryUpdateResource,
} from "~shared/types/guestFileCategory";

class GuestFileCategoryService extends BaseCrudOptionService<
	IGuestFileCategoryIndexResource,
	IGuestFileCategoryUpdateResource,
	IGuestFileCategoryOptionResource,
	IGuestFileCategoryRequest
> {
	public keys = {
		index: ["guest-file-categories"],
		list: ["guest-file-categories", "list"],
		options: ["guest-file-categories", "options"],
		details: (id: Id) => ["guest-file-categories", `${id}`],
	};

	protected urls = {
		index: keyToUrl(this.keys.index),
		options: keyToUrl(this.keys.options),
		details: (id: Id) => keyToUrl(this.keys.details(id)),
	};
}

export const guestFileCategoryService = new GuestFileCategoryService();
