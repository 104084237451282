import { Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { guestViewKeys } from "./guestViewKeys";
import { guestViewService } from "./guestViewService";

export const useGuestViewQuery = (guestViewId: Id) => {
	const { data, isLoading } = useQuery({
		queryKey: guestViewKeys.details(guestViewId),
		queryFn: () => guestViewService.show(guestViewId).then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};
