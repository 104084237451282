import React, { FC, useMemo } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import styled from "styled-components";
import { useRegistrationFormStructureQuery } from "~shared/api/registrationForms/registrationFormQueries";
import { StructureStep } from "~shared/types/registrationFormTypes";
import { mapStructureStepIds } from "./registrationFormDndUtils";
import { RegistrationFormOrderStructure } from "./RegistrationFormOrderStructure";
import { RegistrationFormStructureOverview } from "./RegistrationFormStructureOverview";
import { RegistrationFormStructureProvider } from "./RegistrationFormStructureProvider";

const Wrapper = styled.div`
	padding-bottom: ${({ theme }) => theme.spacing(16)};
`;

export const RegistrationFormStructurePage: FC = () => {
	const { registrationFormId = "" } = useParams<"registrationFormId">();
	const { registrationForm } = useRegistrationFormStructureQuery(registrationFormId);

	const structureSteps = useMemo<StructureStep[] | null>(() => {
		if (!registrationForm) {
			return null;
		}

		return registrationForm.steps.map(mapStructureStepIds);
	}, [registrationForm]);

	if (!structureSteps) {
		return null;
	}

	return (
		<RegistrationFormStructureProvider>
			<Wrapper>
				<Routes>
					<Route index element={<Navigate to="overview" />} />
					<Route
						path="overview"
						element={
							<RegistrationFormStructureOverview
								initialSteps={structureSteps}
								landingStep={registrationForm?.landingPage || null}
								summaryStep={registrationForm?.summaryPage || null}
								shopStep={registrationForm?.shopPage || null}
							/>
						}
					/>
					<Route path="order" element={<RegistrationFormOrderStructure initialSteps={structureSteps} />} />
				</Routes>
			</Wrapper>
		</RegistrationFormStructureProvider>
	);
};
