import { Card, CardBody, CardHeader, CardHeading, FlatIconButton, FormattedDateTime } from "@dgs/core";
import React, { FC, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { EmailLogDetailsAttachments } from "~shared/emails/logs/EmailLogDetailsAttachments";
import { DetailedEmailLog } from "~shared/types/email";
import { ResendEmail } from "./ResendEmail";

interface Props {
	emailLog: DetailedEmailLog;
}

const StyledCard = styled(Card)`
	max-width: 1440px;
	width: 100%;
`;

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	width: 100%;
`;

const Header = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr;
	gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
`;

const StyledBody = styled(CardBody)`
	display: grid;
	grid-auto-rows: max-content;
	gap: ${({ theme }) => theme.spacing(5)};
`;

const InfoWrapper = styled.div`
	max-width: 1440px;
	display: grid;
	grid-template-columns: max-content 1fr;
	grid-auto-rows: max-content;
	gap: ${({ theme }) => theme.spacing(2)};
`;

export const EmailLogDetailsContent: FC<Props> = ({ emailLog }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const shadowHost = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (shadowHost.current) {
			if (!shadowHost.current.shadowRoot) {
				shadowHost.current.attachShadow({ mode: "open" });
			}

			if (shadowHost.current.shadowRoot) {
				shadowHost.current.shadowRoot.innerHTML = emailLog.body;
			}
		}
	}, [emailLog.body]);

	return (
		<Wrapper>
			<StyledCard>
				<CardHeader>
					<Header>
						<FlatIconButton onClick={() => navigate(-1)} title={t("Go back")} icon="caret_left" />
						<CardHeading>{t("Email")} </CardHeading>
					</Header>
					<ResendEmail emailId={emailLog.id} />
				</CardHeader>
				<StyledBody>
					<InfoWrapper>
						<b>{t("Subject")}:</b>
						<span>{emailLog.subject}</span>
						<b>{t("Sent at")}:</b>
						<FormattedDateTime as="span" dateTime={emailLog.date} />
						<b>{t("To")}:</b>
						<span>{emailLog.to}</span>
						<b>{t("From")}:</b>
						<span>{emailLog.from}</span>
						<b>{t("Cc")}:</b>
						<span>{emailLog.cc}</span>
						<b>{t("Bcc")}:</b>
						<span>{emailLog.bcc}</span>
						<b>{t("Attachments")}:</b>
						<EmailLogDetailsAttachments attachments={emailLog.attachments} />
					</InfoWrapper>
					<div ref={shadowHost} />
				</StyledBody>
			</StyledCard>
		</Wrapper>
	);
};
