import { Id } from "@dgs/core";
import { Guest, GuestScreeningStatus } from "~shared/types/guest";
import { GuestIndexResource } from "~shared/types/newGuestTypes";
import { IPaymentProviderIndexResource } from "~shared/types/paymentProvider";
import { RegistrationFormShopItem } from "~shared/types/shop";

export interface OrdersIndex extends GuestIndexResource {
	id: Id;
	orderStatus: OrderStatus;
	remainingPaymentStarted: boolean | null;
	paymentLinkSent: boolean | null;
	netPrice: number;
	createdAt: string;
	paymentProvider: IPaymentProviderIndexResource;
	screeningStatus: GuestScreeningStatus;
}

export interface OrderFormValue {
	id: Id;
	orderStatus: OrderStatus;
	paymentProvider: IPaymentProviderIndexResource;
}

export interface OrderRequest {
	id: Id;
	orderStatus: OrderStatus;
}

export interface CreateOrderFormValue {
	guest: Guest | null;
	paymentProvider: IPaymentProviderIndexResource | null;
	shopItems: ShopItems[];
}

interface ShopItems {
	shopItem: RegistrationFormShopItem | null;
	quantity: number;
}

export interface CreateOrderRequest {
	guestId: Id;
	paymentProviderId: Id;
	shopItems: ShopItemsRequest[];
}

interface ShopItemsRequest {
	shopItemId: Id;
	quantity: number;
}

export enum OrderStatus {
	PAID = "paid",
	PENDING = "pending",
	FAILED = "failed",
	CANCELLED = "cancelled",
	PARTIAL_PAID = "partialPaid",
	APPROVAL_PENDING = "approvalPending",
}

export interface OrderItemsIndex {
	id: Id;
	name: string;
	netPrice: number;
	grossPrice: number;
	totalGrossPrice: number;
	tax: number;
	depositInPercent: number | null;
	quantity: number;
	creditNote: number;
}

export interface OrderDetailsResource {
	id: Id;
	paymentProviders: IPaymentProviderIndexResource[];
	orderItems: OrderItemsIndex[];
}

export type RefundAllFormState = {
	type: "order";
};
type RefundAllRequest = RefundAllFormState;

export type RefundOrderItemsFormState = {
	type: "orderItems";
	orderItems: OrderItemsIndex[];
};
type RefundOrderItemsRequest = Omit<RefundOrderItemsFormState, "orderItems"> & {
	orderItemIds: Id[];
};

export type RefundFixAmountFormState = {
	type: "fixAmount";
	orderItems: OrderItemsIndex[];
	amount: number;
};
type RefundFixAmountRequest = Omit<RefundFixAmountFormState, "orderItems"> & {
	orderItemIds: Id[];
};

export type RefundPercentFormState = {
	type: "percent";
	orderItems: OrderItemsIndex[];
	amount: number;
};
type RefundPercentRequest = Omit<RefundPercentFormState, "orderItems"> & {
	orderItemIds: Id[];
};

export type OrderRefundFormState =
	| RefundAllFormState
	| RefundOrderItemsFormState
	| RefundFixAmountFormState
	| RefundPercentFormState;

export type OrderRefundRequest =
	| RefundAllRequest
	| RefundOrderItemsRequest
	| RefundFixAmountRequest
	| RefundPercentRequest;

export type OrderRefundResource = OrderRefundFormState;
