import { ContextMenu, ContextMenuItem, Id, SplitButton, useOpen } from "@dgs/core";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDataFieldsQuery } from "~shared/api/dataFields";
import { registrationFormService } from "~shared/api/registrationForms";
import { StructureSection } from "~shared/types/registrationFormTypes";
import { DeleteModelWithUsagesDialog, useModelUsage } from "~shared/ui/modelUsage/DeleteModelWithUsagesDialog";
import { CreateRegistrationFormBlockDrawer } from "../blocks/CreateRegistrationFormBlockDrawer";
import { CreateRegistrationFormDataFieldsDrawer } from "../dataFields/CreateRegistrationFormDataFieldsDrawer";
import { EditRegistrationFormSectionDrawer } from "./EditRegistrationFormSectionDrawer";
import { useRegistrationFormSection } from "./useRegistrationFormSection";

interface Props {
	registrationFormId: string;
	registrationFormStepId: Id;
	registrationFormSection: StructureSection;
	hideDataFieldOptions?: boolean;
}

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(3)};
`;

export const RegistrationFormSectionContextMenu: FC<Props> = ({
	registrationFormId,
	registrationFormStepId,
	registrationFormSection,
	hideDataFieldOptions = false,
}) => {
	const { data: dataFields } = useDataFieldsQuery();
	const { t } = useTranslation();
	const { open, handleOpen, handleClose } = useOpen();
	const {
		open: openRegistrationFormDataFields,
		handleOpen: handleOpenRegistrationFormDataFields,
		handleClose: handleCloseRegistrationFormDataFields,
	} = useOpen();

	const showUsageAction = useCallback(
		() =>
			registrationFormService.showSectionUsage(
				registrationFormId,
				registrationFormStepId,
				registrationFormSection.sectionId,
			),
		[registrationFormId, registrationFormSection.sectionId, registrationFormStepId],
	);
	const { handleUsage, ...usageModalProps } = useModelUsage(showUsageAction);

	const {
		open: openSectionBlockDrawer,
		handleOpen: handleOpenSectionBlockDrawer,
		handleClose: handleCloseSectionBlockDrawer,
	} = useOpen();

	const { deleteRegistrationFormSection } = useRegistrationFormSection(registrationFormId, registrationFormStepId);

	const options = useMemo(() => {
		return [
			{
				title: t("Update data fields"),
				onClick: handleOpenRegistrationFormDataFields,
			},
			{
				title: t("Add block"),
				onClick: handleOpenSectionBlockDrawer,
			},
		];
	}, [handleOpenRegistrationFormDataFields, handleOpenSectionBlockDrawer, t]);
	return (
		<>
			<Wrapper>
				{hideDataFieldOptions ? null : <SplitButton size="small" options={options} />}
				<ContextMenu displayShortcutsAs="icon" size="small" flat={true}>
					{hideDataFieldOptions ? null : (
						<ContextMenuItem
							label={t("Update data fields")}
							title={t("Update data fields")}
							action={handleOpenRegistrationFormDataFields}
							icon="plus"
						/>
					)}
					<ContextMenuItem
						label={t("Edit registration form section")}
						title={t("Edit registration form section")}
						action={handleOpen}
						icon="edit"
					/>
					<ContextMenuItem
						action={handleUsage}
						title={t("Delete registration form section")}
						label={t("Delete registration form section")}
						icon="delete"
					/>
				</ContextMenu>
				<DeleteModelWithUsagesDialog
					usagePrefix={t("This section or the content")}
					confirmationBody={t("Are you sure you want to delete this section?")}
					deleteAction={() => deleteRegistrationFormSection(registrationFormSection.sectionId)}
					{...usageModalProps}
				/>
			</Wrapper>

			<EditRegistrationFormSectionDrawer
				registrationFormId={registrationFormId}
				stepId={registrationFormStepId}
				sectionId={registrationFormSection.sectionId}
				open={open}
				onClose={handleClose}
				typeIsDisabled={true}
			/>
			<CreateRegistrationFormDataFieldsDrawer
				dataFields={dataFields}
				open={openRegistrationFormDataFields}
				onClose={handleCloseRegistrationFormDataFields}
				registrationFormId={registrationFormId}
				registrationFormStepId={registrationFormStepId}
				registrationFormSection={registrationFormSection}
			/>
			<CreateRegistrationFormBlockDrawer
				open={openSectionBlockDrawer}
				onClose={handleCloseSectionBlockDrawer}
				registrationFormId={registrationFormId}
				registrationFormStepId={registrationFormStepId}
				registrationFormSectionId={registrationFormSection.sectionId}
			/>
		</>
	);
};
