import { Card, CardBody, Loading } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { GuestScreeningStatusChangeAction } from "~shared/guests/screeningStatus/GuestScreeningStatusChangeAction";
import { GuestScreeningStatusValue } from "~shared/guests/screeningStatus/GuestScreeningStatusValue";
import { GuestScreeningStatus } from "~shared/types/guest";
import { GuestDetailsResource } from "~shared/types/newGuestTypes";

const StyledBody = styled(CardBody)`
	display: grid;
	grid-template-columns: max-content 1fr max-content;
	gap: ${({ theme }) => theme.spacing(4)};
	align-items: center;
`;

const Title = styled.span`
	font-weight: ${({ theme }) => theme.fonts.weights.bold};
`;

interface Props {
	guest: GuestDetailsResource | null;
}

export const GuestScreeningStatusView: FC<Props> = ({ guest }) => {
	const { t } = useTranslation();
	const screeningStatus: GuestScreeningStatus | null = guest
		? guest.attributes.find((a) => a.type === "column" && a.entityType === "screeningStatus")?.value
		: null;

	return (
		<Card>
			<StyledBody>
				{guest === null || screeningStatus === null ? (
					<Loading />
				) : (
					<>
						<Title>{t("screeningStatus")}:</Title>
						<GuestScreeningStatusValue screeningStatus={screeningStatus} />
						<GuestScreeningStatusChangeAction guestId={guest.id} screeningStatus={screeningStatus} />
					</>
				)}
			</StyledBody>
		</Card>
	);
};
