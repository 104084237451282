import { DrawerAutoDataList, IDrawerDataListColumn, useCrudEntity } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { waitingListService } from "~admin/shared/api/waitingList";
import { WaitingListListContextMenu } from "~admin/waitingList/WaitingListContextMenu";
import { IWaitingList, WaitingList, WaitingListRequest } from "~shared/types/waitingList";
import { WaitingListDrawer } from "./WaitingListDrawer";

function mapWaitingListToRequest(waitingList: WaitingList): WaitingListRequest {
	return {
		formId: waitingList.form?.id ?? null,
		registrationQuota: waitingList.registrationQuota,
		enableAutomaticMoveUp: waitingList.enableAutomaticMoveUp,
		moveUpMailId: waitingList.moveUpMail?.id ?? null,
		confirmationMailId: waitingList.confirmationMail?.id ?? null,
		confirmationBlockId: waitingList.confirmationBlock?.id ?? null,
	};
}

export const WaitingListList: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const crudOperations = useCrudEntity(waitingListService, {
		entityName: t("WaitingList"),
		messages: {
			create: t("Waiting list was saved."),
			update: t("Waiting list was saved."),
			delete: t("Waiting list was successfully deleted."),
		},
	});

	const dataListColumns: IDrawerDataListColumn<IWaitingList>[] = useMemo(
		() => [
			{
				heading: t("Registration form"),
				valueKey: "form",
				type: "value",
				render: (waitingList) => waitingList.form.name,
			},
			{
				heading: t("Contingent"),
				valueKey: "registrationQuota",
				type: "value",
			},
			{
				heading: t("Registered guests"),
				valueKey: "registrationGuestCount",
				type: "value",
			},
			{
				heading: t("Guests on waiting list"),
				valueKey: "waitingListGuestCount",
				type: "value",
			},
			{
				heading: t("Automatic Move Up"),
				valueKey: "enableAutomaticMoveUp",
				type: "value",
				render: (waitingList) => (waitingList.enableAutomaticMoveUp ? t("Yes") : t("No")),
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (waitingList, openDrawer) => (
					<WaitingListListContextMenu
						handleEdit={openDrawer}
						handleDelete={() => crudOperations.deleteEntity(waitingList.id)}
					/>
				),
			},
		],
		[crudOperations, t],
	);

	const emptyWaitingList: WaitingList = {
		id: -1,
		form: null,
		registrationQuota: 0,
		enableAutomaticMoveUp: false,
		confirmationMail: null,
		moveUpMail: null,
		confirmationBlock: null,
	};

	return (
		<DrawerAutoDataList
			translations={{
				title: t("Waiting Lists"),
				empty: t("No waiting lists available"),
			}}
			renderCreateDrawer={(drawer) => (
				<WaitingListDrawer
					{...drawer}
					heading={t("Create waiting list")}
					initialValues={emptyWaitingList}
					onSubmit={(entityFormState) => crudOperations.createEntity(mapWaitingListToRequest(entityFormState))}
				/>
			)}
			renderUpdateDrawer={(entity: WaitingList, drawer) => (
				<WaitingListDrawer
					{...drawer}
					heading={t("Edit waiting list")}
					initialValues={entity}
					onSubmit={(entityFormState) =>
						crudOperations.updateEntity(entity.id, mapWaitingListToRequest(entityFormState))
					}
				/>
			)}
			columns={dataListColumns}
			service={waitingListService}
			onRowClick={(value) => navigate(value.id.toString())}
		/>
	);
};
