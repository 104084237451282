import { Loading, RenderView, useBlockQueryShowBySlug } from "@dgs/core";
import React, { FC } from "react";
import { useParams } from "react-router-dom";

export const BlockPage: FC = () => {
	const { slug = "" } = useParams();
	const { block, isLoading } = useBlockQueryShowBySlug(slug);

	if (isLoading) {
		return <Loading />;
	}
	return block && <RenderView block={block} />;
};
