import { Button, Drawer, DrawerBody, DrawerHeader, SubmitButton } from "@dgs/core";
import { FieldArray, Form, Formik } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
	ActionFilter,
	AnyRulesetFilterItem,
	AnyRulesetFilterItemFormState,
	RuleFilter,
	RuleSet,
} from "~shared/types/ruleSet";
import { RuleSetFilterItemChooser } from "./RuleSetFilterItemChooser";
import { RuleSetFilterItemFormField } from "./RuleSetFilterItemFormField";

const Reverse = styled.div`
	display: flex;
	flex-direction: column-reverse;
`;

const Actions = styled.div`
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
`;

interface Props {
	initialValues: AnyRulesetFilterItem[];
	ruleSets: RuleSet[];
	open: boolean;
	handleClose: () => void;
	onChange: (filterItems: AnyRulesetFilterItem[]) => void;
}

export const RuleSetFilterDrawer: FC<Props> = ({ ruleSets, initialValues, open, onChange, handleClose }) => {
	const { t } = useTranslation();
	const onSubmit = useCallback(
		async (values: AnyRulesetFilterItemFormState[]) => {
			onChange(
				values.map((x) => {
					if (x.type === "action") {
						return x as ActionFilter;
					}
					if (x.type === "rule") {
						return x as RuleFilter;
					}
					return x;
				}),
			);
			handleClose();
		},
		[handleClose, onChange],
	);

	return (
		<Drawer open={open} onClose={handleClose}>
			<DrawerHeader onClose={handleClose}>{t("Ruleset filter")}</DrawerHeader>
			<DrawerBody>
				<Formik initialValues={{ filters: initialValues }} onSubmit={({ filters }) => onSubmit(filters)}>
					{({ values, resetForm }) => (
						<Form>
							<FieldArray
								name="filters"
								render={(arrayHelpers) => (
									<>
										<RuleSetFilterItemChooser onAdd={(filterItem) => arrayHelpers.push(filterItem)} />
										<Reverse>
											{values.filters.map((x, idx) => (
												<RuleSetFilterItemFormField
													key={idx}
													filterItem={x}
													name={`filters.${idx}`}
													onDelete={() => arrayHelpers.remove(idx)}
													ruleSets={ruleSets}
												/>
											))}
										</Reverse>
									</>
								)}
							/>
							<Actions>
								<Button
									type="button"
									onClick={() => {
										onChange([]);
										resetForm({ values: { filters: [] } });
										handleClose();
									}}
								>
									{t("Reset")}
								</Button>
								<SubmitButton>{t("Apply")}</SubmitButton>
							</Actions>
						</Form>
					)}
				</Formik>
			</DrawerBody>
		</Drawer>
	);
};
