import { PagedMultiSelectField, filesKeys, filesService, useI18nForm } from "@dgs/core";
import { useField } from "formik";
import React, { FC, ReactElement } from "react";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
}

interface WrapperProps {
	name: string;
	children: ReactElement;
}

const fetcher = () => filesService.options().then((r) => r.data);

const I18nSelectFieldWrapper: FC<WrapperProps> = ({ name, children }) => {
	const { fallbackLocale, currentLocale } = useI18nForm();
	const [{ value: fallbackValue }] = useField(`translations.${fallbackLocale}.${name}`);

	if (React.Children.count(children) !== 1) {
		throw new Error("Only one child is supported");
	}

	if (children)
		if (fallbackValue && currentLocale !== fallbackLocale) {
			return React.cloneElement(children, {
				description: "Fallback: " + fallbackValue.map((i: any) => i.filename).join(", "),
			});
		}

	return children;
};

export const I18nFileMultiSelectField: FC<Props> = ({ name, label, disabled, required }) => {
	const { currentLocale } = useI18nForm();

	return (
		<I18nSelectFieldWrapper name={name}>
			<PagedMultiSelectField
				key={`translations.${currentLocale}.${name}`}
				name={`translations.${currentLocale}.${name}`}
				label={label}
				fetcher={fetcher}
				queryKey={filesKeys.options}
				getLabel={(o) => o.filename}
				disabled={disabled}
				required={required}
				clearable={false}
			/>
		</I18nSelectFieldWrapper>
	);
};
