import { DrawerAutoDataList, IDrawerDataListColumn, useCrudEntity } from "@dgs/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { merchantService } from "~admin/shared/api/shop/merchants";
import { MerchantDrawer } from "~admin/shop/merchants/MerchantDrawer";
import { IMerchant, IMerchantIndex, IMerchantRequest } from "~shared/types/merchant";
import { MerchantContextMenu } from "./MerchantContextMenu";

const emptyMerchant: IMerchant = {
	bank: "",
	bankAccountHolder: "",
	bic: "",
	city: "",
	country: "",
	email: "",
	houseNumber: "",
	iban: "",
	id: "",
	logo: null,
	name: "",
	phone: "",
	street: "",
	tradeRegister: "",
	tradeRegisterNumber: "",
	vatId: "",
	zip: "",
};

function mapToRequest(formState: IMerchant): IMerchantRequest {
	const { logo, ...rest } = formState;

	return {
		...rest,
		logoFileId: logo?.id ?? null,
	};
}

export const MerchantsList = () => {
	const { t } = useTranslation();

	const crudOperations = useCrudEntity(merchantService, {
		entityName: t("Merchant"),
		messages: {
			create: t("Merchant was successfully saved."),
			update: t("Merchant was successfully saved."),
			delete: t("Merchant was successfully deleted."),
		},
	});

	const dataListColumns: IDrawerDataListColumn<IMerchantIndex>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (entity, openDrawer) => (
					<MerchantContextMenu handleEdit={openDrawer} handleDelete={() => crudOperations.deleteEntity(entity.id)} />
				),
			},
		],
		[t, crudOperations],
	);

	return (
		<DrawerAutoDataList
			translations={{
				title: t("Merchants"),
				empty: t("No merchants available"),
			}}
			renderCreateDrawer={(props) => (
				<MerchantDrawer
					{...props}
					heading={t("Create merchant")}
					initialValues={emptyMerchant}
					onSubmit={(entity) => crudOperations.createEntity(mapToRequest(entity))}
				/>
			)}
			renderUpdateDrawer={(resource, props) => (
				<MerchantDrawer
					{...props}
					heading={t("Edit merchant")}
					initialValues={resource}
					onSubmit={(formState) => crudOperations.updateEntity(resource.id, mapToRequest(formState))}
				/>
			)}
			columns={dataListColumns}
			service={merchantService}
		/>
	);
};
