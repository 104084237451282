import { Empty, Loading, TextControl } from "@dgs/core";
import React, { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import styled from "styled-components";
import { Recipient } from "~shared/types/guest";

interface Props {
	options: Recipient[];
	search: string;
	onSearch: (search: string) => void;
	endReached: () => void;
	loading?: boolean;
}

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const ListWrapper = styled.div`
	height: 100%;
	flex: 1;
	display: grid;
	grid-auto-rows: 1fr;
	gap: ${({ theme }) => theme.spacing(3)};
`;

export const EmailRecipientList: FC<Props> = ({ options, endReached, onSearch, search, loading }) => {
	const { t } = useTranslation();
	const virtuosoRef = useRef<VirtuosoHandle | null>(null);
	return (
		<Wrapper>
			<TextControl
				placeholder={t("Search...")}
				name={`recipient.search`}
				label={t("Search recipients")}
				onChange={(e) => onSearch(e.target.value)}
				value={search}
				suffix={loading && <Loading variant="tiny" />}
			/>
			{!loading && options.length === 0 && <Empty>{t("No recipients")}</Empty>}
			<ListWrapper role="listbox">
				<Virtuoso
					style={{ height: "100%" }}
					ref={virtuosoRef}
					data={options}
					endReached={endReached}
					computeItemKey={(index) => (!!options[index] ? options[index].id : index)}
					itemContent={(index, recipient) => (
						<span key={recipient.id}>{`${recipient.firstName} ${recipient.lastName} (${recipient.email})`}</span>
					)}
				/>
			</ListWrapper>
		</Wrapper>
	);
};
