import { apiIsOK, useToasts } from "@dgs/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { systemMessageService } from "~admin/shared/api/systemMessages";
import { dataFieldService } from "~shared/api/dataFields";
import { DataFieldSettings } from "~shared/types/dataField";
import { DataFieldSettingsForm } from "./DataFieldSettingsForm";

export const DataFieldSettingsPage: FC = () => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { data, isLoading } = useQuery({
		queryKey: dataFieldService.keys.settings,
		queryFn: () => dataFieldService.getSettings().then((r) => r.data.data),
	});

	const onSubmit = useCallback(
		async ({
			email,
			lastName,
			firstName,
			salutation,
			ccEmail,
			academicTitle,
			uniqueEmail,
			dateOfBirth,
		}: DataFieldSettings) => {
			const response = await dataFieldService.putSettings({
				emailId: email.id,
				firstNameId: firstName.id,
				lastNameId: lastName.id,
				salutationId: salutation.id,
				academicTitleId: academicTitle ? academicTitle.id : null,
				ccEmailId: ccEmail ? ccEmail.id : null,
				uniqueEmail: uniqueEmail,
				dateOfBirthId: dateOfBirth ? dateOfBirth.id : null,
			});

			if (apiIsOK(response)) {
				showToast({ body: t("Data field settings saved."), type: "success", title: t("Data field settings") });
				await queryClient.invalidateQueries({ queryKey: dataFieldService.keys.settings });
				await queryClient.invalidateQueries({ queryKey: systemMessageService.keys.all });
				return true;
			}

			return false;
		},
		[showToast, t, queryClient],
	);

	if (isLoading || !data) {
		return null;
	}

	return <DataFieldSettingsForm initialData={data} onSubmit={onSubmit} />;
};
