import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { IDashboard } from "~shared/types/dashboard";
import { dashboardKeys } from "./dashboardKeys";

export const dashboardUrls = {
	index: keyToUrl(dashboardKeys.index),
};

const index = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<IDashboard | null>>(dashboardUrls.index, config);

export const dashboardService = {
	index,
};
