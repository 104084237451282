import { IconButton } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGuestFilter } from "~shared/guestFilters";
import { BaseGuestList, GuestExportButton } from "~shared/guests";
import { BulkDeleteCompanionAction } from "~shared/guests/BulkDeleteCompanionAction";
import { useGlobalGuestView } from "~shared/providers/GlobalGuestViewProvider";
import { nominationManagerGuestKeys } from "../api/guests/nominationManagerGuestKeys";
import { nominationManagerGuestService } from "../api/guests/nominationManagerGuestService";
import { GuestListBulkEditAction } from "./GuestListBulkEditAction";

export const GuestList: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { globalGuestView } = useGlobalGuestView();
	const { filters, handleOpen, mapGuestFilterToGuestFilterRequest } = useGuestFilter();
	const guestFilterRequest = useMemo(
		() => mapGuestFilterToGuestFilterRequest(filters),
		[mapGuestFilterToGuestFilterRequest, filters],
	);

	return (
		<BaseGuestList
			queryKey={nominationManagerGuestKeys.filtered.concat(`${globalGuestView.id}`, JSON.stringify(filters))}
			fetcher={nominationManagerGuestService.filtered(globalGuestView.id, guestFilterRequest)}
			headerActions={
				<>
					<IconButton onClick={() => navigate("new")} color="primary" size="small" title={t("Add")} icon="plus" />
					<IconButton size="small" title={t("Guest filter")} onClick={handleOpen} icon="filter" />
					<GuestExportButton
						generateExport={nominationManagerGuestService.generateExport}
						filters={guestFilterRequest}
						currentGuestViewId={globalGuestView.id}
					/>
				</>
			}
			bulkEditActions={
				<>
					<GuestListBulkEditAction filters={guestFilterRequest} />
					<BulkDeleteCompanionAction filters={guestFilterRequest} />
				</>
			}
		/>
	);
};
