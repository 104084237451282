import {
	BaseUnsavedChangesPrompt,
	Button,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerFooterActions,
	DrawerHeader,
	DrawerHeading,
	Id,
	Loading,
} from "@dgs/core";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isDataField } from "~admin/registrationForms/structure/registrationFormDndUtils";
import { useStateWithDependency } from "~admin/shared/hooks";
import { IDataFieldIndexResource } from "~shared/types/dataField";
import { SortableChild, StructureSection } from "~shared/types/registrationFormTypes";
import { EditSortableDataFields } from "./EditSortableDataFields";
import { useRegistrationFormDataField } from "./useRegistrationFormDataField";

const StyledDrawer = styled(Drawer)`
	width: 600px;
	display: flex;
	flex-direction: column;
`;

interface Props {
	open: boolean;
	onClose: () => void;
	dataFields: IDataFieldIndexResource[];
	registrationFormId: string;
	registrationFormStepId: Id;
	registrationFormSection: StructureSection;
}

export const CreateRegistrationFormDataFieldsDrawer: FC<Props> = ({
	open,
	onClose,
	dataFields,
	registrationFormId,
	registrationFormStepId,
	registrationFormSection,
}) => {
	const { t } = useTranslation();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isDirty, setIsDirty] = useState(false);
	const [isPromptOpen, setIsPromptOpen] = useState(false);
	const activeChildrenFromSection = useMemo(
		() =>
			registrationFormSection.children.map((child) =>
				isDataField(child)
					? {
							id: child.dataFieldId,
							name: child.name,
							entityType: child.entityType,
							dataFieldType: child.type,
					  }
					: {
							id: `${child.blockId}-block:${child.id}`,
							referenceId: child.id,
							name: child.name,
							entityType: child.entityType,
							isRestrictedToVerticalAxis: true,
					  },
			),
		[registrationFormSection.children],
	);

	const [activeChildren, setActiveChildren] = useStateWithDependency(activeChildrenFromSection);

	const { syncRegistrationFormDataField } = useRegistrationFormDataField(
		registrationFormId,
		registrationFormStepId,
		registrationFormSection.sectionId,
	);

	const close = useCallback(
		(newChildren: SortableChild[]) => {
			setActiveChildren(newChildren);
			setIsDirty(false);
			onClose();
		},
		[onClose, setActiveChildren],
	);

	const handleChange = useCallback(
		(value: SortableChild[]) => {
			setIsDirty(true);
			setActiveChildren(value);
		},
		[setActiveChildren],
	);

	const handleClose = useCallback(() => {
		if (!isDirty) {
			close(activeChildrenFromSection);
		} else {
			setIsPromptOpen(true);
		}
	}, [activeChildrenFromSection, close, isDirty]);

	const onSubmit = useCallback(async () => {
		const result = await syncRegistrationFormDataField(
			activeChildren.map((sortableChild: SortableChild) =>
				sortableChild.entityType === "dataField"
					? {
							id: sortableChild.id,
							entityType: sortableChild.entityType,
					  }
					: {
							id: (sortableChild.id as string).split("-")[0],
							entityType: sortableChild.entityType,
							referenceId: sortableChild.referenceId,
					  },
			),
		);

		if (result) {
			close(activeChildren);
		}

		return result;
	}, [activeChildren, close, syncRegistrationFormDataField]);

	return (
		<>
			<StyledDrawer open={open} onClose={handleClose}>
				<DrawerHeader onClose={handleClose}>
					<DrawerHeading>{registrationFormSection.name}</DrawerHeading>
				</DrawerHeader>
				<DrawerBody>
					<EditSortableDataFields
						activeChildren={activeChildren}
						setActiveChildren={handleChange}
						initialChildren={dataFields.map((dataField) => ({
							id: dataField.id,
							name: dataField.name,
							entityType: "dataField",
							dataFieldType: dataField.type,
						}))}
					/>
				</DrawerBody>
				<DrawerFooter>
					<DrawerFooterActions />
					<Button
						color="primary"
						prefix={isSubmitting ? <Loading variant="tiny" /> : null}
						disabled={isSubmitting}
						onClick={async () => {
							setIsSubmitting(true);
							await onSubmit();
							setIsSubmitting(false);
						}}
					>
						{t("Submit")}
					</Button>
				</DrawerFooter>
			</StyledDrawer>
			<BaseUnsavedChangesPrompt
				open={isPromptOpen}
				action={() => close(activeChildrenFromSection)}
				onClose={() => setIsPromptOpen(false)}
			/>
		</>
	);
};
