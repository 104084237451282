import {
	Button,
	Card,
	DataList,
	DataListClickableRow,
	DataListColumnTitle,
	DataListHeader,
	DataListItem,
	FormattedDateTime,
	Icon,
} from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { DashboardEmailLog } from "~shared/types/email";

interface props {
	emails: DashboardEmailLog[];
}

export const DashboardEmailList: FC<props> = ({ emails }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<Card>
			<DataListHeader
				heading={t("Latest sent emails")}
				actions={
					<Button
						color="primary"
						size="small"
						prefix={<Icon icon="caret" />}
						onClick={() => navigate(`../emails/logs/list`)}
					>
						{t("View more")}
					</Button>
				}
			/>
			<DataList isEmpty={emails.length === 0} empty={t("No email logs available")}>
				<DataListColumnTitle>{t("To")}</DataListColumnTitle>
				<DataListColumnTitle>{t("Subject")}</DataListColumnTitle>
				<DataListColumnTitle>{t("Date")}</DataListColumnTitle>
				<DataListColumnTitle>{t("Attachments")}</DataListColumnTitle>
				{emails &&
					emails.map((email) => (
						<DataListClickableRow key={email.id} onClick={() => navigate(`../emails/logs/${email.id}`)}>
							<DataListItem>{email.to}</DataListItem>
							<DataListItem>{email.subject}</DataListItem>
							<DataListItem>
								<FormattedDateTime dateTime={email.date} />
							</DataListItem>
							<DataListItem>{email.hasAttachments ? t("Yes") : t("No")}</DataListItem>
						</DataListClickableRow>
					))}
			</DataList>
		</Card>
	);
};
