import { Id, LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios from "axios";
import { IInvoiceExportRequest, IInvoiceIndexResource, IInvoiceSendEmail } from "~admin/shared/types/invoice";
import { mapFetcherConfigToAxiosConfig } from "~shared/api/indexMapper";
import { IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";

export class InvoiceService {
	public keys = {
		index: ["invoices"],
		list: ["invoices", "list"],
		sendEmail: ["invoices", "send"],
		regenerate: (id: Id) => ["invoices", `${id}`, "regenerate"],
		filtered: (guestViewId: Id, filter: IGuestFilterFormValueEntry[]) => [
			"invoices",
			`${guestViewId}`,
			JSON.stringify(filter),
		],
		export: ["invoices", "export-files"],
	};

	protected urls = {
		index: keyToUrl(this.keys.index),
		sendEmail: keyToUrl(this.keys.sendEmail),
		regenerate: (id: Id) => keyToUrl(this.keys.regenerate(id)),
		export: keyToUrl(this.keys.export),
	};

	index = (config: ListFetcherConfig<any>) => {
		return axios.get<LaravelResponse<IInvoiceIndexResource[]>>(this.urls.index, mapFetcherConfigToAxiosConfig(config));
	};

	regenerate = (id: Id) => {
		return axios.post<LaravelResponse<any>>(this.urls.regenerate(id));
	};

	sendEmail = (values: IInvoiceSendEmail) => {
		return axios.post<LaravelResponse<any>>(this.urls.sendEmail, values);
	};

	filtered = (guestViewId: Id, filter: IGuestFilterFormValueEntry[], listFetcherConfig: ListFetcherConfig<any>) =>
		axios.post<LaravelResponse<IInvoiceIndexResource[]>>(
			this.urls.index,
			{
				guestViewId,
				filters: filter,
				sort: listFetcherConfig.sortParams
					? {
							guestAttributeId: listFetcherConfig.sortParams.column,
							direction: listFetcherConfig.sortParams.direction,
					  }
					: null,
			},
			{
				params: {
					...listFetcherConfig.pageParams,
					...listFetcherConfig.searchParams,
				},
			},
		);

	export = (request: IInvoiceExportRequest) => axios.post(this.urls.export, request);
}

export const invoiceService = new InvoiceService();
