import { JarvisAuthPages, NotAuthorizedPage } from "@dgs/core";
import React from "react";
import { useNominationManagerConfig } from "./useNominationManagerConfig";

export const NominationManagerAuthPages = () => {
	const { authUrl, htmlTitle } = useNominationManagerConfig();

	return (
		<JarvisAuthPages
			authUrl={authUrl}
			identityProvidersUrl={`${authUrl}/providers`}
			showPasswordReset={true}
			afterSuccessfulLoginUrl="/nomination-manager"
			notAuthorizedPage={<NotAuthorizedPage basePath="/nomination-manager" title={htmlTitle} redirect={-1} />}
		/>
	);
};
