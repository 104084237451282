import { BaseCrudOptionService, Id, keyToUrl } from "@dgs/core";
import { GuestFilterFormValues } from "~shared/guestFilters/guestFilterTypes";
import { IGuestFilter } from "~shared/types/guestFilter";
import { guestFilterKeys } from "./guestFilterKeys";

class GuestFilterService extends BaseCrudOptionService<
	IGuestFilter,
	IGuestFilter,
	IGuestFilter,
	GuestFilterFormValues
> {
	public keys = guestFilterKeys;

	protected urls = {
		options: keyToUrl(this.keys.options),
		index: keyToUrl(this.keys.index),
		details: (taxId: Id) => keyToUrl(this.keys.details(taxId)),
	};
}

export const guestFilterService = new GuestFilterService();
