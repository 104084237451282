import React from "react";
import { GuestFilterProvider } from "~shared/guestFilters";
import { Dashboard } from "./Dashboard";

export const DashboardPage = () => {
	return (
		<GuestFilterProvider listFilterKey="guestListFilter">
			<Dashboard />
		</GuestFilterProvider>
	);
};
