import { Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { hotelInquiryService } from "~shared/api/hotelInquiries/hotelInquiryService";

export const useHotelInquiryCountQuery = () => {
	const { data, isLoading } = useQuery({
		queryKey: hotelInquiryService.keys.inquiryCounts,
		queryFn: () => hotelInquiryService.inquiryCounts().then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};

export const useHotelInquiryQuery = (id: Id) => {
	const { data, isLoading } = useQuery({
		queryKey: hotelInquiryService.keys.details(id),
		queryFn: () => hotelInquiryService.show(id).then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};
