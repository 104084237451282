import { useToasts } from "@dgs/core";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

interface Props {
	hasErrors: boolean;
}

export const TranslationErrorToasts: FC<Props> = ({ hasErrors }) => {
	const { showToast } = useToasts();
	const { t } = useTranslation();

	useEffect(() => {
		if (hasErrors) {
			showToast({
				body: t("Not all required fields are filled in the fallback language"),
				title: t("Required fields"),
				type: "error",
			});
		}
	}, [hasErrors, showToast, t]);

	return null;
};
