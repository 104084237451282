import { CheckboxField, DateField, FCC, TextField } from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { PricesField } from "~admin/hotels/roomTypes/contingent/PricesField";
import { IRoomContingentForm, IRoomContingentPrice } from "~shared/types/roomContingent";
import { IRoomTypeResource } from "~shared/types/roomType";

interface Props {
	roomContingent: IRoomContingentForm;
	roomType: IRoomTypeResource;
	onSubmit: (roomContingent: IRoomContingentForm) => void;
}

export const RoomContingentForm: FCC<Props> = ({ children, roomContingent, roomType, onSubmit }) => {
	const { t } = useTranslation();
	const validate = useCallback(
		(values: IRoomContingentForm) => {
			const errors: { [K in keyof IRoomContingentForm]?: any } = {};

			if (values.contingent === null) {
				errors.contingent = t("Field is required");
			}

			if (values.contingent === 0 && !values.overbookable) {
				errors.contingent = t("The value can only be 0 when overbookable is enabled");
			}

			const priceErrors: { [K in keyof IRoomContingentPrice]?: any }[] = [];

			values.prices.forEach((price, idx) => {
				if (price.purchasePrice <= 0) {
					priceErrors[idx] = priceErrors[idx]
						? { ...priceErrors[idx], purchasePrice: t("Price needs to be larger than 0") }
						: { purchasePrice: t("Price needs to be larger than 0") };
				}
				if (price.sellingPrice <= 0) {
					priceErrors[idx] = priceErrors[idx]
						? { ...priceErrors[idx], sellingPrice: t("Price needs to be larger than 0") }
						: { sellingPrice: t("Price needs to be larger than 0") };
				}
				if (price.occupancy < 1) {
					priceErrors[idx] = priceErrors[idx]
						? { ...priceErrors[idx], occupancy: t("Occupancy needs to be larger than 0") }
						: { occupancy: t("Occupancy needs to be larger than 0") };
				} else if (price.occupancy > roomType.capacity) {
					priceErrors[idx] = priceErrors[idx]
						? {
								...priceErrors[idx],
								occupancy: t(
									"Occupancy needs to be smaller than or equal to the number of maximum persons the room type has.",
								),
						  }
						: {
								occupancy: t(
									"Occupancy needs to be smaller than or equal to the number of maximum persons the room type has.",
								),
						  };
				}
			});

			if (priceErrors.filter((x) => x).length > 0) {
				errors.prices = priceErrors;
			}

			return errors;
		},
		[roomType.capacity, t],
	);

	return (
		<Formik initialValues={roomContingent} onSubmit={onSubmit} validate={validate}>
			<Form>{children}</Form>
		</Formik>
	);
};

export const RoomContingentFormFields: FC<{ disabledDate?: boolean }> = ({ disabledDate = false }) => {
	const { t } = useTranslation();

	return (
		<>
			<DateField label={t("Date")} name="date" disabled={disabledDate} />
			<TextField label={t("Contingent")} name="contingent" type="number" required={true} />
			<PricesField name="prices" />
			<CheckboxField label={t("Overbookable")} name="overbookable" />
		</>
	);
};
