import { ContextMenu, ContextMenuDeleteItem } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { companionService } from "~shared/api/guests/companionService";
import { useGuestFilter } from "~shared/guestFilters";
import { BaseCompanionList } from "~shared/guests/companion/BaseCompanionList";
import { useCompanionUtils } from "~shared/guests/companion/useCompanionUtils";
import { useGuestDetails } from "~shared/guests/details/GuestDetailProvider";
import { useGuestView } from "~shared/providers/useGuestView";

export const CompanionList: FC = () => {
	const { t } = useTranslation();
	const { guest } = useGuestDetails();
	const { detachCompanion } = useCompanionUtils();
	const { filters, mapGuestFilterToGuestFilterRequest } = useGuestFilter();
	const { currentGuestView } = useGuestView();

	return (
		guest && (
			<BaseCompanionList
				queryKey={companionService.keys.companionsFiltered(filters, guest.id).concat(`${currentGuestView.id}`)}
				fetcher={companionService.indexByParent(
					currentGuestView.id,
					mapGuestFilterToGuestFilterRequest(filters),
					guest.id,
				)}
				guest={guest}
				contextMenu={(companion) => (
					<ContextMenu>
						<ContextMenuDeleteItem
							action={() => detachCompanion(guest.id, companion.id)}
							label={t("Detach companion")}
							title={t("Detach companion")}
							heading={t("Detach companion")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to detach this companion?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				)}
			/>
		)
	);
};
