import { Card, CardHeader, CardHeading, Loading, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
	nominationModuleSettingsKeys,
	nominationModuleSettingsService,
	useNominationModuleSettingsQuery,
} from "~admin/shared/api/nominationModuleSettings";
import { PMBlockMetaProvider } from "~shared/blocks/PMBlockMetaProvider";
import { INominationModuleSettings, INominationModuleSettingsRequest } from "~shared/types/nominationModuleSettings";
import { SettingsForm } from "./SettingsForm";

export const Settings: FC = () => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { data: settings } = useNominationModuleSettingsQuery();

	const handleSubmit = useCallback(
		async (values: INominationModuleSettings) => {
			const request: INominationModuleSettingsRequest = {
				guestViewId: values.guestView.id,
				startPageBlockId: values.startPage?.id || null,
				showEmails: values.showEmails,
				requiredGuestAttributeIds: values.requiredGuestAttributeIds,
			};

			const response = await nominationModuleSettingsService.update(request);

			if (apiIsOK(response)) {
				showToast({ body: t("Settings were saved."), title: t("Save settings"), type: "success" });
				await queryClient.invalidateQueries({ queryKey: nominationModuleSettingsKeys.index });
				return true;
			}

			return false;
		},
		[queryClient, showToast, t],
	);

	if (!settings) return <Loading />;

	return (
		<PMBlockMetaProvider>
			<Card>
				<CardHeader>
					<CardHeading>{t("Settings")}</CardHeading>
				</CardHeader>
				<SettingsForm settings={settings} onSubmit={handleSubmit} />
			</Card>
		</PMBlockMetaProvider>
	);
};
