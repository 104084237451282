import { useIsAddonEnabled } from "@dgs/core";
import {
	DndContext,
	DragEndEvent,
	DragOverEvent,
	DragOverlay,
	DragStartEvent,
	KeyboardSensor,
	PointerSensor,
	closestCorners,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import React, { FC, useEffect, useState } from "react";
import {
	RegistrationFormPage,
	RegistrationFormShopPage,
	SectionChildUpdate,
	StructureSection,
	StructureSectionChild,
	StructureStep,
} from "~shared/types/registrationFormTypes";
import { RowContainer } from "./common/RowContainer";
import { StructureWrapper } from "./common/StructureWrapper";
import {
	activeIsSectionChild,
	determineParentStepChildrenAndSteps,
	findActiveElementsBySectionChildId,
	findSectionBySectionChildId,
	findStepBySectionChildId,
	getDataFieldIdFromId,
	handleSectionChildInsideSameSectionMove,
	handleSectionChildToAnotherStepChildMove,
	isDataField,
	isSection,
	isSectionChild,
	isSectionChildInsideSameSectionMove,
	isSectionChildToAnotherStepChildMove,
	revertId,
} from "./registrationFormDndUtils";
import { useRegistrationFormStructure } from "./RegistrationFormStructureProvider";
import { SectionChild } from "./sectionChildren/SectionChild";
import { SortableSectionChild } from "./sectionChildren/SortableSectionChild";
import { DroppableStepChild } from "./stepChildren/DroppableStepChild";
import { CreateStepAction } from "./steps/CreateStepAction";
import { RegistrationFormLandingStep } from "./steps/RegisatrationFormLandingStep";
import { RegistrationFormSummaryStep } from "./steps/RegisatrationFormSummaryStep";
import { RegistrationFormShopStep } from "./steps/shop/RegistrationFormShopStep";
import { Step } from "./steps/Step";

interface Props {
	landingStep: RegistrationFormPage | null;
	initialSteps: StructureStep[];
	summaryStep: RegistrationFormPage | null;
	shopStep: RegistrationFormShopPage | null;
}

export const RegistrationFormStructureOverview: FC<Props> = ({ initialSteps, landingStep, summaryStep, shopStep }) => {
	const { registrationFormId, moveSectionChild } = useRegistrationFormStructure();
	const [collidingDataField, setCollidingDataField] = useState<StructureSectionChild | null>(null);
	const [activeElements, setActiveElements] = useState<{
		step: StructureStep;
		section: StructureSection;
		sectionChild: StructureSectionChild;
	} | null>(null);
	const [steps, setSteps] = useState(initialSteps);
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);
	const isShopAddonEnabled = useIsAddonEnabled("shop");

	useEffect(() => {
		setSteps(initialSteps);
	}, [initialSteps]);

	const handleDragStart = ({ active }: DragStartEvent) => {
		if (isSectionChild(active)) {
			setActiveElements(findActiveElementsBySectionChildId(steps, active));
		}
	};

	const handleDragOver = (e: DragOverEvent) => {
		if (activeIsSectionChild(e)) {
			const parents = determineParentStepChildrenAndSteps(e, steps);

			if (isSectionChildToAnotherStepChildMove(parents)) {
				const colliding = parents.overSection.children
					.filter(isDataField)
					.find((dataField) => dataField.dataFieldId === getDataFieldIdFromId(e.active.id as string));

				setCollidingDataField(colliding || null);
				if (!colliding) {
					setSteps(handleSectionChildToAnotherStepChildMove(parents, e));
				}
			}
		}
	};

	const handleDragEnd = async (event: DragEndEvent) => {
		if (activeElements !== null && isSectionChildInsideSameSectionMove(steps, event)) {
			setSteps((current) => {
				const newSteps = handleSectionChildInsideSameSectionMove(current, event);
				const toStep = findStepBySectionChildId(newSteps, event.active.id);
				if (toStep === undefined) {
					throw new Error("Should not happen");
				}

				const toSection = findSectionBySectionChildId(toStep.children.filter(isSection), event.active.id);
				if (toSection === undefined) {
					throw new Error("Should not happen");
				}

				const location: SectionChildUpdate = {
					childToMove: activeElements.sectionChild,
					from: { stepId: revertId(activeElements.step.id), sectionId: activeElements.section.sectionId },
					to: { stepId: revertId(toStep.id), sectionId: toSection.sectionId },
					children: toSection.children || [],
				};

				moveSectionChild(location);

				return newSteps;
			});
		}

		setCollidingDataField(null);
		setActiveElements(null);
	};

	return (
		<>
			<StructureWrapper>
				<RegistrationFormLandingStep
					registrationFormId={registrationFormId}
					block={landingStep?.block || null}
					consents={landingStep?.consents || []}
				/>
				<DndContext
					sensors={sensors}
					collisionDetection={closestCorners}
					onDragEnd={handleDragEnd}
					onDragOver={handleDragOver}
					onDragStart={handleDragStart}
				>
					{steps.map((step) => (
						<Step key={step.id} registrationFormId={registrationFormId} step={step}>
							<RowContainer>
								{step.children.map((stepChild) => (
									<DroppableStepChild
										key={stepChild.id}
										registrationFormId={registrationFormId}
										stepId={step.id}
										stepChild={stepChild}
									>
										{isSection(stepChild) && (
											<SortableContext items={stepChild.children} strategy={verticalListSortingStrategy}>
												<RowContainer>
													{stepChild.children.map((child) => (
														<SortableSectionChild
															key={child.id}
															registrationFormId={registrationFormId}
															stepId={step.id}
															section={stepChild}
															sectionChild={child}
															isColliding={!!collidingDataField && child.id === collidingDataField.id}
														/>
													))}
												</RowContainer>
											</SortableContext>
										)}
									</DroppableStepChild>
								))}
							</RowContainer>
						</Step>
					))}
					<DragOverlay>
						{activeElements ? (
							<SectionChild
								registrationFormId={registrationFormId}
								stepId={activeElements.step.id}
								section={activeElements.section}
								sectionChild={activeElements.sectionChild}
								isActive={true}
							/>
						) : null}
					</DragOverlay>
				</DndContext>
				{isShopAddonEnabled && shopStep && (
					<RegistrationFormShopStep
						formId={registrationFormId}
						shopItems={shopStep.shopItems}
						paymentProviders={shopStep.paymentProviders}
					/>
				)}
				<RegistrationFormSummaryStep
					registrationFormId={registrationFormId}
					block={summaryStep?.block || null}
					consents={summaryStep?.consents || []}
				/>
			</StructureWrapper>
			<CreateStepAction />
		</>
	);
};
