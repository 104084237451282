import { Id, apiIsOK, defaultRemoteConfigKey, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { nominationModuleSettingsKeys } from "~admin/shared/api/nominationModuleSettings";
import { guestKeys } from "~shared/api/guests";
import { guestViewService } from "~shared/api/guestViews";
import { useGlobalGuestView } from "~shared/providers/GlobalGuestViewProvider";
import { GuestViewConfigFormValues, GuestViewConfigRequest } from "~shared/types/guestView";

interface UseGuestViewForm {
	deleteGuestView: (id: Id) => Promise<boolean>;
	createGuestView: (guestView: GuestViewConfigFormValues) => Promise<boolean>;
	updateGuestView: (id: Id, guestView: GuestViewConfigFormValues) => Promise<boolean>;
}

const mapToRequest = (guestView: GuestViewConfigFormValues): GuestViewConfigRequest => ({
	name: guestView.name,
	guestAttributeIds: guestView.guestAttributes.map((a) => a.id),
});

export const useGuestViewForm = (): UseGuestViewForm => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { globalGuestView, setGlobalGuestView } = useGlobalGuestView();

	const createGuestView = useCallback(
		async (guestView: GuestViewConfigFormValues) => {
			const res = await guestViewService.store(mapToRequest(guestView));

			if (apiIsOK(res)) {
				await queryClient.invalidateQueries({ queryKey: guestViewService.keys.list });
				await queryClient.invalidateQueries({ queryKey: guestKeys.index });
				await queryClient.invalidateQueries({ queryKey: defaultRemoteConfigKey });
				showToast({ body: t("Guest view was saved."), title: t("Save guest view"), type: "success" });
				return true;
			}

			return false;
		},
		[showToast, t, queryClient],
	);

	const updateGuestView = useCallback(
		async (id: Id, guestView: GuestViewConfigFormValues) => {
			const res = await guestViewService.update(id, mapToRequest(guestView));

			if (apiIsOK(res)) {
				if (globalGuestView.id === id) {
					setGlobalGuestView(res.data.data);
				}
				await queryClient.invalidateQueries({ queryKey: guestViewService.keys.index });
				await queryClient.invalidateQueries({ queryKey: guestKeys.index });
				await queryClient.invalidateQueries({ queryKey: defaultRemoteConfigKey });
				await queryClient.invalidateQueries({ queryKey: nominationModuleSettingsKeys.index });
				showToast({ body: t("Guest view was saved."), title: t("Save guest view"), type: "success" });
				return true;
			}

			return false;
		},
		[globalGuestView.id, queryClient, setGlobalGuestView, showToast, t],
	);

	const deleteGuestView = useCallback(
		async (id: Id) => {
			const res = await guestViewService.destroy(id);
			if (apiIsOK(res)) {
				showToast({
					body: t("Guest view was successfully deleted."),
					title: t("Delete guest view"),
					type: "success",
				});
				if (globalGuestView.id === id) {
					setGlobalGuestView(res.data.data);
				}
				await queryClient.invalidateQueries({ queryKey: guestViewService.keys.list });
				await queryClient.invalidateQueries({ queryKey: guestViewService.keys.options, refetchType: "all" });
				await queryClient.invalidateQueries({ queryKey: defaultRemoteConfigKey });
				return true;
			}
			return false;
		},
		[showToast, t, queryClient, globalGuestView, setGlobalGuestView],
	);

	return { createGuestView, updateGuestView, deleteGuestView };
};
