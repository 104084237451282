import { ActionFormState, ActionTypes } from "~shared/types/actions";
import { RuleFormState } from "~shared/types/rules";
import { RuleSet, RuleSetType } from "~shared/types/ruleSet";

export const emptyRule: RuleFormState = {
	id: -1,
	type: null,
	sectionDataField: null,
	value: "",
};

export const emptyActionCondition: ActionFormState = {
	id: -1,
	type: ActionTypes.SHOW_DATA_FIELD,
	value: null,
};

export const emptyActionAutomation: ActionFormState = {
	id: -1,
	type: ActionTypes.ATTACH_TAG,
	value: null,
};

export const emptyCondition: RuleSet<RuleFormState, ActionFormState> = {
	id: -1,
	type: RuleSetType.Condition,
	isAndLinked: false,
	description: "",
	color: "",
	registrationFormId: -1,
	rules: [emptyRule],
	actions: [emptyActionCondition],
};

export const emptyAutomation: RuleSet<RuleFormState, ActionFormState> = {
	id: -1,
	type: RuleSetType.Automation,
	isAndLinked: false,
	description: "",
	color: "",
	registrationFormId: -1,
	rules: [emptyRule],
	actions: [emptyActionAutomation],
};
