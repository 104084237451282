import React, { FC } from "react";
import { OrdersList } from "~admin/shop/orders/OrdersList";
import { GuestFilterDrawer, GuestFilterProvider } from "~shared/guestFilters";

export const OrdersPage: FC = () => {
	return (
		<GuestFilterProvider listFilterKey="ordersListGuestFilter">
			<OrdersList />
			<GuestFilterDrawer />
		</GuestFilterProvider>
	);
};
