import { SingleSelectField } from "@dgs/core";
import { useField } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ImportableAttributeOption } from "~shared/types/guestImport";
import { TImportProviderType } from "~shared/types/importProvider";

interface Props {
	name: string;
	label: string;
	options: ImportableAttributeOption[];
	disabled?: boolean;
	importProviderType: TImportProviderType;
}

export const ImportableAttributeSelect: FC<Props> = ({ name, label, options, disabled }) => {
	const { t } = useTranslation();
	const [field] = useField<ImportableAttributeOption>(name);
	const getLabel = useCallback(
		(option: ImportableAttributeOption) => {
			if (option === "guest-id") {
				return t("Guest Id");
			} else {
				if (option.required) {
					return `${option.name}*`;
				} else {
					return option.name;
				}
			}
		},
		[t],
	);

	return (
		<SingleSelectField
			name={name}
			label={label}
			disabled={disabled}
			options={options}
			getLabel={getLabel}
			autoComplete="off"
			clearable={true}
			description={
				field.value === "guest-id"
					? t("Assigning this column to Guest Id will overwrite existing guest data.")
					: undefined
			}
		/>
	);
};
