import { useQuery } from "@tanstack/react-query";
import { TPaymentProviderType } from "~shared/types/paymentProvider";
import { paymentProviderService } from "./paymentProviderService";

const initial: TPaymentProviderType[] = [];

export const usePaymentProviderTypeOptionsQuery = () => {
	const { data, ...rest } = useQuery({
		queryKey: paymentProviderService.keys.typeOptions,
		queryFn: () => paymentProviderService.typeOptions().then((r) => r.data.data),
	});

	return {
		typeOptions: data || initial,
		...rest,
	};
};
