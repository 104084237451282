import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { GuestViewAttributeResource } from "~shared/types/guestView";

export const guestAttributeKeys = {
	index: ["guest-attributes"],
};

export const guestAttributeUrls = {
	index: keyToUrl(guestAttributeKeys.index),
};

const index = () => axios.get<LaravelResponse<GuestViewAttributeResource[]>>(guestAttributeUrls.index);

export const guestAttributeService = {
	index,
};
