import { Step, StepStatus, Stepper } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useMatch } from "react-router-dom";
import styled from "styled-components";
import { useEmptyHotel } from "~admin/hotels/useEmptyHotel";
import { useEmptyStepperRoomType } from "~admin/hotels/useEmptyRoom";
import { useHotelStepper } from "./HotelStepperProvider";

const StyledStepper = styled(Stepper)`
	margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const HotelStepper: FC = () => {
	const { hotel, roomType, roomContingent } = useHotelStepper();
	const emptyHotel = useEmptyHotel();
	const emptyRoomType = useEmptyStepperRoomType();
	const { t } = useTranslation();
	const routeMatch = useMatch("admin/hotel-management/create-hotel/:path");
	const path = routeMatch?.params.path ?? "/";
	const steps = [
		{ status: "inactive", label: t("Create hotel") },
		{ status: "inactive", label: t("Create room type and contingent") },
		{ status: "inactive", label: t("Set details") },
	];

	if (path === "room-types") {
		steps[0].status = JSON.stringify(hotel) !== JSON.stringify(emptyHotel) ? "done" : "active";
		steps[1].status = "active";
		steps[2].status = roomContingent.length ? "done" : "inactive";
	} else if (path === "details") {
		steps[0].status = JSON.stringify(hotel) !== JSON.stringify(emptyHotel) ? "done" : "active";
		steps[1].status = JSON.stringify(roomType) !== JSON.stringify(emptyRoomType) ? "done" : "active";
		steps[2].status = "active";
	} else {
		steps[0].status = "active";
		steps[1].status = JSON.stringify(roomType) !== JSON.stringify(emptyRoomType) ? "done" : "inactive";
		steps[2].status = roomContingent.length ? "done" : "inactive";
	}

	return (
		<>
			<StyledStepper>
				{steps.map((x, idx) => (
					<Step key={idx} index={idx + 1} status={x.status as StepStatus}>
						{x.label}
					</Step>
				))}
			</StyledStepper>
			<Outlet />
		</>
	);
};
