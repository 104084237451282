import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useGuestFilter } from "~shared/guestFilters";
import { IActiveGuestFilterEntry } from "~shared/guestFilters/guestFilterTypes";
import { DashboardGuestStatus } from "~shared/types/dashboard";
import { DashboardCardGrid } from "./DashboardCard";
import { GroupInfo, StatusInfo } from "./StatusInfo";

interface Props {
	guestStatus: DashboardGuestStatus;
}

interface FilterOptions {
	registrations: IActiveGuestFilterEntry[];
	cancellations: IActiveGuestFilterEntry[];
	pendingFeedbacks: IActiveGuestFilterEntry[];
}

export const DashboardGuestInfo: FC<Props> = ({ guestStatus }) => {
	const { t } = useTranslation();
	const { guestAttributes } = useGuestFilter();

	const filters: FilterOptions = useMemo(() => {
		const statusAttribute = guestAttributes.find((attribute) => attribute.entityType === "status");

		if (!statusAttribute) return { registrations: [], cancellations: [], pendingFeedbacks: [] };

		return {
			registrations: [
				{
					guestAttributeId: statusAttribute.id,
					operator: "WHERE_IN",
					value: ["Registered", "RegistrationChanged", "CheckedIn", "ConfirmationSent"],
				},
			],
			cancellations: [
				{
					guestAttributeId: statusAttribute.id,
					operator: "WHERE_IN",
					value: ["RegistrationCancelled", "InvitationDeclined"],
				},
			],
			pendingFeedbacks: [
				{
					guestAttributeId: statusAttribute.id,
					operator: "WHERE_IN",
					value: ["Invited", "Reminded"],
				},
			],
		};
	}, [guestAttributes]);

	return (
		<DashboardCardGrid>
			<StatusInfo
				title={t("Registrations")}
				data={guestStatus.registrations}
				iconName="check"
				iconColor="success"
				filter={filters.registrations}
			/>
			<StatusInfo
				title={t("Cancellations")}
				data={guestStatus.cancellations}
				iconName="warning"
				iconColor="danger"
				filter={filters.cancellations}
			/>
			<StatusInfo
				title={t("Pending feedbacks")}
				data={guestStatus.pendingFeedbacks}
				iconName="duration"
				iconColor="warning"
				filter={filters.pendingFeedbacks}
			/>
			<GroupInfo title={t("Registrations by groups")} data={guestStatus.registrationsByGroups} />
		</DashboardCardGrid>
	);
};
