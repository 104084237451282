import { Id, LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { emailKeys } from "~shared/api/emails/emailKeys";
import { mapFetcherConfigToAxiosConfig, mapSortParamsToGuestListSortParams } from "~shared/api/indexMapper";
import { IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";
import {
	BaseEmailLog,
	DetailedEmailLog,
	EmailListResource,
	EmailSaveRequest,
	EmailSaveResource,
	SendOut,
	SendOutRequest,
} from "~shared/types/email";
import { GuestIndexResource } from "~shared/types/newGuestTypes";

export const emailUrls = {
	index: keyToUrl(emailKeys.index),
	indexAll: keyToUrl(emailKeys.indexAll),
	options: keyToUrl(emailKeys.options),
	details: (mailId: Id) => keyToUrl(emailKeys.details(mailId)),
	sendOuts: {
		index: keyToUrl(emailKeys.sendOuts.index),
		details: (sendOutId: Id) => keyToUrl(emailKeys.sendOuts.details(sendOutId)),
		filteredGuests: (status: string, sendOutId: Id) =>
			keyToUrl(emailKeys.sendOuts.filteredStatusGuests(status, sendOutId)),
	},
	logs: {
		index: keyToUrl(emailKeys.logs.index),
		details: (logId: Id) => keyToUrl(emailKeys.logs.details(logId)),
		resend: (logId: Id) => keyToUrl(emailKeys.logs.resend(logId)),
	},
};

const index = (config: ListFetcherConfig) =>
	axios.get<LaravelResponse<EmailListResource[]>>(emailUrls.index, mapFetcherConfigToAxiosConfig(config));

const indexAll = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<EmailListResource[]>>(emailUrls.indexAll, config);

const options = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<EmailListResource[]>>(emailUrls.options, config);
const details = (mailId: Id) => axios.get<LaravelResponse<EmailSaveResource>>(emailUrls.details(mailId));
const store = (email: EmailSaveRequest) => axios.post<LaravelResponse<EmailSaveResource>>(emailUrls.index, email);
const update = (mailId: Id, email: EmailSaveRequest) =>
	axios.put<LaravelResponse<EmailSaveResource>>(emailUrls.details(mailId), email);
const remove = (mailId: Id) => axios.delete(emailUrls.details(mailId));

const sendOutIndex = (config: ListFetcherConfig) =>
	axios.get<LaravelResponse<SendOut[]>>(emailUrls.sendOuts.index, mapFetcherConfigToAxiosConfig(config));

const createSendOut = (sendOutRequest: SendOutRequest) =>
	axios.post<LaravelResponse<SendOut>>(emailUrls.sendOuts.index, sendOutRequest);

const removeSendOut = (sendOut: SendOut) =>
	axios.delete<LaravelResponse<SendOut>>(emailUrls.sendOuts.details(sendOut.id));

const logs = (config: ListFetcherConfig) =>
	axios.get<LaravelResponse<BaseEmailLog[]>>(emailUrls.logs.index, mapFetcherConfigToAxiosConfig(config));

const logDetails = (logId: Id) => axios.get<LaravelResponse<DetailedEmailLog>>(emailUrls.logs.details(logId));

const resend = (logId: Id) => axios.get<LaravelResponse<DetailedEmailLog>>(emailUrls.logs.resend(logId));

const sendOutFilteredGuests =
	(status: string, sendOutId: Id, guestViewId: Id, filters: IGuestFilterFormValueEntry[]) =>
	(config: ListFetcherConfig) =>
		axios.post<LaravelResponse<GuestIndexResource[]>>(
			emailUrls.sendOuts.filteredGuests(status, sendOutId),
			{ guestViewId, filters, ...mapSortParamsToGuestListSortParams(config.sortParams) },
			mapFetcherConfigToAxiosConfig(config),
		);

export const emailService = {
	indexAll,
	index,
	store,
	update,
	options,
	details,
	remove,
	sendOuts: {
		index: sendOutIndex,
		post: createSendOut,
		remove: removeSendOut,
		sendOutFilteredGuests,
	},
	logs: {
		index: logs,
		details: logDetails,
		resend: resend,
	},
};
