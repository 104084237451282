import { apiIsOK, useToasts } from "@dgs/core";
import { menuKeys } from "@dgs/core/lib/modules/menu/menuService";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { MenuItemDrawer } from "~admin/menu/MenuItemDrawer";
import { menuItemService } from "~admin/shared/api/menu/menuItemService";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { IMenuItemFormState, IMenuItemRequest } from "~shared/types/menuItem";

interface Props {
	open: boolean;
	onClose: () => void;
}
export const MenuItemCreateDrawer: FC<Props> = ({ open, onClose }) => {
	const { t } = useTranslation();
	const { locales } = useContentLocale();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();

	const initialValue: IMenuItemFormState = useMemo(() => {
		return {
			previewType: null,
			previewUntil: null,
			previewUrl: "",
			visibility: "all",
			type: "INTERNAL",
			url: "",
			name: "",
			previewBlock: null,
			block: null,
			children: [],
			translations: locales.reduce(
				(previousValue, currentValue) => ({
					...previousValue,
					[currentValue]: {
						name: "",
					},
				}),
				{},
			),
		};
	}, [locales]);

	const handleCreate = useCallback(
		async (menuItem: IMenuItemRequest) => {
			const response = await menuItemService.store(menuItem);

			if (apiIsOK(response)) {
				showToast({ body: t("Menu item was saved."), title: t("Save menu item"), type: "success" });
				await queryClient.invalidateQueries({ queryKey: menuKeys.index });
				await queryClient.invalidateQueries({ queryKey: menuItemService.keys.index });
				return true;
			}
			return false;
		},
		[queryClient, showToast, t],
	);

	return (
		<MenuItemDrawer
			title={t("Create menu item")}
			open={open}
			onClose={onClose}
			menuItem={initialValue}
			handleSubmit={handleCreate}
		/>
	);
};
