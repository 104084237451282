import { FCC } from "@dgs/core";
import React from "react";
import styled from "styled-components";

const Label = styled.div`
	${({ theme }) => theme.forms.label};
	white-space: normal;
`;

interface Props {
	label: string;
}

export const ValueViewer: FCC<Props> = ({ label, children }) => {
	return (
		<div>
			<Label>{label}</Label>
			<span>{children}</span>
		</div>
	);
};
