import { Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { usersKeys } from "~admin/shared/api/users/usersKeys";
import { usersService } from "~admin/shared/api/users/usersService";

export const useUserQuery = (userId: Id) => {
	return useQuery({
		queryKey: usersKeys.details(userId),
		queryFn: () => usersService.get(userId).then((r) => r.data.data),
	});
};
