import { useDynamicConfig } from "@dgs/core";
import { useEffect } from "react";
import { useLocalState } from "~admin/shared/useLocalState";

const removeBugfix = (release: string): string => {
	const [major, minor] = release.split(".");
	return `${major}.${minor}`;
};
export const useReleaseVersion = () => {
	const { release } = useDynamicConfig();
	const localRelease = localStorage.getItem("release") ?? "0.0.0";
	const [showReleaseNotice, setShowReleaseNotice] = useLocalState("showReleaseNotice", false);

	useEffect(() => {
		if (release) {
			const localFeatureVersion = removeBugfix(localRelease);
			const currentFeatureVersion = removeBugfix(release);

			if (release !== localRelease) {
				localStorage.setItem("release", release);
			}

			if (currentFeatureVersion > localFeatureVersion) {
				setShowReleaseNotice(true);
			}
		}
	}, [localRelease, release, setShowReleaseNotice]);

	return { showReleaseNotice, setShowReleaseNotice };
};
