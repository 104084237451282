import {
	DropdownItem,
	LinkDropdownItem,
	UserService,
	getUserDropdown,
	useAuth,
	useDynamicConfig,
	useMeQuery,
} from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const BaseUserDropdown = getUserDropdown();

export const UserDropDown: FC = () => {
	const { userUrl } = useDynamicConfig();
	const service = new UserService(userUrl);
	const { data: user } = useMeQuery(service);
	const { logout } = useAuth();
	const { t } = useTranslation();

	if (!user) {
		return null;
	}

	return (
		<BaseUserDropdown name={user.name} company={user.company} image={user.image}>
			<LinkDropdownItem to="/admin/profile">{t("My profile")}</LinkDropdownItem>
			<DropdownItem onClick={logout}>{t("Log out")}</DropdownItem>
		</BaseUserDropdown>
	);
};
