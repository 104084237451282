import React, { FC } from "react";
import { GuestFilterDrawer, GuestFilterProvider } from "~shared/guestFilters";
import { InvoiceList } from "./InvoiceList";

export const InvoicesPage: FC = () => {
	return (
		<GuestFilterProvider listFilterKey="invoicesListGuestFilter">
			<InvoiceList />
			<GuestFilterDrawer />
		</GuestFilterProvider>
	);
};
