import { useFormatDate, useFormatDateTime } from "@dgs/core";
import { IDataFieldOptionOptionResource } from "~shared/types/dataFieldOption";
import { DataFieldType } from "~shared/types/dataFieldType";
import { Rule, RuleTypes } from "~shared/types/rules";

export const getRuleValuePayload = (rule: Rule) => {
	switch (rule.type) {
		case RuleTypes.MIN:
		case RuleTypes.MAX:
		case RuleTypes.HAS_MAX_CHARS:
		case RuleTypes.HAS_MIN_CHARS:
			return rule.value as number;
		case RuleTypes.IS_REQUIRED:
		case RuleTypes.NOT_UPDATABLE:
		case RuleTypes.READ_ONLY:
			return null;
		case RuleTypes.MUST_CONTAIN:
		case RuleTypes.MUST_NOT_CONTAIN:
		case RuleTypes.HAS_NOT_VALUE:
		case RuleTypes.CONTAINS_ONE:
		case RuleTypes.NOT_CONTAINS_ONE:
		case RuleTypes.HAS_VALUE: {
			switch (rule.sectionDataField.dataField.type) {
				case DataFieldType.RADIO:
				case DataFieldType.SELECT_FIELD:
					return (rule.value as IDataFieldOptionOptionResource).id;
				case DataFieldType.CHECKBOX_GROUP:
				case DataFieldType.MULTI_SELECT_FIELD:
					return (rule.value as IDataFieldOptionOptionResource[]).map((x) => x.id);
				default:
					return rule.value as number | string | Date | boolean;
			}
		}
	}
};

export const useGetRuleValueText = () => {
	const formatDate = useFormatDate();
	const formatDateTime = useFormatDateTime();

	return (rule: Rule): number | string => {
		switch (rule.type) {
			case RuleTypes.NOT_CONTAINS_ONE:
			case RuleTypes.CONTAINS_ONE: {
				const maxNumberOfValues = 3;
				const numberOfValues = rule.value.length;
				const valuesAsString = rule.value.slice(0, maxNumberOfValues).join(", ");
				if (numberOfValues > maxNumberOfValues) {
					return `${valuesAsString} | +${numberOfValues - maxNumberOfValues}`;
				} else {
					return valuesAsString;
				}
			}
			case RuleTypes.MAX:
			case RuleTypes.MIN:
				switch (rule.sectionDataField.dataField.type) {
					case DataFieldType.DATE:
						return formatDate(rule.value as string);
					case DataFieldType.DATETIME:
						return formatDateTime(rule.value as string);
					default:
						return rule.value as string;
				}
			case RuleTypes.HAS_MAX_CHARS:
			case RuleTypes.HAS_MIN_CHARS:
				return rule.value as number;
			case RuleTypes.IS_REQUIRED:
			case RuleTypes.NOT_UPDATABLE:
			case RuleTypes.READ_ONLY:
				return "";
			case RuleTypes.MUST_CONTAIN:
			case RuleTypes.MUST_NOT_CONTAIN:
			case RuleTypes.HAS_NOT_VALUE:
			case RuleTypes.HAS_VALUE: {
				switch (rule.sectionDataField.dataField.type) {
					case DataFieldType.RADIO:
					case DataFieldType.SELECT_FIELD:
						return (rule.value as IDataFieldOptionOptionResource).value;
					case DataFieldType.CHECKBOX_GROUP:
					case DataFieldType.MULTI_SELECT_FIELD:
						return (rule.value as IDataFieldOptionOptionResource[]).map((x) => x.value).join(", ");
					case DataFieldType.CHECKBOX:
						return rule.value ? "true" : "false";
					case DataFieldType.DATE:
						return formatDate(rule.value as string);
					case DataFieldType.DATETIME:
						return formatDateTime(rule.value as string);
					default:
						return rule.value as number | string;
				}
			}
		}
	};
};

export const getRuleValueFromRequest = (rule: Rule): any => {
	switch (rule.type) {
		case RuleTypes.CONTAINS_ONE:
		case RuleTypes.HAS_MAX_CHARS:
		case RuleTypes.HAS_MIN_CHARS:
		case RuleTypes.MUST_CONTAIN:
		case RuleTypes.MUST_NOT_CONTAIN:
		case RuleTypes.HAS_NOT_VALUE:
		case RuleTypes.HAS_VALUE:
		case RuleTypes.MIN:
		case RuleTypes.MAX:
			return rule.value;
		case RuleTypes.NOT_UPDATABLE:
		case RuleTypes.IS_REQUIRED:
		case RuleTypes.READ_ONLY:
			return null;
	}
};
