import { CheckboxField, CrudDrawer, DateField, DateTimeField, EntityDrawerProps, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IWaveFormState } from "~shared/types/wave";

export const WaveDrawer: FC<EntityDrawerProps<IWaveFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IWaveFormState) => {
			const errors: { [K in keyof IWaveFormState]?: any } = {};

			if (!values.name) {
				errors.name = t("Required field");
			}

			if (values.startDate && values.endDate && values.endDate < values.startDate) {
				errors.endDate = t("Date must be after {{field}}", { field: t("Start date") });
			}

			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={({ values }) => (
				<>
					<TextField name="name" label={t("Name")} required />
					<DateField name="startDate" label={t("Start date")} />
					<DateField name="endDate" label={t("End date")} />
					<CheckboxField name="isReadonlyAfterDateTime" label={t("Is readonly after specific date")} />
					{values.isReadonlyAfterDateTime && <DateTimeField name="readonlyAfterDateTime" label={t("Date")} />}
				</>
			)}
		/>
	);
};
