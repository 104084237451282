import { IFile, Id } from "@dgs/core";

export interface PersonalizedDocumentOptionsResource {
	id: Id;
	name: string;
}

export interface PersonalizedDocumentIndexResource {
	id: Id;
	name: string;
	type: PersonalizedDocumentTypes;
}

export interface PersonalizedDocument {
	id: Id;
	type: PersonalizedDocumentTypes;
	translations: Record<string, TranslatableAttributes>;
}

interface TranslatableAttributes {
	name: string;
	html: string;
	backgroundImage: IFile | null;
}

interface TranslatableAttributesRequest {
	name: string;
	html: string;
	backgroundImageId: Id | null;
}

export enum PersonalizedDocumentTypes {
	CR80_LANDSCAPE = "CR80_LANDSCAPE",
	CR80_PORTRAIT = "CR80_PORTRAIT",
	A4_LANDSCAPE = "A4_LANDSCAPE",
	A4_PORTRAIT = "A4_PORTRAIT",
	A5_LANDSCAPE = "A5_LANDSCAPE",
	A5_PORTRAIT = "A5_PORTRAIT",
	A6_LANDSCAPE = "A6_LANDSCAPE",
	A6_PORTRAIT = "A6_PORTRAIT",
}

export interface PersonalizedDocumentRequest {
	id: Id;
	type: PersonalizedDocumentTypes;
	translations: Record<string, TranslatableAttributesRequest>;
}

export interface PersonalizedDocumentExportRequest {
	guestIds: Id[];
	personalizedDocumentId: Id;
	locale: string;
}
