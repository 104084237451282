import { ContextMenu, ContextMenuDeleteItem, Id } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRegistrationFormBlock } from "./useRegistrationFormBlock";

interface Props {
	registrationFormId: string;
	registrationFormStepId: Id;
	registrationFormSectionId?: Id;
	registrationFormBlockIdentifier: Id;
}

export const RegistrationFormBlockContextMenu: FC<Props> = ({
	registrationFormId,
	registrationFormStepId,
	registrationFormSectionId,
	registrationFormBlockIdentifier,
}) => {
	const { t } = useTranslation();
	const { detachRegistrationFormBlock } = useRegistrationFormBlock(
		registrationFormId,
		registrationFormStepId,
		registrationFormSectionId,
	);
	return (
		<>
			<ContextMenu displayShortcutsAs="icon" size="small" flat={true}>
				<ContextMenuDeleteItem
					label={t("Detach registration form block")}
					title={t("Detach registration form block")}
					action={() => detachRegistrationFormBlock(registrationFormBlockIdentifier)}
					heading={t("Detach block")}
					labels={{ close: t("Close"), confirm: t("Confirm") }}
					shortcut="danger"
				>
					{t("Are you sure you want to detach this registration form block?")}
				</ContextMenuDeleteItem>
			</ContextMenu>
		</>
	);
};
