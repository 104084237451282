import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { groupKeys, groupService } from "~shared/api/groups";

export interface GroupSingleSelectProps {
	name: string;
	label: string;
	disabled?: boolean;
}

const fetcher = (search: string, page: number) => groupService.options(search, page).then((r) => r.data);

export const GroupSingleSelect: FC<GroupSingleSelectProps> = ({ name, label, disabled }) => {
	return (
		<PagedSingleSelectField
			name={name}
			label={label}
			queryKey={groupKeys.options}
			fetcher={fetcher}
			disabled={disabled}
		/>
	);
};
