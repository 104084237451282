import { useCallback, useMemo } from "react";
import { emptySlate } from "~admin/shared/utils";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { Consent, ConsentFormValues, IConsentRequest } from "~shared/types/consent";

interface UseConsent {
	emptyConsent: Consent;
	parseTranslations: (consent: ConsentFormValues | Consent) => IConsentRequest;
}

export const useConsent = (): UseConsent => {
	const { locales } = useContentLocale();

	const emptyConsent: Consent = useMemo(
		() => ({
			id: "",
			name: "",
			type: "radio",
			audience: "all",
			required: false,
			translations: locales.reduce(
				(acc, locale) => ({
					...acc,
					[locale]: {
						headline: "",
						consent: emptySlate,
					},
				}),
				{},
			),
		}),
		[locales],
	);

	const parseTranslations = useCallback(
		(consent: ConsentFormValues | Consent) => {
			return {
				...consent,
				translations: locales.reduce(
					(acc, locale) => ({
						...acc,
						[locale]: {
							headline: consent.translations[locale]?.headline,
							consent: JSON.stringify(consent.translations[locale]?.consent),
						},
					}),
					{},
				),
			};
		},
		[locales],
	);

	return { emptyConsent, parseTranslations };
};
