import { BaseCrudOptionService, BaseCrudService, Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import {
	DataFieldSettings,
	IDataFieldContingentResource,
	IDataFieldIndexResource,
	IDataFieldOptionResource,
	IDataFieldUpdateRequest,
	IDataFieldUpdateResource,
	SaveDataFieldSettings,
} from "~shared/types/dataField";
import {
	IDataFieldOptionIndexResource,
	IDataFieldOptionOptionResource,
	IDataFieldOptionRequest,
	IDataFieldOptionUpdateResource,
} from "~shared/types/dataFieldOption";
import { ModelUsageResource } from "~shared/types/modelUsageResource";

class DataFieldService extends BaseCrudOptionService<
	IDataFieldIndexResource,
	IDataFieldUpdateResource,
	IDataFieldOptionResource,
	IDataFieldUpdateRequest
> {
	public keys;
	protected urls;

	constructor() {
		super();
		const baseKey = ["data-fields"];
		this.keys = {
			index: baseKey,
			indexAll: [...baseKey, "all"],
			list: [...baseKey, "list"],
			details: (id: Id) => [...baseKey, `${id}`],
			options: [...baseKey, "options"],
			settings: [...baseKey, "settings"],
			contingentOverview: [...baseKey, "contingent-overview"],
		};
		this.urls = {
			index: keyToUrl(this.keys.index),
			details: (id: Id) => keyToUrl(this.keys.details(id)),
			options: keyToUrl(this.keys.options),
			contingentOverview: keyToUrl(this.keys.contingentOverview),
			order: (id: Id) => `${keyToUrl(this.keys.details(id))}/order-options`,
		};
	}

	indexAll = (config?: AxiosRequestConfig) =>
		axios.get<LaravelResponse<IDataFieldIndexResource[]>>(keyToUrl(this.keys.indexAll), config);

	order = (dataFieldId: Id, language: string, dataFieldOptionIds: Id[]) =>
		axios.put<LaravelResponse<IDataFieldOptionIndexResource[]>>(
			this.urls.order(dataFieldId),
			{
				dataFieldOptionIds,
				locale: language,
			},
			{ headers: { "Content-Language": language } },
		);

	getSettings = () => axios.get<LaravelResponse<DataFieldSettings>>(keyToUrl(this.keys.settings));

	getContingentOverview = (config?: AxiosRequestConfig) =>
		axios.get<LaravelResponse<IDataFieldContingentResource[]>>(this.urls.contingentOverview, config);

	putSettings = (settings: SaveDataFieldSettings) =>
		axios.put<LaravelResponse<DataFieldSettings>>(keyToUrl(this.keys.settings), settings);

	exportFileDataField = (datFieldId: number) =>
		axios.get<LaravelResponse<void>>(`data-fields/${datFieldId}/exports/file`);
}

export const dataFieldService = new DataFieldService();

export class DataFieldOptionService extends BaseCrudService<
	IDataFieldOptionIndexResource,
	IDataFieldOptionUpdateResource,
	IDataFieldOptionRequest
> {
	public keys;
	protected urls;

	constructor(dataFieldId: string) {
		super();
		const baseKey = ["data-fields", dataFieldId, "options"];
		this.keys = {
			index: baseKey,
			list: [...baseKey, "list"],
			details: (id: Id) => [...baseKey, `${id}`],
		};
		this.urls = {
			index: keyToUrl(this.keys.index),
			details: (id: Id) => keyToUrl(this.keys.details(id)),
		};
	}

	showUsageKey = (dataFieldId: Id, dataFieldOptionId: Id) => [
		"data-fields",
		`${dataFieldId}`,
		"options",
		`${dataFieldOptionId}`,
		"usage",
	];
	showUsage = (dataFieldId: Id, dataFieldOptionId: Id) =>
		axios.get<LaravelResponse<ModelUsageResource[]>>(keyToUrl(this.showUsageKey(dataFieldId, dataFieldOptionId)));

	listByLanguage = (language: string) =>
		axios.get<LaravelResponse<IDataFieldOptionIndexResource[]>>(this.urls.index, {
			headers: { "Content-Language": language },
		});

	indexAll = () => axios.get<LaravelResponse<IDataFieldOptionOptionResource[]>>(this.urls.index);
}
