import { useQuery } from "@tanstack/react-query";
import { productDataKeys } from "./productDataKeys";
import { productDataService } from "./productDataService";

export const useProductDataQuery = () => {
	const { data, isLoading } = useQuery({
		queryKey: productDataKeys.index,
		queryFn: () => productDataService.index().then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};

export const useProductDataEditQuery = () => {
	const { data, isLoading } = useQuery({
		queryKey: productDataKeys.edit,
		queryFn: () => productDataService.edit().then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};
