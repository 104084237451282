import { CheckboxField, TextField } from "@dgs/core";
import { useField } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { InvoiceProviderType } from "~shared/types/invoiceProvider";

export const InvoiceProviderConfigField: FC = () => {
	const { t } = useTranslation();
	const [{ value: type }] = useField<InvoiceProviderType>("type");

	if (type === "BMW")
		return (
			<>
				<TextField name="config.apiKey" label={t("Api key")} required={true} />
				<TextField name="config.merchantId" label={t("Merchant id")} required={true} />
				<TextField name="config.merchantDescription" label={t("Merchant description")} required={true} />
				<TextField name="config.template.brand" label={t("Brand")} required={true} />
				<TextField name="config.template.variantName" label={t("Variant")} required={true} />
				<TextField name="config.template.language" label={t("Language")} required={true} />
				<TextField name="config.template.country" label={t("Country")} required={true} />
				<CheckboxField name="config.inTestMode" label={t("In test mode")} />
				<TextField name="config.accountingUsername" label={t("Accounting Username")} />
				<TextField name="config.accountingPassword" label={t("Accounting Password")} />
			</>
		);

	return null;
};
