import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardHeading,
	DeleteAction,
	Loading,
	SubmitButton,
	TextField,
} from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RoleMultiSelect } from "~admin/roles";
import { UserFormValues } from "~admin/shared/types/user";
import { Separator } from "~admin/shared/ui/Separator";
import { isRemoteAuthType } from "~admin/users/isRemoteAuthType";
import { TenantMultiSelectField } from "~shared/selects/TenantMultiSelectField";
import { FormikHelpersWithRedirect, FormikWithRedirect } from "~shared/ui/FormikWithRedirect";

interface Props {
	user: UserFormValues | null;
	title: string;
	handleSubmit: (request: UserFormValues, helpers: FormikHelpersWithRedirect<UserFormValues>) => Promise<boolean>;
	handleDelete?: () => void;
	loading?: boolean;
}

const MAX_IMAGE_HEIGHT = 200;

const UserImage = styled.img`
	max-height: ${MAX_IMAGE_HEIGHT}px;
	border-radius: 50%;
	object-fit: contain;
`;

const REQUIRED_FIELDS: (keyof UserFormValues)[] = ["firstName", "lastName", "email"];

export const UserForm: FC<Props> = ({ user, title, handleSubmit, handleDelete, loading }) => {
	const { t } = useTranslation();

	const validate = (values: UserFormValues) => {
		const errors: { [K in keyof UserFormValues]?: any } = {};
		REQUIRED_FIELDS.forEach((requiredField) => {
			if (values[requiredField] === "") {
				errors[requiredField] = t("Required field");
			}
		});
		return errors;
	};

	return (
		<Card>
			<CardHeader>
				<CardHeading>{title}</CardHeading>
			</CardHeader>
			{(loading || !user) && <Loading />}
			{!loading && user && (
				<FormikWithRedirect initialValues={user} validate={validate} onSubmit={handleSubmit}>
					<>
						<CardBody>
							{user.image && <UserImage src={user.image.url} alt={user.image.name} /> && <Separator />}
							<TextField label={t("First name")} name="firstName" disabled={isRemoteAuthType} />
							<TextField label={t("Last name")} name="lastName" disabled={isRemoteAuthType} />
							<TextField label={t("Email")} name="email" disabled={isRemoteAuthType} />
							<TextField label={t("Company")} name="company" disabled={isRemoteAuthType} />
							<TextField label={t("Position")} name="position" disabled={isRemoteAuthType} />
							<RoleMultiSelect label={t("Roles")} name="roles" />
							<TenantMultiSelectField label={t("Tenants")} name="tenants" />
						</CardBody>
						<CardFooter>
							{!isRemoteAuthType && handleDelete && (
								<DeleteAction
									action={handleDelete}
									heading={t("Delete")}
									title={t("Delete")}
									buttonLabel={t("Delete")}
									labels={{ close: t("Close"), confirm: t("Confirm") }}
								>
									{t("Are you sure you want to delete this user?")}
								</DeleteAction>
							)}
							<SubmitButton>{t("Save")}</SubmitButton>
						</CardFooter>
					</>
				</FormikWithRedirect>
			)}
		</Card>
	);
};
