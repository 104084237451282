import { DrawerAutoDataList, IDrawerDataListColumn, useCrudEntity } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { taxService } from "~admin/shared/api/tax";
import { TaxDrawer } from "~admin/shop/taxes/TaxDrawer";
import { TaxListContextMenu } from "~admin/shop/taxes/TaxListContextMenu";
import { ITax, TaxTypes } from "~shared/types/tax";

export const TaxesList: FC = () => {
	const { t } = useTranslation();

	const crudOperations = useCrudEntity(taxService, {
		entityName: t("Tax"),
		messages: {
			create: t("Tax was successfully saved."),
			update: t("Tax was successfully saved."),
			delete: t("Tax was successfully deleted."),
		},
	});

	const dataListColumns: IDrawerDataListColumn<ITax>[] = useMemo(
		() => [
			{
				heading: t("Type"),
				valueKey: "type",
				type: "value",
			},
			{
				heading: t("Percentage"),
				valueKey: "percent",
				type: "value",
				render: (value) => value.percent + "%",
			},
			{
				heading: t("Notice"),
				valueKey: "notice",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (tax: ITax, openDrawer) => (
					<TaxListContextMenu
						taxId={tax.id}
						handleEdit={openDrawer}
						handleDelete={() => crudOperations.deleteEntity(tax.id)}
					/>
				),
			},
		],
		[crudOperations, t],
	);

	const emptyTax: ITax = useMemo(
		() => ({
			id: "new",
			type: TaxTypes.TYPE_NORMAL,
			percent: null,
			notice: null,
		}),
		[],
	);

	return (
		<DrawerAutoDataList
			translations={{
				title: t("Taxes"),
				empty: t("No taxes available"),
			}}
			renderCreateDrawer={(props) => (
				<TaxDrawer
					{...props}
					heading={t("Create tax")}
					initialValues={emptyTax}
					onSubmit={(entity) => crudOperations.createEntity(entity)}
				/>
			)}
			renderUpdateDrawer={(resource, props) => (
				<TaxDrawer
					{...props}
					heading={t("Edit tax")}
					initialValues={resource}
					onSubmit={(formState) => crudOperations.updateEntity(resource.id, formState)}
				/>
			)}
			columns={dataListColumns}
			service={taxService}
		/>
	);
};
