import { PagedMultiSelectField, PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { shopItemService } from "~admin/shared/api/shop";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
}

const fetcher = (search: string, page: number) =>
	shopItemService.options({ params: { search, page } }).then((r) => r.data);

export const ShopItemMultiSelect: FC<Props> = ({ name, label, disabled, required }) => {
	const { fallbackLocale } = useContentLocale();

	return (
		<PagedMultiSelectField
			name={name}
			queryKey={shopItemService.keys.options}
			fetcher={fetcher}
			label={label}
			disabled={disabled}
			clearable={true}
			getLabel={(option) => `${option.translations[fallbackLocale].name}`}
			required={required}
		/>
	);
};

export const ShopItemSingleSelect: FC<Props> = ({ name, label, disabled, required }) => {
	const { fallbackLocale } = useContentLocale();

	return (
		<PagedSingleSelectField
			name={name}
			queryKey={shopItemService.keys.options}
			fetcher={fetcher}
			label={label}
			disabled={disabled}
			clearable={true}
			getLabel={(option) => `${option.translations[fallbackLocale].name}`}
			required={required}
		/>
	);
};
