import {
	MenuItemType as BaseMenuItemType,
	VisibilityOption as BaseVisibilityOption,
	defaultMenuItemTypes,
	defaultVisibilityOptions,
} from "@dgs/core";

const visibilityOptions = [...defaultVisibilityOptions] as const;
export type VisibilityOption = (typeof visibilityOptions)[number] | BaseVisibilityOption;
export const initialVisibilityOptions = [...visibilityOptions];

const menuItemTypes = [...defaultMenuItemTypes, "PAGE"] as const;
export type MenuItemType = (typeof menuItemTypes)[number] | BaseMenuItemType;
export const initialMenuItemTypes = [...menuItemTypes];
