import { Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { registrationFormKeys } from "~shared/api/registrationForms";
import { getRuleValuePayload } from "~shared/registrationForms/getRuleValue";
import { ActionFormState, ActionTypes } from "~shared/types/actions";
import { Rule, RuleFormState } from "~shared/types/rules";
import { Automation, RuleSet } from "~shared/types/ruleSet";
import { IRegistrationFormStep } from "~shared/types/step";

const resolveValue = (action: ActionFormState) => {
	switch (action.type) {
		case ActionTypes.ATTACH_TAG:
		case ActionTypes.ATTACH_GROUP:
			return action.value?.id;
		case ActionTypes.OVERWRITE_GROUPS:
		case ActionTypes.OVERWRITE_TAGS:
			return action.value?.map((v) => v.id);
		case ActionTypes.SHOW_STEP:
		case ActionTypes.SHOW_SECTION:
		case ActionTypes.SEND_EMAIL:
			return action.value?.id;
		case ActionTypes.SHOW_DATA_FIELD:
			return action.value
				? {
						sectionId: action.value.section.id,
						dataFieldId: action.value.dataField.id,
				  }
				: null;
		default:
			return null;
	}
};

const mapRulesetToPayload = (ruleset: RuleSet<RuleFormState, ActionFormState>) => ({
	...ruleset,
	rules: ruleset.rules.map((x) => ({
		dataFieldId: x.sectionDataField?.dataField.id,
		sectionId: x.sectionDataField?.section.id,
		value: getRuleValuePayload(x as Rule),
		type: x.type,
	})),
	actions: ruleset.actions.map((x) => ({
		type: x.type,
		value: resolveValue(x),
	})),
});

class RuleSetService {
	public keys = {
		ruleSets: (formId: Id) => [...registrationFormKeys.detail(formId), "rule-sets"],
		ruleSetsByType: (formId: Id, type: string) => [...registrationFormKeys.detail(formId), "rule-sets", type],
		ruleSetDetails: (formId: Id, ruleSetId: Id) => [
			...registrationFormKeys.detail(formId),
			"rule-sets",
			`${ruleSetId}`,
		],
		ruleSetByDataFieldReference: (formId: Id, dataFieldId: Id) => [
			...registrationFormKeys.detail(formId),
			"rule-sets",
			"dataField",
			`${dataFieldId}`,
		],
	};

	private urls = {
		ruleSets: (formId: Id) => keyToUrl(this.keys.ruleSets(formId)),
		ruleSetsByType: (formId: Id, ruleSetType: string) => keyToUrl(this.keys.ruleSetsByType(formId, ruleSetType)),
		ruleSetDetails: (formId: Id, ruleSetId: Id) => keyToUrl(this.keys.ruleSetDetails(formId, ruleSetId)),
		ruleSetByDataFieldReference: (formId: Id, dataFieldId: Id) =>
			keyToUrl(this.keys.ruleSetByDataFieldReference(formId, dataFieldId)),
	};

	ruleSetsByType = (formId: Id, ruleSetType: string) =>
		axios.get<LaravelResponse<Automation[]>>(this.urls.ruleSetsByType(formId, ruleSetType));

	getRuleSet = (formId: Id, ruleSetId: Id) =>
		axios.get<LaravelResponse<RuleSet>>(this.urls.ruleSetDetails(formId, ruleSetId));

	postRuleSet = (formId: Id, ruleSet: RuleSet<RuleFormState, ActionFormState>) =>
		axios.post<LaravelResponse<{ id: Id }>>(this.urls.ruleSets(formId), mapRulesetToPayload(ruleSet));

	putRuleSet = (formId: Id, ruleSet: RuleSet<RuleFormState, ActionFormState>) =>
		axios.put<LaravelResponse<RuleSet<RuleFormState, ActionFormState>>>(
			this.urls.ruleSetDetails(formId, ruleSet.id),
			mapRulesetToPayload(ruleSet),
		);

	removeRuleSet = (formId: Id, ruleSetId: Id) =>
		axios.delete<LaravelResponse<IRegistrationFormStep[]>>(this.urls.ruleSetDetails(formId, ruleSetId));

	checkDataFieldUsage = (formId: Id, dataFieldId: Id) =>
		axios.get<LaravelResponse<RuleSet[]>>(this.urls.ruleSetByDataFieldReference(formId, dataFieldId));
}

export const ruleSetService = new RuleSetService();
