import { apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { usersKeys, usersService } from "~admin/shared/api/users";
import { UserFormValues, UserRequest } from "~admin/shared/types/user";
import { FormikHelpersWithRedirect } from "~shared/ui/FormikWithRedirect";

interface UseUserForm {
	deleteUser: (userId: string, shouldGoBackTo?: string) => Promise<void>;
	createUser: (user: UserFormValues, helpers: FormikHelpersWithRedirect<UserFormValues>) => Promise<boolean>;
	updateUser: (user: UserFormValues, helpers: FormikHelpersWithRedirect<UserFormValues>) => Promise<boolean>;
}

const mapUserValuesToUserSaveRequest = (user: UserFormValues): UserRequest => {
	return {
		id: user.id,
		image: user.image,
		email: user.email,
		firstName: user.firstName,
		lastName: user.lastName,
		company: user.company,
		position: user.position,
		roles: user.roles.map((t) => t.id),
		tenantIds: user.tenants.map((t) => t.id),
	};
};

export const useUserForm = (reload?: () => void): UseUserForm => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const createUser = useCallback(
		async (user: UserFormValues, helpers: FormikHelpersWithRedirect<UserFormValues>) => {
			const response = await usersService.post(mapUserValuesToUserSaveRequest(user));

			if (apiIsOK(response)) {
				showToast({
					body: t("User was successfully created."),
					title: t("User"),
					type: "success",
				});
				helpers.setTo(-1);
				await queryClient.invalidateQueries({ queryKey: usersKeys.list });
				return true;
			}
			return false;
		},
		[showToast, t, queryClient],
	);

	const updateUser = useCallback(
		async (user: UserFormValues, helpers: FormikHelpersWithRedirect<UserFormValues>) => {
			const response = await usersService.put(mapUserValuesToUserSaveRequest(user));
			if (apiIsOK(response)) {
				showToast({
					body: t("User was successfully updated."),
					title: t("User"),
					type: "success",
				});
				if (reload) {
					reload();
				}
				helpers.setTo(-1);
				await queryClient.invalidateQueries({ queryKey: usersKeys.list });
				await queryClient.invalidateQueries({ queryKey: usersKeys.details(user.id) });
				return true;
			}
			return false;
		},
		[queryClient, reload, showToast, t],
	);

	const deleteUser = useCallback(
		async (userId: string, shouldGoBackTo?: string) => {
			const response = await usersService.remove(userId);
			if (apiIsOK(response)) {
				showToast({
					body: t("User was successfully deleted."),
					title: t("User"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: usersKeys.list });
				if (shouldGoBackTo) {
					navigate(shouldGoBackTo, { replace: true });
				}
			}
		},
		[navigate, queryClient, showToast, t],
	);

	return { createUser, updateUser, deleteUser };
};
