import { Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { EmailListResource } from "~shared/types/email";
import { emailKeys } from "./emailKeys";
import { emailService } from "./emailService";

const emails: EmailListResource[] = [];
export const useEmailsQuery = () => {
	const { data, isLoading } = useQuery({
		queryKey: emailKeys.indexAll,
		queryFn: () => emailService.indexAll().then((r) => r.data.data),
	});

	return {
		data: data || emails,
		isLoading,
	};
};

export const useEmailQuery = (mailId: Id) => {
	const { data, isLoading } = useQuery({
		queryKey: emailKeys.details(mailId),
		queryFn: () => emailService.details(mailId).then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};

export const useEmailLogDetailsQuery = (logId: Id) => {
	const { data, isLoading } = useQuery({
		queryKey: emailKeys.logs.details(logId),
		queryFn: () => emailService.logs.details(logId).then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};
