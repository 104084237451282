import { DrawerAutoDataList, IDrawerDataListColumn } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { LocationDrawer } from "~admin/checkInApp/locations/LocationDrawer";
import { LocationsListContextMenu } from "~admin/checkInApp/locations/LocationsListContextMenu";
import { useLocationForm } from "~admin/checkInApp/locations/useLocationForm";
import { locationService } from "~admin/shared/api/locations";
import { Location } from "~admin/shared/types/location";

const emptyLocation: Location = { name: "", id: -1, guestView: null, image: null, guestFilter: null };

export const LocationsList: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { updateLocation, createLocation } = useLocationForm();

	const dataListColumns: IDrawerDataListColumn<Location>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Guest view"),
				valueKey: "guestView.name",
				type: "value",
			},
			{
				heading: t("Image"),
				valueKey: "image.name",
				type: "value",
			},
			{
				heading: t("Guest filter"),
				valueKey: "guestFilter.name",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }, openDrawer) => <LocationsListContextMenu locationId={id} handleEdit={openDrawer} />,
			},
		],
		[t],
	);

	return (
		<DrawerAutoDataList
			translations={{
				title: t("Locations"),
				empty: t("No locations available"),
			}}
			renderCreateDrawer={(drawerProps) => (
				<LocationDrawer
					{...drawerProps}
					title={t("Create location")}
					location={emptyLocation}
					onSave={(entityFormState) => createLocation(entityFormState)}
				/>
			)}
			renderUpdateDrawer={(entityResource, drawerProps) => (
				<LocationDrawer
					{...drawerProps}
					title={t("Edit Location")}
					location={entityResource}
					onSave={(entityFormState) => updateLocation(entityFormState)}
				/>
			)}
			columns={dataListColumns}
			service={locationService}
			onRowClick={(entity) => navigate(`${entity.id}/logs`)}
		/>
	);
};
