import { SingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
}

const StyledSingleSelectField = styled(SingleSelectField)`
	margin-bottom: 0;
`;

export const ContentLanguageSingleSelect: FC<Props> = ({ name, label, disabled, required }) => {
	const { locales, fallbackLocale } = useContentLocale();
	const { t } = useTranslation();

	const options = ["registrationLocale"].concat(locales);

	return (
		<StyledSingleSelectField
			name={name}
			label={label}
			disabled={disabled}
			options={options}
			getLabel={(o: any) => t(o, { context: "email", fallbackLocale })}
			clearable={false}
			required={required}
		/>
	);
};

export const ContentLanguageImportSingleSelect: FC<Props> = ({ name, label, disabled, required }) => {
	const { locales } = useContentLocale();
	const { t } = useTranslation();

	return (
		<StyledSingleSelectField
			name={name}
			label={label}
			disabled={disabled}
			options={locales}
			getLabel={(o: any) => t(o)}
			clearable={false}
			required={required}
		/>
	);
};
