import { Step, StepStatus, Stepper } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export type CreateEmailStep = "Email data" | "Email content";

interface Props {
	currentStep: CreateEmailStep;
}

const orderedSteps: CreateEmailStep[] = ["Email data", "Email content"];

export const stepToStepStatus = (step: CreateEmailStep, currentStep: CreateEmailStep): StepStatus => {
	const stepIndex = orderedSteps.indexOf(step);
	const currentIndex = orderedSteps.indexOf(currentStep);

	if (currentIndex === stepIndex) {
		return "active";
	}

	if (currentIndex > stepIndex) {
		return "done";
	}

	return "inactive";
};

const StyledStepper = styled(Stepper)`
	margin-bottom: ${({ theme }) => theme.spacing(4)};
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
`;

export const CreateEmailStepper: FC<Props> = ({ currentStep }) => {
	const { t } = useTranslation();

	return (
		<StyledStepper>
			{orderedSteps.map((step, index) => (
				<Step key={step} status={stepToStepStatus(step, currentStep)} index={index + 1}>
					{t(step)}
				</Step>
			))}
		</StyledStepper>
	);
};
