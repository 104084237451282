import { ContextMenu, ContextMenuDeleteItem, ContextMenuLinkItem } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IRoomTypeIndex } from "~shared/types/roomType";
import { useRoomTypeForm } from "./useRoomTypeForm";

interface Props {
	roomType: IRoomTypeIndex;
}

export const RoomTypeContextMenu: FC<Props> = ({ roomType }) => {
	const { t } = useTranslation();
	const { deleteRoomType } = useRoomTypeForm();

	return (
		<ContextMenu displayShortcutsAs="icon">
			<ContextMenuLinkItem
				label={t("Edit")}
				title={t("Edit")}
				to={`/admin/hotel-management/hotels/${roomType.hotel.id}/room-types/${roomType.id}`}
				icon="edit"
				shortcut="primary"
			/>
			{roomType.bookings === 0 && (
				<ContextMenuDeleteItem
					label={t("Delete")}
					action={() => deleteRoomType(roomType.hotel.id, roomType.id)}
					title={t("Delete")}
					heading={t("Delete")}
					labels={{ close: t("Close"), confirm: t("Confirm") }}
				>
					{t("Are you sure you want to delete this room type?")}
				</ContextMenuDeleteItem>
			)}
		</ContextMenu>
	);
};
