import {
	DndContext,
	DragEndEvent,
	DragOverlay,
	DragStartEvent,
	KeyboardSensor,
	PointerSensor,
	closestCorners,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	SortableContext,
	arrayMove,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { ConsentListItem } from "~shared/types/consent";
import { RowContainer } from "../common/RowContainer";
import { RegistrationFormConsent } from "./RegistrationFormConsent";
import { SortableRegistrationFormConsent } from "./SortableRegistrationFormConsent";
import { useRegistrationFormStep } from "./useRegistrationFormStep";

const Wrapper = styled.div`
	overflow: auto;
	padding: 0 ${({ theme }) => theme.spacing(4)};
	display: grid;
`;

interface Props {
	registrationFormId: string;
	initialConsents: ConsentListItem[];
	forLandingPage: boolean;
}

export const RegistrationFormConsents: FC<Props> = ({ registrationFormId, initialConsents, forLandingPage }) => {
	const [consents, setConsents] = useState(initialConsents);
	const [activeConsent, setActiveConsent] = useState<ConsentListItem | null>(null);
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);
	const { syncConsents } = useRegistrationFormStep(registrationFormId);

	useEffect(() => {
		setConsents(initialConsents);
	}, [initialConsents]);

	const handleDragStart = (e: DragStartEvent) => {
		const activeConsent = consents.find((consent) => consent.id === e.active.id);

		setActiveConsent(activeConsent || null);
	};

	const handleDragEnd = (e: DragEndEvent) => {
		const activeIndex = consents.findIndex((consent) => consent.id === e.active.id);
		const overIndex = consents.findIndex((consent) => consent.id === e.over?.id);

		if (activeIndex !== undefined && overIndex !== undefined) {
			setConsents((current) => {
				const newConsents = arrayMove(current, activeIndex, overIndex);

				void syncConsents(
					newConsents.map((consent) => consent.id),
					forLandingPage,
				);

				return newConsents;
			});
		}

		setActiveConsent(null);
	};

	return (
		<Wrapper>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCorners}
				onDragStart={handleDragStart}
				onDragEnd={handleDragEnd}
			>
				<SortableContext items={consents} strategy={verticalListSortingStrategy}>
					<RowContainer>
						{consents.map((consent) => (
							<SortableRegistrationFormConsent
								key={forLandingPage ? `landing${consent.id}` : `summary${consent.id}`}
								consent={consent}
								forLandingPage={forLandingPage}
							/>
						))}
					</RowContainer>
				</SortableContext>
				<DragOverlay>
					{activeConsent ? <RegistrationFormConsent consent={activeConsent} forLandingPage={forLandingPage} /> : null}
				</DragOverlay>
			</DndContext>
		</Wrapper>
	);
};
