import { Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { IDataFieldOptionUpdateResource } from "~shared/types/dataFieldOption";
import { ModelUsageResource } from "~shared/types/modelUsageResource";
import {
	IRegistrationFormDetails,
	IRegistrationFormForm,
	IRegistrationFormIndex,
	IRegistrationFormRequest,
	IRegistrationFormSettingsRequest,
} from "~shared/types/registrationForm";
import { Rule } from "~shared/types/rules";
import { IRegistrationFormSectionRequest } from "~shared/types/section";
import { IRegistrationFormStep, IRegistrationFormStepIndex, RegistrationFormStepFormValues } from "~shared/types/step";
import { registrationFormKeys } from "./registrationFormsKeys";

export const registrationFormUrls = {
	index: keyToUrl(registrationFormKeys.index),
	options: keyToUrl(registrationFormKeys.options),
	detail: (formId: Id) => keyToUrl(registrationFormKeys.detail(formId)),
	showUsage: (registrationFormId: Id) => keyToUrl(registrationFormKeys.showUsage(registrationFormId)),
	duplicate: (formId: Id) => keyToUrl(registrationFormKeys.duplicate(formId)),
	settings: (formId: Id) => keyToUrl(registrationFormKeys.settings(formId)),
	details: (formId: Id) => keyToUrl(registrationFormKeys.details(formId)),
	dataFieldOptions: (formId: Id) => keyToUrl(registrationFormKeys.dataFieldsOptions(formId)),
	steps: (formId: Id) => keyToUrl(registrationFormKeys.steps(formId)),
	summaryStep: (formId: Id) => keyToUrl(registrationFormKeys.summaryStep(formId)),
	landingStep: (formId: Id) => keyToUrl(registrationFormKeys.landingStep(formId)),
	stepDetails: (formId: Id, stepId: Id) => keyToUrl(registrationFormKeys.stepDetails(formId, stepId)),
	stepOrder: (formId: Id) => keyToUrl(registrationFormKeys.stepOrder(formId)),
	sections: (formId: Id, stepId: Id) => keyToUrl(registrationFormKeys.sections(formId, stepId)),
	sectionDetails: (formId: Id, stepId: Id, sectionId: Id) =>
		keyToUrl(registrationFormKeys.sectionDetails(formId, stepId, sectionId)),
	dataFieldValidationRules: (formId: Id, stepId: Id, sectionId: Id, dataFieldId: Id) =>
		keyToUrl(registrationFormKeys.dataFieldValidationRules(formId, stepId, sectionId, dataFieldId)),
	stepChildrenOrder: (formId: Id, stepId: Id) => keyToUrl(registrationFormKeys.stepChildrenOrder(formId, stepId)),
	sectionChildrenOrder: (formId: Id, stepId: Id, sectionId: Id) =>
		keyToUrl(registrationFormKeys.sectionChildrenOrder(formId, stepId, sectionId)),
	syncDataFields: (formId: Id, stepId: Id, sectionId: Id) =>
		keyToUrl(registrationFormKeys.syncDataFields(formId, stepId, sectionId)),
	detachDataFields: (formId: Id, stepId: Id, sectionId: Id) =>
		keyToUrl(registrationFormKeys.detachDataFields(formId, stepId, sectionId)),
	attachStepBlocks: (formId: Id, stepId: Id) => keyToUrl(registrationFormKeys.attachStepBlocks(formId, stepId)),
	detachStepBlocks: (formId: Id, stepId: Id) => keyToUrl(registrationFormKeys.detachStepBlocks(formId, stepId)),
	attachSectionBlocks: (formId: Id, stepId: Id, sectionId: Id) =>
		keyToUrl(registrationFormKeys.attachSectionBlocks(formId, stepId, sectionId)),
	detachSectionBlocks: (formId: Id, stepId: Id, sectionId: Id) =>
		keyToUrl(registrationFormKeys.detachSectionBlocks(formId, stepId, sectionId)),
	syncConsents: (formId: Id) => keyToUrl(registrationFormKeys.syncConsents(formId)),
	updateShopStep: (formId: Id) => keyToUrl(registrationFormKeys.updateShopStep(formId)),
	showSectionUsage: (formId: Id, stepId: Id, sectionId: Id) =>
		keyToUrl(registrationFormKeys.showSectionUsage(formId, stepId, sectionId)),
	showStepUsage: (formId: Id, stepId: Id) => keyToUrl(registrationFormKeys.showStepUsage(formId, stepId)),
	showDataFieldUsage: (formId: Id, stepId: Id, sectionId: Id, dataFieldId: Id) =>
		keyToUrl(registrationFormKeys.showDataFieldUsage(formId, stepId, sectionId, dataFieldId)),
};

const index = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<IRegistrationFormIndex[]>>(registrationFormUrls.index, config);

const options = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<IRegistrationFormIndex[]>>(registrationFormUrls.options, config);

const details = (formId: Id) =>
	axios.get<LaravelResponse<Omit<IRegistrationFormDetails, "closeAt"> & { closeAt: string }>>(
		registrationFormUrls.details(formId),
	);

const showUsage = (registrationFormId: Id) =>
	axios.get<LaravelResponse<ModelUsageResource[]>>(registrationFormUrls.showUsage(registrationFormId));

const put = (id: Id, registrationForm: IRegistrationFormRequest) =>
	axios.put<LaravelResponse<IRegistrationFormIndex>>(registrationFormUrls.detail(id), registrationForm);

const putSettings = (id: Id, registrationForm: IRegistrationFormSettingsRequest) =>
	axios.put<LaravelResponse<IRegistrationFormIndex>>(registrationFormUrls.settings(id), registrationForm);

const post = (createRegistrationForm: IRegistrationFormRequest) =>
	axios.post<LaravelResponse<IRegistrationFormIndex>>(registrationFormUrls.index, createRegistrationForm);

const remove = (formId: Id) =>
	axios.delete<LaravelResponse<IRegistrationFormIndex>>(registrationFormUrls.detail(formId));

const indexDataFields = (formId: Id) =>
	axios.get<LaravelResponse<IDataFieldOptionUpdateResource[]>>(registrationFormUrls.dataFieldOptions(formId));

const postDataFieldValidationRules = (
	formId: Id,
	stepId: Id,
	sectionId: Id,
	dataFieldId: Id,
	validationRules: Record<string, any>,
) =>
	axios.post<LaravelResponse<Rule[]>>(
		registrationFormUrls.dataFieldValidationRules(formId, stepId, sectionId, dataFieldId),
		{
			rules: validationRules,
		},
	);

const putSummaryStep = (formId: Id, blockId: Id | null) =>
	axios.put<LaravelResponse<IRegistrationFormStepIndex[]>>(registrationFormUrls.summaryStep(formId), { blockId });

const putLandingStep = (formId: Id, blockId: Id | null) =>
	axios.put<LaravelResponse<IRegistrationFormStepIndex[]>>(registrationFormUrls.landingStep(formId), { blockId });

const postStep = (formId: Id, step: RegistrationFormStepFormValues) =>
	axios.post<LaravelResponse<{ id: Id }>>(registrationFormUrls.steps(formId), step);

const putStep = (formId: Id, step: IRegistrationFormStep) =>
	axios.put<LaravelResponse<IRegistrationFormStepIndex[]>>(registrationFormUrls.stepDetails(formId, step.id), step);

const showStep = (formId: Id, stepId: Id) =>
	axios.get<LaravelResponse<IRegistrationFormStep>>(registrationFormUrls.stepDetails(formId, stepId));

const removeStep = (formId: Id, stepId: Id) =>
	axios.delete<LaravelResponse<IRegistrationFormStepIndex[]>>(registrationFormUrls.stepDetails(formId, stepId));

const putStepOrder = (formId: Id, order: { ids: Id[] }) =>
	axios.put<LaravelResponse<IRegistrationFormStepIndex[]>>(registrationFormUrls.stepOrder(formId), order);

const postSection = (formId: Id, stepId: Id, section: IRegistrationFormSectionRequest) =>
	axios.post<LaravelResponse<{ id: Id }>>(registrationFormUrls.sections(formId, stepId), section);

const putSection = (formId: Id, stepId: Id, sectionId: Id, section: IRegistrationFormSectionRequest) =>
	axios.put<LaravelResponse<IRegistrationFormStepIndex[]>>(
		registrationFormUrls.sectionDetails(formId, stepId, sectionId),
		section,
	);

const removeSection = (formId: Id, stepId: Id, sectionId: Id) =>
	axios.delete<LaravelResponse<IRegistrationFormStepIndex[]>>(
		registrationFormUrls.sectionDetails(formId, stepId, sectionId),
	);

const putStepChildrenOrder = (
	formId: string,
	stepId: string,
	order: {
		from: string;
		children: { identifier: Id; entityType: "section" | "block"; sectionId?: Id; blockId?: Id }[];
	},
) =>
	axios.put<LaravelResponse<IRegistrationFormStepIndex[]>>(
		registrationFormUrls.stepChildrenOrder(formId, stepId),
		order,
	);

const putSectionChildrenOrder = (
	formId: Id,
	stepId: Id,
	sectionId: Id,
	order: {
		from: { stepId: Id; sectionId: Id };
		children: { entityType: "dataField" | "block"; blockId?: Id; dataFieldId?: Id }[];
	},
) =>
	axios.put<LaravelResponse<IRegistrationFormStepIndex[]>>(
		registrationFormUrls.sectionChildrenOrder(formId, stepId, sectionId),
		order,
	);

const syncDataFields = (formId: Id, stepId: Id, sectionId: Id, dataFieldIds: { dataFieldIds: Id[] }) =>
	axios.put<LaravelResponse<IRegistrationFormStepIndex[]>>(
		registrationFormUrls.syncDataFields(formId, stepId, sectionId),
		dataFieldIds,
	);

const detachDataFields = (formId: Id, stepId: Id, sectionId: Id, dataFieldIds: { dataFieldIds: Id[] }) =>
	axios.put<LaravelResponse<IRegistrationFormStepIndex[]>>(
		registrationFormUrls.detachDataFields(formId, stepId, sectionId),
		dataFieldIds,
	);
const attachStepBlocks = (formId: Id, stepId: Id, blockIds: { blockIds: Id[] }) =>
	axios.put<LaravelResponse<IRegistrationFormStepIndex[]>>(
		registrationFormUrls.attachStepBlocks(formId, stepId),
		blockIds,
	);

const detachStepBlocks = (formId: Id, stepId: Id, blockIdentifiers: { blockIdentifiers: Id[] }) =>
	axios.put<LaravelResponse<void>>(registrationFormUrls.detachStepBlocks(formId, stepId), blockIdentifiers);

const attachSectionBlocks = (formId: Id, stepId: Id, sectionId: Id, blockIds: { blockIds: Id[] }) =>
	axios.put<LaravelResponse<IRegistrationFormStepIndex[]>>(
		registrationFormUrls.attachSectionBlocks(formId, stepId, sectionId),
		blockIds,
	);

const detachSectionBlocks = (formId: Id, stepId: Id, sectionId: Id, blockIdentifiers: { blockIdentifiers: Id[] }) =>
	axios.put<LaravelResponse<void>>(
		registrationFormUrls.detachSectionBlocks(formId, stepId, sectionId),
		blockIdentifiers,
	);

const syncConsents = (formId: Id, forLandingPage: boolean, consentIds: Id[]) =>
	axios.put<LaravelResponse<IRegistrationFormDetails>>(registrationFormUrls.syncConsents(formId), {
		forLandingPage,
		consentIds,
	});

const duplicate = (id: Id, values: IRegistrationFormForm) =>
	axios.post<LaravelResponse<IRegistrationFormIndex>>(registrationFormUrls.duplicate(id), values);

const updateShopStep = (formId: Id, paymentProviderIds: Id[], shopItemIds: Id[]) =>
	axios.put<LaravelResponse<IRegistrationFormDetails>>(registrationFormUrls.updateShopStep(formId), {
		paymentProviderIds,
		shopItemIds,
	});

const showSectionUsage = (formId: Id, stepId: Id, sectionId: Id) =>
	axios.get<LaravelResponse<ModelUsageResource[]>>(registrationFormUrls.showSectionUsage(formId, stepId, sectionId));

const showStepUsage = (formId: Id, stepId: Id) =>
	axios.get<LaravelResponse<ModelUsageResource[]>>(registrationFormUrls.showStepUsage(formId, stepId));

const showDataFieldUsage = (formId: Id, stepId: Id, sectionId: Id, dataFieldId: Id) =>
	axios.get<LaravelResponse<ModelUsageResource[]>>(
		registrationFormUrls.showDataFieldUsage(formId, stepId, sectionId, dataFieldId),
	);

export const registrationFormService = {
	index,
	options,
	put,
	putSettings,
	post,
	remove,
	details,
	showUsage,
	duplicate,
	indexDataFields,
	postDataFieldValidationRules,
	putSummaryStep,
	putLandingStep,
	showStep,
	postStep,
	putStep,
	removeStep,
	putStepOrder,
	postSection,
	putSection,
	removeSection,
	putStepChildrenOrder,
	putSectionChildrenOrder,
	syncDataFields,
	detachDataFields,
	attachStepBlocks,
	detachStepBlocks,
	attachSectionBlocks,
	detachSectionBlocks,
	syncConsents,
	updateShopStep,
	showSectionUsage,
	showStepUsage,
	showDataFieldUsage,
};
