import { Card, CardBody } from "@dgs/core";
import { useSortable } from "@dnd-kit/sortable";
import React, { FC } from "react";
import { ActiveCard } from "../common/ActiveStructureCard";
import { StepChildHeader, StepChildHeaderProps } from "./StepChildHeader";

interface Props extends StepChildHeaderProps {
	isActive?: boolean;
	children?: React.ReactNode;
}

export const DroppableStepChild: FC<Props> = ({ isActive, stepId, stepChild, registrationFormId, children }) => {
	const { setNodeRef } = useSortable({
		id: stepChild.id,
	});

	const StepChildCard = isActive ? ActiveCard : Card;

	return (
		<div ref={setNodeRef}>
			<StepChildCard $color="content">
				<StepChildHeader
					registrationFormId={registrationFormId}
					stepId={stepId}
					stepChild={stepChild}
					indicator={null}
				/>
				{children && <CardBody>{children}</CardBody>}
			</StepChildCard>
		</div>
	);
};
