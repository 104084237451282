import {
	CardBody,
	CardFooter,
	CardHeader,
	CardHeaderActions,
	CardHeading,
	DeleteAction,
	I18nFormProvider,
	I18nLocaleSwitcher,
	I18nTextareaField,
	SubmitButton,
	TextControl,
	TextField,
	TextareaField,
} from "@dgs/core";
import { useField } from "formik";
import React, { FC, ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CountrySingleSelect } from "~admin/shared/selects/CountrySingleSelect";
import { CurrencySingleSelect } from "~admin/shared/selects/CurrencySingleSelect";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { IHotelResource } from "~shared/types/hotel";
import { FormikHelpersWithRedirect, FormikWithRedirect, SetTo } from "~shared/ui/FormikWithRedirect";

interface Props {
	hotel: IHotelResource;
	title: string;
	onSubmit: (request: IHotelResource, helpers: FormikHelpersWithRedirect<IHotelResource>) => Promise<boolean>;
	footer?: ReactNode | ((setTo: SetTo) => ReactNode);
}

const StyledCardBody = styled(CardBody)`
	display: grid;
	grid-template-columns: 1fr;

	${({ theme }) => theme.breakpoints.tablet} {
		grid-template-columns: 1fr 2fr;
		grid-gap: ${({ theme }) => theme.spacing(16)};
	}
`;

const TimeInputs = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: ${({ theme }) => theme.spacing(4)};
`;

interface TimeFieldProps {
	name: string;
	label: string;
}

const TimeField: FC<TimeFieldProps> = ({ name, label }) => {
	const [field, meta, helper] = useField(name);

	const onChange = useCallback(
		(value: string) => {
			if (value === "") {
				helper.setValue(null);
			} else {
				helper.setValue(value);
			}
		},
		[helper],
	);

	return (
		<TextControl
			name={name}
			type="time"
			value={field.value || ""}
			error={meta.touched && meta.error ? meta.error : undefined}
			onChange={(e) => onChange(e.target.value)}
			label={label}
		/>
	);
};

export const HotelForm: FC<Props> = ({ hotel, title, onSubmit, footer }) => {
	const { t } = useTranslation();
	const { fallbackLocale, locales } = useContentLocale();

	const validate = useCallback(
		(values: IHotelResource) => {
			const errors: Record<string, any> = {};

			if (!values.name) {
				errors.name = t("Required field");
			}

			return errors;
		},
		[t],
	);

	return (
		<I18nFormProvider locales={locales} fallbackLocale={fallbackLocale}>
			{({ isMainForm }) => (
				<FormikWithRedirect initialValues={hotel} onSubmit={onSubmit} validate={validate} validateOnMount>
					{({ setTo }) => (
						<>
							<CardHeader>
								<CardHeading>{title}</CardHeading>
								<CardHeaderActions>
									<I18nLocaleSwitcher />
								</CardHeaderActions>
							</CardHeader>
							{isMainForm ? (
								<StyledCardBody>
									<div>
										<TextField label={t("Name")} name="name" required />
										<TextField label={t("Street & house number")} name="street" />
										<TextField label={t("City")} name="city" />
										<TextField label={t("ZIP")} name="zip" />
										<TextField label={t("Email")} name="email" />
										<CountrySingleSelect label={t("Country")} name="country" clearable={true} />
										<CurrencySingleSelect name="currency" label={t("Currency")} />
									</div>
									<div>
										<TextField type="tel" label={t("Phone")} name="phone" />
										<TimeInputs>
											<TimeField label={t("Check-in time")} name="checkInTime" />
											<TimeField label={t("Check-out time")} name="checkOutTime" />
											<TimeField label={t("Late check-out time")} name="lateCheckOutTime" />
										</TimeInputs>
										<TextareaField label={t("Internal remarks")} name="internalRemark" rows={5} />
										<I18nTextareaField name="guestRemark" label={t("Guest remark")} />
									</div>
								</StyledCardBody>
							) : (
								<CardBody>
									<I18nTextareaField name="guestRemark" label={t("Guest remark")} />
								</CardBody>
							)}
							{typeof footer === "function" ? footer(setTo) : footer}
						</>
					)}
				</FormikWithRedirect>
			)}
		</I18nFormProvider>
	);
};

export const HotelEditFormFooter: FC<{ handleDelete: () => void }> = ({ handleDelete }) => {
	const { t } = useTranslation();

	return (
		<CardFooter>
			{handleDelete && (
				<DeleteAction
					action={handleDelete}
					heading={t("Delete")}
					title={t("Delete")}
					buttonLabel={t("Delete")}
					labels={{ close: t("Close"), confirm: t("Confirm") }}
				>
					{t("Are you sure you want to delete this hotel?")}
				</DeleteAction>
			)}
			<SubmitButton>{t("Save")}</SubmitButton>
		</CardFooter>
	);
};

export const HotelCreateFormFooter: FC = () => {
	const { t } = useTranslation();

	return (
		<CardFooter>
			<SubmitButton>{t("Save")}</SubmitButton>
		</CardFooter>
	);
};
