import { Icon, TextControl, useDebounce } from "@dgs/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
	autoFocus?: boolean;
	onSubmit: (needle: string) => void;
}

const StyledTextControl = styled(TextControl)`
	padding-bottom: 0;
`;

const StyledButton = styled.button`
	color: inherit;
	font-family: ${({ theme }) => theme.fonts.body};
	font-style: normal;
	font-variant: normal;
	font-weight: ${({ theme }) => theme.fonts.weights.normal};
	font-size: inherit;
	line-height: ${({ theme }) => theme.fonts.lineHeights.default};
	cursor: pointer;
	background: transparent;
	width: 100%;
	height: 100%;
`;

export const SortableListItemSearch = React.forwardRef(function SortableListItemSearch(
	{ onSubmit }: Props,
	ref: React.Ref<HTMLInputElement>,
) {
	const { t } = useTranslation();
	const [needle, setNeedle] = useState("");
	const debouncedNeedle = useDebounce(needle, 500);

	useEffect(() => {
		onSubmit(debouncedNeedle);
	}, [debouncedNeedle, onSubmit]);

	return (
		<StyledTextControl
			ref={ref}
			name="search"
			value={needle}
			autoFocus={false}
			placeholder={t("Search")}
			prefix={
				<StyledButton type="submit">
					<Icon icon="search" />
				</StyledButton>
			}
			onChange={(e) => setNeedle(e.target.value)}
			suffix={
				needle && (
					<StyledButton type="button" onClick={() => setNeedle("")}>
						<Icon icon="close" />
					</StyledButton>
				)
			}
		/>
	);
});
