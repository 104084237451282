import { Id } from "@dgs/core";
import { IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";

export const ordersKeys = {
	index: ["orders"],
	filtered: (guestViewId: Id, filter: IGuestFilterFormValueEntry[]) => [
		"orders",
		`${guestViewId}`,
		JSON.stringify(filter),
	],
	bulkSendRemainingPaymentMail: ["orders", "send-remaining-payment-mails"],
	details: (orderId: Id) => ["orders", `${orderId}`],
	sendRemainingPaymentMail: (orderId: Id) => ["orders", `${orderId}`, "send-remaining-payment-mail"],
	approveOrder: (orderId: Id) => ["orders", `${orderId}`, "approve"],
	items: (orderId: Id): string[] => ["orders", `${orderId}`, "items"],
	refund: (orderId: Id): string[] => ["orders", `${orderId}`, "refund"],
	refundableItems: (orderId: Id): string[] => ["orders", `${orderId}`, "refund", "options"],
	createOrder: ["orders", "create"],
	createExport: ["orders", "export"],
};
