import { CrudDrawer } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GuestFilterDrawerFields } from "~shared/guestFilters/GuestFilterCreateDrawer";
import {
	GuestFilterFormValues,
	IActiveGuestFilterEntry,
	IGuestFilterFormValueEntry,
} from "~shared/guestFilters/guestFilterTypes";
import { useGuestFilter } from "./GuestFilterProvider";

export const GuestFilterDrawer: FC<{
	isCrudForm?: boolean;
}> = (props) => {
	const { isCrudForm = false } = props;
	const { t } = useTranslation();
	const { open, handleClose, filters, setFilters } = useGuestFilter();

	const validate = useCallback(
		({ name, filters }: GuestFilterFormValues) => {
			const errors: { [K in keyof GuestFilterFormValues]?: any } = {};

			const filterItemErrors: Record<keyof IGuestFilterFormValueEntry, string>[] = [];

			filters.forEach((filterItem, index) => {
				const error: Record<string, string> = {};

				if (!filterItem.operator) {
					error.operator = t("Field is required");
				}

				if (
					filterItem.operator &&
					filterItem.operator !== "IS_EMPTY" &&
					filterItem.operator !== "IS_NOT_EMPTY" &&
					(filterItem.value === null || filterItem.value === "" || filterItem.value === undefined)
				) {
					error.value = t("Field is required");
				}

				if (Object.keys(error).length) {
					filterItemErrors[index] = error;
				} else {
					delete filterItemErrors[index];
				}
			});

			if (filterItemErrors.length) {
				errors.filters = filterItemErrors;
			}

			if (isCrudForm && !name) {
				errors.name = t("Field is required");
			}

			return errors;
		},
		[t, isCrudForm],
	);

	return (
		<CrudDrawer
			validate={validate}
			open={open}
			onSubmit={async ({ filters }) => {
				setFilters(filters as IActiveGuestFilterEntry[]);
				return true;
			}}
			initialValues={{ name: "", filters, isCrudForm: isCrudForm }}
			onClose={handleClose}
			heading={t("Guest filters")}
			translations={{ save: t("Apply") }}
			body={<GuestFilterDrawerFields isCrudForm={isCrudForm} />}
		/>
	);
};
