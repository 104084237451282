import React, { FC } from "react";
import { EmailSaveResource } from "~shared/types/email";
import { EmailForm } from "../EmailForm";
import { useEmailActions } from "../useEmailActions";

interface Props {
	email: EmailSaveResource;
}

export const EditEmailDataForm: FC<Props> = ({ email }) => {
	const { editEmail } = useEmailActions();

	return <EmailForm initialValues={email} onSubmit={(values, helpers) => editEmail(values, email.id, helpers)} />;
};
