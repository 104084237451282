import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useUserQuery } from "~admin/shared/api/users";
import { UserFormValues } from "~admin/shared/types/user";
import { UserForm } from "./UserForm";
import { useUserForm } from "./useUserForm";

export const UserEdit: FC = () => {
	const { t } = useTranslation();
	const { userId = "" } = useParams<"userId">();
	const { data, isLoading } = useUserQuery(userId);
	const { updateUser, deleteUser } = useUserForm();
	const userValues = useMemo<UserFormValues | null>(() => {
		if (!data) {
			return null;
		}

		return {
			...data,
			company: data.company || "",
			position: data.position || "",
			email: data.email || "",
		};
	}, [data]);

	return (
		<UserForm
			title={t("Edit user")}
			user={userValues}
			handleSubmit={updateUser}
			handleDelete={() => deleteUser(userId, "..")}
			loading={isLoading}
		/>
	);
};
