import { DateField, SubmitButton } from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { HotelSingleSelect } from "~admin/hotels/HotelSingleSelect";
import { IHotelBookingValues } from "~shared/types/hotel";

const StyledForm = styled(Form)`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr max-content;
	gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
`;

interface Props {
	initialState: IHotelBookingValues;
	setFilter: (filter: IHotelBookingValues) => void;
}

export const HotelBookingsFilter: FC<Props> = ({ initialState, setFilter }) => {
	const { t } = useTranslation();
	const validate = (values: IHotelBookingValues) => {
		const errors: Record<string, string> = {};

		(["start", "end"] as (keyof IHotelBookingValues)[]).forEach((k) => {
			if (!values[k]) {
				errors[k] = t("Field is required");
			}
		});

		if (values.end < values.start) {
			errors.start = t("Start has to be before end");
		}

		return errors;
	};

	return (
		<Formik initialValues={initialState} validate={validate} onSubmit={async (values) => setFilter(values)}>
			<StyledForm>
				<DateField name="start" label={t("Start")} required={true} />
				<DateField name="end" label={t("End")} required={true} />
				<HotelSingleSelect name="hotel" label={t("Hotel")} clearable={true} />
				<SubmitButton>{t("Show")}</SubmitButton>
			</StyledForm>
		</Formik>
	);
};
