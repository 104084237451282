import { SingleSelectControl } from "@dgs/core";
import { useField } from "formik";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDataFieldsQuery } from "~shared/api/dataFields";
import { GuestViewAttributeOption } from "~shared/guestFilters/guestFilterTypes";
import { useGuestView } from "~shared/providers/useGuestView";
import { DataFieldType } from "~shared/types/dataFieldType";
import { GuestViewColumnAttribute, GuestViewRelationAttribute } from "~shared/types/guestView";

interface Props {
	name: string;
	onSelect?: (guestAttribute: GuestViewAttributeOption | null) => void;
}

const allowedDataFields = [
	DataFieldType.SELECT_FIELD,
	DataFieldType.RADIO,
	DataFieldType.MULTI_SELECT_FIELD,
	DataFieldType.CHECKBOX_GROUP,
	DataFieldType.DATETIME,
	DataFieldType.DATE,
	DataFieldType.TIME,
];

const allowedRelations: GuestViewRelationAttribute["entityType"][] = [
	"registrationForm",
	"tags",
	"groups",
	"tenant",
	"wave",
];
const allowedColumns: GuestViewColumnAttribute["entityType"][] = ["registrationLocale", "screeningStatus"];

export const GuestListBulkEditAttributeSelect: FC<Props> = ({ name, onSelect }) => {
	const { t } = useTranslation();
	const [field, , helpers] = useField<GuestViewAttributeOption | null>(name);
	const [search, setSearch] = useState("");
	const { data: dataFields } = useDataFieldsQuery();
	const { currentGuestView } = useGuestView();
	const options = useMemo(() => {
		return currentGuestView.guestAttributes
			.map((attribute) => {
				if (attribute.type === "relation" && allowedRelations.includes(attribute.entityType)) {
					return {
						id: attribute.id,
						name: t(attribute.entityType),
					};
				}

				if (attribute.type === "column" && allowedColumns.includes(attribute.entityType)) {
					return {
						id: attribute.id,
						name: t(attribute.entityType),
					};
				}

				if (attribute.type === "dataField") {
					const dataField = dataFields.find((dataField) => dataField.id === attribute.entityType);

					if (!dataField) {
						throw new Error("No data field found");
					}

					return allowedDataFields.includes(dataField.type)
						? {
								id: attribute.id,
								name: dataField.name,
						  }
						: null;
				}

				return null;
			})
			.filter(Boolean) as GuestViewAttributeOption[];
	}, [currentGuestView.guestAttributes, dataFields, t]);

	const filteredOptions = useMemo(() => {
		return options.filter((o: GuestViewAttributeOption) => o.name.toLowerCase().includes(search.toLowerCase()));
	}, [options, search]);

	return (
		<SingleSelectControl
			onChange={(e) => {
				field.onChange(e);
				onSelect && onSelect(e.target.value);
			}}
			label={t("Guest attribute")}
			value={field.value}
			name={name}
			onClear={() => helpers.setValue(null)}
			options={filteredOptions}
			getLabel={(o: GuestViewAttributeOption) => o.name}
			search={search}
			onSearch={setSearch}
		/>
	);
};
