import { FCC, LocaleProvider } from "@dgs/core";
import React from "react";
import { I18nextProvider } from "react-i18next";
import i18n from "~shared/i18n";

interface Props {
	initialLocale?: string;
	initialTimeZone?: string;
}

const handleChangeLanguage = (language: string) => i18n.changeLanguage(language);

export const PMLocaleProvider: FCC<Props> = ({
	children,
	initialTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone,
	initialLocale = Intl.DateTimeFormat().resolvedOptions().locale,
}) => {
	return (
		<I18nextProvider i18n={i18n}>
			<LocaleProvider initialLocale={initialLocale} initialTimeZone={initialTimeZone} onChange={handleChangeLanguage}>
				{children}
			</LocaleProvider>
		</I18nextProvider>
	);
};
