import { DrawerAutoDataList, IDrawerDataListColumn, useCrudEntity } from "@dgs/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { waveService } from "~shared/api/waves/waveService";
import { IWaveFormState, IWaveIndexResource, IWaveRequest } from "~shared/types/wave";
import { WaveContextMenu } from "./WaveContextMenu";
import { WaveDrawer } from "./WaveDrawer";

const mapToRequest = (values: IWaveFormState): IWaveRequest => ({
	...values,
	readonlyAfterDateTime: !values.isReadonlyAfterDateTime ? null : values.readonlyAfterDateTime,
});

export const WaveList = () => {
	const { t } = useTranslation();
	const crudOperations = useCrudEntity(
		waveService,
		{
			entityName: t("Wave"),
			messages: {
				create: t("Wave was saved."),
				update: t("Wave was saved."),
				delete: t("Wave was deleted."),
			},
		},
		[waveService.keys.getContingentOverview],
	);
	const emptyWave: IWaveFormState = {
		name: "",
		startDate: "",
		endDate: "",
		isReadonlyAfterDateTime: false,
		readonlyAfterDateTime: "",
	};
	const dataListColumns: IDrawerDataListColumn<IWaveIndexResource>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }, openDrawer) => <WaveContextMenu id={id} openEditDrawer={openDrawer} />,
			},
		],
		[t],
	);

	return (
		<>
			<DrawerAutoDataList
				columns={dataListColumns}
				service={waveService}
				renderCreateDrawer={(drawerProps) => (
					<WaveDrawer
						{...drawerProps}
						heading={t("Add wave")}
						initialValues={emptyWave}
						onSubmit={(entityFormState) => crudOperations.createEntity(mapToRequest(entityFormState))}
					/>
				)}
				renderUpdateDrawer={(entityResource, drawerProps) => (
					<WaveDrawer
						{...drawerProps}
						heading={t("Edit wave")}
						initialValues={{
							...entityResource,
							isReadonlyAfterDateTime: !!entityResource.readonlyAfterDateTime,
						}}
						onSubmit={(entityFormState) =>
							crudOperations.updateEntity(entityResource.id, mapToRequest(entityFormState))
						}
					/>
				)}
				translations={{
					title: t("Waves"),
					empty: t("No wave available."),
				}}
			/>
		</>
	);
};
