import { Layout, Section } from "@dgs/core";
import React, { FC } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { NominationManagerAppBar } from "./NominationManagerAppBar";
import { NominationManagerNavBar } from "./NominationManagerNavBar";
import { NominationManagerContentProviders } from "./NoninationManagerContentProviders";

const ContentContainer = styled.div`
	position: relative;
	flex: 1;
	height: 100%;
	padding: 1.5rem 0;
`;

export const NominationManagerContent: FC = () => {
	return (
		<NominationManagerContentProviders>
			<Layout
				header={<NominationManagerAppBar />}
				navigation={<NominationManagerNavBar />}
				content={
					<Section>
						<ContentContainer style={{ margin: "0px 0" }}>
							<Outlet />
						</ContentContainer>
					</Section>
				}
			/>
		</NominationManagerContentProviders>
	);
};
