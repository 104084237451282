import { ContextMenu, ContextMenuDeleteItem, ContextMenuLinkItem } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useHotelForm } from "~admin/hotels/useHotelForm";
import { IHotelIndex } from "~shared/types/hotel";

interface Props {
	hotel: IHotelIndex;
}

export const HotelsContextMenu: FC<Props> = ({ hotel }) => {
	const { t } = useTranslation();
	const { deleteHotel } = useHotelForm();

	return (
		<ContextMenu displayShortcutsAs="icon">
			<ContextMenuLinkItem label={t("Edit")} title={t("Edit")} to={`${hotel.id}`} icon="edit" shortcut="primary" />
			{hotel.bookings === 0 && (
				<ContextMenuDeleteItem
					label={t("Delete")}
					action={() => deleteHotel(hotel.id)}
					title={t("Delete")}
					heading={t("Delete")}
					labels={{ close: t("Close"), confirm: t("Confirm") }}
				>
					{t("Are you sure you want to delete this hotel?")}
				</ContextMenuDeleteItem>
			)}
		</ContextMenu>
	);
};
