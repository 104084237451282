import { SingleSelectControl } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";

interface Props {
	value: string;
	onChange: (locale: string) => void;
	className?: string;
}

export const EmailSendOutPreviewLocaleSelect: FC<Props> = ({ value, onChange, className }) => {
	const { t } = useTranslation();
	const { locales, fallbackLocale } = useContentLocale();

	return (
		<SingleSelectControl
			className={className}
			options={locales}
			name="locale"
			label={t("Preview language")}
			getValue={String}
			getLabel={String}
			clearable={false}
			value={value}
			onChange={(e) => onChange(e.target.value || fallbackLocale)}
		/>
	);
};
