import { useQuery } from "@tanstack/react-query";
import { parse } from "date-fns";
import { contingentOverviewKeys, contingentOverviewService } from "./contingentOverviewService";

export const useContingentOverviewDaysQuery = () => {
	const { data, isLoading } = useQuery({
		queryKey: contingentOverviewKeys.days,
		queryFn: () => contingentOverviewService.days().then((r) => r.data.data),
	});

	return {
		data: data ? data.map((day) => parse(day, "yyyy-MM-dd", new Date())) : [],
		isLoading: isLoading,
	};
};
