import { CrudDrawer, Id, SingleSelectField, TextField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRegistrationFormSectionDetailsQuery } from "~shared/api/registrationForms/registrationFormQueries";
import { RegistrationFormSectionSettingsFields } from "./RegistrationFormSectionSettingsFields";
import { SectionTypeSelectField } from "./SectionTypeSelectField";
import { useRegistrationFormSection } from "./useRegistrationFormSection";

interface Props {
	open: boolean;
	onClose: () => void;
	typeIsDisabled?: boolean;
	registrationFormId: Id;
	stepId: Id;
	sectionId: Id;
}

const colorOptions = ["background", "content"];
export const EditRegistrationFormSectionDrawer: FC<Props> = ({
	stepId,
	sectionId,
	registrationFormId,
	open,
	onClose,
	typeIsDisabled = false,
}) => {
	const { t } = useTranslation();
	const { section } = useRegistrationFormSectionDetailsQuery(registrationFormId, stepId, sectionId, open);
	const { updateRegistrationFormSection, validate } = useRegistrationFormSection(registrationFormId, stepId);

	if (!section) {
		return null;
	}

	return (
		<CrudDrawer
			open={open}
			onSubmit={async (value) => {
				const success = await updateRegistrationFormSection(sectionId, value);

				if (success) {
					onClose();
				}

				return success;
			}}
			initialValues={section}
			onClose={onClose}
			heading={t("Edit registration form section")}
			validate={validate}
			body={
				<>
					<TextField name="name" label={t("Name")} />
					<SingleSelectField
						name="backgroundColor"
						label={t("Color")}
						options={colorOptions}
						getLabel={(x) => x}
						getValue={(x) => x}
					/>
					{!typeIsDisabled && <SectionTypeSelectField />}
					<RegistrationFormSectionSettingsFields />
				</>
			}
		/>
	);
};
