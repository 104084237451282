import { mapBaseBlockFormToRequest, mapBaseBlockResourceToForm, validateBlockForm } from "@dgs/core";
import { EmailHeadlineBlockForm } from "./EmailHeadlineBlockForm";
import { IEmailHeadlineBlockForm, IEmailHeadlineBlockView } from "./emailHeadlineBlockTypes";
import { EmailHeadlineBlockView } from "./EmailHeadlineBlockView";

export const emailHeadlineBlockView: IEmailHeadlineBlockView = {
	View: EmailHeadlineBlockView,
};

export const emailHeadlineBlockForm: IEmailHeadlineBlockForm = {
	blockType: "emailHeadlineBlock",
	type: "block",
	Form: EmailHeadlineBlockForm,
	Preview: EmailHeadlineBlockView,
	emptyValue: {
		size: 1,
	},
	emptyTranslatedValue: {
		headline: "",
	},
	mapEditResourceToFormState: (resource, locales, fallbackLocale, emptyValue) =>
		mapBaseBlockResourceToForm(resource, locales, fallbackLocale, emptyValue),
	mapFormStateToRequest: (resource, form) => mapBaseBlockFormToRequest(resource.type, form),
	validate: validateBlockForm,
};

export const emailHeadlineBlock = {
	...emailHeadlineBlockView,
	...emailHeadlineBlockForm,
};
