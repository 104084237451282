import { SingleSelectField } from "@dgs/core";
import { useField } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useInvoiceProviderTypeOptionsQuery } from "~admin/shared/api/invoices";
import { IInvoiceProviderFormState, InvoiceProviderType } from "~shared/types/invoiceProvider";

export const InvoiceProviderTypeSelectField: FC = () => {
	const { t } = useTranslation();
	const [, , { setValue: setConfig }] = useField<IInvoiceProviderFormState["config"]>("config");
	const { typeOptions } = useInvoiceProviderTypeOptionsQuery();

	const changeConfigByType = useCallback(
		(e: InvoiceProviderType) => {
			switch (e) {
				case "BMW":
					return setConfig({
						apiKey: "",
						merchantId: "",
						merchantDescription: "",
						template: {
							brand: "",
							variantName: "",
							language: "",
							country: "",
						},
						accountingPassword: "",
						accountingUsername: "",
						inTestMode: true,
					});
				case "DGS":
					return setConfig({});
			}
		},
		[setConfig],
	);

	return (
		<SingleSelectField
			options={typeOptions}
			label={t("Type")}
			name="type"
			getValue={(x) => x}
			getLabel={(x) => x}
			onChange={(e) => e.target.value && changeConfigByType(e.target.value)}
		/>
	);
};
