import { FileSingleSelect, TextField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StreetWrapper = styled.div`
	display: grid;
	grid-template-columns: 3fr 1fr;
	gap: ${({ theme }) => theme.spacing(2)};
`;

const CityWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr;
	gap: ${({ theme }) => theme.spacing(2)};
`;

export const MerchantFormFields: FC = () => {
	const { t } = useTranslation();
	return (
		<>
			<TextField label={t("Name")} name="name" required={true} />
			<TextField label={t("Email")} name="email" required={true} />
			<TextField label={t("Phone")} name="phone" type="tel" />
			<StreetWrapper>
				<TextField name="street" label={t("Street")} required={true} />
				<TextField name="houseNumber" label={t("House number")} required={true} />
			</StreetWrapper>
			<CityWrapper>
				<TextField name="zip" label={t("ZIP")} required={true} />
				<TextField name="city" label={t("City")} required={true} />
			</CityWrapper>
			<TextField name="country" label={t("Country")} required={true} />
			<TextField label={t("Bank account holder")} name="bankAccountHolder" required={true} />
			<TextField label={t("Bank")} name="bank" required={true} />
			<TextField label={t("IBAN")} name="iban" required={true} />
			<TextField label={t("BIC")} name="bic" required={true} />
			<TextField label={t("VAT ID")} name="vatId" required={true} />
			<TextField label={t("Trade register")} name="tradeRegister" required={true} />
			<TextField label={t("Trade register number")} name="tradeRegisterNumber" required={true} />
			<FileSingleSelect name="logo" label={t("Logo")} />
		</>
	);
};
