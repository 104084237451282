import {
	AutoDataList,
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	FormattedDateTime,
	HasPermission,
	IDataListColumn,
	Icon,
	IconButton,
	ListFetcher,
	useDynamicConfig,
	useOpen,
} from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { importKeys, importService } from "~shared/api/imports";
import { useImportGuests } from "~shared/import/ImportGuestProvider";
import { GuestImport, GuestImportStatus } from "~shared/types/guestImport";
import { ImportFileDrawer } from "./ImportFileDrawer";

const ImportErrors = styled.span<{ $hasErrors: boolean }>`
	color: ${({ $hasErrors, theme }) =>
		$hasErrors ? theme.colors.palette.danger.main.base : theme.colors.palette.success.main.base};
	display: flex;
	align-items: center;
	justify-content: center;
	gap: ${({ theme }) => theme.spacing(1)};
`;

const ImportErrorIcon = styled(Icon)`
	height: 16px;
	width: 16px;
`;

interface Props {
	queryKey?: string[];
	fetcher?: ListFetcher<any, any, any>;
	isNominationModule?: boolean;
}

export const ImportOverview: FC<Props> = ({
	queryKey = importKeys.guests.list,
	fetcher = importService.guests.index,
	isNominationModule = false,
}) => {
	const { t } = useTranslation();
	const { open, handleOpen, handleClose } = useOpen();
	const { setHeaderRow, setGuestImportId, setImportType } = useImportGuests();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { nominationManagerApiUrl } = useDynamicConfig();

	const navigateToDataPage = useCallback(
		(guestImport: GuestImport) => {
			setGuestImportId(guestImport.id);
			setHeaderRow(guestImport.headers);
			setImportType(guestImport.type);
			navigate("../data");
		},
		[navigate, setGuestImportId, setHeaderRow, setImportType],
	);

	const navigateToDataOrErrorPage = useCallback(
		(guestImport: GuestImport) =>
			guestImport.status === GuestImportStatus.OPEN
				? navigateToDataPage(guestImport)
				: navigate(`../errors/${guestImport.id}`),
		[navigate, navigateToDataPage],
	);

	const dataListColumns: IDataListColumn<GuestImport>[] = useMemo(
		() => [
			{
				heading: t("Status"),
				valueKey: "status",
				type: "value",
				render: (guestImport) => t(`GuestImport.${guestImport.status}` as any),
			},
			{
				heading: t("Created at"),
				valueKey: "createdAt",
				type: "value",
				render: (guestImport) => <FormattedDateTime dateTime={guestImport.createdAt} />,
			},
			{
				heading: t("Created by"),
				valueKey: "user",
				type: "value",
				render: (guestImport) => guestImport.user.name,
			},
			{
				heading: t("Successful imports"),
				valueKey: "successfulImports",
				type: "value",
				render: (guestImport) => guestImport.successfulImports,
			},
			{
				heading: t("Errors"),
				valueKey: "errors",
				type: "value",
				render: (guestImport) => (
					<ImportErrors $hasErrors={guestImport.errors !== 0}>
						<ImportErrorIcon icon={guestImport.errors !== 0 ? "warning" : "check"} /> {guestImport.errors}
					</ImportErrors>
				),
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (guestImport) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuItem
							action={() => navigateToDataOrErrorPage(guestImport)}
							label={
								guestImport.status === GuestImportStatus.OPEN ? t("Go to import data page") : t("Go to import errors")
							}
							title={
								guestImport.status === GuestImportStatus.OPEN ? t("Go to import data page") : t("Go to import errors")
							}
							icon={guestImport.status === GuestImportStatus.OPEN ? "caret" : "eye"}
							shortcut="primary"
						/>
						{guestImport.status === GuestImportStatus.OPEN && (
							<ContextMenuDeleteItem
								label={t("Delete")}
								action={async () => {
									await importService.guests.remove(guestImport.id);
									await queryClient.invalidateQueries({ queryKey: queryKey });
								}}
								title={t("Delete")}
								shortcut="danger"
								heading={t("Delete")}
								labels={{
									close: t("Close"),
									confirm: t("Confirm"),
								}}
							>
								{t("Are you sure you want to delete this import?")}
							</ContextMenuDeleteItem>
						)}
					</ContextMenu>
				),
			},
		],
		[navigateToDataOrErrorPage, queryClient, queryKey, t],
	);

	return (
		<>
			<AutoDataList
				heading={t("Imports")}
				headerActions={
					<HasPermission permissions={["import.create"]}>
						{isNominationModule && (
							<a target="_blank" href={`${nominationManagerApiUrl}/guest-imports/template`} rel="noreferrer">
								<IconButton
									type="button"
									color="primary"
									title={t("Download template")}
									size="small"
									icon="file_download"
								/>
							</a>
						)}
						<IconButton onClick={handleOpen} color="primary" size="small" title={t("Create import")} icon="plus" />
					</HasPermission>
				}
				empty={t("No imports available")}
				columns={dataListColumns}
				onRowClick={navigateToDataOrErrorPage}
				queryKey={queryKey}
				fetcher={fetcher}
			/>
			<ImportFileDrawer open={open} onClose={handleClose} />
		</>
	);
};
