import { Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { roomContingentKeys, roomContingentService } from "./contingentService";

export const useRoomContingentQuery = (hotelId: Id, roomTypeId: Id, id: Id) => {
	const { data, isLoading } = useQuery({
		queryKey: roomContingentKeys.details(hotelId, roomTypeId, id),
		queryFn: () => roomContingentService.details(hotelId, roomTypeId, id).then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};
