import { AutoDataList, IDataListColumn, IconLinkButton } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import styled from "styled-components";
import { hotelService } from "~admin/shared/api/hotels";
import { IHotelIndex } from "~shared/types/hotel";
import { HotelsContextMenu } from "./HotelsContextMenu";

interface StyledProps {
	$color: "f00" | "000";
}

const BookingInfo = styled.span<StyledProps>`
	color: #${({ $color }) => $color};
`;

export const HotelList: FC = () => {
	const { t } = useTranslation();

	const dataListColumns: IDataListColumn<IHotelIndex>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Booked rooms"),
				valueKey: "bookings",
				type: "value",
				render: (hotel) => (
					<BookingInfo $color={hotel.bookings > hotel.contingent ? "f00" : "000"}>
						{hotel.bookings} / {hotel.contingent}
					</BookingInfo>
				),
			},
			{
				heading: t("Internal remarks"),
				valueKey: "internalRemark",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (hotel) => <HotelsContextMenu hotel={hotel} />,
			},
		],
		[t],
	);

	return (
		<AutoDataList
			heading={t("Hotels")}
			headerActions={<IconLinkButton to="new" title={t("Add")} color="primary" size="small" icon="plus" />}
			empty={<Navigate to="../overview" />}
			columns={dataListColumns}
			queryKey={hotelService.keys.list}
			fetcher={hotelService.index}
			to={(value) => `${value.id}`}
		/>
	);
};
