import { AutoDataList, Button, IDataListColumn, useEditDrawer } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { ordersKeys, ordersService } from "~admin/shared/api/orders";
import { OrderItemsIndex } from "~admin/shared/types/orders";
import { OrderRefundDrawer } from "~admin/shop/orders/orderItems/RefundDrawer";
import { mapFetcherConfigToAxiosConfig } from "~shared/api/indexMapper";

export const OrderItemsList: FC = () => {
	const { orderId = "" } = useParams<"orderId">();
	const { t } = useTranslation();
	const drawerProps = useEditDrawer();

	const dataListColumns: IDataListColumn<OrderItemsIndex>[] = useMemo(
		() => [
			{
				heading: t("Shop item name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Quantity"),
				valueKey: "quantity",
				type: "value",
			},
			{
				heading: t("Net price"),
				valueKey: "netPrice",
				type: "value",
			},
			{
				heading: t("Tax"),
				valueKey: "tax",
				type: "value",
			},
			{
				heading: t("Gross price"),
				valueKey: "grossPrice",
				type: "value",
			},
			{
				heading: t("Total") + t("Gross price"),
				valueKey: "totalGrossPrice",
				type: "value",
			},
			{
				heading: t("creditNote") + " (" + t("Gross price") + ")",
				valueKey: "creditNote",
				type: "value",
			},
			{
				heading: t("Deposit in percent"),
				valueKey: "depositInPercent",
				type: "value",
				render: (value) => (value.depositInPercent ? value.depositInPercent + "%" : ""),
			},
		],
		[t],
	);

	return (
		<>
			<AutoDataList
				heading={t("Order items")}
				empty={t("No order items available")}
				headerActions={
					<Button color="primary" size="small" onClick={() => drawerProps.handleOpen(orderId)}>
						{t("Refund")}
					</Button>
				}
				columns={dataListColumns}
				queryKey={ordersKeys.items(orderId)}
				fetcher={(config) => ordersService.items(orderId, mapFetcherConfigToAxiosConfig(config))}
			/>
			<OrderRefundDrawer orderId={orderId} open={drawerProps.open} onClose={drawerProps.onClose} />
		</>
	);
};
