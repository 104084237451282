import { IBaseBlockIndexResource, Id } from "@dgs/core";
import { ConsentListItem } from "~shared/types/consent";
import { EmailResponse } from "~shared/types/email";
import { IRegistrationFormStepIndex } from "~shared/types/step";

export interface IRegistrationFormIndex {
	id: string;
	name: string;
}

export interface IRegistrationFormForm {
	name: string;
}

export interface IRegistrationFormRequest {
	name: string;
}

export interface IRegistrationFormDetails {
	id: string;
	name: string;
	landingBlock: IBaseBlockIndexResource | null;
	summaryBlock: IBaseBlockIndexResource | null;
	steps: IRegistrationFormStepIndex[];
	contingent: number | null;

	closedBlock: IBaseBlockIndexResource | null;
	successBlock: IBaseBlockIndexResource | null;
	editedBlock: IBaseBlockIndexResource | null;
	declinedBlock: IBaseBlockIndexResource | null;
	cancelledBlock: IBaseBlockIndexResource | null;
	privateBlock: IBaseBlockIndexResource | null;

	confirmDeclinationBlock: IBaseBlockIndexResource | null;
	confirmCancellationBlock: IBaseBlockIndexResource | null;

	confirmReplacementBlock: IBaseBlockIndexResource | null;
	replacedBlock: IBaseBlockIndexResource | null;
	screeningStatusPositiveBlock: IBaseBlockIndexResource | null;
	paymentSuccessfulBlock: IBaseBlockIndexResource | null;

	closeAt: string | null;
	isCancelable: boolean;
	isEditable: boolean;
	isShopEnabled: boolean;
	mustApproveShopOrders: boolean;
	isReplacementEnabled: boolean;
	visibility: RegistrationVisibility;

	registeredMail: EmailResponse | null;
	editedMail: EmailResponse | null;
	declinedMail: EmailResponse | null;
	cancelledMail: EmailResponse | null;
	invoiceMail: EmailResponse | null;
	paymentMail: EmailResponse | null;
	remainingPaymentMail: EmailResponse | null;
	refundConfirmationMail: EmailResponse | null;
	screeningStatusPositiveEmail: EmailResponse | null;

	replacementNominatedMail: EmailResponse | null;
	replacementInvitationMail: EmailResponse | null;

	landingPageConsents: ConsentListItem[];
	summaryPageConsents: ConsentListItem[];

	isCodePageEnabled: boolean;
	codePageBlock: IBaseBlockIndexResource | null;
}

export type IRegistrationFormSettingsForm = Omit<IRegistrationFormDetails, "id" | "name">;

export interface IRegistrationFormSettingsRequest {
	closeAt: string | null;
	isCancelable: boolean;
	isEditable: boolean;
	isShopEnabled: boolean;
	mustApproveShopOrders: boolean;
	isReplacementEnabled: boolean;
	visibility: RegistrationVisibility;
	registeredMailId: Id | null;
	editedMailId: Id | null;
	declinedMailId: Id | null;
	cancelledMailId: Id | null;
	invoiceMailId: Id | null;
	paymentMailId: Id | null;
	remainingPaymentMailId: Id | null;
	replacementNominatedMailId: Id | null;
	replacementInvitationMailId: Id | null;
	refundConfirmationMailId: Id | null;
	closedBlockId: Id | null;
	successBlockId: Id | null;
	editedBlockId: Id | null;
	declinedBlockId: Id | null;
	cancelledBlockId: Id | null;
	confirmDeclinationBlockId: Id | null;
	confirmCancellationBlockId: Id | null;
	privateBlockId: Id | null;
	confirmReplacementBlockId: Id | null;
	replacedBlockId: Id | null;
	isCodePageEnabled: boolean;
	codePageBlockId: Id | null;
	contingent: number | null;
	screeningStatusPositiveBlockId: Id | null;
	screeningStatusPositiveEmailId: Id | null;
	paymentSuccessfulBlockId: Id | null;
}

export enum RegistrationVisibility {
	ALL = "all",
	PRIVATE = "private",
	PUBLIC = "public",
	CLOSED = "closed",
}

export const RegistrationFormItemTypes = {
	STEP: "step",
	STEP_BLOCK: "stepBlock",
	SECTION: "section",
	SECTION_BLOCK: "sectionBlock",
	DATA_FIELD: "dataField",
	CONSENT: "consent",
};
