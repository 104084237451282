import { Id, apiIsOK, useToasts } from "@dgs/core";
import { menuKeys } from "@dgs/core/lib/modules/menu/menuService";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { MenuItemDrawer } from "~admin/menu/MenuItemDrawer";
import { useMenuItem } from "~admin/shared/api/menu/menuItemQueries";
import { menuItemService } from "~admin/shared/api/menu/menuItemService";
import { IMenuItemRequest } from "~shared/types/menuItem";

interface Props {
	menuItemId: Id;
	open: boolean;
	onClose: () => void;
}
export const MenuItemEditDrawer: FC<Props> = ({ menuItemId, open, onClose }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { menuItem } = useMenuItem(menuItemId);

	const handleEdit = useCallback(
		async (menuItem: IMenuItemRequest) => {
			const response = await menuItemService.update(menuItemId, menuItem);

			if (apiIsOK(response)) {
				showToast({ body: t("Menu item was saved."), title: t("Save menu item"), type: "success" });
				await queryClient.invalidateQueries({ queryKey: menuKeys.index });
				await queryClient.invalidateQueries({ queryKey: menuItemService.keys.index });
				return true;
			}
			return false;
		},
		[menuItemId, queryClient, showToast, t],
	);

	return (
		menuItem && (
			<MenuItemDrawer
				title={t("Edit menu item")}
				open={open}
				onClose={onClose}
				menuItem={menuItem}
				handleSubmit={handleEdit}
			/>
		)
	);
};
