import { Id } from "@dgs/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDataFieldsQuery } from "~shared/api/dataFields";
import { ImportableGuestAttributeOption } from "~shared/types/guestImport";
import {
	GuestViewAttributeResource,
	GuestViewColumnAttribute,
	GuestViewRelationAttribute,
} from "~shared/types/guestView";

interface Props {
	attributes: GuestViewAttributeResource[];
	requiredAttributeIds?: Id[];
}

const supportedImportColumns: GuestViewColumnAttribute["entityType"][] = [
	"status",
	"referenceId",
	"registrationLocale",
	"registrationCode",
];

const supportedImportRelations: GuestViewRelationAttribute["entityType"][] = [
	"tags",
	"groups",
	"registrationForm",
	"wave",
	"tenant",
];

export const useImportableGuestAttributeOptions = ({
	attributes,
	requiredAttributeIds,
}: Props): ImportableGuestAttributeOption[] => {
	const { data: dataFields } = useDataFieldsQuery();
	const { t } = useTranslation();

	return useMemo(() => {
		return attributes.flatMap((attribute) => {
			const optionWithoutName: Omit<ImportableGuestAttributeOption, "name"> = {
				id: attribute.id,
				required: !!requiredAttributeIds?.includes(attribute.id),
				attribute,
			};
			switch (attribute.type) {
				case "column":
					if (!supportedImportColumns.includes(attribute.entityType)) {
						return [];
					}
					return [{ ...optionWithoutName, name: t(attribute.entityType) }];
				case "relation":
					if (!supportedImportRelations.includes(attribute.entityType)) {
						return [];
					}
					return [{ ...optionWithoutName, name: t(attribute.entityType) }];
				case "dataField": {
					const dataField = dataFields.find(({ id }) => id === attribute.entityType);
					if (!dataField) {
						return [];
					}
					return [{ ...optionWithoutName, name: dataField.name }];
				}
			}
		});
	}, [dataFields, attributes, t, requiredAttributeIds]);
};
