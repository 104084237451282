import { BaseBlockForm, FormProps, IBaseBlockEditResource, NameField } from "@dgs/core";
import React, { FC } from "react";
import { emailSummaryBlockForm } from "./EmailSummaryBlock";

export const EmailSummaryBlockForm: FC<FormProps<IBaseBlockEditResource>> = ({ block, onSubmit }) => {
	return (
		<BaseBlockForm block={block} onSubmit={onSubmit} blockForm={emailSummaryBlockForm}>
			<NameField blockType={block.type} />
		</BaseBlockForm>
	);
};
