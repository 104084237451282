import {
	Button,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerFooterActions,
	DrawerHeader,
	DrawerHeading,
	SubmitButton,
	apiIsOK,
	useOpen,
	useToasts,
} from "@dgs/core";
import { Form, Formik, FormikErrors } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { dataFieldService } from "~shared/api/dataFields";
import { IDataFieldOptionResource } from "~shared/types/dataField";
import { FileDataFieldSelect } from "./FileDataFieldSelect";

interface FormValues {
	dataField: IDataFieldOptionResource | null;
}

const initialValues: FormValues = {
	dataField: null,
};

export const ExportFileDataField: FC = () => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const fileDataFieldDrawer = useOpen();

	const createExport = useCallback(
		async (formValues: FormValues) => {
			const response = await dataFieldService.exportFileDataField(formValues.dataField?.id as number);
			if (apiIsOK(response)) {
				showToast({ body: t("An export is created, please wait"), title: t("Export"), type: "success" });
				fileDataFieldDrawer.handleClose();
			}
		},
		[showToast, t, fileDataFieldDrawer],
	);

	const validate = useCallback(
		(formValues: FormValues) => {
			const errors: FormikErrors<FormValues> = {};

			if (formValues.dataField === null) {
				errors.dataField = t("Required field");
			}

			return errors;
		},
		[t],
	);

	return (
		<>
			<Button type="button" onClick={fileDataFieldDrawer.handleOpen}>
				{t("Create file data field export")}
			</Button>
			<Drawer open={fileDataFieldDrawer.open} onClose={fileDataFieldDrawer.handleClose}>
				<DrawerHeader onClose={fileDataFieldDrawer.handleClose}>
					<DrawerHeading>{t("Create file data field export")}</DrawerHeading>
				</DrawerHeader>
				<Formik initialValues={initialValues} onSubmit={createExport} validate={validate}>
					<Form>
						<DrawerBody>
							<FileDataFieldSelect label={t("Data field")} name="dataField" required />
						</DrawerBody>
						<DrawerFooter>
							<DrawerFooterActions />
							<SubmitButton>{t("Export")}</SubmitButton>
						</DrawerFooter>
					</Form>
				</Formik>
			</Drawer>
		</>
	);
};
