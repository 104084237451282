import { IconButton } from "@dgs/core";
import { useFormikContext } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RuleSet } from "~shared/types/ruleSet";

export const StyledButton = styled(IconButton)`
	margin-left: ${({ theme }) => theme.spacing(2)};
`;
export const RemoveRuleOrActionButton: FC<{ index: number; type: "rules" | "actions" }> = ({ index, type }) => {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<RuleSet<any, any>>();
	const onClick = useCallback(async () => {
		values[type].splice(index, 1);
		void setFieldValue(type, values[type]);
	}, [index, setFieldValue, type, values]);

	return <StyledButton type="button" title={t("Remove")} color="danger" size="small" onClick={onClick} icon="delete" />;
};
