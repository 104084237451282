import { BaseCrudOptionService, Id, keyToUrl } from "@dgs/core";
import { IMerchant, IMerchantIndex, IMerchantRequest } from "~shared/types/merchant";

export class MerchantService extends BaseCrudOptionService<
	IMerchantIndex,
	IMerchant,
	IMerchantIndex,
	IMerchantRequest
> {
	public keys = {
		index: ["merchants"],
		list: ["merchants", "list"],
		options: ["merchants", "options"],
		details: (merchantId: Id | null) => ["merchants", `${merchantId}`],
	};
	protected urls = {
		index: keyToUrl(this.keys.index),
		options: keyToUrl(this.keys.options),
		details: (shopItemId: Id | null) => keyToUrl(this.keys.details(shopItemId)),
	};
}

export const merchantService = new MerchantService();
