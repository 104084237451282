import { Id, LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { format } from "date-fns";
import { mapFetcherConfigToAxiosConfig } from "~shared/api/indexMapper";
import {
	IRoomContingentDetailsResource,
	IRoomContingentIndex,
	IRoomContingentRequest,
	IRoomContingentResource,
	IRoomContingentUpdateRequest,
	IRoomTypeWithContingentResource,
} from "~shared/types/roomContingent";

export const roomContingentKeys = {
	index: (hotelId: Id, roomTypeId: Id) => ["hotels", `${hotelId}`, "room-types", `${roomTypeId}`, "contingent"],
	details: (hotelId: Id, roomTypeId: Id, id: Id) => [
		"hotels",
		`${hotelId}`,
		"room-types",
		`${roomTypeId}`,
		"contingent",
		`${id}`,
	],
	optionsByDay: (day: Date, guestId?: Id) => ["room-contingents", "by-day", day.toISOString(), `${guestId || ""}`],
	optionsByDateRange: (start: string, end: string, guestId?: Id) => [
		"room-contingents",
		"by-date-range",
		start,
		end,
		`${guestId || ""}`,
	],
};

export const roomContingentUrls = {
	index: (hotelId: Id, roomTypeId: Id) => keyToUrl(roomContingentKeys.index(hotelId, roomTypeId)),
	details: (hotelId: Id, roomTypeId: Id, id: Id) => keyToUrl(roomContingentKeys.details(hotelId, roomTypeId, id)),
	optionsByDay: (day: Date, guestId?: Id) => keyToUrl(roomContingentKeys.optionsByDay(day, guestId).slice(0, -2)),
	optionsByDateRange: (start: string, end: string, guestId?: Id) =>
		keyToUrl(roomContingentKeys.optionsByDateRange(start, end, guestId).slice(0, -3)),
};

export const roomContingentService = {
	index: (hotelId: Id, roomTypeId: Id) => (config: ListFetcherConfig<any>) => {
		return axios.get<LaravelResponse<IRoomContingentIndex[]>>(
			roomContingentUrls.index(hotelId, roomTypeId),
			mapFetcherConfigToAxiosConfig(config),
		);
	},
	details: (hotelId: Id, roomTypeId: Id, id: Id) => {
		return axios.get<LaravelResponse<IRoomContingentResource>>(roomContingentUrls.details(hotelId, roomTypeId, id));
	},
	post: (hotelId: Id, roomTypeId: Id, roomContingent: IRoomContingentRequest) => {
		return axios.post<LaravelResponse<IRoomContingentResource>>(
			roomContingentUrls.index(hotelId, roomTypeId),
			roomContingent,
		);
	},
	put: (hotelId: Id, roomTypeId: Id, id: Id, roomContingent: IRoomContingentUpdateRequest) => {
		return axios.put<LaravelResponse<IRoomContingentResource>>(
			roomContingentUrls.details(hotelId, roomTypeId, id),
			roomContingent,
		);
	},
	delete: (hotelId: Id, roomTypeId: Id, id: Id) => {
		return axios.delete<LaravelResponse<IRoomContingentResource>>(roomContingentUrls.details(hotelId, roomTypeId, id));
	},
	optionsByDay: (day: Date, guestId?: Id, config?: AxiosRequestConfig) => {
		return axios.get<LaravelResponse<IRoomContingentDetailsResource[]>>(roomContingentUrls.optionsByDay(day, guestId), {
			...config,
			params: { ...config?.params, day: format(day, "y-MM-dd"), guestId },
		});
	},
	optionsByDateRange: (start: string, end: string, guestId?: Id, config?: AxiosRequestConfig) => {
		return axios.get<LaravelResponse<IRoomTypeWithContingentResource[]>>(
			roomContingentUrls.optionsByDateRange(start, end, guestId),
			{
				...config,
				params: { ...config?.params, start, end, guestId },
			},
		);
	},
};
