import { IconButton, Trigger } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FileDrawer } from "~admin/files/FileDrawer";

export const CreateFileButton: FC = () => {
	const { t } = useTranslation();

	return (
		<Trigger render={(props) => <FileDrawer {...props} title={t("Create file")} />}>
			{(triggerProps) => <IconButton {...triggerProps} color="primary" size="small" title={t("Add")} icon="plus" />}
		</Trigger>
	);
};
