import { Card, CardBody, CardHeader, CardHeading, Empty, Id } from "@dgs/core";
import React, { ReactNode } from "react";
import styled from "styled-components";

const ListItem = styled.li`
	${({ theme }) => theme.borderRadius.medium};
	font-size: ${({ theme }) => theme.fonts.sizes.small};
	border: 1px solid ${({ theme }) => theme.colors.palette.grey[20]};
	background-color: ${({ theme }) => theme.colors.palette.white};
	overflow: hidden;
	padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
	margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const NormalCardHeading = styled(CardHeading)`
	font-size: ${({ theme }) => theme.fonts.sizes.normal};
`;

interface Item {
	id: Id;
}

interface Props<T extends Item> {
	items: T[];
	empty: string;
	heading: string;
	children: (item: T) => ReactNode;
}

export const RegistrationFormShopList = <T extends Item>({ items, empty, heading, children }: Props<T>) => {
	return (
		<Card $color="content">
			<CardHeader>
				<NormalCardHeading>{heading}</NormalCardHeading>
			</CardHeader>
			<CardBody>
				{items.length === 0 && <Empty>{empty}</Empty>}
				<ul>
					{items.map((item) => (
						<ListItem key={item.id}>{children(item)}</ListItem>
					))}
				</ul>
			</CardBody>
		</Card>
	);
};
