import { FCC, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { dashboardKeys } from "~shared/api/dashboard";
import { emailKeys, emailService, useEmailQuery } from "~shared/api/emails";
import { useRecipientCountQuery } from "~shared/api/guests";
import { useGuestFilter } from "~shared/guestFilters";
import { IActiveGuestFilterEntry } from "~shared/guestFilters/guestFilterTypes";
import { EmailResponse, RecipientType } from "~shared/types/email";
import { GuestIndexResource } from "~shared/types/newGuestTypes";

interface EmailSendOutState {
	currentMail: EmailResponse | null;
	recipients: GuestIndexResource[];
	recipientCount: number;
	recipientType: RecipientType;
	changeRecipientType: (type: RecipientType) => void;
	setRecipients: (guests: GuestIndexResource[]) => void;
	sendMail: (sendAt: string | null, locale: string) => void;
}

const EmailSendOutContext = React.createContext<EmailSendOutState | undefined>(undefined);

export const EmailSendOutProvider: FCC = ({ children }) => {
	const [recipients, setRecipients] = useState<GuestIndexResource[]>([]);
	const [recipientType, setRecipientType] = useState<RecipientType>(RecipientType.NONE);
	const { mapGuestFilterToGuestFilterRequest, filters, setFilters } = useGuestFilter();
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const { mailId = "" } = useParams<"mailId">();
	const queryClient = useQueryClient();
	const { data: mail } = useEmailQuery(mailId);
	const { data: recipientCount } = useRecipientCountQuery(
		mapGuestFilterToGuestFilterRequest(filters) as IActiveGuestFilterEntry[],
		recipientType !== RecipientType.ALL && recipientType !== RecipientType.NONE
			? recipients.map((recipient) => recipient.id)
			: [],
		recipientType,
	);

	const _recipientCount = useMemo(
		() => (recipientType !== RecipientType.NONE && recipientCount ? recipientCount.guestCount : 0),
		[recipientCount, recipientType],
	);
	const changeRecipientType = useCallback(
		(type: RecipientType) => {
			if (type === RecipientType.NONE || type === RecipientType.GUEST_SELECT) {
				setRecipients([]);
			}
			if (type === RecipientType.ALL) {
				setFilters([]);
			}
			setRecipientType(type);
		},
		[setFilters],
	);

	const sendMail = useCallback(
		async (sendAt: string | null, locale: string) => {
			const response = await emailService.sendOuts.post({
				customEmailId: mailId,
				sendAt,
				locale,
				receivers: {
					filters: mapGuestFilterToGuestFilterRequest(filters) as IActiveGuestFilterEntry[],
					guestIds:
						recipientType !== RecipientType.ALL && recipientType !== RecipientType.NONE
							? recipients.map((recipient) => recipient.id)
							: [],
					recipientType: recipientType,
				},
			});

			if (apiIsOK(response)) {
				await queryClient.invalidateQueries({ queryKey: emailKeys.sendOuts.index });
				await queryClient.invalidateQueries({ queryKey: dashboardKeys.index });
				showToast({
					body: t("Email send out has been started."),
					title: t("Email send out"),
					type: "success",
				});
			}
		},
		[filters, mailId, mapGuestFilterToGuestFilterRequest, queryClient, recipientType, recipients, showToast, t],
	);

	return (
		<EmailSendOutContext.Provider
			value={{
				recipients,
				recipientCount: _recipientCount,
				setRecipients,
				recipientType,
				changeRecipientType,
				currentMail: mail || null,
				sendMail,
			}}
		>
			{children}
		</EmailSendOutContext.Provider>
	);
};

export const useEmailSendOut = () => {
	const context = useContext(EmailSendOutContext);

	if (context === undefined) {
		throw new Error(`Context undefined. Are you missing the EmailSendOutProvider?`);
	}

	return context;
};
