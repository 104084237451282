import { useCallback, useMemo } from "react";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import {
	PersonalizedDocument,
	PersonalizedDocumentRequest,
	PersonalizedDocumentTypes,
} from "~shared/types/personalizedDocument";

export const usePersonalizedDocumentForm = () => {
	const { locales } = useContentLocale();

	const emptyPersonalizedDocument: PersonalizedDocument = useMemo(
		() => ({
			id: -1,
			type: PersonalizedDocumentTypes.CR80_LANDSCAPE,
			translations: locales.reduce(
				(previousValue, currentValue) => ({
					...previousValue,
					[currentValue]: {
						name: "",
						html: "",
						backgroundImage: null,
					},
				}),
				{},
			),
		}),
		[locales],
	);

	const parseTranslations = useCallback(
		(values: PersonalizedDocument) => {
			return {
				...values,
				translations: locales.reduce(
					(acc, locale) => ({
						...acc,
						[locale]: {
							...values.translations[locale],
							backgroundImageId: values.translations[locale]?.backgroundImage?.id ?? null,
						},
					}),
					{},
				),
			} as PersonalizedDocumentRequest;
		},
		[locales],
	);

	return { emptyPersonalizedDocument, parseTranslations };
};
