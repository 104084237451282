import { BaseCrudService, Id, LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios from "axios";
import { waitingListKeys } from "~admin/shared/api/waitingList/waitingListKeys";
import { mapFetcherConfigToAxiosConfig } from "~shared/api/indexMapper";
import { IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";
import { GuestIndexResource } from "~shared/types/newGuestTypes";
import {
	IWaitingListCreateRequest,
	IWaitingListIndexResource,
	IWaitingListUpdateRequest,
	IWaitingListUpdateResource,
} from "~shared/types/waitingList";

class WaitingListService extends BaseCrudService<
	IWaitingListIndexResource,
	IWaitingListUpdateResource,
	IWaitingListUpdateRequest,
	IWaitingListCreateRequest
> {
	public keys = waitingListKeys;
	protected urls = {
		index: keyToUrl(waitingListKeys.index),
		details: (waitingListId: Id) => keyToUrl(waitingListKeys.details(waitingListId)),
		guests: (waitingListId: Id) => keyToUrl(waitingListKeys.guests(waitingListId)),
	};

	getGuests = (
		waitingListId: Id,
		guestViewId: Id,
		filters: IGuestFilterFormValueEntry[],
		config: ListFetcherConfig<any>,
	) =>
		axios.post<LaravelResponse<GuestIndexResource[]>>(
			this.urls.guests(waitingListId),
			{
				guestViewId,
				filters,
				sort: config.sortParams
					? {
							guestAttributeId: config.sortParams.column,
							direction: config.sortParams.direction,
					  }
					: null,
			},
			mapFetcherConfigToAxiosConfig(config),
		);
}

export const waitingListService = new WaitingListService();
