import { PagedMultiSelectField, PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { guestFileCategoryService } from "~shared/api/guestFileCategory/guestFileCategoryService";

const fetcher = (search: string, page: number) => guestFileCategoryService.options(search, page).then((x) => x.data);

interface Props {
	name: string;
	label: string;
	required?: boolean;
}

export const FileCategorySingleSelectField: FC<Props> = (props) => {
	return <PagedSingleSelectField {...props} fetcher={fetcher} queryKey={guestFileCategoryService.keys.options} />;
};

export const FileCategoryMultiSelectField: FC<Props> = (props) => {
	return <PagedMultiSelectField {...props} fetcher={fetcher} queryKey={guestFileCategoryService.keys.options} />;
};
