import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ruleSetService } from "~shared/api/registrationForms/ruleSets/ruleSetService";
import { ActionFormState } from "~shared/types/actions";
import { RuleFormState } from "~shared/types/rules";
import { RuleSet, RuleSetType } from "~shared/types/ruleSet";

export const useRuleSet = (registrationFormId: string) => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const createRuleSet = useCallback(
		async (ruleSet: RuleSet<RuleFormState, ActionFormState>) => {
			const response = await ruleSetService.postRuleSet(registrationFormId, ruleSet);

			if (apiIsOK(response)) {
				showToast({ body: t("Rule set was saved."), title: t("Save rule set"), type: "success" });
				void queryClient.invalidateQueries({ queryKey: ruleSetService.keys.ruleSets(registrationFormId) });
				return response.data.data;
			}

			return false;
		},
		[queryClient, registrationFormId, showToast, t],
	);

	const updateRuleSet = useCallback(
		async (ruleSet: RuleSet<RuleFormState, ActionFormState>) => {
			const response = await ruleSetService.putRuleSet(registrationFormId, ruleSet);

			if (apiIsOK(response)) {
				showToast({ body: t("Rule set was saved."), title: t("Save rule set"), type: "success" });
				void queryClient.invalidateQueries({ queryKey: ruleSetService.keys.ruleSets(registrationFormId) });
				return true;
			}

			return false;
		},
		[queryClient, registrationFormId, showToast, t],
	);

	const deleteRuleSet = useCallback(
		async (ruleSetId: Id, ruleSetType: RuleSetType) => {
			const response = await ruleSetService.removeRuleSet(registrationFormId, ruleSetId);

			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form was successfully deleted."),
					title: t("Delete registration form"),
					type: "success",
				});
				void queryClient.invalidateQueries({
					queryKey: ruleSetService.keys.ruleSetsByType(registrationFormId, ruleSetType),
				});
				return true;
			}

			return false;
		},
		[queryClient, registrationFormId, showToast, t],
	);

	return {
		createRuleSet,
		updateRuleSet,
		deleteRuleSet,
	};
};
