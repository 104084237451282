import { Id, LaravelResponse } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { DataFieldType } from "~shared/types/dataFieldType";
import { RuleActionTypes, RuleTypes } from "~shared/types/rules";
import { Automation, RuleSetType } from "~shared/types/ruleSet";
import { ruleSetService } from "./ruleSetService";

const ruleSetFetcher = (formId: Id, ruleSetId: Id) => () =>
	ruleSetService.getRuleSet(formId, ruleSetId).then((r) => r.data.data);

export const useRegistrationFormRuleSetQuery = (formId: Id, ruleSetId: Id) => {
	const { data, isLoading } = useQuery({
		queryKey: ruleSetService.keys.ruleSetDetails(formId, ruleSetId),
		queryFn: ruleSetFetcher(formId, ruleSetId),
	});

	return {
		data,
		isLoading,
	};
};

const initialData: Automation[] = [];
const ruleSetsByTypeFetcher = (formId: Id, ruleSetType: RuleSetType) => () =>
	ruleSetService.ruleSetsByType(formId, ruleSetType).then((r) => r.data.data);

export const useRegistrationFormRuleSetsByTypeQuery = (formId: Id, ruleSetType: RuleSetType) => {
	const { data, isLoading, refetch } = useQuery({
		queryKey: ruleSetService.keys.ruleSetsByType(formId, ruleSetType),
		queryFn: ruleSetsByTypeFetcher(formId, ruleSetType),
	});

	return {
		data: data || initialData,
		isLoading,
		refetch,
	};
};

const emptyRules: Record<DataFieldType, RuleTypes[]> = {
	[DataFieldType.TEXT]: [],
	[DataFieldType.CRYPT]: [],
	[DataFieldType.LONG_TEXT]: [],
	[DataFieldType.EMAIL]: [],
	[DataFieldType.NUMBER]: [],
	[DataFieldType.URL]: [],
	[DataFieldType.RADIO]: [],
	[DataFieldType.SELECT_FIELD]: [],
	[DataFieldType.MULTI_SELECT_FIELD]: [],
	[DataFieldType.CHECKBOX_GROUP]: [],
	[DataFieldType.CHECKBOX]: [],
	[DataFieldType.FILE]: [],
	[DataFieldType.DATE]: [],
	[DataFieldType.PHONE]: [],
	[DataFieldType.TIME]: [],
	[DataFieldType.DATETIME]: [],
};

const initialRuleActionTypes: RuleActionTypes = {
	[RuleSetType.Validation]: {
		rules: emptyRules,
		actions: [],
	},
	[RuleSetType.Condition]: {
		rules: emptyRules,
		actions: [],
	},
	[RuleSetType.Automation]: {
		rules: emptyRules,
		actions: [],
	},
};

export const useRuleActionTypeQuery = () => {
	const { data: ruleActionTypes, ...rest } = useQuery({
		queryKey: ["rule-action-types"],
		queryFn: () => axios.get<LaravelResponse<RuleActionTypes>>("rule-action-types").then((r) => r.data.data),
	});

	return {
		...rest,
		ruleActionTypes: (ruleActionTypes || initialRuleActionTypes) as RuleActionTypes,
	};
};
