import { IconButton, SingleSelectControl } from "@dgs/core";
import { useField } from "formik";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ActionFilter, AnyRulesetFilterItem, AnyRulesetFilterItemFormState, RuleFilter } from "~shared/types/ruleSet";

const Wrapper = styled.div<{ $selectCount: number }>`
	display: grid;
	grid-template-columns: repeat(${({ $selectCount }) => $selectCount}, 1fr) min-content;
	gap: ${({ theme }) => theme.spacing(4)};
	align-items: center;
`;

const options: AnyRulesetFilterItem["type"][] = ["color", "action", "rule"];
const filterItemOptions: ActionFilter["filterItem"]["type"][] = ["showDataField", "showSection", "showStep"];

interface Props {
	onAdd: (filterItem: AnyRulesetFilterItemFormState) => void;
}
export const RuleSetFilterItemChooser: FC<Props> = ({ onAdd }) => {
	const { t } = useTranslation();
	const [type, setType] = useState<AnyRulesetFilterItem["type"] | null>(null);
	const [filterItemType, setFilterItemType] = useState<
		ActionFilter["filterItem"]["type"] | RuleFilter["filterItem"]["type"] | null
	>(null);
	const [field] = useField<AnyRulesetFilterItem[]>("filters");

	const handleAdd = () => {
		if (type === "color") {
			onAdd({
				type,
				value: "",
			});
		} else if (type === "action" && filterItemType) {
			onAdd({
				type,
				filterItem: {
					type: filterItemType,
					value: null,
				},
			});
		} else if (type === "rule") {
			onAdd({
				type,
				filterItem: {
					type: "showDataField",
					value: null,
				},
			});
		}
		setType(null);
		setFilterItemType(null);
	};

	return (
		<Wrapper $selectCount={type === "action" ? 2 : 1}>
			<SingleSelectControl
				onChange={(e) => setType(e.target.value)}
				value={type}
				label={t("Choose filter type")}
				name="filterName"
				onClear={() => null}
				options={options.filter((x) => !field.value.find((a) => x === a.type))}
				getLabel={(x) => t(`RuleSetFilterType.${x}`)}
			/>
			{type === "action" && (
				<SingleSelectControl
					onChange={(e) => setFilterItemType(e.target.value)}
					value={filterItemType}
					label={t("Action type")}
					name="actionFilterName"
					onClear={() => null}
					options={filterItemOptions.filter(
						(x) => !field.value.find((a) => a.type === "action" && a.filterItem.type === x),
					)}
					getLabel={(x) => t(`ActionType.${x}`)}
				/>
			)}
			<IconButton
				disabled={!type || (type === "action" && !filterItemType)}
				type="button"
				color="primary"
				title={t("Add filter")}
				onClick={handleAdd}
				icon="plus"
			/>
		</Wrapper>
	);
};
