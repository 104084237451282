import { DrawerAutoDataList, IDrawerDataListColumn, useCrudEntity } from "@dgs/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { tenantService } from "~shared/api/tenants/tenantService";
import { waveService } from "~shared/api/waves/waveService";
import { ITenantFormState, ITenantIndexResource, ITenantRequest } from "~shared/types/tenant";
import { TenantContextMenu } from "./TenantContextMenu";
import { TenantDrawer } from "./TenantDrawer";

const mapToRequest = ({ users, defaultRegistrationForm, ...tenant }: ITenantFormState): ITenantRequest => ({
	...tenant,
	userIds: users.map((x) => x.id),
	defaultRegistrationFormId: defaultRegistrationForm?.id || null,
});

export const TenantList = () => {
	const { t } = useTranslation();
	const crudOperations = useCrudEntity(
		tenantService,
		{
			entityName: t("Tenant"),
			messages: {
				create: t("Tenant was saved."),
				update: t("Tenant was saved."),
				delete: t("Tenant was deleted."),
			},
		},
		[waveService.keys.getContingentOverview],
	);
	const emptyTenant: ITenantFormState = {
		name: "",
		activeStartDate: "",
		activeEndDate: "",
		users: [],
		defaultRegistrationForm: null,
		defaultRegistrationLocale: null,
	};

	const dataListColumns: IDrawerDataListColumn<ITenantIndexResource>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Default registration form"),
				valueKey: "defaultRegistrationForm",
				type: "value",
				render: (value) => value.defaultRegistrationForm?.name || "-",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }, openDrawer) => <TenantContextMenu id={id} openDrawer={openDrawer} />,
			},
		],
		[t],
	);

	return (
		<DrawerAutoDataList
			columns={dataListColumns}
			service={tenantService}
			renderCreateDrawer={(drawerProps) => (
				<TenantDrawer
					{...drawerProps}
					heading={t("Add tenant")}
					initialValues={emptyTenant}
					onSubmit={(entityFormState) => crudOperations.createEntity(mapToRequest(entityFormState))}
				/>
			)}
			renderUpdateDrawer={(entityResource, drawerProps) => (
				<TenantDrawer
					{...drawerProps}
					heading={t("Edit tenant")}
					initialValues={entityResource}
					onSubmit={(entityFormState) => crudOperations.updateEntity(entityResource.id, mapToRequest(entityFormState))}
				/>
			)}
			translations={{
				title: t("Tenants"),
				empty: t("No tenant available."),
			}}
		/>
	);
};
