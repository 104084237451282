import { DrawerAutoDataList, IDrawerDataListColumn, useCrudEntity } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PlaceholderDrawer } from "~admin/placeholders/PlaceholderDrawer";
import { placeholderService } from "~admin/shared/api/placeholders";
import { IPlaceholderIndex, IPlaceholderResource } from "~admin/shared/types/placeholder";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { PlaceholderListContextMenu } from "./PlaceholderListContextMenu";

export const PlaceholderList: FC = () => {
	const { t } = useTranslation();
	const { locales } = useContentLocale();
	const crudOperations = useCrudEntity(placeholderService, {
		entityName: t("Placeholder"),
		messages: {
			create: t("Placeholder was saved."),
			update: t("Placeholder was saved."),
			delete: t("Placeholder was deleted."),
		},
	});

	const dataListColumns: IDrawerDataListColumn<IPlaceholderIndex>[] = useMemo(
		() => [
			{
				heading: t("Key"),
				valueKey: "key",
				type: "value",
			},
			{
				heading: t("Value"),
				valueKey: "value",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (placeholder, openDrawer) => (
					<PlaceholderListContextMenu
						handleEdit={openDrawer}
						handleDelete={() => crudOperations.deleteEntity(placeholder.id)}
					/>
				),
			},
		],
		[t, crudOperations],
	);

	const emptyPlaceholder: IPlaceholderResource = useMemo(
		() => ({
			id: -1,
			key: "",
			translations: locales.reduce(
				(previousValue, currentValue) => ({ ...previousValue, [currentValue]: { value: "" } }),
				{},
			),
		}),
		[locales],
	);

	return (
		<DrawerAutoDataList
			initialSearch={{ search: "", searchFields: ["key"] }}
			translations={{
				title: t("Placeholder"),
				empty: t("No placeholders available"),
			}}
			renderCreateDrawer={(props) => (
				<PlaceholderDrawer
					{...props}
					heading={t("Create placeholder")}
					initialValues={emptyPlaceholder}
					onSubmit={(entity) => crudOperations.createEntity(entity)}
				/>
			)}
			renderUpdateDrawer={(resource, props) => (
				<PlaceholderDrawer
					{...props}
					heading={t("Edit placeholder")}
					initialValues={resource}
					onSubmit={(formState) => crudOperations.updateEntity(resource.id, formState)}
				/>
			)}
			columns={dataListColumns}
			service={placeholderService}
		/>
	);
};
