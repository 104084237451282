import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useGuestViewQuery } from "~shared/api/guestViews";
import { useImportableGuestAttributeOptions } from "~shared/import/importableGuestAttributeOption";
import { ImportDataPage } from "~shared/import/ImportDataPage";
import { ImportErrorPage } from "~shared/import/ImportErrorPage";
import { ImportGuestProvider } from "~shared/import/ImportGuestProvider";
import { ImportOverview } from "~shared/import/ImportOverview";
import { useNominationManagerConfig } from "./useNominationManagerConfig";

export const NominationManagerImportPage: FC = () => {
	const { settings } = useNominationManagerConfig();
	const { data: guestView } = useGuestViewQuery(settings.guestView.id);
	const importableGuestAttributeOptions = useImportableGuestAttributeOptions({
		attributes: guestView?.guestAttributes || [],
		requiredAttributeIds: settings.requiredGuestAttributeIds,
	});

	return (
		<ImportGuestProvider importableGuestAttributeOptions={importableGuestAttributeOptions}>
			<Routes>
				<Route index element={<Navigate to="overview" />} />
				<Route path="overview" element={<ImportOverview isNominationModule={true} />} />
				<Route path="data" element={<ImportDataPage />} />
				<Route path="errors/:guestImportId" element={<ImportErrorPage />} />
			</Routes>
		</ImportGuestProvider>
	);
};
