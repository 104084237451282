import { I18nNameField, I18nTextField, SingleSelectField, TAllBlockTypes, useI18nForm } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";

interface Option {
	name: string;
	value: number;
}

const sizeOptions: Option[] = [
	{ name: "H1", value: 1 },
	{ name: "H2", value: 2 },
	{ name: "H3", value: 3 },
	{ name: "H4", value: 4 },
	{ name: "H5", value: 5 },
	{ name: "H6", value: 6 },
];

export const EmailHeadlineBlockFormFields: FC<{ blockType: TAllBlockTypes }> = ({ blockType }) => {
	const { t } = useTranslation();
	const { isMainForm } = useI18nForm();
	const getLabel = useCallback((o: Option) => o.name, []);
	const getValue = useCallback((o: Option) => o.value, []);

	return (
		<>
			<I18nNameField blockType={blockType} />
			<I18nTextField label={t("Headline")} name={`translatedValue.headline`} />
			{isMainForm && (
				<SingleSelectField
					label={t("Size")}
					name="value.size"
					options={sizeOptions}
					getLabel={getLabel}
					getValue={getValue}
				/>
			)}
		</>
	);
};
