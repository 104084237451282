import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { registrationFormKeys, registrationFormService } from "~shared/api/registrationForms";

export const useRegistrationFormBlock = (formId: string, stepId: Id, sectionId?: Id) => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const attachRegistrationFormBlock = useCallback(
		async (blockId: Id) => {
			const response = sectionId
				? await registrationFormService.attachSectionBlocks(formId, stepId, sectionId, {
						blockIds: [blockId],
				  })
				: await registrationFormService.attachStepBlocks(formId, stepId, { blockIds: [blockId] });

			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form block was attached."),
					title: t("Attach registration form block"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: registrationFormKeys.detail(formId) });
				return response.data.data;
			}
		},
		[sectionId, formId, stepId, showToast, t, queryClient],
	);

	const detachRegistrationFormBlock = useCallback(
		async (identifier: Id) => {
			const response = sectionId
				? await registrationFormService.detachSectionBlocks(formId, stepId, sectionId, {
						blockIdentifiers: [identifier],
				  })
				: await registrationFormService.detachStepBlocks(formId, stepId, { blockIdentifiers: [identifier] });

			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form block was detached."),
					title: t("Detach registration form block"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: registrationFormKeys.detail(formId) });
				return true;
			}
			return false;
		},
		[sectionId, formId, stepId, showToast, t, queryClient],
	);

	return { attachRegistrationFormBlock, detachRegistrationFormBlock };
};
