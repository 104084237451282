import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Activity } from "~shared/types/activity";

interface Props {
	activity: Activity;
}

export const CreateActivity: FC<Props> = ({ activity }) => {
	const { t } = useTranslation();

	return <span>{activity.causer ? t("Created by {{name}}", { name: activity.causer.name }) : t("Created")}</span>;
};

export const UpdateActivity: FC<Props> = ({ activity }) => {
	const { t } = useTranslation();

	return <span>{activity.causer ? t("Updated by {{name}}", { name: activity.causer.name }) : t("Updated")}</span>;
};

export const AttachActivity: FC<Props> = ({ activity }) => {
	const { t } = useTranslation();

	return <span>{activity.causer ? t("Attached by {{name}}", { name: activity.causer.name }) : t("Attached")}</span>;
};

export const DetachActivity: FC<Props> = ({ activity }) => {
	const { t } = useTranslation();

	return <span>{activity.causer ? t("Detached by {{name}}", { name: activity.causer.name }) : t("Detached")}</span>;
};

export const SoftDeleteActivity: FC<Props> = ({ activity }) => {
	const { t } = useTranslation();

	return <span>{activity.causer ? t("Deleted by {{name}}", { name: activity.causer.name }) : t("Deleted")}</span>;
};

export const DeleteActivity: FC<Props> = ({ activity }) => {
	const { t } = useTranslation();

	return <span>{activity.causer ? t("Deleted by {{name}}", { name: activity.causer.name }) : t("Deleted")}</span>;
};

export const RestoreActivity: FC<Props> = ({ activity }) => {
	const { t } = useTranslation();

	return <span>{activity.causer ? t("Restored by {{name}}", { name: activity.causer.name }) : t("Restored")}</span>;
};

export const DeleteRelatedActivity: FC<Props> = ({ activity }) => {
	const { t } = useTranslation();

	return <span>{activity.causer ? t("Deleted by {{name}}", { name: activity.causer.name }) : t("Deleted")}</span>;
};

export const UnknownActivity: FC = () => {
	const { t } = useTranslation();

	return <span>{t("Change not recognized")}</span>;
};

export const ChangelogAction: FC<Props> = ({ activity }) => {
	switch (activity.action) {
		case "created":
			return <CreateActivity activity={activity} />;
		case "updated":
			return <UpdateActivity activity={activity} />;
		case "attached":
			return <AttachActivity activity={activity} />;
		case "detached":
			return <DetachActivity activity={activity} />;
		case "softdeleted":
			return <SoftDeleteActivity activity={activity} />;
		case "deleted":
			return <DeleteActivity activity={activity} />;
		case "restored":
			return <RestoreActivity activity={activity} />;
		case "relationDeleted":
			return <DeleteRelatedActivity activity={activity} />;
		default:
			return <UnknownActivity />;
	}
};
