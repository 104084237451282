import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { importProviderKeys, importProviderService } from "~shared/api/importProviders";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
}

const fetcher = (search: string, page: number) =>
	importProviderService.options({ params: { search, page } }).then((r) => r.data);

export const ImportProviderSingleSelectField: FC<Props> = ({ name, label, disabled, required }) => {
	return (
		<PagedSingleSelectField
			name={name}
			queryKey={importProviderKeys.options}
			fetcher={fetcher}
			label={label}
			disabled={disabled}
			clearable={true}
			required={required}
		/>
	);
};
