import { AutoDataList, FormattedDateTime, IDataListColumn } from "@dgs/core";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { emailKeys, emailService } from "~shared/api/emails";
import { BaseEmailLog } from "~shared/types/email";

export const EmailLogs: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const columns: IDataListColumn<BaseEmailLog>[] = useMemo(
		() => [
			{
				heading: t("Date"),
				valueKey: "date",
				type: "value",
				render: (log) => <FormattedDateTime dateTime={log.date} />,
			},
			{
				heading: t("To"),
				valueKey: "to",
				type: "value",
			},
			{
				heading: t("Cc"),
				valueKey: "cc",
				type: "value",
			},
			{
				heading: t("Bcc"),
				valueKey: "bcc",
				type: "value",
			},
			{
				heading: t("Subject"),
				valueKey: "subject",
				type: "value",
			},
			{
				heading: t("Has attachments"),
				valueKey: "hasAttachments",
				type: "value",
				render: (log) => (log.hasAttachments ? t("Yes") : t("No")),
			},
		],
		[t],
	);

	const onRowClick = useCallback((log: BaseEmailLog) => navigate(`../${log.id}`), [navigate]);

	return (
		<AutoDataList
			heading={t("Email logs")}
			empty={t("No email logs available")}
			columns={columns}
			queryKey={emailKeys.logs.index}
			fetcher={emailService.logs.index}
			onRowClick={onRowClick}
			initialSearch={{ search: "", searchFields: ["to", "cc", "bcc", "subject"] }}
		/>
	);
};
