import { FC, useLayoutEffect } from "react";
import { useNominationManagerConfig } from "./useNominationManagerConfig";

export const HtmlTitle: FC = () => {
	const { htmlTitle } = useNominationManagerConfig();

	useLayoutEffect(() => {
		document.title = htmlTitle;
	}, [htmlTitle]);

	return null;
};
