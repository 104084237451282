import { Card } from "@dgs/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { FC } from "react";
import { ActiveCard } from "../common/ActiveStructureCard";
import { DragIndicator } from "../common/DragIndicator";
import { StepChildHeader, StepChildHeaderProps } from "./StepChildHeader";

export const SortableStepChild: FC<StepChildHeaderProps> = (props) => {
	const { transform, transition, setNodeRef, listeners, attributes, isDragging } = useSortable({
		id: props.stepChild.id,
	});

	const StepChildCard = isDragging ? ActiveCard : Card;

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		opacity: isDragging ? 0.8 : 1,
	};

	return (
		<div ref={setNodeRef} style={style}>
			<StepChildCard $color="content">
				<StepChildHeader {...props} indicator={<DragIndicator {...listeners} {...attributes} />} />
			</StepChildCard>
		</div>
	);
};
