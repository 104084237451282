import { CrudDrawer, DateField, EntityDrawerProps, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { UserMultiSelectField } from "~admin/shared/selects/UserSelectField";
import { LocaleSelect } from "~shared/selects/LocaleSelect";
import { RegistrationFormSingleSelect } from "~shared/selects/RegistrationFormSingleSelect";
import { ITenantFormState } from "~shared/types/tenant";

export const TenantDrawer: FC<EntityDrawerProps<ITenantFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: ITenantFormState) => {
			const errors: { [K in keyof ITenantFormState]?: any } = {};

			if (!values.name) {
				errors.name = t("Required field");
			}
			if (values.activeStartDate && values.activeEndDate && values.activeEndDate <= values.activeStartDate) {
				errors.activeEndDate = t("Date must be after {{field}}", { field: t("Active start date") });
			}
			if (!values.users.length) {
				errors.users = t("Required field");
			}
			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<TextField name="name" label={t("Name")} required />
					<DateField name="activeStartDate" label={t("Active start date")} />
					<DateField name="activeEndDate" label={t("Active end date")} />
					<UserMultiSelectField name="users" label={t("Users")} required />
					<LocaleSelect name="defaultRegistrationLocale" label={t("Default registration locale")} />
					<RegistrationFormSingleSelect label={t("Default registration form")} name="defaultRegistrationForm" />
				</>
			}
		/>
	);
};
