import { AutoDataList, FormattedDate, IDataListColumn, IconLinkButton } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { roomContingentKeys, roomContingentService } from "~shared/api/contingent/contingentService";
import { IRoomContingentIndex } from "~shared/types/roomContingent";
import { RoomContingentContextMenu } from "./RoomContingentContextMenu";

export const RoomContingentList: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { hotelId = "", roomTypeId = "" } = useParams<{ hotelId: string; roomTypeId: string }>();
	const dataListColumns: IDataListColumn<IRoomContingentIndex>[] = useMemo(
		() => [
			{
				heading: t("Day"),
				valueKey: "date",
				type: "value",
				render: (roomContingent) => <FormattedDate date={roomContingent.date} />,
			},
			{
				heading: t("Contingent"),
				valueKey: "contingent",
				type: "value",
			},
			{
				heading: t("Overbookable"),
				valueKey: "overbookable",
				type: "value",
				render: (roomContingent) => (roomContingent.overbookable ? t("Yes") : t("No")),
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (roomContingent) => (
					<RoomContingentContextMenu hotelId={hotelId} roomTypeId={roomTypeId} roomContingent={roomContingent} />
				),
			},
		],
		[hotelId, roomTypeId, t],
	);

	return (
		<AutoDataList
			heading={t("Contingent")}
			headerActions={<IconLinkButton to="new" title={t("Add")} color="primary" size="small" icon="plus" />}
			empty={t("No contingent available")}
			columns={dataListColumns}
			queryKey={roomContingentKeys.index(hotelId, roomTypeId)}
			fetcher={roomContingentService.index(hotelId, roomTypeId)}
			onRowClick={(value) => navigate(`../contingent/${value.id}`)}
		/>
	);
};
