import { Id, PagedMultiSelectField, PagedMultiSelectFieldProps } from "@dgs/core";
import React, { FC } from "react";
import { companionService } from "~shared/api/guests/companionService";
import { Guest } from "~shared/types/guest";

interface Props extends Omit<PagedMultiSelectFieldProps<Guest>, "queryKey" | "fetcher"> {
	guestId: Id;
}

const getLabel = (guest: Guest) => `${guest.firstName} ${guest.lastName} (${guest.email})`;

const companionFetcher = (guestId: Id) => (search: string, page: number) =>
	companionService.optionsByParent(guestId, { params: { search, page } }).then((r) => r.data);

export const CompanionMultiSelect: FC<Props> = ({ guestId, ...rest }) => {
	return (
		<PagedMultiSelectField<Guest>
			{...rest}
			fetcher={companionFetcher(guestId)}
			queryKey={companionService.keys.optionsByParent(guestId)}
			getLabel={getLabel}
			clearable={true}
		/>
	);
};
