import { Id } from "@dgs/core";

export const importKeys = {
	index: ["imports"],
	guests: {
		index: ["imports", "guests"],
		list: ["imports", "guests", "list"],
		headerRow: ["imports", "guests", "header-row"],
		details: (guestImportId: Id) => [...importKeys.guests.index, `${guestImportId}`],
		errors: {
			index: (guestImportId: Id) => [...importKeys.guests.index, `${guestImportId}`, "errors"],
			list: (guestImportId: Id) => [...importKeys.guests.index, `${guestImportId}`, "errors", "list"],
			details: (guestImportId: Id, guestImportErrorId: Id) => [
				...importKeys.guests.index,
				`${guestImportId}`,
				"errors",
				`${guestImportErrorId}`,
			],
			correct: (guestImportId: Id, guestImportErrorId: Id) => [
				...importKeys.guests.index,
				`${guestImportId}`,
				"errors",
				`${guestImportErrorId}`,
				"correct",
			],
			bulkCorrection: (guestImportId: Id) => [
				...importKeys.guests.index,
				`${guestImportId}`,
				"errors",
				"bulk-correction",
			],
		},
	},
};
