import {
	AccordionBody,
	Button,
	ControlledAccordionItem,
	Empty,
	Icon,
	IconButton,
	Id,
	LinkButton,
	Loading,
	Tag,
	TextControl,
	useAccordionToggleWithAll,
	useOpen,
} from "@dgs/core";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useRegistrationFormRuleSetsByTypeQuery } from "~shared/api/registrationForms/ruleSets/ruleSetQueries";
import { AnyRulesetFilterItem, RuleSetType } from "~shared/types/ruleSet";
import { applyRuleSetFilter } from "./filter/RuleSetFilter";
import { RuleSetFilterDrawer } from "./filter/RuleSetFilterDrawer";
import { RuleSetFilterItemLabelContent } from "./filter/RuleSetFilterItemLabelContent";
import { RuleSetContextMenu } from "./RuleSetContextMenu";
import { RuleSetDetails } from "./RuleSetDetails";
import { useRuleSet } from "./useRuleSet";

const HeaderRow = styled.div`
	display: grid;
	justify-content: flex-end;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(2)};
	grid-template-columns: max-content 1fr min-content max-content;
`;

const Chips = styled.div`
	display: flex;
	flex-wrap: wrap;
	gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
	justify-content: flex-end;
`;

const StyledTextControl = styled(TextControl)`
	padding-bottom: 0;
`;

const AccordionList = styled.div`
	display: grid;
	gap: ${({ theme }) => theme.spacing(4)};
`;

const StyledLinkButton = styled(LinkButton)`
	justify-self: start;
`;

const ColorIndicator = styled.div<{ $color: string | null }>`
	background-color: ${({ $color }) => $color ?? "transparent"};
	width: 8px;
	left: 0;
	top: 0;
	bottom: 0;
	position: absolute;

	${({ theme }) => theme.borderRadius.medium};
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
`;

const SearchIcon = styled(Icon)`
	cursor: pointer;
`;

interface Props {
	ruleSetType: RuleSetType;
}

export const RuleSetListPage: FC<Props> = ({ ruleSetType }) => {
	const { registrationFormId = "" } = useParams<"registrationFormId">();
	const { data, isLoading, refetch } = useRegistrationFormRuleSetsByTypeQuery(registrationFormId, ruleSetType);
	const { deleteRuleSet } = useRuleSet(registrationFormId);
	const { t } = useTranslation();
	const [activeFilter, setActiveFilter] = useState<AnyRulesetFilterItem[]>([]);
	const [searchValue, setSearchValue] = useState("");
	const clearSearch = () => setSearchValue("");
	const ruleSets = useMemo(
		() =>
			applyRuleSetFilter(data ?? [], activeFilter).filter(
				(x) => !searchValue || (x.description || "").includes(searchValue),
			),
		[activeFilter, data, searchValue],
	);
	const { isOpen, toggle, toggleAll, isAllToggled } = useAccordionToggleWithAll(ruleSets.map((x) => x.id));
	const { open, handleClose, setOpen } = useOpen();

	const onDelete = useCallback(
		async (rulesetId: Id) => {
			await deleteRuleSet(rulesetId, ruleSetType);
			void refetch();
		},
		[deleteRuleSet, refetch, ruleSetType],
	);
	const onFilterDelete = useCallback(
		(index: number) => setActiveFilter(activeFilter.filter((_, idx) => idx !== index)),
		[activeFilter],
	);

	if (isLoading || !data) {
		return <Loading />;
	}

	return (
		<AccordionList>
			<HeaderRow>
				<Button type="button" onClick={toggleAll}>
					{isAllToggled ? t("Collapse all") : t("Expand all")}
				</Button>
				<Chips>
					{activeFilter.map((item, index) => (
						<Tag key={item.type} color="dark" onClick={() => setOpen(true)} onClose={() => onFilterDelete(index)}>
							<RuleSetFilterItemLabelContent filterItem={item} />
						</Tag>
					))}
				</Chips>
				<IconButton onClick={() => setOpen(true)} title={t("Open ruleset filter")} size="small" icon="filter" />
				<StyledTextControl
					autoFocus={true}
					name={t("Search")}
					placeholder={t("Search")}
					suffix={
						<SearchIcon icon={searchValue ? "close" : "search"} onClick={searchValue ? clearSearch : undefined} />
					}
					value={searchValue}
					onChange={(e) => setSearchValue(e.target.value)}
				/>
				<RuleSetFilterDrawer
					ruleSets={data}
					onChange={setActiveFilter}
					initialValues={activeFilter}
					open={open}
					handleClose={handleClose}
				/>
			</HeaderRow>
			{ruleSets.map((ruleSet) => (
				<div key={ruleSet.id} style={{ position: "relative" }}>
					<ControlledAccordionItem
						id={ruleSet.id}
						open={isOpen(ruleSet.id)}
						onToggle={toggle}
						title={ruleSet.description}
						actions={<RuleSetContextMenu rulesetId={ruleSet.id} onDelete={onDelete} />}
						body={
							<AccordionBody>
								<RuleSetDetails ruleSet={ruleSet} />
							</AccordionBody>
						}
					/>
					<ColorIndicator $color={ruleSet.color} />
				</div>
			))}
			{ruleSets.length === 0 && <Empty>{t("No rule sets available.")}</Empty>}
			<StyledLinkButton color="primary" to="new">
				{t("Add rule set")}
			</StyledLinkButton>
		</AccordionList>
	);
};
