import { JarvisAuthPages, NotAuthorizedPage, useDynamicConfig } from "@dgs/core";
import React, { FC } from "react";

export const PMAuthPages: FC = () => {
	const { authUrl } = useDynamicConfig();

	return (
		<JarvisAuthPages
			authUrl={authUrl}
			identityProvidersUrl={`${authUrl}/providers`}
			showPasswordReset={true}
			notAuthorizedPage={<NotAuthorizedPage basePath="/admin" title="PARTICIPANT MANAGEMENT" redirect={-1} />}
		/>
	);
};
