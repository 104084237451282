import { Id, useApiField } from "@dgs/core";
import React, { FC, useEffect } from "react";
import { guestKeys, guestService } from "~shared/api/guests";
import { useGuestFilter } from "~shared/guestFilters";
import { IActiveGuestFilterEntry } from "~shared/guestFilters/guestFilterTypes";
import { RecipientType } from "~shared/types/email";
import { EmailRecipientList } from "./EmailRecipientList";
import { useEmailSendOut } from "./EmailSendOutProvider";

const recipientFetcher =
	(filters: IActiveGuestFilterEntry[], recipientType: RecipientType, guestIds: Id[]) =>
	(search: string, page: number) =>
		guestService.getRecipients(filters, guestIds, recipientType, { params: { search, page } }).then((r) => r.data);

export const PagedEmailRecipientList: FC = () => {
	const { recipientType, recipients } = useEmailSendOut();
	const { filters, mapGuestFilterToGuestFilterRequest } = useGuestFilter();
	const loadProps = useApiField(
		guestKeys.filteredRecipients(
			filters,
			recipients.map((recipient) => recipient.id),
			recipientType,
		),
		recipientFetcher(
			mapGuestFilterToGuestFilterRequest(filters) as IActiveGuestFilterEntry[],
			recipientType,
			recipients.map((recipient) => recipient.id),
		),
	);

	useEffect(() => {
		loadProps.handleFirstLoad();
	}, [loadProps]);

	if (loadProps.options === undefined) {
		return null;
	}

	return (
		<EmailRecipientList
			options={loadProps.options}
			search={loadProps.search}
			onSearch={loadProps.onSearch}
			endReached={loadProps.onEndReached}
			loading={loadProps.loading}
		/>
	);
};
