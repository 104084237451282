import { Tag, useMediaQuery } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useGetRuleValueText } from "~shared/registrationForms/getRuleValue";
import { Rule, RuleTypes } from "~shared/types/rules";

const useGetRuleTranslation = () => {
	const getRuleValueText = useGetRuleValueText();
	const screenSize = useMediaQuery();

	return (rule: Rule) => {
		switch (rule.type) {
			case RuleTypes.MUST_CONTAIN:
				return {
					key: "contains {{value}}",
					values: { value: getRuleValueText(rule) },
				};
			case RuleTypes.MUST_NOT_CONTAIN:
				return {
					key: "contains not {{value}}",
					values: { value: getRuleValueText(rule) },
				};
			case RuleTypes.HAS_VALUE:
				return {
					key: "value = {{value}}",
					values: { value: getRuleValueText(rule) },
				};
			case RuleTypes.HAS_NOT_VALUE:
				return {
					key: "value != {{value}}",
					values: { value: getRuleValueText(rule) },
				};
			case RuleTypes.IS_REQUIRED:
				return {
					key: "required",
					values: {},
				};
			case RuleTypes.NOT_UPDATABLE:
				return {
					key: "not updatable",
					values: {},
				};
			case RuleTypes.READ_ONLY:
				return {
					key: "read only",
					values: {},
				};
			case RuleTypes.HAS_MIN_CHARS:
				return {
					key: "min. {{value}} characters",
					values: { value: rule.value },
				};
			case RuleTypes.HAS_MAX_CHARS:
				return {
					key: "max. {{value}} characters",
					values: { value: rule.value },
				};
			case RuleTypes.MIN:
				return {
					key: "min. {{value}}",
					values: { value: getRuleValueText(rule) },
				};
			case RuleTypes.MAX:
				return {
					key: "max. {{value}}",
					values: { value: getRuleValueText(rule) },
				};
			case RuleTypes.CONTAINS_ONE: {
				switch (screenSize) {
					case "mobile":
					case "tablet":
						return {
							key: "Contains one of the following values ({{numberOfValues}})",
							values: { numberOfValues: rule.value.length },
						};
					default:
						return {
							key: "Contains one of the following values: {{value}}",
							values: { value: getRuleValueText(rule) },
						};
				}
			}
			case RuleTypes.NOT_CONTAINS_ONE: {
				switch (screenSize) {
					case "mobile":
					case "tablet":
						return {
							key: "Contains none of the following values ({{numberOfValues}})",
							values: { numberOfValues: rule.value.length },
						};
					default:
						return {
							key: "Contains none of the following values: {{value}}",
							values: { value: getRuleValueText(rule) },
						};
				}
			}
		}
	};
};

export const DataFieldValidationDetails: FC<{ validationRules: Rule[] }> = ({ validationRules }) => {
	const { t } = useTranslation();
	const getRuleTranslation = useGetRuleTranslation();

	return (
		<>
			{validationRules.map((rule) => {
				const { key, values } = getRuleTranslation(rule);
				return (
					<Tag key={`rule-${rule.id}`} color="light">
						{t(key as any, { ...values }) as any}
					</Tag>
				);
			})}
		</>
	);
};
