import { CheckboxControl, CheckboxField, Id, Text, useFormatDate } from "@dgs/core";
import { eachDayOfInterval, endOfDay, isEqual, subDays } from "date-fns";
import { FieldArray, FormikProps } from "formik";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CompanionMultiSelect } from "~shared/selects/CompanionMultiSelect";
import { RoomContingentByDateRangeSingleSelectField } from "~shared/selects/RoomContingentByDateRangeSingleSelectControl";
import { RoomContingentSingleSelectField } from "~shared/selects/RoomContingentSingleSelectControl";
import { RoomContingentFormState } from "~shared/types/roomContingent";
import { parseDate } from "~shared/utils/dateUtils";

const CheckboxWithMargin = styled(CheckboxField)`
	margin-bottom: ${({ theme }) => theme.spacing(3)};
`;

const Message = styled(Text)`
	text-align: center;
	width: 100%;
	padding: ${({ theme }) => theme.spacing(3)};
`;

interface Props {
	guestId: Id;
	startDate: string;
	endDate: string;
	formikProps: FormikProps<RoomContingentFormState>;
}

export const ContingentFormFields: FC<Props> = ({ startDate, endDate, guestId, formikProps }) => {
	const { t } = useTranslation();
	const isSameDay = isEqual(parseDate(startDate), parseDate(endDate));
	const formatDate = useFormatDate();
	const [wantsToAddCompanions, setWantsToAddCompanions] = useState(false);

	return (
		<>
			{!formikProps.values.openSpecificRoomSelect && (
				<RoomContingentByDateRangeSingleSelectField
					name="roomTypeForAllDays"
					disabled={isSameDay}
					onChange={(e) => {
						const roomType = e.target.value;

						void formikProps.setFieldValue(
							"roomAllocation",
							roomType?.contingents
								? roomType.contingents.map((contingent) => ({
										roomContingent: {
											id: contingent.id,
											date: contingent.date,
											contingent: contingent.contingent,
											bookings: contingent.bookings,
											hotel: roomType.hotel,
											roomType: {
												id: roomType.id,
												name: roomType.name,
												capacity: roomType.capacity,
											},
										},
										roomMates: formikProps.values.roomMatesForAllDays ? formikProps.values.roomMatesForAllDays : [],
								  }))
								: [],
						);
					}}
					label={t("Room type")}
					start={startDate}
					end={endDate}
					placeholder={t("Select room for all requested days")}
				/>
			)}
			{formikProps.values.roomAllocation.some(
				(allocation) => allocation && allocation.roomContingent && allocation.roomContingent.roomType.capacity > 1,
			) && (
				<CheckboxControl
					label={t("Add more guests to this room")}
					name="wantsToAddCompanions"
					value={wantsToAddCompanions}
					onChange={(e) => setWantsToAddCompanions(e.target.value)}
				/>
			)}
			{wantsToAddCompanions && !formikProps.values.openSpecificRoomSelect && (
				<CompanionMultiSelect
					name="roomMatesForAllDays"
					guestId={guestId}
					label={t("Additional guests")}
					placeholder={t("Select additional guests for all requested days")}
					onChange={(e) => {
						const guests = e.target.value;

						void formikProps.setFieldValue(
							"roomAllocation",
							formikProps.values?.roomAllocation.length
								? formikProps.values?.roomAllocation.map((allocation) => ({
										roomContingent: allocation.roomContingent,
										roomMates: guests,
								  }))
								: [],
						);
					}}
				/>
			)}
			{isSameDay && <Message>{t("No nights requested in inquiry.")}</Message>}
			{!isSameDay && (
				<>
					<CheckboxWithMargin label={t("Select specific rooms per day")} name={"openSpecificRoomSelect"} />
					{formikProps.values.openSpecificRoomSelect && (
						<FieldArray
							name="roomAllocation"
							render={() =>
								eachDayOfInterval({
									start: parseDate(startDate),
									end: subDays(endOfDay(parseDate(endDate)), 1),
								}).map((date, index) => (
									<>
										<RoomContingentSingleSelectField
											key={`${date.toISOString()}_roomContingent`}
											day={date}
											name={`roomAllocation.${index}.roomContingent`}
											guestId={guestId}
											label={formatDate(date)}
											placeholder={t("Choose room type")}
										/>
										{wantsToAddCompanions && (
											<CompanionMultiSelect
												key={`${date.toISOString()}_roomMates`}
												name={`roomAllocation.${index}.roomMates`}
												guestId={guestId}
												label={t("Additional guests")}
											/>
										)}
									</>
								))
							}
						/>
					)}
				</>
			)}
		</>
	);
};
