import { ConfirmationDialog, IconButton, Id, useOpen } from "@dgs/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SortableListOptionItem } from "~admin/dataFields/dataFieldOptions/SortableOptionListItem";
import { IDataFieldOptionIndexResource } from "~shared/types/dataFieldOption";

interface Props {
	item: IDataFieldOptionIndexResource;
	onEditClick: (id: Id) => void;
	onDeleteClick: (id: Id) => void;
}

const ButtonContainer = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing(2)};
`;
const ListItemContent = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex: 1;
`;

export function SortableOptionItem({ item, onEditClick, onDeleteClick }: Props) {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: item.id,
	});
	const { t } = useTranslation();
	const { open, handleClose, handleOpen } = useOpen();
	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<>
			<SortableListOptionItem ref={setNodeRef} style={style} {...attributes} {...listeners}>
				<ListItemContent>
					{item.value}
					<ButtonContainer>
						<IconButton icon="edit" size="small" type="button" title={t("Edit")} onClick={() => onEditClick(item.id)} />
						<IconButton icon="delete" size="small" type="button" title={t("Delete")} onClick={handleOpen} />
					</ButtonContainer>
				</ListItemContent>
			</SortableListOptionItem>
			<ConfirmationDialog
				open={open}
				onClose={handleClose}
				action={() => onDeleteClick(item.id)}
				heading={t("Delete data field option")}
				labels={{ close: t("Close"), confirm: t("Confirm") }}
			>
				{t("Are you sure you want to remove this data field option?")}
			</ConfirmationDialog>
		</>
	);
}
