import { CrudDrawer, Id, TextField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRegistrationFormStep } from "~admin/registrationForms/structure/steps/useRegistrationFormStep";
import { useRegistrationFormStepDetailsQuery } from "~shared/api/registrationForms/registrationFormQueries";

interface Props {
	registrationFormId: Id;
	stepId: Id;
	open: boolean;
	onClose: () => void;
}

export const EditRegistrationFormStepDrawer: FC<Props> = ({ open, onClose, stepId, registrationFormId }) => {
	const { t } = useTranslation();
	const { step } = useRegistrationFormStepDetailsQuery(registrationFormId, stepId, open);
	const { updateRegistrationFormStep, validate } = useRegistrationFormStep(registrationFormId);

	if (!step) {
		return null;
	}

	return (
		<CrudDrawer
			open={open}
			onSubmit={async (values) => {
				const success = await updateRegistrationFormStep(values);

				if (success) {
					onClose();
				}

				return success;
			}}
			initialValues={step}
			onClose={onClose}
			validate={validate}
			heading={t("Edit registration form step")}
			body={<TextField name="name" label={t("Name")} />}
		/>
	);
};
