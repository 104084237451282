import { BrandResource, IAddon, useDynamicConfig } from "@dgs/core";
import { INominationModuleSettings } from "~shared/types/nominationModuleSettings";

export const useNominationManagerConfig = () => {
	return useDynamicConfig() as unknown as {
		apiUrl: string;
		authUrl: string;
		htmlTitle: string;
		logo: string;
		brand: BrandResource;
		settings: INominationModuleSettings;
		addons: IAddon[];
	};
};
