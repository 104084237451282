import { BaseCrudOptionService, Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import {
	IInvoiceProviderIndexResource,
	IInvoiceProviderUpdateRequest,
	IInvoiceProviderUpdateResource,
	InvoiceProviderType,
} from "~shared/types/invoiceProvider";

class InvoiceProviderService extends BaseCrudOptionService<
	IInvoiceProviderIndexResource,
	IInvoiceProviderUpdateResource,
	IInvoiceProviderIndexResource,
	IInvoiceProviderUpdateRequest
> {
	public keys = {
		index: ["invoice-providers"],
		list: ["invoice-providers", "list"],
		details: (invoiceProviderId: Id) => [...this.keys.index, `${invoiceProviderId}`],
		options: ["invoice-providers", "options"],
		typeOptions: ["invoice-providers", "type-options"],
	};

	protected urls = {
		index: keyToUrl(this.keys.index),
		options: keyToUrl(this.keys.options),
		details: (locationId: Id) => keyToUrl(this.keys.details(locationId)),
		typeOptions: keyToUrl(this.keys.typeOptions),
	};

	typeOptions(config?: AxiosRequestConfig) {
		return axios.get<LaravelResponse<InvoiceProviderType[]>>(this.urls.typeOptions, config);
	}
}
export const invoiceProviderService = new InvoiceProviderService();
