import { SingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { RegistrationVisibility } from "~shared/types/registrationForm";

const visibilityOptions: RegistrationVisibility[] = [
	RegistrationVisibility.ALL,
	RegistrationVisibility.PRIVATE,
	RegistrationVisibility.PUBLIC,
	RegistrationVisibility.CLOSED,
];
export const RegistrationFormVisibilitySelect: FC<{ name: string; label: string }> = (props) => {
	const { t } = useTranslation();
	const getLabel = (value: RegistrationVisibility) => t(`RegistrationVisibility.${value}`);
	const getValue = (value: RegistrationVisibility) => value;

	return <SingleSelectField {...props} options={visibilityOptions} getLabel={getLabel} getValue={getValue} />;
};
