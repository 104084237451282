import {
	Button,
	Card,
	CardHeader,
	CardHeading,
	DataList,
	DataListActionsItem,
	DataListClickableRow,
	DataListColumnTitle,
	DataListItem,
	EntityFetcher,
	Icon,
	IconButton,
	Id,
	Loading,
	Trigger,
	apiIsOK,
	useCrudEntity,
	useEditDrawer,
	useToasts,
} from "@dgs/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { waveService } from "~shared/api/waves/waveService";
import { IWaveFormState, IWaveRequest, IWaveTenantsContingentsFormState } from "~shared/types/wave";
import { WaveDrawer } from "./WaveDrawer";
import { WaveTenantContingentDrawer } from "./WaveTenantContingentDrawer";

const mapToRequest = (values: IWaveFormState): IWaveRequest => ({
	...values,
	readonlyAfterDateTime: !values.isReadonlyAfterDateTime ? null : values.readonlyAfterDateTime,
});

const StyledDataList = styled(DataList)`
	grid-template-columns: repeat(4, 1fr);
`;

const TenantDataListActionsItem = styled(DataListActionsItem)<{ $hasBorder: boolean }>`
	background-color: ${({ theme }) => theme.colors.palette.grey[10]};
	border-bottom: ${({ $hasBorder, theme }) => ($hasBorder ? "1px solid " + theme.colors.palette.grey[20] : "")};
`;

const TenantDataListItem = styled(DataListItem)<{ $hasBorder: boolean }>`
	background-color: ${({ theme }) => theme.colors.palette.grey[10]};
	border-bottom: ${({ $hasBorder, theme }) => ($hasBorder ? "1px solid " + theme.colors.palette.grey[20] : "")};
`;
const FirstTenantDataListItem = styled(TenantDataListItem)<{ $hasBorder: boolean }>`
	padding-left: ${({ theme }) => theme.spacing(8)};
	border-bottom: ${({ $hasBorder, theme }) => ($hasBorder ? "1px solid " + theme.colors.palette.grey[20] : "")};
`;

export const WaveContingentOverviewPage: FC = () => {
	const { t } = useTranslation();
	const { data, isLoading } = useQuery({
		queryKey: waveService.keys.getContingentOverview,
		queryFn: () => waveService.getContingentOverview().then((x) => x.data.data),
	});
	const drawerProps = useEditDrawer();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const onSubmitContingent = useCallback(
		async (values: IWaveTenantsContingentsFormState) => {
			const res = await waveService.updateTenantsWithContingent(drawerProps.editEntityId as Id, {
				waveTenantContingents: values.waveTenantContingents.map((x) => {
					if (!x.tenant) throw "Missing validation for field: tenant";
					return {
						tenantId: x.tenant.id,
						contingent: x.contingent,
					};
				}),
			});
			if (apiIsOK(res)) {
				showToast({
					type: "success",
					title: t("Contingent"),
					body: t("Contingent was successfully saved."),
				});
				queryClient.invalidateQueries({ queryKey: waveService.keys.getContingentOverview });
				return true;
			}
			return false;
		},
		[drawerProps.editEntityId, queryClient, showToast, t],
	);
	const crudOperations = useCrudEntity(
		waveService,
		{
			entityName: t("Wave"),
			messages: {
				create: t("Wave was saved."),
				update: t("Wave was saved."),
				delete: t("Wave was deleted."),
			},
		},
		[waveService.keys.getContingentOverview],
	);
	const emptyWave: IWaveFormState = {
		name: "",
		startDate: "",
		endDate: "",
		isReadonlyAfterDateTime: false,
		readonlyAfterDateTime: "",
	};
	if (!data || isLoading) {
		return <Loading />;
	}

	return (
		<>
			<Card>
				<CardHeader>
					<CardHeading>{t("Wave & Tenant Contingents")}</CardHeading>
					<Trigger
						render={(props) => (
							<WaveDrawer
								heading={t("Add wave")}
								open={props.open}
								onClose={props.handleClose}
								onClosed={() => void 0}
								initialValues={emptyWave}
								onSubmit={(entityFormState) => crudOperations.createEntity(mapToRequest(entityFormState))}
							/>
						)}
					>
						{(props) => (
							<Button {...props} type="button" color="primary" prefix={<Icon icon="plus" />}>
								{t("Add wave")}
							</Button>
						)}
					</Trigger>
				</CardHeader>
				<StyledDataList isEmpty={data.length === 0} empty={t("No wave available")}>
					<DataListColumnTitle>{t("Name")}</DataListColumnTitle>
					<DataListColumnTitle>{t("Contingent")}</DataListColumnTitle>
					<DataListColumnTitle>{t("Used contingent")}</DataListColumnTitle>
					<DataListColumnTitle>{t("Available contingent")}</DataListColumnTitle>
					<DataListColumnTitle>{t("Actions")}</DataListColumnTitle>
					{data &&
						data.map((wave) => (
							<React.Fragment key={`wave_${wave.id}`}>
								<DataListClickableRow onClick={() => drawerProps.handleOpen(wave.id)}>
									<DataListItem>{wave.name}</DataListItem>
									<DataListItem>{wave.contingent}</DataListItem>
									<DataListItem>{wave.usedContingent}</DataListItem>
									<DataListItem>{wave.availableContingent}</DataListItem>
									<DataListActionsItem>
										<IconButton
											icon="contingent"
											type="button"
											size="small"
											color="primary"
											title={t("Edit contingent")}
											onClick={() => drawerProps.handleOpen(wave.id)}
										/>
									</DataListActionsItem>
								</DataListClickableRow>
								{wave.tenants.map((tenant, idx) => {
									const hasBorder = wave.tenants.length > 1 && idx + 1 < wave.tenants.length;
									return (
										<React.Fragment key={`tenant_${tenant.id}`}>
											<FirstTenantDataListItem $hasBorder={hasBorder}>{tenant.name}</FirstTenantDataListItem>
											<TenantDataListItem $hasBorder={hasBorder}>{tenant.contingent}</TenantDataListItem>
											<TenantDataListItem $hasBorder={hasBorder}>{tenant.usedContingent}</TenantDataListItem>
											<TenantDataListItem $hasBorder={hasBorder}>{tenant.availableContingent}</TenantDataListItem>
											<TenantDataListActionsItem $hasBorder={hasBorder} />
										</React.Fragment>
									);
								})}
							</React.Fragment>
						))}
				</StyledDataList>
			</Card>
			{drawerProps.editEntityId && (
				<EntityFetcher<any>
					id={drawerProps.editEntityId}
					fetcher={(id) => waveService.tenantsWithContingent(id).then((x) => x.data.data)}
					getQueryKey={waveService.keys.tenantsWithContingent}
					renderDrawer={(entityResource, isLoading, reset) =>
						entityResource &&
						!isLoading && (
							<WaveTenantContingentDrawer
								{...drawerProps}
								heading={t("Contingent")}
								initialValues={{ waveTenantContingents: entityResource }}
								onSubmit={onSubmitContingent}
								onClosed={() => {
									drawerProps.onClosed();
									reset();
								}}
							/>
						)
					}
				/>
			)}
		</>
	);
};
