import { StylesContainer } from "@dgs/core";
import {
	DndContext,
	DragEndEvent,
	DragOverEvent,
	DragOverlay,
	DragStartEvent,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { arrayMove, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import React, { ReactNode, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { SortableListItemSearch } from "./SortableItemSearch";
import { SortableList } from "./SortableList";
import { ISortableItem, SortableListItem } from "./SortableListItem";

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	align-content: start;
	width: 100%;
	gap: ${({ theme }) => theme.spacing(4)};
`;

const Search = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(4)};
`;

const SortableWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: ${({ theme }) => theme.spacing(4)};
	align-content: start;
	grid-auto-rows: max-content;
	width: 100%;
`;

const Container = styled.div`
	width: 100%;
	display: flex;
	flex: 1;
`;

const Error = styled.div`
	color: ${({ theme }) => theme.colors.palette.danger.main.base};
`;

interface Props<T extends ISortableItem> {
	value: T[];
	initialItems: T[];
	getLabel: (item: T) => string;
	onChange: (sortableItems: T[]) => void;
	renderLabel?: (item: T) => ReactNode;
	listHeaders: { active: ReactNode; available: ReactNode };
	error?: string;
	headerActions?: ReactNode;
}

export function EditSortableLists<T extends ISortableItem>({
	value,
	initialItems,
	onChange,
	getLabel,
	renderLabel,
	listHeaders,
	headerActions,
	error,
}: Props<T>) {
	const ref = useRef<HTMLDivElement | null>(null);
	const searchRef = useRef<HTMLInputElement | null>(null);
	const [needle, setNeedle] = useState("");
	const [activeItem, setActiveItem] = useState<T | null>(null);
	const availableItems = useMemo(
		() => initialItems.filter((d) => !value.some((a) => a.id === d.id && !a.isRestrictedToVerticalAxis)),
		[initialItems, value]
	);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	function handleDragStart(event: DragStartEvent) {
		const { active } = event;
		const source = active.data.current?.sortable.containerId === "available" ? availableItems : value;

		setActiveItem(source.find((d) => d.id === active.id) || null);
	}

	function handleDragOver(event: DragOverEvent) {
		if (
			!event.active.data.current ||
			Object.keys(event.active.data.current).length === 0 ||
			!event.over?.data.current ||
			Object.keys(event.over.data.current).length === 0
		) {
			return;
		}

		const from = {
			columnId: event.active.data.current.sortable.containerId,
			id: event.active.id,
		};

		const to = {
			columnId: event.over.data.current.sortable.containerId,
			id: event.over.id,
		};

		if (to.columnId === "Sortable") {
			if (from.columnId !== to.id) {
				const source = from.columnId === "available" ? availableItems : value;
				const fromItem = source.find((d) => d.id === from.id);
				if (!fromItem) return;

				if (to.id === "active") {
					onChange([fromItem, ...value]);
				} else if (to.id === "available") {
					onChange(value.filter((c) => c.id !== from.id));
				}
			}
		} else if (from.columnId !== to.columnId && !event.active.data.current.modifiers) {
			const source = from.columnId === "available" ? availableItems : value;
			const fromItem = source.find((d) => d.id === from.id);

			if (!fromItem || to.id === "available" || to.id === "active") {
				return;
			}
			if (from.columnId === "available") {
				const tmp = value.concat(fromItem);
				const oldIndex = tmp.findIndex((d) => d.id === fromItem.id);
				const newIndex = tmp.findIndex((d) => d.id === to.id);

				onChange(arrayMove(tmp, oldIndex, newIndex));
			} else {
				onChange(value.filter((c) => c.id !== from.id));
			}
		}
	}

	function handleDragEnd(event: DragEndEvent) {
		const { active, over } = event;
		setActiveItem(null);

		if (over === null || over.id === "active" || over.id === "available") {
			return;
		}

		if (active.id !== over.id && value.length) {
			if (over.data.current?.sortable.containerId !== "available") {
				const oldIndex = value.findIndex((d) => d.id === active.id);
				const newIndex = value.findIndex((d) => d.id === over.id);

				return onChange(arrayMove(value, oldIndex, newIndex));
			}

			searchRef.current?.select();
			searchRef.current?.focus();
		}
	}

	return (
		<Wrapper>
			<Search>
				<SortableListItemSearch ref={searchRef} onSubmit={(needle) => setNeedle(needle || "")} />
				{headerActions}
			</Search>
			<Container>
				<DndContext
					sensors={sensors}
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
					onDragOver={handleDragOver}
					modifiers={activeItem && activeItem.isRestrictedToVerticalAxis ? [restrictToVerticalAxis] : []}
				>
					<SortableWrapper ref={ref}>
						<SortableList
							heading={listHeaders.available}
							needle={needle}
							columnId="available"
							items={availableItems}
							getLabel={getLabel}
							renderLabel={renderLabel}
						/>
						<SortableList
							heading={listHeaders.active}
							columnId="active"
							items={value}
							getLabel={getLabel}
							renderLabel={renderLabel}
						/>
					</SortableWrapper>
					{createPortal(
						<StylesContainer>
							<DragOverlay>
								{activeItem ? (
									<SortableListItem
										renderLabel={() => (renderLabel ? renderLabel(activeItem) : getLabel(activeItem))}
										active={true}
									/>
								) : null}
							</DragOverlay>
						</StylesContainer>,
						document.body
					)}
				</DndContext>
			</Container>
			{error && <Error>{error}</Error>}
		</Wrapper>
	);
}
