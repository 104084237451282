import { Id, LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { UserListResource, UserRequest, UserResource } from "~admin/shared/types/user";
import { mapFetcherConfigToAxiosConfig } from "~shared/api/indexMapper";
import { usersKeys } from "./usersKeys";

export const usersUrls = {
	index: keyToUrl(usersKeys.index),
	options: keyToUrl(usersKeys.options),
	details: (userId: Id) => keyToUrl(usersKeys.details(userId)),
};

const index = (config: ListFetcherConfig<any>) =>
	axios.get<LaravelResponse<UserListResource[]>>(usersUrls.index, mapFetcherConfigToAxiosConfig(config));

const get = (userId: Id) => axios.get<LaravelResponse<UserResource>>(usersUrls.details(userId));

const put = (user: UserRequest) => axios.put<LaravelResponse<void>>(usersUrls.details(user.id), user);

const post = (user: UserRequest) => axios.post<LaravelResponse<void>>(usersUrls.index, user);

const remove = (userId: Id) => axios.delete<LaravelResponse<void>>(usersUrls.details(userId));

const options = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<UserListResource[]>>(usersUrls.options, config);

export const usersService = {
	index,
	get,
	put,
	post,
	remove,
	options,
};
