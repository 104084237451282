import { ContextMenu, ContextMenuAnchorItem, ContextMenuItem, useDynamicConfig, useOpen } from "@dgs/core";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { registrationFormService } from "~shared/api/registrationForms";
import { IRegistrationFormIndex } from "~shared/types/registrationForm";
import { DeleteModelWithUsagesDialog, useModelUsage } from "~shared/ui/modelUsage/DeleteModelWithUsagesDialog";
import { DuplicateRegistrationFormDrawer } from "./DuplicateRegistrationFormDrawer";
import { EditRegistrationFormDrawer } from "./EditRegistrationFormDrawer";
import { useRegistrationFormForm } from "./useRegistrationFormForm";

interface Props {
	registrationForm: IRegistrationFormIndex;
	onAfterDelete?: () => void;
}

export const RegistrationFormSidebarContextMenu: FC<Props> = ({ registrationForm, onAfterDelete }) => {
	const { t } = useTranslation();
	const { frontendUrl } = useDynamicConfig();
	const { open, handleOpen, handleClose } = useOpen();
	const { open: openDuplicate, handleOpen: handleOpenDuplicate, handleClose: handleCloseDuplicate } = useOpen();
	const { deleteRegistrationForm, duplicateRegistrationForm } = useRegistrationFormForm();
	const showUsageAction = useCallback(
		() => registrationFormService.showUsage(registrationForm.id),
		[registrationForm.id],
	);
	const { handleUsage, ...usageModalProps } = useModelUsage(showUsageAction);
	const isDefault = useMemo(
		() => usageModalProps.usages.some((usage) => usage.name === "defaultRegistrationForm"),
		[usageModalProps.usages],
	);
	const usages = usageModalProps.usages.filter((usage) => usage.name !== "defaultRegistrationForm");

	return (
		<>
			<ContextMenu displayShortcutsAs="icon" size="small" flat={true}>
				<ContextMenuItem
					label={t("Edit registration form")}
					title={t("Edit registration form")}
					action={handleOpen}
					icon="edit"
				/>
				<ContextMenuItem
					label={t("Duplicate registration form")}
					title={t("Duplicate registration form")}
					action={handleOpenDuplicate}
					icon="copy"
				/>
				<ContextMenuItem
					action={handleUsage}
					title={t("Delete registration form")}
					label={t("Delete registration form")}
					icon="delete"
				/>
				<ContextMenuAnchorItem
					href={`${frontendUrl}/registrations/preview/${registrationForm.id}`}
					icon="eye"
					title={t("View registration form")}
					label={t("View registration form")}
				/>
			</ContextMenu>
			<EditRegistrationFormDrawer registrationForm={registrationForm} open={open} onClose={handleClose} />
			<DuplicateRegistrationFormDrawer
				registrationForm={registrationForm}
				open={openDuplicate}
				onClose={handleCloseDuplicate}
				duplicateRegistrationForm={duplicateRegistrationForm}
			/>
			<DeleteModelWithUsagesDialog
				{...usageModalProps}
				usages={usages}
				usagePrefix={t("This registration form")}
				confirmationBody={
					<>
						<p>
							{usages.length > 0 &&
								(!isDefault
									? t("All attached guests will be moved to the default registration form.")
									: t("All attached guests will no longer have a registration form."))}
						</p>
						<p>{t("Are you sure you want to delete this registration form?")}</p>
					</>
				}
				deleteAction={async () => {
					await deleteRegistrationForm(registrationForm.id);
					onAfterDelete && onAfterDelete();
				}}
			/>
		</>
	);
};
