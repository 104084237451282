import { IDataListSortParams, Id, LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { mapFetcherConfigToAxiosConfig, mapSortParamsToGuestListSortParams } from "~shared/api/indexMapper";
import { IActiveGuestFilterEntry, IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";
import { Activity } from "~shared/types/activity";
import { BaseEmailLog, RecipientType } from "~shared/types/email";
import {
	Guest,
	GuestFormValues,
	GuestListBulkEditRequest,
	GuestScreeningStatus,
	IGuestHotelData,
	IUpdateGuestRoomContingentRequest,
	Recipient,
} from "~shared/types/guest";
import { ModelUsageResource } from "~shared/types/modelUsageResource";
import { GuestBookingsResource, GuestDetailsResource, GuestIndexResource } from "~shared/types/newGuestTypes";
import {
	AttachRoomMateToRoomsRequest,
	IRoomAllocationRequest,
	IRoomContingentDetailsResource,
	IRoomWithAllRelationsResource,
} from "~shared/types/roomContingent";
import { guestKeys } from "./guestKeys";

export const guestUrls = {
	index: keyToUrl(guestKeys.index),
	options: keyToUrl(guestKeys.options),
	details: (guestId: Id) => keyToUrl(guestKeys.details(guestId)),
	hotelData: (guestId: Id) => keyToUrl(guestKeys.hotelData(guestId)),
	rooms: (guestId: Id) => keyToUrl(guestKeys.rooms(guestId)),
	attachRoomMateToRooms: (guestId: Id) => keyToUrl(guestKeys.attachRoomMateToRooms(guestId)),
	attachRoomContingents: (guestId: Id) => keyToUrl(guestKeys.attachRoomContingents(guestId)),
	detachRooms: (guestId: Id) => keyToUrl(guestKeys.detachRooms(guestId)),
	activities: (guestId: Id) => keyToUrl(guestKeys.activities(guestId)),
	filtered: keyToUrl(guestKeys.filtered),
	recipients: keyToUrl(guestKeys.recipients),
	recipientCount: keyToUrl(guestKeys.recipientCount),
	bulkDelete: keyToUrl(guestKeys.bulkDelete),
	bulkEdit: keyToUrl(guestKeys.bulkEdit),
	remove: (guestId: Id) => keyToUrl(guestKeys.remove(guestId)),
	register: (guestId: Id) => keyToUrl(guestKeys.register(guestId)),
	emails: (guestId: Id) => keyToUrl(guestKeys.emails(guestId)),
	sendEmail: (guestId: Id) => keyToUrl(guestKeys.sendEmail(guestId)),
	generateExport: keyToUrl(guestKeys.generateExport),
	showUsage: (guestId: Id) => keyToUrl(guestKeys.showUsage(guestId)),
	validateEmail: keyToUrl(guestKeys.validateEmail),
	bookingDetails: (guestId: Id | null) => keyToUrl(guestKeys.bookingDetails(guestId)),
	changeScreeningStatus: (guestId: Id) => keyToUrl(guestKeys.changeScreeningStatus(guestId)),
};

const options = (exclude: Id[], config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<Guest[]>>(guestUrls.options, {
		...config,
		params: { ...config?.params, exclude },
	});

const details = (guestId: Id, guestViewId: Id) =>
	axios.get<LaravelResponse<GuestDetailsResource>>(guestUrls.details(guestId), { params: { guestViewId } });

const rooms = (guestId: Id) => (config: ListFetcherConfig) =>
	axios.get<LaravelResponse<IRoomWithAllRelationsResource[]>>(
		guestUrls.rooms(guestId),
		mapFetcherConfigToAxiosConfig(config),
	);

const attachRoomMateToRooms = (guestId: Id, request: AttachRoomMateToRoomsRequest) =>
	axios.put<LaravelResponse<IRoomContingentDetailsResource>>(guestUrls.attachRoomMateToRooms(guestId), request);
const attachRoomContingents = (guestId: Id, allocation: IRoomAllocationRequest[]) =>
	axios.put<LaravelResponse<IRoomContingentDetailsResource>>(guestUrls.attachRoomContingents(guestId), {
		roomAllocation: allocation,
	});
const detachRooms = (guestId: Id, request: IUpdateGuestRoomContingentRequest) =>
	axios.put<LaravelResponse<IRoomContingentDetailsResource>>(guestUrls.detachRooms(guestId), request);

const hotelData = (guestId: Id) => axios.get<LaravelResponse<IGuestHotelData>>(guestUrls.hotelData(guestId));

const filtered = (guestViewId: Id, filters: IGuestFilterFormValueEntry[]) => (config: ListFetcherConfig) =>
	axios.post<LaravelResponse<GuestIndexResource[]>>(
		guestUrls.filtered,
		{ guestViewId, filters, ...mapSortParamsToGuestListSortParams(config.sortParams) },
		mapFetcherConfigToAxiosConfig(config),
	);

const getRecipients = (
	filters: IActiveGuestFilterEntry[],
	guestIds: Id[],
	recipientType: RecipientType,
	config?: AxiosRequestConfig,
) => axios.post<LaravelResponse<Recipient[]>>(guestUrls.recipients, { filters, guestIds, recipientType }, config);

const getRecipientCount = (filters: IActiveGuestFilterEntry[], guestIds: Id[], recipientType: RecipientType) =>
	axios.post<LaravelResponse<{ guestCount: number }>>(guestUrls.recipientCount, { filters, guestIds, recipientType });

const activities = (guestId: Id) => (config: ListFetcherConfig) =>
	axios.get<LaravelResponse<Activity[]>>(guestUrls.activities(guestId), mapFetcherConfigToAxiosConfig(config));

const put = (guestId: Id, guestViewId: Id, guest: GuestFormValues) =>
	axios.put<LaravelResponse<Guest>>(guestUrls.details(guestId), { guestViewId, attributes: guest.attributes });

const post = (guestViewId: Id, guest: GuestFormValues) =>
	axios.post<LaravelResponse<Guest>>(guestUrls.index, { attributes: guest.attributes, guestViewId });

const bulkDelete = (guestIds: Id[], filters: IGuestFilterFormValueEntry[], search: string | null, allGuests = false) =>
	axios.post<LaravelResponse<GuestIndexResource[]>>(guestUrls.bulkDelete, { guestIds, filters, search, allGuests });

const bulkEdit = (
	guestIds: Id[],
	filters: IGuestFilterFormValueEntry[],
	search: string | null,
	change: GuestListBulkEditRequest,
	allGuests = false,
) =>
	axios.post<LaravelResponse<GuestIndexResource[]>>(guestUrls.bulkEdit, {
		guestIds,
		filters,
		search,
		change,
		allGuests,
	});

const remove = (guestId: Id) => axios.delete<LaravelResponse<Guest>>(guestUrls.details(guestId));
const register = (guestId: Id) => axios.put<LaravelResponse<Guest>>(guestUrls.register(guestId));

const emails = (guestId: Id, config: ListFetcherConfig) =>
	axios.get<LaravelResponse<BaseEmailLog[]>>(guestUrls.emails(guestId), mapFetcherConfigToAxiosConfig(config));

const sendEmail = (guestId: Id, locale: string, customEmailId: Id) =>
	axios.post<LaravelResponse<BaseEmailLog>>(guestUrls.sendEmail(guestId), { locale, customEmailId });

const generateExport = (
	search: string | null,
	sort: IDataListSortParams<any> | null,
	filters: IGuestFilterFormValueEntry[],
	guestViewId?: Id | null,
) =>
	axios.post<LaravelResponse<void>>(guestUrls.generateExport, {
		search,
		sort: sort
			? {
					guestAttributeId: sort.column,
					direction: sort.direction,
			  }
			: null,
		filters,
		guestViewId,
	});

const showUsage = (id: Id) => axios.get<LaravelResponse<ModelUsageResource[]>>(guestUrls.showUsage(id));
const validateEmail = (email: string) =>
	axios.put<LaravelResponse<{ guest: GuestIndexResource | null }>>(guestUrls.validateEmail, { email });

const bookingDetails = (guestId: Id | null) => {
	return axios.get<LaravelResponse<GuestBookingsResource>>(guestUrls.bookingDetails(guestId));
};

const changeScreeningStatus = (guestId: Id, screeningStatus: GuestScreeningStatus) =>
	axios.put<void>(guestUrls.changeScreeningStatus(guestId), { screeningStatus });

export const guestService = {
	options,
	details,
	hotelData,
	rooms,
	attachRoomMateToRooms,
	attachRoomContingents,
	detachRooms,
	activities,
	put,
	post,
	bulkDelete,
	bulkEdit,
	filtered,
	getRecipients,
	getRecipientCount,
	remove,
	register,
	emails,
	sendEmail,
	generateExport,
	showUsage,
	validateEmail,
	bookingDetails,
	changeScreeningStatus,
};
