import { AutoDataList, IDataListColumn, IconButton, Id } from "@dgs/core";
import { addDays, eachDayOfInterval } from "date-fns";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HotelBookingsDrawer } from "~admin/hotels/bookings/HotelBookingsDrawer";
import { hotelService } from "~admin/shared/api/hotels";
import { useProductDataQuery } from "~shared/api/productData";
import { ActiveGuestFilterEntries, useGuestFilter } from "~shared/guestFilters";
import { useGuestListUtils } from "~shared/guests";
import { LocalGuestViewSwitcher } from "~shared/guests/GuestViewSwitchers";
import { useGuestView } from "~shared/providers/useGuestView";
import { IHotelBookingResource, IHotelBookingValues } from "~shared/types/hotel";
import { parseDate, toISODateOnlyString } from "~shared/utils/dateUtils";
import { BookingsExportButton } from "./BookingsExportButton";
import { HotelBookingsFilter } from "./HotelBookingsFilter";
import { useHotelBookingUtils } from "./useHotelBookingUtils";

export const HotelBookingsList: FC = () => {
	const { currentGuestView } = useGuestView();
	const { data: productData, isLoading } = useProductDataQuery();
	const initialFilter = useMemo<IHotelBookingValues>(
		() => ({
			guestView: localStorage.getItem("bookingsGuestView")
				? JSON.parse(localStorage.getItem("bookingsGuestView") as string)
				: null,
			start: productData?.eventStartDate
				? toISODateOnlyString(addDays(parseDate(productData.eventStartDate), -3))
				: toISODateOnlyString(new Date()),
			end: productData?.eventEndDate
				? toISODateOnlyString(addDays(parseDate(productData.eventEndDate), 2))
				: toISODateOnlyString(addDays(new Date(), 7)),
			hotel: null,
		}),
		[productData?.eventEndDate, productData?.eventStartDate],
	);
	const { filters: guestFilters, handleOpen, mapGuestFilterToGuestFilterRequest } = useGuestFilter();
	const [bookingsFilter, setBookingsFilter] = useState<IHotelBookingValues>(initialFilter);
	const { toBookingColumn } = useHotelBookingUtils();
	const { t } = useTranslation();
	const { columns: guestColumns } = useGuestListUtils(currentGuestView);
	const [guestId, setGuestId] = useState<Id | null>(null);

	const columns = useMemo<IDataListColumn<IHotelBookingResource>[]>(() => {
		const range = eachDayOfInterval({ start: parseDate(bookingsFilter.start), end: parseDate(bookingsFilter.end) });
		const dayColumns = range.map(toBookingColumn);

		return guestColumns.concat(dayColumns as any[]) as any[];
	}, [bookingsFilter.end, bookingsFilter.start, guestColumns, toBookingColumn]);

	const setFilter = useCallback((filter: IHotelBookingValues) => {
		localStorage.setItem("bookingsGuestView", JSON.stringify(filter.guestView));
		setBookingsFilter(filter);
	}, []);

	return (
		!isLoading && (
			<>
				<HotelBookingsFilter initialState={initialFilter} setFilter={setFilter} />
				<AutoDataList
					name="bookings"
					heading={t("Bookings")}
					headerChildren={<ActiveGuestFilterEntries />}
					headerActions={
						<>
							<LocalGuestViewSwitcher />
							<IconButton size="small" title={t("Guest filter")} onClick={handleOpen} icon="filter" />
							<BookingsExportButton
								guestViewId={currentGuestView.id}
								start={bookingsFilter.start}
								end={bookingsFilter.end}
								hotelId={bookingsFilter.hotel ? bookingsFilter.hotel.id : null}
								guestFilter={mapGuestFilterToGuestFilterRequest(guestFilters)}
							/>
						</>
					}
					empty={t("No bookings for selected duration")}
					columns={columns}
					queryKey={[
						...hotelService.keys.list,
						currentGuestView.id,
						bookingsFilter.start,
						bookingsFilter.end,
						bookingsFilter.hotel ? bookingsFilter.hotel.id : "",
						JSON.stringify(guestFilters),
					]}
					fetcher={(config) =>
						hotelService.bookings(
							{
								hotelId: bookingsFilter.hotel ? bookingsFilter.hotel.id : null,
								guestViewId: currentGuestView.id,
								start: bookingsFilter.start,
								end: bookingsFilter.end,
							},
							mapGuestFilterToGuestFilterRequest(guestFilters),
							config,
						)
					}
					onRowClick={(booking) => setGuestId(booking.id)}
				/>
				<HotelBookingsDrawer guestId={guestId} onClose={() => setGuestId(null)} />
			</>
		)
	);
};
