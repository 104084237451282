import { CrudDrawer, ImagePickerInput, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GuestViewSelect } from "~admin/shared/selects/GuestViewSelect";
import { Location } from "~admin/shared/types/location";
import { GuestFilterSelect } from "~shared/selects/GuestFilterSelect";

interface Props {
	location: Location;
	open: boolean;
	title: string;
	onSave: (location: Location) => Promise<boolean>;
	onClose: () => void;
}

export const LocationDrawer: FC<Props> = ({ location, open, title, onClose, onSave }) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: Location) => {
			const errors: { [K in keyof Location]?: any } = {};

			if (!values.name) {
				errors.name = t("Required field");
			}
			if (!values.guestView) {
				errors.guestView = t("Required field");
			}
			if (!values.image) {
				errors.image = t("Required field");
			}

			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			open={open}
			onSubmit={onSave}
			body={
				<>
					<TextField name="name" label={t("Name")} required />
					<GuestViewSelect name={"guestView"} label={t("Guest view")} />
					<ImagePickerInput
						label={t("Image")}
						name="image"
						cropImageWithAspect={4}
						aspectRatioOptions={[{ name: "4 / 1", aspect: 4 }]}
					/>
					<GuestFilterSelect name={"guestFilter"} label={t("Guest filter")} />
				</>
			}
			initialValues={location}
			onClose={onClose}
			heading={title}
			validate={validate}
		/>
	);
};
