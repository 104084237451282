import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { FC } from "react";
import { BaseSectionChildProps, SectionChild } from "./SectionChild";

export const SortableSectionChild: FC<BaseSectionChildProps> = (props) => {
	const { transform, transition, setNodeRef, listeners, attributes, isDragging } = useSortable({
		id: props.sectionChild.id,
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<SectionChild ref={setNodeRef} style={style} isActive={isDragging} {...props} {...attributes} {...listeners} />
	);
};
