import { jsonParseLinter } from "@codemirror/lang-json";
import { linter } from "@codemirror/lint";
import { Extension } from "@codemirror/state";
import { CodeMirror } from "@dgs/core";
import { useField } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Wrapper = styled.div`
	display: grid;
	gap: ${({ theme }) => theme.spacing(1)};
`;

const Container = styled.div`
	${({ theme }) => theme.forms.fieldContainer};
	height: 20rem;
	max-height: 20rem;
	overflow: scroll;
	align-items: start;
`;

const Error = styled.div`
	${({ theme }) => theme.forms.error};
`;

const ext: Extension[] = [linter(jsonParseLinter())];

interface Props {
	name: string;
}

const isValidJson = (json: string): boolean => {
	try {
		JSON.parse(json);
	} catch (e) {
		return false;
	}

	return true;
};

export const JsonCodeMirrorField: FC<Props> = ({ name }) => {
	const { t } = useTranslation();

	const validate = useCallback((value: string) => (isValidJson(value) ? "" : t("You need to enter valid JSON.")), [t]);

	const [{ value, onChange }, { error, touched }, { setTouched }] = useField({
		name,
		validate,
	});

	const handleChange = useCallback(
		(value: string) => {
			onChange({ target: { value, name } });
		},
		[name, onChange],
	);

	return (
		<Wrapper>
			<Container
				onBlur={() => setTouched(true)}
				className={touched && error ? "has-error" : undefined}
				$hasValue={!!value}
			>
				<CodeMirror value={value} onChange={handleChange} extensions={ext} />
			</Container>
			{touched && error && <Error>{error}</Error>}
		</Wrapper>
	);
};
