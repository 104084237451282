import { CardBody, CardFooter, PagedSingleSelectField, SubmitButton } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IHotelSettingsFormValues } from "~admin/shared/types/hotelSettings";
import { dataFieldService } from "~shared/api/dataFields";
import { DataFieldType } from "~shared/types/dataFieldType";
import { FormikWithRedirect } from "~shared/ui/FormikWithRedirect";

interface Props {
	settings: IHotelSettingsFormValues;
	onSubmit: (values: IHotelSettingsFormValues) => Promise<boolean>;
}

export const HotelSettingsForm: FC<Props> = ({ settings, onSubmit }) => {
	const { t } = useTranslation();
	const dataFieldFetcher = (type: DataFieldType) => (search: string, page: number) =>
		dataFieldService.options(search, page, { type }).then((r) => r.data);

	const validate = useCallback(
		(values: IHotelSettingsFormValues) => {
			const errors: { [K in keyof IHotelSettingsFormValues]?: any } = {};

			if (
				values.checkInDataField &&
				values.checkOutDataField &&
				values.checkInDataField.id === values.checkOutDataField.id
			) {
				errors.checkInDataField = t("Check in data field can't be the same as check out data field");
				errors.checkOutDataField = t("Check in data field can't be the same as check out data field");
			}

			return errors;
		},
		[t],
	);

	return (
		<FormikWithRedirect initialValues={settings} onSubmit={onSubmit} validate={validate}>
			<CardBody>
				<PagedSingleSelectField
					fetcher={dataFieldFetcher(DataFieldType.DATE)}
					queryKey={dataFieldService.keys.options.concat(DataFieldType.DATE)}
					label={t("Check-in data field for hotel inquiries")}
					name="checkInDataField"
				/>
				<PagedSingleSelectField
					fetcher={dataFieldFetcher(DataFieldType.DATE)}
					queryKey={dataFieldService.keys.options.concat(DataFieldType.DATE)}
					label={t("Check-out data field for hotel inquiries")}
					name="checkOutDataField"
				/>
				<PagedSingleSelectField
					fetcher={dataFieldFetcher(DataFieldType.LONG_TEXT)}
					queryKey={dataFieldService.keys.options.concat(DataFieldType.LONG_TEXT)}
					label={t("Comment data field for hotel inquiries")}
					name="hotelRemarkDataField"
				/>
			</CardBody>
			<CardFooter>
				<SubmitButton>{t("Save")}</SubmitButton>
			</CardFooter>
		</FormikWithRedirect>
	);
};
