import { FCC } from "@dgs/core";
import React from "react";
import styled from "styled-components";

const Bold = styled.strong`
	font-weight: bold;
	padding-left: ${({ theme }) => theme.spacing(2)};
	color: ${({ theme }) => theme.colors.palette.primary.main.base};
`;

interface Props {
	label: string;
}

export const Labeled: FCC<Props> = ({ label, children }) => {
	return (
		<div>
			<span>{label}:</span>
			<Bold>{children}</Bold>
		</div>
	);
};
