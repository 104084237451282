import { UnsavedChangesPrompt } from "@dgs/core";
import { Form, Formik, FormikConfig, FormikHelpers, FormikProps, FormikValues, useFormikContext } from "formik";
import React, { FC, ReactNode, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export type SetTo = (to: string | number | undefined) => void;

export type FormikHelpersWithRedirect<Values> = FormikHelpers<Values> & {
	setTo: SetTo;
};

interface Props<Values> extends Omit<FormikConfig<Values>, "onSubmit" | "children"> {
	className?: string;
	onSubmit: (values: Values, helpers: FormikHelpersWithRedirect<Values>) => Promise<boolean>;
	children: ReactNode | ((formik: FormikProps<Values> & { setTo: SetTo }) => ReactNode);
}

export function FormikWithRedirect<Values extends FormikValues = FormikValues>({
	children,
	onSubmit,
	className,
	...props
}: Props<Values>) {
	const [to, setTo] = useState<string | number | undefined>(undefined);

	const handleSubmit = useCallback(
		async (values: Values, helpers: FormikHelpers<Values>) => {
			const success = await onSubmit(values, { ...helpers, setTo });

			if (success) {
				helpers.resetForm({ values });
			}
		},
		[onSubmit],
	);

	return (
		<Formik onSubmit={handleSubmit} {...props}>
			{(formik) => (
				<Form className={className}>
					<UnsavedChangesPrompt />
					<RedirectAfterSubmit to={to} />
					{typeof children === "function" ? children({ ...formik, setTo }) : children}
				</Form>
			)}
		</Formik>
	);
}

export const RedirectAfterSubmit: FC<{ to: string | number | undefined }> = ({ to }) => {
	const { dirty } = useFormikContext();
	const navigate = useNavigate();

	useEffect(() => {
		if (!dirty && to !== undefined) {
			//navigate has two different function declarations, you cant pass a delta with options, which makes the type incompatible
			//https://reactrouter.com/en/main/hooks/use-navigate
			typeof to === "number" ? navigate(to) : navigate(to);
		}
	}, [dirty, navigate, to]);

	return null;
};
