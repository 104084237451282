import { LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { IContingentOverviewIndex } from "~admin/shared/types/contingentOverview";
import { mapFetcherConfigToAxiosConfig } from "~shared/api/indexMapper";

export const contingentOverviewKeys = {
	overview: ["room-types", "overview"],
	days: ["room-contingent", "days"],
};

export const contingentOverviewURLs = {
	overview: keyToUrl(contingentOverviewKeys.overview),
	days: keyToUrl(contingentOverviewKeys.days),
};

export const contingentOverviewService = {
	overview: (config: ListFetcherConfig<any>) =>
		axios.get<LaravelResponse<IContingentOverviewIndex[]>>(
			contingentOverviewURLs.overview,
			mapFetcherConfigToAxiosConfig(config),
		),
	days: (config?: AxiosRequestConfig) => axios.get<LaravelResponse<string[]>>(contingentOverviewURLs.days, config),
};
