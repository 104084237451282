import { ConfirmationDialog, IconButton, Id, apiIsOK, useBulkEdit, useList, useOpen, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { dashboardKeys } from "~shared/api/dashboard";
import { dataFieldService } from "~shared/api/dataFields";
import { emailKeys } from "~shared/api/emails";
import { guestKeys, guestService } from "~shared/api/guests";
import { companionService } from "~shared/api/guests/companionService";
import { waveService } from "~shared/api/waves/waveService";
import { IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";
import { nominationManagerGuestKeys } from "../../nominationManager/api/guests/nominationManagerGuestKeys";

export interface Props {
	filters: IGuestFilterFormValueEntry[];
	companionParentId?: Id;
}

export const BulkDeleteCompanionAction: FC<Props> = ({ filters, companionParentId }) => {
	const { t } = useTranslation();
	const { open, handleClose, handleOpen } = useOpen();
	const { selectedRows, setSelectedRow } = useBulkEdit();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { searchParams } = useList();

	const handleDelete = useCallback(async () => {
		let response;
		const editAll = selectedRows === "all";
		if (companionParentId) {
			response = await companionService.companionBulkDelete(
				editAll ? [] : selectedRows,
				filters,
				searchParams?.search ?? null,
				companionParentId,
				editAll,
			);
		} else {
			response = await guestService.bulkDelete(
				editAll ? [] : selectedRows,
				filters,
				searchParams?.search ?? null,
				editAll,
			);
		}
		if (apiIsOK(response)) {
			showToast({
				body: t("{{count}} guest was deleted", { count: selectedRows.length }),
				title: t("Guests"),
				type: "success",
			});
			void queryClient.invalidateQueries({ queryKey: guestKeys.index });
			void queryClient.invalidateQueries({ queryKey: nominationManagerGuestKeys.index });
			void queryClient.invalidateQueries({ queryKey: dashboardKeys.index });
			void queryClient.invalidateQueries({ queryKey: emailKeys.sendOuts.index });
			void queryClient.invalidateQueries({ queryKey: dataFieldService.keys.contingentOverview });
			void queryClient.invalidateQueries({ queryKey: waveService.keys.getContingentOverview });
			setSelectedRow("none");
		}
	}, [companionParentId, selectedRows, filters, searchParams?.search, showToast, t, queryClient, setSelectedRow]);

	return (
		<>
			<IconButton
				title={t("Delete guests")}
				size="small"
				onClick={handleOpen}
				disabled={selectedRows.length === 0}
				icon="delete"
			/>
			<ConfirmationDialog
				heading={t("Delete guests")}
				labels={{ close: t("Close"), confirm: t("Confirm") }}
				open={open}
				onClose={handleClose}
				action={() => handleDelete()}
			>
				{selectedRows === "all"
					? t("Are you sure you want to delete all guests?")
					: t("Are you sure you want to delete the selected {{count}} guest(s)?", { count: selectedRows.length })}
			</ConfirmationDialog>
		</>
	);
};
