import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { PMSystemMessage } from "~admin/shared/types/systemMessage";

class SystemMessageService {
	public keys = {
		all: ["system-messages"],
	};
	private urls = {
		all: keyToUrl(this.keys.all),
	};

	all = async () => {
		return axios.get<LaravelResponse<PMSystemMessage[]>>(this.urls.all);
	};
}

export const systemMessageService = new SystemMessageService();
