import { AutoDataList, FormattedDate, IDataListColumn, IconButton, SelectedRows, Trigger } from "@dgs/core";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { invoiceService } from "~admin/shared/api/shop/invoices/invoiceService";
import { IInvoiceIndexResource } from "~admin/shared/types/invoice";
import { InvoiceDownloadDrawer } from "~admin/shop/invoices/InvoiceDownloadDrawer";
import { ActiveGuestFilterEntries, useGuestFilter } from "~shared/guestFilters";
import { useGuestListUtils } from "~shared/guests";
import { LocalGuestViewSwitcher } from "~shared/guests/GuestViewSwitchers";
import { useGuestView } from "~shared/providers/useGuestView";
import { InvoiceDrawer } from "./InvoiceDrawer";
import { InvoiceListBulkEditAction } from "./InvoiceListBulkEditAction";
import { InvoiceListContextMenu } from "./InvoiceListContextMenu";

export const InvoiceList: FC = () => {
	const { t } = useTranslation();
	const { currentGuestView } = useGuestView();
	const { filters, mapGuestFilterToGuestFilterRequest, handleOpen } = useGuestFilter();
	const [drawerValues, setDrawerValues] = useState<SelectedRows | null>(null);
	const { columns: guestAttributeColumns } = useGuestListUtils(currentGuestView);

	const dataListColumns: IDataListColumn<IInvoiceIndexResource>[] = useMemo(
		() => [
			{
				heading: t("Number"),
				valueKey: "documentNumber",
				type: "value",
			},
			{
				heading: t("Date"),
				valueKey: "documentDate",
				type: "value",
				render: ({ documentDate }) => <FormattedDate date={documentDate} />,
			},
			{
				heading: t("Type"),
				valueKey: "type",
				type: "value",
				render: ({ type }) => t(type),
			},
			...(guestAttributeColumns as IDataListColumn<IInvoiceIndexResource>[]),
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (invoice) => <InvoiceListContextMenu invoice={invoice} setDrawerValues={setDrawerValues} />,
			},
		],
		[t, guestAttributeColumns],
	);

	return (
		<>
			<AutoDataList
				name="invoices"
				heading={t("Invoices")}
				empty={t("No invoices available")}
				headerChildren={<ActiveGuestFilterEntries />}
				headerActions={
					<>
						<LocalGuestViewSwitcher />
						<IconButton size="small" title={t("Guest filter")} onClick={handleOpen} icon="filter" />
						<Trigger render={(props) => <InvoiceDownloadDrawer {...props} />}>
							{(props) => <IconButton {...props} title={t("Download invoices")} size="small" icon="file_download" />}
						</Trigger>
					</>
				}
				columns={dataListColumns}
				queryKey={invoiceService.keys.filtered(currentGuestView.id, filters)}
				fetcher={(config) =>
					invoiceService.filtered(currentGuestView.id, mapGuestFilterToGuestFilterRequest(filters), config)
				}
				initialSearch={{ search: "", searchFields: ["documentNumber"] }}
				enableBulkEdit={true}
				bulkEditActions={<InvoiceListBulkEditAction setDrawerValues={setDrawerValues} />}
			/>
			<InvoiceDrawer invoiceIds={drawerValues} handleClose={() => setDrawerValues(null)} />
		</>
	);
};
