import { BaseCrudOptionService, Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { groupKeys } from "~shared/api/groups/groupKeys";
import { IGroupIndexResource, IGroupOptionResource, IGroupRequest, IGroupUpdateResource } from "~shared/types/group";
import { ModelUsageResource } from "~shared/types/modelUsageResource";

class GroupService extends BaseCrudOptionService<
	IGroupIndexResource,
	IGroupUpdateResource,
	IGroupOptionResource,
	IGroupRequest
> {
	public keys = groupKeys;
	protected urls = {
		index: keyToUrl(this.keys.index),
		options: keyToUrl(this.keys.options),
		details: (groupId: Id) => keyToUrl(this.keys.details(groupId)),
		showUsage: (groupId: Id) => keyToUrl(this.keys.showUsage(groupId)),
	};

	showUsage = (groupId: Id) => axios.get<LaravelResponse<ModelUsageResource[]>>(this.urls.showUsage(groupId));
}

export const groupService = new GroupService();
