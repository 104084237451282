import { CookieRefreshService, FCC, JarvisCookieAuthProvider, useDynamicConfig } from "@dgs/core";
import React from "react";

interface Props {
	emailRedirectBaseUrl: string;
}

export const PMNominationManagerAuthProvider: FCC<Props> = ({ emailRedirectBaseUrl, children }) => {
	const { authUrl } = useDynamicConfig();

	return (
		<JarvisCookieAuthProvider authUrl={authUrl} emailRedirectBaseUrl={emailRedirectBaseUrl}>
			<CookieRefreshService />
			{children}
		</JarvisCookieAuthProvider>
	);
};
