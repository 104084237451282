import { CrudDrawer, EntityDrawerProps, TextField } from "@dgs/core";
import { FieldArray } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { GuestFilterAttributeSelect } from "~shared/guestFilters/GuestFilterAttributeSelect";
import { GuestFilterDrawerPredefinedFilterSelect } from "~shared/guestFilters/GuestFilterDrawerPredefinedFilterSelect";
import { GuestFilterEntries } from "~shared/guestFilters/GuestFilterEntrys";
import { GuestFilterFormValues, IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";
import { useGuestFilter } from "~shared/guestFilters/index";

const Line = styled.div`
	height: 1px;
	background-color: ${({ theme }) => theme.colors.palette.grey[20]};
	margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const GuestFilterCreateDrawer: FC<
	EntityDrawerProps<GuestFilterFormValues> & {
		isCrudForm?: boolean;
	}
> = (props) => {
	const { isCrudForm = false } = props;
	const { t } = useTranslation();
	const { mapGuestFilterToGuestFilterRequest } = useGuestFilter();

	const validate = useCallback(
		({ name, filters }: GuestFilterFormValues) => {
			const errors: { [K in keyof GuestFilterFormValues]?: any } = {};

			const filterItemErrors: Record<keyof IGuestFilterFormValueEntry, string>[] = [];

			filters.forEach((filterItem, index) => {
				const error: Record<string, string> = {};

				if (!filterItem.operator) {
					error.operator = t("Field is required");
				}

				if (
					filterItem.operator &&
					filterItem.operator !== "IS_EMPTY" &&
					filterItem.operator !== "IS_NOT_EMPTY" &&
					(filterItem.value === null || filterItem.value === "" || filterItem.value === undefined)
				) {
					error.value = t("Field is required");
				}

				if (Object.keys(error).length) {
					filterItemErrors[index] = error;
				} else {
					delete filterItemErrors[index];
				}
			});

			if (filterItemErrors.length) {
				errors.filters = filterItemErrors;
			}

			if (isCrudForm && !name) {
				errors.name = t("Field is required");
			}

			return errors;
		},
		[t, isCrudForm],
	);

	return (
		<CrudDrawer
			{...props}
			onSubmit={(entity) => {
				return props.onSubmit({
					...entity,
					filters: mapGuestFilterToGuestFilterRequest(entity.filters),
				});
			}}
			validate={validate}
			body={<GuestFilterDrawerFields isCrudForm={isCrudForm} />}
		/>
	);
};
export const GuestFilterDrawerFields: FC<{ isCrudForm: boolean }> = ({ isCrudForm }) => {
	const { t } = useTranslation();
	return (
		<>
			{isCrudForm ? <TextField name="name" label={t("Name")} /> : <GuestFilterDrawerPredefinedFilterSelect />}
			<FieldArray
				name="filters"
				render={(arrayHelpers) => {
					return (
						<div>
							<GuestFilterAttributeSelect
								onSelect={(id) =>
									arrayHelpers.unshift({
										guestAttributeId: id,
										operator: null,
										value: null,
									})
								}
							/>
							<Line />
							<GuestFilterEntries arrayHelpers={arrayHelpers} />
						</div>
					);
				}}
			/>
		</>
	);
};
