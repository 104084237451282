import { AutoDataList, BaseIcon, IDataListColumn, IconLinkButton } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { emailKeys, emailService } from "~shared/api/emails";
import { EmailListResource } from "~shared/types/email";
import { EmailsListContextMenu } from "./EmailsListContextMenu";

const AttachIcon = styled(BaseIcon)`
	width: 24px;
	height: 24px;
`;

const StyledDiv = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const EmailsList: FC = () => {
	const { t } = useTranslation();
	const dataListColumns: IDataListColumn<EmailListResource>[] = useMemo(
		() => [
			{
				heading: t("Attachments"),
				valueKey: "attachmentCount",
				type: "value",
				size: "max-content",
				render: (email: EmailListResource) => (
					<>
						{email.attachmentCount > 0 ? (
							<StyledDiv>
								<AttachIcon title={"attachFile"} src="/icons/email/attachFile.svg" /> {email.attachmentCount}
							</StyledDiv>
						) : null}
					</>
				),
			},
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
				size: "1fr",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (email: EmailListResource) => <EmailsListContextMenu email={email} />,
			},
		],
		[t],
	);

	return (
		<AutoDataList
			heading={t("Emails")}
			headerActions={<IconLinkButton icon="plus" to="../new" title={t("Add")} color="primary" size="small" />}
			empty={t("No emails available")}
			columns={dataListColumns}
			queryKey={emailKeys.index}
			fetcher={emailService.index}
			to={(value) => `../${value.id}/data`}
			initialSearch={{ search: "", searchFields: ["name"] }}
		/>
	);
};
