import { AutoDataList, ListFetcher } from "@dgs/core";
import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ActiveGuestFilterEntries } from "~shared/guestFilters";
import { useGuestListUtils } from "~shared/guests";
import { useGlobalGuestView } from "~shared/providers/GlobalGuestViewProvider";
import { GuestIndexResource } from "~shared/types/newGuestTypes";

interface Props {
	queryKey: string[];
	fetcher: ListFetcher<GuestIndexResource, any, any>;
	headerActions: ReactNode;
	bulkEditActions: ReactNode;
	baseUrl?: string;
	name?: string;
	heading?: string;
}

export const BaseGuestList: FC<Props> = ({
	queryKey,
	fetcher,
	headerActions,
	bulkEditActions,
	baseUrl,
	name,
	heading,
}) => {
	const { t } = useTranslation();
	const { globalGuestView } = useGlobalGuestView();
	const { columns } = useGuestListUtils(globalGuestView);

	return (
		<AutoDataList
			name={name || "guests"}
			heading={heading || t("Guests")}
			headerChildren={<ActiveGuestFilterEntries />}
			headerActions={headerActions}
			empty={t("No guests available.")}
			columns={columns}
			queryKey={queryKey}
			fetcher={fetcher}
			to={(value) => (baseUrl ? `${baseUrl}${value.id}` : `${value.id}`)}
			initialSearch={{ search: "", searchFields: ["textDataFields"] }}
			enableBulkEdit={true}
			bulkEditActions={bulkEditActions}
		/>
	);
};
