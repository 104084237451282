import { FCC, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { eachDayOfInterval, subDays } from "date-fns";
import { FormikState } from "formik";
import React, { createContext, useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEmptyHotel } from "~admin/hotels/useEmptyHotel";
import { useEmptyStepperRoomType } from "~admin/hotels/useEmptyRoom";
import { hotelService } from "~admin/shared/api/hotels";
import { IHotelResource } from "~shared/types/hotel";
import { IRoomContingentResource } from "~shared/types/roomContingent";
import { IRoomTypeStepperResource } from "~shared/types/roomType";
import { SetTo } from "~shared/ui/FormikWithRedirect";
import { parseDate, toISODateOnlyString } from "~shared/utils/dateUtils";

interface IHotelStepperState {
	hotel: IHotelResource;
	roomType: IRoomTypeStepperResource;
	roomContingent: IRoomContingentResource[];
	setHotel: (hotel: IHotelResource) => void;
	setRoomType: (roomType: IRoomTypeStepperResource) => void;
	setRoomContingent: (roomContingent: IRoomContingentResource[]) => void;
	submit: () => Promise<void>;
	submitWithoutRoomTypes: (
		hotel: IHotelResource,
		setTo: SetTo,
		resetForm: (nextState?: Partial<FormikState<IHotelResource>> | undefined) => void,
	) => Promise<void>;
}

const Context = createContext<IHotelStepperState | undefined>(undefined);

export const HotelStepperProvider: FCC = ({ children }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const { showToast } = useToasts();
	const emptyHotel = useEmptyHotel();
	const emptyRoomType = useEmptyStepperRoomType();
	const [hotel, setHotel] = useState<IHotelResource>(emptyHotel);
	const [roomType, _setRoomType] = useState<IRoomTypeStepperResource>(emptyRoomType);
	const [roomContingent, setRoomContingent] = useState<IRoomContingentResource[]>([]);

	const setRoomType = useCallback((roomType: IRoomTypeStepperResource) => {
		_setRoomType(roomType);
		setRoomContingent(
			eachDayOfInterval({ start: parseDate(roomType.startDate), end: subDays(parseDate(roomType.endDate), 1) }).map(
				(day) => ({
					id: roomType.id,
					date: toISODateOnlyString(day),
					overbookable: roomType.overbookable,
					contingent: roomType.defaultContingent,
					prices: roomType.defaultPrices || [],
				}),
			),
		);
	}, []);

	const submitWithoutRoomTypes = useCallback(
		async (
			hotel: IHotelResource,
			setTo: SetTo,
			resetForm: (nextState?: Partial<FormikState<IHotelResource>> | undefined) => void,
		) => {
			const { country, currency, ..._hotel } = hotel;

			const response = await hotelService.store({
				..._hotel,
				countryId: country?.id ?? null,
				currencyId: currency?.id ?? null,
			});

			if (apiIsOK(response)) {
				showToast({
					body: t("Hotel was successfully created."),
					title: t("Hotel"),
					type: "success",
				});
				setTo("../hotels");
				await queryClient.invalidateQueries({ queryKey: hotelService.keys.index });
				resetForm();
			}
		},
		[queryClient, showToast, t],
	);

	const submit = useCallback(async () => {
		const { country, currency, ..._hotel } = hotel;
		const { customEmailForBookingOverview, customEmailForRequester, groups, ..._roomType } = roomType;

		const response = await hotelService.storeWithRoomType({
			hotel: {
				..._hotel,
				countryId: country?.id ?? null,
				currencyId: currency?.id ?? null,
			},
			roomType: {
				..._roomType,
				customEmailIdForBookingOverview: customEmailForBookingOverview?.id ?? null,
				customEmailIdForRequester: customEmailForRequester?.id ?? null,
				groupIds: groups.map((x) => x.id),
			},
			roomContingent,
		});

		if (apiIsOK(response)) {
			showToast({
				body: t("Hotel was successfully created."),
				title: t("Hotel"),
				type: "success",
			});
			navigate("../hotels");
			await queryClient.invalidateQueries({ queryKey: hotelService.keys.index });
		}
	}, [hotel, navigate, queryClient, roomContingent, roomType, showToast, t]);

	const value = {
		hotel,
		roomType,
		roomContingent,
		setHotel,
		setRoomType,
		setRoomContingent,
		submit,
		submitWithoutRoomTypes,
	};

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useHotelStepper() {
	const ctx = useContext(Context) as IHotelStepperState | undefined;

	if (ctx === undefined) {
		throw new Error(`useHotelStepper needs to be wrapped in a HotelStepperProvider.`);
	}

	return ctx;
}
