import { FC, useLayoutEffect } from "react";
import { useProductDataQuery } from "~shared/api/productData";

export const HtmlTitle: FC = () => {
	const { data: productData } = useProductDataQuery();

	useLayoutEffect(() => {
		if (productData?.eventName) {
			document.title = productData.eventName;
		}
	}, [productData?.eventName]);

	return null;
};
