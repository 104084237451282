import React, { FC } from "react";
import styled from "styled-components";
import { ActiveGuestFilterEntry } from "./ActiveGuestFilterEntry";
import { useGuestFilter } from "./GuestFilterProvider";

const Wrapper = styled.div`
	margin-top: ${({ theme }) => theme.spacing(2)};
	margin-left: -${({ theme }) => theme.spacing(1)};
	margin-right: -${({ theme }) => theme.spacing(1)};
	display: flex;
	flex-wrap: wrap;
`;

export const ActiveGuestFilterEntries: FC = () => {
	const { filters } = useGuestFilter();

	if (filters.length === 0) {
		return null;
	}

	return (
		<Wrapper>
			{filters.map((filter, index) => (
				<ActiveGuestFilterEntry key={`${filter.guestAttributeId}.${index}`} filter={filter} />
			))}
		</Wrapper>
	);
};
