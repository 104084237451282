import { Card, CardHeader, CardHeading, Loading, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { systemMessageService } from "~admin/shared/api/systemMessages";
import { productDataKeys, productDataService, useProductDataEditQuery } from "~shared/api/productData";
import { IProductDataFormState, IProductDataRequest } from "~shared/types/productData";
import { SettingsForm } from "./SettingsForm";

export const GeneralSettingsPage: FC = () => {
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const { data: settings, isLoading } = useProductDataEditQuery();

	const updateSettings = useCallback(
		async (values: IProductDataFormState) => {
			const request: IProductDataRequest = {
				contactName: values.contactName,
				contactEmail: values.contactEmail,
				htmlTitle: values.htmlTitle,
				startPageBehaviour: values.startPageBehaviour,
				enableAnalytics: values.enableAnalytics,
				enableAdobeAnalytics: values.enableAdobeAnalytics,
				defaultRegistrationFormId: values.defaultRegistrationForm?.id ?? null,
				startPageMenuItemId: values.startPageBehaviour === "menuItem" ? values.startPageMenuItem?.id ?? null : null,
				adobeAnalyticsThirdPartyScripts: values.adobeAnalyticsThirdPartyScripts
					? JSON.parse(values.adobeAnalyticsThirdPartyScripts)
					: null,
			};

			const response = await productDataService.put(request);

			if (apiIsOK(response)) {
				showToast({ body: t("Settings were saved."), title: t("Save settings"), type: "success" });
				await queryClient.invalidateQueries({ queryKey: productDataKeys.index });
				await queryClient.invalidateQueries({ queryKey: systemMessageService.keys.all });
			}
		},
		[queryClient, showToast, t],
	);

	return (
		<Card>
			<CardHeader>
				<CardHeading>{t("Edit settings")}</CardHeading>
			</CardHeader>
			{isLoading && <Loading />}
			{!isLoading && settings && <SettingsForm settings={settings} handleSave={updateSettings} />}
		</Card>
	);
};
