import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { hotelSettingsKeys } from "~admin/shared/api/hotelSettings/hotelSettingsKeys";
import { IHotelSettingsFormValues, IHotelSettingsRequest } from "~admin/shared/types/hotelSettings";

export const hotelSettingsUrls = {
	index: keyToUrl(hotelSettingsKeys.index),
};

const index = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<IHotelSettingsFormValues>>(hotelSettingsUrls.index, config);

const update = (settings: IHotelSettingsRequest) =>
	axios.put<LaravelResponse<IHotelSettingsFormValues>>(hotelSettingsUrls.index, settings);

export const hotelSettingsService = {
	index,
	update,
};
