import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { RegistrationFormShopItem } from "~shared/types/shop";
import { RegistrationFormShopList } from "./RegistrationFormShopList";

interface Props {
	shopItems: RegistrationFormShopItem[];
}

export const RegistrationFormShopItems: FC<Props> = ({ shopItems }) => {
	const { t } = useTranslation();
	const { fallbackLocale } = useContentLocale();

	return (
		<RegistrationFormShopList
			items={shopItems}
			empty={t("This step will be omitted because no shop items are configured for this form.")}
			heading={t("Shop items")}
		>
			{(item) => item.translations[fallbackLocale].name}
		</RegistrationFormShopList>
	);
};
