import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { GroupMultiSelect } from "~shared/selects/GroupMultiSelect";
import { RegistrationFormSingleSelect } from "~shared/selects/RegistrationFormSingleSelect";
import { TagMultiSelect } from "~shared/selects/TagMultiSelect";
import { TenantSelectField } from "~shared/selects/TenantSelectField";
import { WaveSelectField } from "~shared/selects/WaveSelectField";
import { GuestViewRelationAttribute } from "~shared/types/guestView";

interface Props {
	attribute: GuestViewRelationAttribute;
	required: boolean;
}

export const GuestAttributeFieldRelation: FC<Props> = ({ attribute, required }) => {
	const { t } = useTranslation();

	switch (attribute.entityType) {
		case "registrationForm":
			return (
				<RegistrationFormSingleSelect
					name={attribute.id.toString()}
					label={t("Registration form")}
					clearable={false}
					required={required}
				/>
			);
		case "tenant":
			return <TenantSelectField name={attribute.id.toString()} label={t("Tenant")} required={required} />;
		case "wave":
			return <WaveSelectField name={attribute.id.toString()} label={t("Wave")} required={required} />;
		case "groups":
			return <GroupMultiSelect name={attribute.id.toString()} label={t("Groups")} required={required} />;
		case "tags":
			return <TagMultiSelect name={attribute.id.toString()} label={t("Tags")} required={required} />;
		default:
			return null;
	}
};
