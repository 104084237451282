import { ConfirmationDialog, IconButton, Id, apiIsOK, useOpen, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { emailKeys, emailService } from "~shared/api/emails";

interface Props {
	emailId: Id;
}

export const ResendEmail: FC<Props> = ({ emailId }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { open, handleClose, handleOpen } = useOpen();

	const resendMail = useCallback(async () => {
		const response = await emailService.logs.resend(emailId);

		if (apiIsOK(response)) {
			await queryClient.invalidateQueries({ queryKey: emailKeys.logs.index });
			showToast({
				body: t("Email send out has been started."),
				title: t("Email send out"),
				type: "success",
			});
		}
	}, [emailId, queryClient, showToast, t]);

	return (
		<>
			<IconButton title={t("Resend Email")} onClick={handleOpen} icon="send" />
			<ConfirmationDialog
				heading={t("Resend Email")}
				labels={{ close: t("Close"), confirm: t("Send") }}
				open={open}
				onClose={handleClose}
				action={resendMail}
			>
				{t("Are you sure you want to send this email again?")}
			</ConfirmationDialog>
		</>
	);
};
