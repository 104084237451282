import { ISystemMessage } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { systemMessageService } from "./systemMessageService";

export const useSystemMessages = (initialMessages: ISystemMessage[] = []) => {
	const { t } = useTranslation();
	const { data: messages, isLoading } = useQuery({
		queryKey: systemMessageService.keys.all,
		queryFn: () => systemMessageService.all().then((x) => x.data.data),
	});

	const systemMessages: ISystemMessage[] = useMemo(() => {
		if (!messages) {
			return [];
		}

		return messages.map((message, index) => {
			switch (message.messageType) {
				case "startPageBehaviourNotSet":
					return {
						id: `${message.messageType}-${index}`,
						text: t(`SystemMessages.${message.messageType}`),
						type: "error",
						to: "../settings",
					};
				case "shopNotConfigured":
					return {
						id: `${message.messageType}-${index}`,
						text: t(`SystemMessages.${message.messageType}`),
						type: "error",
						to: "../shop/settings",
					};
				case "shopNotConfiguredInRegistrationForm":
					return {
						id: `${message.messageType}-${index}`,
						text: t(`SystemMessages.${message.messageType}`, { name: message.data.name }),
						type: "error",
						to: `../registration-forms/${message.data.id}`,
					};
				case "dataFieldSettingsNotConfigured":
					return {
						id: `${message.messageType}-${index}`,
						text: t(`SystemMessages.${message.messageType}`) + " " + message.data.map((field) => t(field)).join(", "),
						type: "error",
						to: `../data-fields/settings`,
					};
			}
		});
	}, [messages, t]);

	return {
		systemMessages: isLoading ? [] : [...systemMessages, ...initialMessages],
		isLoading,
	};
};
