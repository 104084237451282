import { Id } from "@dgs/core";
import { IActiveGuestFilterEntry } from "~shared/guestFilters/guestFilterTypes";
import { RecipientType } from "~shared/types/email";

export const nominationManagerGuestKeys = {
	index: ["nomination-manager", "guests"],
	list: ["nomination-manager", "guests", "list"],
	options: ["nomination-manager", "guests", "options"],
	bulkDelete: ["nomination-manager", "guests", "bulk-delete"],
	bulkEdit: ["nomination-manager", "guests", "bulk-edit"],
	remove: (guestId: Id) => [...nominationManagerGuestKeys.index, `${guestId}`],
	details: (guestId: Id) => [...nominationManagerGuestKeys.index, `${guestId}`],
	hotelData: (guestId: Id) => [...nominationManagerGuestKeys.index, `${guestId}`, "hotel-data"],
	rooms: (guestId: Id) => [...nominationManagerGuestKeys.index, `${guestId}`, "rooms"],
	attachRoomMateToRooms: (guestId: Id) => [...nominationManagerGuestKeys.rooms(guestId), "attach-room-mate"],
	attachRoomContingents: (guestId: Id) => [
		...nominationManagerGuestKeys.index,
		`${guestId}`,
		"room-contingents",
		"attach",
	],
	detachRooms: (guestId: Id) => [...nominationManagerGuestKeys.index, `${guestId}`, "rooms", "detach"],
	filtered: ["nomination-manager", "guests", "filter"],
	recipients: ["nomination-manager", "guests", "recipients"],
	recipientCount: ["nomination-manager", "guests", "recipients", "count"],
	filteredRecipients: (filters: IActiveGuestFilterEntry[], guestIds: Id[], recipientType: RecipientType) => [
		...nominationManagerGuestKeys.recipients,
		JSON.stringify(filters),
		JSON.stringify(guestIds),
		recipientType,
	],
	filteredRecipientCount: (filters: IActiveGuestFilterEntry[], guestIds: Id[], recipientType: RecipientType) => [
		...nominationManagerGuestKeys.recipients,
		JSON.stringify(filters),
		JSON.stringify(guestIds),
		recipientType,
	],
	activities: (guestId: Id) => [...nominationManagerGuestKeys.index, `${guestId}`, "activities"],
	register: (guestId: Id) => [...nominationManagerGuestKeys.index, `${guestId}`, "register"],
	emails: (guestId: Id) => [...nominationManagerGuestKeys.index, `${guestId}`, "email-logs"],
	sendEmail: (guestId: Id) => [...nominationManagerGuestKeys.index, `${guestId}`, "send-email"],
	generateExport: ["nomination-manager", "guests", "export"],
	showUsage: (id: Id) => ["nomination-manager", "guests", `${id}`, "usage"],
	validateEmail: ["nomination-manager", "guests", "validate-email"],
	bookingDetails: (guestId: Id | null) => [...nominationManagerGuestKeys.index, `${guestId}`, "bookings"],
};
