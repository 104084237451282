import { EntityDrawerProps, I18NCrudDrawer, I18nOnlyOnMainForm, I18nTextField, TextField, mapErrors } from "@dgs/core";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataFieldTypeSelect } from "~admin/dataFields/DataFieldTypeSelect";
import { config } from "~shared/config";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { IDataFieldFormState } from "~shared/types/dataField";
import { DataFieldType } from "~shared/types/dataFieldType";

export const DataFieldDrawer: FC<EntityDrawerProps<IDataFieldFormState>> = (props) => {
	const { t } = useTranslation();
	const { fallbackLocale, locales } = useContentLocale();
	const [type, setType] = useState<DataFieldType | null>(props.initialValues.type);

	const validate = useCallback(
		(values: IDataFieldFormState) => {
			let errors: { [K in keyof IDataFieldFormState]?: any } = {};

			if (!values.name) {
				errors.name = t("Required field");
			}
			const translations = values.translations[fallbackLocale] || {};

			if (fallbackLocale in translations && !values.translations[fallbackLocale].label) {
				errors = mapErrors(errors, fallbackLocale, "label", t as any);
			}

			return errors;
		},
		[fallbackLocale, t],
	);

	const getPlaceholder = (type: DataFieldType | null) => {
		if (type === DataFieldType.FILE) {
			return t("Max. {{size}} MB file size", { size: config.uploadedFileMaxSize });
		}
	};

	const isPlaceholderDisabled = (type: DataFieldType | null): boolean => {
		return type
			? [
					DataFieldType.RADIO,
					DataFieldType.CHECKBOX,
					DataFieldType.CHECKBOX_GROUP,
					DataFieldType.FILE,
					DataFieldType.DATE,
					DataFieldType.DATETIME,
			  ].includes(type)
			: false;
	};
	const isDescriptionDisabled = (type: DataFieldType | null): boolean => {
		return type ? [DataFieldType.CHECKBOX].includes(type) : false;
	};

	return (
		<I18NCrudDrawer
			{...props}
			validate={validate}
			locales={locales}
			fallbackLocale={fallbackLocale}
			body={
				<>
					<I18nOnlyOnMainForm>
						<TextField name="name" label={t("Name")} required />
						<DataFieldTypeSelect
							name="type"
							label={t("Type")}
							currentDataFieldType={props.initialValues.id != -1 && type ? type : undefined}
							onChange={setType}
						/>
					</I18nOnlyOnMainForm>
					<I18nTextField name="label" label={t("Label")} required="onlyOnMainForm" />
					<I18nTextField
						name="placeholder"
						label={t("Placeholder")}
						disabled={isPlaceholderDisabled(type)}
						placeholder={getPlaceholder(type)}
					/>
					<I18nTextField name="description" label={t("Description")} disabled={isDescriptionDisabled(type)} />
					<I18nTextField name="dataPrivacyInfo" label={t("Data privacy info")} />
				</>
			}
		/>
	);
};
