import { Button, CardBody, CardFooter, Id, SubmitButton, isEmail } from "@dgs/core";
import { TFunction } from "i18next";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { guestService } from "~shared/api/guests";
import { useProductDataQuery } from "~shared/api/productData";
import { config } from "~shared/config";
import { DataFieldSettings, IDataFieldIndexResource } from "~shared/types/dataField";
import { DataFieldType } from "~shared/types/dataFieldType";
import { GuestFormValues, GuestStatus } from "~shared/types/guest";
import { GuestViewAttributeResource, GuestViewConfigResource } from "~shared/types/guestView";
import { GuestDetailsResource } from "~shared/types/newGuestTypes";
import { FormikHelpersWithRedirect, FormikWithRedirect } from "~shared/ui/FormikWithRedirect";
import { DeleteModelWithUsagesDialog, useModelUsage } from "~shared/ui/modelUsage/DeleteModelWithUsagesDialog";
import { GuestAttributeField } from "./GuestAttributeField";

interface Props {
	guest: GuestDetailsResource;
	dataFields: IDataFieldIndexResource[];
	guestView: GuestViewConfigResource;
	dataFieldSettings: DataFieldSettings | null;
	requiredGuestAttributeIds: Id[];
	onSubmit: (
		guest: GuestFormValues,
		helpers: FormikHelpersWithRedirect<GuestFormValues>,
		shouldGoBack: boolean,
	) => Promise<boolean>;
	onDelete?: (() => Promise<void>) | false;
}

const isEditable = (attribute: GuestViewAttributeResource): boolean => {
	if (attribute.type === "column") {
		if (attribute.entityType === "createdAt" || attribute.entityType === "updatedAt") {
			return false;
		}
	}

	return true;
};

const PHONE_REGEX = /^[+]*[-\s./0-9]{0,4}[(]?[0-9]{1,4}[)]?[-\s./0-9]*$/;

export const GuestForm: FC<Props> = ({
	guest,
	dataFieldSettings,
	dataFields,
	guestView,
	requiredGuestAttributeIds,
	onSubmit,
	onDelete,
}) => {
	const { t } = useTranslation();
	const [shouldGoBack, setShouldGoBack] = useState<boolean>(false);
	const { data: productData } = useProductDataQuery();
	const showUsageAction = useCallback(() => guestService.showUsage(guest.id), [guest.id]);
	const { handleUsage, ...usageModalProps } = useModelUsage(showUsageAction);

	const getFormValueFromAttribute = useCallback(
		(attribute: GuestViewAttributeResource) => {
			const guestAttribute = guest.attributes.find((a) => a.id.toString() === attribute.id.toString());

			switch (attribute.type) {
				case "column": {
					if (attribute.entityType === "status") {
						return guestAttribute?.value ? guestAttribute.value : GuestStatus.NONE;
					}
					break;
				}
				case "relation": {
					switch (attribute.entityType) {
						case "tags":
						case "groups":
							return !guestAttribute?.value ? [] : guestAttribute.value;
						case "registrationForm": {
							return guestAttribute ? guestAttribute.value : productData?.defaultRegistrationForm;
						}
					}
					break;
				}
				case "dataField": {
					const dataField = dataFields.find((dataField) => dataField.id === attribute.entityType);

					if (!dataField) {
						throw new Error("Unknown datafield");
					}

					switch (dataField.type) {
						case DataFieldType.MULTI_SELECT_FIELD:
							return !guestAttribute?.value
								? []
								: dataField.options.filter((option) => (guestAttribute.value as Id[]).includes(option.id));
						case DataFieldType.CHECKBOX_GROUP:
							return !guestAttribute?.value ? [] : guestAttribute.value;
						case DataFieldType.RADIO:
							return !guestAttribute?.value ? null : `${guestAttribute.value}`;
					}
				}
			}
			return guestAttribute?.value || null;
		},
		[dataFields, guest.attributes, productData?.defaultRegistrationForm],
	);

	const initialValues = useMemo(() => {
		return guestView.guestAttributes
			.filter(isEditable)
			.reduce((prev, curr) => ({ ...prev, [curr.id]: getFormValueFromAttribute(curr) }), {});
	}, [getFormValueFromAttribute, guestView.guestAttributes]);

	const validate = useCallback(
		(values: GuestFormValues) => {
			const errors: Record<Id, any> = {};
			const isEmpty = (value: unknown) => {
				return value == null || value === "" || (Array.isArray(value) && value.length === 0);
			};

			Object.entries(values).map(([key, attributeValue]) => {
				const guestAttribute = guestView.guestAttributes.find((guestAttribute) => guestAttribute.id.toString() === key);

				if (!guestAttribute) {
					throw new Error("Unknown attribute");
				}

				if (requiredGuestAttributeIds.includes(guestAttribute.id) && isEmpty(attributeValue)) {
					errors[key] = t("Required field");
				} else if (guestAttribute.entityType === "status" && !attributeValue) {
					errors[key] = t("Required field");
				} else if (guestAttribute.type === "dataField") {
					const dataField = dataFields.find((dataField) => dataField.id === guestAttribute.entityType);

					if (!dataField) {
						throw new Error("Unknown data field");
					}

					const dataFieldError = getDataFieldError(attributeValue, dataField.type, t);

					if (dataFieldError) {
						errors[key] = dataFieldError;
					}
				}
			});

			return errors;
		},
		[guestView.guestAttributes, dataFields, t, requiredGuestAttributeIds],
	);

	return (
		<>
			<FormikWithRedirect
				initialValues={initialValues}
				validate={validate}
				onSubmit={(values: GuestFormValues, helpers: FormikHelpersWithRedirect<GuestFormValues>) =>
					onSubmit(values, helpers, shouldGoBack)
				}
				enableReinitialize={true}
			>
				<CardBody>
					{guestView.guestAttributes.map((attribute) => (
						<GuestAttributeField
							key={attribute.id}
							guest={guest}
							dataFields={dataFields}
							dataFieldSettings={dataFieldSettings}
							attribute={attribute}
							required={requiredGuestAttributeIds.includes(attribute.id)}
						/>
					))}
				</CardBody>
				<CardFooter>
					{onDelete && (
						<Button onClick={handleUsage} title={t("Delete")} type="button" color="danger">
							{t("Delete")}
						</Button>
					)}
					<SubmitButton title={t("Save")}>{t("Save")}</SubmitButton>
					<SubmitButton title={t("Save and go back")} onClick={() => setShouldGoBack(true)} color="secondary">
						{t("Save and go back")}
					</SubmitButton>
				</CardFooter>
			</FormikWithRedirect>
			{onDelete && (
				<DeleteModelWithUsagesDialog
					usagePrefix={t("This guest")}
					confirmationBody={t("Are you sure you want to delete this guest?")}
					deleteAction={onDelete}
					{...usageModalProps}
				/>
			)}
		</>
	);
};

const getDataFieldError = (value: any, type: DataFieldType, t: TFunction): string | null => {
	if (type === DataFieldType.FILE && value instanceof File) {
		const file = value as File;

		if (file.size > config.uploadedFileMaxSize * 1000000) {
			return t("Max. {{size}} MB file size", { size: config.uploadedFileMaxSize });
		}
	}
	if (type === DataFieldType.EMAIL && value?.length > 0 && !isEmail(value)) {
		return t("Invalid email address");
	}
	if (type === DataFieldType.PHONE && value?.length > 0) {
		if (value.length > 20) {
			return t("This field may have only max. 20 characters");
		} else if (!PHONE_REGEX.test(value)) {
			return t("Invalid phone number");
		}
	}
	if (type === DataFieldType.TEXT && value?.length > 0 && value.length > 181) {
		return t("This field may have only max. 181 characters");
	}
	if (type === DataFieldType.URL && value?.length > 0 && !/^https?:\/\//.test(value)) {
		return t("Invalid URL format");
	}
	return null;
};
