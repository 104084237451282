import {
	Card,
	CardBody,
	CardHeader,
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerHeading,
	Id,
	Loading,
	useFormatDate,
} from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useGuestBookingQuery } from "~shared/api/guests/guestQueries";

const StyledCard = styled(Card)`
	margin-bottom: 1rem;
`;

interface Props {
	guestId: Id | null;
	onClose: () => void;
}

export const HotelBookingsDrawer: FC<Props> = ({ guestId, onClose }) => {
	const { t } = useTranslation();
	const { data: guestBookings, isLoading } = useGuestBookingQuery(guestId);
	const formatDate = useFormatDate();

	return (
		<Drawer onClose={onClose} open={!!guestId}>
			<>
				{isLoading ? (
					<Loading />
				) : (
					<>
						{guestBookings && (
							<>
								<DrawerHeader onClose={onClose}>
									<DrawerHeading>{guestBookings.fullName}</DrawerHeading>
								</DrawerHeader>
								<DrawerBody>
									{guestBookings.bookings.map((booking) => (
										<StyledCard key={booking.date}>
											<CardHeader>{formatDate(booking.date)}</CardHeader>
											<CardBody>
												<div>
													{t("Hotel")}: {booking.hotelName}
												</div>
												<div>
													{t("Room")}: {booking.roomTypeName}
												</div>
												<div>
													{t("Room mates")}: {booking.roommates.length > 0 ? booking.roommates.join(",") : "-"}
												</div>
											</CardBody>
										</StyledCard>
									))}
								</DrawerBody>
							</>
						)}
					</>
				)}
			</>
		</Drawer>
	);
};
