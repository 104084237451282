import { IconButton, Id, useOpen } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { GuestScreeningStatus } from "~shared/types/guest";
import { GuestScreeningStatusDrawer } from "./GuestScreeningStatusDrawer";

interface Props {
	guestId: Id;
	screeningStatus: GuestScreeningStatus;
}

export const GuestScreeningStatusChangeAction: FC<Props> = ({ guestId, screeningStatus }) => {
	const { t } = useTranslation();
	const { open, handleOpen, handleClose } = useOpen();

	return (
		<>
			<IconButton size="small" color="primary" title={t("Change screening status")} icon="edit" onClick={handleOpen} />
			<GuestScreeningStatusDrawer
				guestId={guestId}
				screeningStatus={screeningStatus}
				open={open}
				onClose={handleClose}
			/>
		</>
	);
};
