import { Button, Icon, IconButton, TextField } from "@dgs/core";
import { FieldArray, useField } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const RowWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr min-content;
	gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
`;
const Wrapper = styled.div`
	padding-bottom: ${({ theme }) => theme.spacing(5)};
`;
export const BccEmailAddressesField: FC<{ label: string; name: string }> = ({ label, name }) => {
	const [{ value }] = useField<string[]>(name);
	const { t } = useTranslation();

	return (
		<FieldArray
			name={name}
			render={(helpers) => (
				<Wrapper>
					<div>
						{value.map((_, idx) => (
							<RowWrapper key={idx}>
								<TextField name={`${name}.${idx}`} label={label} />
								<IconButton
									icon="delete"
									size="small"
									type="button"
									title={t("Delete")}
									onClick={() => helpers.remove(idx)}
								/>
							</RowWrapper>
						))}
					</div>
					<Button type="button" prefix={<Icon icon="plus" />} onClick={() => helpers.push("")}>
						{t("Add BCC address")}
					</Button>
				</Wrapper>
			)}
		/>
	);
};
