import { Id } from "@dgs/core";
import { IDataFieldOptionOptionResource } from "./dataFieldOption";
import { DataFieldType } from "./dataFieldType";

export interface DataFieldTranslatableAttributes {
	label: string;
	placeholder: string;
	description: string;
	dataPrivacyInfo: string;
}

export interface IDataFieldIndexResource {
	label: string;
	placeholder: string;
	description: string;
	id: Id;
	name: string;
	alias: string;
	order: number;
	type: DataFieldType;
	options: IDataFieldOptionOptionResource[];
}

export interface SortableDataField {
	id: Id;
	name: string;
	type: DataFieldType;
}

export type IDataFieldOptionResource = IDataFieldIndexResource;

export interface IDataFieldUpdateResource {
	id: Id;
	name: string;
	type: DataFieldType;
	translations: Record<string, DataFieldTranslatableAttributes>;
}

export type IDataFieldUpdateRequest = IDataFieldUpdateResource;

export type IDataFieldFormState = IDataFieldUpdateResource;

export interface SaveDataFieldSettings {
	firstNameId: Id;
	lastNameId: Id;
	emailId: Id;
	salutationId: Id;
	academicTitleId: Id | null;
	ccEmailId: Id | null;
	uniqueEmail: boolean;
	dateOfBirthId: Id | null;
}

export interface DataFieldSettings {
	firstName: IDataFieldUpdateResource;
	lastName: IDataFieldUpdateResource;
	email: IDataFieldUpdateResource;
	salutation: IDataFieldUpdateResource;
	academicTitle: IDataFieldUpdateResource | null;
	ccEmail: IDataFieldUpdateResource | null;
	uniqueEmail: boolean;
	dateOfBirth: IDataFieldUpdateResource | null;
}

export interface IDataFieldContingentResource {
	id: Id;
	name: string;
	options: IDataFieldContingentOptionResource[];
}

export interface IDataFieldContingentOptionResource {
	id: Id;
	value: string | null;
	contingent: number | null;
	usedContingent: number | null;
	availableContingent: number | null;
}

export const hasDataFieldOptions = (type: DataFieldType) =>
	["multiSelectField", "selectField", "radioField", "checkbox", "checkboxGroup"].includes(type);
