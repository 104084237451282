import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { emailKeys, emailService } from "~shared/api/emails";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { EmailFormValues } from "~shared/types/email";
import { FormikHelpersWithRedirect } from "~shared/ui/FormikWithRedirect";

export const useEmailActions = () => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { locales } = useContentLocale();

	const createEmail = useCallback(
		async (email: EmailFormValues, helpers: FormikHelpersWithRedirect<EmailFormValues>) => {
			const response = await emailService.store({
				...email,
				personalizedDocumentIds: email.personalizedDocuments.map((pD) => pD.id),
				guestFileCategoryIds: email.guestFileCategories.map((x) => x.id),
				tagIds: email.tags ? email.tags.map((tag) => tag.id) : [],
				translations: locales.reduce((translations, locale) => {
					return {
						...translations,
						[locale]: {
							subject: email.translations[locale].subject,
							replyTo: email.translations[locale].replyTo,
							fileIds: email.translations[locale].files ? email.translations[locale].files.map((file) => file.id) : [],
						},
					};
				}, {}),
			});

			if (apiIsOK(response)) {
				await queryClient.invalidateQueries({ queryKey: emailKeys.index });
				helpers.setTo(`../content/${response.data.data.blockId}`);
				showToast({ body: t("Email was saved."), title: t("Save email"), type: "success" });
				return true;
			} else {
				return false;
			}
		},
		[showToast, t, queryClient, locales],
	);

	const editEmail = useCallback(
		async (email: EmailFormValues, id: Id, helpers: FormikHelpersWithRedirect<EmailFormValues>) => {
			const response = await emailService.update(id, {
				...email,
				personalizedDocumentIds: email.personalizedDocuments.map((pD) => pD.id),
				guestFileCategoryIds: email.guestFileCategories.map((x) => x.id),
				tagIds: email.tags ? email.tags.map((tag) => tag.id) : [],
				translations: locales.reduce((translations, locale) => {
					return {
						...translations,
						[locale]: {
							subject: email.translations[locale].subject,
							replyTo: email.translations[locale].replyTo,
							fileIds: email.translations[locale].files ? email.translations[locale].files.map((file) => file.id) : [],
						},
					};
				}, {}),
			});

			if (apiIsOK(response)) {
				await queryClient.invalidateQueries({ queryKey: emailKeys.index });
				helpers.setTo("..");
				showToast({ body: t("Email successfully edited."), title: t("Edit email"), type: "success" });
				return true;
			} else {
				return false;
			}
		},
		[showToast, t, queryClient, locales],
	);

	return { createEmail, editEmail };
};
