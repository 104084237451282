import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderActions,
	CardHeading,
	I18nFormProvider,
	I18nTextField,
	SubmitButton,
	TextField,
	isEmail,
	useIsAddonEnabled,
} from "@dgs/core";
import { FormikErrors } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Switcher } from "~admin/emails/Switcher";
import { I18nFileMultiSelectField } from "~admin/shared/selects/I18nFileMultiSelectField";
import { PersonalizedDocumentMultiSelect } from "~admin/shared/selects/PersonalizedDocumentMultiSelect";
import { TranslationErrorToasts } from "~admin/shared/ui/TranslationErrorToasts";
import { StatusSelect } from "~shared/guests/details/StatusSelect";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { FileCategoryMultiSelectField } from "~shared/selects/FileCategorySelectField";
import { TagMultiSelect } from "~shared/selects/TagMultiSelect";
import { EmailFormValues } from "~shared/types/email";
import { FormikHelpersWithRedirect, FormikWithRedirect } from "~shared/ui/FormikWithRedirect";
import { BccEmailAddressesField } from "./BccEmailAddressesField";

interface Props {
	initialValues: EmailFormValues;
	onSubmit: (email: EmailFormValues, helpers: FormikHelpersWithRedirect<EmailFormValues>) => Promise<boolean>;
}

const Actions = styled.div`
	display: flex;
	justify-content: flex-end;
`;

const getErrorLocales = (errors: FormikErrors<EmailFormValues>, fallbackLocale: string): string[] => {
	let errorLocales: string[] = errors.translations ? Object.keys(errors.translations) : [];

	if (!errorLocales.some((locale) => locale === fallbackLocale) && errors.name) {
		errorLocales = [...errorLocales, fallbackLocale];
	}
	return errorLocales;
};

export const EmailForm: FC<Props> = ({ initialValues, onSubmit }) => {
	const { t } = useTranslation();
	const { fallbackLocale, locales } = useContentLocale();
	const isPersonalizedDocumentsEnabled = useIsAddonEnabled("personalizedDocuments");

	const validate = useCallback(
		(values: EmailFormValues) => {
			let errors: Record<string, any> = {};

			if (!values.name) {
				errors.name = t("Required field");
			}

			const bccErrors = Array(values.bccEmailAddresses.length).fill(null);

			values.bccEmailAddresses.forEach((email, idx) => {
				if (!email) {
					bccErrors[idx] = t("Required field");
				} else if (!isEmail(email)) {
					bccErrors[idx] = t("Invalid email address");
				}
			});

			if (bccErrors.filter((x) => x).length > 0) {
				errors.bccEmailAddresses = bccErrors;
			}

			const translations = values.translations[fallbackLocale] || {};

			if (!translations.subject) {
				errors = mapErrors(errors, fallbackLocale, "subject", t("Required field"));
			}

			locales.map((locale) => {
				if (values.translations[locale].replyTo && !isEmail(values.translations[locale].replyTo)) {
					errors = mapErrors(errors, locale, "replyTo", t("Invalid email address"));
				}
			});

			return errors;
		},
		[fallbackLocale, locales, t],
	);

	return (
		<I18nFormProvider locales={locales} fallbackLocale={fallbackLocale}>
			{({ isMainForm }) => (
				<FormikWithRedirect initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
					{({ errors, isSubmitting }) => (
						<Card>
							<CardHeader>
								<CardHeading>{t("Email data")}</CardHeading>
								<CardHeaderActions>
									<Switcher errorLocales={getErrorLocales(errors, fallbackLocale)} />
								</CardHeaderActions>
							</CardHeader>
							<CardBody>
								{isMainForm && <TextField label={t("Name")} name="name" required={true} />}
								{isMainForm && (
									<TextField
										label={t("Sender name")}
										name="senderName"
										description={t(
											"Please be aware that this can affect you SPAM rating. Fill in only if really needed.",
										)}
									/>
								)}
								{isMainForm && <StatusSelect name="changeStatusTo" label={t("Change status to")} />}
								<I18nTextField label={t("Subject")} name="subject" required={isMainForm} />
								{isMainForm && <BccEmailAddressesField label={t("Bcc")} name="bccEmailAddresses" />}
								<I18nTextField label={t("Reply-To")} name="replyTo" />
								<I18nFileMultiSelectField name="files" label={t("Files")} />
								{isPersonalizedDocumentsEnabled && isMainForm && (
									<PersonalizedDocumentMultiSelect name="personalizedDocuments" label={t("Personalized Documents")} />
								)}
								{isMainForm && (
									<FileCategoryMultiSelectField name="guestFileCategories" label={t("Guest file categories")} />
								)}
								{isMainForm && <TagMultiSelect name={"tags"} label={t("Automatically attach tags to guests")} />}
								<TranslationErrorToasts hasErrors={isSubmitting && Object.keys(errors).length > 0} />
								<Actions>
									<SubmitButton>{t("Save")}</SubmitButton>
								</Actions>
							</CardBody>
						</Card>
					)}
				</FormikWithRedirect>
			)}
		</I18nFormProvider>
	);
};

const mapErrors = <T extends Record<string, any>>(errors: T, locale: string, key: string, errorText: string) => {
	const currentErrors = errors.translations || {};
	const translationErrors = {
		translations: {
			...currentErrors,
			[locale]: {
				...(currentErrors[locale] || {}),
				[key]: errorText,
			},
		},
	};

	errors = {
		...errors,
		...translationErrors,
	};

	return errors;
};
