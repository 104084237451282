import { ContextMenu, ContextMenuDeleteItem, ContextMenuLinkItem, Id } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
	rulesetId: Id;
	onDelete: (rulesetId: Id) => void;
}

export const RuleSetContextMenu: FC<Props> = ({ rulesetId, onDelete }) => {
	const { t } = useTranslation();

	return (
		<ContextMenu displayShortcutsAs="icon">
			<ContextMenuLinkItem
				label={t("Edit")}
				to={`${rulesetId}`}
				title={t("Edit condition")}
				icon="edit"
				shortcut="primary"
			/>
			<ContextMenuDeleteItem
				action={() => onDelete(rulesetId)}
				title={t("Delete condition")}
				label={t("Delete")}
				heading={t("Delete")}
				labels={{ close: t("Close"), confirm: t("Confirm") }}
			>
				{t("Are you sure you want to delete this condition?")}
			</ContextMenuDeleteItem>
		</ContextMenu>
	);
};
