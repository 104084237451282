import { AddonProtected, BlocksPage, ImageList, ImageListProvider, Protected, RolePage } from "@dgs/core";
import React, { FC } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { CheckInAppPage } from "~admin/checkInApp/CheckInAppPage";
import { ConsentsPage } from "~admin/consents";
import { DashboardPage } from "~admin/dashboard";
import { DataFieldsPage } from "~admin/dataFields";
import { EmailsPage } from "~admin/emails";
import { ExportImportPage } from "~admin/exportImport/ExportImportPage";
import { FilesPage } from "~admin/files";
import { GroupsPage } from "~admin/groups";
import { GuestFileCategoryList } from "~admin/guestFileCategory/GuestFileCategoryList";
import { GuestFilterList } from "~admin/guestFilter/GuestFilterList";
import { GuestsPage } from "~admin/guests/GuestPage";
import { GuestViewList } from "~admin/guestView";
import { HotelManagementPage } from "~admin/hotels";
import { MenuPage } from "~admin/menu";
import { NominationModulePage } from "~admin/nominationModule/NominationModulePage";
import { PlaceholderList } from "~admin/placeholders/PlaceholderList";
import { RegistrationFormPage } from "~admin/registrationForms";
import { SettingsPage } from "~admin/settings";
import { ShopPage } from "~admin/shop";
import { TagsPage } from "~admin/tags";
import { ProfilePage } from "~admin/users/ProfilePage";
import { UsersPage } from "~admin/users/UsersPage";
import { WaitingListPage } from "~admin/waitingList/WaitingListPage";
import { PMBlockMetaProvider } from "~shared/blocks/PMBlockMetaProvider";
import { aspectOptions } from "~shared/defaultOptions";
import { LocalGuestViewProvider } from "~shared/providers/LocalGuestViewProvider";
import { AdminContent } from "./AdminContent";

export const AdminPage: FC = () => {
	return (
		<Routes>
			<Route
				path="*"
				element={
					<Protected fallback={<Navigate to="/admin/auth/login" />}>
						<AdminContent />
					</Protected>
				}
			>
				<Route
					element={
						<PMBlockMetaProvider scopes="web">
							<Outlet />
						</PMBlockMetaProvider>
					}
				>
					<Route path="dashboard" element={<DashboardPage />} />
					<Route path="guests/*" element={<GuestsPage />} />
					<Route path="guest-views" element={<GuestViewList />} />
					<Route path="guest-filters" element={<GuestFilterList />} />
					<Route path="grouping/*" element={<GroupingPage />} />
					<Route path="export-import/*" element={<ExportImportPage />} />
					<Route path="users-roles/*" element={<UsersRolesPage />} />
					<Route path="data-fields/*" element={<DataFieldsPage />} />
					<Route path="content-management/*" element={<ContentManagementPage />} />
					<Route
						path="hotel-management/*"
						element={
							<AddonProtected addon="hotel">
								<HotelManagementPage />
							</AddonProtected>
						}
					/>
					<Route path="registration-forms/*" element={<RegistrationFormPage />} />
					<Route path="consents/*" element={<ConsentsPage />} />
					<Route path="menu/*" element={<MenuPage />} />
					<Route path="settings/*" element={<SettingsPage />} />
					<Route path="placeholders" element={<PlaceholderList />} />
					<Route
						path="shop/*"
						element={
							<AddonProtected addon="shop">
								<ShopPage />
							</AddonProtected>
						}
					/>
					<Route
						path="waiting-list/*"
						element={
							<AddonProtected addon="waitingList">
								<LocalGuestViewProvider guestViewKey="waitingList">
									<WaitingListPage />
								</LocalGuestViewProvider>
							</AddonProtected>
						}
					/>
					<Route
						path="checkInApp/*"
						element={
							<AddonProtected addon="checkInApp">
								<CheckInAppPage />
							</AddonProtected>
						}
					/>
				</Route>
				<Route
					element={
						<PMBlockMetaProvider scopes="email">
							<Outlet />
						</PMBlockMetaProvider>
					}
				>
					<Route path="emails/*" element={<EmailsPage />} />
				</Route>
				<Route
					path="nomination/*"
					element={
						<AddonProtected addon="nominationModule">
							<NominationModulePage />
						</AddonProtected>
					}
				/>
				<Route path="profile" element={<ProfilePage />} />
			</Route>
		</Routes>
	);
};

const ContentManagementPage = () => {
	return (
		<Routes>
			<Route path="blocks/*" element={<BlocksPage />} />
			<Route
				path="images"
				element={
					<ImageListProvider>
						<ImageList aspectRatioOptions={aspectOptions} />
					</ImageListProvider>
				}
			/>
			<Route path="files/*" element={<FilesPage />} />
		</Routes>
	);
};

const GroupingPage = () => {
	return (
		<Routes>
			<Route path="groups/*" element={<GroupsPage />} />
			<Route path="tags/*" element={<TagsPage />} />
			<Route path="file-categories/*" element={<GuestFileCategoryList />} />
			<Route path="*" element={<Navigate to="groups" />} />
		</Routes>
	);
};

const UsersRolesPage = () => {
	return (
		<Routes>
			<Route path="users/*" element={<UsersPage />} />
			<Route path="roles/*" element={<RolePage />} />
			<Route path="*" element={<Navigate to="users" />} />
		</Routes>
	);
};
