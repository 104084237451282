import {
	Card,
	CardBody,
	CardHeader,
	CardHeading,
	CheckboxField,
	DateTimeField,
	FlatIconButton,
	SubmitButton,
	Trigger,
	useOpen,
} from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ContentLanguageSingleSelect } from "~shared/selects/ContentLanguageSingleSelect";
import { EmailSendOutFormState } from "~shared/types/email";
import { EmailRecipientDrawer } from "./EmailRecipientDrawer";
import { EmailSendOutDrawer } from "./EmailSendOutDrawer";
import { useEmailSendOut } from "./EmailSendOutProvider";

const StyledCard = styled(Card)`
	overflow: visible;
`;

const StyledCardBody = styled(CardBody)`
	display: grid;
	grid-template-columns: 1fr;
	gap: ${({ theme }) => theme.spacing(4)};
`;

const StyledCheckboxField = styled(CheckboxField)`
	margin-bottom: ${({ theme }) => theme.spacing(1)};
`;

const Recipients = styled.div`
	display: flex;
	align-items: center;
`;

const RecipientCount = styled.span`
	margin-left: ${({ theme }) => theme.spacing(1)};
	white-space: nowrap;
`;

const formState: EmailSendOutFormState = {
	showSendAt: false,
	sendAt: null,
	locale: "registrationLocale",
};

export const EmailSendOutSendCard: FC = () => {
	const { t } = useTranslation();
	const { open, handleOpen, handleClose } = useOpen();
	const { sendMail, recipientCount } = useEmailSendOut();
	const onModalSubmit = useCallback(
		({ sendAt, locale }: EmailSendOutFormState) => {
			sendMail(sendAt, locale);
			handleClose();
		},
		[handleClose, sendMail],
	);
	const onFormSubmit = useCallback(async () => {
		handleOpen();
	}, [handleOpen]);

	const validate = useCallback(
		({ showSendAt, sendAt }: EmailSendOutFormState) => {
			if (showSendAt && !sendAt) {
				return { sendAt: t("Required field") };
			}
			return {};
		},
		[t],
	);

	return (
		<>
			<Formik initialValues={formState} onSubmit={onFormSubmit} validate={validate}>
				{({ values, resetForm }) => (
					<Form>
						<StyledCard>
							<CardHeader>
								<CardHeading>{t("Send email")}</CardHeading>
							</CardHeader>
							<StyledCardBody>
								<StyledCheckboxField name="showSendAt" label={t("Specific date")} />
								{values.showSendAt && <DateTimeField name="sendAt" label={t("Send at")} />}
								<Recipients>
									<Trigger render={(props) => <EmailRecipientDrawer {...props} />}>
										{(triggerProps) => (
											<FlatIconButton icon="eye" size="small" title={t("Open recipient drawer")} {...triggerProps} />
										)}
									</Trigger>
									<RecipientCount>{t("Recipient", { count: recipientCount })}</RecipientCount>
								</Recipients>
								<ContentLanguageSingleSelect name="locale" label={t("Language")} />
								<SubmitButton>{t("Send")}</SubmitButton>
							</StyledCardBody>
						</StyledCard>
						<EmailSendOutDrawer
							open={open}
							onClose={handleClose}
							onSubmit={() => {
								onModalSubmit(values);
								resetForm({ values });
								return true;
							}}
							initialValues={formState}
						/>
					</Form>
				)}
			</Formik>
		</>
	);
};
