import { BaseBlockForm, FormProps, IBaseBlockEditResource, NameField } from "@dgs/core";
import React, { FC } from "react";
import { emailOrderSummaryBlockForm } from "./EmailOrderSummaryBlock";

export const EmailOrderSummaryBlockForm: FC<FormProps<IBaseBlockEditResource>> = ({ block, onSubmit }) => {
	return (
		<BaseBlockForm block={block} onSubmit={onSubmit} blockForm={emailOrderSummaryBlockForm}>
			<NameField blockType={block.type} />
		</BaseBlockForm>
	);
};
