import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { taxKeys, taxService } from "~admin/shared/api/tax";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
}

const fetcher = (search: string, page: number) => taxService.options(search, page).then((r) => r.data);

export const TaxSingleSelect: FC<Props> = ({ name, label, disabled, required }) => {
	return (
		<PagedSingleSelectField
			name={name}
			queryKey={taxKeys.options}
			fetcher={fetcher}
			label={label}
			disabled={disabled}
			required={required}
			getLabel={(o) => `${o.percent}% ${o.type}`}
		/>
	);
};
