import { Drawer, DrawerBody, DrawerHeader, DrawerHeading } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IRegistrationFormIndex } from "~shared/types/registrationForm";
import { RegistrationFormForm } from "./RegistrationFormForm";
import { useRegistrationFormForm } from "./useRegistrationFormForm";

interface Props {
	registrationForm: IRegistrationFormIndex;
	open: boolean;
	onClose: () => void;
}

export const EditRegistrationFormDrawer: FC<Props> = ({ registrationForm, open, onClose }) => {
	const { t } = useTranslation();
	const { updateRegistrationForm } = useRegistrationFormForm();

	return (
		<Drawer open={open} onClose={onClose}>
			<DrawerHeader onClose={onClose}>
				<DrawerHeading>{t("Edit registration form")}</DrawerHeading>
			</DrawerHeader>
			<DrawerBody>
				<RegistrationFormForm
					initialValues={registrationForm}
					onSubmit={async (registrationForm) => {
						const registrationFormId = await updateRegistrationForm(
							(registrationForm as IRegistrationFormIndex).id,
							registrationForm,
						);

						if (registrationFormId === false) {
							return false;
						}

						onClose();
						return true;
					}}
				/>
			</DrawerBody>
		</Drawer>
	);
};
