import { Button, H6 } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CreateDataFieldButton } from "~admin/dataFields/CreateDataFieldButton";
import { BlockIcon } from "~admin/registrationForms/structure/blocks/BlockIcon";
import { EditSortableLists } from "~admin/shared/ui/sortableLists/EditSortableLists";
import { SortableChild } from "~shared/types/registrationFormTypes";

const Name = styled.span`
	font-size: 14px;
`;

const Suffix = styled.span`
	font-size: 12px;
	color: ${({ theme }) => theme.colors.palette.grey[70]};
`;

const ListHeader = styled.span`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

interface Props {
	initialChildren: SortableChild[];
	activeChildren: SortableChild[];
	setActiveChildren: (sortableDataFields: SortableChild[]) => void;
}

export const EditSortableDataFields: FC<Props> = ({ initialChildren, activeChildren, setActiveChildren }) => {
	const { t } = useTranslation();
	const onlyVerticalSortableChildren = activeChildren.filter((child) => child.isRestrictedToVerticalAxis);

	return (
		<EditSortableLists
			value={activeChildren}
			onChange={setActiveChildren}
			initialItems={initialChildren}
			getLabel={(dataField) => dataField.name}
			headerActions={<CreateDataFieldButton />}
			renderLabel={(sortableChild) => (
				<>
					{sortableChild.entityType === "dataField" ? (
						<>
							<Name>{sortableChild.name}</Name>
							<Suffix>-</Suffix>
							<Suffix>{sortableChild.dataFieldType}</Suffix>
						</>
					) : (
						<>
							<BlockIcon />
							<Name>{sortableChild.name}</Name>
							<Suffix>-</Suffix>
							<Suffix>{sortableChild.entityType}</Suffix>
						</>
					)}
				</>
			)}
			listHeaders={{
				active: (
					<ListHeader>
						<H6>{t("Assigned")}</H6>
						<Button type="button" size="small" onClick={() => setActiveChildren(onlyVerticalSortableChildren)}>
							{t("Remove all")}
						</Button>
					</ListHeader>
				),
				available: (
					<ListHeader>
						<H6>{t("Available")}</H6>
						<Button
							type="button"
							size="small"
							onClick={() => setActiveChildren([...onlyVerticalSortableChildren, ...initialChildren])}
						>
							{t("Assign all")}
						</Button>
					</ListHeader>
				),
			}}
		/>
	);
};
