import { PagedSingleSelectField, SelectSingleChangeHandler } from "@dgs/core";
import React, { FC } from "react";
import { guestViewService } from "~shared/api/guestViews";
import { IGuestViewOptionResource } from "~shared/types/guestView";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
	onChange?: SelectSingleChangeHandler<IGuestViewOptionResource>;
}

const fetcher = (search: string, page: number) => guestViewService.options(search, page).then((r) => r.data);

export const GuestViewSelect: FC<Props> = ({ name, label, disabled, required, onChange }) => {
	return (
		<PagedSingleSelectField
			name={name}
			queryKey={guestViewService.keys.options}
			fetcher={fetcher}
			label={label}
			disabled={disabled}
			clearable={true}
			getLabel={(option) => `${option.name}`}
			required={required}
			onChange={onChange}
		/>
	);
};
