import { ApplicationBar, H2 } from "@dgs/core";
import React, { FC } from "react";
import styled from "styled-components";
import { UserDropDown } from "~admin/users/UserDropDown";
import { useProductDataQuery } from "~shared/api/productData";
import { GlobalGuestViewSwitcher } from "~shared/guests";

const Actions = styled.div`
	margin-left: auto;
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	gap: 0.5rem;
	height: 100%;
	align-items: center;
`;

const AppName = styled(H2)`
	font-size: 1.25rem;
	margin-bottom: 0;
	color: ${({ theme }) => theme.colors.applicationBar.text};
`;

export const Header: FC = () => {
	const { data: productData } = useProductDataQuery();

	return (
		<ApplicationBar>
			<AppName>{productData?.eventName || "DGS Participant Management"}</AppName>
			<Actions>
				<GlobalGuestViewSwitcher />
				<UserDropDown />
			</Actions>
		</ApplicationBar>
	);
};
