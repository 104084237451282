import { CrudDrawer, EntityDrawerProps, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CountryMultiSelect } from "~admin/shared/selects/CountryMultiSelect";
import { InvoiceProviderTypeSelectField } from "~admin/shared/selects/InvoiceProviderTypeSelectField";
import { MerchantSingleSelectField } from "~admin/shared/selects/MerchantSingleSelectField";
import { InvoiceProviderConfigField } from "~admin/shop/invoiceProviders/InvoiceProviderConfigField";
import { IInvoiceProviderBaseFormState, IInvoiceProviderFormState } from "~shared/types/invoiceProvider";

export const emptyInvoiceProvider: IInvoiceProviderBaseFormState = {
	name: "",
	type: null,
	merchant: null,
	countries: [],
	config: {},
};
export const InvoiceProviderDrawer: FC<EntityDrawerProps<IInvoiceProviderFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(formState: IInvoiceProviderFormState) => {
			const errors: { [P in keyof IInvoiceProviderFormState]?: any } = {};

			if (!formState.type) {
				errors.type = t("Required field");
			}
			if (!formState.name) {
				errors.name = t("Required field");
			}
			if (!formState.merchant) {
				errors.merchant = t("Required field");
			}
			if (!formState.countries) {
				errors.countries = t("Required field");
			}
			if (formState.type === "BMW") {
				const configErrors: Partial<IInvoiceProviderFormState["config"]> = {};
				const configTemplateErrors: Partial<IInvoiceProviderFormState["config"]["template"]> = {};

				if (!formState.config.apiKey) {
					configErrors.apiKey = t("Required field");
				}
				if (!formState.config.merchantId) {
					configErrors.merchantId = t("Required field");
				}
				if (!formState.config.merchantDescription) {
					configErrors.merchantDescription = t("Required field");
				}
				if (formState.config.template) {
					if (!formState.config.template.brand) {
						configTemplateErrors.brand = t("Required field");
					}
					if (!formState.config.template.variantName) {
						configTemplateErrors.variantName = t("Required field");
					}
					if (!formState.config.template.language) {
						configTemplateErrors.language = t("Required field");
					}
					if (!formState.config.template.country) {
						configTemplateErrors.country = t("Required field");
					}
					if (Object.keys(configTemplateErrors).length) {
						configErrors.template = configTemplateErrors as any;
					}
				}
				if (Object.keys(configErrors).length) {
					errors.config = configErrors;
				}
			}
			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<TextField name="name" label={t("Name")} required={true} />
					<MerchantSingleSelectField label={t("Merchant")} name="merchant" required={true} />
					<CountryMultiSelect name="countries" label={t("Countries")} required={true} />
					<InvoiceProviderTypeSelectField />
					<InvoiceProviderConfigField />
				</>
			}
		/>
	);
};
