import { Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { SyncChildrenRequest } from "~shared/types/registrationFormTypes";
import { Rule } from "~shared/types/rules";

export class RegistrationFormDataFieldService {
	public keys = {
		sync: (registrationFormId: Id, stepId: Id, sectionId: Id) => [
			"registration-forms",
			`${registrationFormId}`,
			"steps",
			`${stepId}`,
			"sections",
			`${sectionId}`,
			"sync-data-fields",
		],
		detach: (registrationFormId: Id, stepId: Id, sectionId: Id) => [
			"registration-forms",
			`${registrationFormId}`,
			"steps",
			`${stepId}`,
			"sections",
			`${sectionId}`,
			"detach-data-fields",
		],
		validationRules: (registrationFormId: Id, stepId: Id, sectionId: Id, dataFieldId: Id) => [
			"registration-forms",
			`${registrationFormId}`,
			"steps",
			`${stepId}`,
			"sections",
			`${sectionId}`,
			"validations",
			`${dataFieldId}`,
		],
	};

	protected urls = {
		sync: (registrationFormId: Id, stepId: Id, sectionId: Id) =>
			keyToUrl(this.keys.sync(registrationFormId, stepId, sectionId)),
		detach: (registrationFormId: Id, stepId: Id, sectionId: Id) =>
			keyToUrl(this.keys.detach(registrationFormId, stepId, sectionId)),
		validationRules: (formId: Id, stepId: Id, sectionId: Id, dataFieldId: Id) =>
			keyToUrl(this.keys.validationRules(formId, stepId, sectionId, dataFieldId)),
	};

	sync(
		registrationFormId: Id,
		stepId: Id,
		sectionId: Id,
		syncChildrenRequest: { syncChildrenRequest: SyncChildrenRequest[] },
	) {
		return axios.put<void>(this.urls.sync(registrationFormId, stepId, sectionId), syncChildrenRequest);
	}

	detach(registrationFormId: Id, stepId: Id, sectionId: Id, dataFieldIds: { dataFieldIds: Id[] }) {
		return axios.put<void>(this.urls.detach(registrationFormId, stepId, sectionId), dataFieldIds);
	}

	postValidationRules(formId: Id, stepId: Id, sectionId: Id, dataFieldId: Id, validationRules: Record<string, any>) {
		return axios.post<LaravelResponse<Rule[]>>(this.urls.validationRules(formId, stepId, sectionId, dataFieldId), {
			rules: validationRules,
		});
	}
}

export const registrationFormDataFieldService = new RegistrationFormDataFieldService();
