import { Id, LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { IBaseService } from "~shared/api/baseService";
import { mapFetcherConfigToAxiosConfig } from "~shared/api/indexMapper";
import {
	IHotelInquiryAccept,
	IHotelInquiryCounts,
	IHotelInquiryIndex,
	IHotelInquiryRequest,
	IHotelInquiryResource,
} from "~shared/types/hotelInquiry";
import { IRoomAllocationRequest } from "~shared/types/roomContingent";

export class HotelInquiryService extends IBaseService<IHotelInquiryIndex, IHotelInquiryResource, IHotelInquiryRequest> {
	public keys = {
		index: ["hotel-inquiries"],
		list: ["hotel-inquiries", "list"],
		details: (hotelInquiryId: Id) => ["hotel-inquiries", `${hotelInquiryId}`],
		resolveConflicts: (hotelInquiryId: Id) => ["hotel-inquiries", `${hotelInquiryId}`, "resolve-conflicts"],
		acceptInquiry: (hotelInquiryId: Id) => ["hotel-inquiries", `${hotelInquiryId}`, "accept"],
		pending: ["hotel-inquiries", "pending"],
		confirmed: ["hotel-inquiries", "confirmed"],
		conflicts: ["hotel-inquiries", "conflict"],
		inquiryCounts: ["hotel-inquiries", "inquiryCounts"],
	};
	protected urls = {
		index: keyToUrl(this.keys.index),
		details: (hotelInquiryId: Id) => keyToUrl(this.keys.details(hotelInquiryId)),
		resolveConflicts: (hotelInquiryId: Id) => keyToUrl(this.keys.resolveConflicts(hotelInquiryId)),
		acceptInquiry: (hotelInquiryId: Id) => keyToUrl(this.keys.acceptInquiry(hotelInquiryId)),
		pending: keyToUrl(this.keys.pending),
		confirmed: keyToUrl(this.keys.confirmed),
		conflict: keyToUrl(this.keys.conflicts),
		inquiryCounts: keyToUrl(this.keys.inquiryCounts),
	};

	pending = (config: ListFetcherConfig<any>) => {
		return axios.get<LaravelResponse<IHotelInquiryAccept[]>>(this.urls.pending, mapFetcherConfigToAxiosConfig(config));
	};
	confirmed = (config: ListFetcherConfig<any>) => {
		return axios.get<LaravelResponse<IHotelInquiryIndex[]>>(this.urls.confirmed, mapFetcherConfigToAxiosConfig(config));
	};
	conflicts = (config: ListFetcherConfig<any>) => {
		return axios.get<LaravelResponse<IHotelInquiryAccept[]>>(this.urls.conflict, mapFetcherConfigToAxiosConfig(config));
	};
	resolveConflicts = (hotelInquiryId: Id, roomAllocation: IRoomAllocationRequest[]) => {
		return axios.put<LaravelResponse<IHotelInquiryIndex[]>>(this.urls.resolveConflicts(hotelInquiryId), {
			roomAllocation,
		});
	};
	acceptInquiry = (hotelInquiryId: Id, roomAllocation: IRoomAllocationRequest[]) => {
		return axios.put<LaravelResponse<IHotelInquiryIndex[]>>(this.urls.acceptInquiry(hotelInquiryId), {
			roomAllocation,
		});
	};
	inquiryCounts = (config?: AxiosRequestConfig) => {
		return axios.get<LaravelResponse<IHotelInquiryCounts>>(this.urls.inquiryCounts, config);
	};
}

export const hotelInquiryService = new HotelInquiryService();
