import { apiIsOK, useToasts } from "@dgs/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { shopSettingsKeys, shopSettingsService } from "~admin/shared/api/shop";
import { systemMessageService } from "~admin/shared/api/systemMessages";
import { IShopSettings } from "~shared/types/shop";
import { ShopSettingsForm } from "./ShopSettingsForm";

export const ShopSettings: FC = () => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { data: shopSettings, isLoading } = useQuery({
		queryKey: shopSettingsKeys.settings,
		queryFn: () => shopSettingsService.getSettings().then((r) => r.data.data),
	});

	const onSubmit = useCallback(
		async (settings: IShopSettings) => {
			const response = await shopSettingsService.putSettings({
				streetDataFieldId: settings.streetDataField.id,
				houseNumberDataFieldId: settings.houseNumberDataField.id,
				zipDataFieldId: settings.zipDataField.id,
				cityDataFieldId: settings.cityDataField.id,
				countryDataFieldId: settings.countryDataField.id,
				isBusinessDataFieldId: settings.isBusinessDataField.id,
				documentPrefix: settings.documentPrefix,
				currencyId: settings.currency.id,
				defaultInvoiceProviderId: settings.defaultInvoiceProvider.id,
				invoiceRecipientDataFieldId: settings.invoiceRecipientDataField.id,
				vatIdDataFieldId: settings.vatIdDataField.id,
				customerReferenceDataFieldId: settings.customerReferenceDataField
					? settings.customerReferenceDataField.id
					: null,
				ccInvoiceEmailDataFieldId: settings.ccInvoiceEmailDataField ? settings.ccInvoiceEmailDataField.id : null,
			});

			if (apiIsOK(response)) {
				showToast({ body: t("Shop settings saved."), type: "success", title: t("Shop settings") });
				await queryClient.invalidateQueries({ queryKey: shopSettingsKeys.settings });
				await queryClient.invalidateQueries({ queryKey: systemMessageService.keys.all });
				return true;
			}

			return false;
		},
		[showToast, t, queryClient],
	);

	if (isLoading || !shopSettings) {
		return null;
	}

	return <ShopSettingsForm initialData={shopSettings} onSubmit={onSubmit} />;
};
