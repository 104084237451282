import { PagedMultiSelectField, PagedSingleSelectField, SingleSelectField } from "@dgs/core";
import { useField } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { paymentProviderService } from "~admin/shared/api/paymentProvider";
import { usePaymentProviderTypeOptionsQuery } from "~admin/shared/api/paymentProvider/paymentProviderQueries";
import {
	IPaymentProviderFormState,
	IPaymentProviderIndexResource,
	TPaymentProviderType,
} from "~shared/types/paymentProvider";

export const PaymentProviderTypeSelectField: FC = () => {
	const { t } = useTranslation();
	const [, , { setValue: setConfig }] = useField<IPaymentProviderFormState["config"]>("config");
	const { typeOptions } = usePaymentProviderTypeOptionsQuery();

	const changeConfigByType = useCallback(
		(e: TPaymentProviderType) => {
			switch (e) {
				case "PayPal":
					return setConfig({
						clientId: "",
						clientSecret: "",
						inTestMode: true,
					});
				case "Stripe":
					return setConfig({
						apiKey: "",
					});
				case "Mollie":
					return setConfig({
						apiKey: "",
					});
				case "BMWCreditCard":
				case "BMWPayPal":
					return setConfig({
						apiKey: "",
						integratorId: "",
						merchantId: "",
						merchantDescription: "",
						inTestMode: true,
					});
				case "Invoice":
					return setConfig({});
			}
		},
		[setConfig],
	);

	return (
		<SingleSelectField
			options={typeOptions}
			label={t("Type")}
			name="type"
			getValue={(x) => x}
			getLabel={(x) => x}
			onChange={(e) => e.target.value && changeConfigByType(e.target.value)}
		/>
	);
};

export interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
}

const fetcher = (search: string, page: number) =>
	paymentProviderService.options({ params: { search, page } }).then((r) => r.data);

export const PaymentProviderMultiSelect: FC<Props> = ({ name, label, disabled, required }) => {
	return (
		<PagedMultiSelectField<IPaymentProviderIndexResource>
			name={name}
			queryKey={paymentProviderService.keys.options}
			fetcher={fetcher}
			label={label}
			disabled={disabled}
			clearable={true}
			getLabel={(option) => `${option.name}`}
			required={required}
		/>
	);
};

export const PaymentProviderSingleSelect: FC<Props> = ({ name, label, disabled, required }) => {
	return (
		<PagedSingleSelectField<IPaymentProviderIndexResource>
			name={name}
			queryKey={paymentProviderService.keys.options}
			fetcher={fetcher}
			label={label}
			disabled={disabled}
			clearable={true}
			required={required}
		/>
	);
};
