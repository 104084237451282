import { FCC, Id, useDynamicConfig } from "@dgs/core";
import React, { createContext, useCallback, useContext, useMemo, useState } from "react";
import { GuestViewConfigResource } from "~shared/types/guestView";

interface GlobalGuestProviderState {
	globalGuestView: GuestViewConfigResource;
	guestViews: GuestViewConfigResource[];
	setGlobalGuestView: (guestView: GuestViewConfigResource) => void;
}

const GlobalGuestViewContext = createContext<GlobalGuestProviderState | undefined>(undefined);

const findGuestViewById = (guestViews: GuestViewConfigResource[], id: Id) => {
	const parsedId = Number(id);
	return guestViews.find((guestView) => guestView.id === parsedId);
};

const findGuestViewByIdOrByFallbackId = (
	guestViews: GuestViewConfigResource[],
	id: Id,
	fallbackId?: Id,
): GuestViewConfigResource => {
	const foundById = findGuestViewById(guestViews, id);
	if (foundById) return foundById;

	if (fallbackId) {
		const foundByFallbackId = findGuestViewById(guestViews, fallbackId);
		if (foundByFallbackId) return foundByFallbackId;
	}

	return guestViews[0];
};

const getGuestViewFromLocalStorage = (
	guestViews: GuestViewConfigResource[],
	key: string,
	initialGuestViewId: Id | undefined,
) => {
	const id = localStorage.getItem(key) ?? initialGuestViewId;

	if (!id) {
		return guestViews[0];
	}

	return findGuestViewById(guestViews, id) || guestViews[0];
};

interface Props {
	guestViewKey: string;
	initialGuestViewId?: Id;
}

export const GlobalGuestViewProvider: FCC<Props> = ({ initialGuestViewId, guestViewKey, children }) => {
	const { guestViews } = useDynamicConfig();
	const [guestViewId, setGuestViewId] = useState(() => {
		return getGuestViewFromLocalStorage(guestViews, guestViewKey, initialGuestViewId).id;
	});
	const globalGuestView = useMemo(() => {
		return findGuestViewByIdOrByFallbackId(guestViews, guestViewId, initialGuestViewId);
	}, [guestViews, guestViewId, initialGuestViewId]);

	const setGlobalGuestView = useCallback(
		(guestView: GuestViewConfigResource) => {
			localStorage.setItem(guestViewKey, `${guestView.id}`);
			setGuestViewId(guestView.id);
		},
		[guestViewKey],
	);

	return (
		<GlobalGuestViewContext.Provider value={{ globalGuestView, guestViews, setGlobalGuestView }}>
			{children}
		</GlobalGuestViewContext.Provider>
	);
};

export const useGlobalGuestView = () => {
	const ctx = useContext(GlobalGuestViewContext);

	if (ctx === undefined) {
		throw new Error("useGlobalGuestView should be wrapped in a GlobalGuestViewProvider");
	}

	return ctx;
};
