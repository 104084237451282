import { AutoDataList, IDataListColumn } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { CreateFileButton } from "~admin/files/CreateFileButton";
import { FileListContextMenu } from "~admin/files/FileListContextMenu";
import { filesKeys, filesService } from "~admin/shared/api/files";
import { IFile } from "~shared/types/file";

export const FileList: FC = () => {
	const { t } = useTranslation();
	const dataListColumns: IDataListColumn<IFile>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "filename",
				type: "value",
			},
			{
				heading: t("Path"),
				valueKey: "path",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				action: (file) => <FileListContextMenu file={file} />,
			},
		],
		[t],
	);

	return (
		<AutoDataList
			heading={t("Files")}
			headerActions={<CreateFileButton />}
			empty={t("No files available")}
			columns={dataListColumns}
			queryKey={filesKeys.list}
			fetcher={filesService.index}
		/>
	);
};
