import { LinkTab, LinkTabs } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { EmailSendOutGuestsList } from "~admin/emails/sendOut/details/EmailSendOutGuestsList";

const Wrapper = styled.div`
	max-height: 100%;
	display: grid;
	grid-auto-rows: max-content 1fr;
	gap: ${({ theme }) => theme.spacing(4)};
`;

export const EmailSendOutDetailsPage: FC = () => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<LinkTabs>
				<LinkTab to="successful">{t("Successful recipients")}</LinkTab>
				<LinkTab to="failed">{t("Failed recipients")}</LinkTab>
			</LinkTabs>
			<Routes>
				<Route index element={<Navigate to="successful" replace={true} />} />
				<Route
					path="successful"
					element={<EmailSendOutGuestsList status="successful" heading={t("Successful recipients")} />}
				/>
				<Route path="failed" element={<EmailSendOutGuestsList status="failed" heading={t("Failed recipients")} />} />
			</Routes>
		</Wrapper>
	);
};
