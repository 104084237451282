import { IconButton, Id, apiIsOK, useList, useToasts } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { locationService } from "~admin/shared/api/locations";
import { useGuestFilter } from "~shared/guestFilters";
import { IActiveGuestFilterEntry } from "~shared/guestFilters/guestFilterTypes";

interface props {
	filters: IActiveGuestFilterEntry[];
	locationId: Id;
	guestViewId: Id;
}

export const LogExportButton: FC<props> = ({ filters, locationId, guestViewId }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const { searchParams, sort } = useList();
	const { mapGuestFilterToGuestFilterRequest } = useGuestFilter();

	const createExport = useCallback(async () => {
		const response = await locationService.exportLogs(
			locationId,
			guestViewId,
			mapGuestFilterToGuestFilterRequest(filters),
			searchParams?.search ?? null,
			sort,
		);
		if (apiIsOK(response)) {
			showToast({
				body: t("An export is being created, please wait."),
				title: t("Export"),
				type: "success",
			});
		}
	}, [searchParams?.search, sort, locationId, guestViewId, filters, showToast, t, mapGuestFilterToGuestFilterRequest]);

	return (
		<IconButton type="button" size="small" onClick={() => createExport()} title={t("Download")} icon="file_download" />
	);
};
