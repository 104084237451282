import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { GuestList } from "~admin/guests/GuestList";
import { GuestFilterDrawer, GuestFilterProvider } from "~shared/guestFilters";
import { GuestDetailsProvider } from "~shared/guests/details/GuestDetailProvider";
import { LocalGuestViewProvider } from "~shared/providers/LocalGuestViewProvider";
import { GuestDetailsPage } from "./GuestDetailsPage";

interface Props {
	showEmails?: boolean;
}

export const GuestsPage: FC<Props> = ({ showEmails }) => {
	return (
		<Routes>
			<Route
				index
				element={
					<GuestFilterProvider listFilterKey="guestListFilter">
						<GuestList />
						<GuestFilterDrawer />
					</GuestFilterProvider>
				}
			/>
			<Route
				path=":guestId/*"
				element={
					<LocalGuestViewProvider guestViewKey="companionsList">
						<GuestDetailsProvider requiredGuestAttributeIds={[]}>
							<GuestFilterProvider listFilterKey="companionListFilter">
								<GuestDetailsPage showEmails={showEmails} />
								<GuestFilterDrawer />
							</GuestFilterProvider>
						</GuestDetailsProvider>
					</LocalGuestViewProvider>
				}
			/>
		</Routes>
	);
};
