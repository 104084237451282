import { LaravelResponse, PagedSingleSelectField, useDynamicConfig } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import React, { FC } from "react";
import { Currency } from "~shared/types/currency";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	clearable?: boolean;
	required?: boolean;
}

const currencyFetcher = (apiUrl: string, AxiosRequestConfig?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<Currency[]>>(`${apiUrl}/currencies/options`, AxiosRequestConfig);

export const CurrencySingleSelect: FC<Props> = ({ name, label, disabled, clearable, required }) => {
	const { apiUrl } = useDynamicConfig();

	return (
		<PagedSingleSelectField<Currency>
			name={name}
			label={label}
			disabled={disabled}
			fetcher={(search, page) => currencyFetcher(apiUrl, { params: { search, page } }).then((r) => r.data)}
			queryKey={["currencies"]}
			clearable={clearable}
			getLabel={(option) => `${option.name} (${option.isoCode})`}
			required={required}
		/>
	);
};
