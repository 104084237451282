import {
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	DrawerAutoDataList,
	IDrawerDataListColumn,
	apiIsOK,
	useCrudEntity,
	useEditDrawer,
	useToasts,
} from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DataFieldDrawer } from "~admin/dataFields/DataFieldDrawer";
import { DataFieldOptionsDrawer } from "~admin/dataFields/dataFieldOptions/DataFieldOptionsDrawer";
import { dataFieldService } from "~shared/api/dataFields";
import { guestAttributeKeys } from "~shared/api/guestAttributes";
import { guestKeys } from "~shared/api/guests";
import { guestViewService } from "~shared/api/guestViews";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import {
	DataFieldTranslatableAttributes,
	IDataFieldFormState,
	IDataFieldIndexResource,
	IDataFieldUpdateResource,
	hasDataFieldOptions,
} from "~shared/types/dataField";
import { DataFieldType } from "~shared/types/dataFieldType";

export const DataFieldList = () => {
	const { t } = useTranslation();
	const crudOperations = useCrudEntity(
		dataFieldService,
		{
			entityName: t("Data field"),
			messages: {
				create: t("Data field was saved."),
				update: t("Data field was saved."),
				delete: t("Data field was successfully deleted."),
			},
		},
		[dataFieldService.keys.contingentOverview],
	);
	const { locales } = useContentLocale();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const emptyDataField: IDataFieldFormState = {
		id: -1,
		name: "",
		type: DataFieldType.TEXT,
		translations: locales.reduce(
			(translations, locale) => ({
				...translations,
				[locale]: {
					label: "",
					placeholder: "",
					description: "",
					dataPrivacyInfo: "",
				},
			}),
			{} as Record<string, DataFieldTranslatableAttributes>,
		),
	};
	const optionDrawerProps = useEditDrawer();
	const dataListColumns: IDrawerDataListColumn<IDataFieldIndexResource>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Type"),
				valueKey: "type",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id, type }, openDrawer) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuItem
							title={t("Edit data field")}
							label={t("Edit data field")}
							action={openDrawer}
							icon="edit"
							shortcut="primary"
						/>
						{hasDataFieldOptions(type) && (
							<ContextMenuItem
								title={t("Edit data field options")}
								label={t("Edit data field options")}
								action={() => optionDrawerProps.handleOpen(id)}
								shortcut="default"
								icon="list"
							/>
						)}
						<ContextMenuDeleteItem
							action={() => crudOperations.deleteEntity(id)}
							title={t("Delete data field")}
							label={t("Delete data field")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to delete this data field?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		],
		[crudOperations, optionDrawerProps, t],
	);

	const createDataField = useCallback(
		async (dataField: IDataFieldUpdateResource) => {
			const dataFieldResponse = await dataFieldService.store(dataField);

			if (apiIsOK(dataFieldResponse)) {
				showToast({ body: t("Data field was saved."), title: t("Save data field"), type: "success" });
				await queryClient.resetQueries({ queryKey: dataFieldService.keys.index });
				await queryClient.resetQueries({ queryKey: guestAttributeKeys.index });
				await queryClient.resetQueries({ queryKey: guestViewService.keys.list });
				await queryClient.resetQueries({ queryKey: guestKeys.index });
				await queryClient.resetQueries({ queryKey: dataFieldService.keys.contingentOverview });
				if (hasDataFieldOptions(dataField.type)) {
					optionDrawerProps.handleOpen(dataFieldResponse.data.data.id);
				}
				return true;
			}
			return false;
		},
		[optionDrawerProps, queryClient, showToast, t],
	);

	return (
		<>
			<DrawerAutoDataList
				columns={dataListColumns}
				service={dataFieldService}
				initialSearch={{ search: "", searchFields: ["name"] }}
				renderCreateDrawer={(drawerProps) => (
					<DataFieldDrawer
						{...drawerProps}
						heading={t("Add data field")}
						initialValues={emptyDataField}
						onSubmit={(entityFormState) => createDataField(entityFormState)}
					/>
				)}
				renderUpdateDrawer={(entityResource, drawerProps) => (
					<DataFieldDrawer
						{...drawerProps}
						heading={t("Edit data field")}
						initialValues={entityResource}
						onSubmit={(entityFormState) =>
							crudOperations.updateEntity(entityResource.id, {
								...entityFormState,
								translations: entityFormState.translations,
							})
						}
					/>
				)}
				translations={{
					title: t("Data fields"),
					empty: t("No data fields available"),
				}}
			/>
			<DataFieldOptionsDrawer {...optionDrawerProps} />
		</>
	);
};
