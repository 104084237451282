import {
	mapBaseBlockFormToRequest,
	mapBaseBlockResourceToForm,
	validateBlockForm,
	validateTranslationValues,
} from "@dgs/core";
import { EmailButtonBlockForm } from "./EmailButtonBlockForm";
import { IEmailButtonBlockForm, IEmailButtonBlockView } from "./emailButtonBlockTypes";
import { EmailButtonBlockView } from "./EmailButtonBlockView";

export const emailButtonBlockView: IEmailButtonBlockView = {
	View: EmailButtonBlockView,
};

export const emailButtonBlockForm: IEmailButtonBlockForm = {
	blockType: "emailButtonBlock",
	type: "block",
	Form: EmailButtonBlockForm,
	Preview: EmailButtonBlockView,
	emptyValue: {
		type: "primary",
		color: "#FFF",
		backgroundColor: "#000000",
	},
	emptyTranslatedValue: {
		label: "",
		url: "",
	},
	mapEditResourceToFormState: (resource, locales, fallbackLocale, emptyValue) =>
		mapBaseBlockResourceToForm(resource, locales, fallbackLocale, emptyValue),
	mapFormStateToRequest: (resource, form) => mapBaseBlockFormToRequest(resource.type, form),
	validate: (formState, t, fallbackLocale) => {
		return {
			...validateBlockForm(formState, t),
			...validateTranslationValues(formState, fallbackLocale, (value, valueErrors) => {
				if (!value.label) {
					valueErrors.label = t("Required field");
				}
			}),
		};
	},
};

export const emailButtonBlock = {
	...emailButtonBlockView,
	...emailButtonBlockForm,
};
