import { DisableableOption, SingleSelectField, useIsAddonEnabled } from "@dgs/core";
import { useField } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { isSection } from "~admin/registrationForms/structure/registrationFormDndUtils";
import { useRegistrationFormStructure } from "~admin/registrationForms/structure/RegistrationFormStructureProvider";
import { useRegistrationFormStructureQuery } from "~shared/api/registrationForms/registrationFormQueries";
import { IRegistrationFormCompanionSectionFormValues, IRegistrationFormSection } from "~shared/types/section";

export type SectionTypeOption = { option: IRegistrationFormSection["type"] } & DisableableOption;
export const SectionTypeSelectField: FC = () => {
	const { t } = useTranslation();
	const isCompanionEnabled = useIsAddonEnabled("companion");
	const [, , { setValue: setSettings }] = useField<
		IRegistrationFormCompanionSectionFormValues["companionSectionSettings"] | undefined
	>("companionSectionSettings");
	const { registrationFormId } = useRegistrationFormStructure();
	const { registrationForm } = useRegistrationFormStructureQuery(registrationFormId);
	const registrationFormHasCompanionSection = useMemo(() => {
		if (!registrationForm) {
			return false;
		}
		return !!registrationForm.steps
			.flatMap((step) => step.children)
			.filter(isSection)
			.find((section) => section.type === "companion");
	}, [registrationForm]);
	const companionOption = useMemo(() => {
		const option: SectionTypeOption = { option: "companion" };
		if (!isCompanionEnabled) {
			return { ...option, disable: { disabled: true, info: t("The companion addon is deactivated") } };
		}
		if (registrationFormHasCompanionSection) {
			return { ...option, disable: { disabled: true, info: t("The companion section can only be used once") } };
		}
		return option;
	}, [registrationFormHasCompanionSection, isCompanionEnabled, t]);

	const changeConfigByType = useCallback(
		(e: IRegistrationFormSection["type"]) => {
			switch (e) {
				case "companion":
					return setSettings({
						maxCompanionCount: 1,
						companionConfirmationMail: null,
						companionRegistrationForm: null,
						groups: [],
						tags: [],
						companionStatus: null,
					});
				default:
					return setSettings(undefined);
			}
		},
		[setSettings],
	);

	return (
		<SingleSelectField<IRegistrationFormSection["type"], SectionTypeOption>
			options={[{ option: "default" }, companionOption]}
			label={t("Type")}
			name="type"
			getValue={(x) => x.option}
			getLabel={(x) => x.option}
			onChange={(e) => e.target.value && changeConfigByType(e.target.value)}
		/>
	);
};
