import { Button, Icon } from "@dgs/core";
import { FieldArray } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useI18nForm, useLocaleIndex } from "./I18nFormProvider";

export const I18nDiscardLocaleButton: FC = () => {
	const { t } = useTranslation();
	const { currentLocale, setCurrentLocale, fallbackLocale } = useI18nForm();
	const currentLocaleIdx = useLocaleIndex(currentLocale);

	if (currentLocale === fallbackLocale) {
		return null;
	}

	return (
		<FieldArray
			name="translations"
			render={({ remove }) => (
				<Button
					title={t("Discard language")}
					type="button"
					onClick={() => {
						remove(currentLocaleIdx);
						setCurrentLocale(fallbackLocale);
					}}
					prefix={<Icon icon="delete" />}
				>
					{t("Discard language")}
				</Button>
			)}
		/>
	);
};
