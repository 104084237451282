import { FormProps, I18nBlockForm, IBaseBlockEditResource } from "@dgs/core";
import React, { FC } from "react";
import { emailHeadlineBlockForm } from "./EmailHeadlineBlock";
import { EmailHeadlineBlockFormFields } from "./EmailHeadlineBlockFormFields";

export const EmailHeadlineBlockForm: FC<FormProps<IBaseBlockEditResource>> = ({ block, onSubmit }) => {
	return (
		<I18nBlockForm block={block} onSubmit={onSubmit} blockForm={emailHeadlineBlockForm}>
			<EmailHeadlineBlockFormFields blockType={block.type} />
		</I18nBlockForm>
	);
};
