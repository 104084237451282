import { mapBaseBlockFormToRequest, mapBaseBlockResourceToForm, validateBlockForm } from "@dgs/core";
import { EmailSummaryBlockForm } from "./EmailSummaryBlockForm";
import { IEmailSummaryBlockForm, IEmailSummaryBlockView } from "./emailSummaryBlockTypes";
import { EmailSummaryBlockView } from "./EmailSummaryBlockView";

export const emailSummaryBlockView: IEmailSummaryBlockView = {
	View: EmailSummaryBlockView,
};

export const emailSummaryBlockForm: IEmailSummaryBlockForm = {
	blockType: "emailSummaryBlock",
	type: "block",
	Form: EmailSummaryBlockForm,
	Preview: EmailSummaryBlockView,
	emptyValue: null,
	emptyTranslatedValue: null,
	mapEditResourceToFormState: (resource, locales, fallbackLocale, emptyValue) =>
		mapBaseBlockResourceToForm(resource, locales, fallbackLocale, emptyValue),
	mapFormStateToRequest: (resource, form) => mapBaseBlockFormToRequest(resource.type, form),
	validate: validateBlockForm,
};

export const emailSummaryBlock = {
	...emailSummaryBlockView,
	...emailSummaryBlockForm,
};
