import { Button, Card, CardBody, CardHeader, CardHeading, CrudDrawer, Id, useOpen } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useRegistrationFormStep } from "~admin/registrationForms/structure/steps/useRegistrationFormStep";
import { PaymentProviderMultiSelect } from "~admin/shared/selects/PaymentProviderSelectField";
import { ShopItemMultiSelect } from "~admin/shared/selects/ShopItemSelects";
import { IPaymentProviderIndexResource } from "~shared/types/paymentProvider";
import { RegistrationFormShopPage } from "~shared/types/registrationFormTypes";
import { RegistrationFormShopItem } from "~shared/types/shop";
import { RegistrationFormPaymentProviders } from "./RegistrationFormPaymentProviders";
import { RegistrationFormShopItems } from "./RegistrationFormShopItems";

interface Props {
	formId: Id;
	paymentProviders: IPaymentProviderIndexResource[];
	shopItems: RegistrationFormShopItem[];
}

const Content = styled.div`
	flex-direction: column;
	display: flex;
	gap: ${({ theme }) => theme.spacing(4)};
`;

export const RegistrationFormShopStep: FC<Props> = ({ formId, paymentProviders, shopItems }) => {
	const { t } = useTranslation();
	const { open, handleOpen, handleClose } = useOpen();
	const { updateShopStep } = useRegistrationFormStep(formId);
	const handleSubmit = useCallback(
		async (values: RegistrationFormShopPage) => {
			await updateShopStep(
				values.paymentProviders.map(({ id }) => id),
				values.shopItems.map(({ id }) => id),
			);
			return true;
		},
		[updateShopStep],
	);
	const initialFormValues: RegistrationFormShopPage = { paymentProviders: paymentProviders, shopItems: shopItems };

	return (
		<>
			<Card>
				<CardHeader>
					<CardHeading>{t("Shop")}</CardHeading>
					<Button title={t("Edit shop")} size="small" onClick={handleOpen}>
						{t("Edit shop")}
					</Button>
				</CardHeader>
				<CardBody>
					<Content>
						<RegistrationFormPaymentProviders paymentProviders={paymentProviders} />
						<RegistrationFormShopItems shopItems={shopItems} />
					</Content>
				</CardBody>
			</Card>

			<CrudDrawer
				open={open}
				onSubmit={handleSubmit}
				body={
					<>
						<PaymentProviderMultiSelect name="paymentProviders" label={t("Payment providers")} />
						<ShopItemMultiSelect name="shopItems" label={t("Shop items")} />
					</>
				}
				initialValues={initialFormValues}
				onClose={handleClose}
				heading={t("Edit shop")}
			/>
		</>
	);
};
