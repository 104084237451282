import { IconButton, Id, LaravelResponse, apiIsOK, useList, useToasts } from "@dgs/core";
import { AxiosResponse } from "axios";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";

interface Props {
	generateExport: (
		search: string | null,
		filters: IGuestFilterFormValueEntry[],
		guestViewId?: Id | null,
	) => Promise<AxiosResponse<LaravelResponse<void>>>;
	filters: IGuestFilterFormValueEntry[];
	currentGuestViewId?: Id;
}

export const OrderExportButton: FC<Props> = ({ generateExport, filters, currentGuestViewId }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const { searchParams } = useList();

	const createExport = useCallback(async () => {
		const response = await generateExport(
			searchParams?.search ?? null,
			filters,
			currentGuestViewId === 0 ? null : currentGuestViewId,
		);
		if (apiIsOK(response)) {
			showToast({
				body: t("An export is being created, please wait."),
				title: t("Export"),
				type: "success",
			});
		}
	}, [generateExport, searchParams?.search, currentGuestViewId, filters, showToast, t]);

	return (
		<IconButton type="button" size="small" onClick={() => createExport()} title={t("Download")} icon="file_download" />
	);
};
