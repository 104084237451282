import { PagedMultiSelectField } from "@dgs/core";
import React, { FC } from "react";
import { usersKeys, usersService } from "~admin/shared/api/users";

const fetcher = (search: string, page: number) =>
	usersService.options({ params: { search, page } }).then((x) => x.data);

interface Props {
	name: string;
	label: string;
	required?: boolean;
}
export const UserMultiSelectField: FC<Props> = (props) => {
	return <PagedMultiSelectField {...props} fetcher={fetcher} queryKey={usersKeys.options} />;
};
