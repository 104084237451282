import { Id } from "@dgs/core";
import { ActionTypes } from "~shared/types/actions";
import { IDataFieldOptionOptionResource } from "~shared/types/dataFieldOption";
import { DataFieldType } from "~shared/types/dataFieldType";
import { ISectionDataFieldOption } from "~shared/types/registrationFormChildDataField";
import { RuleSetType } from "~shared/types/ruleSet";

export enum RuleTypes {
	IS_REQUIRED = "isRequired",
	NOT_UPDATABLE = "notUpdatable",
	READ_ONLY = "readOnly",
	HAS_VALUE = "hasValue",
	HAS_NOT_VALUE = "hasNotValue",
	HAS_MAX_CHARS = "hasMaxChars",
	HAS_MIN_CHARS = "hasMinChars",
	MUST_CONTAIN = "mustContain",
	MUST_NOT_CONTAIN = "mustNotContain",
	MIN = "min",
	MAX = "max",
	CONTAINS_ONE = "containsOne",
	NOT_CONTAINS_ONE = "notContainsOne",
}

export interface BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption | null;
	type: RuleTypes | null;
	value: any;
}
export interface IS_REQUIRED_Rule extends BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption;
	type: RuleTypes.IS_REQUIRED;
	value: null;
}
export interface NOT_UPDATABLE_Rule extends BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption;
	type: RuleTypes.NOT_UPDATABLE;
	value: null;
}
export interface READ_ONLY_Rule extends BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption;
	type: RuleTypes.READ_ONLY;
	value: null;
}
export interface HAS_VALUE_Rule extends BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption;
	type: RuleTypes.HAS_VALUE;
	value: string | number | boolean | IDataFieldOptionOptionResource | IDataFieldOptionOptionResource[];
}
export interface HAS_NOT_VALUE_Rule extends BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption;
	type: RuleTypes.HAS_NOT_VALUE;
	value: string | number | boolean | IDataFieldOptionOptionResource | IDataFieldOptionOptionResource[];
}
export interface MUST_CONTAIN_Rule extends BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption;
	type: RuleTypes.MUST_CONTAIN;
	value: string | number | boolean | IDataFieldOptionOptionResource | IDataFieldOptionOptionResource[];
}
export interface MUST_NOT_CONTAIN_Rule extends BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption;
	type: RuleTypes.MUST_NOT_CONTAIN;
	value: string | number | boolean | IDataFieldOptionOptionResource | IDataFieldOptionOptionResource[];
}
export interface HAS_MAX_CHARS_Rule extends BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption;
	type: RuleTypes.HAS_MAX_CHARS;
	value: number;
}
export interface HAS_MIN_CHARS_Rule extends BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption;
	type: RuleTypes.HAS_MIN_CHARS;
	value: number;
}
export interface MAX_Rule extends BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption;
	type: RuleTypes.MAX;
	value: string | number;
}
export interface MIN_Rule extends BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption;
	type: RuleTypes.MIN;
	value: string | number;
}

export interface CONTAINS_ONE_RULE extends BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption;
	type: RuleTypes.CONTAINS_ONE;
	value: string[] | number[];
}

export interface NOT_CONTAINS_ONE_RULE extends BaseRuleFormState {
	id: Id;
	sectionDataField: ISectionDataFieldOption;
	type: RuleTypes.NOT_CONTAINS_ONE;
	value: string[] | number[];
}

export type Rule =
	| IS_REQUIRED_Rule
	| NOT_UPDATABLE_Rule
	| READ_ONLY_Rule
	| HAS_VALUE_Rule
	| HAS_NOT_VALUE_Rule
	| HAS_MAX_CHARS_Rule
	| HAS_MIN_CHARS_Rule
	| MAX_Rule
	| MIN_Rule
	| MUST_CONTAIN_Rule
	| MUST_NOT_CONTAIN_Rule
	| CONTAINS_ONE_RULE
	| NOT_CONTAINS_ONE_RULE;

export type RuleFormState = BaseRuleFormState;

export type IRuleFormState = BaseRuleFormState;

export type RuleActionTypes = Record<
	RuleSetType,
	{
		rules: Record<DataFieldType, RuleTypes[]>;
		actions: ActionTypes[];
	}
>;
