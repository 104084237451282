import { ConfirmationDialog, IconButton, Id, apiIsOK, useBulkEdit, useList, useOpen, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { dashboardKeys } from "~shared/api/dashboard";
import { emailKeys } from "~shared/api/emails";
import { guestKeys } from "~shared/api/guests";
import { companionService } from "~shared/api/guests/companionService";
import { IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";

export interface Props {
	filters: IGuestFilterFormValueEntry[];
	companionParentId: Id;
}

export const BulkDetachCompanionAction: FC<Props> = ({ filters, companionParentId }) => {
	const { t } = useTranslation();
	const { open, handleClose, handleOpen } = useOpen();
	const { selectedRows, setSelectedRow } = useBulkEdit();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { searchParams } = useList();

	const handleDetach = useCallback(async () => {
		const editAll = selectedRows === "all";
		const response = await companionService.companionBulkDetach(
			editAll ? [] : selectedRows,
			filters,
			searchParams?.search ?? null,
			companionParentId,
			editAll,
		);

		if (apiIsOK(response)) {
			showToast({
				body: t("{{count}} companion(s) were detached", { count: selectedRows.length }),
				title: t("Guests"),
				type: "success",
			});
			await queryClient.invalidateQueries({ queryKey: guestKeys.index });
			await queryClient.invalidateQueries({ queryKey: dashboardKeys.index });
			await queryClient.invalidateQueries({ queryKey: emailKeys.sendOuts.index });
			setSelectedRow("none");
		}
	}, [companionParentId, selectedRows, filters, searchParams?.search, showToast, t, queryClient, setSelectedRow]);

	return (
		<>
			<IconButton
				title={t("Detach companions")}
				size="small"
				onClick={handleOpen}
				disabled={selectedRows.length === 0}
				icon="minus"
			/>
			<ConfirmationDialog
				heading={t("Detach companions")}
				labels={{ close: t("Close"), confirm: t("Confirm") }}
				open={open}
				onClose={handleClose}
				action={() => handleDetach()}
			>
				{selectedRows === "all"
					? t("Are you sure you want to detach all companions?")
					: t("Are you sure you want to detach the selected {{count}} companion(s)?", { count: selectedRows.length })}
			</ConfirmationDialog>
		</>
	);
};
