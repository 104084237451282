import { CardHeader, CardHeading, Empty, Icon, Loading, TextControl } from "@dgs/core";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { useRegistrationFormsQuery } from "~shared/api/registrationForms";
import { CreateRegistrationFormDrawer } from "./CreateRegistrationFormDrawer";
import { RegistrationFormDetailsPage } from "./RegistrationFormDetailsPage";
import { RegistrationFormLink } from "./RegistrationFormLink";

const Wrapper = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	display: grid;
	grid-template-columns: max-content 1fr;
`;

const Sidebar = styled.div`
	z-index: ${({ theme }) => theme.zIndices.content.l1};
	background-color: white;
	box-shadow: ${({ theme }) => theme.shadows.l1};
	width: 280px;
	overflow: hidden;
	display: grid;
	grid-template-rows: max-content 1fr;
`;

const SidebarBody = styled.div`
	overflow-y: auto;
	height: 100%;
`;

const StyledCardHeader = styled(CardHeader)`
	padding: 1rem 1.25rem;
	gap: 1rem;
`;

const StyledTextControl = styled(TextControl)`
	margin: 0;
`;

const SearchIcon = styled(Icon)`
	cursor: pointer;
`;

export const RegistrationFormPage: FC = () => {
	const { t } = useTranslation();
	const { data, isLoading } = useRegistrationFormsQuery();
	const [searchValue, setSearchValue] = useState("");
	const clearSearch = () => setSearchValue("");

	return (
		<Wrapper>
			{isLoading ? (
				<Loading />
			) : (
				<>
					<Sidebar>
						<StyledCardHeader>
							<CardHeading>{t("Registration forms")}</CardHeading>
							<CreateRegistrationFormDrawer />
							<StyledTextControl
								name={t("Search")}
								placeholder={t("Search")}
								suffix={
									<SearchIcon icon={searchValue ? "close" : "search"} onClick={searchValue ? clearSearch : undefined} />
								}
								value={searchValue}
								onChange={(e) => setSearchValue(e.target.value)}
							/>
						</StyledCardHeader>
						<SidebarBody>
							{data
								.filter((x) => !searchValue || x.name.includes(searchValue))
								.map((r) => (
									<RegistrationFormLink key={r.id} registrationForm={r} />
								))}
						</SidebarBody>
					</Sidebar>
					<Routes>
						<Route path="*" element={<Outlet />}>
							<Route index element={<Navigate to={data.length === 1 ? data[0].id : "empty"} />} />
							<Route path="empty" element={<Empty>{t("Please choose a registration form")}</Empty>} />
							<Route path=":registrationFormId/*" element={<RegistrationFormDetailsPage />} />
						</Route>
					</Routes>
				</>
			)}
		</Wrapper>
	);
};
