import { Icon } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { GuestScreeningStatus } from "~shared/types/guest";

const CellWithIconWrapper = styled.span`
	display: grid;
	grid-template-columns: max-content max-content;
	gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
`;

const CellIcon = styled(Icon)<{ $small: boolean }>`
	width: ${({ $small }) => ($small ? "16px" : "24px")};
	height: ${({ $small }) => ($small ? "16px" : "24px")};
	color: ${({ theme }) => theme.colors.palette.warning.main.base};
`;

interface Props {
	small?: boolean;
	screeningStatus: GuestScreeningStatus;
}

export const GuestScreeningStatusValue: FC<Props> = ({ screeningStatus, small = false }) => {
	const { t } = useTranslation();

	return (
		<CellWithIconWrapper>
			{screeningStatus === GuestScreeningStatus.POSITIVE_AUTOMATIC && <CellIcon $small={small} icon="warning" />}
			{t(screeningStatus)}
		</CellWithIconWrapper>
	);
};
