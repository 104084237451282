import { Id, MultiSelectField } from "@dgs/core";
import { useField } from "formik";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDataFieldsQuery } from "~shared/api/dataFields";
import { useGuestAttributesQuery } from "~shared/api/guestAttributes/guestAttributeQueries";
import { useGuestViewQuery } from "~shared/api/guestViews";
import { IGuestViewOptionResource } from "~shared/types/guestView";

interface Option {
	id: Id;
	name: string;
}

const useOptionsForGuestView = (guestViewId: Id): Record<Id, Option> => {
	const { data: guestView } = useGuestViewQuery(guestViewId);
	const { guestAttributes } = useGuestAttributesQuery();
	const { data: dataFields } = useDataFieldsQuery();
	const { t } = useTranslation();

	return useMemo(() => {
		if (!guestView) {
			return {};
		}

		return guestView.guestAttributes.reduce(
			(idToOption, attribute) => {
				const foundAttribute = guestAttributes.find(({ id }) => attribute.id === id);
				if (!foundAttribute) {
					return idToOption;
				}
				switch (foundAttribute.type) {
					case "column":
					case "relation":
						return {
							...idToOption,
							[foundAttribute.id]: { id: foundAttribute.id, name: t(foundAttribute.entityType) },
						};
					case "dataField": {
						const dataField = dataFields.find(({ id }) => id === foundAttribute.entityType);
						if (!dataField) {
							return idToOption;
						}
						return { ...idToOption, [foundAttribute.id]: { id: foundAttribute.id, name: dataField.name } };
					}
				}
			},
			{} as Record<Id, Option>,
		);
	}, [dataFields, guestAttributes, guestView, t]);
};

interface Props {
	name: string;
	label: string;
}

export const GuestViewAttributeMultiSelect: FC<Props> = ({ label, name }) => {
	const [{ value: guestView }] = useField<IGuestViewOptionResource>("guestView");
	const options = useOptionsForGuestView(guestView.id);

	return (
		<MultiSelectField
			name={name}
			label={label}
			options={Object.keys(options)}
			getValue={(key) => options[key]?.id}
			getLabel={(key) => options[key]?.name}
		/>
	);
};
