import { Button, Icon, useOpen } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { FloatingActionButton } from "~admin/shared/ui/FloatingActionButton";
import { useRegistrationFormStructure } from "../RegistrationFormStructureProvider";
import { CreateRegistrationFormStepDrawer } from "./CreateRegistrationFormStepDrawer";

export const CreateStepAction: FC = () => {
	const { registrationFormId } = useRegistrationFormStructure();
	const { t } = useTranslation();
	const { open, handleClose, handleOpen } = useOpen();

	return (
		<>
			<FloatingActionButton>
				<Button
					prefix={<Icon icon="plus" />}
					type="button"
					title={t("Create step")}
					size="default"
					color="primary"
					onClick={handleOpen}
				>
					{t("Create step")}
				</Button>
			</FloatingActionButton>
			<CreateRegistrationFormStepDrawer registrationFormId={registrationFormId} open={open} onClose={handleClose} />
		</>
	);
};
