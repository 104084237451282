import { ImportExportPage, ImportExportProvider, useDynamicConfig } from "@dgs/core";
import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ImportPage } from "~shared/import/ImportPage";
import { ExportFileDataField } from "./ExportFileDataField";

export const ExportImportPage = () => {
	const { apiUrl } = useDynamicConfig();

	return (
		<Routes>
			<Route
				path="exports"
				element={
					<ImportExportProvider>
						<ImportExportPage apiUrl={apiUrl} listHeader={<ExportFileDataField />} />
					</ImportExportProvider>
				}
			/>
			<Route path="import/*" element={<ImportPage />} />
			<Route path="*" element={<Navigate to="exports" />} />
		</Routes>
	);
};
