import { ActionTypes } from "~shared/types/actions";
import { ActionFilter, AnyRulesetFilterItem, ColorFilter, RuleFilter, RuleSet } from "~shared/types/ruleSet";

export const applyRuleSetFilter = (ruleSets: RuleSet[], filterItems: AnyRulesetFilterItem[]) => {
	return ruleSets.filter((x) => {
		if (!filterItems.length) {
			return true;
		}
		const areColorRulesValid = filterItems
			.filter((x) => x.type === "color")
			.every((f) => (f as ColorFilter).value === x.color);
		const areRuleRulesValid = filterItems
			.filter((x) => x.type === "rule")
			.every((f) => {
				const dataField = (f as RuleFilter).filterItem.value;
				return x.rules.some((rule) => rule.sectionDataField.dataField.id === dataField.id);
			});
		const areActionRulesValid = filterItems
			.filter((x) => x.type === "action")
			.every((f) => {
				const item = (f as ActionFilter).filterItem;
				if (item.type === "showDataField") {
					return x.actions.some(
						(action) => action.type === ActionTypes.SHOW_DATA_FIELD && action.value.dataField.id === item.value.id,
					);
				}
				if (item.type === "showSection") {
					return x.actions.some(
						(action) => action.type === ActionTypes.SHOW_SECTION && action.value.id === item.value.id,
					);
				}
				if (item.type === "showStep") {
					return x.actions.some((action) => action.type === ActionTypes.SHOW_STEP && action.value.id === item.value.id);
				}
			});
		return areColorRulesValid && areRuleRulesValid && areActionRulesValid;
	});
};
