import { Id } from "@dgs/core";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BaseGuestImportForm } from "./BaseGuestImportForm";

export const JarvisGuestImportForm = () => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: { distributionListId: Id }) => {
			const errors: Record<string, string> = {};

			if (!values.distributionListId) {
				errors.distributionListId = t("Required field");
			}

			return errors;
		},
		[t],
	);

	return (
		<BaseGuestImportForm
			additionalValues={{ distributionListId: -1, importOnlyDifferences: true }}
			validateAdditionalInformation={validate}
			filterEnabled={true}
			importProviderType="jarvis"
		/>
	);
};
