import { IconButton, Id, Separator } from "@dgs/core";
import { useField } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IGuestFilterOperator } from "~shared/guestFilters/guestFilterTypes";
import { GuestFilterValueField } from "~shared/guestFilters/GuestFilterValueField";
import { GuestFilterOperator } from "./GuestFilterOperator";
import { useResolveInfoForGuestAttribute } from "./guestFilterUtils";

const Divider = styled.div`
	position: relative;
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: max-content;
	align-items: center;
`;

const Line = styled(Separator)`
	height: 1px;
	grid-area: 1 / 1;
`;

const TextWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	grid-area: 1 / 1;
`;

const Text = styled.div`
	padding: 0 ${({ theme }) => theme.spacing(2)};
	background-color: white;
	color: ${({ theme }) => theme.colors.palette.grey[70]};
`;

const NameRow = styled.div`
	font-weight: bold;
	align-items: center;
	display: grid;
	grid-template-columns: 1fr max-content;
	gap: ${({ theme }) => theme.spacing(4)};
	margin: ${({ theme }) => theme.spacing(2)} 0;
`;

const OperatorWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr;
	gap: ${({ theme }) => theme.spacing(4)};
`;

interface Props {
	namePrefix: string;
	attributeId: Id;
	withDivider: boolean;
	onDelete: () => void;
}

export const GuestFilterEntry: FC<Props> = ({ attributeId, withDivider, onDelete, namePrefix }) => {
	const { t } = useTranslation();
	const deleteTitle = t("Remove filter entry");
	const guestAttributeInfo = useResolveInfoForGuestAttribute(attributeId);
	const [{ value }] = useField<IGuestFilterOperator | null>(`${namePrefix}.operator`);

	if (!guestAttributeInfo) return null;

	return (
		<>
			<NameRow>
				<span>
					{guestAttributeInfo.attribute.type === "dataField"
						? guestAttributeInfo.dataField?.name
						: t(guestAttributeInfo.attribute.entityType)}
				</span>
				<IconButton type="button" title={deleteTitle} size="small" onClick={onDelete} icon="delete" />
			</NameRow>
			<OperatorWrapper>
				<GuestFilterOperator type={guestAttributeInfo.type} name={namePrefix} />
				{value && (
					<GuestFilterValueField
						name={`${namePrefix}.value`}
						operator={value}
						type={guestAttributeInfo.type}
						dataField={guestAttributeInfo.dataField}
					/>
				)}
			</OperatorWrapper>
			{withDivider && (
				<Divider>
					<Line />
					<TextWrapper>
						<Text>{t("AND")}</Text>
					</TextWrapper>
				</Divider>
			)}
		</>
	);
};
