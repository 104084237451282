import { DrawerAutoDataList, IDrawerDataListColumn, useCrudEntity } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { personalizedDocumentService } from "~shared/api/personalizedDocuments";
import { PersonalizedDocument, PersonalizedDocumentIndexResource } from "~shared/types/personalizedDocument";
import { PersonalizedDocumentDrawer } from "./PersonalizedDocumentDrawer";
import { PersonalizedDocumentListContextMenu } from "./PersonalizedDocumentListContextMenu";
import { usePersonalizedDocumentForm } from "./usePersonalizedDocumentForm";

export const PersonalizedDocumentList: FC = () => {
	const { t } = useTranslation();
	const { emptyPersonalizedDocument, parseTranslations } = usePersonalizedDocumentForm();
	const crudOperations = useCrudEntity(personalizedDocumentService, {
		entityName: t("Personalized document"),
		messages: {
			create: t("Personalized document was saved."),
			update: t("Personalized document was saved."),
			delete: t("Personalized document was deleted."),
		},
	});

	const dataListColumns: IDrawerDataListColumn<PersonalizedDocumentIndexResource>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Type"),
				valueKey: "type",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }, openDrawer) => (
					<PersonalizedDocumentListContextMenu
						handleEdit={openDrawer}
						handleDelete={() => crudOperations.deleteEntity(id)}
					/>
				),
			},
		],
		[t, crudOperations],
	);

	return (
		<DrawerAutoDataList
			initialSearch={{ search: "", searchFields: ["name", "type"] }}
			translations={{
				title: t("Personalized documents"),
				empty: t("No personalized documents available"),
			}}
			renderCreateDrawer={(drawerProps) => (
				<PersonalizedDocumentDrawer
					{...drawerProps}
					heading={t("Create personalized document")}
					initialValues={emptyPersonalizedDocument}
					onSubmit={async (entityFormState) => crudOperations.createEntity(parseTranslations(entityFormState))}
				/>
			)}
			renderUpdateDrawer={(entityResource: PersonalizedDocument, drawerProps) => (
				<PersonalizedDocumentDrawer
					{...drawerProps}
					heading={t("Edit personalized document")}
					initialValues={entityResource}
					onSubmit={async (entityFormState) =>
						crudOperations.updateEntity(entityResource.id, parseTranslations(entityFormState))
					}
					handleDelete={() => crudOperations.deleteEntity(entityResource.id).then(drawerProps.onClose)}
				/>
			)}
			columns={dataListColumns}
			service={personalizedDocumentService}
		/>
	);
};
