import {
	BlockSingleSelectField,
	Button,
	Card,
	CardHeader,
	CardHeading,
	CrudDrawer,
	DrawerBody,
	Empty,
	IBaseBlockIndexResource,
	IconButton,
	useOpen,
} from "@dgs/core";
import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ConsentMultiSelect } from "~shared/selects/ConsentMultiSelect";
import { ConsentListItem } from "~shared/types/consent";
import { RegistrationFormConsents } from "./RegistrationFormConsents";

interface Props {
	registrationFormId: string;
	action?: ReactNode;
	type: "landing" | "summary";
	blockName: string | null;
	consents: ConsentListItem[];
}

export interface RegistrationFormContentStepProps {
	registrationFormId: string;
	block: IBaseBlockIndexResource | null;
	consents: ConsentListItem[];
}

export const RegistrationFormContentStep: FC<Props> = ({ registrationFormId, action, blockName, type, consents }) => {
	const { t } = useTranslation();

	return (
		<Card>
			<CardHeader>
				<CardHeading>{t(`${type} step`)}</CardHeading>
				{blockName === null && <Empty>{t(`Please choose a Block for the ${type} step.`)}</Empty>}
				{blockName && <span>{t("Block: {{name}}", { name: blockName })}</span>}
				{action}
			</CardHeader>
			{!!consents.length && (
				<DrawerBody>
					<RegistrationFormConsents
						registrationFormId={registrationFormId}
						initialConsents={consents}
						forLandingPage={type === "landing"}
					/>
				</DrawerBody>
			)}
		</Card>
	);
};

export interface RegistrationFormContentFormValues {
	block: IBaseBlockIndexResource | null;
}

export interface RegistrationFormConsentFormValues {
	consents: ConsentListItem[];
}

interface RegistrationFormContentDrawerProps {
	block: IBaseBlockIndexResource | null;
	onSubmit: (values: RegistrationFormContentFormValues) => Promise<void>;
}

interface RegistrationFormConsentDrawerProps {
	consents: ConsentListItem[];
	onSubmit: (values: RegistrationFormConsentFormValues) => Promise<void>;
}

export const RegistrationFormContentDrawer: FC<RegistrationFormContentDrawerProps> = ({ block, onSubmit }) => {
	const { t } = useTranslation();
	const { open, handleOpen, handleClose } = useOpen();

	return (
		<>
			<IconButton title={t("Edit step")} size="small" color="primary" onClick={handleOpen} icon="edit" />
			<CrudDrawer
				open={open}
				onOpened={handleOpen}
				initialValues={{ block }}
				onClose={handleClose}
				heading={t("Choose Block")}
				onSubmit={(values) => {
					return onSubmit(values).then(handleClose) as Promise<any>;
				}}
				body={<BlockSingleSelectField blockType="pageBlock" name="block" label={t("Block")} clearable />}
			/>
		</>
	);
};

export const RegistrationFormConsentDrawer: FC<RegistrationFormConsentDrawerProps> = ({ consents, onSubmit }) => {
	const { t } = useTranslation();
	const { open, handleOpen, handleClose } = useOpen();

	return (
		<>
			<Button title={t("Edit consents")} size="small" onClick={handleOpen}>
				{t("Edit consents")}
			</Button>
			<CrudDrawer
				open={open}
				onOpened={handleOpen}
				initialValues={{ consents }}
				onClose={handleClose}
				heading={t("Choose Block")}
				onSubmit={(values) => {
					return onSubmit(values).then(handleClose) as Promise<any>;
				}}
				body={<ConsentMultiSelect name="consents" label={t("Consents")} />}
			/>
		</>
	);
};
