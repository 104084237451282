import React, { FC } from "react";
import styled from "styled-components";

export const Logo = styled.img`
	height: 36px;
`;

export const SidebarHeader: FC = () => {
	return (
		<a href="https://guest-solutions.com" rel="noreferrer" target="_blank">
			<Logo src="/dgs_logo.png" alt="DGS Logo" />
		</a>
	);
};
