import { FCC, FormattedDate, FormattedDateTime } from "@dgs/core";
import React, { FC } from "react";
import { IChange } from "~shared/types/activity";

export const Change: FC<{ change: IChange; attribute: "from" | "to" }> = ({ change, attribute }) => {
	switch (change.type) {
		case "dataField":
			switch (change.dataFieldType) {
				case "crypt":
				case "email":
				case "longText":
				case "number":
				case "text":
				case "phone":
				case "url":
				case "checkbox":
				case "time":
				case "file":
					return <TextChange>{change[attribute]?.value}</TextChange>;
				case "selectField":
				case "radioField":
					return <TextChange>{change[attribute]?.value.name}</TextChange>;
				case "multiSelectField":
				case "checkboxGroup":
					return <TextChange>{change[attribute]?.value.map((v) => v.name).join(", ")}</TextChange>;
				case "date":
					const date = change[attribute]?.value;
					return (
						<TextChange>
							<FormattedDate date={date} />
						</TextChange>
					);
				case "dateTime":
					const dateTime = change[attribute]?.value;
					return (
						<TextChange>
							<FormattedDateTime dateTime={dateTime} />
						</TextChange>
					);
			}
			return null;
		case "referenceId":
		case "companionParentId":
		case "replacementId":
		case "registrationFormId":
		case "registrationCode":
		case "status":
		case "id":
		case "screeningStatus":
		case "registrationLocale":
			return <TextChange>{change[attribute]?.value}</TextChange>;
		case "registrationTimestamp":
			const dateTime = change[attribute]?.value;
			return (
				<TextChange>
					<FormattedDateTime dateTime={dateTime} />
				</TextChange>
			);
		case "group":
		case "tag":
		case "roomcontingent":
		case "guest":
		case "sendout":
			return <TextChange>{change[attribute]?.value.map((v) => v.name).join(", ")}</TextChange>;
		case "tenantId":
		case "waveId":
			return <TextChange>{change[attribute]?.value.name}</TextChange>;
	}

	return null;
};

const TextChange: FCC = ({ children }) => {
	return <span>{children || "-"}</span>;
};
