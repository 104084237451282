import { IconButton, apiIsOK, useBulkEdit, useOpen, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ordersKeys, ordersService } from "~admin/shared/api/orders";

export const OrderListBulkAction = () => {
	const { t } = useTranslation();
	const { handleClose } = useOpen();
	const { selectedRows, setSelectedRow, setBulkEditing } = useBulkEdit();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();

	const handleSendRemainingPaymentMail = useCallback(async () => {
		const editAll = selectedRows === "all";

		const response = await ordersService.bulkSendRemainingPaymentMail(editAll ? [] : selectedRows, editAll);

		if (apiIsOK(response)) {
			showToast({
				body: t("Remaining payment started"),
				title: t("Mails"),
				type: "success",
			});
			await queryClient.invalidateQueries({ queryKey: ordersKeys.index });
			setSelectedRow("none");
			setBulkEditing(false);

			handleClose();
			return true;
		}
		return false;
	}, [selectedRows, showToast, t, queryClient, setSelectedRow, setBulkEditing, handleClose]);

	return (
		<>
			<IconButton
				size="small"
				onClick={handleSendRemainingPaymentMail}
				disabled={selectedRows.length === 0}
				icon="send"
				title={t("Send email")}
			/>
		</>
	);
};
