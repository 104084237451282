import { CrudDrawer, Id, useFormatDate } from "@dgs/core";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useAcceptHotelInquiry } from "~admin/hotels/hotelInquiries/useAcceptHotelInquiry";
import { useHotelInquiryQuery } from "~shared/api/hotelInquiries/hotelInquiryQueries";
import { ContingentFormFields } from "~shared/guests/hotels/ContingentFormFields";
import { IRoomAllocation, RoomContingentFormState } from "~shared/types/roomContingent";
import { ValueViewer } from "~shared/ui/ValueViewer";
import { getHotelInquiryGuestName } from "./hotelInquiryUtils";

interface Props {
	hotelInquiryId: Id;
	onClose: () => void;
}

const Range = styled.div`
	display: flex;
	grid-template-columns: max-content max-content;
	gap: ${({ theme }) => theme.spacing(8)};
`;

const Values = styled.div`
	display: grid;
	gap: ${({ theme }) => theme.spacing(4)};
	padding-bottom: ${({ theme }) => theme.spacing(4)};
	border-bottom: 1px solid ${({ theme }) => theme.colors.palette.grey[30]};
	margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

export const HotelInquiryAcceptDrawer: FC<Props> = ({ hotelInquiryId, onClose }) => {
	const { data: hotelInquiry } = useHotelInquiryQuery(hotelInquiryId);
	const { acceptInquiry } = useAcceptHotelInquiry();
	const { t } = useTranslation();
	const formatDate = useFormatDate();

	const onSubmit = useCallback(
		async (roomAllocation: IRoomAllocation[]) => {
			void acceptInquiry(hotelInquiryId, roomAllocation, onClose);
			return true;
		},
		[acceptInquiry, hotelInquiryId, onClose],
	);

	const initialValues: RoomContingentFormState = useMemo(
		() => ({
			roomAllocation: hotelInquiry?.roomAllocation || [],
			roomMatesForAllDays: null,
			roomTypeForAllDays: null,
			openSpecificRoomSelect: false,
		}),
		[hotelInquiry?.roomAllocation],
	);

	const validate = useCallback(
		({ roomAllocation }: RoomContingentFormState) => {
			const errors: { [K in keyof RoomContingentFormState]?: any } = {};
			const allocationErrors: { [K in keyof IRoomAllocation]?: any }[] = [];

			roomAllocation.forEach((allocation, index) => {
				if (
					allocation &&
					allocation.roomMates &&
					allocation.roomMates.length > allocation.roomContingent.roomType.capacity
				) {
					allocationErrors[index].roomContingent = t(
						"This room only has {{capacity}} beds. Choose another room or reduce the amount of room mates.",
						{ capacity: allocation.roomContingent.roomType.capacity },
					);
				}
			});

			if (allocationErrors.length) {
				errors.roomAllocation = allocationErrors;
			}

			return errors;
		},
		[t],
	);

	return (
		hotelInquiry && (
			<CrudDrawer
				open={true}
				onSubmit={(values) => {
					return onSubmit(values.roomAllocation);
				}}
				initialValues={initialValues}
				validate={validate}
				onClose={onClose}
				heading={t("Hotel request")}
				body={(formikProps) => (
					<>
						<Values>
							<ValueViewer label={t("Guest")}>{getHotelInquiryGuestName(hotelInquiry)}</ValueViewer>
							<Range>
								<ValueViewer label={t("Check-in")}>{formatDate(hotelInquiry.checkIn)}</ValueViewer>
								<ValueViewer label={t("Check-out")}>{formatDate(hotelInquiry.checkOut)}</ValueViewer>
							</Range>
						</Values>
						<ContingentFormFields
							startDate={hotelInquiry.checkIn}
							endDate={hotelInquiry.checkOut}
							guestId={hotelInquiry.guest.id}
							formikProps={formikProps}
						/>
					</>
				)}
			/>
		)
	);
};
