import { HasPermission, Loading, useIsAddonEnabled } from "@dgs/core";
import React, { FC } from "react";
import styled from "styled-components";
import { PermissionName } from "~admin/shared/types/permission";
import { useDashboardQuery } from "~shared/api/dashboard";
import { DashboardEmailList } from "./DashboardEmailList";
import { DashboardGuestInfo } from "./DashboardGuestInfo";
import { DashboardLocations } from "./DashboardLocations";

const DashboardContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing(6)};
`;

export const Dashboard: FC = () => {
	const { data: dashboard, isLoading } = useDashboardQuery();
	const isCheckInAppEnabled = useIsAddonEnabled("checkInApp");

	if (isLoading || !dashboard) {
		return <Loading />;
	}

	return (
		<DashboardContainer>
			<HasPermission<PermissionName> permissions={["guest.read"]}>
				<DashboardGuestInfo guestStatus={dashboard.guestStatus} />
			</HasPermission>
			<HasPermission<PermissionName> permissions={["emailLog.read"]}>
				<DashboardEmailList emails={dashboard.emails} />
			</HasPermission>
			{isCheckInAppEnabled && (
				<HasPermission<PermissionName> permissions={["location.read"]}>
					<DashboardLocations locations={dashboard.locations} />
				</HasPermission>
			)}
		</DashboardContainer>
	);
};
