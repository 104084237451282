import { FCC, Id, Loading, useOpen } from "@dgs/core";
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useDataFieldsQuery } from "~shared/api/dataFields";
import { useGuestAttributesQuery } from "~shared/api/guestAttributes/guestAttributeQueries";
import { IDataFieldIndexResource } from "~shared/types/dataField";
import { GuestViewAttributeResource } from "~shared/types/guestView";
import { GuestViewAttributeOption, IActiveGuestFilterEntry, IGuestFilterFormValueEntry } from "./guestFilterTypes";

export function encodeFilter(entries: IActiveGuestFilterEntry[]): string {
	return btoa(JSON.stringify(entries));
}

function parseFilter(filter: string | null): IActiveGuestFilterEntry[] {
	if (!filter) {
		return [];
	}

	return JSON.parse(atob(filter));
}

interface GuestFilterProviderState {
	open: boolean;
	handleOpen: () => void;
	handleClose: () => void;
	filters: IActiveGuestFilterEntry[];
	setFilters: (filter: IActiveGuestFilterEntry[]) => void;
	options: GuestViewAttributeOption[];
	guestAttributes: GuestViewAttributeResource[];
	dataFields: IDataFieldIndexResource[];
	removeFilter: (filterId: Id) => void;
	mapGuestFilterToGuestFilterRequest: (filters: IGuestFilterFormValueEntry[]) => IGuestFilterFormValueEntry[];
}

const GuestFilterContext = createContext<GuestFilterProviderState | undefined>(undefined);

interface Props {
	listFilterKey: string;
}

export const GuestFilterProvider: FCC<Props> = ({ children, listFilterKey }) => {
	const localStorageValue = localStorage.getItem(listFilterKey);
	const [searchParams, setSearchParams] = useSearchParams();
	const [filters, _setFilters] = useState<IActiveGuestFilterEntry[]>(() =>
		parseFilter(searchParams.get(listFilterKey) || localStorageValue),
	);
	const { data: dataFields } = useDataFieldsQuery();
	const { guestAttributes } = useGuestAttributesQuery();
	const { open, handleOpen, handleClose } = useOpen();
	const { t } = useTranslation();
	const options = useMemo<GuestViewAttributeOption[]>(() => {
		if (dataFields.length === 0) {
			return [];
		}

		return guestAttributes.flatMap((attribute) => {
			if (attribute.type === "dataField") {
				const dataField = dataFields.find((dataField) => dataField.id === attribute.entityType);

				if (!dataField) {
					return [];
				}

				return [{ id: attribute.id, name: dataField.name, attribute: attribute as GuestViewAttributeResource }];
			}

			return [{ id: attribute.id, name: t(attribute.entityType), attribute: attribute }];
		});
	}, [dataFields, t, guestAttributes]);

	const setFilters = useCallback(
		(entries: IActiveGuestFilterEntry[]) => {
			_setFilters(entries);
			setSearchParams((search) => {
				search.set(listFilterKey, encodeFilter(entries));

				// if filter is used on a list find the corresponding page query param and reset it to page 1 on filter change
				for (const entry of search.entries()) {
					const name = entry[0] || "";
					if ((name.includes("page") || name.includes("Page")) && !name.includes("PerPage")) {
						search.set(name, "1");
					}
				}
				return search;
			});
			localStorage.setItem(listFilterKey, encodeFilter(entries));
			handleClose();
		},
		[handleClose, listFilterKey, setSearchParams],
	);

	const removeFilter = useCallback(
		(filterId: Id) => {
			setFilters(filters.filter((f) => f.guestAttributeId !== filterId));
		},
		[filters, setFilters],
	);

	const mapGuestFilterToGuestFilterRequest = (filter: IGuestFilterFormValueEntry[]): IGuestFilterFormValueEntry[] => {
		return filter.map((item) => {
			const attribute = guestAttributes.find((a) => a.id === item.guestAttributeId);

			if (!attribute) {
				throw new Error("Unknown attribute");
			}

			const pickIdIfPossible = (value: unknown) => {
				if (value !== null && typeof value === "object" && "id" in value) {
					return value.id;
				} else {
					return value;
				}
			};

			const mapValue = (value: unknown) => {
				if (Array.isArray(value)) {
					return value.map(pickIdIfPossible);
				} else {
					return pickIdIfPossible(value);
				}
			};

			return {
				guestAttributeId: item.guestAttributeId,
				operator: item.operator,
				value: mapValue(item.value),
			};
		});
	};

	useEffect(() => {
		if (!localStorageValue && filters.length !== 0) {
			localStorage.setItem(listFilterKey, encodeFilter(filters));
		}
	}, [filters, listFilterKey, localStorageValue]);

	if (guestAttributes.length === 0 || dataFields.length === 0) {
		return <Loading />;
	}

	return (
		<GuestFilterContext.Provider
			value={{
				options,
				filters,
				setFilters,
				handleClose,
				handleOpen,
				open,
				dataFields,
				removeFilter,
				guestAttributes,
				mapGuestFilterToGuestFilterRequest,
			}}
		>
			{children}
		</GuestFilterContext.Provider>
	);
};

export const useGuestFilter = () => {
	const ctx = useContext(GuestFilterContext);

	if (ctx === undefined) {
		throw new Error("useGuestFilter needs to be wrapped inside GuestFilterProvider");
	}

	return ctx;
};
