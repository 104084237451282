import React, { FC } from "react";
import { Trans } from "react-i18next";
import styled from "styled-components";
import { getActionTranslation } from "~admin/registrationForms/actionTranslations/actionTranslations";
import { useGetRuleTranslation } from "~admin/registrationForms/ruleTranslations/ruleTranslation";
import { Action } from "~shared/types/actions";
import { Rule } from "~shared/types/rules";
import { RuleSet } from "~shared/types/ruleSet";
import { ActionIndicator, RuleAndOrIndicator } from "./rules/RuleAndOrIndicator";

interface Props {
	ruleSet: RuleSet;
}

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr;
	gap: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(4)};
`;

const Bold = styled.span`
	font-weight: bold;
`;

const Separator = styled.hr`
	grid-column-start: 1;
	grid-column-end: -1;
	background-color: rgba(0, 0, 0, 0.1);
	height: 1px;
	margin: ${({ theme }) => theme.spacing(2)} 0;
`;

export const RuleSetDetails: FC<Props> = ({ ruleSet }) => {
	return (
		<Wrapper>
			<RulesDetails rules={ruleSet.rules} isAndLinked={ruleSet.isAndLinked} />
			<Separator />
			<ActionsDetails actions={ruleSet.actions} />
		</Wrapper>
	);
};

export const RulesDetails: FC<{ rules: Rule[]; isAndLinked: boolean }> = ({ rules, isAndLinked }) => {
	const getRuleTranslation = useGetRuleTranslation();

	return (
		<>
			{rules.map((rule, index) => {
				const { key, values } = getRuleTranslation(rule);
				return (
					<React.Fragment key={`rule-${rule.id}`}>
						<RuleAndOrIndicator index={index} isAndLinked={isAndLinked} />
						<p data-testid={`rule-${rule.id}`}>
							<Trans i18nKey={key as any} components={{ bold: <Bold /> }} values={values} />
						</p>
					</React.Fragment>
				);
			})}
		</>
	);
};

export const ActionsDetails: FC<{ actions: Action[] }> = ({ actions }) => {
	return (
		<>
			{actions.map((action, index) => {
				const { key, values } = getActionTranslation(action);
				return (
					<React.Fragment key={`action-${action.id}`}>
						<ActionIndicator index={index} />
						<p data-testid={`action-${action.id}`}>
							<Trans i18nKey={key as any} components={{ bold: <Bold /> }} values={values} />
						</p>
					</React.Fragment>
				);
			})}
		</>
	);
};
