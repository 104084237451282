import { ImportExportPage, ImportExportProvider, NotAuthorizedPage, Protected, useDynamicConfig } from "@dgs/core";
import React, { FC } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { EmailLogDetails } from "~shared/emails/logs";
import { BlockPage } from "./BlockPage";
import { GuestsPage } from "./guests/GuestPage";
import { HtmlTitle } from "./HtmlTitle";
import { NavigateToStartPage } from "./NavigateToStartPage";
import { NominationManagerAuthPages } from "./NominationManagerAuthPages";
import { NominationManagerContent } from "./NominationManagerContent";
import { NominationManagerImportPage } from "./NominationManagerImportPage";
import { NominationManagerProviders } from "./NominationManagerProviders";
import { useNominationManagerConfig } from "./useNominationManagerConfig";

export const NominationManager: FC = () => {
	return (
		<Routes>
			<Route
				path=""
				element={
					<NominationManagerProviders>
						<HtmlTitle />
						<Outlet />
					</NominationManagerProviders>
				}
			>
				<Route path="auth/*" element={<NominationManagerAuthPages />} />
				<Route path="not-authorized" element={<NotAuthorizedPage title="PARTICIPANT-MANAGEMENT" redirect={-1} />} />
				<Route
					path="*"
					element={
						<Protected fallback={<Navigate to="auth/login" />}>
							<NominationManagerContent />
						</Protected>
					}
				>
					<Route index element={<NavigateToStartPage />} />
					<Route path="guests/*" element={<NominationModuleGuestPage />} />
					<Route path="emails/logs/:logId" element={<NominationModuleEmailLogPage />} />
					<Route path="export-import/*" element={<ExportImportPage />} />
					<Route path="blocks/:slug" element={<BlockPage />} />
				</Route>
			</Route>
		</Routes>
	);
};

const ExportImportPage = () => {
	const { apiUrl } = useDynamicConfig();

	return (
		<Routes>
			<Route
				path="exports"
				element={
					<ImportExportProvider>
						<ImportExportPage apiUrl={apiUrl} />
					</ImportExportProvider>
				}
			/>
			<Route path="imports/*" element={<NominationManagerImportPage />} />
			<Route path="*" element={<Navigate to="exports" />} />
		</Routes>
	);
};

const NominationModuleGuestPage = () => {
	const { settings } = useNominationManagerConfig();

	return <GuestsPage showEmails={settings.showEmails} />;
};

const NominationModuleEmailLogPage = () => {
	const { settings } = useNominationManagerConfig();

	if (!settings.showEmails) {
		return <Navigate to="/nomination-manager" />;
	}

	return <EmailLogDetails />;
};
