import { Id, LaravelResponse, ListFetcherConfig } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { mapFetcherConfigToAxiosConfig } from "~shared/api/indexMapper";

interface IBaseUrls {
	index: string;
	details: (id: Id) => string;
}
interface iBaseKeys {
	index: string[];
	list: string[];
	details: (id: Id) => string[];
}

export abstract class IBaseService<
	TEntityIndex,
	TEntityResource,
	TEntityEditRequest,
	TEntityCreateRequest = TEntityEditRequest,
> {
	public abstract keys: iBaseKeys;
	protected abstract urls: IBaseUrls;

	index = (config: ListFetcherConfig<any>) => {
		return axios.get<LaravelResponse<TEntityIndex[]>>(this.urls.index, mapFetcherConfigToAxiosConfig(config));
	};

	show = (id: Id) => {
		return axios.get<LaravelResponse<TEntityResource>>(this.urls.details(id));
	};

	update = (id: Id, entity: TEntityEditRequest) => {
		return axios.put<LaravelResponse<TEntityIndex>>(this.urls.details(id), entity);
	};

	store = (entity: TEntityCreateRequest) => {
		return axios.post<LaravelResponse<TEntityIndex>>(this.urls.index, entity);
	};

	destroy = (id: Id) => {
		return axios.delete<LaravelResponse<TEntityIndex>>(this.urls.details(id));
	};

	create = (entity: TEntityCreateRequest) => {
		return axios.post<LaravelResponse<TEntityIndex>>(this.urls.index, entity);
	};
}

interface IOptionsUrls extends IBaseUrls {
	options: string;
}
interface IOptionsKeys extends iBaseKeys {
	options: string[];
}

export abstract class IOptionService<
	TEntityIndex,
	TEntityResource,
	TEntityEditRequest,
	TEntityCreateRequest = TEntityEditRequest,
> extends IBaseService<TEntityIndex, TEntityResource, TEntityEditRequest, TEntityCreateRequest> {
	protected abstract urls: IOptionsUrls;
	public abstract keys: IOptionsKeys;

	options = (config?: AxiosRequestConfig) => {
		return axios.get<LaravelResponse<TEntityIndex[]>>(this.urls.options, config);
	};
}
