import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { registrationFormKeys, registrationFormService } from "~shared/api/registrationForms";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
	clearable?: boolean;
}

const fetcher = (search: string, page: number) =>
	registrationFormService.options({ params: { search, page } }).then((r) => r.data);

export const RegistrationFormSingleSelect: FC<Props> = ({ name, label, disabled, required, clearable = true }) => {
	return (
		<PagedSingleSelectField
			name={name}
			queryKey={registrationFormKeys.options}
			fetcher={fetcher}
			label={label}
			disabled={disabled}
			clearable={clearable}
			required={required}
		/>
	);
};
