import {
	Card,
	CardBody,
	CardFooter,
	ColorSelectField,
	DeleteAction,
	FCC,
	Id,
	SubmitButton,
	TextField,
} from "@dgs/core";
import { Form, Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ActionFormState } from "~shared/types/actions";
import { RuleFormState } from "~shared/types/rules";
import { RuleSet } from "~shared/types/ruleSet";
import { SwitchInput } from "../input/SwitchInput";

interface Props {
	ruleSet: RuleSet<RuleFormState, ActionFormState>;
	onSubmit: (ruleSet: RuleSet<RuleFormState, ActionFormState>) => void;
	onDelete?: (ruleSetId: Id) => void;
}

const Wrapper = styled.div`
	width: 100%;
`;

const RuleSetBlock = styled.div`
	display: grid;
	grid-template-rows: max-content max-content max-content;
	grid-gap: ${({ theme }) => theme.spacing(4)};
`;

const InputContainer = styled.div`
	display: flex;
	width: 100%;
	gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
`;

const StyledColorWrapper = styled(ColorSelectField)`
	padding: 0 8px;
`;

export const RuleSetForm: FCC<Props> = ({ ruleSet, children, onSubmit, onDelete }) => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<Card>
				<Formik initialValues={ruleSet} onSubmit={onSubmit}>
					{(formik) => (
						<Form>
							<CardBody>
								<RuleSetBlock>
									<InputContainer>
										<TextField label={t("Description")} name="description" autoFocus={true} />
										{formik.values.rules.length > 1 && (
											<>
												<SwitchInput name="isAndLinked" label="AND" value={true} />
												<SwitchInput name="isAndLinked" label="OR" value={false} />
											</>
										)}
										<StyledColorWrapper label={t("Color")} name="color" />
									</InputContainer>
									{children}
								</RuleSetBlock>
							</CardBody>
							<CardFooter>
								{onDelete && (
									<DeleteAction
										title={t("Delete rule set")}
										action={() => onDelete(ruleSet.id)}
										heading={t("Delete")}
										buttonLabel={t("Delete")}
										labels={{ close: t("Close"), confirm: t("Confirm") }}
									>
										{t("Are you sure you want to delete this rule set?")}
									</DeleteAction>
								)}
								<SubmitButton title={t("Save")} name={t("Save")}>
									{t("Save")}
								</SubmitButton>
							</CardFooter>
						</Form>
					)}
				</Formik>
			</Card>
		</Wrapper>
	);
};
