import { DrawerAutoDataList, IDrawerDataListColumn, Id, useCrudEntity } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { guestFilterKeys } from "~shared/api/guestFilters";
import { guestKeys } from "~shared/api/guests";
import { tagService } from "~shared/api/tags";
import { Tag } from "~shared/types/tag";
import { TagDrawer } from "./TagDrawer";
import { TagsContextMenu } from "./TagsContextMenu";

const TagColor = styled.div<{
	color: string;
}>`
	background-color: ${({ color }) => color};
	width: 22px;
	height: 22px;
	border-radius: 4px;
	border: 1px solid ${({ theme }) => theme.colors.palette.grey[30]};
`;

export const TagList: FC = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const crudOperations = useCrudEntity(tagService, {
		entityName: t("Tag"),
		messages: {
			create: t("Tag was saved."),
			update: t("Tag was saved."),
			delete: t("Tag was deleted."),
		},
	});

	const editTag = useCallback(
		async (id: Id, formState: Tag) => {
			const response = await crudOperations.updateEntity(id, formState);
			await queryClient.invalidateQueries({ queryKey: guestKeys.index });
			return response;
		},
		[crudOperations, queryClient],
	);

	const deleteTag = useCallback(
		async (id: Id) => {
			const response = await crudOperations.deleteEntity(id);
			await queryClient.invalidateQueries({ queryKey: guestKeys.index });
			await queryClient.resetQueries({ queryKey: guestFilterKeys.index });
			return response;
		},
		[crudOperations, queryClient],
	);

	const dataListColumns: IDrawerDataListColumn<Tag>[] = useMemo(
		() => [
			{
				heading: t("Color"),
				valueKey: "color",
				type: "value",
				size: "max-content",
				render: (tag) => <TagColor color={tag.color} />,
			},
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (tag, openDrawer) => (
					<TagsContextMenu tagId={tag.id} handleEdit={openDrawer} handleDelete={() => deleteTag(tag.id)} />
				),
			},
		],
		[t, deleteTag],
	);

	const emptyTag: Tag = {
		name: "",
		id: -1,
		color: "medium",
	};

	return (
		<DrawerAutoDataList
			initialSearch={{ search: "", searchFields: ["name"] }}
			translations={{
				title: t("Tags"),
				empty: t("No tags available"),
			}}
			renderCreateDrawer={(props) => (
				<TagDrawer
					{...props}
					heading={t("Create tag")}
					initialValues={emptyTag}
					onSubmit={(entity) => crudOperations.createEntity(entity)}
				/>
			)}
			renderUpdateDrawer={(resource, props) => (
				<TagDrawer
					{...props}
					heading={t("Edit tag")}
					initialValues={resource}
					onSubmit={(formState) => editTag(resource.id, formState)}
				/>
			)}
			columns={dataListColumns}
			service={tagService}
		/>
	);
};
