import { CrudDrawer, EntityDrawerProps } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { MerchantFormFields } from "~admin/shop/merchants/MerchantFormFields";
import { IMerchant } from "~shared/types/merchant";

const useValidateMerchant = () => {
	const { t } = useTranslation();

	return (values: IMerchant) => {
		const errors: { [key in keyof IMerchant]?: string } = {};

		if (!values.name) {
			errors.name = t("Required field");
		}

		if (!values.email) {
			errors.email = t("Required field");
		}

		if (!values.street) {
			errors.street = t("Required field");
		}

		if (!values.houseNumber) {
			errors.houseNumber = t("Required field");
		}

		if (!values.city) {
			errors.city = t("Required field");
		}

		if (!values.zip) {
			errors.zip = t("Required field");
		}

		if (!values.country) {
			errors.country = t("Required field");
		}

		if (!values.bankAccountHolder) {
			errors.bankAccountHolder = t("Required field");
		}

		if (!values.bank) {
			errors.bank = t("Required field");
		}

		if (!values.iban) {
			errors.iban = t("Required field");
		}

		if (!values.bic) {
			errors.bic = t("Required field");
		}

		if (!values.vatId) {
			errors.vatId = t("Required field");
		}

		if (!values.tradeRegister) {
			errors.tradeRegister = t("Required field");
		}

		if (!values.tradeRegisterNumber) {
			errors.tradeRegisterNumber = t("Required field");
		}

		return errors;
	};
};

export const MerchantDrawer: FC<EntityDrawerProps<IMerchant>> = (props) => {
	const validate = useValidateMerchant();

	return <CrudDrawer {...props} validate={validate} body={<MerchantFormFields />} />;
};
