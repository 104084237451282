import { useQuery } from "@tanstack/react-query";
import { hotelSettingsKeys } from "~admin/shared/api/hotelSettings/hotelSettingsKeys";
import { hotelSettingsService } from "~admin/shared/api/hotelSettings/hotelSettingsService";

export const useHotelSettingsQuery = () => {
	const { data, isLoading } = useQuery({
		queryKey: hotelSettingsKeys.index,
		queryFn: () => hotelSettingsService.index().then(({ data }) => data.data),
	});

	return {
		data,
		isLoading,
	};
};
