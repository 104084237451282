import { Id, PagedSingleSelectField, PagedSingleSelectFieldProps } from "@dgs/core";
import React, { FC } from "react";
import { roomContingentKeys, roomContingentService } from "~shared/api/contingent";
import { IRoomTypeWithContingentResource } from "~shared/types/roomContingent";

interface Props extends Omit<PagedSingleSelectFieldProps<IRoomTypeWithContingentResource>, "queryKey" | "fetcher"> {
	start: string;
	end: string;
	guestId?: Id;
}

const fetcher = (start: string, end: string, guestId?: Id) => (search: string, page: number) =>
	roomContingentService.optionsByDateRange(start, end, guestId, { params: { search, page } }).then((r) => r.data);

export const RoomContingentByDateRangeSingleSelectField: FC<Props> = ({ start, end, guestId, ...props }) => {
	return (
		<PagedSingleSelectField
			queryKey={roomContingentKeys.optionsByDateRange(start, end, guestId)}
			fetcher={fetcher(start, end, guestId)}
			getLabel={(option) => `${option.hotel.name} - ${option.name}`}
			{...props}
		/>
	);
};
