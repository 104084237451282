import { HasPermission } from "@dgs/core";
import React, { FC } from "react";
import styled from "styled-components";
import { DragIndicator } from "~admin/registrationForms/structure/common/DragIndicator";
import { PermissionName } from "~admin/shared/types/permission";
import { ConsentListItem } from "~shared/types/consent";

const Wrapper = styled.div`
	${({ theme }) => theme.borderRadius.medium};
	border: 1px solid ${({ theme }) => theme.colors.palette.grey[20]};
	background-color: ${({ theme }) => theme.colors.palette.grey[10]};
	overflow: hidden;
`;

const Content = styled.span`
	display: grid;
	grid-template-columns: max-content 1fr;
	gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
	padding: ${({ theme }) => theme.spacing(2)};
`;

const Name = styled.span`
	font-size: 14px;
`;

interface Props {
	consent: ConsentListItem;
	forLandingPage: boolean;
}

export const RegistrationFormConsent: FC<Props> = ({ consent }) => {
	return (
		<Wrapper>
			<Content>
				<HasPermission<PermissionName> permissions={["consent.update"]}>
					<DragIndicator />
				</HasPermission>
				<Name>{consent.name}</Name>
			</Content>
		</Wrapper>
	);
};
