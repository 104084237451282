import { Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { ordersKeys } from "~admin/shared/api/orders/ordersKeys";
import { ordersService } from "~admin/shared/api/orders/ordersService";

export const useOrderQuery = (orderId: Id) => {
	const { data, isLoading } = useQuery({
		queryKey: ordersKeys.details(orderId),
		queryFn: () => ordersService.details(orderId).then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};
