import {
	DateField,
	DateTimeField,
	MultiSelectField,
	SingleSelectField,
	TextField,
	TextareaField,
	TimeField,
} from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CheckboxValueField } from "~shared/guestFilters/CheckboxValueField";
import { FilterCreatableMultiSelect } from "~shared/guestFilters/FilterCreatableMultiSelect";
import { StatusMultiSelect, StatusSelect } from "~shared/guests/details/StatusSelect";
import {
	GuestScreeningStatusMultiSelect,
	GuestScreeningStatusSelect,
} from "~shared/guests/screeningStatus/GuestScreeningStatusSelect";
import { ConsentMultiSelect } from "~shared/selects/ConsentMultiSelect";
import { GroupMultiSelect } from "~shared/selects/GroupMultiSelect";
import { GuestMultiSelectField } from "~shared/selects/GuestMultiSelectField";
import { GuestSingleSelect } from "~shared/selects/GuestSingleSelect";
import { RegistrationFormMultiSelect } from "~shared/selects/RegistrationFormMultiSelect";
import { RegistrationFormSingleSelect } from "~shared/selects/RegistrationFormSingleSelect";
import { TagMultiSelect } from "~shared/selects/TagMultiSelect";
import { TenantMultiSelectField } from "~shared/selects/TenantMultiSelectField";
import { TenantSelectField } from "~shared/selects/TenantSelectField";
import { WaveMultiSelectField } from "~shared/selects/WaveMultiSelectField";
import { WaveSelectField } from "~shared/selects/WaveSelectField";
import { IDataFieldIndexResource } from "~shared/types/dataField";
import { IDataFieldOptionOptionResource } from "~shared/types/dataFieldOption";
import { DataFieldType } from "~shared/types/dataFieldType";
import { GuestFilterAttributeType } from "./guestFilterTypes";

const MultiValueWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(1)};
`;

interface Props {
	name: string;
	operator: string;
	type: GuestFilterAttributeType;
	dataField?: IDataFieldIndexResource;
}

export const GuestFilterValueField: FC<Props> = ({ name, operator, type, dataField }) => {
	const { t } = useTranslation();
	const props = { label: t("Value"), name: name };

	switch (operator) {
		case "CONTAINS":
		case "NOT_CONTAINS":
			return <TextField {...props} />;
		case "WHERE_IN_CONTAINS":
		case "WHERE_NOT_IN_CONTAINS":
			return <FilterCreatableMultiSelect {...props} />;
		case "LESS_THAN_EQUAL":
		case "GREATER_THAN_EQUAL":
		case "EQUAL":
		case "NOT_EQUAL":
			return resolveValueField(type, props, dataField);
		case "BETWEEN":
			return <BetweenFieldValue type={type} name={name} />;
		case "WHERE_IN":
		case "WHERE_NOT_IN":
			return resolveWhereInField(type, props, dataField);
		default:
			return null;
	}
};

const resolveValueField = (
	type: GuestFilterAttributeType,
	props: { label: string; name: string },
	dataField?: IDataFieldIndexResource,
) => {
	switch (type) {
		case "groups":
			return <GroupMultiSelect {...props} />;
		case "tags":
			return <TagMultiSelect {...props} />;
		case "registrationForm":
			return <RegistrationFormSingleSelect {...props} />;
		case "tenant":
			return <TenantSelectField {...props} />;
		case "wave":
			return <WaveSelectField {...props} />;
		case "companionParent":
			return <GuestSingleSelect {...props} />;
		case "companions":
			return <GuestSingleSelect {...props} />;
		case "status":
			return <StatusSelect {...props} />;
		case "screeningStatus":
			return <GuestScreeningStatusSelect {...props} />;
		case "agreedConsents":
			return <ConsentMultiSelect {...props} />;
		case DataFieldType.RADIO:
		case DataFieldType.SELECT_FIELD:
			if (!dataField) {
				return null;
			}
			return (
				<SingleSelectField
					{...props}
					options={dataField.options}
					getLabel={(option: IDataFieldOptionOptionResource) => option.value}
					clearable={true}
				/>
			);
		case DataFieldType.MULTI_SELECT_FIELD:
		case DataFieldType.CHECKBOX_GROUP:
			if (!dataField) {
				return null;
			}
			return (
				<MultiSelectField
					{...props}
					options={dataField.options}
					getLabel={(option: IDataFieldOptionOptionResource) => option.value}
					clearable={true}
				/>
			);
		case DataFieldType.DATE:
			return <DateField {...props} />;
		case DataFieldType.TIME:
			return <TimeField {...props} />;
		case "createdAt":
		case "updatedAt":
		case "registrationTimestamp":
		case DataFieldType.DATETIME:
			return <DateTimeField {...props} />;
		case DataFieldType.CHECKBOX:
			return <CheckboxValueField {...props} />;
		case DataFieldType.NUMBER:
			return <TextField type="number" {...props} />;
		case "registrationLocale":
		case "registrationCode":
		case "referenceId":
		case DataFieldType.TEXT:
			return <TextField {...props} />;
		case DataFieldType.EMAIL:
			return <TextField {...props} />;
		case DataFieldType.LONG_TEXT:
			return <TextareaField {...props} />;
		case DataFieldType.URL:
			return <TextField {...props} />;
		case DataFieldType.PHONE:
			return <TextField type="tel" {...props} />;
		default:
			return null;
	}
};

const BetweenFieldValue: FC<{
	name: string;
	type: GuestFilterAttributeType;
}> = ({ name, type }) => {
	const { t } = useTranslation();
	switch (type) {
		case DataFieldType.DATE:
			return (
				<MultiValueWrapper>
					<DateField label={t("Start")} name={`${name}.start`} />
					<DateField label={t("End")} name={`${name}.end`} />
				</MultiValueWrapper>
			);
		case "createdAt":
		case "updatedAt":
		case "registrationTimestamp":
		case DataFieldType.DATETIME:
			return (
				<MultiValueWrapper>
					<DateTimeField label={t("Start")} name={`${name}.start`} />
					<DateTimeField label={t("End")} name={`${name}.end`} />
				</MultiValueWrapper>
			);
		default:
			return null;
	}
};

const resolveWhereInField = (
	type: GuestFilterAttributeType,
	props: { label: string; name: string },
	dataField?: IDataFieldIndexResource,
) => {
	switch (type) {
		case "wave":
			return <WaveMultiSelectField {...props} />;
		case "tenant":
			return <TenantMultiSelectField {...props} />;
		case "groups":
			return <GroupMultiSelect {...props} />;
		case "tags":
			return <TagMultiSelect {...props} />;
		case "registrationForm":
			return <RegistrationFormMultiSelect {...props} />;
		case "companions":
			return <GuestMultiSelectField {...props} />;
		case "companionParent":
			return <GuestMultiSelectField {...props} />;
		case "status":
			return <StatusMultiSelect {...props} />;
		case "screeningStatus":
			return <GuestScreeningStatusMultiSelect {...props} />;
		case "agreedConsents":
			return <ConsentMultiSelect {...props} />;
		case DataFieldType.SELECT_FIELD:
		case DataFieldType.RADIO:
		case DataFieldType.MULTI_SELECT_FIELD:
		case DataFieldType.CHECKBOX_GROUP:
			if (!dataField) {
				return null;
			}
			return (
				<MultiSelectField
					{...props}
					options={dataField.options}
					getLabel={(option: IDataFieldOptionOptionResource) => option.value}
					clearable={true}
				/>
			);
		default:
			return null;
	}
};
