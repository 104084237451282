import { SingleSelectField } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { DataFieldType } from "~shared/types/dataFieldType";

interface Props {
	name: string;
	label: string;
	currentDataFieldType?: DataFieldType;
	onChange: (dataFieldType: DataFieldType | null) => void;
}

const allOptions = Object.values(DataFieldType);
const getValue = (type: DataFieldType) => type;
const getLabel = (type: DataFieldType) => type;
export const DataFieldTypeSelect: FC<Props> = ({ label, name, currentDataFieldType, onChange }) => {
	const options = useMemo(() => {
		if (!currentDataFieldType) return allOptions;

		switch (currentDataFieldType) {
			case DataFieldType.CHECKBOX_GROUP:
			case DataFieldType.MULTI_SELECT_FIELD:
				return [DataFieldType.CHECKBOX_GROUP, DataFieldType.MULTI_SELECT_FIELD];
			case DataFieldType.TEXT:
			case DataFieldType.LONG_TEXT:
				return [DataFieldType.TEXT, DataFieldType.LONG_TEXT];
		}

		return [];
	}, [currentDataFieldType]);

	return (
		<SingleSelectField
			label={label}
			name={name}
			options={!options.length ? allOptions : options}
			getLabel={getLabel}
			getValue={getValue}
			disabled={!options.length}
			onChange={(e) => onChange(e.target.value)}
		/>
	);
};
