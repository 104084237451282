import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { eachDayOfInterval, endOfDay, isEqual, subDays } from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { hotelInquiryService } from "~shared/api/hotelInquiries";
import { IHotelInquiryIndex } from "~shared/types/hotelInquiry";
import { IRoomAllocation } from "~shared/types/roomContingent";

export const useAcceptHotelInquiry = () => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();

	const acceptInquiry = useCallback(
		async (hotelInquiryId: Id, roomAllocation: IRoomAllocation[], afterResolve: () => void) => {
			const response = await hotelInquiryService.acceptInquiry(
				hotelInquiryId,
				roomAllocation
					.filter((allocation) => allocation.roomContingent)
					.map((allocation) => ({
						roomContingentId: allocation.roomContingent.id,
						roomMateIds: allocation.roomMates ? allocation.roomMates.map((roomMate) => roomMate.id) : [],
					})),
			);

			if (apiIsOK(response)) {
				showToast({
					type: "success",
					title: t("Successfully accepted inquiry"),
					body: t("The hotel inquiry has been accepted successfully."),
				});
				afterResolve();
				await queryClient.invalidateQueries({ queryKey: hotelInquiryService.keys.index });
			}
		},
		[queryClient, showToast, t],
	);

	const getContingentRange = useCallback(
		(
			checkIn: Date,
			checkOut: Date,
			hotelInquiry: IHotelInquiryIndex | null,
		): {
			range: Date[];
		} => {
			if (!hotelInquiry || isEqual(checkIn, checkOut)) {
				return {
					range: [],
				};
			}

			const range = eachDayOfInterval({ start: checkIn, end: subDays(endOfDay(checkOut), 1) });

			return {
				range: range,
			};
		},
		[],
	);

	return {
		acceptInquiry,
		getContingentRange,
	};
};
