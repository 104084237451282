import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { locationService } from "~admin/shared/api/locations";
import { Location } from "~admin/shared/types/location";

export const useLocationForm = () => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const createLocation = useCallback(
		async (location: Location, afterSuccess?: () => void) => {
			const response = await locationService.store({
				id: location.id,
				name: location.name,
				guestViewId: location.guestView?.id ?? null,
				imageId: location.image?.id ?? null,
				guestFilterId: location.guestFilter?.id ?? null,
			});
			if (apiIsOK(response)) {
				showToast({
					body: t("Location was saved."),
					title: t("Location"),
					type: "success",
				});
				afterSuccess && afterSuccess();
				await queryClient.invalidateQueries({ queryKey: locationService.keys.list });
				return true;
			}
			return false;
		},
		[queryClient, showToast, t],
	);

	const updateLocation = useCallback(
		async (location: Location, afterSuccess?: () => void) => {
			const response = await locationService.update(location.id, {
				id: location.id,
				name: location.name,
				guestViewId: location.guestView?.id ?? null,
				imageId: location.image?.id ?? null,
				guestFilterId: location.guestFilter?.id ?? null,
			});
			if (apiIsOK(response)) {
				showToast({
					body: t("Location was saved."),
					title: t("Location"),
					type: "success",
				});
				afterSuccess && afterSuccess();
				await queryClient.invalidateQueries({ queryKey: locationService.keys.list });
				await queryClient.invalidateQueries({ queryKey: locationService.keys.details(location.id) });
				return true;
			}

			return false;
		},
		[queryClient, showToast, t],
	);

	const deleteLocation = useCallback(
		async (locationId: Id, shouldGoBackTo?: string) => {
			const response = await locationService.destroy(locationId);
			if (apiIsOK(response)) {
				showToast({
					body: t("Location was deleted."),
					title: t("Location"),
					type: "success",
				});
				if (shouldGoBackTo) {
					navigate(shouldGoBackTo, { replace: true });
				}
				await queryClient.invalidateQueries({ queryKey: locationService.keys.list });
			}
		},
		[navigate, queryClient, showToast, t],
	);

	return {
		createLocation,
		updateLocation,
		deleteLocation,
	};
};
