import {
	mapBaseBlockFormToRequest,
	mapBaseBlockResourceToForm,
	validateBlockForm,
	validateTranslationValues,
} from "@dgs/core";
import { EmailPersonalizedButtonBlockForm } from "./EmailPersonalizedButtonBlockForm";
import {
	IEmailPersonalizedButtonBlockForm,
	IEmailPersonalizedButtonBlockView,
} from "./emailPersonalizedButtonBlockTypes";
import { EmailPersonalizedButtonBlockView } from "./EmailPersonalizedButtonBlockView";

export const emailPersonalizedButtonBlockView: IEmailPersonalizedButtonBlockView = {
	View: EmailPersonalizedButtonBlockView,
};

export const emailPersonalizedButtonBlockForm: IEmailPersonalizedButtonBlockForm = {
	blockType: "emailPersonalizedButtonBlock",
	type: "block",
	Form: EmailPersonalizedButtonBlockForm,
	Preview: EmailPersonalizedButtonBlockView,
	emptyValue: {
		type: "primary",
		color: "#FFF",
		backgroundColor: "#000000",
	},
	emptyTranslatedValue: {
		label: "",
		linkType: "registrationLink",
	},
	mapEditResourceToFormState: (resource, locales, fallbackLocale, emptyValue) =>
		mapBaseBlockResourceToForm(resource, locales, fallbackLocale, emptyValue, (x) => x),
	mapFormStateToRequest: (resource, form) => mapBaseBlockFormToRequest(resource.type, form),
	validate: (formState, t, fallbackLocale) => {
		return {
			...validateBlockForm(formState, t),
			...validateTranslationValues(formState, fallbackLocale, (value, valueErrors) => {
				if (!value.label) {
					valueErrors.label = t("Required field");
				}
			}),
		};
	},
};

export const emailPersonalizedButtonBlock = {
	...emailPersonalizedButtonBlockView,
	...emailPersonalizedButtonBlockForm,
};
