import {
	CardBody,
	CardFooter,
	CardHeader,
	CheckboxField,
	FormattedDate,
	MenuItemSingleSelect,
	SingleSelectField,
	SubmitButton,
	TextField,
	isEmail,
	useDynamicConfig,
} from "@dgs/core";
import { Form, Formik, useField } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AdobeThirdPartyScriptsField } from "~admin/settings/AdobeThirdPartyScriptsField";
import { QrCode } from "~admin/shared/ui/QrCode";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { RegistrationFormSingleSelect } from "~shared/selects/RegistrationFormSingleSelect";
import { IProductDataFormState, IProductDataResource, TStartPageBehaviour } from "~shared/types/productData";

const Subtitle = styled.div`
	font-size: 1.25rem;
	margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const Divider = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.palette.grey[20]};
`;

const EventInfoSection = styled.div`
	padding: ${({ theme }) => theme.spacing(2)} 0;
`;

const Info = styled.div`
	display: grid;
	grid-template-columns: 1fr max-content;
	gap: ${({ theme }) => theme.spacing(16)};
	align-items: center;
`;

const MutedLabel = styled.span`
	color: #6d6e80;
	font-size: ${({ theme }) => theme.fonts.sizes.small};
`;

interface Props {
	settings: IProductDataResource;
	handleSave: (settings: IProductDataFormState) => Promise<void>;
}

export const SettingsForm: FC<Props> = ({ settings, handleSave }) => {
	const { t } = useTranslation();
	const { frontendUrl } = useDynamicConfig();
	const { locales } = useContentLocale();
	const { translations, eventStartDate, eventEndDate, eventProjectNumber, ...rest } = settings;
	const formState = {
		...rest,
		adobeAnalyticsThirdPartyScripts:
			settings.adobeAnalyticsThirdPartyScripts !== null
				? JSON.stringify(settings.adobeAnalyticsThirdPartyScripts, null, 2)
				: "",
	};

	const validate = useCallback(
		(values: IProductDataFormState) => {
			const errors: { [K in keyof IProductDataFormState]?: any } = {};

			if (values.contactEmail && !isEmail(values.contactEmail)) {
				errors.contactEmail = t("Invalid email address");
			}

			if (values.startPageBehaviour === "menuItem" && !values.startPageMenuItem) {
				errors.startPageMenuItem = t("Required field");
			}

			return errors;
		},
		[t],
	);

	return (
		<>
			<CardHeader>
				<EventInfoSection>
					<Info>
						<MutedLabel>{t("Project number")}:</MutedLabel>
						{eventProjectNumber}
					</Info>
					<Info>
						<MutedLabel>{t("Event start date")}:</MutedLabel>
						<FormattedDate date={eventStartDate} />
					</Info>
					<Info>
						<MutedLabel>{t("Event end date")}:</MutedLabel>
						<FormattedDate date={eventEndDate} />
					</Info>
					{locales.map((locale) => (
						<Info key={locale}>
							<MutedLabel>
								{t("Event name")} {locale}:
							</MutedLabel>
							{translations[locale].eventName}
						</Info>
					))}
				</EventInfoSection>
			</CardHeader>

			<Formik initialValues={formState} validate={validate} onSubmit={handleSave}>
				<Form>
					<CardBody>
						<Subtitle>{t("General")}</Subtitle>
						<RegistrationFormSingleSelect name="defaultRegistrationForm" label={t("Default registration form")} />
						<TextField name="contactName" label={t("Contact name")} />
						<TextField name="contactEmail" label={t("Contact email")} />
						<TextField name="htmlTitle" label={t("HTML title")} />
						<CheckboxField label={t("Enable analytics")} name="enableAnalytics" />
						<CheckboxField label={t("Enable adobe analytics")} name="enableAdobeAnalytics" />
						<AdobeThirdPartyScriptsField />
					</CardBody>
					<Divider />
					<CardBody>
						<Subtitle>{t("Start page behaviour")}</Subtitle>
						<StartPageBehaviourSwitcher />
						<QrCode value={`${frontendUrl}`} />
					</CardBody>
					<CardFooter>
						<SubmitButton title={t("Save")} name={t("Save")}>
							{t("Save")}
						</SubmitButton>
					</CardFooter>
				</Form>
			</Formik>
		</>
	);
};

const startPageBehaviorOptions: TStartPageBehaviour[] = ["menuItem", "defaultRegistrationForm"];
const StartPageBehaviourSwitcher: FC = () => {
	const [{ value }] = useField<TStartPageBehaviour>("startPageBehaviour");
	const { t } = useTranslation();

	return (
		<>
			<SingleSelectField
				name="startPageBehaviour"
				label={t("Define what happens when the guest lands on your registration page with a public link")}
				options={startPageBehaviorOptions}
				getLabel={(x) => {
					if (x === "menuItem") {
						return t("Redirect to menu item");
					}
					return t("Show general registration form");
				}}
				clearable
			/>
			{value === "menuItem" && <MenuItemSingleSelect name="startPageMenuItem" label={t("Start page menu item")} />}
		</>
	);
};
