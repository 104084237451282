import { CrudDrawer, EntityDrawerProps, TextField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { EditSortableGuestAttributesField } from "~admin/guestView/EditSortableGuestAttributesField";
import { GuestViewConfigFormValues } from "~shared/types/guestView";

export const GuestViewDrawer: FC<EntityDrawerProps<GuestViewConfigFormValues>> = (props) => {
	const { t } = useTranslation();

	const validate = (values: GuestViewConfigFormValues) => {
		const errors: { [K in keyof GuestViewConfigFormValues]?: any } = {};

		if (!values.name) {
			errors.name = t("Field is required");
		}

		if (values.guestAttributes.length === 0) {
			errors.guestAttributes = t("Choose at least one guest attribute for this guest view.");
		}

		return errors;
	};

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<TextField name="name" label={t("Name")} required={true} />
					<EditSortableGuestAttributesField name="guestAttributes" />
				</>
			}
		/>
	);
};
