import {
	AutoDataList,
	ContextMenu,
	ContextMenuItem,
	DialogContextMenuItem,
	IDataListColumn,
	IconButton,
	Id,
	apiIsOK,
	useFormatDateTime,
	useToasts,
} from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ordersKeys, ordersService } from "~admin/shared/api/orders";
import { OrderFormValue, OrdersIndex } from "~admin/shared/types/orders";
import { CreateOrderDrawer } from "~admin/shop/orders/CreateOrderDrawer";
import { OrderDrawer } from "~admin/shop/orders/OrderDrawer";
import { OrderExportButton } from "~admin/shop/orders/OrderExportButton";
import { OrderListBulkAction } from "~admin/shop/orders/OrderListBulkAction";
import { ActiveGuestFilterEntries, useGuestFilter } from "~shared/guestFilters";
import { useGuestListUtils } from "~shared/guests";
import { LocalGuestViewSwitcher } from "~shared/guests/GuestViewSwitchers";

import { useGuestView } from "~shared/providers/useGuestView";
import { GuestScreeningStatus } from "~shared/types/guest";

export const OrdersList: FC = () => {
	const { t } = useTranslation();
	const { currentGuestView } = useGuestView();
	const { filters, mapGuestFilterToGuestFilterRequest, handleOpen } = useGuestFilter();
	const { columns: guestAttributeColumns } = useGuestListUtils(currentGuestView);
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const [order, setOrder] = useState<OrderFormValue | null>(null);
	const [createDrawerOpen, setCreateDrawerOpen] = useState<boolean>(false);
	const { showToast } = useToasts();
	const formatDateTime = useFormatDateTime();
	const guestFilterRequest = useMemo(
		() => mapGuestFilterToGuestFilterRequest(filters),
		[mapGuestFilterToGuestFilterRequest, filters],
	);

	const sendRemainingPaymentMail = useCallback(
		async (id: Id) => {
			const response = await ordersService.sendRemainingPaymentMail(id);

			if (apiIsOK(response)) {
				showToast({
					body: t("Remaining payment started"),
					title: t("Mails"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: ordersKeys.index });
			}
		},
		[showToast, t, queryClient],
	);

	const approveOrder = useCallback(
		async (id: Id) => {
			const response = await ordersService.sendPaymentMail(id);

			if (apiIsOK(response)) {
				showToast({
					body: t("The order was approved."),
					title: t("Approve order"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: ordersKeys.index });
			}
		},
		[showToast, t, queryClient],
	);

	const columns: IDataListColumn<OrdersIndex>[] = useMemo(() => {
		return [
			{
				heading: t("Order status"),
				valueKey: "orderStatus",
				type: "value",
				render: (order) => t(order.orderStatus),
			},
			{
				heading: t("Order created at"),
				valueKey: "createdAt",
				type: "value",
				render: (order) => formatDateTime(order.createdAt),
			},
			{
				heading: t("Net price"),
				valueKey: "netPrice",
				type: "value",
			},
			{
				heading: t("Remaining payment started"),
				valueKey: "remainingPaymentStarted",
				type: "value",
				render: (order) =>
					order.remainingPaymentStarted === null ? "-" : order.remainingPaymentStarted ? t("Yes") : t("No"),
			},
			{
				heading: t("Payment link sent"),
				valueKey: "paymentLinkSent",
				type: "value",
				render: (order) => (order.paymentLinkSent === null ? "-" : order.paymentLinkSent ? t("Yes") : t("No")),
			},
			{
				heading: t("Payment providers"),
				valueKey: "paymentProvider",
				type: "value",
				render: (order) => order.paymentProvider.name,
			},
			...(guestAttributeColumns as IDataListColumn<OrdersIndex>[]),
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (order: OrdersIndex) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuItem
							label={t("Order items")}
							action={() => navigate(`../orders/${order.id}`)}
							title={t("Order items")}
							icon="caret"
							shortcut="primary"
						/>
						<ContextMenuItem
							label={t("Edit order status")}
							action={() => setOrder(order)}
							title={t("Edit order status")}
							icon="edit"
							shortcut="default"
						/>
						{order.orderStatus === "partialPaid" && (
							<ContextMenuItem
								label={t("Trigger remaining payment")}
								action={() => sendRemainingPaymentMail(order.id)}
								title={t("Trigger remaining payment")}
								icon="send"
							/>
						)}
						{order.orderStatus === "approvalPending" &&
							(order.screeningStatus === GuestScreeningStatus.POSITIVE_AUTOMATIC ||
							order.screeningStatus === GuestScreeningStatus.POSITIVE_CONFIRMED ? (
								<DialogContextMenuItem
									label={t("Approve order")}
									action={() => approveOrder(order.id)}
									title={t("Approve order")}
									icon="check"
									heading={t("Order")}
									labels={{ close: t("Close"), confirm: t("Approve") }}
								>
									{t("The guest has been screened positively. Do you really want to approve this order?")}
								</DialogContextMenuItem>
							) : (
								<ContextMenuItem
									label={t("Approve order")}
									action={() => approveOrder(order.id)}
									title={t("Approve order")}
									icon="check"
								/>
							))}
					</ContextMenu>
				),
			},
		];
	}, [guestAttributeColumns, t, navigate, sendRemainingPaymentMail, approveOrder, formatDateTime]);

	return (
		<>
			<AutoDataList
				name="orders"
				heading={t("Orders")}
				headerChildren={<ActiveGuestFilterEntries />}
				headerActions={
					<>
						<LocalGuestViewSwitcher />
						<IconButton size="small" title={t("Create Order")} onClick={() => setCreateDrawerOpen(true)} icon="plus" />
						<IconButton size="small" title={t("Guest filter")} onClick={handleOpen} icon="filter" />
						<OrderExportButton
							generateExport={ordersService.createExport}
							filters={guestFilterRequest}
							currentGuestViewId={currentGuestView.id}
						/>
					</>
				}
				empty={t("No orders available")}
				columns={columns}
				queryKey={ordersKeys.filtered(currentGuestView.id, filters)}
				fetcher={(config) =>
					ordersService.filtered(currentGuestView.id, mapGuestFilterToGuestFilterRequest(filters), config)
				}
				onRowClick={(value) => navigate(`../orders/${value.id}`)}
				initialSearch={{ search: "", searchFields: ["textDataFields"] }}
				bulkEditActions={<OrderListBulkAction />}
				enableBulkEdit={true}
			/>
			<OrderDrawer order={order} onClose={() => setOrder(null)} />
			<CreateOrderDrawer open={createDrawerOpen} onClose={() => setCreateDrawerOpen(false)} />
		</>
	);
};
