import { Empty, Id, Loading, StylesContainer, apiIsOK, useI18nForm } from "@dgs/core";
import {
	DndContext,
	DragEndEvent,
	DragOverlay,
	DragStartEvent,
	KeyboardSensor,
	MouseSensor,
	closestCenter,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import {
	SortableContext,
	arrayMove,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { SortableOptionItem } from "~admin/dataFields/dataFieldOptions/SortableOptionItem";
import { SortableListOptionItem } from "~admin/dataFields/dataFieldOptions/SortableOptionListItem";
import { DataFieldOptionService, dataFieldService } from "~shared/api/dataFields";
import { IDataFieldOptionIndexResource } from "~shared/types/dataFieldOption";

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	align-content: start;
	width: 100%;
	gap: ${({ theme }) => theme.spacing(4)};
	margin: ${({ theme }) => theme.spacing(4)} 0;
`;

const SortableWrapper = styled.div`
	display: grid;
	gap: ${({ theme }) => theme.spacing(4)};
	align-content: start;
	overflow-y: auto;
	grid-auto-rows: max-content;
	width: 100%;
`;

const Container = styled.div`
	width: 100%;
	display: flex;
	flex: 1;
`;

interface Props {
	dataFieldId: Id;
	onEditClick: (id: Id) => void;
	onDeleteClick: (id: Id) => void;
}

export const DataFieldOptionSortableList: FC<Props> = ({ dataFieldId, onEditClick, onDeleteClick }) => {
	const { currentLocale: language } = useI18nForm();
	const service = new DataFieldOptionService(`${dataFieldId}`);
	const queryClient = useQueryClient();
	const { data, isLoading } = useQuery({
		queryKey: [...service.keys.list, language],
		queryFn: () => service.listByLanguage(language).then((x) => x.data.data),
	});
	const [options, setOptions] = useState<IDataFieldOptionIndexResource[]>([]);
	const [activeItem, setActiveItem] = useState<IDataFieldOptionIndexResource | null>(null);
	const { t } = useTranslation();
	const sensors = useSensors(
		useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	useEffect(() => {
		if (data) {
			setOptions(data);
		}
	}, [data]);

	const handleDragStart = useCallback(
		({ active }: DragStartEvent) => {
			setActiveItem(options.find((d) => d.id === active.id) || null);
		},
		[options],
	);

	const handleDragEnd = useCallback(
		async (event: DragEndEvent) => {
			const { active, over } = event;
			setActiveItem(null);

			if (over === null) {
				return;
			}

			if (active.id !== over.id) {
				const oldIndex = options.findIndex((d) => d.id === active.id);
				const newIndex = options.findIndex((d) => d.id === over.id);
				const newOptions = arrayMove(options, oldIndex, newIndex);

				setOptions(newOptions);

				const res = await dataFieldService.order(
					dataFieldId,
					language,
					newOptions.map((x) => x.id),
				);

				if (apiIsOK(res)) {
					await queryClient.invalidateQueries({ queryKey: [...service.keys.list, language] });
				}
			}
		},
		[dataFieldId, language, options, queryClient, service.keys.list],
	);

	if (isLoading) {
		return <Loading />;
	}

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragStart={handleDragStart}
			onDragEnd={handleDragEnd}
		>
			<Wrapper>
				<Container>
					<SortableWrapper>
						<SortableContext items={options} strategy={verticalListSortingStrategy}>
							{options.map((item) => (
								<SortableOptionItem key={item.id} item={item} onEditClick={onEditClick} onDeleteClick={onDeleteClick} />
							))}
							{options.length === 0 && <Empty>{t("No data field options available")}</Empty>}
						</SortableContext>
					</SortableWrapper>
				</Container>
			</Wrapper>
			{createPortal(
				<StylesContainer>
					<DragOverlay>
						{activeItem ? <SortableListOptionItem active>{activeItem.value}</SortableListOptionItem> : null}
					</DragOverlay>
				</StylesContainer>,
				document.body,
			)}
		</DndContext>
	);
};
