import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { InvoiceProviderList } from "~admin/shop/invoiceProviders/InvoiceProviderList";
import { InvoicesPage } from "~admin/shop/invoices/InvoicesPage";
import { OrderItemsList } from "~admin/shop/orders/orderItems/OrderItemsList";
import { OrdersPage } from "~admin/shop/orders/OrdersPage";
import { PaymentProviderList } from "~admin/shop/paymentProvider/PaymentProviderList";
import { TaxesList } from "~admin/shop/taxes/TaxesList";
import { LocalGuestViewProvider } from "~shared/providers/LocalGuestViewProvider";
import { ShopItemList } from "./items/ShopItemList";
import { MerchantsList } from "./merchants/MerchantsList";
import { ShopSettings } from "./ShopSettings";

export const ShopPage: FC = () => {
	return (
		<Routes>
			<Route index element={<Navigate to="items" replace={true} />} />
			<Route path="items" element={<ShopItemList />} />
			<Route path="settings" element={<ShopSettings />} />
			<Route
				path="orders"
				element={
					<LocalGuestViewProvider guestViewKey="ordersList">
						<OrdersPage />
					</LocalGuestViewProvider>
				}
			/>
			<Route path="orders/:orderId/*" element={<OrderItemsList />} />
			<Route path="payment-provider" element={<PaymentProviderList />} />
			<Route path="invoice-provider" element={<InvoiceProviderList />} />
			<Route path="taxes" element={<TaxesList />} />
			<Route path="merchants" element={<MerchantsList />} />
			<Route
				path="invoices"
				element={
					<LocalGuestViewProvider guestViewKey="invoicesList">
						<InvoicesPage />
					</LocalGuestViewProvider>
				}
			/>
		</Routes>
	);
};
