import { CardBody } from "@dgs/core";
import { Link } from "react-router-dom";
import styled from "styled-components";

export const DashboardCardGrid = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: ${({ theme }) => theme.spacing(6)};

	${({ theme }) => theme.breakpoints.desktop} {
		grid-template-columns: repeat(2, 1fr);
	}

	${({ theme }) => theme.breakpoints.largeDesktop} {
		grid-template-columns: repeat(4, 1fr);
	}
`;

export const LinkCard = styled(Link)`
	${({ theme }) => theme.card.container};
	text-decoration: none;
`;

export const DashboardCardBody = styled(CardBody)`
	display: grid;
	grid-auto-rows: max-content;
	gap: ${({ theme }) => theme.spacing(1)};
`;
