import { Id, LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { mapFetcherConfigToAxiosConfig, mapSortParamsToGuestListSortParams } from "~shared/api/indexMapper";
import { IActiveGuestFilterEntry, IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";
import { Guest, GuestListBulkEditRequest } from "~shared/types/guest";
import { GuestIndexResource } from "~shared/types/newGuestTypes";

class CompanionService {
	public keys = {
		companionsFiltered: (filters: IActiveGuestFilterEntry[], guestId: Id) => [
			"guests",
			`${guestId}`,
			"companions",
			JSON.stringify(filters),
		],
		companionsBulkDelete: (guestId: Id) => ["guests", `${guestId}`, "companions", "bulk-delete"],
		companionsBulkDetach: (guestId: Id) => ["guests", `${guestId}`, "companions", "bulk-detach"],
		companionsBulkEdit: (guestId: Id) => ["guests", `${guestId}`, "companions", "bulk-edit"],
		indexByParent: (guestId: Id) => ["guests", `${guestId}`, "companions"],
		optionsByParent: (guestId: Id) => ["guests", `${guestId}`, "companions", "options"],
		attachCompanions: (guestId: Id) => ["guests", `${guestId}`, "companions", "attach"],
		deleteCompanion: (companionId: Id) => ["companions", `${companionId}`],
		detachCompanion: (companionId: Id) => ["companions", `${companionId}`, "detach"],
	};

	private urls = {
		indexByParent: (guestId: Id) => keyToUrl(this.keys.indexByParent(guestId)),
		optionsByParent: (guestId: Id) => keyToUrl(this.keys.optionsByParent(guestId)),
		attachCompanions: (guestId: Id) => keyToUrl(this.keys.attachCompanions(guestId)),
		companionBulkDelete: (guestId: Id) => keyToUrl(this.keys.companionsBulkDelete(guestId)),
		companionBulkDetach: (guestId: Id) => keyToUrl(this.keys.companionsBulkDetach(guestId)),
		companionBulkEdit: (guestId: Id) => keyToUrl(this.keys.companionsBulkEdit(guestId)),
		detachCompanion: (companionId: Id) => keyToUrl(this.keys.detachCompanion(companionId)),
		deleteCompanion: (companionId: Id) => keyToUrl(this.keys.deleteCompanion(companionId)),
	};

	companionBulkDetach = (
		guestIds: Id[],
		filters: IGuestFilterFormValueEntry[],
		search: string | null,
		parentId: Id,
		allGuests = false,
	) =>
		axios.put<LaravelResponse<GuestIndexResource[]>>(this.urls.companionBulkDetach(parentId), {
			guestIds,
			filters,
			search,
			allGuests,
		});

	companionBulkDelete = (
		guestIds: Id[],
		filters: IGuestFilterFormValueEntry[],
		search: string | null,
		parentId: Id,
		allGuests = false,
	) =>
		axios.post<LaravelResponse<GuestIndexResource[]>>(this.urls.companionBulkDelete(parentId), {
			guestIds,
			filters,
			search,
			allGuests,
		});

	companionBulkEdit = (
		guestIds: Id[],
		filters: IGuestFilterFormValueEntry[],
		search: string | null,
		change: GuestListBulkEditRequest,
		parentId: Id,
		allGuests = false,
	) =>
		axios.put<LaravelResponse<any>>(this.urls.companionBulkEdit(parentId), {
			guestIds,
			filters,
			search,
			change,
			allGuests,
		});

	indexByParent =
		(guestViewId: Id, filters: IGuestFilterFormValueEntry[], guestId: Id) => (config: ListFetcherConfig) =>
			axios.put<LaravelResponse<GuestIndexResource[]>>(
				this.urls.indexByParent(guestId),
				{ guestViewId, filters, ...mapSortParamsToGuestListSortParams(config.sortParams) },
				mapFetcherConfigToAxiosConfig(config),
			);

	optionsByParent = (guestId: Id, config?: AxiosRequestConfig) =>
		axios.get<LaravelResponse<Guest[]>>(this.urls.optionsByParent(guestId), config);

	attachCompanions = (guestId: Id, companionIds: Id[], config?: AxiosRequestConfig) =>
		axios.put<LaravelResponse<GuestIndexResource[]>>(this.urls.attachCompanions(guestId), { companionIds }, config);

	detachCompanion = (companionId: Id) =>
		axios.put<LaravelResponse<GuestIndexResource[]>>(this.urls.detachCompanion(companionId));

	deleteCompanion = (companionId: Id) =>
		axios.delete<LaravelResponse<GuestIndexResource[]>>(this.urls.deleteCompanion(companionId));
}

export const companionService = new CompanionService();
