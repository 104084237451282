import { HasPermission } from "@dgs/core";
import React, { FC } from "react";
import styled from "styled-components";
import { PermissionName } from "~admin/shared/types/permission";
import {
	RegistrationFormConsentDrawer,
	RegistrationFormContentDrawer,
	RegistrationFormContentStep,
	RegistrationFormContentStepProps,
} from "./RegistrationFormContentStep";
import { useRegistrationFormStep } from "./useRegistrationFormStep";

const ActionWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr max-content;
	gap: ${({ theme }) => theme.spacing(3)};
`;

export const RegistrationFormLandingStep: FC<RegistrationFormContentStepProps> = ({
	registrationFormId,
	block,
	consents,
}) => {
	const { updateLandingStep, syncConsents } = useRegistrationFormStep(registrationFormId);

	return (
		<RegistrationFormContentStep
			registrationFormId={registrationFormId}
			type="landing"
			blockName={block?.name || null}
			consents={consents}
			action={
				<ActionWrapper>
					<HasPermission<PermissionName> permissions={["consent.update"]}>
						<RegistrationFormConsentDrawer
							consents={consents}
							onSubmit={(values) =>
								syncConsents(
									values.consents.map((consent) => consent.id),
									true,
								)
							}
						/>
					</HasPermission>
					<RegistrationFormContentDrawer block={block} onSubmit={updateLandingStep} />
				</ActionWrapper>
			}
		/>
	);
};
