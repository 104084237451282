import { H6 } from "@dgs/core";
import styled from "styled-components";

export const RuleList = styled.div`
	display: grid;
	grid-template-columns: max-content 1fr 1fr 1fr max-content;
	grid-column-gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
`;

export const RuleSetFooter = styled.div`
	padding: 0 ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(4)};
`;

export const RuleSetTitle = styled(H6)`
	font-weight: 600;
	text-align: right;
	padding-right: ${({ theme }) => theme.spacing(2)};
`;
