import { useMemo } from "react";
import { GuestViewConfigResource } from "~shared/types/guestView";
import { useGlobalGuestView } from "./GlobalGuestViewProvider";
import { useLocalGuestView } from "./LocalGuestViewProvider";

export const useGuestView = (): { currentGuestView: GuestViewConfigResource } => {
	const { globalGuestView } = useGlobalGuestView();
	const { localGuestView } = useLocalGuestView();

	return useMemo(
		() => ({
			currentGuestView: localGuestView || globalGuestView,
		}),
		[globalGuestView, localGuestView],
	);
};
