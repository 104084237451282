import { Button, IconButton, TextField } from "@dgs/core";
import { FieldArray, useField } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IRoomContingentPrice } from "~shared/types/roomContingent";

const PricesWrapper = styled.div`
	display: grid;
	grid-auto-rows: max-content;
	gap: ${({ theme }) => theme.spacing(2)};
`;

const PriceWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 3fr 3fr max-content;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(3)};
`;

const ButtonWrapper = styled.div`
	padding-bottom: ${({ theme }) => theme.spacing(5)};
`;

interface Props {
	name: string;
}
export const PricesField: FC<Props> = ({ name }) => {
	const { t } = useTranslation();
	const [{ value }] = useField<IRoomContingentPrice[]>(name);
	return (
		<FieldArray
			name={name}
			render={(helpers) => (
				<PricesWrapper>
					{value.map((price, index) => (
						<PriceWrapper key={index}>
							<TextField label={t("Occupancy")} name={`${name}.${[index]}.occupancy`} type="number" required={true} />
							<TextField
								label={t("Purchase price")}
								name={`${name}.${[index]}.purchasePrice`}
								type="number"
								required={true}
							/>
							<TextField
								label={t("Selling price")}
								name={`${name}.${[index]}.sellingPrice`}
								type="number"
								required={true}
							/>
							<IconButton
								size="small"
								type="button"
								color="danger"
								title={t("Delete")}
								onClick={() => helpers.remove(index)}
								icon="delete"
							/>
						</PriceWrapper>
					))}
					<ButtonWrapper>
						<Button type="button" onClick={() => helpers.push({ occupancy: 0, purchasePrice: 0, sellingPrice: 0 })}>
							{t("Add prices")}
						</Button>
					</ButtonWrapper>
				</PricesWrapper>
			)}
		/>
	);
};
