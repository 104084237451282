import { FormLabel, useDynamicConfig } from "@dgs/core";
import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { EmailLogAttachment } from "~shared/types/email";

interface Props {
	attachments: EmailLogAttachment[];
}

export const EmailLogDetailsAttachments: FC<Props> = ({ attachments }) => {
	const { apiUrl } = useDynamicConfig();
	const { t } = useTranslation();

	return (
		<>
			{attachments.map(
				(attachment): ReactNode =>
					attachment.id ? (
						<>
							<Link
								key={attachment.id}
								to={`${apiUrl}/files/${attachment.id}/download`}
								type="button"
								target="_blank"
								title={attachment.name}
							>
								{attachment.name}
							</Link>
							<br />
						</>
					) : (
						<>
							<FormLabel key={attachment.id}>{attachment.name + " (" + t("Deleted") + ")"}</FormLabel>
							<br />
						</>
					),
			)}
		</>
	);
};
