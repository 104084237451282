import { ContextMenu, ContextMenuItem, Id } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { tagService } from "~shared/api/tags";
import { DeleteModelWithUsagesDialog, useModelUsage } from "~shared/ui/modelUsage/DeleteModelWithUsagesDialog";

interface Props {
	tagId: Id;
	handleEdit: () => void;
	handleDelete: () => Promise<any>;
}

export const TagsContextMenu: FC<Props> = ({ tagId, handleEdit, handleDelete }) => {
	const { t } = useTranslation();
	const showUsageAction = useCallback(() => tagService.showUsage(tagId), [tagId]);
	const { handleUsage, ...usageModalProps } = useModelUsage(showUsageAction);

	return (
		<>
			<ContextMenu displayShortcutsAs="icon">
				<ContextMenuItem label={t("Edit")} action={handleEdit} title={t("Edit")} icon="edit" shortcut="primary" />
				<ContextMenuItem action={handleUsage} title={t("Delete")} label={t("Delete")} icon="delete" />
			</ContextMenu>
			<DeleteModelWithUsagesDialog
				usagePrefix={t("This tag")}
				confirmationBody={t("Are you sure you want to delete this tag?")}
				deleteAction={handleDelete}
				{...usageModalProps}
			/>
		</>
	);
};
