import {
	CheckboxField,
	CheckboxGroupField,
	DateField,
	DateTimeField,
	Icon,
	Id,
	MultiSelectField,
	SingleSelectField,
	TextField,
	TextLink,
	TextareaField,
	TimeField,
	apiIsOK,
	isEmail,
} from "@dgs/core";
import { Field, FieldProps } from "formik";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { guestService } from "~shared/api/guests";
import { RadioDataField } from "~shared/dataFields/RadioDataField";
import { DataFieldSettings, IDataFieldIndexResource } from "~shared/types/dataField";
import { IDataFieldOptionIndexResource } from "~shared/types/dataFieldOption";
import { DataFieldType } from "~shared/types/dataFieldType";
import { GuestViewDataFieldAttribute } from "~shared/types/guestView";
import { GuestDetailsResource, GuestIndexResource } from "~shared/types/newGuestTypes";
import { FileDataField } from "./FileDataField";

const debounceAsync = (func: (...args: any) => any, delay: number) => {
	let timeoutId: NodeJS.Timeout;

	return (...args: any[]) => {
		clearTimeout(timeoutId);

		return new Promise((resolve) => {
			timeoutId = setTimeout(async () => {
				const result = await func(...args);
				resolve(result);
			}, delay);
		});
	};
};
const StyledTextLink = styled(TextLink)`
	margin-bottom: 1rem;
	justify-content: start;
	width: max-content;
`;

interface Props {
	attribute: GuestViewDataFieldAttribute;
	required: boolean;
	guest: GuestDetailsResource;
	dataFields: IDataFieldIndexResource[];
	dataFieldSettings: DataFieldSettings | null;
}

export const GuestAttributeFieldDataField: FC<Props> = ({
	attribute,
	guest,
	dataFieldSettings,
	dataFields,
	required,
}) => {
	const { t } = useTranslation();
	const dataField = dataFields.find((dataField) => dataField.id === attribute.entityType);
	const [existentGuest, setExistentGuest] = useState<GuestIndexResource | null>(null);
	const validateEmail = useCallback(
		(emailAttributeId: Id) => async (email: string) => {
			if (!email) {
				return "";
			}
			if (email === guest.attributes.find((x) => x.id === emailAttributeId)?.value) {
				return "";
			}

			if (!isEmail(email)) {
				return t("Invalid email address");
			}

			const debouncedValidate = debounceAsync(async () => {
				const res = await guestService.validateEmail(email);

				if (apiIsOK(res)) {
					setExistentGuest(res.data.data.guest);
					if (res.data.data.guest) {
						return t("Email is already in use");
					}
				}
			}, 500);

			return await debouncedValidate();
		},
		[guest.attributes, t],
	);

	if (!dataField) {
		throw new Error("Unknown datafield");
	}

	const props = {
		label: dataField.name,
		name: attribute.id.toString(),
		required,
		placeholder: dataField.placeholder,
		description: dataField.description,
	};

	switch (dataField.type) {
		case DataFieldType.SELECT_FIELD:
			return (
				<SingleSelectField
					{...props}
					options={dataField.options}
					getLabel={(option: IDataFieldOptionIndexResource) => option.value}
					getValue={(option: IDataFieldOptionIndexResource) => option.id}
					clearable={true}
				/>
			);
		case DataFieldType.MULTI_SELECT_FIELD:
			return (
				<MultiSelectField
					{...props}
					options={dataField.options}
					getLabel={(option: IDataFieldOptionIndexResource) => option.value}
					clearable={true}
				/>
			);
		case DataFieldType.CHECKBOX_GROUP:
			return (
				<CheckboxGroupField
					{...props}
					options={dataField.options.map((x) => ({
						value: x.id,
						name: x.value,
					}))}
				/>
			);
		case DataFieldType.FILE:
			return <FileDataField {...props} guestId={guest.id} dataFieldId={dataField.id} />;
		case DataFieldType.DATE:
			return <DateField {...props} />;
		case DataFieldType.TIME:
			return <TimeField {...props} />;
		case DataFieldType.DATETIME:
			return <DateTimeField {...props} />;
		case DataFieldType.CHECKBOX:
			return <CheckboxField {...props} />;
		case DataFieldType.NUMBER:
			return <TextField type="number" {...props} />;
		case DataFieldType.CRYPT:
		case DataFieldType.TEXT:
			return <TextField {...props} />;
		case DataFieldType.EMAIL:
			return (
				<Field
					name={props.name}
					validate={dataFieldSettings?.email.id === attribute.entityType ? validateEmail(attribute.id) : undefined}
				>
					{({ meta }: FieldProps) => {
						return (
							<>
								<TextField {...props} />
								{meta.error && meta.touched && existentGuest && (
									<StyledTextLink prefix={<Icon icon="external" />} to={`/admin/guests/${existentGuest.id}`}>
										{t("Go to {{guestName}}", {
											guestName: `${existentGuest.firstName} ${existentGuest.lastName}`,
										})}
									</StyledTextLink>
								)}
							</>
						);
					}}
				</Field>
			);
		case DataFieldType.LONG_TEXT:
			return <TextareaField {...props} />;
		case DataFieldType.URL:
			return <TextField {...props} />;
		case DataFieldType.RADIO:
			return <RadioDataField {...props} dataField={dataField} />;
		case DataFieldType.PHONE:
			return <TextField type="tel" {...props} />;
	}
};
