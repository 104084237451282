import { DrawerAutoDataList, IDrawerDataListColumn, useCrudEntity } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { shopItemService } from "~admin/shared/api/shop";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { IShopItem, IShopItemFormValues, IShopItemSaveRequest } from "~shared/types/shop";
import { ShopItemDrawer } from "./ShopItemDrawer";
import { ShopItemListContextMenu } from "./ShopItemListContextMenu";

const mapValuesToRequest = (item: IShopItemFormValues): IShopItemSaveRequest => {
	return {
		id: item.id,
		price: item.price,
		depositInPercent: item.depositInPercent,
		depositEndDate: item.depositEndDate,
		minPerGuest: item.minPerGuest,
		maxPerGuest: item.maxPerGuest,
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		defaultTaxId: item.defaultTax!.id,
		taxIncluded: item.taxIncluded,
		contingent: item.contingent,
		taxes: item.taxes.map((taxArray) => ({
			taxId: taxArray.tax.id,
			countryId: taxArray.country.id,
			isBusiness: taxArray.isBusiness,
		})),
		translations: item.translations,
	};
};

export const ShopItemList: FC = () => {
	const { t } = useTranslation();
	const { fallbackLocale, locales } = useContentLocale();
	const crudOperations = useCrudEntity(shopItemService, {
		entityName: t("Shop item"),
		messages: {
			delete: t("Shop item was saved."),
			create: t("Shop item was saved."),
			update: t("Shop item was deleted."),
		},
	});

	const emptyShopItem: IShopItemFormValues = useMemo(
		() => ({
			id: "new",
			price: "",
			depositEndDate: null,
			taxIncluded: false,
			defaultTax: null,
			minPerGuest: null,
			maxPerGuest: null,
			enableDeposit: false,
			depositInPercent: null,
			contingent: null,
			taxes: [],
			translations: locales.reduce(
				(acc, locale) => ({
					...acc,
					[locale]: {
						name: "",
						description: "",
					},
				}),
				{},
			),
		}),
		[locales],
	);

	const dataListColumns: IDrawerDataListColumn<IShopItem>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: ("translations[" + fallbackLocale + "].name") as any,
				type: "value",
				render: (item) => item.translations[fallbackLocale].name,
			},
			{
				heading: t("Price"),
				valueKey: "price",
				type: "value",
			},
			{
				heading: t("Deposit in percent"),
				valueKey: "depositInPercent",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (item, handleOpen) => (
					<ShopItemListContextMenu
						itemId={item.id}
						handleEdit={handleOpen}
						handleDelete={() => crudOperations.deleteEntity(item.id)}
					/>
				),
			},
		],
		[t, fallbackLocale, crudOperations],
	);

	return (
		<DrawerAutoDataList
			columns={dataListColumns}
			service={shopItemService}
			translations={{ empty: t("No shop items available"), title: t("Shop items") }}
			renderCreateDrawer={(drawerProps) => (
				<ShopItemDrawer
					{...drawerProps}
					heading={t("Add shop item")}
					initialValues={emptyShopItem}
					onSubmit={(entityFormState) => crudOperations.createEntity(mapValuesToRequest(entityFormState))}
				/>
			)}
			renderUpdateDrawer={(entityResource, drawerProps) => (
				<ShopItemDrawer
					{...drawerProps}
					heading={t("Edit shop item")}
					initialValues={entityResource}
					onSubmit={(entityFormState) =>
						crudOperations.updateEntity(entityResource.id, mapValuesToRequest(entityFormState))
					}
				/>
			)}
		/>
	);
};
