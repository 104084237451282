import { mapEntityBlockFormToRequest, mapEntityBlockResourceToForm, validateEntityBlockForm } from "@dgs/core";
import { EmailImageBlockForm } from "./EmailImageBlockForm";
import { IEmailImageBlockForm, IEmailImageBlockView } from "./emailImageBlockTypes";
import { EmailImageBlockView } from "./EmailImageBlockView";

export const emailImageBlockView: IEmailImageBlockView = {
	View: EmailImageBlockView,
};

export const emailImageBlockForm: IEmailImageBlockForm = {
	blockType: "emailImageBlock",
	type: "entityBlock",
	Form: EmailImageBlockForm,
	Preview: EmailImageBlockView,
	emptyValue: null,
	emptyTranslatedValue: {
		title: "",
	},
	mapEditResourceToFormState: (resource, locales, fallbackLocale, emptyValue) =>
		mapEntityBlockResourceToForm(resource, locales, fallbackLocale, emptyValue),
	mapFormStateToRequest: (resource, form) => mapEntityBlockFormToRequest(resource.type, form),
	validate: validateEntityBlockForm,
};

export const emailImageBlock = {
	...emailImageBlockView,
	...emailImageBlockForm,
};
