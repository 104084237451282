import React, { FC } from "react";
import { Outlet, useParams } from "react-router-dom";
import styled from "styled-components";
import { GuestFilterProvider } from "~shared/guestFilters";
import { EmailSendOutActions } from "./EmailSendOutActions";
import { EmailSendOutProvider } from "./EmailSendOutProvider";
import { EmailSendOutSidebar } from "./EmailSendOutSidebar";

const Wrapper = styled.div<{ $hasActions: boolean }>`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: max-content ${({ $hasActions }) => ($hasActions ? "3fr 400px" : "1fr")};
`;

export const EmailSendOutPage: FC = () => {
	const { mailId } = useParams<"mailId">();

	return (
		<GuestFilterProvider listFilterKey="emailSendOutFilter">
			<EmailSendOutProvider>
				<Wrapper $hasActions={!!mailId}>
					<EmailSendOutSidebar />
					<Outlet />
					{mailId && <EmailSendOutActions />}
				</Wrapper>
			</EmailSendOutProvider>
		</GuestFilterProvider>
	);
};
