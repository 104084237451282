import { SingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

const options: string[] = ["Leads", "Accounts", "Contacts"];

export const ZohoModuleSelectField: FC = () => {
	const { t } = useTranslation();

	return (
		<SingleSelectField<string, string>
			options={options}
			label={t("Module")}
			name="module"
			getValue={(x) => x}
			getLabel={(x) => x}
		/>
	);
};
