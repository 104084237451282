import { ContextMenu, ContextMenuDeleteItem, ContextMenuLinkItem, Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { emailKeys, emailService } from "~shared/api/emails";
import { EmailListResource } from "~shared/types/email";

interface Props {
	email: EmailListResource;
}

export const EmailsListContextMenu: FC<Props> = ({ email }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();

	const handleDelete = useCallback(
		async (emailId: Id) => {
			const response = await emailService.remove(emailId);
			if (apiIsOK(response)) {
				await queryClient.invalidateQueries({ queryKey: emailKeys.index });
				showToast({
					body: t("Email was deleted."),
					title: t("Delete email"),
					type: "success",
				});
			}
		},
		[queryClient, showToast, t],
	);

	return (
		<ContextMenu displayShortcutsAs="icon">
			<ContextMenuLinkItem
				label={t("Edit")}
				title={t("Edit")}
				to={`../${email.id}/data`}
				icon="edit"
				shortcut="primary"
			/>
			<ContextMenuDeleteItem
				action={() => handleDelete(email.id)}
				title={t("Delete")}
				label={t("Delete")}
				heading={t("Delete")}
				labels={{ close: t("Close"), confirm: t("Confirm") }}
			>
				{t("Are you sure you want to delete this email?")}
			</ContextMenuDeleteItem>
		</ContextMenu>
	);
};
