import { useMemo } from "react";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { HotelTranslatableAttributes, IHotelResource } from "~shared/types/hotel";

export const createEmptyHotel = (locales: string[]): IHotelResource => {
	return {
		id: -1,
		name: "",
		street: "",
		city: "",
		zip: "",
		email: "",
		country: null,
		phone: "",
		internalRemark: "",
		translations: locales.reduce(
			(translations, locale) => {
				return {
					...translations,
					[locale]: { guestRemark: "" },
				};
			},
			{} as Record<string, HotelTranslatableAttributes>,
		),
		checkInTime: null,
		checkOutTime: null,
		lateCheckOutTime: null,
		currency: null,
	};
};

export const useEmptyHotel = (): IHotelResource => {
	const { locales } = useContentLocale();
	return useMemo(() => createEmptyHotel(locales), [locales]);
};
