import { Id } from "@dgs/core";

export const registrationFormKeys = {
	index: ["registration-forms"] as const,
	list: ["registration-forms", "list"] as const,
	options: ["registration-forms", "options"],
	detail: (formId: Id) => [...registrationFormKeys.index, `${formId}`] as const,
	showUsage: (registrationFormId: Id) => [...registrationFormKeys.index, `${registrationFormId}`, "usage"] as const,
	duplicate: (formId: Id) => [...registrationFormKeys.index, `${formId}`, "duplicate"] as const,
	settings: (formId: Id) => [...registrationFormKeys.index, `${formId}`, "settings"] as const,
	details: (formId: Id) => [...registrationFormKeys.detail(formId), "details"] as const,
	dataFieldsOptions: (formId: Id) => [...registrationFormKeys.detail(formId), "data-fields", "options"] as const,
	steps: (formId: Id) => [...registrationFormKeys.detail(formId), "steps"] as const,
	summaryStep: (formId: Id) => [...registrationFormKeys.steps(formId), "summary"] as const,
	landingStep: (formId: Id) => [...registrationFormKeys.steps(formId), "landing"] as const,
	stepDetails: (formId: Id, stepId: Id) => [...registrationFormKeys.steps(formId), `${stepId}`] as const,
	stepOrder: (formId: Id) => [...registrationFormKeys.steps(formId), "order"] as const,
	stepChildrenOrder: (formId: Id, stepId: Id) =>
		[...registrationFormKeys.stepDetails(formId, stepId), "children", "order"] as const,
	sections: (formId: Id, stepId: Id) => [...registrationFormKeys.steps(formId), `${stepId}`, "sections"] as const,
	sectionDetails: (formId: Id, stepId: Id, sectionId: Id) =>
		[...registrationFormKeys.sections(formId, stepId), `${sectionId}`] as const,
	dataFieldValidationRules: (formId: Id, stepId: Id, sectionId: Id, dataFieldId: Id) =>
		[...registrationFormKeys.sectionDetails(formId, stepId, sectionId), "validations", `${dataFieldId}`] as const,
	sectionChildrenOrder: (formId: Id, stepId: Id, sectionId: Id) =>
		[...registrationFormKeys.sectionDetails(formId, stepId, sectionId), "children", "order"] as const,
	dataFields: (formId: Id, stepId: Id, sectionId: Id) =>
		[...registrationFormKeys.sections(formId, stepId), sectionId, "data-fields"] as const,
	syncDataFields: (formId: Id, stepId: Id, sectionId: Id) =>
		[...registrationFormKeys.sectionDetails(formId, stepId, sectionId), "sync-data-fields"] as const,
	detachDataFields: (formId: Id, stepId: Id, sectionId: Id) =>
		[...registrationFormKeys.sectionDetails(formId, stepId, sectionId), "detach-data-fields"] as const,
	attachStepBlocks: (formId: Id, stepId: Id) =>
		[...registrationFormKeys.stepDetails(formId, stepId), "attach-blocks"] as const,
	detachStepBlocks: (formId: Id, stepId: Id) =>
		[...registrationFormKeys.stepDetails(formId, stepId), "detach-blocks"] as const,
	attachSectionBlocks: (formId: Id, stepId: Id, sectionId: Id) =>
		[...registrationFormKeys.sectionDetails(formId, stepId, sectionId), "attach-blocks"] as const,
	detachSectionBlocks: (formId: Id, stepId: Id, sectionId: Id) =>
		[...registrationFormKeys.sectionDetails(formId, stepId, sectionId), "detach-blocks"] as const,
	syncConsents: (formId: Id) => [...registrationFormKeys.detail(formId), "consents"],
	updateShopStep: (formId: Id) => [...registrationFormKeys.detail(formId), "shop"],
	showSectionUsage: (formId: Id, stepId: Id, sectionId: Id) =>
		[...registrationFormKeys.sectionDetails(formId, stepId, sectionId), "show-section-usage"] as const,
	showStepUsage: (formId: Id, stepId: Id) =>
		[...registrationFormKeys.stepDetails(formId, stepId), "show-step-usage"] as const,
	showDataFieldUsage: (formId: Id, stepId: Id, sectionId: Id, dataFieldId: Id) =>
		[
			...registrationFormKeys.sectionDetails(formId, stepId, sectionId),
			`${dataFieldId}`,
			"show-datafield-usage",
		] as const,
};
