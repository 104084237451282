import { Id, LaravelResponse, ListFetcherConfig, keyToUrl, mapFetcherConfigToAxiosConfig } from "@dgs/core";
import axios from "axios";
import { IGuestFileRequest, IGuestFileUpdateResource } from "~shared/types/guestFile";

export const guestFileKeys = {
	index: (guestId: Id) => ["guests", `${guestId}`, "guest-files"],
	details: (id: Id) => ["guest-files", `${id}`],
};

export const guestFileUrls = {
	indexByGuest: (guestId: Id) => keyToUrl(guestFileKeys.index(guestId)),
	details: (id: Id) => keyToUrl(guestFileKeys.details(id)),
};

const indexByGuest = (guestId: Id) => (config: ListFetcherConfig<any>) =>
	axios.get(guestFileUrls.indexByGuest(guestId), mapFetcherConfigToAxiosConfig(config));

const upload = (guestId: Id, request: IGuestFileRequest) => {
	const formData = new FormData();
	request.file && formData.append("file", request.file);
	formData.append("guestFileCategoryId", request.guestFileCategoryId.toString());
	return axios.post<LaravelResponse<IGuestFileUpdateResource>>(guestFileUrls.indexByGuest(guestId), formData);
};

const destroy = (id: Id) => axios.delete(guestFileUrls.details(id));

export const guestFileService = {
	indexByGuest,
	upload,
	destroy,
};
