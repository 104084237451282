import React, { FC, forwardRef } from "react";
import styled from "styled-components";
import {
	StructureBlock,
	StructureDataField,
	StructureSection,
	StructureSectionChild,
} from "~shared/types/registrationFormTypes";
import { BlockIcon } from "../blocks/BlockIcon";
import { RegistrationFormBlockContextMenu } from "../blocks/RegistrationFormBlockContextMenu";
import { DragIndicator } from "../common/DragIndicator";
import { StructureHeaderSpacer } from "../common/StructureHeaderSpacer";
import { RegistrationFormDataFieldContextMenu } from "../dataFields/RegistrationFormDataFieldContextMenu";
import { DataFieldValidationDetails } from "../dataFields/validation/DataFieldValidationDetails";
import { isBlock, isDataField, revertId } from "../registrationFormDndUtils";

export interface BaseSectionChildProps {
	isColliding?: boolean;
	registrationFormId: string;
	stepId: string;
	section: StructureSection;
	sectionChild: StructureSectionChild;
}

interface Props extends BaseSectionChildProps {
	style?: any;
	isActive?: boolean;
}

const Header = styled.div<{ $isActive: boolean; $isColliding: boolean }>`
	display: flex;
	gap: ${({ theme }) => theme.spacing(2)};
	padding: ${({ theme }) => theme.spacing(2)};
	align-items: center;
	background-color: ${({ theme, $isActive, $isColliding }) =>
		$isColliding
			? theme.colors.palette.warning.main.base
			: $isActive
			? theme.colors.palette.primary.main.base
			: theme.colors.palette.content.light.base};
	color: ${({ theme, $isActive, $isColliding }) =>
		$isColliding
			? theme.colors.palette.warning.main.contrast
			: $isActive
			? theme.colors.palette.primary.main.contrast
			: theme.colors.palette.content.light.contrast};
	border: 1px solid
		${({ theme, $isActive }) => ($isActive ? theme.colors.palette.primary.main.base : theme.colors.palette.grey[30])};
	border-radius: ${({ theme }) => theme.spacing(1)};
	transition: ${({ theme }) => theme.transitions.default};
`;

export const SectionChild = forwardRef<HTMLDivElement, Props>(function StepContainer(
	{ sectionChild, section, registrationFormId, stepId, isActive = false, isColliding = false, style, ...handlerProps },
	ref,
) {
	section = {
		...section,
		id: revertId(section.id),
	};

	sectionChild = {
		...sectionChild,
		id: revertId(sectionChild.id),
	};

	return (
		<div ref={ref} style={style}>
			<Header $isActive={isActive} $isColliding={isColliding}>
				<DragIndicator {...handlerProps} />
				{isBlock(sectionChild) && (
					<BlockSectionChild
						registrationFormId={registrationFormId}
						stepId={revertId(stepId)}
						section={section}
						sectionChild={sectionChild}
					/>
				)}
				{isDataField(sectionChild) && (
					<DataFieldSectionChild
						registrationFormId={registrationFormId}
						stepId={revertId(stepId)}
						section={section}
						sectionChild={sectionChild}
					/>
				)}
			</Header>
		</div>
	);
});

interface BlockSectionChildProps extends Omit<Props, "sectionChild"> {
	sectionChild: StructureBlock;
}

const BlockSectionChild: FC<BlockSectionChildProps> = ({ sectionChild, registrationFormId, stepId, section }) => {
	return (
		<>
			<BlockIcon />
			{sectionChild.name}
			<StructureHeaderSpacer />
			<RegistrationFormBlockContextMenu
				registrationFormId={registrationFormId}
				registrationFormStepId={stepId}
				registrationFormSectionId={section.sectionId}
				registrationFormBlockIdentifier={revertId(sectionChild.id)}
			/>
		</>
	);
};

interface DataFieldSectionChildProps extends Omit<Props, "sectionChild"> {
	sectionChild: StructureDataField;
}

const DataFieldSectionChild: FC<DataFieldSectionChildProps> = ({
	sectionChild,
	registrationFormId,
	stepId,
	section,
}) => {
	return (
		<>
			{sectionChild.name}
			<DataFieldValidationDetails validationRules={sectionChild.validationRules} />
			<StructureHeaderSpacer />
			<RegistrationFormDataFieldContextMenu
				registrationFormId={registrationFormId}
				registrationFormStepId={stepId}
				section={section}
				registrationFormDataField={sectionChild}
			/>
		</>
	);
};
