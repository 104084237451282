import { AxiosConfig, FCC, ImageLibraryProvider, UserPermissionProvider, useDynamicConfig } from "@dgs/core";
import React from "react";
import { HtmlTitle } from "~admin/title/HtmlTitle";
import { PMAddonsProvider } from "~shared/addons/PMAddonsProvider";
import { PMBlockProvider } from "~shared/blocks/PMBlockProvider";
import { ContentLocaleProvider } from "~shared/providers/ContentLocaleProvider";
import { GlobalGuestViewProvider } from "~shared/providers/GlobalGuestViewProvider";

export const ContentProviders: FCC = ({ children }) => {
	const { apiUrl } = useDynamicConfig();

	return (
		<GlobalGuestViewProvider guestViewKey="guestViewId">
			<UserPermissionProvider>
				<AxiosConfig apiUrl={apiUrl} basePath="/admin/auth" />
				<HtmlTitle />
				<PMAddonsProvider>
					<PMBlockProvider>
						<ImageLibraryProvider moduleUrl={`${apiUrl}/images`}>
							<ContentLocaleProvider>{(initialized) => initialized && children}</ContentLocaleProvider>
						</ImageLibraryProvider>
					</PMBlockProvider>
				</PMAddonsProvider>
			</UserPermissionProvider>
		</GlobalGuestViewProvider>
	);
};
