import { Button, CrudDrawer } from "@dgs/core";
import { useField } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EmailSendOutFormState } from "~shared/types/email";

interface Props {
	onSubmit: () => boolean;
	onClose: () => void;
	open: boolean;
	initialValues: EmailSendOutFormState;
}

const Explanation = styled.p`
	padding-bottom: 10px;
`;

const Disclaimer = styled.p`
	padding-bottom: 10px;
	color: ${({ theme }) => theme.colors.palette.danger.main.base};
`;

export const EmailSendOutDrawer: FC<Props> = ({ onSubmit, onClose, open, initialValues }) => {
	const { t } = useTranslation();
	const [{ value: showSendAt }] = useField<boolean>("showSendAt");
	const [{ value: sendAt }] = useField<Date | null>("sendAt");

	return (
		<CrudDrawer
			open={open}
			onSubmit={async () => onSubmit()}
			initialValues={initialValues}
			onClose={onClose}
			heading={t("Email send out")}
			footer={
				<Button type="button" onClick={onClose}>
					{t("Cancel")}
				</Button>
			}
			translations={{ save: t("Send") }}
			body={
				<>
					<Explanation>{t("Are you sure you want to send this email?")}</Explanation>
					{showSendAt && sendAt && (
						<Disclaimer>
							{t(
								"The selected email will be sent to the selected filtered addressees as it will be at the time of the scheduled sending. If something changes in the guest list or the content of the email until then, the number and content of the emails may differ from the preview.",
							)}
						</Disclaimer>
					)}
				</>
			}
		/>
	);
};
