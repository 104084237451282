import { AutoDataList, CardBody, FormattedDateTime, IDataListColumn, IconButton, Loading, Trigger } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { guestKeys, guestService } from "~shared/api/guests";
import { useGuestDetails } from "~shared/guests/details/GuestDetailProvider";
import { GuestEmailDrawer } from "~shared/guests/emails/GuestEmailDrawer";
import { BaseEmailLog } from "~shared/types/email";

const LoadingWrapper = styled(CardBody)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

interface GuestEmailsListProps {
	module?: "admin" | "nomination-manager";
}

export const GuestEmailsList: FC<GuestEmailsListProps> = ({ module = "admin" }) => {
	const { t } = useTranslation();
	const { guest, isLoading } = useGuestDetails();
	const columns: IDataListColumn<BaseEmailLog>[] = useMemo(
		() => [
			{
				heading: t("Date"),
				valueKey: "date",
				type: "value",
				render: (log) => <FormattedDateTime dateTime={log.date} />,
			},
			{
				heading: t("To"),
				valueKey: "to",
				type: "value",
			},
			{
				heading: t("Cc"),
				valueKey: "cc",
				type: "value",
			},
			{
				heading: t("Bcc"),
				valueKey: "bcc",
				type: "value",
			},
			{
				heading: t("Subject"),
				valueKey: "subject",
				type: "value",
			},
			{
				heading: t("Has attachments"),
				valueKey: "hasAttachments",
				type: "value",
				render: (log) => (log.hasAttachments ? t("Yes") : t("No")),
			},
		],
		[t],
	);

	if (!guest || isLoading)
		return (
			<LoadingWrapper>
				<Loading />
			</LoadingWrapper>
		);

	return (
		<AutoDataList
			heading={t("Emails")}
			to={(guestEmail) => `/${module}/emails/logs/${guestEmail.id}`}
			headerActions={
				<Trigger render={(props) => <GuestEmailDrawer {...props} guestId={guest.id} />}>
					{(props) => <IconButton {...props} title={t("Send email")} color="primary" size="small" icon="send" />}
				</Trigger>
			}
			empty={t("No sent emails available.")}
			columns={columns}
			queryKey={guestKeys.emails(guest.id)}
			fetcher={(config) => guestService.emails(guest.id, config)}
		/>
	);
};
