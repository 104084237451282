import { CheckboxField, TextField } from "@dgs/core";
import { useField } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { TPaymentProviderType } from "~shared/types/paymentProvider";

export const PaymentProviderConfigField: FC = () => {
	const { t } = useTranslation();
	const [{ value: type }] = useField<TPaymentProviderType>("type");

	if (type === "PayPal")
		return (
			<>
				<TextField name="config.clientId" label={t("Client id")} />
				<TextField name="config.clientSecret" label={t("Client secret")} />
				<CheckboxField name="config.inTestMode" label={t("In test mode")} />
			</>
		);

	if (type === "Stripe")
		return (
			<>
				<TextField name="config.apiKey" label={t("Api key")} />
			</>
		);

	if (type === "Mollie")
		return (
			<>
				<TextField name="config.apiKey" label={t("Api key")} />
			</>
		);

	if (type === "BMWCreditCard" || type === "BMWPayPal")
		return (
			<>
				<TextField name="config.apiKey" label={t("Api key")} />
				<TextField name="config.integratorId" label={t("Integrator id")} />
				<TextField name="config.merchantId" label={t("Merchant id")} />
				<TextField name="config.merchantDescription" label={t("Merchant description")} />
				<CheckboxField name="config.inTestMode" label={t("In test mode")} />
			</>
		);

	return null;
};
