import { LaravelResponse, PagedSingleSelectField, useDynamicConfig } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import React, { FC } from "react";
import { Country } from "~shared/types/country";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	clearable?: boolean;
	required?: boolean;
}

const countryFetcher = (apiUrl: string, AxiosRequestConfig?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<Country[]>>(`${apiUrl}/countries`, AxiosRequestConfig);

export const CountrySingleSelect: FC<Props> = ({ name, label, disabled, clearable, required = false }) => {
	const { apiUrl } = useDynamicConfig();

	return (
		<PagedSingleSelectField
			name={name}
			label={label}
			disabled={disabled}
			fetcher={(search, page) => countryFetcher(apiUrl, { params: { search, page } }).then((r) => r.data)}
			queryKey={["countries"]}
			clearable={clearable}
			required={required}
		/>
	);
};
