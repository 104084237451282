import {
	BlockSingleSelectField,
	Card,
	CardBody,
	CardFooter,
	CheckboxField,
	CheckboxFieldProps,
	DateTimeField,
	Loading,
	SubmitButton,
	TextField,
	useDynamicConfig,
	useIsAddonEnabled,
} from "@dgs/core";
import { Form, Formik, useField } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { QrCode } from "~admin/shared/ui/QrCode";
import { useRegistrationFormDetailsQuery } from "~shared/api/registrationForms";
import { EmailSingleSelect } from "~shared/selects/EmailSingleSelect";
import { IRegistrationFormDetails, RegistrationVisibility } from "~shared/types/registrationForm";
import { useRegistrationFormForm } from "../useRegistrationFormForm";
import { RegistrationFormVisibilitySelect } from "./RegistrationFormVisibilitySelect";

const Subtitle = styled.div`
	font-size: 1.25rem;
	margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
const CheckboxContainer = styled.div`
	margin-bottom: 1.5rem;
`;
const Divider = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.palette.grey[20]};
`;

const MustApproveShopOrdersCheckboxField: FC<CheckboxFieldProps> = (props) => {
	const [{ value: isShopEnabled }] = useField<boolean>("isShopEnabled");

	if (!isShopEnabled) {
		return null;
	}

	return <CheckboxField {...props} />;
};

export const RegistrationFormSettingsPage: FC = () => {
	const { registrationFormId = "" } = useParams<"registrationFormId">();
	const { t } = useTranslation();
	const isShopAddonEnabled = useIsAddonEnabled("shop");
	const isReplacementAddonEnabled = useIsAddonEnabled("replacement");
	const isScreeningEnabled = useIsAddonEnabled("guestScreening");
	const { data: registrationForm } = useRegistrationFormDetailsQuery(registrationFormId);
	const { updateRegistrationFormSettings } = useRegistrationFormForm();
	const { frontendUrl } = useDynamicConfig();

	const onSubmit = useCallback(
		(registrationForm: IRegistrationFormDetails) =>
			updateRegistrationFormSettings(registrationForm.id, registrationForm),
		[updateRegistrationFormSettings],
	);

	const validate = useCallback(
		(values: IRegistrationFormDetails) => {
			const errors: Partial<IRegistrationFormDetails> = {};

			if (values.closeAt && values.closeAt < new Date().toISOString()) {
				errors.closeAt = t("Time must be in the future") as any;
			}

			if (values.isReplacementEnabled && !values.replacementInvitationMail) {
				errors.replacementInvitationMail = t("Field is required") as any;
			}

			return errors;
		},
		[t],
	);

	if (!registrationForm) {
		return <Loading />;
	}

	return (
		<Card>
			<Formik initialValues={registrationForm} onSubmit={onSubmit} validate={validate}>
				{(formik) => (
					<Form>
						<CardBody>
							<Subtitle>{t("General")}</Subtitle>
							<DateTimeField name="closeAt" label={t("Close at")} />
							<TextField label={t("Automatically close for X registrations")} name="contingent" type="number" />
							<CheckboxContainer>
								<CheckboxField name="isCancelable" label={t("Cancelable")} />
								<CheckboxField name="isEditable" label={t("Editable")} />
								{isShopAddonEnabled && <CheckboxField name="isShopEnabled" label={t("Enable shop functionality")} />}
								{isShopAddonEnabled && (
									<MustApproveShopOrdersCheckboxField
										name="mustApproveShopOrders"
										label={t("Must approve shop orders")}
									/>
								)}
								{isReplacementAddonEnabled && (
									<CheckboxField name="isReplacementEnabled" label={t("Enable replacement functionality")} />
								)}
							</CheckboxContainer>
							<RegistrationFormVisibilitySelect name="visibility" label={t("Visibility")} />
							{formik.values.visibility === RegistrationVisibility.PUBLIC && (
								<QrCode value={`${frontendUrl}/registrations/${registrationForm.id}`} />
							)}
							{formik.values.visibility === RegistrationVisibility.PRIVATE && (
								<>
									<CheckboxField name="isCodePageEnabled" label={t("Code page enabled")} />
									{formik.values.isCodePageEnabled && (
										<BlockSingleSelectField
											name="codePageBlock"
											label={t("Code page block")}
											blockType="pageBlock"
											clearable
										/>
									)}
								</>
							)}
						</CardBody>
						<Divider />
						<CardBody>
							<Subtitle>{t("Mails")}</Subtitle>
							<EmailSingleSelect name="registeredMail" label={t("Registered")} />
							<EmailSingleSelect name="editedMail" label={t("Edited")} />
							<EmailSingleSelect name="declinedMail" label={t("Declined")} />
							<EmailSingleSelect name="cancelledMail" label={t("Cancelled")} />
							{isShopAddonEnabled && (
								<>
									<EmailSingleSelect name="invoiceMail" label={t("Invoice")} />
									<EmailSingleSelect name="paymentMail" label={t("Payment")} />
									<EmailSingleSelect name="remainingPaymentMail" label={t("Remaining payment")} />
									<EmailSingleSelect name="refundConfirmationMail" label={t("Refund confirmation mail")} />
								</>
							)}
							{isReplacementAddonEnabled && (
								<>
									<EmailSingleSelect
										name="replacementNominatedMail"
										label={t("Confirmation email when a replacement has been appointed")}
									/>
									<EmailSingleSelect
										name="replacementInvitationMail"
										label={t("Invitation email to the replacement")}
									/>
								</>
							)}
							{isScreeningEnabled && (
								<EmailSingleSelect
									name="screeningStatusPositiveEmail"
									label={t("Email for positive screening status")}
								/>
							)}
						</CardBody>
						<Divider />
						<CardBody>
							<Subtitle>{t("Pages")}</Subtitle>
							<BlockSingleSelectField name="closedBlock" label={t("Closed")} blockType="pageBlock" clearable />
							<BlockSingleSelectField
								name="privateBlock"
								label={t("Private registration information")}
								blockType="pageBlock"
								clearable
							/>
							<BlockSingleSelectField name="successBlock" label={t("Registered")} blockType="pageBlock" clearable />
							<BlockSingleSelectField name="editedBlock" label={t("Edited")} blockType="pageBlock" clearable />
							<BlockSingleSelectField
								name="confirmDeclinationBlock"
								label={t("Confirm declination")}
								blockType="pageBlock"
								clearable
							/>
							<BlockSingleSelectField name="declinedBlock" label={t("Declined")} blockType="pageBlock" clearable />
							<BlockSingleSelectField
								name="confirmCancellationBlock"
								label={t("Confirm cancellation")}
								blockType="pageBlock"
								clearable
							/>
							<BlockSingleSelectField name="cancelledBlock" label={t("Cancelled")} blockType="pageBlock" clearable />
							{isReplacementAddonEnabled && (
								<>
									<BlockSingleSelectField
										name="confirmReplacementBlock"
										label={t("Page to designate the replacement")}
										blockType="pageBlock"
										clearable
									/>
									<BlockSingleSelectField
										name="replacedBlock"
										label={t("Confirmation page when a replacement has been appointed")}
										blockType="pageBlock"
										clearable
									/>
								</>
							)}
							{isScreeningEnabled && (
								<BlockSingleSelectField
									name="screeningStatusPositiveBlock"
									label={t("Page for positive screening status")}
									blockType="pageBlock"
									clearable
								/>
							)}
							{formik.values.mustApproveShopOrders && (
								<BlockSingleSelectField
									name="paymentSuccessfulBlock"
									label={t("Payment for approved shop order successful")}
									blockType="pageBlock"
									clearable
								/>
							)}
						</CardBody>
						<CardFooter>
							<SubmitButton>{t("Save")}</SubmitButton>
						</CardFooter>
					</Form>
				)}
			</Formik>
		</Card>
	);
};
