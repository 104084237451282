import { useQuery } from "@tanstack/react-query";
import { productTranslationsKeys, productTranslationsService } from "./productTranslationsService";

export const useProductTranslationsQuery = () => {
	const { data, isLoading } = useQuery({
		queryKey: productTranslationsKeys.details,
		queryFn: () => productTranslationsService.get().then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};
