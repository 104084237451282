import { PagedSingleSelectField, SelectFetcher } from "@dgs/core";
import React, { FC } from "react";
import { dataFieldService } from "~shared/api/dataFields";
import { IDataFieldOptionResource } from "~shared/types/dataField";
import { DataFieldType } from "~shared/types/dataFieldType";

interface Props {
	label: string;
	name: string;
	required?: boolean;
}

const fetcher: SelectFetcher<IDataFieldOptionResource> = async (search, page) => {
	const response = await dataFieldService.options(search, page, { type: DataFieldType.FILE });
	return response.data;
};

export const FileDataFieldSelect: FC<Props> = (props) => {
	return (
		<PagedSingleSelectField
			{...props}
			fetcher={fetcher}
			queryKey={[...dataFieldService.keys.options, DataFieldType.FILE]}
		></PagedSingleSelectField>
	);
};
