import { useQuery } from "@tanstack/react-query";
import { GuestViewAttributeResource } from "~shared/types/guestView";
import { guestAttributeKeys, guestAttributeService } from "./guestAttributeService";

const initialGuestAttributes: GuestViewAttributeResource[] = [];

export const useGuestAttributesQuery = () => {
	const { data, isLoading } = useQuery({
		queryKey: guestAttributeKeys.index,
		queryFn: () => guestAttributeService.index().then((r) => r.data.data),
	});

	return {
		guestAttributes: data ?? initialGuestAttributes,
		isLoading,
	};
};
