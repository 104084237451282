import { AutoDataList, FormattedDateTime, IDataListColumn, IconButton } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { locationService } from "~admin/shared/api/locations";
import { CheckInOutLog } from "~admin/shared/types/location";
import { ActiveGuestFilterEntries, useGuestFilter } from "~shared/guestFilters";
import { useGuestListUtils } from "~shared/guests";
import { LocalGuestViewSwitcher } from "~shared/guests/GuestViewSwitchers";
import { useGuestView } from "~shared/providers/useGuestView";
import { LogExportButton } from "./LogExportButton";

export const LogList: FC = () => {
	const { t } = useTranslation();
	const { locationId = "" } = useParams<"locationId">();
	const { currentGuestView } = useGuestView();
	const { filters, mapGuestFilterToGuestFilterRequest, handleOpen } = useGuestFilter();
	const { columns: guestAttributeColumns } = useGuestListUtils(currentGuestView);

	const columns: IDataListColumn<CheckInOutLog>[] = useMemo(
		() => [
			{
				heading: t("Type"),
				valueKey: "type",
				type: "value",
			},
			{
				heading: t("Timestamp"),
				valueKey: "createdAt",
				type: "value",
				render: (guestImport) => <FormattedDateTime dateTime={guestImport.createdAt} />,
			},
			...(guestAttributeColumns as IDataListColumn<CheckInOutLog>[]),
		],
		[t, guestAttributeColumns],
	);

	return (
		<AutoDataList
			name="locationLogs"
			heading={t("Check-in/out logs")}
			headerChildren={<ActiveGuestFilterEntries />}
			headerActions={
				<>
					<LocalGuestViewSwitcher />
					<IconButton icon="filter" size="small" title={t("Guest filter")} onClick={handleOpen} />
					<LogExportButton filters={filters} locationId={locationId} guestViewId={currentGuestView.id} />
				</>
			}
			empty={t("No logs available.")}
			columns={columns}
			queryKey={locationService.keys.logsFilter(locationId, currentGuestView.id, filters)}
			fetcher={(config) =>
				locationService.logs(locationId, currentGuestView.id, mapGuestFilterToGuestFilterRequest(filters), config)
			}
			initialSearch={{ search: "", searchFields: ["type"] }}
		/>
	);
};
