import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { roomTypeService } from "~admin/shared/api/roomTypes/roomTypeService";
import { IRoomTypeResource } from "~shared/types/roomType";
import { FormikHelpersWithRedirect } from "~shared/ui/FormikWithRedirect";

export const useRoomTypeForm = () => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const createRoomType = useCallback(
		async (
			hotelId: Id,
			{ customEmailForBookingOverview, customEmailForRequester, groups, ...roomType }: IRoomTypeResource,
			helpers: FormikHelpersWithRedirect<IRoomTypeResource>,
		) => {
			const response = await roomTypeService.createRoomType(hotelId, {
				...roomType,
				customEmailIdForBookingOverview: customEmailForBookingOverview?.id ?? null,
				customEmailIdForRequester: customEmailForRequester?.id ?? null,
				groupIds: groups.map((x) => x.id),
			});

			if (apiIsOK(response)) {
				showToast({
					body: t("Room type was successfully created."),
					title: t("Room type"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: roomTypeService.keys.index });
				await queryClient.invalidateQueries({ queryKey: roomTypeService.keys.indexByHotel(hotelId) });
				helpers.setTo("./..");

				return true;
			}
			return false;
		},
		[showToast, t, queryClient],
	);

	const updateRoomType = useCallback(
		async (
			hotelId: Id,
			{ customEmailForBookingOverview, customEmailForRequester, groups, ...roomType }: IRoomTypeResource,
			helpers: FormikHelpersWithRedirect<IRoomTypeResource>,
		) => {
			const response = await roomTypeService.update(roomType.id, {
				...roomType,
				customEmailIdForBookingOverview: customEmailForBookingOverview?.id ?? null,
				customEmailIdForRequester: customEmailForRequester?.id ?? null,
				groupIds: groups.map((x) => x.id),
			});

			if (apiIsOK(response)) {
				showToast({
					body: t("Room type was successfully updated."),
					title: t("Room type"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: roomTypeService.keys.index });
				await queryClient.invalidateQueries({ queryKey: roomTypeService.keys.details(roomType.id) });
				await queryClient.invalidateQueries({ queryKey: roomTypeService.keys.indexByHotel(hotelId) });
				helpers.setTo("./..");

				return true;
			}
			return false;
		},
		[queryClient, showToast, t],
	);

	const deleteRoomType = useCallback(
		async (hotelId: Id, id: Id) => {
			const response = await roomTypeService.destroy(id);
			if (apiIsOK(response)) {
				showToast({
					body: t("Room type was successfully deleted."),
					title: t("Room type"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: roomTypeService.keys.index });
				await queryClient.invalidateQueries({ queryKey: roomTypeService.keys.indexByHotel(hotelId) });

				return true;
			}

			return false;
		},
		[queryClient, showToast, t],
	);

	return { createRoomType, updateRoomType, deleteRoomType };
};
