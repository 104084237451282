import { BaseCrudOptionService, IOptionsKeys, IOptionsUrls, Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { IRegistrationFormIndex, IRegistrationFormRequest } from "~shared/types/registrationForm";
import { RegistrationFormStructureResponse } from "~shared/types/registrationFormTypes";

export class RegistrationFormStructureService extends BaseCrudOptionService<
	IRegistrationFormIndex,
	IRegistrationFormIndex,
	IRegistrationFormIndex,
	IRegistrationFormRequest
> {
	protected urls: IOptionsUrls & { structure: (id: Id) => string };
	public keys: IOptionsKeys & { structure: (id: Id) => string[] };

	constructor() {
		super();
		const base = ["registration-forms"];

		this.keys = {
			index: base,
			list: [...base, "list"],
			options: [...base, "options"],
			details: (id: Id) => [...base, `${id}`],
			structure: (id: Id) => [...base, `${id}`, "structure"],
		};

		this.urls = {
			index: keyToUrl(this.keys.index),
			options: keyToUrl(this.keys.options),
			details: (id: Id) => keyToUrl(this.keys.details(id)),
			structure: (id: Id) => keyToUrl(this.keys.structure(id)),
		};
	}

	structure(id: Id) {
		return axios.get<LaravelResponse<RegistrationFormStructureResponse>>(this.urls.structure(id));
	}
}

export const registrationFormStructureService = new RegistrationFormStructureService();
