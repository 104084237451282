import { CrudDrawer, TextField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRegistrationFormStep } from "./useRegistrationFormStep";

interface Props {
	registrationFormId: string;
	open: boolean;
	onClose: () => void;
}

export const CreateRegistrationFormStepDrawer: FC<Props> = ({ registrationFormId, open, onClose }) => {
	const { t } = useTranslation();
	const { createRegistrationFormStep, validate } = useRegistrationFormStep(registrationFormId);

	return (
		<CrudDrawer
			open={open}
			initialValues={{ name: "" }}
			onClose={onClose}
			onSubmit={async (values) => {
				const success = await createRegistrationFormStep(values);

				if (success) {
					onClose();
				}

				return success;
			}}
			heading={t("Create step")}
			validate={validate}
			body={<TextField name="name" label={t("Name")} />}
		/>
	);
};
