import { AutoDataList, FormattedDateTime, IDataListColumn, IconLinkButton } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { emailKeys, emailService } from "~shared/api/emails";
import { SendOut } from "~shared/types/email";
import { EmailSendOutContextMenu } from "./EmailSendOutContextMenu";

export const EmailSendOutList: FC = () => {
	const { t } = useTranslation();
	const dataListColumns: IDataListColumn<SendOut>[] = useMemo(
		() => [
			{
				heading: t("Email"),
				valueKey: "customEmail",
				type: "value",
				render: (sendOut) => sendOut.customEmail.name,
			},
			{
				heading: t("Status"),
				valueKey: "status",
				type: "value",
			},
			{
				heading: t("Failed recipients"),
				valueKey: "failedReceiverCount",
				type: "value",
				size: "max-content",
				render: (sendOut) => sendOut.failedReceiverCount,
			},
			{
				heading: t("Successful recipients"),
				valueKey: "successfulReceiverCount",
				type: "value",
				size: "max-content",
				render: (sendOut) => sendOut.successfulReceiverCount,
			},
			{
				heading: t("Created by"),
				valueKey: "creator",
				type: "value",
				render: (sendOut) => sendOut.creator.name,
			},
			{
				heading: t("Finished at"),
				valueKey: "finishedAt",
				type: "value",
				render: (sendOut) => <FormattedDateTime dateTime={sendOut.finishedAt} />,
			},
			{
				heading: t("Send at"),
				valueKey: "sendAt",
				type: "value",
				render: (sendOut) => <FormattedDateTime dateTime={sendOut.sendAt} />,
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (sendOut) => <EmailSendOutContextMenu sendOut={sendOut} />,
			},
		],
		[t],
	);

	return (
		<AutoDataList
			heading={t("Send outs")}
			empty={t("No send outs available")}
			headerActions={<IconLinkButton icon="plus" to="../send" color="primary" size="small" title={t("Send email")} />}
			columns={dataListColumns}
			queryKey={emailKeys.sendOuts.index}
			fetcher={emailService.sendOuts.index}
			to={(value) => `../${value.id}`}
		/>
	);
};
