import React, { FC } from "react";
import { Navigate } from "react-router-dom";
import { useNominationManagerConfig } from "./useNominationManagerConfig";

export const NavigateToStartPage: FC = () => {
	const { settings } = useNominationManagerConfig();

	if (settings.startPage) {
		return <Navigate to={`../blocks/${settings.startPage.slug}`} replace />;
	} else {
		return <Navigate to="../guests" replace />;
	}
};
