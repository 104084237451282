import {
	mapBaseBlockFormToRequest,
	mapBaseBlockResourceToForm,
	validateBlockForm,
	validateTranslationValues,
} from "@dgs/core";
import { EmailTextBlockForm } from "./EmailTextBlockForm";
import { IEmailTextBlockForm, IEmailTextBlockTranslatedValue, IEmailTextBlockView } from "./emailTextBlockTypes";
import { EmailTextBlockView } from "./EmailTextBlockView";

const emptyTranslatedValue: IEmailTextBlockTranslatedValue = {
	slateData: [{ type: "paragraph", children: [{ text: "" }] }],
};

export const emailTextBlockView: IEmailTextBlockView = {
	View: EmailTextBlockView,
};

export const emailTextBlockForm: IEmailTextBlockForm = {
	blockType: "emailTextBlock",
	type: "block",
	Form: EmailTextBlockForm,
	Preview: EmailTextBlockView,
	emptyValue: null,
	emptyTranslatedValue,
	mapEditResourceToFormState: (resource, locales, fallbackLocale, emptyValue) =>
		mapBaseBlockResourceToForm(resource, locales, fallbackLocale, emptyValue),
	mapFormStateToRequest: (resource, form) =>
		mapBaseBlockFormToRequest(
			resource.type,
			form,
			(x) => x,
			(x) => ({
				...x,
				slateData: JSON.stringify(x.slateData),
			}),
		),
	validate: (formState, t, fallbackLocale) => {
		return {
			...validateBlockForm(formState, t),
			...validateTranslationValues(formState, fallbackLocale, (value, valueErrors) => {
				if (value.slateData === emptyTranslatedValue.slateData) {
					valueErrors.slateData = { slateData: t("Required field") };
				}
			}),
		};
	},
};

export const emailTextBlock = {
	...emailTextBlockView,
	...emailTextBlockForm,
};
