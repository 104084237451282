import {
	BlockMetaProvider,
	FCC,
	getFrontendTheme,
	useBrandQuery,
	useBrandTheme,
	useDynamicConfig,
	useIsAddonEnabled,
} from "@dgs/core";
import React from "react";
import { config } from "~shared/config";
import { aspectOptions } from "~shared/defaultOptions";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";

export const PMBlockMetaProvider: FCC<{ scopes?: string }> = ({ children, scopes }) => {
	const isProEnabled = useIsAddonEnabled("cmsPro");
	const { apiUrl } = useDynamicConfig();
	const { locales, fallbackLocale } = useContentLocale();
	const { data } = useBrandQuery();
	const theme = getFrontendTheme(config.mode);
	const brandTheme = useBrandTheme(config.frontendThemeName, theme, data);

	return (
		<BlockMetaProvider
			apiUrl={apiUrl}
			mode={config.mode}
			scopes={scopes}
			locales={locales}
			fallbackLocale={fallbackLocale}
			config={{
				frontendTheme: brandTheme,
				aspectRatioOptions: aspectOptions,
				isProEnabled,
			}}
		>
			{children}
		</BlockMetaProvider>
	);
};
