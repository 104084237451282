import { LaravelResponse, PagedSingleSelectField } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import React, { FC } from "react";
import { IRegistrationFormStep } from "~shared/types/step";

interface Props {
	registrationFormId: string;
	name: string;
	label: string;
	disabled?: boolean;
}

const stepFetcher = (registrationFormId: string, config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<IRegistrationFormStep[]>>(`registration-forms/${registrationFormId}/steps/options`, config);

export const StepPagedSingleSelect: FC<Props> = ({ registrationFormId, name, label, disabled }) => {
	return (
		<PagedSingleSelectField
			name={name}
			label={label}
			fetcher={(search, page) => stepFetcher(registrationFormId, { params: { search, page } }).then((r) => r.data)}
			queryKey={["registration-forms", registrationFormId, "steps", "options"]}
			disabled={disabled}
		/>
	);
};
