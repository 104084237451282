import { Id } from "@dgs/core";
import { IDataFieldOptionIndexResource } from "~shared/types/dataFieldOption";
import { DataFieldType } from "~shared/types/dataFieldType";

interface NewValue {
	id: Id;
}

export type ImportErrorNewValue = NewValue | NewValue[] | Date | string | number | null;

export interface ImportErrorListBulkEditValues {
	entity: ImportErrorListBulkEditOption | null;
	newValue: ImportErrorNewValue;
}

export interface ImportErrorListBulkEditRequest {
	entityType: ImportErrorListBulkEditEntityType;
	dataFieldId: Id | null;
	newValue: Id[] | Id | null;
}

export interface ImportErrorListBulkEditOption {
	name: string;
	entityType: ImportErrorListBulkEditEntityType;
	id?: Id;
	type?: DataFieldType;
	options?: IDataFieldOptionIndexResource[];
}

export enum ImportErrorListBulkEditEntityType {
	groups = "groups",
	tags = "tags",
	status = "status",
	registrationForm = "registrationForm",
	dataField = "dataField",
	tenant = "tenant",
	wave = "wave",
	id = "id",
	registrationLocale = "registrationLocale",
	registrationCode = "registrationCode",
}
