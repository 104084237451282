import { IDataListColumn, Id, Label, useFormatDate, useFormatDateTime } from "@dgs/core";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useDataFieldsQuery } from "~shared/api/dataFields";
import { getFilenameFromPath } from "~shared/guests/details/FileDataField";
import { GuestScreeningStatusValue } from "~shared/guests/screeningStatus/GuestScreeningStatusValue";
import { IDataFieldIndexResource } from "~shared/types/dataField";
import { DataFieldType } from "~shared/types/dataFieldType";
import { GuestScreeningStatus, GuestStatus } from "~shared/types/guest";
import {
	GuestViewAttributeResource,
	GuestViewColumnAttribute,
	GuestViewConfigResource,
	GuestViewDataFieldAttribute,
	GuestViewRelationAttribute,
} from "~shared/types/guestView";
import {
	GuestAttribute,
	GuestColumnAttribute,
	GuestDataFieldAttribute,
	GuestIndexResource,
	GuestRelationAttribute,
} from "~shared/types/newGuestTypes";

const largeFields: DataFieldType[] = [
	DataFieldType.TEXT,
	DataFieldType.CHECKBOX_GROUP,
	DataFieldType.MULTI_SELECT_FIELD,
	DataFieldType.NUMBER,
	DataFieldType.URL,
];

const getGuestListColumnSizes = (
	dataField: IDataFieldIndexResource,
): "1fr" | "2fr" | "3fr" | "4fr" | "5fr" | "max-content" | undefined => {
	if (dataField.type === DataFieldType.LONG_TEXT) {
		return "2fr";
	}
	if (largeFields.includes(dataField.type)) {
		return "1fr";
	}
	return "max-content";
};

function findGuestAttribute<T extends GuestAttribute>(id: Id) {
	return function (guestAttribute: GuestAttribute): guestAttribute is T {
		return id === guestAttribute.id;
	};
}

const emptyValue = "-";

const StyledLabel = styled(Label)`
	margin: ${({ theme }) => theme.spacing(1)};
`;

const mapGuestRelationAttributeRender = (relationAttribute?: GuestRelationAttribute) => {
	if (!relationAttribute) {
		return emptyValue;
	}

	switch (relationAttribute.entityType) {
		case "tags":
			return relationAttribute.value.length > 0
				? relationAttribute.value.map((value) => (
						<StyledLabel size="small" key={value.id} color={value.color}>
							{value.name}
						</StyledLabel>
				  ))
				: emptyValue;
		case "groups":
		case "agreedConsents":
			return relationAttribute.value.length > 0
				? relationAttribute.value.map((value) => value.name).join(", ")
				: emptyValue;
		case "companions":
			return relationAttribute.value.length > 0
				? relationAttribute.value.map((value) => `${value.firstName} ${value.lastName}`).join(", ")
				: emptyValue;
		case "registrationForm":
		case "tenant":
		case "waitingList":
		case "wave":
			return relationAttribute.value ? relationAttribute.value.name : emptyValue;
		case "companionParent":
		case "replacementParent":
		case "replacement":
			return relationAttribute.value
				? `${relationAttribute.value.firstName} ${relationAttribute.value.lastName}`
				: emptyValue;
		case "guestFiles":
			return relationAttribute.value.length > 0
				? relationAttribute.value
						.map((guestFile) => `${guestFile.guestFileCategoryName}: ${guestFile.filename}`)
						.join(", ")
				: emptyValue;
	}
};

export const useGuestListUtils = (currentGuestView: GuestViewConfigResource) => {
	const { t } = useTranslation();
	const { data } = useDataFieldsQuery();
	const formatDateTime = useFormatDateTime();
	const formatDate = useFormatDate();

	const resolveDataFieldValue = useCallback(
		(value: any, dataField: IDataFieldIndexResource) => {
			switch (dataField?.type) {
				case DataFieldType.CHECKBOX:
					return value ? t("Yes") : t("No");
				case DataFieldType.SELECT_FIELD:
				case DataFieldType.RADIO:
					return dataField.options.find((option) => `${option.id}` === `${value}`)?.value;
				case DataFieldType.DATETIME:
					return formatDateTime(value);
				case DataFieldType.DATE:
					return formatDate(value);
				case DataFieldType.CHECKBOX_GROUP:
				case DataFieldType.MULTI_SELECT_FIELD:
					const options =
						dataField.options && value ? dataField.options.filter((option) => value.includes(option.id)) : [];
					return options.map((option) => option.value).join(", ");
				case DataFieldType.FILE:
					return value ? getFilenameFromPath(value) : "";
				default:
					return value;
			}
		},
		[formatDate, formatDateTime, t],
	);

	const mapGuestColumnAttributeToColumn = useCallback(
		(attribute: GuestViewColumnAttribute): IDataListColumn<GuestIndexResource> => {
			return {
				heading: t(attribute.entityType),
				size: "1fr",
				valueKey: attribute.id as any,
				type: "value",
				sortable: true,
				render: (guest) => {
					const current = guest.attributes.find(findGuestAttribute<GuestColumnAttribute>(attribute.id));

					if (!current) {
						return emptyValue;
					}

					switch (current.entityType) {
						case "status":
							return t(current.value as GuestStatus);
						case "screeningStatus":
							const value = current.value as GuestScreeningStatus;
							return <GuestScreeningStatusValue small={true} screeningStatus={value} />;
						case "createdAt":
						case "updatedAt":
						case "registrationTimestamp":
							return formatDateTime(current.value);
						default:
							return current.value ? current.value : emptyValue;
					}
				},
			};
		},
		[formatDateTime, t],
	);

	const mapGuestDataFieldAttributeToColumn = useCallback(
		(attribute: GuestViewDataFieldAttribute): IDataListColumn<GuestIndexResource> => {
			const dataField = data.find((d) => attribute.entityType === d.id);
			const sortable =
				dataField?.type !== DataFieldType.MULTI_SELECT_FIELD && dataField?.type !== DataFieldType.CHECKBOX_GROUP;

			return {
				heading: t(dataField?.name as any),
				size: dataField ? getGuestListColumnSizes(dataField) : "1fr",
				valueKey: attribute.id as any,
				type: "value",
				sortable: sortable,
				render: (guest) => {
					const current = guest.attributes.find(findGuestAttribute<GuestDataFieldAttribute>(attribute.id));

					if (!current || !dataField || !current.value) {
						return emptyValue;
					}

					return resolveDataFieldValue(current.value, dataField) || emptyValue;
				},
			};
		},
		[data, resolveDataFieldValue, t],
	);

	const mapGuestRelationAttributeToColumn = useCallback(
		(attribute: GuestViewRelationAttribute): IDataListColumn<GuestIndexResource> => {
			return {
				heading: t(attribute.entityType),
				size: "1fr",
				valueKey: attribute.entityType as any,
				type: "value",
				render: (guest) => {
					const current = guest.attributes.find(findGuestAttribute<GuestRelationAttribute>(attribute.id));

					return mapGuestRelationAttributeRender(current);
				},
			};
		},
		[t],
	);

	const mapGuestAttributeToColumn = useCallback(
		(attribute: GuestViewAttributeResource) => {
			switch (attribute.type) {
				case "column":
					return mapGuestColumnAttributeToColumn(attribute);
				case "dataField":
					return mapGuestDataFieldAttributeToColumn(attribute);
				case "relation":
					return mapGuestRelationAttributeToColumn(attribute);
			}
		},
		[mapGuestColumnAttributeToColumn, mapGuestDataFieldAttributeToColumn, mapGuestRelationAttributeToColumn],
	);

	const columns = useMemo(() => {
		return currentGuestView.guestAttributes.map(mapGuestAttributeToColumn);
	}, [mapGuestAttributeToColumn, currentGuestView.guestAttributes]);

	return {
		columns,
		mapGuestAttributeToColumn,
	};
};
