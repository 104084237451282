import { useField } from "formik";
import React, { FC, useCallback } from "react";
import { EditSortableGuestAttributes } from "~admin/guestView/EditSortableGuestAttributes";
import { GuestViewAttributeResource } from "~shared/types/guestView";

interface Props {
	name: string;
}

export const EditSortableGuestAttributesField: FC<Props> = ({ name }) => {
	const [field, meta, helpers] = useField<GuestViewAttributeResource[]>(name);

	const onChange = useCallback(
		(value: GuestViewAttributeResource[]) => {
			const values = value.filter((v) => v);
			helpers.setValue(values);
		},
		[helpers],
	);

	return (
		<EditSortableGuestAttributes
			value={field.value}
			onChange={onChange}
			error={meta.touched ? meta.error : undefined}
		/>
	);
};
