import {
	I18nNameField,
	Shortcut,
	SlateTextInputField,
	TAllBlockTypes,
	colorStringToHexColor,
	useBlockFormTranslationValuePrefix,
} from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useTheme } from "styled-components";
import { useDataFieldsQuery } from "~shared/api/dataFields";

export const EmailTextBlockFormFields: FC<{ blockType: TAllBlockTypes }> = ({ blockType }) => {
	const { t } = useTranslation();
	const { data: dataFields } = useDataFieldsQuery();
	const valuePrefix = useBlockFormTranslationValuePrefix();
	const { colors } = useTheme();

	const shortcuts: Shortcut[] = useMemo(() => {
		const defaultShortCuts: Shortcut[] = [
			{
				label: t("Deep Link"),
				value: `{{$deepLink}}`,
			},
			{
				label: t("App Link"),
				value: `{{$appLink}}`,
			},
			{
				label: t("Payment Link"),
				value: `{{$paymentLink}}`,
			},
			{
				label: t("Guest summary"),
				value: `{{$guestSummary}}`,
			},
			{
				label: t("Guest companion summary"),
				value: `{{$companionSummary}}`,
			},
			{
				label: t("Hotel summary"),
				value: `{{$hotelSummary}}`,
			},
			{
				label: t("Personal salutation first name"),
				value: `{{$personalSalutationFirstName}}`,
			},
			{
				label: t("Personal salutation last name"),
				value: `{{$personalSalutationLastName}}`,
			},
			{
				label: t("Personal salutation full name"),
				value: `{{$personalSalutationFullName}}`,
			},
			{
				label: t("Formal salutation first name"),
				value: `{{$formalSalutationFirstName}}`,
			},
			{
				label: t("Formal salutation last name"),
				value: `{{$formalSalutationLastName}}`,
			},
			{
				label: t("Formal salutation full name"),
				value: `{{$formalSalutationFullName}}`,
			},
			{
				label: t("Modern salutation first name"),
				value: `{{$modernSalutationFirstName}}`,
			},
			{
				label: t("Modern salutation last name"),
				value: `{{$modernSalutationLastName}}`,
			},
			{
				label: t("Modern salutation full name"),
				value: `{{$modernSalutationFullName}}`,
			},
			{
				label: t("Full name of the companion initiator"),
				value: `{{$companionParentFullName}}`,
			},
			{
				label: t("Full name of the replacement initiator"),
				value: `{{$replacementParentFullName}}`,
			},
		];

		return defaultShortCuts.concat(
			dataFields.map((dataField) => ({
				label: dataField.name,
				value: `{{$${dataField.alias}}}`,
			})),
		);
	}, [dataFields, t]);

	return (
		<>
			<I18nNameField blockType={blockType} />
			<SlateTextInputField
				shortcuts={shortcuts}
				label={t("Text")}
				name={`${valuePrefix}.slateData`}
				predefinedColors={[
					colorStringToHexColor(colors.palette.primary.main.base),
					colorStringToHexColor(colors.palette.secondary.main.base),
				]}
			/>
		</>
	);
};
