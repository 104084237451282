import { MultiSelectField, SingleSelectField } from "@dgs/core";
import i18next from "i18next";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { GuestScreeningStatus } from "~shared/types/guest";

const defaultOptions: GuestScreeningStatus[] = [
	GuestScreeningStatus.NONE,
	GuestScreeningStatus.NEGATIVE_AUTOMATIC,
	GuestScreeningStatus.NEGATIVE_CONFIRMED,
	GuestScreeningStatus.POSITIVE_AUTOMATIC,
	GuestScreeningStatus.POSITIVE_CONFIRMED,
];

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	className?: string;
	clearable?: boolean;
	required?: boolean;
	options?: GuestScreeningStatus[];
}

export const GuestScreeningStatusSelect: FC<Props> = ({
	name,
	label,
	disabled,
	className,
	clearable,
	required,
	options = defaultOptions,
}) => {
	const { t } = useTranslation();

	return (
		<SingleSelectField
			options={options}
			getLabel={(option) => (i18next.exists(option) ? t(option) : option)}
			getValue={(option) => option}
			name={name}
			label={label}
			disabled={disabled}
			className={className}
			clearable={clearable}
			required={required}
		/>
	);
};

interface StatusMultiSelectProps {
	name: string;
	label: string;
	disabled?: boolean;
	className?: string;
	required?: boolean;
}

export const GuestScreeningStatusMultiSelect: FC<StatusMultiSelectProps> = ({
	name,
	label,
	disabled,
	className,
	required,
}) => {
	const { t } = useTranslation();

	return (
		<MultiSelectField
			options={defaultOptions}
			getLabel={(option) => (i18next.exists(option) ? t(option) : option)}
			name={name}
			label={label}
			disabled={disabled}
			className={className}
			required={required}
		/>
	);
};
