import React from "react";
import { BaseGuestImportForm } from "./BaseGuestImportForm";
import { useImportGuests } from "./ImportGuestProvider";
import { JarvisGuestImportForm } from "./JarvisGuestImportForm";
import { ZohoGuestImportForm } from "./ZohoGuestImportForm";

export const ImportDataPage = () => {
	const { importType } = useImportGuests();

	switch (importType) {
		case "jarvis":
			return <JarvisGuestImportForm />;
		case "zoho":
			return <ZohoGuestImportForm />;
		case "excel":
		default:
			return <BaseGuestImportForm additionalValues={{}} importProviderType="excel" />;
	}
};
