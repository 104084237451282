import { Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { menuItemService } from "./menuItemService";

export const useMenuItem = (menuItemId: Id) => {
	const { data: menuItem, isLoading } = useQuery({
		queryKey: menuItemService.keys.details(menuItemId),
		queryFn: () => menuItemService.show(menuItemId).then((r) => r.data.data),
	});

	return {
		menuItem,
		isLoading,
	};
};
