import {
	AutoDataList,
	ContextMenu,
	ContextMenuAnchorItem,
	ContextMenuDeleteItem,
	EntityCreateDrawerButton,
	IDataListColumn,
	Id,
	apiIsOK,
	useDynamicConfig,
	useToasts,
} from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { guestFileKeys, guestFileService } from "~shared/api/guestFile/guestFileService";
import { guestKeys } from "~shared/api/guests";
import { IGuestFileFormState, IGuestFileIndexResource, IGuestFileRequest } from "~shared/types/guestFile";
import { GuestFileDrawer } from "./GuestFileDrawer";

const mapToRequest = ({ guestFileCategory, file }: IGuestFileFormState): IGuestFileRequest => {
	if (!guestFileCategory) throw "Missing validation for field: guestFileCategory";
	if (!file) throw "Missing validation for field: file";
	return {
		file,
		guestFileCategoryId: guestFileCategory.id,
	};
};

export const GuestFileList: FC = () => {
	const { guestId = "" } = useParams<"guestId">();
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { apiUrl } = useDynamicConfig();
	const deleteGuestFile = useCallback(
		async (id: Id) => {
			const res = await guestFileService.destroy(id);
			if (apiIsOK(res)) {
				showToast({
					title: t("Guest file"),
					body: t("Guest file was deleted."),
					type: "success",
				});
				queryClient.invalidateQueries({ queryKey: guestFileKeys.index(guestId) });
			}
		},
		[guestId, queryClient, showToast, t],
	);
	const uploadGuestFile = useCallback(
		async (guestFile: IGuestFileRequest) => {
			const res = await guestFileService.upload(guestId, guestFile);
			if (apiIsOK(res)) {
				showToast({
					title: t("Guest file"),
					body: t("Guest file was uploaded."),
					type: "success",
				});
				queryClient.invalidateQueries({ queryKey: guestFileKeys.index(guestId) });
				queryClient.invalidateQueries({ queryKey: guestKeys.filtered });
				return true;
			}
			return false;
		},
		[guestId, queryClient, showToast, t],
	);
	const emptyGuestFile: IGuestFileFormState = {
		file: null,
		guestFileCategory: null,
	};
	const dataListColumns: IDataListColumn<IGuestFileIndexResource>[] = useMemo(
		() => [
			{
				heading: t("File name"),
				valueKey: "file.filename",
				type: "value",
			},
			{
				heading: t("Guest file category"),
				valueKey: "guestFileCategory.name",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuAnchorItem
							href={`${apiUrl}/guest-files/${id}`}
							target="_blank"
							shortcut="primary"
							icon="file_download"
							title={t("Download")}
							label={t("Download")}
						/>
						<ContextMenuDeleteItem
							action={() => deleteGuestFile(id)}
							title={t("Delete guest file")}
							label={t("Delete guest file")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to delete this guest file?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		],
		[deleteGuestFile, t, apiUrl],
	);

	return (
		<AutoDataList
			heading={t("Guest files")}
			columns={dataListColumns}
			fetcher={guestFileService.indexByGuest(guestId)}
			queryKey={guestFileKeys.index(guestId)}
			headerActions={
				<EntityCreateDrawerButton
					renderDrawer={(drawerProps) => (
						<GuestFileDrawer
							{...drawerProps}
							heading={t("Add guest file")}
							initialValues={emptyGuestFile}
							onSubmit={(entityFormState) => uploadGuestFile(mapToRequest(entityFormState))}
						/>
					)}
				/>
			}
			empty={t("No guest files available.")}
		/>
	);
};
