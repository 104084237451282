import { Id } from "@dgs/core";
import { IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";

export const emailKeys = {
	index: ["custom-emails"],
	indexAll: ["custom-emails", "all"],
	options: ["custom-emails", "options"],
	list: ["custom-emails", "list"],
	details: (mailId: Id) => [...emailKeys.index, `${mailId}`],
	sendOuts: {
		index: ["send-outs"],
		details: (sendOutId: Id) => [...emailKeys.sendOuts.index, `${sendOutId}`],
		filteredStatusGuests: (status: string, sendOutId: Id) => ["send-outs", `${sendOutId}`, status],
		filteredGuests: (status: string, sendOutId: Id, guestViewId: Id, filter: IGuestFilterFormValueEntry[]) => [
			"send-outs",
			`${sendOutId}`,
			`${status}`,
			`${guestViewId}`,
			JSON.stringify(filter),
		],
	},
	logs: {
		index: ["email-logs"],
		details: (logId: Id) => [...emailKeys.logs.index, `${logId}`],
		resend: (logId: Id) => ["email-logs", "resend", `${logId}`],
	},
};
