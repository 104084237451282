import { mapLayoutBlockFormToRequest, mapLayoutBlockResourceToForm, validateBlockForm } from "@dgs/core";
import { EmailBlockForm } from "./EmailBlockForm";
import { IEmailBlockForm, IEmailBlockView } from "./emailBlockTypes";
import { EmailBlockView } from "./EmailBlockView";

export const emailBlockView: IEmailBlockView = {
	View: EmailBlockView,
};

export const emailBlockForm: IEmailBlockForm = {
	blockType: "emailBlock",
	type: "layoutBlock",
	Form: EmailBlockForm,
	Preview: EmailBlockView,
	emptyTranslatedValue: null,
	emptyValue: {
		preview: "",
	},
	mapEditResourceToFormState: (resource, locales, fallbackLocale, emptyValue) =>
		mapLayoutBlockResourceToForm(resource, locales, fallbackLocale, emptyValue),
	mapFormStateToRequest: (resource, form) => mapLayoutBlockFormToRequest(resource.type, form),
	validate: validateBlockForm,
};

export const emailBlock = {
	...emailBlockView,
	...emailBlockForm,
};
