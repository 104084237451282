import { useToasts } from "@dgs/core";
import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useI18nForm } from "./I18nFormProvider";
import { TranslationObject } from "./i18nTypes";
import { hasTouchedField } from "./i18nUtils";

export const I18nErrorToast: FC = () => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const { currentLocale, isMainForm } = useI18nForm();
	const { values, errors, touched } = useFormikContext<{ translations: Array<TranslationObject>; [K: string]: any }>();
	const hasRegularErrors =
		!isMainForm &&
		Object.keys(errors).some((k) => {
			return k !== "translations" && touched[k];
		});
	const hasTranslationErrors = useMemo(() => {
		const translationErrors = values.translations.reduce(
			(acc, x, idx) => {
				const localeErrors = ((errors.translations as FormikErrors<TranslationObject>[]) ?? [])[idx];
				const localeTouched = ((touched.translations as FormikTouched<TranslationObject>[]) ?? [])[idx];

				return {
					...acc,
					[x.locale]: localeErrors && localeTouched && hasTouchedField(localeTouched),
				};
			},
			{} as Record<string, boolean>,
		);

		return Object.keys(translationErrors).some((locale) => locale !== currentLocale && translationErrors[locale]);
	}, [currentLocale, errors.translations, touched.translations, values.translations]);

	useEffect(() => {
		if (hasRegularErrors || hasTranslationErrors) {
			showToast({
				body: t("There are validation errors in other languages of this form."),
				title: t("Validation errors"),
				type: "error",
			});
		}
	}, [currentLocale, hasRegularErrors, showToast, t, hasTranslationErrors]);

	return null;
};
