import { CrudDrawer, useFormatDate } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
	IRoomContingentForm,
	IRoomContingentIndex,
	IRoomContingentPrice,
	IRoomContingentResource,
} from "~shared/types/roomContingent";
import { IRoomTypeResource } from "~shared/types/roomType";
import { RoomContingentFormFields } from "./contingent/RoomContingentForm";

interface Props {
	roomContingent: IRoomContingentIndex;
	roomType: IRoomTypeResource;
	onSubmit: (roomContingent: IRoomContingentResource) => void;
	open: boolean;
	handleClose: () => void;
}

export const RoomTypeDayDetailsDrawer: FC<Props> = ({ open, roomContingent, roomType, handleClose, onSubmit }) => {
	const { t } = useTranslation();
	const formatDate = useFormatDate();
	const handleSubmit = useCallback(
		async (roomContingent: IRoomContingentForm) => {
			onSubmit(roomContingent as IRoomContingentResource);
			return true;
		},
		[onSubmit],
	);

	const validate = useCallback(
		(values: IRoomContingentForm) => {
			const errors: { [K in keyof IRoomContingentForm]?: any } = {};

			if (values.contingent === null) {
				errors.contingent = t("Field is required");
			}

			if (values.contingent === 0 && !values.overbookable) {
				errors.contingent = t("The value can only be 0 when overbookable is enabled");
			}

			const priceErrors: { [K in keyof IRoomContingentPrice]?: any }[] = [];

			values.prices.forEach((price, idx) => {
				if (price.purchasePrice <= 0) {
					priceErrors[idx] = priceErrors[idx]
						? { ...priceErrors[idx], purchasePrice: t("Price needs to be larger than 0") }
						: { purchasePrice: t("Price needs to be larger than 0") };
				}
				if (price.sellingPrice <= 0) {
					priceErrors[idx] = priceErrors[idx]
						? { ...priceErrors[idx], sellingPrice: t("Price needs to be larger than 0") }
						: { sellingPrice: t("Price needs to be larger than 0") };
				}
				if (price.occupancy < 1) {
					priceErrors[idx] = priceErrors[idx]
						? { ...priceErrors[idx], occupancy: t("Occupancy needs to be larger than 0") }
						: { occupancy: t("Occupancy needs to be larger than 0") };
				} else if (price.occupancy > roomType.capacity) {
					priceErrors[idx] = priceErrors[idx]
						? {
								...priceErrors[idx],
								occupancy: t(
									"Occupancy needs to be smaller than or equal to the number of maximum persons the room type has.",
								),
						  }
						: {
								occupancy: t(
									"Occupancy needs to be smaller than or equal to the number of maximum persons the room type has.",
								),
						  };
				}
			});

			if (priceErrors.filter((x) => x).length > 0) {
				errors.prices = priceErrors;
			}

			return errors;
		},
		[roomType.capacity, t],
	);

	return (
		<CrudDrawer
			open={open}
			validate={validate}
			onSubmit={handleSubmit}
			body={<RoomContingentFormFields />}
			initialValues={roomContingent}
			onClose={handleClose}
			heading={formatDate(roomContingent.date)}
		/>
	);
};
