import { HasPermission } from "@dgs/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { FC } from "react";
import styled from "styled-components";
import { DragIndicator } from "~admin/registrationForms/structure/common/DragIndicator";
import { PermissionName } from "~admin/shared/types/permission";
import { ConsentListItem } from "~shared/types/consent";

const Wrapper = styled.div`
	${({ theme }) => theme.borderRadius.medium};
	border: 1px solid ${({ theme }) => theme.colors.palette.grey[20]};
	background-color: ${({ theme }) => theme.colors.palette.grey[10]};
	overflow: hidden;
`;

const Content = styled.span`
	display: grid;
	grid-template-columns: max-content 1fr;
	gap: ${({ theme }) => theme.spacing(2)};
	align-items: center;
	padding: ${({ theme }) => theme.spacing(2)};
`;

const Name = styled.span`
	font-size: ${({ theme }) => theme.fonts.sizes.small};
`;

interface Props {
	consent: ConsentListItem;
	forLandingPage: boolean;
}

export const SortableRegistrationFormConsent: FC<Props> = ({ consent }) => {
	const { transform, transition, setNodeRef, listeners, attributes, isDragging } = useSortable({
		id: consent.id,
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		opacity: isDragging ? 0.8 : 1,
	};

	return (
		<div ref={setNodeRef} style={style}>
			<Wrapper>
				<Content>
					<HasPermission<PermissionName> permissions={["consent.update"]}>
						<DragIndicator {...listeners} {...attributes} />
					</HasPermission>
					<Name>{consent.name}</Name>
				</Content>
			</Wrapper>
		</div>
	);
};
