import { BlockDetailsPage, LinkTab, LinkTabs, Loading } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import styled from "styled-components";
import { useEmailQuery } from "~shared/api/emails";
import { EditEmailDataForm } from "./EditEmailDataForm";

const CardWrapper = styled.div`
	height: 100%;
`;

const StyledDiv = styled.div`
	height: 100%;
	padding-bottom: 3rem;
`;

const CardHeader = styled.div`
	background-color: rgb(255, 255, 255);
	box-shadow:
		rgba(0, 0, 0, 0.12) 0px 1px 3px,
		rgba(0, 0, 0, 0.24) 0px 1px 2px;
`;

export const EmailEditPage: FC = () => {
	const { t } = useTranslation();
	const { emailId = "" } = useParams<"emailId">();
	const { data: email, isLoading } = useEmailQuery(emailId);

	if (!emailId || !email || isLoading) return <Loading />;

	return (
		<StyledDiv>
			<CardWrapper>
				<CardHeader>
					<LinkTabs>
						<LinkTab to="data">{t("Data")}</LinkTab>
						<LinkTab to={`content/${email.blockId}`}>{t("Content")}</LinkTab>
					</LinkTabs>
				</CardHeader>
				<Routes>
					<Route path="data" element={<EditEmailDataForm email={email} />} />

					<Route path="content/:id" element={<BlockDetailsPage />} />

					<Route index element={<Navigate to="data" />} />
				</Routes>
			</CardWrapper>
		</StyledDiv>
	);
};
