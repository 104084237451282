import React, { FC } from "react";
import styled from "styled-components";
import { EmailSendOutFilter } from "./EmailSendOutFilter";
import { EmailSendOutSendCard } from "./EmailSendOutSendCard";

const Wrapper = styled.div`
	display: grid;
	grid-template-rows: 1fr max-content;
	gap: ${({ theme }) => theme.spacing(5)};
`;

export const EmailSendOutActions: FC = () => {
	return (
		<Wrapper>
			<EmailSendOutFilter />
			<EmailSendOutSendCard />
		</Wrapper>
	);
};
