import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { hotelService } from "~admin/shared/api/hotels";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	clearable?: boolean;
}

const fetcher = (search: string, page: number) =>
	hotelService.options({ params: { search, page } }).then((r) => r.data);

export const HotelSingleSelect: FC<Props> = ({ name, label, disabled, clearable = false }) => {
	return (
		<PagedSingleSelectField
			name={name}
			label={label}
			disabled={disabled}
			fetcher={fetcher}
			clearable={clearable}
			queryKey={hotelService.keys.options}
		/>
	);
};
