import { FormattedDate, Icon, Id } from "@dgs/core";
import { t } from "i18next";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { IHotelResource } from "~shared/types/hotel";
import { IRoomContingentIndex } from "~shared/types/roomContingent";
import { IRoomTypeResource } from "~shared/types/roomType";

const StyledLink = styled(Link)`
	font-size: 1rem;
	display: inline-flex;
	height: 1.5rem;
	vertical-align: middle;
	align-items: center;
	margin-bottom: ${({ theme }) => theme.spacing(2)};
	margin-right: ${({ theme }) => theme.spacing(2)};
	color: inherit;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
`;

const Arrow = styled(Icon)`
	width: 16px;
	height: 16px;
	margin-right: ${({ theme }) => theme.spacing(2)};
`;

export const HotelBreadcrumb: FC<{ hotel: IHotelResource }> = ({ hotel }) => {
	return (
		<>
			<StyledLink to={`/admin/hotel-management/hotels`}>{t("Hotels")}</StyledLink>
			<StyledLink to={`/admin/hotel-management/hotels/${hotel.id}`}>
				<Arrow icon="caret" />
				{hotel.name}
			</StyledLink>
		</>
	);
};

export const RoomTypeBreadcrumb: FC<{ hotelId: Id; roomType: IRoomTypeResource }> = ({ hotelId, roomType }) => {
	return (
		<StyledLink to={`/admin/hotel-management/hotels/${hotelId}/room-types/${roomType.id}`}>
			<Arrow icon="caret" />
			{roomType.name}
		</StyledLink>
	);
};

export const ContingentBreadcrumb: FC<{ hotelId: Id; roomTypeId: Id; roomContingent: IRoomContingentIndex }> = ({
	hotelId,
	roomTypeId,
	roomContingent,
}) => {
	return (
		<StyledLink
			to={`/admin/hotel-management/hotels/${hotelId}/room-types/${roomTypeId}/contingent/${roomContingent.id}`}
		>
			<Arrow icon="caret" />
			<FormattedDate date={roomContingent.date} />
		</StyledLink>
	);
};
