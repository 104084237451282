import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { INominationModuleSettings, INominationModuleSettingsRequest } from "~shared/types/nominationModuleSettings";
import { nominationModuleSettingsKeys } from "./nominationModuleSettingsKeys";

export const nominationModuleSettingsUrls = {
	index: keyToUrl(nominationModuleSettingsKeys.index),
};

const index = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<INominationModuleSettings>>(nominationModuleSettingsUrls.index, config);

const update = (settings: INominationModuleSettingsRequest) =>
	axios.put<LaravelResponse<INominationModuleSettings>>(nominationModuleSettingsUrls.index, settings);

export const nominationModuleSettingsService = {
	index,
	update,
};
