import { Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { IBaseService } from "./baseService";

export const useShowQuery = <
	TEntityIndex,
	TEntityResource,
	TEntityEditRequest,
	TEntityCreateRequest = TEntityEditRequest,
>(
	id: Id,
	service: IBaseService<TEntityIndex, TEntityResource, TEntityEditRequest, TEntityCreateRequest>,
) => {
	const { data, isLoading } = useQuery({
		queryKey: service.keys.details(id),
		queryFn: () => service.show(id).then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};
