import { Id, Tag, useFormatDate, useFormatDateTime } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IDataFieldIndexResource } from "~shared/types/dataField";
import { useGuestFilter } from "./GuestFilterProvider";
import { GuestFilterAttributeType, IActiveGuestFilterEntry, IGuestFilterOperator } from "./guestFilterTypes";
import { useResolveInfoForGuestAttribute } from "./guestFilterUtils";

const StyledTag = styled(Tag)`
	margin: ${({ theme }) => theme.spacing(1)};
`;

interface Props {
	filter: IActiveGuestFilterEntry;
}

export const ActiveGuestFilterEntry: FC<Props> = ({ filter }) => {
	const { t } = useTranslation();
	const { handleOpen, removeFilter } = useGuestFilter();
	const guestAttributeInfo = useResolveInfoForGuestAttribute(filter.guestAttributeId);

	if (!guestAttributeInfo) return null;

	return (
		<StyledTag color="dark" onClose={() => removeFilter(guestAttributeInfo.attribute.id)} onClick={handleOpen}>
			{guestAttributeInfo.name}
			<span>
				{` ${t(filter.operator)} `}
				<ValueResolver
					operator={filter.operator}
					value={filter.value}
					type={guestAttributeInfo.type}
					dataField={guestAttributeInfo.dataField}
				/>
			</span>
		</StyledTag>
	);
};

const ValueResolver: FC<{
	operator: IGuestFilterOperator;
	value: any;
	type: GuestFilterAttributeType;
	dataField?: IDataFieldIndexResource;
}> = ({ operator, value, type }) => {
	const { t } = useTranslation();
	const formatDateTime = useFormatDateTime();
	const formatDate = useFormatDate();

	if (operator === "IS_EMPTY" || operator === "IS_NOT_EMPTY") {
		return null;
	}

	if (operator === "WHERE_IN" || operator === "WHERE_NOT_IN") {
		if (type === "status") {
			return value.map(t).join(", ");
		}
		if (type === "companions" || type === "companionParent") {
			return value.map((v: { firstName: string; lastName: string }) => `${v.firstName} ${v.lastName}`).join(", ");
		}
		if (type === "selectField" || type === "multiSelectField" || type === "checkboxGroup") {
			return value.map((v: { value: string }) => v.value).join(", ");
		}
		return value.map((v: { name: string; value: Id }) => v.name).join(", ");
	}

	if (operator === "WHERE_IN_CONTAINS" || operator === "WHERE_NOT_IN_CONTAINS") {
		return (value as string[]).join(", ");
	}

	switch (type) {
		case "companions":
			return `${value.firstName} ${value.lastName}`;
		case "companionParent":
			if (operator === "EQUAL") {
				return `${value.firstName} ${value.lastName}`;
			}
			return "";
		case "date":
			if (operator === "BETWEEN") {
				return t("{{start}} and {{end}}", {
					start: formatDate(value.start),
					end: formatDate(value.end),
				});
			}
			return formatDate(value);
		case "registrationForm":
		case "tenant":
		case "wave":
			return value.name;
		case "status":
		case "screeningStatus":
			return t(value);
		case "tags":
		case "groups":
		case "agreedConsents":
			return value.map((v: { name: string; value: Id }) => v.name).join(", ");
		case "dateTime":
		case "registrationTimestamp":
		case "createdAt":
		case "updatedAt":
			if (operator === "BETWEEN") {
				return t("{{start}} and {{end}}", {
					start: formatDateTime(value.start),
					end: formatDateTime(value.end),
				});
			}
			return formatDateTime(value);
		case "selectField":
		case "radioField":
			return value.value;
		case "multiSelectField":
		case "checkboxGroup":
			return value.map((v: { id: Id; value: string }) => v.value).join(", ");
		case "checkbox":
			return `${value}`;
		default:
			return value;
	}
};
