import { FormProps, I18nBlockForm, IBaseBlockEditResource } from "@dgs/core";
import React, { FC } from "react";
import { emailPersonalizedButtonBlockForm } from "./EmailPersonalizedButtonBlock";
import { EmailPersonalizedButtonBlockFormFields } from "./EmailPersonalizedButtonBlockFormFields";

export const EmailPersonalizedButtonBlockForm: FC<FormProps<IBaseBlockEditResource>> = ({ block, onSubmit }) => {
	return (
		<I18nBlockForm block={block} onSubmit={onSubmit} blockForm={emailPersonalizedButtonBlockForm}>
			<EmailPersonalizedButtonBlockFormFields blockType={block.type} />
		</I18nBlockForm>
	);
};
