import { CrudDrawer, RenderProps, apiIsOK, useBulkEdit, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { guestKeys, guestService } from "~shared/api/guests";
import { GuestSingleSelect } from "~shared/selects/GuestSingleSelect";
import { Guest } from "~shared/types/guest";

interface FormValues {
	roomMate: Guest | null;
}

const initialValues: FormValues = {
	roomMate: null,
};

export const AssignRoomMatesToGuestContingentDrawer: FC<RenderProps> = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const { guestId = "" } = useParams<"guestId">();
	const { selectedRows } = useBulkEdit();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();

	const addNewRoomMatesToContingent = useCallback(
		async ({ roomMate }: FormValues) => {
			const response = await guestService.attachRoomMateToRooms(guestId, {
				roomMateId: roomMate?.id || "",
				roomIds: selectedRows !== "all" ? selectedRows : [],
				allRooms: selectedRows === "all",
			});

			if (apiIsOK(response)) {
				showToast({ type: "success", title: t("Room mates"), body: t("Room mate successfully added") });
				await queryClient.invalidateQueries({ queryKey: guestKeys.rooms(guestId) });
				return true;
			}
			return false;
		},
		[guestId, queryClient, selectedRows, showToast, t],
	);

	const validate = useCallback(
		(values: FormValues) => {
			const errors: Record<string, string> = {};
			if (values.roomMate && values.roomMate.id === guestId) {
				errors.roomMate = t("You cannot assign the guest as its own room mate");
			}
			return errors;
		},
		[guestId, t],
	);

	return (
		<CrudDrawer
			open={open}
			onSubmit={addNewRoomMatesToContingent}
			body={<GuestSingleSelect label={t("Room mates")} name="roomMate" exclude={[guestId]} />}
			initialValues={initialValues}
			onClose={handleClose}
			heading={t("Add room mates")}
			validate={validate}
		/>
	);
};
