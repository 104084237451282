import { AddonProtected, BrandPage } from "@dgs/core";
import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ProductTranslationsPage } from "~admin/settings/productTranslations/ProductTranslationsPage";
import { config } from "~shared/config";
import { GeneralSettingsPage } from "./GeneralSettingsPage";
import { PersonalizedDocumentList, PersonalizedDocumentSettingsPage } from "./personalizedDocuments";

export const SettingsPage: FC = () => {
	return (
		<Routes>
			<Route index={true} element={<Navigate to="general" />} />
			<Route path="general" element={<GeneralSettingsPage />} />
			<Route path="brand" element={<BrandPage frontendThemeName={config.frontendThemeName} />} />
			<Route
				path="personalized-documents"
				element={
					<AddonProtected addon="personalizedDocuments">
						<PersonalizedDocumentSettingsPage />
					</AddonProtected>
				}
			/>
			<Route
				path="personalized-documents-overview"
				element={
					<AddonProtected addon="personalizedDocuments">
						<PersonalizedDocumentList />
					</AddonProtected>
				}
			/>
			<Route path="product-translations" element={<ProductTranslationsPage />} />
		</Routes>
	);
};
