import { Button, ButtonGroup, useI18nForm } from "@dgs/core";
import React, { FC } from "react";

export const Switcher: FC<{ errorLocales: string[] }> = ({ errorLocales }) => {
	const { fallbackLocale, locales, currentLocale, setCurrentLocale } = useI18nForm();

	return (
		<ButtonGroup>
			{locales.map((locale) => (
				<Button
					key={locale}
					title={locale}
					onClick={() => setCurrentLocale(locale)}
					type="button"
					color={
						currentLocale !== locale && errorLocales.some((errorLocale) => errorLocale === locale)
							? "danger"
							: currentLocale === locale
							? "primary"
							: "default"
					}
					size="small"
				>
					{locale}
					{fallbackLocale === locale && "*"}
				</Button>
			))}
		</ButtonGroup>
	);
};
