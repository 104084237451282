import { CrudDrawer, EntityDrawerProps, FileInputField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { FileCategorySingleSelectField } from "~shared/selects/FileCategorySelectField";
import { IGuestFileFormState } from "~shared/types/guestFile";

export const GuestFileDrawer: FC<EntityDrawerProps<IGuestFileFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IGuestFileFormState) => {
			const errors: { [K in keyof IGuestFileFormState]?: any } = {};

			if (!values.file) {
				errors.file = t("Required field");
			}
			if (!values.guestFileCategory) {
				errors.guestFileCategory = t("Required field");
			}

			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<FileInputField name="file" label={t("File")} required />
					<FileCategorySingleSelectField name="guestFileCategory" label={t("Guest file category")} required />
				</>
			}
		/>
	);
};
