import { Button, Card, CardFooter, SubmitButton } from "@dgs/core";
import { setNestedObjectValues, useFormikContext } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IHotelResource } from "~shared/types/hotel";
import { FormikHelpersWithRedirect, SetTo } from "~shared/ui/FormikWithRedirect";
import { HotelForm } from "../HotelForm";
import { useHotelStepper } from "./HotelStepperProvider";

export const HotelStepperMasterDataPage: FC = () => {
	const { hotel, setHotel } = useHotelStepper();
	const { t } = useTranslation();
	const onSubmit = useCallback(
		async (hotel: IHotelResource, helpers: FormikHelpersWithRedirect<IHotelResource>) => {
			setHotel(hotel);
			await helpers.setTo("room-types");
			return true;
		},
		[setHotel],
	);

	return (
		<Card>
			<HotelForm
				hotel={hotel}
				onSubmit={onSubmit}
				title={t("Hotel master data")}
				footer={(setTo) => (
					<CardFooter>
						<SubmitWithoutRoomTypeButton setTo={setTo} />
						<SubmitButton>{t("Next")}</SubmitButton>
					</CardFooter>
				)}
			/>
		</Card>
	);
};

const SubmitWithoutRoomTypeButton: FC<{ setTo: SetTo }> = ({ setTo }) => {
	const { t } = useTranslation();
	const { submitWithoutRoomTypes } = useHotelStepper();
	const { values, validateForm, setTouched, resetForm } = useFormikContext<IHotelResource>();
	const onClick = useCallback(async () => {
		const errors = await validateForm(values);

		setTouched(setNestedObjectValues(errors, true));

		if (Object.keys(errors).length === 0) {
			submitWithoutRoomTypes(values, setTo, resetForm);
		}
	}, [validateForm, values, setTouched, submitWithoutRoomTypes, setTo, resetForm]);

	return (
		<Button type="button" color="warning" onClick={onClick}>
			{t("Save without room types")}
		</Button>
	);
};
