import { SingleSelectField } from "@dgs/core";
import { useField } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { guestFilterOperatorMap } from "~shared/guestFilters/guestFilterOperatorMap";
import { GuestFilterAttributeType, IGuestFilterOperator } from "./guestFilterTypes";

interface Props {
	name: string;
	type: GuestFilterAttributeType;
}

export const GuestFilterOperator: FC<Props> = ({ type, name }) => {
	const { t } = useTranslation();
	const [, , { setValue }] = useField(`${name}.value`);

	return (
		<SingleSelectField<IGuestFilterOperator, IGuestFilterOperator>
			options={guestFilterOperatorMap[type]}
			label={t("Operator")}
			placeholder={t("Operator")}
			name={`${name}.operator`}
			getLabel={(option) => t(option)}
			clearable={false}
			onChange={(e) => setValue(e.target.value === "EQUAL" && type === "checkbox" ? false : null)}
		/>
	);
};
