import { FormLabel, IconAnchorButton, Id, useDynamicConfig } from "@dgs/core";
import { useField } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledFormLabel = styled(FormLabel)`
	display: block;
	margin-bottom: ${({ theme }) => theme.spacing(2)};
`;
const Wrapper = styled.div`
	padding-bottom: 1.5rem;
`;
const FileValue = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing(2)};
`;

export const getFilenameFromPath = (path: string) => {
	const res = path.split("/");
	return res[res.length - 1];
};

interface Props {
	name: string;
	label: string;
	guestId: Id;
	dataFieldId: Id;
}
export const FileDataField: FC<Props> = ({ name, label, guestId, dataFieldId }) => {
	const [{ value }, , { setValue }] = useField<string | null>(name);
	const { t } = useTranslation();
	const { apiUrl } = useDynamicConfig();

	if (!value || !dataFieldId) {
		return (
			<Wrapper>
				<StyledFormLabel>{label}</StyledFormLabel>
				{"-"}
			</Wrapper>
		);
	}
	return (
		<Wrapper>
			<StyledFormLabel>{label}</StyledFormLabel>
			<FileValue>
				{getFilenameFromPath(value)}
				<IconAnchorButton
					href={`${apiUrl}/guests/${guestId}/data-fields/${dataFieldId}/download`}
					type="button"
					size="small"
					target="_blank"
					title={t("Download file")}
					icon="file_download"
				/>
				<IconAnchorButton
					title={t("Remove file")}
					type="button"
					size="small"
					onClick={() => setValue(null)}
					icon="close"
				/>
			</FileValue>
		</Wrapper>
	);
};
