import { AnyMenuItem, getNavBar } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNominationManagerConfig } from "./useNominationManagerConfig";

const NavBar = getNavBar();

export const NominationManagerNavBar: FC = () => {
	const { t } = useTranslation();
	const { htmlTitle, settings } = useNominationManagerConfig();
	const items: AnyMenuItem[] = useMemo(() => {
		const menuItems: AnyMenuItem[] = [];

		if (settings.startPage) {
			menuItems.push({
				id: 0,
				type: "INTERNAL",
				name: t("Home"),
				url: `blocks/${settings.startPage.slug}`,
				children: [],
			});
		}

		return menuItems.concat([
			{
				id: 1,
				type: "INTERNAL",
				name: t("Guests"),
				url: "guests",
				children: [],
			},
			{
				id: 2,
				type: "INTERNAL",
				name: t("Imports"),
				url: "export-import/imports",
				children: [],
			},
			{
				id: 3,
				type: "INTERNAL",
				name: t("Exports"),
				url: "export-import/exports",
				children: [],
			},
		]);
	}, [t, settings.startPage]);

	return <NavBar appIdentifier={htmlTitle} items={items} />;
};
