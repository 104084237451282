import { FormattedDate } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IGuestHotelData } from "~shared/types/guest";
import { ValueViewer } from "~shared/ui/ValueViewer";

const Range = styled.div`
	display: grid;
	grid-template-columns: max-content max-content;
	gap: ${({ theme }) => theme.spacing(8)};
`;

export const HotelData: FC<{ hotelData: IGuestHotelData }> = ({ hotelData }) => {
	const { t } = useTranslation();

	if (!hotelData.checkInDate && !hotelData.checkOutDate && !hotelData.hotelComment) {
		return null;
	}

	return (
		<div>
			{hotelData.checkInDate && hotelData.checkOutDate && (
				<Range>
					<ValueViewer label={t("Check-in")}>
						<FormattedDate date={hotelData.checkInDate} />
					</ValueViewer>
					<ValueViewer label={t("Check-out")}>
						<FormattedDate date={hotelData.checkOutDate} />
					</ValueViewer>
				</Range>
			)}
			{hotelData.hotelComment && <ValueViewer label={t("Hotel comment")}>{hotelData.hotelComment}</ValueViewer>}
		</div>
	);
};
