import { SingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";

interface Props {
	name: string;
	label: string;
	required?: boolean;
}

export const LocaleSelect: FC<Props> = ({ name, label, required }) => {
	const { locales } = useContentLocale();

	return (
		<SingleSelectField
			name={name}
			label={label}
			required={required}
			options={locales}
			getLabel={String}
			getValue={String}
		/>
	);
};
