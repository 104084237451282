import { AutoDataList } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { hotelInquiryService } from "~shared/api/hotelInquiries";
import { useHotelInquiriesListColumns } from "./HotelInquiriesListColumns";

export const ConfirmedHotelInquiriesList: FC = () => {
	const { t } = useTranslation();
	const columns = useHotelInquiriesListColumns();

	return (
		<AutoDataList
			heading={t("Confirmed hotel requests")}
			empty={t("No hotel requests available.")}
			columns={columns}
			queryKey={hotelInquiryService.keys.confirmed}
			fetcher={hotelInquiryService.confirmed}
		/>
	);
};
