import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { distributionListKeys } from "~shared/api/distributionList/distributionListKeys";
import { DistributionList } from "~shared/types/distributionList";

export const distributionListUrls = {
	index: keyToUrl(distributionListKeys.index),
};

const index = () => axios.get<LaravelResponse<DistributionList[]>>(distributionListUrls.index);

export const distributionListService = {
	index,
};
