import { BaseFilterItem } from "~shared/types/filterItem";

export enum DataFieldType {
	TEXT = "text",
	LONG_TEXT = "longText",
	EMAIL = "email",
	NUMBER = "number",
	URL = "url",
	RADIO = "radioField",
	SELECT_FIELD = "selectField",
	MULTI_SELECT_FIELD = "multiSelectField",
	CHECKBOX = "checkbox",
	CHECKBOX_GROUP = "checkboxGroup",
	FILE = "file",
	DATE = "date",
	TIME = "time",
	DATETIME = "dateTime",
	PHONE = "phone",
	CRYPT = "crypt",
}

export type DataFieldTypes =
	| "text"
	| "longText"
	| "email"
	| "number"
	| "url"
	| "radioField"
	| "selectField"
	| "multiSelectField"
	| "checkboxGroup"
	| "checkbox"
	| "file"
	| "date"
	| "time"
	| "dateTime"
	| "phone"
	| "crypt";

export type AttributeTypes = "status" | "registrationFormId" | "groups" | "tags" | "created_at" | "updated_at";

export interface OperatorValueInput {
	operator: BaseFilterItem["operator"];
	inputType: DataFieldOperatorValueInputTypes;
}

const multiOptionsOperators: OperatorValueInput[] = [
	{ operator: "IS_EMPTY", inputType: null },
	{ operator: "=", inputType: "singleSelect" },
	{ operator: "!=", inputType: "singleSelect" },
	{ operator: "CONTAINS", inputType: "textField" },
	{ operator: "NOT_CONTAINS", inputType: "textField" },
	{ operator: "WHERE_IN", inputType: "multiSelect" },
];

const singleOptionOperators: OperatorValueInput[] = [
	{ operator: "IS_EMPTY", inputType: null },
	{ operator: "=", inputType: "singleSelect" },
	{ operator: "!=", inputType: "singleSelect" },
	{ operator: "WHERE_IN", inputType: "multiSelect" },
];

export const allowedDataFieldOperators: { [key in DataFieldTypes]: OperatorValueInput[] } = {
	multiSelectField: multiOptionsOperators,
	checkboxGroup: multiOptionsOperators,
	radioField: singleOptionOperators,
	selectField: singleOptionOperators,
	crypt: [{ operator: "IS_EMPTY", inputType: null }],
	checkbox: [
		{ operator: "=", inputType: "multiSelect" },
		{ operator: "!=", inputType: "singleSelect" },
	],
	date: [
		{ operator: "IS_EMPTY", inputType: null },
		{ operator: "=", inputType: "textField" },
		{ operator: "!=", inputType: "textField" },
	],
	dateTime: [
		{ operator: "IS_EMPTY", inputType: null },
		{ operator: "=", inputType: "textField" },
		{ operator: "!=", inputType: "textField" },
	],
	email: [
		{ operator: "IS_EMPTY", inputType: null },
		{ operator: "=", inputType: "textField" },
		{ operator: "!=", inputType: "textField" },
		{ operator: "CONTAINS", inputType: "textField" },
		{ operator: "NOT_CONTAINS", inputType: "textField" },
	],
	file: [
		{ operator: "IS_EMPTY", inputType: null },
		{ operator: "=", inputType: "textField" },
		{ operator: "!=", inputType: "textField" },
	],
	longText: [
		{ operator: "IS_EMPTY", inputType: null },
		{ operator: "=", inputType: "textField" },
		{ operator: "!=", inputType: "textField" },
		{ operator: "CONTAINS", inputType: "textField" },
		{ operator: "NOT_CONTAINS", inputType: "textField" },
	],
	number: [
		{ operator: "IS_EMPTY", inputType: null },
		{ operator: "=", inputType: "textField" },
		{ operator: "!=", inputType: "textField" },
	],
	phone: [
		{ operator: "IS_EMPTY", inputType: null },
		{ operator: "=", inputType: "textField" },
		{ operator: "!=", inputType: "textField" },
	],
	time: [
		{ operator: "IS_EMPTY", inputType: null },
		{ operator: "=", inputType: "textField" },
		{ operator: "!=", inputType: "textField" },
	],
	url: [
		{ operator: "IS_EMPTY", inputType: null },
		{ operator: "=", inputType: "textField" },
		{ operator: "!=", inputType: "textField" },
	],
	text: [
		{ operator: "IS_EMPTY", inputType: null },
		{ operator: "=", inputType: "textField" },
		{ operator: "!=", inputType: "textField" },
		{ operator: "CONTAINS", inputType: "textField" },
		{ operator: "NOT_CONTAINS", inputType: "textField" },
	],
};

export const allowedAttributeOperators: { [key in AttributeTypes]: OperatorValueInput[] } = {
	status: [
		{ operator: "=", inputType: "singleSelect" },
		{ operator: "!=", inputType: "singleSelect" },
		{ operator: "WHERE_IN", inputType: "multiSelect" },
	],
	registrationFormId: [
		{ operator: "IS_EMPTY", inputType: null },
		{ operator: "=", inputType: "singleSelect" },
		{ operator: "!=", inputType: "singleSelect" },
		{ operator: "CONTAINS", inputType: "textField" },
		{ operator: "NOT_CONTAINS", inputType: "textField" },
	],
	groups: [
		{ operator: "IS_EMPTY", inputType: null },
		{ operator: "=", inputType: "singleSelect" },
		{ operator: "!=", inputType: "singleSelect" },
		{ operator: "CONTAINS", inputType: "textField" },
		{ operator: "NOT_CONTAINS", inputType: "textField" },
	],
	tags: [
		{ operator: "IS_EMPTY", inputType: null },
		{ operator: "=", inputType: "singleSelect" },
		{ operator: "!=", inputType: "singleSelect" },
		{ operator: "CONTAINS", inputType: "textField" },
		{ operator: "NOT_CONTAINS", inputType: "textField" },
	],
	created_at: [
		{ operator: "=", inputType: "textField" },
		{ operator: "!=", inputType: "textField" },
	],
	updated_at: [
		{ operator: "=", inputType: "textField" },
		{ operator: "!=", inputType: "textField" },
	],
};

export const allowedOperators = { ...allowedAttributeOperators, ...allowedDataFieldOperators };

export type DataFieldOperatorValueInputTypes = "multiSelect" | "singleSelect" | "textField" | null;
