import { BaseCrudService, IMenuItemIndexResource, Id, keyToUrl } from "@dgs/core";
import { IMenuItemEditResource, IMenuItemRequest } from "~shared/types/menuItem";

export class MenuItemService extends BaseCrudService<
	IMenuItemIndexResource,
	IMenuItemEditResource,
	IMenuItemRequest,
	IMenuItemRequest
> {
	public keys = {
		index: ["menu-items"],
		list: ["menu-items", "list"],
		details: (menuItemId: Id) => ["menu-items", `${menuItemId}`],
	};
	protected urls = {
		index: keyToUrl(this.keys.index),
		details: (menuItemId: Id) => keyToUrl(this.keys.details(menuItemId)),
	};
}

export const menuItemService = new MenuItemService();
