import { Id, Loading } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRegistrationFormRuleSetQuery } from "~shared/api/registrationForms/ruleSets/ruleSetQueries";
import { ActionFormState } from "~shared/types/actions";
import { RuleFormState } from "~shared/types/rules";
import { Automation, RuleSet, RuleSetType } from "~shared/types/ruleSet";
import { RuleSetForm } from "./RuleSetForm";
import { RuleSetFormActions } from "./RuleSetFormActions";
import { RuleSetFormRules } from "./RuleSetFormRules";
import { useRuleSet } from "./useRuleSet";

const mapRuleSetToForm = (ruleSet: RuleSet): RuleSet<RuleFormState, ActionFormState> => {
	return {
		...ruleSet,
		description: ruleSet.description ?? "",
	};
};

export const RuleSetEditPage: FC<{ ruleSetType: RuleSetType }> = ({ ruleSetType }) => {
	const { registrationFormId = "", ruleSetId = "" } = useParams<{ registrationFormId: string; ruleSetId: string }>();
	const navigate = useNavigate();
	const { data, isLoading } = useRegistrationFormRuleSetQuery(registrationFormId, ruleSetId);
	const { updateRuleSet, deleteRuleSet } = useRuleSet(registrationFormId);

	const onSubmit = useCallback(
		async (ruleSet: Automation<RuleFormState, ActionFormState>) => {
			const res = await updateRuleSet(ruleSet);
			if (res) {
				navigate("..");
			}
		},
		[navigate, updateRuleSet],
	);

	const onDelete = useCallback(
		async (ruleSetId: Id) => {
			await deleteRuleSet(ruleSetId, ruleSetType);
			navigate("..");
		},
		[deleteRuleSet, navigate, ruleSetType],
	);

	if (isLoading || !data) {
		return <Loading />;
	}

	return (
		<RuleSetForm ruleSet={mapRuleSetToForm(data)} onSubmit={onSubmit} onDelete={onDelete}>
			<RuleSetFormRules registrationFormId={registrationFormId} ruleSetType={ruleSetType} />
			<RuleSetFormActions registrationFormId={registrationFormId} ruleSetType={ruleSetType} />
		</RuleSetForm>
	);
};
