import { AutoDataListCard, BulkEditProvider, IDataListColumn, Icon, useList } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { useDataFieldsQuery } from "~shared/api/dataFields";
import { importKeys } from "~shared/api/imports";
import {
	ImportErrorListBulkEditEntityType,
	ImportErrorListBulkEditOption,
} from "~shared/import/importErrorListBulkEditTypes";
import { GuestImportError, ImportError } from "~shared/types/guestImport";
import { ImportErrorDrawer } from "./ImportErrorDrawer";
import { ImportErrorListBulkEditAction } from "./ImportErrorListBulkEditAction";
import { useGuestImportErrors } from "./ImportErrorListProvider";

const Warning = styled(Icon)`
	width: 16px;
	height: 16px;
`;

const ImportErrorItem = styled.div<{ $hasError: boolean }>`
	display: grid;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(1)};
	${({ $hasError }) =>
		$hasError
			? css`
					grid-template-columns: max-content 1fr;
					color: ${({ theme }) => theme.colors.palette.danger.main.base};
			  `
			: css`
					grid-template-columns: 1fr;
			  `}
`;

export const ImportErrorList: FC = () => {
	const { t } = useTranslation();
	const { currentErrorId, setCurrentErrorId } = useGuestImportErrors();
	const { data: dataFields, isLoading } = useDataFieldsQuery();
	const { setPage, pageSizes, pagination, isEmpty, loading, data: guestImportErrors } = useList<GuestImportError>();
	const { guestImportId = "" } = useParams<"guestImportId">();
	const queryClient = useQueryClient();

	const resolveHeader = useCallback(
		(header: string) => {
			switch (header) {
				case "groups":
					return t("Groups");
				case "tags":
					return t("Tags");
				case "status":
					return t("Status");
				case "registrationForm":
					return t("Registration form");
				case "tenant":
					return t("Tenant");
				case "wave":
					return t("Wave");
				case "id":
					return t("id");
				case "registrationLocale":
					return t("registrationLocale");
				case "registrationCode":
					return t("registrationCode");
				default:
					return t("Unknown guest attribute") + ` (${header})`;
			}
		},
		[t],
	);

	const dataListColumns: IDataListColumn<GuestImportError>[] = useMemo(() => {
		const errors = guestImportErrors[0]?.data || [];

		return errors.map((error: ImportError, index) => ({
			heading:
				error.type === "dataField"
					? dataFields.find((field) => field.id === error.dataFieldId)?.name || ""
					: resolveHeader(error.type),
			valueKey: `data.${index}.value`,
			type: "value",
			render: (error) => (
				<ImportErrorItem $hasError={!!error.data[index].error}>
					{!!error.data[index].error && <Warning icon="warning" />}
					{error.data[index].value}
				</ImportErrorItem>
			),
		}));
	}, [guestImportErrors, dataFields, resolveHeader]);

	const options: ImportErrorListBulkEditOption[] = useMemo(() => {
		const errors = guestImportErrors[0]?.data.filter((e) => e.type !== ImportErrorListBulkEditEntityType.id) || [];
		return dataFields.length
			? errors.map((error) => {
					if (error.type === "dataField") {
						const dataField = dataFields.find((field) => field.id === error.dataFieldId);

						return {
							id: dataField?.id,
							name: dataField ? dataField.name : "",
							entityType: ImportErrorListBulkEditEntityType.dataField,
							type: dataField?.type,
							options: dataField?.options,
						};
					} else {
						return { name: resolveHeader(error.type), entityType: error.type };
					}
			  })
			: [];
	}, [dataFields, guestImportErrors, resolveHeader]);

	return (
		<>
			<BulkEditProvider availableItemIds={guestImportErrors.map((d) => d.id)} totalItems={pagination.total}>
				{(props) => {
					return (
						<AutoDataListCard
							{...props}
							loading={isLoading || loading}
							isEmpty={isEmpty}
							empty={t("No errors.")}
							columns={dataListColumns}
							enableBulkEdit={true}
							onRowClick={(error) => (props.bulkEditing ? props.setSelectedRow(error.id) : setCurrentErrorId(error.id))}
							heading={t("Guests")}
							data={guestImportErrors}
							pagination={pagination}
							bulkEditActions={<ImportErrorListBulkEditAction options={options} />}
							pageSizes={pageSizes}
							setPage={setPage}
						/>
					);
				}}
			</BulkEditProvider>
			{currentErrorId && (
				<ImportErrorDrawer
					errorId={currentErrorId}
					onClose={() => {
						setCurrentErrorId(null);
						if (currentErrorId) {
							queryClient.resetQueries({ queryKey: importKeys.guests.errors.details(guestImportId, currentErrorId) });
						}
					}}
				/>
			)}
		</>
	);
};
