import { DrawerAutoDataList, IDrawerDataListColumn } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GuestViewDrawer } from "~admin/guestView/GuestViewDrawer";
import { guestViewService } from "~shared/api/guestViews";
import { GuestViewConfigResource } from "~shared/types/guestView";
import { GuestViewContextMenu } from "./GuestViewContextMenu";
import { useGuestViewForm } from "./useGuestViewForm";

export const GuestViewList: FC = () => {
	const { t } = useTranslation();
	const { createGuestView, updateGuestView, deleteGuestView } = useGuestViewForm();

	const emptyGuestView: GuestViewConfigResource = {
		id: -1,
		name: "",
		guestAttributes: [],
	};

	const dataListColumns: IDrawerDataListColumn<GuestViewConfigResource>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (guestView, openDrawer) => (
					<GuestViewContextMenu handleEdit={openDrawer} handleDelete={() => deleteGuestView(guestView.id)} />
				),
			},
		],
		[t, deleteGuestView],
	);

	return (
		<>
			<DrawerAutoDataList
				translations={{
					title: t("Guest views"),
					empty: t("No guest views available"),
				}}
				renderCreateDrawer={(drawer) => (
					<GuestViewDrawer
						{...drawer}
						heading={t("Create guest view")}
						initialValues={emptyGuestView}
						onSubmit={createGuestView}
					/>
				)}
				renderUpdateDrawer={(entity, drawer) => (
					<GuestViewDrawer
						{...drawer}
						heading={t("Edit guest view")}
						initialValues={entity}
						onSubmit={(entityFormState) => updateGuestView(entity.id, entityFormState)}
					/>
				)}
				service={guestViewService}
				initialSearch={{ search: "", searchFields: ["name"] }}
				columns={dataListColumns}
			/>
		</>
	);
};
