import { FCC, Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { createContext, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { registrationFormKeys, registrationFormService } from "~shared/api/registrationForms";
import { ruleSetService } from "~shared/api/registrationForms/ruleSets/ruleSetService";
import {
	SectionChildUpdate,
	StepChildUpdate,
	StructureSectionChild,
	StructureStep,
	StructureStepChild,
} from "~shared/types/registrationFormTypes";
import { RuleSet, RuleSetType } from "~shared/types/ruleSet";
import { isBlock, isDataField, isSection, revertId } from "./registrationFormDndUtils";

export function toSectionChildRequest(sectionChild: Omit<StructureSectionChild, "name">) {
	return {
		identifier: revertId(sectionChild.id),
		blockId: isBlock(sectionChild) ? sectionChild.blockId : undefined,
		dataFieldId: isDataField(sectionChild) ? sectionChild.dataFieldId : undefined,
		entityType: sectionChild.entityType,
	};
}

export function toStepChildRequest(stepChild: Omit<StructureStepChild, "name">) {
	return {
		identifier: revertId(stepChild.id),
		blockId: isBlock(stepChild) ? stepChild.blockId : undefined,
		sectionId: isSection(stepChild) ? stepChild.sectionId : undefined,
		entityType: stepChild.entityType,
	};
}

interface RegistrationFormState {
	registrationFormId: string;
	moveSectionChild: (update: SectionChildUpdate) => void;
	moveStepChild: (update: StepChildUpdate) => void;
	moveStep: (update: StructureStep[]) => void;
}

const RegistrationFormStructureContext = createContext<RegistrationFormState | undefined>(undefined);

export const RegistrationFormStructureProvider: FCC = ({ children }) => {
	const { registrationFormId = "" } = useParams<"registrationFormId">();
	const queryClient = useQueryClient();
	const { showToast } = useToasts();
	const { t } = useTranslation();

	const checkDataFieldUsage = useCallback(
		async (dataFieldId: Id) => {
			const response = await ruleSetService.checkDataFieldUsage(registrationFormId, dataFieldId);
			if (apiIsOK(response)) {
				const ruleSets: RuleSet[] = response.data.data;
				if (ruleSets.length > 0) {
					const body = ruleSets.map((r) => {
						if (r.type === RuleSetType.Condition) {
							return `${t("Condition")}: ${r.description}`;
						} else if (r.type === RuleSetType.Automation) {
							return `${t("Automation")}: ${r.description}`;
						} else {
							return "";
						}
					});

					showToast({
						body: body,
						title: t("Data fields are being used in Automations / Conditions."),
						type: "warning",
					});
				}
			}
		},
		[registrationFormId, showToast, t],
	);

	const moveSectionChild = useCallback(
		async (update: SectionChildUpdate) => {
			const children = update.children.map(toSectionChildRequest);

			if (isDataField(update.childToMove)) {
				await checkDataFieldUsage(update.childToMove.dataFieldId);
			}

			const response = await registrationFormService.putSectionChildrenOrder(
				registrationFormId,
				update.to.stepId,
				update.to.sectionId,
				{
					from: update.from,
					children,
				},
			);

			if (apiIsOK(response)) {
				await queryClient.invalidateQueries({ queryKey: registrationFormKeys.index });
			}
		},
		[checkDataFieldUsage, queryClient, registrationFormId],
	);

	const moveStepChild = useCallback(
		async (update: StepChildUpdate) => {
			const children = update.children.map(toStepChildRequest);

			const response = await registrationFormService.putStepChildrenOrder(registrationFormId, update.to.stepId, {
				from: update.from.stepId,
				children,
			});

			if (apiIsOK(response)) {
				await queryClient.invalidateQueries({ queryKey: registrationFormKeys.index });
			}
		},
		[queryClient, registrationFormId],
	);

	const moveStep = useCallback(
		async (steps: StructureStep[]) => {
			const response = await registrationFormService.putStepOrder(registrationFormId, {
				ids: steps.map((step) => revertId(step.id)),
			});

			if (apiIsOK(response)) {
				await queryClient.invalidateQueries({ queryKey: registrationFormKeys.index });
			}
		},
		[queryClient, registrationFormId],
	);

	return (
		<RegistrationFormStructureContext.Provider
			value={{
				registrationFormId,
				moveSectionChild,
				moveStepChild,
				moveStep,
			}}
		>
			{children}
		</RegistrationFormStructureContext.Provider>
	);
};

export const useRegistrationFormStructure = () => {
	const ctx = useContext(RegistrationFormStructureContext);

	if (ctx === undefined) {
		throw new Error("useRegistrationFormStructure needs to be wrapped inside a RegistrationFormStructureProvider.");
	}

	return ctx;
};
