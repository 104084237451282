import React, { FC } from "react";
import { I18nFieldWrapper } from "~admin/i18n_forms/I18nFieldWrapper";
import { useI18nForm, useLocaleIndex } from "~admin/i18n_forms/I18nFormProvider";
import { JsonCodeMirrorField } from "~admin/shared/fields/JsonCodemirrorField";

export const ProductTranslationField: FC = () => {
	const { currentLocale } = useI18nForm();
	const currentLocaleIdx = useLocaleIndex(currentLocale);

	return (
		<I18nFieldWrapper name="value">
			<JsonCodeMirrorField name={`translations.${currentLocaleIdx}.value`} />
		</I18nFieldWrapper>
	);
};
