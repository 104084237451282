import {
	ContextMenu,
	ContextMenuItem,
	ContextMenuLinkItem,
	Id,
	SelectedRows,
	apiIsOK,
	useDynamicConfig,
	useToasts,
} from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { invoiceService } from "~admin/shared/api/shop/invoices/invoiceService";
import { IInvoiceIndexResource } from "~admin/shared/types/invoice";

interface Props {
	invoice: IInvoiceIndexResource;
	setDrawerValues: (invoiceIds: SelectedRows | null) => void;
}

export const InvoiceListContextMenu: FC<Props> = ({ invoice, setDrawerValues }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const { apiUrl } = useDynamicConfig();

	const regenerate = useCallback(
		async (id: Id) => {
			const response = await invoiceService.regenerate(id);
			if (apiIsOK(response)) {
				showToast({
					body: t("PDF was regenerated."),
					title: t("Success"),
					type: "success",
				});
			} else {
				showToast({
					body: response.statusText,
					title: t("Error"),
					type: "error",
				});
			}
		},
		[showToast, t],
	);

	return (
		<ContextMenu displayShortcutsAs="icon" size="small">
			<ContextMenuLinkItem
				title={t("Download")}
				label={t("Download")}
				icon="file_download"
				shortcut="primary"
				to={`${apiUrl}/invoices/${invoice.id}`}
				target="_blank"
			/>
			<ContextMenuItem
				title={t("Regenerate PDF")}
				label={t("Regenerate PDF")}
				icon="refresh"
				action={() => regenerate(invoice.id)}
			/>
			<ContextMenuItem
				title={t("Send email")}
				label={t("Send email")}
				icon="send"
				action={() => setDrawerValues([invoice.id])}
			/>
		</ContextMenu>
	);
};
