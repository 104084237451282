import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ISortableItem, SortableListItem, Wrapper } from "./SortableListItem";

interface Props<T extends ISortableItem> {
	item: T;
	getLabel: (item: T) => string;
	renderLabel?: (item: T) => React.ReactNode;
}

const EmptyWrapper = styled(Wrapper)`
	border: 1px dashed ${({ theme }) => theme.colors.palette.grey[40]};
	justify-content: center;
	color: ${({ theme }) => theme.colors.palette.grey[70]};
	cursor: default;
`;

const Name = styled.span`
	font-size: 14px;
`;

export function SortableItem<T extends ISortableItem>({ item, getLabel, renderLabel }: Props<T>) {
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: item.id,
		data: item.isRestrictedToVerticalAxis
			? {
					modifiers: [restrictToVerticalAxis],
			  }
			: {},
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<SortableListItem
			ref={setNodeRef}
			style={style}
			{...attributes}
			{...listeners}
			renderLabel={() => (renderLabel ? renderLabel(item) : getLabel(item))}
		/>
	);
}

export function EmptySortableItem() {
	const { t } = useTranslation();
	const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
		id: -1,
		disabled: true,
	});

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<EmptyWrapper {...attributes} {...listeners} ref={setNodeRef} $active={false} style={style}>
			<Name>{t("Drop here")}</Name>
		</EmptyWrapper>
	);
}
