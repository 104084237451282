import { SingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { TaxTypes } from "~shared/types/tax";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
}
const options = Object.values(TaxTypes);
const getValue = (type: TaxTypes) => type;
const getLabel = (type: TaxTypes) => type;
export const TaxTypeSelect: FC<Props> = ({ label, name, disabled, required }) => {
	return (
		<SingleSelectField
			label={label}
			name={name}
			options={options}
			getLabel={getLabel}
			getValue={getValue}
			disabled={disabled}
			required={required}
		/>
	);
};
