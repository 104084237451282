import { CardBody, Loading } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import React, { FC } from "react";
import styled from "styled-components";
import { guestKeys, guestService } from "~shared/api/guests";
import { useGuestDetails } from "~shared/guests/details/GuestDetailProvider";
import { GuestDetailsResource } from "~shared/types/newGuestTypes";
import { GuestRoomContingentsList } from "./GuestRoomContingentsList";
import { HotelData } from "./HotelData";

const LoadingWrapper = styled(CardBody)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ContentWrapper = styled.div`
	display: grid;
	gap: ${({ theme }) => theme.spacing(2)};
`;

export const GuestHotelsPage: FC = () => {
	const { guest, isLoading } = useGuestDetails();

	if (!guest || isLoading)
		return (
			<LoadingWrapper>
				<Loading />
			</LoadingWrapper>
		);

	return <GuestHotelsContent guest={guest} />;
};

const GuestHotelsContent: FC<{ guest: GuestDetailsResource }> = ({ guest }) => {
	const { data, isLoading } = useQuery({
		queryKey: guestKeys.hotelData(guest.id),
		queryFn: () => guestService.hotelData(guest.id),
	});

	if (!data || isLoading)
		return (
			<LoadingWrapper>
				<Loading />
			</LoadingWrapper>
		);

	return (
		<ContentWrapper>
			<HotelData hotelData={data.data.data} />
			<GuestRoomContingentsList guest={guest} />
		</ContentWrapper>
	);
};
