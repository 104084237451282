import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { RuleSetTitle } from "../ruleSetFormComponents";

export const RuleAndOrIndicator: FC<{ index: number; isAndLinked: boolean }> = ({ index, isAndLinked }) => {
	const { t } = useTranslation();

	if (index === 0) {
		return <RuleSetTitle>{t("When")}</RuleSetTitle>;
	}
	return <RuleSetTitle>{t(isAndLinked ? "AND" : "OR")}</RuleSetTitle>;
};

export const ActionIndicator: FC<{ index: number }> = ({ index }) => {
	const { t } = useTranslation();

	if (index === 0) {
		return <RuleSetTitle>{t("Then")}</RuleSetTitle>;
	}
	return <RuleSetTitle>{t("AND")}</RuleSetTitle>;
};
