import { MenuPage as BaseMenuPage, Id, MenuProvider, apiIsOK, useOpen, useToasts } from "@dgs/core";
import { menuKeys } from "@dgs/core/lib/modules/menu/menuService";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { MenuItemCreateDrawer } from "~admin/menu/MenuItemCreateDrawer";
import { MenuItemEditDrawer } from "~admin/menu/MenuItemEditDrawer";
import { menuItemService } from "~admin/shared/api/menu/menuItemService";

export const MenuPage: FC = () => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const [menuItemId, setMenuItemId] = useState<Id | null>(null);
	const {
		open: isCreateDrawerOpen,
		handleOpen: handleOpenCreateDrawer,
		handleClose: handleCloseCreateDrawer,
	} = useOpen();

	const { open: isEditDrawerOpen, handleOpen: handleOpenEditDrawer, handleClose: handleCloseEditDrawer } = useOpen();

	const handleEditMenuItem = useCallback(
		(menuItemId: Id) => {
			setMenuItemId(menuItemId);
			handleOpenEditDrawer();
		},
		[handleOpenEditDrawer],
	);

	const handleDeleteMenuItem = useCallback(
		async (menuItemId: Id) => {
			const response = await menuItemService.destroy(menuItemId);

			if (apiIsOK(response)) {
				showToast({ body: t("Menu item was deleted."), title: t("Delete menu item"), type: "success" });
				await queryClient.invalidateQueries({ queryKey: menuKeys.index });
			}
		},
		[queryClient, showToast, t],
	);

	return (
		<MenuProvider>
			<BaseMenuPage onCreate={handleOpenCreateDrawer} onEdit={handleEditMenuItem} onDelete={handleDeleteMenuItem} />
			<MenuItemCreateDrawer open={isCreateDrawerOpen} onClose={handleCloseCreateDrawer} />
			{menuItemId && (
				<MenuItemEditDrawer menuItemId={menuItemId} open={isEditDrawerOpen} onClose={handleCloseEditDrawer} />
			)}
		</MenuProvider>
	);
};
