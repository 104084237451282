import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { IImportProvider } from "~shared/types/importProvider";

export const importProviderKeys = {
	options: ["import-providers", "options"],
};

export const importProviderUrls = {
	options: keyToUrl(importProviderKeys.options),
};

const options = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<IImportProvider[]>>(importProviderUrls.options, config);

export const importProviderService = {
	options,
};
