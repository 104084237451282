import {
	EntityDrawerProps,
	I18NCrudDrawer,
	I18nOnlyOnMainForm,
	I18nTextField,
	TextField,
	mapErrors,
	useI18nForm,
} from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { IDataFieldOptionFormState } from "~shared/types/dataFieldOption";

export const DataFieldOptionDrawer: FC<EntityDrawerProps<IDataFieldOptionFormState>> = (props) => {
	const { t } = useTranslation();
	const { fallbackLocale, locales } = useContentLocale();
	const { currentLocale } = useI18nForm();

	const validate = useCallback(
		(values: IDataFieldOptionFormState) => {
			let errors: { [K in keyof IDataFieldOptionFormState]?: any } = {};

			if (!values.key) {
				errors.key = t("Required field");
			}

			const fallbackTranslation = values.translations[fallbackLocale] || {};

			if (!fallbackTranslation.value) {
				errors = mapErrors(errors, fallbackLocale, "value", t as any);
			}
			return errors;
		},
		[fallbackLocale, t],
	);

	return (
		<I18NCrudDrawer
			key={`${props.open}`}
			{...props}
			validate={validate}
			locales={locales}
			fallbackLocale={fallbackLocale}
			initialLocale={currentLocale}
			body={
				<>
					<I18nOnlyOnMainForm>
						<TextField name="key" label={t("Key")} required />
					</I18nOnlyOnMainForm>
					<I18nTextField name="value" label={t("Value")} required="onlyOnMainForm" />
					<I18nOnlyOnMainForm>
						<TextField name="contingent" label={t("Contingent")} type="number" />
					</I18nOnlyOnMainForm>
				</>
			}
		/>
	);
};
