import { useList } from "@dgs/core";
import React, { useContext, useEffect, useState } from "react";
import { Activity, IChange } from "~shared/types/activity";

interface ChangelogContextState {
	activities: Activity[];
}

interface Props {
	children: React.ReactNode;
	mapChange: (entity: IChange[]) => IChange[];
}

const ChangelogContext = React.createContext<ChangelogContextState | undefined>(undefined);

export function ChangelogProvider({ children, mapChange }: Props) {
	const [activities, setActivities] = useState<Activity[]>([]);
	const { data: rawActivities } = useList<Activity>();

	useEffect(() => {
		setActivities(
			rawActivities.map((activity) => ({
				...activity,
				createdAt: new Date(activity.createdAt),
				changes: mapChange(activity.changes),
			})),
		);
	}, [rawActivities, mapChange]);

	return <ChangelogContext.Provider value={{ activities }}>{children}</ChangelogContext.Provider>;
}

export const useChangelog = () => {
	const context = useContext(ChangelogContext);

	if (context === undefined) {
		throw new Error(`Context undefined. Are you missing the ChangelogProvider?`);
	}

	return context;
};
