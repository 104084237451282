import { SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import React, { ReactNode, useMemo } from "react";
import styled from "styled-components";
import { EmptySortableItem, SortableItem } from "./SortableItem";
import { ISortableItem } from "./SortableListItem";

const List = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-auto-columns: 1fr;
	grid-auto-rows: max-content;
	gap: ${({ theme }) => theme.spacing(1)};
`;

interface Props<T extends ISortableItem> {
	heading?: ReactNode;
	needle?: string;
	columnId: string;
	getLabel: (item: T) => string;
	renderLabel?: (item: T) => React.ReactNode;
	items: T[];
}

export function SortableList<T extends ISortableItem>({
	renderLabel,
	getLabel,
	needle = "",
	heading,
	columnId,
	items,
}: Props<T>) {
	const filteredItems = useMemo<T[]>(() => {
		return items.filter((item) => getLabel(item).toLowerCase().includes(needle.toLowerCase()));
	}, [getLabel, items, needle]);
	const { setNodeRef } = useSortable({
		id: columnId,
	});

	return (
		<List ref={setNodeRef}>
			{heading}
			<SortableContext id={columnId} items={items} strategy={verticalListSortingStrategy}>
				{filteredItems.map((item) => (
					<SortableItem key={item.id} item={item} getLabel={getLabel} renderLabel={renderLabel} />
				))}
				{filteredItems.length === 0 && <EmptySortableItem />}
			</SortableContext>
		</List>
	);
}
