import {
	BaseIcon,
	Button,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardHeading,
	CheckboxField,
	ConfirmationDialog,
	Separator,
	SubmitButton,
	TextField,
} from "@dgs/core";
import { Form, Formik, FormikProps } from "formik";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DistributionListSingleSelectField } from "~shared/selects/DistributionListSingleSelectField";
import { GroupMultiSelect } from "~shared/selects/GroupMultiSelect";
import { TagMultiSelect } from "~shared/selects/TagMultiSelect";
import { BaseGuestImportFormValues, ImportableAttributeOption, TImportHeader } from "~shared/types/guestImport";
import { TImportProviderType } from "~shared/types/importProvider";
import { ImportableAttributeSelect } from "./ImportableAttributeSelect";
import { useImportGuests } from "./ImportGuestProvider";
import { ZohoFilterFieldSingleSelect } from "./ZohoFilterFieldSingleSelect";

const StyledDiv = styled.div`
	margin-bottom: 1rem;
	font-size: 1rem;
`;

const FilterDiv = styled.div`
	display: flex;
	gap: 1rem;
	margin-bottom: 1rem;
`;

const ErrorText = styled.div`
	color: ${({ theme }) => theme.colors.palette.danger.main.base};
`;

interface Props<T> {
	children?: ReactNode;
	additionalValues: T;
	validateAdditionalInformation?: (values: T) => { [K in keyof T]?: any };
	filterEnabled?: boolean;
	importProviderType: TImportProviderType;
}

interface NonFieldErrors {
	allValuesEmpty?: never;
	tooManyValues?: never;
	missingRequiredAssignments?: never;
}

export function BaseGuestImportForm<T>({
	children,
	additionalValues,
	validateAdditionalInformation,
	importProviderType,
	filterEnabled = false,
}: Props<T>) {
	type FormValues = T & BaseGuestImportFormValues & NonFieldErrors;
	const { t } = useTranslation();
	const { headerRow, handleImport, importableGuestAttributeOptions } = useImportGuests();
	const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
	const [autoAssignConfirmationOpen, setAutoAssignConfirmationOpen] = useState(false);
	const handleImportWithConfirmation = useCallback(
		(values: FormValues) => {
			if (Object.values(values.mapping).includes("guest-id")) {
				setConfirmationIsOpen(true);
			} else {
				handleImport(values);
			}
		},
		[handleImport],
	);
	const requiredAttributeNames = useMemo(() => {
		return importableGuestAttributeOptions.filter(({ required }) => required).map(({ name }) => name);
	}, [importableGuestAttributeOptions]);

	const validate = useCallback(
		(values: FormValues) => {
			const mapping = values.mapping;
			let errors: {
				[K in keyof FormValues]?: any;
			} = {};
			let allValuesEmpty = true;
			for (const header of headerRow) {
				const option = mapping[header.externalField];
				if (!option) {
					continue;
				}
				allValuesEmpty = false;
				for (const headerToCompare of headerRow) {
					const optionToCompare = mapping[headerToCompare.externalField];
					if (option === optionToCompare && header.externalField !== headerToCompare.externalField) {
						errors.mapping = {
							...errors.mapping,
							[header.externalField]: t("Data field can only be selected once"),
						};
					}
				}
			}

			const missingRequiredAssignments = importableGuestAttributeOptions.filter(
				(option) => option.required && !Object.values(mapping).includes(option),
			);
			if (!allValuesEmpty && missingRequiredAssignments.length) {
				errors.missingRequiredAssignments = t("The following attributes must be assigned: {{attributes}}", {
					attributes: missingRequiredAssignments.map(({ name }) => name).join(", "),
				});
			}

			if (importProviderType === "zoho" && Object.values(mapping).filter((value) => value).length > 50) {
				errors.tooManyValues = t("You may only assign a maximum of 50 guest attributes.");
			}

			if (allValuesEmpty) {
				errors.allValuesEmpty = t("Please assign your data to the existing data fields.");
			}

			if (validateAdditionalInformation && additionalValues) {
				errors = { ...errors, ...validateAdditionalInformation(values) };
			}

			return errors;
		},
		[
			additionalValues,
			headerRow,
			importProviderType,
			importableGuestAttributeOptions,
			t,
			validateAdditionalInformation,
		],
	);

	const options: ImportableAttributeOption[] = useMemo(() => {
		if (importProviderType === "excel") {
			return ["guest-id", ...importableGuestAttributeOptions];
		} else {
			return importableGuestAttributeOptions;
		}
	}, [importableGuestAttributeOptions, importProviderType]);

	const initialValues: FormValues = useMemo(() => {
		return {
			...additionalValues,
			tags: [],
			groups: [],
			mapping: {},
		};
	}, [additionalValues]);

	const guessImportableAttributeOptionByHeader = useCallback(
		(header: TImportHeader): ImportableAttributeOption | undefined => {
			const fieldIdentifier = header.label.split(": ")[1].toLowerCase();
			if (header.externalField.toLowerCase() === "id" || fieldIdentifier === "id") {
				return "guest-id";
			}
			return importableGuestAttributeOptions.find((item) => item.name.toLowerCase() === fieldIdentifier);
		},
		[importableGuestAttributeOptions],
	);

	const automaticallyAssignGuestAttributes = useCallback(
		async (formik: FormikProps<FormValues>) => {
			const mapping = headerRow.reduce(
				(acc, currentHeader, currentIndex): FormValues["mapping"] => {
					const shouldSkip =
						headerRow.findIndex((header) => header.externalField === currentHeader.externalField) < currentIndex;
					if (shouldSkip) {
						return acc;
					}
					const guessedOption = guessImportableAttributeOptionByHeader(currentHeader);
					if (!guessedOption) {
						return acc;
					}

					return {
						...acc,
						[currentHeader.externalField]: guessedOption,
					};
				},
				{} as FormValues["mapping"],
			);

			await formik.setFieldValue("mapping", mapping);
		},
		[guessImportableAttributeOptionByHeader, headerRow],
	);

	return (
		<Formik initialValues={initialValues} onSubmit={handleImportWithConfirmation} validate={validate}>
			{(formik: FormikProps<FormValues>) => (
				<>
					<Form>
						<Card>
							<CardHeader>
								<CardHeading>{t("Participant Import")}</CardHeading>
								<Button
									prefix={<BaseIcon src="/icons/wizard.svg" title={t("Automatically assign matching data fields.")} />}
									onClick={() =>
										formik.dirty ? setAutoAssignConfirmationOpen(true) : automaticallyAssignGuestAttributes(formik)
									}
									size="small"
									title={t("Automatically assign matching data fields.")}
									type="button"
								>
									{t("Assign data fields")}
								</Button>
							</CardHeader>
							<CardBody>
								{filterEnabled && (
									<>
										{importProviderType === "zoho" && (
											<>
												<StyledDiv>
													{t("Please select a field and value to filter and import only the relevant guests.")}
												</StyledDiv>
												<FilterDiv>
													<ZohoFilterFieldSingleSelect
														options={headerRow}
														label={t("Filter field")}
														name="filterField"
														required={true}
													/>
													<TextField name="filterValue" label={t("Filter value")} required={true} />
												</FilterDiv>
											</>
										)}
										{importProviderType === "jarvis" && (
											<DistributionListSingleSelectField name="distributionListId" label={t("Distribution list")} />
										)}
										<TagMultiSelect name="tags" label={t("Tags")} />
										<GroupMultiSelect name="groups" label={t("Groups")} />
										<CheckboxField label={t("Only import new guests")} name="importOnlyDifferences" />
										<Separator />
									</>
								)}
								{children}
								<StyledDiv>{t("Please assign your data to the existing data fields.")}</StyledDiv>
								{requiredAttributeNames.length > 0 && (
									<StyledDiv>
										{t("The following attributes must be assigned: {{attributes}}", {
											attributes: requiredAttributeNames.join(", "),
										})}
									</StyledDiv>
								)}
								{headerRow.map((header) => (
									<ImportableAttributeSelect
										key={header.externalField}
										name={`mapping.${header.externalField}`}
										label={header.label}
										options={options}
										importProviderType={importProviderType}
									/>
								))}
							</CardBody>
							<CardFooter>
								{formik.errors.tooManyValues && <ErrorText>{`${formik.errors.tooManyValues}`}</ErrorText>}
								{formik.errors.allValuesEmpty && <ErrorText>{`${formik.errors.allValuesEmpty}`}</ErrorText>}
								{formik.errors.missingRequiredAssignments && (
									<ErrorText>{`${formik.errors.missingRequiredAssignments}`}</ErrorText>
								)}
								<SubmitButton disabled={!!formik.errors.tooManyValues}>{t("Import")}</SubmitButton>
							</CardFooter>
						</Card>
					</Form>
					<ConfirmationDialog
						heading={t("Corrective Import")}
						labels={{ close: t("Close"), confirm: t("Confirm") }}
						open={confirmationIsOpen}
						onClose={() => setConfirmationIsOpen(false)}
						action={() => handleImport(formik.values)}
					>
						{t("Are you sure you want to overwrite existing guest date with this import?")}
					</ConfirmationDialog>
					<ConfirmationDialog
						heading={t("Automatically assign")}
						labels={{ close: t("Close"), confirm: t("Confirm") }}
						open={autoAssignConfirmationOpen}
						onClose={() => setAutoAssignConfirmationOpen(false)}
						action={() => automaticallyAssignGuestAttributes(formik)}
					>
						{t("Are you sure you want to overwrite existing attribute assignment?")}
					</ConfirmationDialog>
				</>
			)}
		</Formik>
	);
}
