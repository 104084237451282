import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { Settings } from "./settings";
import { TenantList } from "./tenants";
import { WavePage } from "./waves/WavePage";

export const NominationModulePage: FC = () => {
	return (
		<Routes>
			<Route path="tenants" element={<TenantList />} />
			<Route path="waves/*" element={<WavePage />} />
			<Route path="settings" element={<Settings />} />
		</Routes>
	);
};
