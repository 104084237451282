import {
	CheckboxField,
	CheckboxGroupField,
	DateField,
	DateTimeField,
	MultiSelectField,
	SingleSelectField,
	TextField,
	TextareaField,
	TimeField,
} from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { StatusSelect } from "~shared/guests/details/StatusSelect";
import { ImportErrorListBulkEditEntityType } from "~shared/import/importErrorListBulkEditTypes";
import { ContentLanguageImportSingleSelect } from "~shared/selects/ContentLanguageSingleSelect";
import { GroupMultiSelect } from "~shared/selects/GroupMultiSelect";
import { RegistrationFormSingleSelect } from "~shared/selects/RegistrationFormSingleSelect";
import { TagMultiSelect } from "~shared/selects/TagMultiSelect";
import { TenantSelectField } from "~shared/selects/TenantSelectField";
import { WaveSelectField } from "~shared/selects/WaveSelectField";
import { IDataFieldOptionOptionResource } from "~shared/types/dataFieldOption";
import { DataFieldType } from "~shared/types/dataFieldType";

export const DataFieldValueField: FC<{
	valueFieldName: string;
	type: ImportErrorListBulkEditEntityType | DataFieldType;
	options: IDataFieldOptionOptionResource[];
}> = ({ valueFieldName, type, options }) => {
	const { t } = useTranslation();
	const props = { name: valueFieldName, label: t("Value") };

	switch (type) {
		case ImportErrorListBulkEditEntityType.tenant:
			return <TenantSelectField {...props} />;
		case ImportErrorListBulkEditEntityType.wave:
			return <WaveSelectField {...props} />;
		case ImportErrorListBulkEditEntityType.registrationForm:
			return <RegistrationFormSingleSelect {...props} />;
		case ImportErrorListBulkEditEntityType.status:
			return <StatusSelect {...props} />;
		case ImportErrorListBulkEditEntityType.groups:
			return <GroupMultiSelect {...props} />;
		case ImportErrorListBulkEditEntityType.tags:
			return <TagMultiSelect {...props} />;
		case ImportErrorListBulkEditEntityType.registrationLocale:
			return <ContentLanguageImportSingleSelect {...props} />;
		case DataFieldType.RADIO:
		case DataFieldType.SELECT_FIELD:
			return <SingleSelectField options={options} {...props} getLabel={(o) => o.value} />;
		case DataFieldType.MULTI_SELECT_FIELD:
			return <MultiSelectField options={options} {...props} getLabel={(o) => o.value} />;
		case DataFieldType.CHECKBOX_GROUP:
			return (
				<CheckboxGroupField
					{...props}
					options={options.map((x) => ({
						name: x.value,
						value: x.id,
					}))}
				/>
			);
		case DataFieldType.DATE:
			return <DateField {...props} />;
		case DataFieldType.DATETIME:
			return <DateTimeField {...props} />;
		case DataFieldType.TIME:
			return <TimeField {...props} />;
		case DataFieldType.CHECKBOX:
			return <CheckboxField {...props} />;
		case DataFieldType.NUMBER:
			return <TextField type="number" {...props} />;
		case DataFieldType.CRYPT:
		case DataFieldType.TEXT:
		case ImportErrorListBulkEditEntityType.registrationCode:
			return <TextField {...props} />;
		case DataFieldType.EMAIL:
			return <TextField {...props} />;
		case DataFieldType.LONG_TEXT:
			return <TextareaField {...props} />;
		case DataFieldType.URL:
			return <TextField {...props} />;
		case DataFieldType.PHONE:
			return <TextField type="tel" {...props} />;
		default:
			return null;
	}
};
