import { GuestFilterAttributeType, IGuestFilterOperator } from "~shared/guestFilters/guestFilterTypes";

export const guestFilterOperatorMap: { [key in GuestFilterAttributeType]: IGuestFilterOperator[] } = {
	checkbox: ["EQUAL"],
	checkboxGroup: ["IS_EMPTY", "IS_NOT_EMPTY", "EQUAL", "NOT_EQUAL", "WHERE_IN", "WHERE_NOT_IN"],
	date: ["IS_EMPTY", "IS_NOT_EMPTY", "GREATER_THAN_EQUAL", "LESS_THAN_EQUAL", "EQUAL", "NOT_EQUAL", "BETWEEN"],
	crypt: ["IS_EMPTY", "IS_NOT_EMPTY"],
	email: [
		"IS_EMPTY",
		"IS_NOT_EMPTY",
		"CONTAINS",
		"NOT_CONTAINS",
		"EQUAL",
		"NOT_EQUAL",
		"WHERE_IN_CONTAINS",
		"WHERE_NOT_IN_CONTAINS",
	],
	file: ["IS_EMPTY", "IS_NOT_EMPTY"],
	dateTime: ["IS_EMPTY", "IS_NOT_EMPTY", "GREATER_THAN_EQUAL", "LESS_THAN_EQUAL", "EQUAL", "NOT_EQUAL", "BETWEEN"],
	longText: ["IS_EMPTY", "IS_NOT_EMPTY", "CONTAINS", "NOT_CONTAINS", "EQUAL", "NOT_EQUAL", "WHERE_NOT_IN_CONTAINS"],
	number: ["IS_EMPTY", "IS_NOT_EMPTY", "GREATER_THAN_EQUAL", "LESS_THAN_EQUAL", "EQUAL", "NOT_EQUAL"],
	url: ["IS_EMPTY", "IS_NOT_EMPTY", "CONTAINS", "NOT_CONTAINS", "EQUAL", "NOT_EQUAL", "WHERE_NOT_IN_CONTAINS"],
	phone: ["IS_EMPTY", "IS_NOT_EMPTY", "CONTAINS", "NOT_CONTAINS", "EQUAL", "NOT_EQUAL", "WHERE_NOT_IN_CONTAINS"],
	multiSelectField: ["IS_EMPTY", "IS_NOT_EMPTY", "EQUAL", "NOT_EQUAL", "WHERE_IN", "WHERE_NOT_IN"],
	radioField: ["IS_EMPTY", "IS_NOT_EMPTY", "EQUAL", "NOT_EQUAL", "WHERE_IN", "WHERE_NOT_IN"],
	time: ["IS_EMPTY", "IS_NOT_EMPTY", "GREATER_THAN_EQUAL", "LESS_THAN_EQUAL", "EQUAL", "NOT_EQUAL"],
	text: ["IS_EMPTY", "IS_NOT_EMPTY", "CONTAINS", "NOT_CONTAINS", "EQUAL", "NOT_EQUAL", "WHERE_NOT_IN_CONTAINS"],
	selectField: ["IS_EMPTY", "IS_NOT_EMPTY", "EQUAL", "NOT_EQUAL", "WHERE_IN", "WHERE_NOT_IN"],

	// relations
	registrationForm: ["IS_EMPTY", "IS_NOT_EMPTY", "EQUAL", "NOT_EQUAL", "WHERE_IN", "WHERE_NOT_IN"],
	tenant: ["EQUAL", "NOT_EQUAL", "WHERE_IN", "WHERE_NOT_IN"],
	wave: ["EQUAL", "NOT_EQUAL", "WHERE_IN", "WHERE_NOT_IN"],
	companionParent: ["IS_EMPTY", "IS_NOT_EMPTY", "EQUAL", "NOT_EQUAL", "WHERE_IN", "WHERE_NOT_IN"],
	tags: ["IS_EMPTY", "IS_NOT_EMPTY", "EQUAL", "NOT_EQUAL", "WHERE_IN", "WHERE_NOT_IN"],
	groups: ["IS_EMPTY", "IS_NOT_EMPTY", "EQUAL", "NOT_EQUAL", "WHERE_IN", "WHERE_NOT_IN"],
	checkInOutLogs: ["IS_EMPTY", "IS_NOT_EMPTY"],
	companions: ["IS_EMPTY", "IS_NOT_EMPTY", "EQUAL", "NOT_EQUAL", "WHERE_IN", "WHERE_NOT_IN"],
	agreedConsents: ["IS_EMPTY", "IS_NOT_EMPTY", "EQUAL", "NOT_EQUAL", "WHERE_IN", "WHERE_NOT_IN"],
	locations: ["IS_EMPTY", "IS_NOT_EMPTY"],
	waitingList: ["IS_EMPTY", "IS_NOT_EMPTY"],
	replacement: ["IS_EMPTY", "IS_NOT_EMPTY"],
	replacementParent: ["IS_EMPTY", "IS_NOT_EMPTY"],
	guestFiles: ["IS_EMPTY", "IS_NOT_EMPTY"],

	// columns
	createdAt: ["IS_EMPTY", "IS_NOT_EMPTY", "GREATER_THAN_EQUAL", "LESS_THAN_EQUAL", "EQUAL", "NOT_EQUAL", "BETWEEN"],
	id: ["IS_EMPTY", "IS_NOT_EMPTY"],
	registrationLocale: ["IS_EMPTY", "IS_NOT_EMPTY", "CONTAINS", "NOT_CONTAINS", "EQUAL", "NOT_EQUAL"],
	registrationCode: ["IS_EMPTY", "IS_NOT_EMPTY", "EQUAL"],
	referenceId: ["EQUAL", "NOT_EQUAL"],
	registrationTimestamp: [
		"IS_EMPTY",
		"IS_NOT_EMPTY",
		"GREATER_THAN_EQUAL",
		"LESS_THAN_EQUAL",
		"EQUAL",
		"NOT_EQUAL",
		"BETWEEN",
	],
	status: ["WHERE_IN", "WHERE_NOT_IN", "EQUAL", "NOT_EQUAL"],
	screeningStatus: ["WHERE_IN", "WHERE_NOT_IN", "EQUAL", "NOT_EQUAL"],
	updatedAt: ["IS_EMPTY", "IS_NOT_EMPTY", "GREATER_THAN_EQUAL", "LESS_THAN_EQUAL", "EQUAL", "NOT_EQUAL", "BETWEEN"],
};
