import { Id, LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios from "axios";
import { IOptionService } from "~shared/api/baseService";
import { IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";
import {
	ICreateHotelWithRoomTypeRequest,
	IHotelBookingResource,
	IHotelBookingsRequest,
	IHotelIndex,
	IHotelRequest,
	IHotelResource,
} from "~shared/types/hotel";

export class HotelService extends IOptionService<IHotelIndex, IHotelResource, IHotelRequest> {
	public keys = {
		index: ["hotels"],
		list: ["hotels", "list"],
		bookings: ["hotels", "bookings"],
		options: ["hotels", "options"],
		createWithRoomType: ["hotels", "create-with-room-type"],
		details: (hotelId: Id) => ["hotels", `${hotelId}`],
		export: ["hotels", "export"],
	};
	protected urls = {
		index: keyToUrl(this.keys.index),
		options: keyToUrl(this.keys.options),
		bookings: keyToUrl(this.keys.bookings),
		details: (hotelId: Id) => keyToUrl(this.keys.details(hotelId)),
		createWithRoomType: keyToUrl(this.keys.createWithRoomType),
		export: keyToUrl(this.keys.export),
	};

	storeWithRoomType = (request: ICreateHotelWithRoomTypeRequest) => {
		return axios.post<LaravelResponse<IHotelIndex>>(this.urls.createWithRoomType, request);
	};
	bookings = (
		request: IHotelBookingsRequest,
		filter: IGuestFilterFormValueEntry[],
		listFetcherConfig: ListFetcherConfig<any>,
	) => {
		return axios.post<LaravelResponse<IHotelBookingResource[]>>(
			this.urls.bookings,
			{
				...request,
				filters: filter,
				sort: listFetcherConfig.sortParams
					? {
							guestAttributeId: listFetcherConfig.sortParams.column,
							direction: listFetcherConfig.sortParams.direction,
					  }
					: null,
			},
			{
				params: {
					...listFetcherConfig.pageParams,
					...listFetcherConfig.searchParams,
				},
			},
		);
	};
	export = (request: IHotelBookingsRequest, filter: IGuestFilterFormValueEntry[]) => {
		return axios.post<LaravelResponse<IHotelBookingResource>>(this.urls.export, { ...request, filters: filter });
	};
}
export const hotelService = new HotelService();
