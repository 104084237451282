import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { IProductTranslation, IProductTranslationFormValues } from "~shared/types/productTranslation";

export const productTranslationsKeys = {
	details: ["product-translations"],
	update: ["product-translations"],
};

export const productTranslationsUrls = {
	details: keyToUrl(productTranslationsKeys.details),
	update: keyToUrl(productTranslationsKeys.update),
};

const get = () => axios.get<LaravelResponse<IProductTranslation>>(productTranslationsUrls.details);
const put = (productTranslations: IProductTranslationFormValues) =>
	axios.put<LaravelResponse<IProductTranslation>>(productTranslationsUrls.update, productTranslations);

export const productTranslationsService = {
	get,
	put,
};
