import { ContextMenu, ContextMenuDeleteItem, Id } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useFiles } from "~admin/files/useFiles";
import { IFile } from "~shared/types/file";

interface Props {
	file: IFile;
}

export const FileListContextMenu: FC<Props> = ({ file }) => {
	const { deleteFile } = useFiles();
	const { t } = useTranslation();

	const handleDelete = useCallback(
		async (fileId: Id) => {
			await deleteFile(fileId);
		},
		[deleteFile],
	);

	return (
		<ContextMenu displayShortcutsAs="icon">
			<ContextMenuDeleteItem
				action={() => handleDelete(file.id)}
				title={t("Delete")}
				label={t("Delete")}
				heading={t("Delete")}
				labels={{ close: t("Close"), confirm: t("Confirm") }}
			>
				{t("Are you sure you want to delete this file?")}
			</ContextMenuDeleteItem>
		</ContextMenu>
	);
};
