import { Card, DataList, DataListColumnTitle, DataListHeader, DataListItem } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IDataFieldContingentResource } from "~shared/types/dataField";

interface DataFieldContingentOverviewDataListProps {
	dataFieldContingent: IDataFieldContingentResource;
}
export const DataFieldContingentOverviewDataList: FC<DataFieldContingentOverviewDataListProps> = ({
	dataFieldContingent,
}) => {
	const { t } = useTranslation();

	return (
		<Card>
			<DataListHeader heading={dataFieldContingent.name} />
			<DataList isEmpty={dataFieldContingent.options.length === 0} empty={t("No options available.")}>
				<DataListColumnTitle>{t("Name")}</DataListColumnTitle>
				<DataListColumnTitle>{t("Contingent")}</DataListColumnTitle>
				<DataListColumnTitle>{t("Used contingent")}</DataListColumnTitle>
				<DataListColumnTitle>{t("Available contingent")}</DataListColumnTitle>
				{dataFieldContingent.options.map((dataFieldOptionContingent) => (
					<React.Fragment key={dataFieldOptionContingent.id}>
						<DataListItem>{dataFieldOptionContingent.value}</DataListItem>
						<DataListItem>{dataFieldOptionContingent.contingent}</DataListItem>
						<DataListItem>{dataFieldOptionContingent.usedContingent}</DataListItem>
						<DataListItem>{dataFieldOptionContingent.availableContingent}</DataListItem>
					</React.Fragment>
				))}
			</DataList>
		</Card>
	);
};
