import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardHeading,
	PagedSingleSelectField,
	SubmitButton,
	TextField,
} from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { CurrencySingleSelect } from "~admin/shared/selects/CurrencySingleSelect";
import { InvoiceProviderSingleSelect } from "~admin/shared/ui/select";
import { dataFieldService } from "~shared/api/dataFields";
import { DataFieldType } from "~shared/types/dataFieldType";
import { IShopSettings } from "~shared/types/shop";
import { FormikWithRedirect } from "~shared/ui/FormikWithRedirect";

interface Props {
	initialData: IShopSettings;
	onSubmit: (settings: IShopSettings) => Promise<boolean>;
}

const REQUIRED_FIELDS: (keyof IShopSettings)[] = [
	"zipDataField",
	"documentPrefix",
	"defaultInvoiceProvider",
	"currency",
	"streetDataField",
	"isBusinessDataField",
	"houseNumberDataField",
	"cityDataField",
	"countryDataField",
	"invoiceRecipientDataField",
	"vatIdDataField",
];

const dataFieldFetcher = (type: DataFieldType) => (search: string, page: number) =>
	dataFieldService.options(search, page, { type }).then((r) => r.data);

export const ShopSettingsForm: FC<Props> = ({ initialData, onSubmit }) => {
	const { t } = useTranslation();

	const validate = (values: IShopSettings) => {
		const errors: { [K in keyof IShopSettings]?: any } = {};
		REQUIRED_FIELDS.forEach((requiredField) => {
			if (!values[requiredField]) {
				errors[requiredField] = t("Required field");
			}
		});
		return errors;
	};

	return (
		<FormikWithRedirect initialValues={initialData} validate={validate} onSubmit={onSubmit}>
			<Card>
				<CardHeader>
					<CardHeading>{t("Settings")}</CardHeading>
				</CardHeader>
				<CardBody>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.TEXT)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.TEXT)}
						label={t("Invoice recipient")}
						name="invoiceRecipientDataField"
						compare={(first, second) => first.id === second.id}
						required={true}
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.CHECKBOX)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.CHECKBOX)}
						label={t("Is business")}
						name="isBusinessDataField"
						compare={(first, second) => first.id === second.id}
						required={true}
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.TEXT)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.TEXT)}
						label={t("VAT ID")}
						name="vatIdDataField"
						compare={(first, second) => first.id === second.id}
						required={true}
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.TEXT)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.TEXT)}
						label={t("Street")}
						name="streetDataField"
						compare={(first, second) => first.id === second.id}
						required={true}
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.TEXT)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.TEXT)}
						label={t("House number")}
						name="houseNumberDataField"
						compare={(first, second) => first.id === second.id}
						required={true}
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.TEXT)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.TEXT)}
						label={t("City")}
						name="cityDataField"
						compare={(first, second) => first.id === second.id}
						required={true}
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.TEXT)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.TEXT)}
						label={t("ZIP")}
						name="zipDataField"
						compare={(first, second) => first.id === second.id}
						required={true}
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.SELECT_FIELD)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.SELECT_FIELD)}
						label={t("Country")}
						name="countryDataField"
						compare={(first, second) => first.id === second.id}
						required={true}
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.TEXT)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.TEXT)}
						label={t("Customer invoice reference")}
						name="customerReferenceDataField"
						compare={(first, second) => first.id === second.id}
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.EMAIL)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.EMAIL)}
						label={t("CC email for invoice email")}
						name="ccInvoiceEmailDataField"
						compare={(first, second) => first.id === second.id}
					/>
					<CurrencySingleSelect label={t("Currency")} name="currency" required={true} />
					<InvoiceProviderSingleSelect name="defaultInvoiceProvider" label={t("Default invoice provider")} />
					<TextField name="documentPrefix" label={t("Document prefix")} required={true} />
				</CardBody>
				<CardFooter>
					<SubmitButton>{t("Save")}</SubmitButton>
				</CardFooter>
			</Card>
		</FormikWithRedirect>
	);
};
