import {
	Button,
	CheckboxField,
	DateField,
	EntityDrawerProps,
	I18NCrudDrawer,
	I18nErrorToast,
	I18nOnlyOnMainForm,
	I18nTextField,
	I18nTextareaField,
	Icon,
	IconButton,
	TextField,
	mapErrors,
} from "@dgs/core";
import { FieldArray } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CountrySingleSelect } from "~admin/shared/selects/CountrySingleSelect";
import { TaxSingleSelect } from "~admin/shared/selects/TaxSingleSelect";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { IShopItemFormValues } from "~shared/types/shop";

const TaxesHeading = styled.div`
	margin: 1em 0;
`;

const InputContainer = styled.div`
	margin: 1rem 0;
`;

const TaxRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr max-content max-content;
	gap: 1rem;
	align-items: center;
`;

export const ShopItemDrawer: FC<EntityDrawerProps<IShopItemFormValues>> = (props) => {
	const { t } = useTranslation();
	const { fallbackLocale, locales } = useContentLocale();

	const validate = useCallback(
		(values: IShopItemFormValues) => {
			let errors: Record<string, any> = {};

			if (!values.price) {
				errors.price = t("Required field");
			}
			if (!values.defaultTax) {
				errors.defaultTax = t("Required field");
			}
			if (values.depositInPercent && !values.depositEndDate) {
				errors.depositEndDate = t("Required field");
			}
			if (values.minPerGuest && values.maxPerGuest && values.minPerGuest > values.maxPerGuest) {
				errors.minPerGuest = t("Must be smaller than max. per guest.");
				errors.maxPerGuest = t("Must be larger than min. per guest.");
			}
			if (values.contingent && values.contingent < 0) {
				errors.contingent = t("The value cannot be negative");
			}
			values.taxes.forEach((tax, index) => {
				if ((!tax.tax || !tax.country) && !errors.taxes) {
					errors.taxes = { [index]: {} };
				}
				if (!tax.tax) {
					errors.taxes[index] = { ...errors.taxes[index], tax: t("Required field") };
				}
				if (!tax.country) {
					errors.taxes[index] = { ...errors.taxes[index], country: t("Required field") };
				}
			});

			const translations = values.translations[fallbackLocale] || {};

			if (!translations.name) {
				errors = mapErrors(errors, fallbackLocale, "name", t);
			}

			return errors;
		},
		[fallbackLocale, t],
	);

	return (
		<I18NCrudDrawer
			{...props}
			fallbackLocale={fallbackLocale}
			locales={locales}
			validate={validate}
			body={({ values }) => (
				<>
					<I18nTextField name="name" label={t("Name")} required="onlyOnMainForm" />
					<I18nTextareaField name="description" label={t("Description")} />
					<I18nOnlyOnMainForm>
						<TextField name="price" label={t("Price")} required={true} />
						<TaxSingleSelect name="defaultTax" label={t("Default tax")} required={true} />
						<CheckboxField name="taxIncluded" label={t("Tax included")} />
						<CheckboxField name="enableDeposit" label={t("Enable deposit")} />
						<TextField type="number" name="minPerGuest" label={t("Min. per guest")} />
						<TextField type="number" name="maxPerGuest" label={t("Max. per guest")} />
						<TextField type="number" name="contingent" label={t("Contingent")} />
						{values.enableDeposit && (
							<InputContainer>
								<TextField name="depositInPercent" label={t("Deposit in percent")} />
								<DateField name="depositEndDate" label={t("Deposit end date")} />
							</InputContainer>
						)}
						<TaxesHeading>{t("Taxes")}:</TaxesHeading>
						<FieldArray
							name="taxes"
							render={(arrayHelpers) => (
								<InputContainer>
									{values.taxes.map((_, index) => (
										<TaxRow key={index}>
											<TaxSingleSelect name={`taxes[${index}]tax`} label={t("Tax")} />
											<CountrySingleSelect name={`taxes[${index}]country`} label={t("Country")} />
											<CheckboxField name={`taxes[${index}]isBusiness`} label={t("Is business")} />
											<IconButton
												title={t("Delete tax")}
												type="button"
												size="small"
												color="default"
												onClick={() => arrayHelpers.remove(index)}
												icon="delete"
											/>
										</TaxRow>
									))}
									<Button
										type="button"
										color="default"
										size="small"
										prefix={<Icon icon="plus" />}
										onClick={() =>
											arrayHelpers.push({
												tax: null,
												country: null,
												isBusiness: false,
											})
										}
									>
										{t("Add")}
									</Button>
								</InputContainer>
							)}
						/>
					</I18nOnlyOnMainForm>
					<I18nErrorToast />
				</>
			)}
		/>
	);
};
