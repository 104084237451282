import { FormProps, I18nBlockForm, IBaseBlockEditResource } from "@dgs/core";
import React, { FC } from "react";
import { emailButtonBlockForm } from "./EmailButtonBlock";
import { EmailButtonBlockFormFields } from "./EmailButtonBlockFormFields";

export const EmailButtonBlockForm: FC<FormProps<IBaseBlockEditResource>> = ({ block, onSubmit }) => {
	return (
		<I18nBlockForm block={block} onSubmit={onSubmit} blockForm={emailButtonBlockForm}>
			<EmailButtonBlockFormFields blockType={block.type} />
		</I18nBlockForm>
	);
};
