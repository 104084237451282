import {
	BlockSingleSelectField,
	CheckboxControl,
	DateTimeField,
	I18NCrudDrawer,
	I18nOnlyOnMainForm,
	I18nTextField,
	MenuItemTypeSelectField,
	MenuItemVisibilityField,
	SingleSelectField,
	Text,
	TextField,
	TimeControlledControl,
	TimeControlledPreviewTypeField,
	mapErrors,
} from "@dgs/core";
import { isPast, parseISO } from "date-fns";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useStateWithDependency } from "~admin/shared/hooks";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { IMenuItemFormState, IMenuItemRequest } from "~shared/types/menuItem";
import { initialMenuItemTypes, initialVisibilityOptions } from "~shared/utils/menuItem";

interface Props {
	open: boolean;
	onClose: () => void;
	title: string;
	menuItem: IMenuItemFormState;
	handleSubmit: (menuItem: IMenuItemRequest) => Promise<boolean>;
}

const Spaced = styled.div`
	padding-bottom: ${({ theme }) => theme.spacing(4)};
`;

const pages = ["/registrations"];

type Errors = { [key in keyof IMenuItemFormState]?: string };

export const MenuItemDrawer: FC<Props> = ({ open, onClose, title, handleSubmit, menuItem }) => {
	const { t } = useTranslation();
	const { fallbackLocale, locales } = useContentLocale();
	const [isTimeControlled, setIsTimeControlled] = useStateWithDependency(!!menuItem.previewUntil);
	const [isPredefinedLink, setIsPredefinedLink] = useState(true);
	const menuItemTypes = initialMenuItemTypes;

	const validate = useCallback(
		(values: IMenuItemFormState) => {
			let errors: Errors = {};
			if (values.type !== "PAGE" && values.url === "") {
				errors.url = t("Required field");
			}
			if (values.type === "PAGE" && !values.block) {
				errors.block = t("Required field");
			}
			if (values.previewUntil !== null && isPast(parseISO(values.previewUntil))) {
				errors.previewUntil = t("Date and time must be in the future");
			}

			const translations = values.translations[fallbackLocale] || {};

			if (!translations.name) {
				errors = mapErrors(errors, fallbackLocale, "label", t);
			}

			return errors;
		},
		[fallbackLocale, t],
	);

	const onSubmit = async ({ block, previewBlock, ...values }: IMenuItemFormState) => {
		const mappedValues: IMenuItemRequest = {
			...values,
			previewBlockId: previewBlock?.id ?? null,
			blockId: block?.id ?? null,
		};

		if (!isTimeControlled) {
			mappedValues.previewUntil = null;
			mappedValues.previewType = null;
			mappedValues.previewBlockId = null;
			mappedValues.previewUrl = "";
		}

		return handleSubmit(mappedValues);
	};

	return (
		<I18NCrudDrawer
			open={open}
			onSubmit={onSubmit}
			body={(formik) => (
				<>
					<I18nTextField label={t("Label")} name="name" required="onlyOnMainForm" />
					<I18nOnlyOnMainForm>
						<MenuItemTypeSelectField menuItemTypes={menuItemTypes} required={true} />
						{formik.values.type === "PAGE" && (
							<BlockSingleSelectField name="block" label={t("Block")} blockType="pageBlock" />
						)}
						{formik.values.type === "INTERNAL" && (
							<Spaced>
								<CheckboxControl
									label={t("Predefined Link")}
									name="isPredefinedLink"
									value={isPredefinedLink}
									onChange={(e) => setIsPredefinedLink(e.target.value)}
								/>
							</Spaced>
						)}
						{formik.values.type !== "PAGE" &&
							(formik.values.type !== "INTERNAL" || !isPredefinedLink) &&
							formik.values.type !== "CATEGORY" && <TextField label={t("Link")} name="url" required={true} />}
						{formik.values.type === "INTERNAL" && isPredefinedLink && (
							<SingleSelectField
								name="url"
								label={t("Link")}
								options={pages}
								getLabel={(e) => e}
								renderOption={(e) => <Text>{e}</Text>}
								required={true}
							/>
						)}
						<MenuItemVisibilityField
							options={initialVisibilityOptions}
							name="visibility"
							label={t("Visibility")}
							required={true}
						/>
						<TimeControlledControl value={isTimeControlled} onChange={() => setIsTimeControlled(!isTimeControlled)} />
						{isTimeControlled && (
							<>
								<DateTimeField name="previewUntil" label={t("Preview until")} />
								<TimeControlledPreviewTypeField menuItemTypes={menuItemTypes} />
								{formik.values.previewType !== "PAGE" && <TextField name="previewUrl" label={t("Preview URL")} />}
								{formik.values.previewType === "PAGE" && (
									<BlockSingleSelectField
										name="previewBlock"
										label={t("Preview Block")}
										blockType="pageBlock"
										clearable={true}
									/>
								)}
							</>
						)}
					</I18nOnlyOnMainForm>
				</>
			)}
			initialValues={menuItem}
			onClose={onClose}
			heading={title}
			fallbackLocale={fallbackLocale}
			locales={locales}
			validate={validate}
		/>
	);
};
