import { SingleSelectControl } from "@dgs/core";
import { useField } from "formik";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { DataFieldValueField } from "~shared/guests";
import { DataFieldType } from "~shared/types/dataFieldType";
import { ImportErrorListBulkEditEntityType, ImportErrorListBulkEditOption } from "./importErrorListBulkEditTypes";

const entityFieldName = "entity";
const valueFieldName = "newValue";

interface Props {
	options: ImportErrorListBulkEditOption[];
}

export const ImportErrorListBulkEditFormFields: FC<Props> = ({ options }) => {
	const { t } = useTranslation();
	const [entityField, meta] = useField<ImportErrorListBulkEditOption>(entityFieldName);
	const [valueField] = useField(valueFieldName);

	const valueNullValue = { target: { name: valueFieldName, value: null } };
	const valueEmptyArrayValue = { target: { name: valueFieldName, value: [] } };
	const [search, setSearch] = useState("");

	const filteredOptions = useMemo(() => {
		return options.filter((o) => o.name.toLowerCase().includes(search.toLowerCase()));
	}, [options, search]);

	return (
		<>
			<SingleSelectControl
				options={filteredOptions}
				label={t("Property")}
				name={entityFieldName}
				value={entityField.value}
				onChange={(e) => {
					const value = e.target.value;
					entityField.onChange(e);

					if (
						value &&
						(value.type === DataFieldType.MULTI_SELECT_FIELD ||
							value.type === DataFieldType.CHECKBOX_GROUP ||
							value.entityType === ImportErrorListBulkEditEntityType.groups ||
							value.entityType === ImportErrorListBulkEditEntityType.tags)
					) {
						valueField.onChange(valueEmptyArrayValue);
					} else {
						valueField.onChange(valueNullValue);
					}
				}}
				onClear={() => entityField.onChange({ target: { name: entityFieldName, value: null } })}
				error={meta.touched && meta.error ? meta.error : undefined}
				search={search}
				onSearch={setSearch}
				required
			/>

			{entityField.value && (
				<DataFieldValueField
					valueFieldName={valueFieldName}
					type={entityField.value.type || entityField.value.entityType}
					options={entityField.value.options ?? []}
				/>
			)}
		</>
	);
};
