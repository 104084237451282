import { IDataListSortParams, ListFetcherConfig } from "@dgs/core";
import { AxiosRequestConfig } from "axios";
import { GuestIndexResource } from "~shared/types/newGuestTypes";

export const mapFetcherConfigToAxiosConfig = (config: ListFetcherConfig<any>): AxiosRequestConfig => ({
	params: {
		...config.searchParams,
		...config.pageParams,
	},
});

export const mapSortParamsToGuestListSortParams = (sortParams: IDataListSortParams<GuestIndexResource> | null) => ({
	sort: sortParams
		? {
				guestAttributeId: sortParams.column,
				direction: sortParams.direction,
		  }
		: null,
});
