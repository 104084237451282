import { AutoDataList, Id } from "@dgs/core";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { HotelInquiryAcceptDrawer } from "~admin/hotels/hotelInquiries/HotelInquiryAcceptDrawer";
import { hotelInquiryService } from "~shared/api/hotelInquiries";
import { useHotelInquiriesListColumns } from "./HotelInquiriesListColumns";

export const PendingHotelInquiriesList: FC = () => {
	const { t } = useTranslation();
	const [hotelInquiryId, setHotelInquiryId] = useState<Id | null>(null);
	const columns = useHotelInquiriesListColumns();

	return (
		<>
			<AutoDataList
				heading={t("Pending hotel requests")}
				empty={t("No hotel requests available.")}
				columns={columns}
				queryKey={hotelInquiryService.keys.pending}
				fetcher={hotelInquiryService.pending}
				onRowClick={({ id }) => setHotelInquiryId(id)}
			/>
			{hotelInquiryId && (
				<HotelInquiryAcceptDrawer hotelInquiryId={hotelInquiryId} onClose={() => setHotelInquiryId(null)} />
			)}
		</>
	);
};
