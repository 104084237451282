import { ButtonColor, Card, CardHeader, CardHeaderActions, H5, IconButton, IconName } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { createSearchParams } from "react-router-dom";
import styled from "styled-components";
import { encodeFilter } from "~shared/guestFilters";
import { IActiveGuestFilterEntry } from "~shared/guestFilters/guestFilterTypes";
import { GroupInfoItem, StatusInfoItem } from "~shared/types/dashboard";
import { GuestStatus } from "~shared/types/guest";
import { DashboardCardBody, LinkCard } from "./DashboardCard";
import { Labeled } from "./Labeled";

const Count = styled(H5)`
	font-weight: bold;
	color: ${({ theme }) => theme.colors.palette.primary.main.base};
`;

interface Props {
	title: string;
	data: StatusInfoItem[];
	iconName?: IconName;
	iconColor?: ButtonColor;
	filter?: IActiveGuestFilterEntry[];
}

export const StatusInfo: FC<Props> = ({ title, data, iconName, iconColor, filter }) => {
	const { t } = useTranslation();

	return (
		<LinkCard
			$clickable={true}
			aria-label={title}
			to={{
				pathname: "../guests",
				search: createSearchParams({
					guestListFilter: encodeFilter(filter || []),
				}).toString(),
			}}
		>
			<CardHeader>
				<div>
					{title}
					<Count>
						{data.reduce((acc, item) => {
							return acc + item.count;
						}, 0)}
					</Count>
				</div>
				{iconName && iconColor && (
					<CardHeaderActions>
						<IconButton title={t("Navigate to guests")} color={iconColor} icon={iconName} />
					</CardHeaderActions>
				)}
			</CardHeader>
			<DashboardCardBody>
				{data.map((item) => (
					<Labeled key={item.status} label={t(item.status as GuestStatus)}>
						{item.count}
					</Labeled>
				))}
			</DashboardCardBody>
		</LinkCard>
	);
};

interface GroupInfoProps {
	title: string;
	data: GroupInfoItem[];
}

export const GroupInfo: FC<GroupInfoProps> = ({ title, data }) => {
	return (
		<Card aria-label={title}>
			<CardHeader>{title}</CardHeader>
			<DashboardCardBody>
				{data.map((item) => (
					<Labeled key={item.name} label={item.name}>
						{item.count}
					</Labeled>
				))}
			</DashboardCardBody>
		</Card>
	);
};
