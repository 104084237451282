import { Card, CardBody } from "@dgs/core";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { emptyActionAutomation, emptyActionCondition } from "~admin/shared/utils";
import { ActionFormState } from "~shared/types/actions";
import { RuleFormState } from "~shared/types/rules";
import { RuleSet, RuleSetType } from "~shared/types/ruleSet";
import { ActionSelect } from "../input/ActionSelect";
import { ActionValueSelectField } from "./ActionValueSelectField";
import { AddRuleOrActionButton } from "./rules/AddRuleOrActionButton";
import { RemoveRuleOrActionButton } from "./rules/RemoveRuleButton";
import { ActionIndicator } from "./rules/RuleAndOrIndicator";
import { RuleList, RuleSetFooter } from "./ruleSetFormComponents";

const StyledRuleList = styled(RuleList)`
	grid-template-columns: max-content 1fr 1fr max-content;
`;

interface Props {
	registrationFormId: string;
	ruleSetType: RuleSetType;
}

export const RuleSetFormActions: FC<Props> = ({ registrationFormId, ruleSetType }) => {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<RuleSet<RuleFormState, ActionFormState>>();

	return (
		<Card $color="content">
			<CardBody>
				<StyledRuleList>
					{values.actions.map((action, index) => (
						<React.Fragment key={`action-${index}`}>
							<ActionIndicator index={index} />
							<ActionSelect
								name={`actions.${index}.type`}
								label={t("Action type")}
								type={ruleSetType}
								onChange={() => {
									void setFieldValue(`actions.${index}.value`, null);
								}}
							/>
							<ActionValueSelectField
								registrationFormId={registrationFormId}
								name={`actions.${index}.value`}
								action={action}
							/>
							<RemoveRuleOrActionButton type="actions" index={index} />
						</React.Fragment>
					))}
					{!values.actions.length && <span>{t("No actions existent")}</span>}
				</StyledRuleList>
			</CardBody>
			<RuleSetFooter>
				<AddRuleOrActionButton
					initialValue={ruleSetType === RuleSetType.Condition ? emptyActionCondition : emptyActionAutomation}
					type="actions"
				/>
			</RuleSetFooter>
		</Card>
	);
};
