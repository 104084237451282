import { Id } from "@dgs/core";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IDataFieldIndexResource } from "~shared/types/dataField";
import {
	GuestViewColumnAttribute,
	GuestViewDataFieldAttribute,
	GuestViewRelationAttribute,
} from "~shared/types/guestView";
import { useGuestFilter } from "./GuestFilterProvider";
import { GuestFilterAttributeType } from "./guestFilterTypes";

interface BaseGuestFilterInfo {
	name: string;
	type: GuestFilterAttributeType;
}

interface GuestFilterEntityInfo extends BaseGuestFilterInfo {
	attribute: GuestViewColumnAttribute | GuestViewRelationAttribute;

	dataField: undefined;
}

interface GuestFilterDataFieldInfo extends BaseGuestFilterInfo {
	attribute: GuestViewDataFieldAttribute;
	name: string;
	dataField: IDataFieldIndexResource;
}

type GuestFilterInfo = GuestFilterEntityInfo | GuestFilterDataFieldInfo;

export const useResolveInfoForGuestAttribute = (attributeId: Id | null) => {
	const { t } = useTranslation();
	const { guestAttributes, dataFields } = useGuestFilter();

	return useMemo<GuestFilterInfo | null>(() => {
		if (!attributeId) {
			return null;
		}

		const attribute = guestAttributes.find((a) => a.id === attributeId);

		if (!attribute) {
			throw new Error("Unknown attribute");
		}

		if (attribute.type !== "dataField") {
			return {
				attribute,
				name: t(attribute.entityType),
				dataField: undefined,
				type: attribute.entityType,
			} as GuestFilterEntityInfo;
		}

		const dataField = attribute.type === "dataField" ? dataFields.find((d) => d.id === attribute.entityType) : null;

		if (!dataField) {
			throw new Error("Unknown data field");
		}

		return {
			attribute,
			name: dataField.name,
			dataField,
			type: dataField.type,
		} as GuestFilterDataFieldInfo;
	}, [attributeId, dataFields, guestAttributes, t]);
};
