import { AutoDataList, IDataListColumn, IconLinkButton } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { usersKeys, usersService } from "~admin/shared/api/users";
import { UserListResource } from "~admin/shared/types/user";
import { isRemoteAuthType } from "./isRemoteAuthType";

export const UserList: FC = () => {
	const { t } = useTranslation();

	const dataListColumns: IDataListColumn<UserListResource>[] = [
		{
			heading: t("First name"),
			valueKey: "firstName",
			type: "value",
		},
		{
			heading: t("Last name"),
			valueKey: "lastName",
			type: "value",
		},
		{
			heading: t("Email"),
			valueKey: "email",
			type: "value",
		},
		{
			heading: t("Roles"),
			valueKey: "roles",
			render: (user) => user.roles.map((role) => role.name).join(", "),
			type: "value",
		},
	];
	return (
		<AutoDataList
			queryKey={usersKeys.list}
			fetcher={usersService.index}
			empty={t("No users available")}
			heading={t("Users")}
			columns={dataListColumns}
			to={(value) => `${value.id}`}
			headerActions={!isRemoteAuthType && <IconLinkButton to="new" color="primary" size="small" icon="plus" />}
		/>
	);
};
