import { BlockSingleSelectField, CheckboxField, CrudDrawer, EntityDrawerProps, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { EmailSingleSelect } from "~shared/selects/EmailSingleSelect";
import { RegistrationFormSingleSelect } from "~shared/selects/RegistrationFormSingleSelect";
import { WaitingList } from "~shared/types/waitingList";

export const WaitingListDrawer: FC<EntityDrawerProps<WaitingList>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(waitingList: WaitingList) => {
			const errors: { [K in keyof WaitingList]?: any } = {};
			if (waitingList.form === null) {
				errors.form = t("Field is required");
			}
			if (waitingList.confirmationMail === null) {
				errors.confirmationMail = t("Field is required");
			}
			if (waitingList.moveUpMail === null) {
				errors.moveUpMail = t("Field is required");
			}
			if (waitingList.confirmationBlock === null) {
				errors.confirmationBlock = t("Field is required");
			}
			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<RegistrationFormSingleSelect name="form" label={t("Registration form")} required />
					<TextField label={t("Registration quota")} name="registrationQuota" type="number" required />
					<CheckboxField name="enableAutomaticMoveUp" label={t("Enable Automatic Move Up")} />
					<EmailSingleSelect name="moveUpMail" label={t("Move up confirmation mail")} required />
					<EmailSingleSelect name="confirmationMail" label={t("Placed on waiting list Mail")} required />
					<BlockSingleSelectField
						name="confirmationBlock"
						label={t("Placed on waiting list Page")}
						blockType="pageBlock"
					/>
				</>
			}
		/>
	);
};
