import { BlockViewProps } from "@dgs/core";
import React, { FC, useEffect, useRef } from "react";
import { IEmailBlockShowResource } from "./emailBlockTypes";

export const EmailBlockView: FC<BlockViewProps<IEmailBlockShowResource>> = ({ block }) => {
	const shadowHost = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (shadowHost.current) {
			if (!shadowHost.current.shadowRoot) {
				shadowHost.current.attachShadow({ mode: "open" });
			}

			if (shadowHost.current.shadowRoot) {
				shadowHost.current.shadowRoot.innerHTML = `
				<div>
					${block.value.preview}
				</div>`;
			}
		}
	}, [block.value]);

	return <div ref={shadowHost} />;
};
