import { SingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useRuleActionTypeQuery } from "~shared/api/registrationForms/ruleSets/ruleSetQueries";
import { ActionTypes } from "~shared/types/actions";
import { RuleSetType } from "~shared/types/ruleSet";

interface ActionSelectProps {
	name: string;
	label: string;
	type: RuleSetType;
	onChange?: () => void;
}
export const ActionSelect: FC<ActionSelectProps> = ({ name, label, type, onChange }) => {
	const { ruleActionTypes } = useRuleActionTypeQuery();
	const { t } = useTranslation();

	return (
		<SingleSelectField<ActionTypes, ActionTypes>
			options={ruleActionTypes[type].actions}
			getLabel={(option) => t(`ActionType.${option}`)}
			getValue={(option) => option}
			name={name}
			label={label}
			onChange={onChange}
		/>
	);
};
