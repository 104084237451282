import {
	BodyFontFields,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardHeading,
	Loading,
	SubmitButton,
	apiIsOK,
	useToasts,
} from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { Form, Formik, FormikHelpers } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import {
	personalizedDocumentSettingsKeys,
	personalizedDocumentSettingsService,
	usePersonalizedDocumentSettingsQuery,
} from "~admin/shared/api/personalizedDocumentSettings";
import {
	PersonalizedDocumentSettingsFormValues,
	PersonalizedDocumentSettingsRequest,
	PersonalizedDocumentSettingsResource,
} from "~admin/shared/types/personalizedDocumentSettings";

export const mapPersonalizedDocumentSettingsValueToRequest = (
	values: PersonalizedDocumentSettingsFormValues,
): PersonalizedDocumentSettingsRequest => {
	const { fontName, regularFontFile, boldFontFile, italicFontFile, boldItalicFontFile, ...rest } = values;

	if (!fontName || !regularFontFile) {
		throw new Error("FontName and regularFontFile are required!");
	}

	return {
		...rest,
		fontName: fontName,
		regularFontFileId: regularFontFile.id,
		boldFontFileId: boldFontFile?.id || null,
		italicFontFileId: italicFontFile?.id || null,
		boldItalicFontFileId: boldItalicFontFile?.id || null,
	};
};

export const mapPersonalizedDocumentSettingsResourceToFormValues = (
	personalizedDocumentSettings?: PersonalizedDocumentSettingsResource,
): PersonalizedDocumentSettingsFormValues | null => {
	if (!personalizedDocumentSettings) {
		return {
			boldFontFile: null,
			boldItalicFontFile: null,
			fontName: "",
			id: 0,
			italicFontFile: null,
			regularFontFile: null,
		};
	}

	return personalizedDocumentSettings;
};

export const PersonalizedDocumentSettingsPage: FC = () => {
	const { data, isLoading } = usePersonalizedDocumentSettingsQuery();
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const personalizedDocumentSettings = mapPersonalizedDocumentSettingsResourceToFormValues(data);
	const queryClient = useQueryClient();

	const validate = (brandValues: PersonalizedDocumentSettingsFormValues) => {
		const errors: Record<string, string> = {};
		if (!brandValues.fontName) {
			errors.fontName = t("Required field");
		}

		if (!brandValues.regularFontFile) {
			errors.regularFontFile = t("Required field");
		}

		return errors;
	};

	const handleSubmit = async (
		values: PersonalizedDocumentSettingsFormValues,
		helpers: FormikHelpers<PersonalizedDocumentSettingsResource>,
	) => {
		const response = await personalizedDocumentSettingsService.put(
			mapPersonalizedDocumentSettingsValueToRequest(values),
		);

		if (apiIsOK(response)) {
			helpers.resetForm({ values });
			showToast({
				type: "success",
				title: t("Personalized document settings"),
				body: t("Personalized document settings saved successfully."),
			});
			await queryClient.invalidateQueries({ queryKey: personalizedDocumentSettingsKeys.details });
		}
	};

	return (
		<Card>
			<CardHeader>
				<CardHeading>{t("Edit settings")}</CardHeading>
			</CardHeader>
			{isLoading && <Loading />}
			{!isLoading && personalizedDocumentSettings && (
				<Formik initialValues={personalizedDocumentSettings} onSubmit={handleSubmit} validate={validate}>
					<Form>
						<CardBody>
							<BodyFontFields />
						</CardBody>
						<CardFooter>
							<SubmitButton>{t("Submit")}</SubmitButton>
						</CardFooter>
					</Form>
				</Formik>
			)}
		</Card>
	);
};
