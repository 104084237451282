import { MultiSelectField, SingleSelectField } from "@dgs/core";
import i18next from "i18next";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { GuestStatus } from "~shared/types/guest";

const options: GuestStatus[] = [
	GuestStatus.NONE,
	GuestStatus.INVITED,
	GuestStatus.INVITATION_OPENED,
	GuestStatus.INVITATION_DECLINED,
	GuestStatus.REMINDED,
	GuestStatus.REMINDER_OPENED,
	GuestStatus.WAITING_LIST,
	GuestStatus.REGISTERED,
	GuestStatus.REGISTRATION_CHANGED,
	GuestStatus.REGISTRATION_CANCELLED,
	GuestStatus.CONFIRMATION_SENT,
	GuestStatus.CHECKED_IN,
	GuestStatus.NO_SHOW,
	GuestStatus.APP_RECEIVED,
	GuestStatus.PENDING,
];

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	className?: string;
	clearable?: boolean;
	required?: boolean;
}

export const StatusSelect: FC<Props> = ({ name, label, disabled, className, clearable, required }) => {
	const { t } = useTranslation();

	return (
		<SingleSelectField
			options={options}
			getLabel={(option) => (i18next.exists(option) ? t(option) : option)}
			getValue={(option) => option}
			name={name}
			label={label}
			disabled={disabled}
			className={className}
			clearable={clearable}
			required={required}
		/>
	);
};

interface StatusMultiSelectProps {
	name: string;
	label: string;
	disabled?: boolean;
	className?: string;
	required?: boolean;
}

export const StatusMultiSelect: FC<StatusMultiSelectProps> = ({ name, label, disabled, className, required }) => {
	const { t } = useTranslation();

	return (
		<MultiSelectField
			options={options}
			getLabel={(option) => (i18next.exists(option) ? t(option) : option)}
			name={name}
			label={label}
			disabled={disabled}
			className={className}
			required={required}
		/>
	);
};
