import { CrudDrawer, EntityDrawerProps, TextField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Tag } from "~shared/types/tag";

export const TagDrawer: FC<EntityDrawerProps<Tag>> = (props) => {
	const { t } = useTranslation();
	return (
		<CrudDrawer
			{...props}
			body={
				<>
					<TextField name="name" label={t("Name")} />
					<TextField name="color" label={t("Color")} type="color" />
				</>
			}
		/>
	);
};
