import { DrawerAutoDataList, IDrawerDataListColumn, useCrudEntity } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { guestFilterService } from "~shared/api/guestFilters";
import { GuestFilterProvider } from "~shared/guestFilters";
import { GuestFilterCreateDrawer } from "~shared/guestFilters/GuestFilterCreateDrawer";
import { GuestFilterFormValues } from "~shared/guestFilters/guestFilterTypes";
import { IGuestFilter } from "~shared/types/guestFilter";
import { GuestFilterContextMenu } from "./GuestFilterContextMenu";

export const GuestFilterList: FC = () => {
	const { t } = useTranslation();

	const crudOperations = useCrudEntity(guestFilterService, {
		entityName: t("Guest filter"),
		messages: {
			create: t("Guest filter was saved."),
			update: t("Guest filter was saved."),
			delete: t("Guest filter was successfully deleted."),
		},
	});

	const dataListColumns: IDrawerDataListColumn<IGuestFilter>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (guestFilter, openDrawer) => (
					<GuestFilterContextMenu
						handleEdit={openDrawer}
						handleDelete={() => crudOperations.deleteEntity(guestFilter.id)}
					/>
				),
			},
		],
		[t, crudOperations],
	);
	const emptyGuestFilter: GuestFilterFormValues = {
		name: "",
		filters: [],
	};

	return (
		<GuestFilterProvider listFilterKey={"guestIndexListFilter"}>
			<DrawerAutoDataList
				translations={{
					title: t("Guest filter"),
					empty: t("No guest filters available"),
				}}
				renderCreateDrawer={(props) => (
					<GuestFilterCreateDrawer
						{...props}
						heading={t("Create guest filter")}
						initialValues={emptyGuestFilter}
						onSubmit={(entity) => crudOperations.createEntity(entity)}
						isCrudForm={true}
					/>
				)}
				renderUpdateDrawer={(resource, props) => (
					<GuestFilterCreateDrawer
						{...props}
						heading={t("Edit guest filter")}
						initialValues={resource}
						onSubmit={(formState) => crudOperations.updateEntity(resource.id, formState)}
						isCrudForm={true}
					/>
				)}
				columns={dataListColumns}
				service={guestFilterService}
				initialSearch={{ search: "", searchFields: ["name"] }}
			/>
		</GuestFilterProvider>
	);
};
