import { IDataListColumn, useFormatDate } from "@dgs/core";
import { isSameDay, parseISO } from "date-fns";
import { useCallback } from "react";
import { IHotelBookingResource } from "~shared/types/hotel";

export const useHotelBookingUtils = () => {
	const formatDate = useFormatDate();

	const mapToBookingColumn = useCallback(
		(day: Date): IDataListColumn<IHotelBookingResource> => {
			return {
				heading: formatDate(day),
				type: "value",
				valueKey: day.toISOString() as any,
				render: (booking) => {
					const date = booking.dates.find((date) => isSameDay(parseISO(date.date), day));

					if (!date) {
						return "-";
					}

					return `${date.hotelName} - ${date.roomTypeName} - ${date.beds}`;
				},
			};
		},
		[formatDate],
	);
	return { toBookingColumn: mapToBookingColumn };
};
