import { useFormikContext } from "formik";
import React, { FC, useCallback } from "react";
import styled from "styled-components";
import { ActionFormState } from "~shared/types/actions";
import { RuleFormState } from "~shared/types/rules";
import { RuleSet } from "~shared/types/ruleSet";

interface Props {
	name: string;
	label: string;
	value: boolean;
}

const RadioInput = styled.input`
	display: none;

	&:checked + label {
		background-color: ${({ theme }) => theme.colors.layout.content.text};
		color: #fff;
	}
`;

const Label = styled.label`
	display: inline-flex;
	width: 5rem;
	height: 44px;
	background-color: ${({ theme }) => theme.colors.layout.content.background};
	color: rgba(0, 0, 0, 0.6);
	font-size: 0.9rem;
	padding: ${({ theme }) => theme.spacing(2)};
	border: 1px solid rgba(0, 0, 0, 0.2);
	transition: all 0.5s ease-in-out;
	justify-content: center;
	align-items: center;

	&:hover {
		cursor: pointer;
	}
`;

export const SwitchInput: FC<Props> = ({ name, label, value }) => {
	const { values, setFieldValue } = useFormikContext<RuleSet<RuleFormState, ActionFormState>>();

	const handleChange = useCallback(
		(event: any) => {
			const value = event.target.value === "AND";
			void setFieldValue("isAndLinked", value);
		},
		[setFieldValue],
	);

	return (
		<>
			<RadioInput
				type="radio"
				id={`logicalOperator${label}`}
				name={name}
				value={label}
				onChange={handleChange}
				checked={values.isAndLinked === value}
			/>
			<Label htmlFor={`logicalOperator${label}`}>{label}</Label>
		</>
	);
};
