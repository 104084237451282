import React, { FC, ReactNode } from "react";
import { StructureStep } from "~shared/types/registrationFormTypes";
import { StructureHeader } from "../common/StructureHeader";
import { StructureHeaderSpacer } from "../common/StructureHeaderSpacer";
import { revertId } from "../registrationFormDndUtils";
import { RegistrationFormStepContextMenu } from "./RegistrationFormStepContextMenu";

interface Props {
	registrationFormId: string;
	step: StructureStep;
	indicator: ReactNode;
}

export const StepHeader: FC<Props> = ({ registrationFormId, step, indicator }) => {
	return (
		<StructureHeader>
			{indicator}
			{step.name}
			<StructureHeaderSpacer />
			<RegistrationFormStepContextMenu registrationFormId={registrationFormId} stepId={revertId(step.id)} />
		</StructureHeader>
	);
};
