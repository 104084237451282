import { BaseCrudOptionService, Id, keyToUrl } from "@dgs/core";
import { GuestViewConfigRequest, GuestViewConfigResource, IGuestViewOptionResource } from "~shared/types/guestView";
import { guestViewKeys } from "./guestViewKeys";

class GuestViewService extends BaseCrudOptionService<
	GuestViewConfigResource,
	GuestViewConfigResource,
	IGuestViewOptionResource,
	GuestViewConfigRequest
> {
	public keys = guestViewKeys;
	protected urls = {
		index: keyToUrl(this.keys.index),
		options: keyToUrl(this.keys.options),
		details: (id: Id) => keyToUrl(this.keys.details(id)),
	};
}

export const guestViewService = new GuestViewService();
