import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { guestFilterKeys, guestFilterService } from "~shared/api/guestFilters";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
}

export const guestFilterFetcher = (search: string, page: number) =>
	guestFilterService.options(search, page).then((r) => r.data);

export const GuestFilterSelect: FC<Props> = ({ name, label, disabled, required }) => {
	return (
		<PagedSingleSelectField
			name={name}
			queryKey={guestFilterKeys.options}
			fetcher={guestFilterFetcher}
			label={label}
			disabled={disabled}
			clearable={true}
			getLabel={(option) => `${option.name}`}
			required={required}
		/>
	);
};
