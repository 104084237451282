import { BaseCrudOptionService, Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { ModelUsageResource } from "~shared/types/modelUsageResource";
import {
	IWaveContingentOverviewResource,
	IWaveIndexResource,
	IWaveOptionResource,
	IWaveRequest,
	IWaveTenantsContingent,
	IWaveTenantsContingentRequest,
	IWaveUpdateResource,
} from "~shared/types/wave";

class WaveService extends BaseCrudOptionService<
	IWaveIndexResource,
	IWaveUpdateResource,
	IWaveOptionResource,
	IWaveRequest
> {
	public keys = {
		index: ["waves"],
		list: ["waves", "list"],
		options: ["waves", "options"],
		details: (id: Id) => ["waves", `${id}`],
		tenantsWithContingent: (id: Id) => ["waves", `${id}`, "tenants-with-contingent"],
		showUsage: (id: Id) => ["waves", `${id}`, "usage"],
		getContingentOverview: ["nomination-manager", "contingent-overview"],
	};

	protected urls = {
		index: keyToUrl(this.keys.index),
		options: keyToUrl(this.keys.options),
		details: (id: Id) => keyToUrl(this.keys.details(id)),
		tenantsWithContingent: (id: Id) => keyToUrl(this.keys.tenantsWithContingent(id)),
		showUsage: (id: Id) => keyToUrl(this.keys.showUsage(id)),
		getContingentOverview: keyToUrl(this.keys.getContingentOverview),
	};

	tenantsWithContingent = (id: Id) =>
		axios.get<LaravelResponse<IWaveTenantsContingent[]>>(this.urls.tenantsWithContingent(id));

	updateTenantsWithContingent = (id: Id, request: IWaveTenantsContingentRequest) =>
		axios.put<LaravelResponse<IWaveTenantsContingent[]>>(this.urls.tenantsWithContingent(id), request);

	showUsage = (id: Id) => axios.get<LaravelResponse<ModelUsageResource[]>>(this.urls.showUsage(id));

	getContingentOverview = () =>
		axios.get<LaravelResponse<IWaveContingentOverviewResource[]>>(this.urls.getContingentOverview);
}

export const waveService = new WaveService();
