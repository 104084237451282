import { Button, Card, CardBody, CardHeader, CardHeading, GroupedRadioControl, RadioGroup } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ActiveGuestFilterEntries, GuestFilterDrawer, useGuestFilter } from "~shared/guestFilters";
import { GuestMultiSelect } from "~shared/selects/GuestMultiSelect";
import { RecipientType } from "~shared/types/email";
import { useEmailSendOut } from "./EmailSendOutProvider";

const StyledCard = styled(Card)``;

const StyledCardBody = styled(CardBody)`
	display: grid;
	gap: ${({ theme }) => theme.spacing(5)};
`;

const Filter = styled.div``;

export const EmailSendOutFilter: FC = ({}) => {
	const { t } = useTranslation();
	const { setRecipients, recipients, recipientType, changeRecipientType } = useEmailSendOut();
	const { handleOpen } = useGuestFilter();
	return (
		<>
			<StyledCard>
				<CardHeader>
					<CardHeading>{t("Filter recipients")}</CardHeading>
				</CardHeader>
				<StyledCardBody>
					<div>
						<RadioGroup
							name={t("Filter select")}
							onChange={(e) => changeRecipientType(e.target.value as RecipientType)}
							value={recipientType}
						>
							<GroupedRadioControl label={t("None")} value={RecipientType.NONE} id="none" />
							<GroupedRadioControl label={t("All")} value={RecipientType.ALL} id="all" />
							<GroupedRadioControl label={t("Filter")} value={RecipientType.FILTER} id="filter" />
							<GroupedRadioControl
								label={t("Select recipients")}
								value={RecipientType.GUEST_SELECT}
								id="recipientselect"
							/>
						</RadioGroup>
					</div>
					{recipientType === RecipientType.GUEST_SELECT && (
						<GuestMultiSelect
							value={recipients as any}
							onChange={(e) => setRecipients(e.target.value as any)}
							label={t("Guests")}
							name={t("Guest select")}
						/>
					)}
					{recipientType === RecipientType.FILTER && (
						<Filter>
							<Button type="button" onClick={handleOpen}>
								{t("Add filter")}
							</Button>
							<ActiveGuestFilterEntries />
						</Filter>
					)}
				</StyledCardBody>
			</StyledCard>
			<GuestFilterDrawer />
		</>
	);
};
