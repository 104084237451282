import { SelectedRows, apiIsOK, useToasts } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { invoiceService } from "~admin/shared/api/shop/invoices/invoiceService";
import { SendEmailFormValues } from "~shared/types/email";
import { SendEmailCrudDrawer } from "~shared/ui/SendEmailCrudDrawer";

interface Props {
	invoiceIds: SelectedRows | null;
	handleClose: () => void;
}

export const InvoiceDrawer: FC<Props> = ({ invoiceIds, handleClose }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();

	let count = "0";
	if (invoiceIds) {
		count = invoiceIds === "all" ? t("all") : `${invoiceIds.length}`;
	}

	const sendEmail = useCallback(
		async (values: SendEmailFormValues) => {
			if (!invoiceIds) {
				throw "No selected invoice";
			}
			const response = await invoiceService.sendEmail({
				invoiceIds: invoiceIds === "all" ? null : invoiceIds,
				locale: values.locale,
				customEmailId: values.customEmail?.id || "",
			});
			if (apiIsOK(response)) {
				showToast({
					body: t("Email sent successfully."),
					title: t("Send email"),
					type: "success",
				});
				handleClose();
				return true;
			} else {
				showToast({
					body: response.statusText,
					title: t("Send email"),
					type: "error",
				});
				return false;
			}
		},
		[invoiceIds, handleClose, showToast, t],
	);

	return (
		<SendEmailCrudDrawer
			title={`${t("Send email")} (${count})`}
			open={!!invoiceIds}
			handleClose={handleClose}
			handleSubmit={sendEmail}
		/>
	);
};
