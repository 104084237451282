import { Id } from "@dgs/core";
import { IActiveGuestFilterEntry } from "~shared/guestFilters/guestFilterTypes";

export const waitingListKeys = {
	index: ["waiting-lists"],
	list: ["waiting-lists", "list"],
	details: (waitingListId: Id) => [...waitingListKeys.index, `${waitingListId}`],
	guests: (waitingListId: Id) => [...waitingListKeys.details(waitingListId), "guests"],
	filteredGuests: (waitingListId: Id, guestViewId: Id, filters: IActiveGuestFilterEntry[]) => [
		...waitingListKeys.guests(waitingListId),
		JSON.stringify(guestViewId),
		JSON.stringify(filters),
	],
};
