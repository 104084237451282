import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { dashboardKeys } from "~shared/api/dashboard";
import { emailKeys } from "~shared/api/emails";
import { guestKeys } from "~shared/api/guests";
import { companionService } from "~shared/api/guests/companionService";
import { waveService } from "~shared/api/waves/waveService";
import { Guest } from "~shared/types/guest";

export function useCompanionUtils() {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();

	const deleteCompanion = async (guestId: Id, companionId: Id) => {
		const response = await companionService.deleteCompanion(companionId);

		if (apiIsOK(response)) {
			showToast({
				title: t("Delete companion"),
				type: "success",
				body: t("Companion successfully deleted"),
			});
			void queryClient.invalidateQueries({ queryKey: companionService.keys.indexByParent(guestId) });
			void queryClient.invalidateQueries({ queryKey: waveService.keys.getContingentOverview });
			void queryClient.invalidateQueries({ queryKey: guestKeys.index });
			void queryClient.invalidateQueries({ queryKey: dashboardKeys.index });
			void queryClient.invalidateQueries({ queryKey: emailKeys.sendOuts.index });
		}
	};

	const detachCompanion = async (guestId: Id, companionId: Id) => {
		const response = await companionService.detachCompanion(companionId);

		if (apiIsOK(response)) {
			showToast({
				title: t("Detach companion"),
				type: "success",
				body: t("Companion successfully detached"),
			});
			void queryClient.invalidateQueries({ queryKey: guestKeys.index });
			void queryClient.invalidateQueries({ queryKey: dashboardKeys.index });
			void queryClient.invalidateQueries({ queryKey: emailKeys.sendOuts.index });
			void queryClient.invalidateQueries({ queryKey: companionService.keys.indexByParent(guestId) });
		}
	};

	const attachCompanion = async (companions: Guest[], guestId: Id) => {
		const response = await companionService.attachCompanions(
			guestId,
			companions.map((c) => c.id),
		);
		if (apiIsOK(response)) {
			showToast({
				body: t("Companions attached successfully."),
				title: t("Attach companions"),
				type: "success",
			});
			void queryClient.invalidateQueries({ queryKey: guestKeys.index });
			void queryClient.invalidateQueries({ queryKey: dashboardKeys.index });
			void queryClient.invalidateQueries({ queryKey: emailKeys.sendOuts.index });
			void queryClient.invalidateQueries({ queryKey: companionService.keys.indexByParent(guestId) });
			return true;
		} else {
			showToast({
				body: response.statusText,
				title: t("Attach companions"),
				type: "error",
			});
			return false;
		}
	};

	return {
		deleteCompanion,
		detachCompanion,
		attachCompanion,
	};
}
