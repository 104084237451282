import { AutoDataList, CrudDrawer, IDataListColumn, IconButton, IconLinkButton, Trigger } from "@dgs/core";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { roomTypeService } from "~admin/shared/api/roomTypes/roomTypeService";
import { IHotelIndex } from "~shared/types/hotel";
import { IRoomTypeIndex } from "~shared/types/roomType";
import { HotelSingleSelect } from "../HotelSingleSelect";
import { RoomTypeContextMenu } from "./RoomTypeContextMenu";

interface StyledProps {
	$color: "f00" | "000";
}

const BookingInfo = styled.span<StyledProps>`
	color: #${({ $color }) => $color};
`;

export const RoomTypeList: FC = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { hotelId } = useParams<"hotelId">();
	const dataListColumns: IDataListColumn<IRoomTypeIndex>[] = useMemo(
		() => [
			...((hotelId
				? []
				: [
						{
							heading: t("Hotel"),
							valueKey: "hotel",
							type: "value",
							render: (roomType) => roomType.hotel.name,
						},
				  ]) as IDataListColumn<IRoomTypeIndex>[]),
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Booked rooms"),
				valueKey: "bookings",
				type: "value",
				render: (roomType) => (
					<BookingInfo $color={roomType.bookings > roomType.contingent ? "f00" : "000"}>
						{roomType.bookings} / {roomType.contingent}
					</BookingInfo>
				),
			},
			{
				heading: t("Internal remarks"),
				valueKey: "internalRemark",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (roomType) => <RoomTypeContextMenu roomType={roomType} />,
			},
		],
		[hotelId, t],
	);
	const onSubmit = useCallback(
		async (hotel: IHotelIndex) => {
			navigate(`/admin/hotel-management/hotels/${hotel.id}/room-types/new`);
			return true;
		},
		[navigate],
	);

	return (
		<AutoDataList
			heading={t("Room types")}
			headerActions={
				!hotelId ? (
					<Trigger render={(props) => <ChooseHotelToCreateRoomTypeDrawer {...props} onSubmit={onSubmit} />}>
						{(props) => <IconButton {...props} title={t("Add")} color="primary" size="small" icon="plus" />}
					</Trigger>
				) : (
					<IconLinkButton to="new" title={t("Add")} color="primary" size="small" icon="plus" />
				)
			}
			empty={t("No room types available")}
			columns={dataListColumns}
			queryKey={hotelId ? roomTypeService.keys.indexByHotel(hotelId) : roomTypeService.keys.index}
			fetcher={roomTypeService.indexByHotel(hotelId)}
			to={(value) => `/admin/hotel-management/hotels/${value.hotel.id}/room-types/${value.id}`}
		/>
	);
};

interface Props {
	open: boolean;
	handleClose: () => void;
	onSubmit: (hotel: IHotelIndex) => Promise<boolean>;
}

const ChooseHotelToCreateRoomTypeDrawer: FC<Props> = ({ open, handleClose, onSubmit }) => {
	const { t } = useTranslation();

	return (
		<CrudDrawer
			open={open}
			onSubmit={async ({ hotel }: { hotel: IHotelIndex | null }) => {
				if (hotel) {
					return onSubmit(hotel);
				}
				return false;
			}}
			body={<HotelSingleSelect name="hotel" label={t("Hotel")} />}
			initialValues={{ hotel: null } as { hotel: IHotelIndex | null }}
			onClose={handleClose}
			translations={{ save: t("Next") }}
			heading={t("Select a hotel to create a room type")}
		/>
	);
};
