import {
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerHeading,
	Id,
	Loading,
	SingleSelectControl,
	SubmitButton,
	TextField,
} from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useOrderQuery } from "~admin/shared/api/orders/orderQueries";
import { RefundableItemMultiSelect } from "~admin/shared/selects/RefundableItemMultiSelect";
import { OrderRefundFormState, RefundFixAmountFormState, RefundOrderItemsFormState } from "~admin/shared/types/orders";
import { useOrderForm } from "~admin/shop/orders/useOrderForm";

const allowedRefundProvider = ["Invoice", "Mollie", "BMWCreditCard", "BMWPayPal", "PayPal"];
const refundTypeOptions: OrderRefundFormState["type"][] = ["order", "fixAmount", "orderItems", "percent"];

type ErrorTypes<T> = { [K in keyof T]?: any };

const refundTypeOptionsTranslations: Record<OrderRefundFormState["type"], string> = {
	order: "Entire order",
	orderItems: "Individual order items",
	fixAmount: "Fix amount",
	percent: "Percentage",
};

interface Props {
	orderId: Id;
	open: boolean;
	onClose: () => void;
}

export const OrderRefundDrawer: FC<Props> = ({ orderId, open, onClose }) => {
	const { t } = useTranslation();
	const { data: order, isLoading } = useOrderQuery(orderId);
	const enabled = order?.paymentProviders.some((provider) => allowedRefundProvider.includes(provider.type));
	const { refundOrder } = useOrderForm();

	const initialValue: OrderRefundFormState = {
		type: "order",
	};

	const validate = (values: OrderRefundFormState) => {
		const errors: { [K in keyof OrderRefundFormState]?: any } = {};
		if (
			(values.type === "orderItems" || values.type === "fixAmount" || values.type === "percent") &&
			!values.orderItems.length
		) {
			(errors as ErrorTypes<RefundOrderItemsFormState>).orderItems = t("Required field");
		}
		if (values.type === "fixAmount") {
			if (!values.amount) {
				(errors as ErrorTypes<RefundFixAmountFormState>).amount = t("Required field");
			}
		}
		if (values.type === "percent") {
			if (!values.amount) {
				(errors as ErrorTypes<RefundFixAmountFormState>).amount = t("Required field");
			}
			if (values.amount > 100) {
				(errors as ErrorTypes<RefundFixAmountFormState>).amount = t("max. {{value}}", { value: "100%" });
			}
		}
		return errors;
	};

	return (
		<Drawer onClose={onClose} open={open}>
			{order && !isLoading ? (
				<>
					<DrawerHeader onClose={onClose}>
						<DrawerHeading>{t("Refund")}</DrawerHeading>
					</DrawerHeader>
					<Formik
						initialValues={initialValue}
						onSubmit={(order: OrderRefundFormState) => refundOrder(orderId, order, onClose)}
						validate={validate}
					>
						{({ values, resetForm }) => (
							<Form>
								<DrawerBody>
									<SingleSelectControl
										name="type"
										label={t("Type")}
										options={refundTypeOptions}
										value={values.type}
										clearable={false}
										getLabel={(x) => t(refundTypeOptionsTranslations[x])}
										onChange={(e) => {
											const type = e.target.value as OrderRefundFormState["type"];
											if (type === "fixAmount" || type === "percent") {
												resetForm({
													values: {
														type,
														orderItems: [],
														amount: 0,
													},
												});
											}
											if (type === "orderItems") {
												resetForm({
													values: {
														type,
														orderItems: [],
													},
												});
											}
											if (type === "order") {
												resetForm({
													values: {
														type,
													},
												});
											}
										}}
									/>
									{(values.type === "fixAmount" || values.type === "percent") && (
										<>
											<TextField
												name="amount"
												type="number"
												label={values.type === "fixAmount" ? t("Amount") : t("Percentage")}
												min={values.type === "percent" ? "0" : undefined}
												max={values.type === "percent" ? "100" : undefined}
											/>
										</>
									)}
									{values.type && values.type !== "order" && (
										<>
											<RefundableItemMultiSelect
												name={"orderItems"}
												disabled={!enabled}
												label={t("Order items")}
												orderId={orderId}
											/>
										</>
									)}
									{!enabled && t("Refund only can be saved for invoices!")}
								</DrawerBody>
								<DrawerFooter>
									<SubmitButton disabled={!enabled}>{t("Save")}</SubmitButton>
								</DrawerFooter>
							</Form>
						)}
					</Formik>
				</>
			) : (
				<Loading />
			)}
		</Drawer>
	);
};
