import { BaseCrudService, Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { ModelUsageResource } from "~shared/types/modelUsageResource";
import {
	IShopItemIndexResource,
	IShopItemSaveRequest,
	IShopItemUpdateResource,
	RegistrationFormShopItem,
} from "~shared/types/shop";

export class ShopItemService extends BaseCrudService<
	IShopItemIndexResource,
	IShopItemUpdateResource,
	IShopItemSaveRequest
> {
	public keys = {
		index: ["shop-items"],
		list: ["shop-items", "list"],
		details: (shopItemId: Id) => ["shop-items", `${shopItemId}`],
		options: ["shop-items", "options"],
		showUsage: (shopItemId: Id) => [...this.keys.details(shopItemId), "usage"],
	};
	protected urls = {
		index: keyToUrl(this.keys.index),
		details: (shopItemId: Id) => keyToUrl(this.keys.details(shopItemId)),
		options: keyToUrl(this.keys.options),
		showUsage: (shopItemId: Id) => keyToUrl(this.keys.showUsage(shopItemId)),
	};

	options = (config?: AxiosRequestConfig) => {
		return axios.get<LaravelResponse<RegistrationFormShopItem[]>>(this.urls.options, config);
	};

	showUsage = (shopItemId: Id) => axios.get<LaravelResponse<ModelUsageResource[]>>(this.urls.showUsage(shopItemId));
}

export const shopItemService = new ShopItemService();
