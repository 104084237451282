import { Id } from "@dgs/core";
import { IActiveGuestFilterEntry } from "~shared/guestFilters/guestFilterTypes";
import { RecipientType } from "~shared/types/email";

export const guestKeys = {
	index: ["guests"],
	list: ["guests", "list"],
	options: ["guests", "options"],
	bulkDelete: ["guests", "bulk-delete"],
	bulkEdit: ["guests", "bulk-edit"],
	remove: (guestId: Id) => [...guestKeys.index, `${guestId}`],
	details: (guestId: Id) => [...guestKeys.index, `${guestId}`],
	hotelData: (guestId: Id) => [...guestKeys.index, `${guestId}`, "hotel-data"],
	rooms: (guestId: Id) => [...guestKeys.index, `${guestId}`, "rooms"],
	attachRoomMateToRooms: (guestId: Id) => [...guestKeys.rooms(guestId), "attach-room-mate"],
	attachRoomContingents: (guestId: Id) => [...guestKeys.index, `${guestId}`, "room-contingents", "attach"],
	detachRooms: (guestId: Id) => [...guestKeys.index, `${guestId}`, "rooms", "detach"],
	filtered: ["guests", "filter"],
	recipients: ["guests", "recipients"],
	recipientCount: ["guests", "recipients", "count"],
	filteredRecipients: (filters: IActiveGuestFilterEntry[], guestIds: Id[], recipientType: RecipientType) => [
		...guestKeys.recipients,
		JSON.stringify(filters),
		JSON.stringify(guestIds),
		recipientType,
	],
	filteredRecipientCount: (filters: IActiveGuestFilterEntry[], guestIds: Id[], recipientType: RecipientType) => [
		...guestKeys.recipients,
		JSON.stringify(filters),
		JSON.stringify(guestIds),
		recipientType,
	],
	activities: (guestId: Id) => [...guestKeys.index, `${guestId}`, "activities"],
	register: (guestId: Id) => [...guestKeys.index, `${guestId}`, "register"],
	emails: (guestId: Id) => [...guestKeys.index, `${guestId}`, "email-logs"],
	sendEmail: (guestId: Id) => [...guestKeys.index, `${guestId}`, "send-email"],
	generateExport: ["guests", "export"],
	showUsage: (id: Id) => ["guests", `${id}`, "usage"],
	validateEmail: ["guests", "validate-email"],
	bookingDetails: (guestId: Id | null) => [...guestKeys.index, `${guestId}`, "bookings"],
	changeScreeningStatus: (guestId: Id) => [...guestKeys.index, `${guestId}`, "change-screening-status"],
};
