import { Button, Drawer, DrawerBody, DrawerHeader, DrawerHeading, Icon, useOpen } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { RegistrationFormForm } from "./RegistrationFormForm";
import { useRegistrationFormForm } from "./useRegistrationFormForm";

const PlusIcon = styled(Icon).attrs({ icon: "plus" })`
	width: 16px;
	height: 16px;
`;

export const CreateRegistrationFormDrawer: FC = () => {
	const { t } = useTranslation();
	const { open, handleOpen, handleClose } = useOpen();
	const { createRegistrationForm } = useRegistrationFormForm();

	return (
		<>
			<Button prefix={<PlusIcon />} title={t("Create registration form")} onClick={handleOpen} color="primary">
				{t("Create")}
			</Button>
			<Drawer open={open} onClose={handleClose}>
				<DrawerHeader onClose={handleClose}>
					<DrawerHeading>{t("Create registration form")}</DrawerHeading>
				</DrawerHeader>
				<DrawerBody>
					<RegistrationFormForm
						initialValues={{ name: "" }}
						onSubmit={async (registrationForm, helpers) => {
							const registrationFormId = await createRegistrationForm(registrationForm);

							if (registrationFormId === false) {
								return false;
							}
							helpers.setTo(`../registration-forms/${registrationFormId}`);
							handleClose();

							return true;
						}}
					/>
				</DrawerBody>
			</Drawer>
		</>
	);
};
