import { Button, H6, Loading } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { EditSortableLists } from "~admin/shared/ui/sortableLists/EditSortableLists";
import { useDataFieldsQuery } from "~shared/api/dataFields";
import { useGuestAttributesQuery } from "~shared/api/guestAttributes/guestAttributeQueries";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { GuestViewAttributeResource } from "~shared/types/guestView";

interface Props {
	value: GuestViewAttributeResource[];
	onChange: (sortableDataFields: GuestViewAttributeResource[]) => void;
	error?: string;
}

const ListHeader = styled.span`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export const EditSortableGuestAttributes: FC<Props> = ({ value, onChange, error }) => {
	const { t } = useTranslation();
	const { data: dataFields, isLoading } = useDataFieldsQuery();
	const { guestAttributes, isLoading: isGuestAttributesLoading } = useGuestAttributesQuery();
	const { fallbackLocale } = useContentLocale();

	const getGuestAttributeLabel = useCallback(
		(guestAttribute: GuestViewAttributeResource) => {
			if (guestAttribute.type === "column") {
				switch (guestAttribute.entityType) {
					case "createdAt":
						return t("Created at");
					case "updatedAt":
						return t("Updated at");
					case "status":
						return t("Status");
					case "registrationLocale":
						return t("registrationLocale", { fallbackLocale: fallbackLocale });
					default:
						return t(guestAttribute.entityType);
				}
			} else if (guestAttribute.type === "relation") {
				switch (guestAttribute.entityType) {
					case "tags":
						return t("Tags");
					case "registrationForm":
						return t("Registration form");
					case "tenant":
						return t("Tenant");
					case "wave":
						return t("Wave");
					case "groups":
						return t("Groups");
					case "locations":
						return t("Locations");
					case "companionParent":
						return t("companionParent");
					case "waitingList":
						return t("WaitingList");
					case "companions":
						return t("Companions");
					case "checkInOutLogs":
						return t("Check-in/out logs");
					case "agreedConsents":
						return t("agreedConsents");
					case "replacement":
						return t("replacement");
					case "replacementParent":
						return t("replacementParent");
					case "guestFiles":
						return t("guestFiles");
					default:
						return "";
				}
			} else {
				return dataFields.find((dataField) => dataField.id === guestAttribute.entityType)?.name || "";
			}
		},
		[dataFields, fallbackLocale, t],
	);

	if (isLoading || isGuestAttributesLoading) return <Loading />;

	return guestAttributes ? (
		<EditSortableLists
			initialItems={guestAttributes}
			value={value}
			getLabel={getGuestAttributeLabel}
			onChange={onChange}
			listHeaders={{
				active: (
					<ListHeader>
						<H6>{t("Assigned guest attributes")}</H6>
						<Button type="button" size="small" onClick={() => onChange([])}>
							{t("Remove all")}
						</Button>
					</ListHeader>
				),
				available: (
					<ListHeader>
						<H6>{t("Available guest attributes")}</H6>
						<Button type="button" size="small" onClick={() => onChange(guestAttributes)}>
							{t("Assign all")}
						</Button>
					</ListHeader>
				),
			}}
			error={error}
		/>
	) : null;
};
