import { mapBaseBlockFormToRequest, mapBaseBlockResourceToForm, validateBlockForm } from "@dgs/core";
import { EmailOrderSummaryBlockForm } from "./EmailOrderSummaryBlockForm";
import { IEmailOrderSummaryBlockForm, IEmailOrderSummaryBlockView } from "./emailOrderSummaryBlockTypes";
import { EmailOrderSummaryBlockView } from "./EmailOrderSummaryBlockView";

export const emailOrderSummaryBlockView: IEmailOrderSummaryBlockView = {
	View: EmailOrderSummaryBlockView,
};

export const emailOrderSummaryBlockForm: IEmailOrderSummaryBlockForm = {
	blockType: "emailOrderSummaryBlock",
	type: "block",
	Form: EmailOrderSummaryBlockForm,
	Preview: EmailOrderSummaryBlockView,
	emptyValue: null,
	emptyTranslatedValue: null,
	mapEditResourceToFormState: (resource, locales, fallbackLocale, emptyValue) =>
		mapBaseBlockResourceToForm(resource, locales, fallbackLocale, emptyValue),
	mapFormStateToRequest: (resource, form) => mapBaseBlockFormToRequest(resource.type, form),
	validate: validateBlockForm,
};

export const emailOrderSummaryBlock = {
	...emailOrderSummaryBlockView,
	...emailOrderSummaryBlockForm,
};
