import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { contingentOverviewKeys } from "~admin/shared/api/contingentOverview";
import { roomContingentKeys, roomContingentService } from "~shared/api/contingent/contingentService";
import { IRoomContingentForm } from "~shared/types/roomContingent";

export const useRoomContingentForm = () => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const createRoomContingent = useCallback(
		async (hotelId: Id, roomTypeId: Id, roomContingent: IRoomContingentForm) => {
			const response = await roomContingentService.post(hotelId, roomTypeId, roomContingent);

			if (apiIsOK(response)) {
				showToast({
					body: t("Room contingent was successfully created."),
					title: t("Room contingent"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: roomContingentKeys.index(hotelId, roomTypeId) });
				await queryClient.invalidateQueries({ queryKey: contingentOverviewKeys.days });
				navigate("./..");

				return true;
			}
			return false;
		},
		[showToast, t, queryClient, navigate],
	);

	const updateRoomContingent = useCallback(
		async (hotelId: Id, roomTypeId: Id, id: Id, roomContingent: IRoomContingentForm) => {
			const response = await roomContingentService.put(hotelId, roomTypeId, id, {
				contingent: roomContingent.contingent,
				overbookable: roomContingent.overbookable,
				prices: roomContingent.prices,
			});

			if (apiIsOK(response)) {
				showToast({
					body: t("Room contingent was successfully updated."),
					title: t("Room contingent"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: contingentOverviewKeys.overview });
				await queryClient.invalidateQueries({ queryKey: roomContingentKeys.index(hotelId, roomTypeId) });
				await queryClient.invalidateQueries({ queryKey: roomContingentKeys.details(hotelId, roomTypeId, id) });

				navigate("./..");

				return true;
			}
			return false;
		},
		[navigate, queryClient, showToast, t],
	);

	const deleteRoomContingent = useCallback(
		async (hotelId: Id, roomTypeId: Id, id: Id) => {
			const response = await roomContingentService.delete(hotelId, roomTypeId, id);
			if (apiIsOK(response)) {
				showToast({
					body: t("Room contingent was successfully deleted."),
					title: t("Room contingent"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: roomContingentKeys.index(hotelId, roomTypeId) });

				return true;
			}

			return false;
		},
		[queryClient, showToast, t],
	);

	return { createRoomContingent, updateRoomContingent, deleteRoomContingent };
};
