import React, { FC } from "react";
import { DataFieldSettings, IDataFieldIndexResource } from "~shared/types/dataField";
import { GuestViewAttributeResource } from "~shared/types/guestView";
import { GuestDetailsResource } from "~shared/types/newGuestTypes";
import { GuestAttributeFieldColumn } from "./GuestAttributeFieldColumn";
import { GuestAttributeFieldDataField } from "./GuestAttributeFieldDataField";
import { GuestAttributeFieldRelation } from "./GuestAttributeFieldRelation";

interface Props {
	guest: GuestDetailsResource;
	dataFields: IDataFieldIndexResource[];
	dataFieldSettings: DataFieldSettings | null;
	attribute: GuestViewAttributeResource;
	required: boolean;
}

export const GuestAttributeField: FC<Props> = ({ guest, dataFields, dataFieldSettings, attribute, required }) => {
	switch (attribute.type) {
		case "column":
			return <GuestAttributeFieldColumn attribute={attribute} required={required} />;
		case "relation":
			return <GuestAttributeFieldRelation attribute={attribute} required={required} />;
		case "dataField":
			return (
				<GuestAttributeFieldDataField
					attribute={attribute}
					required={required}
					guest={guest}
					dataFields={dataFields}
					dataFieldSettings={dataFieldSettings}
				/>
			);
	}
};
