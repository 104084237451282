import { useField } from "formik";
import React, { FC } from "react";
import { JsonCodeMirrorField } from "~admin/shared/fields/JsonCodemirrorField";

export const AdobeThirdPartyScriptsField: FC = () => {
	const [{ value }] = useField("enableAdobeAnalytics");

	if (!value) {
		return null;
	}

	return <JsonCodeMirrorField name="adobeAnalyticsThirdPartyScripts" />;
};
