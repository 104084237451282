import { BaseBlockForm, FormProps, IBaseBlockEditResource, NameField, TetrisImagePicker } from "@dgs/core";
import React, { FC } from "react";
import { emailImageBlockForm } from "./EmailImageBlock";

export const EmailImageBlockForm: FC<FormProps<IBaseBlockEditResource>> = ({ block, onSubmit }) => {
	return (
		<BaseBlockForm block={block} onSubmit={onSubmit} blockForm={emailImageBlockForm}>
			<NameField blockType={block.type} />
			<TetrisImagePicker />
		</BaseBlockForm>
	);
};
