import { LaravelResponse, PagedSingleSelectField } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import React, { FC } from "react";
import { IRegistrationFormSection } from "~shared/types/section";

interface Props {
	registrationFormId: string;
	name: string;
	label: string;
	disabled?: boolean;
}

const sectionFetcher = (registrationFormId: string, config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<IRegistrationFormSection[]>>(
		`registration-forms/${registrationFormId}/sections/options`,
		config,
	);

export const SectionPagedSingleSelect: FC<Props> = ({ registrationFormId, name, label, disabled }) => {
	return (
		<PagedSingleSelectField
			name={name}
			label={label}
			fetcher={(search, page) => sectionFetcher(registrationFormId, { params: { search, page } }).then((r) => r.data)}
			queryKey={["registration-forms", registrationFormId, "sections", "options"]}
			disabled={disabled}
		/>
	);
};
