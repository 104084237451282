import {
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	DrawerAutoDataList,
	IDrawerDataListColumn,
	useCrudEntity,
} from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { RoomDrawer } from "~admin/hotels/roomTypes/contingent/rooms/RoomDrawer";
import { RoomService } from "~admin/shared/api/rooms/roomService";
import { roomContingentKeys } from "~shared/api/contingent";
import { IRoomFormValues, IRoomGuest, IRoomIndex } from "~shared/types/room";

const emptyRoom: IRoomFormValues = {
	name: "",
};

const getLabel = (guest: IRoomGuest) => `${guest.firstName} ${guest.lastName} (${guest.email})`;

export const RoomList: FC = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { hotelId = "", roomTypeId = "", roomContingentId = "" } = useParams();

	const roomService = useMemo(
		() => new RoomService(hotelId, roomTypeId, roomContingentId),
		[hotelId, roomContingentId, roomTypeId],
	);

	const crudOperations = useCrudEntity(roomService, {
		entityName: t("Room"),
		messages: {
			create: t("Room was saved."),
			update: t("Room was saved."),
			delete: t("Room was deleted."),
		},
	});

	const dataListColumns: IDrawerDataListColumn<IRoomIndex>[] = useMemo(
		() => [
			{
				heading: t("Room number"),
				valueKey: "name",
				type: "value",
				render: (room) => room.name || t("No room number given"),
			},
			{
				heading: t("Guests"),
				valueKey: "guests",
				type: "value",
				render: (room) => room.guests.map((guest) => getLabel(guest)).join(", "),
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }, openDrawer) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuItem
							title={t("Edit room")}
							label={t("Edit room")}
							action={openDrawer}
							icon="edit"
							shortcut="primary"
						/>
						<ContextMenuDeleteItem
							action={async () => {
								await crudOperations.deleteEntity(id);
								await queryClient.resetQueries({
									queryKey: roomContingentKeys.details(hotelId, roomTypeId, roomContingentId),
								});
							}}
							title={t("Delete room")}
							label={t("Delete room")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("This action will decrement the contingent. Are you sure you want to delete this room?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		],
		[crudOperations, t, hotelId, roomTypeId, roomContingentId, queryClient],
	);

	return (
		<DrawerAutoDataList
			columns={dataListColumns}
			service={roomService}
			renderCreateDrawer={(drawerProps) => (
				<RoomDrawer
					{...drawerProps}
					confirmationAccepted={false}
					heading={t("Add room")}
					initialValues={emptyRoom}
					onSubmit={(entityFormState) => crudOperations.createEntity(entityFormState)}
				/>
			)}
			renderUpdateDrawer={(entityResource, drawerProps) => (
				<RoomDrawer
					{...drawerProps}
					confirmationAccepted={true}
					heading={t("Edit room")}
					initialValues={entityResource}
					onSubmit={(entityFormState) => crudOperations.updateEntity(entityResource.id, entityFormState)}
				/>
			)}
			translations={{
				title: t("Rooms"),
				empty: t("No rooms available."),
			}}
		/>
	);
};
