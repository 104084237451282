import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { CreateEmailContent } from "./CreateEmailContent";
import { CreateEmailDataForm } from "./CreateEmailDataForm";

export const EmailCreatePage: FC = () => {
	return (
		<Routes>
			<Route path="data" element={<CreateEmailDataForm />} />
			<Route path="content/:id" element={<CreateEmailContent />} />
			<Route index element={<Navigate to="data" />} />
		</Routes>
	);
};
