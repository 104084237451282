import {
	CheckboxField,
	CheckboxGroupField,
	CrudDrawer,
	DateField,
	DateTimeField,
	Id,
	MultiSelectField,
	SingleSelectField,
	TextField,
	TextareaField,
	TimeField,
} from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useDataFieldsQuery } from "~shared/api/dataFields";
import { hotelInquiryService } from "~shared/api/hotelInquiries";
import { useGuestImportErrorsQuery } from "~shared/api/imports";
import { RadioDataField } from "~shared/dataFields/RadioDataField";
import { StatusSelect } from "~shared/guests/details/StatusSelect";
import { useGuestImportErrors } from "~shared/import/ImportErrorListProvider";
import { ContentLanguageImportSingleSelect } from "~shared/selects/ContentLanguageSingleSelect";
import { GroupMultiSelect } from "~shared/selects/GroupMultiSelect";
import { GuestSingleSelect } from "~shared/selects/GuestSingleSelect";
import { RegistrationFormSingleSelect } from "~shared/selects/RegistrationFormSingleSelect";
import { TagMultiSelect } from "~shared/selects/TagMultiSelect";
import { TenantSelectField } from "~shared/selects/TenantSelectField";
import { WaveSelectField } from "~shared/selects/WaveSelectField";
import { IDataFieldIndexResource } from "~shared/types/dataField";
import { IDataFieldOptionIndexResource } from "~shared/types/dataFieldOption";
import { DataFieldType } from "~shared/types/dataFieldType";
import { ImportError, ImportErrorKey } from "~shared/types/guestImport";

interface Props {
	errorId: Id;
	onClose: () => void;
}

const Error = styled.div`
	color: ${({ theme }) => theme.colors.palette.danger.main.base};
`;

const ErrorWrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: ${({ theme }) => theme.spacing(2)};
`;

const renderDataField = (error: ImportError, dataFields: IDataFieldIndexResource[]) => {
	const dataField = dataFields.find((field) => field.id === error.dataFieldId);

	if (!dataField) {
		return null;
	}

	switch (dataField.type) {
		case DataFieldType.RADIO:
			return (
				<RadioDataField
					name={dataField.id.toString()}
					key={dataField.id}
					disabled={!error.error}
					dataField={dataField}
				/>
			);
		case DataFieldType.SELECT_FIELD:
			return (
				<SingleSelectField
					key={dataField.id}
					label={dataField.name}
					name={dataField.id.toString()}
					options={dataField.options}
					getLabel={(option: IDataFieldOptionIndexResource) => option.value}
					disabled={!error.error}
				/>
			);
		case DataFieldType.MULTI_SELECT_FIELD:
			return (
				<MultiSelectField
					key={dataField.id}
					label={dataField.name}
					name={dataField.id.toString()}
					options={dataField.options}
					getLabel={(option: IDataFieldOptionIndexResource) => option.value}
					disabled={!error.error}
				/>
			);
		case DataFieldType.CHECKBOX_GROUP:
			return (
				<CheckboxGroupField
					key={dataField.id}
					label={dataField.name}
					name={dataField.id.toString()}
					options={dataField.options.map((x) => ({
						name: x.value,
						value: x.id,
					}))}
					disabled={!error.error}
				/>
			);
		case DataFieldType.DATE:
			return (
				<DateField key={dataField.id} name={dataField.id.toString()} label={dataField.name} disabled={!error.error} />
			);
		case DataFieldType.TIME:
			return (
				<TimeField key={dataField.id} name={dataField.id.toString()} label={dataField.name} disabled={!error.error} />
			);
		case DataFieldType.DATETIME:
			return (
				<DateTimeField
					key={dataField.id}
					name={dataField.id.toString()}
					label={dataField.name}
					disabled={!error.error}
				/>
			);
		case DataFieldType.CHECKBOX:
			return (
				<CheckboxField
					key={dataField.id}
					name={dataField.id.toString()}
					label={dataField.name}
					disabled={!error.error}
				/>
			);
		case DataFieldType.NUMBER:
			return (
				<TextField
					type="number"
					key={dataField.id}
					name={dataField.id.toString()}
					label={dataField.name}
					disabled={!error.error}
				/>
			);
		case DataFieldType.TEXT:
			return (
				<TextField key={dataField.id} name={dataField.id.toString()} label={dataField.name} disabled={!error.error} />
			);
		case DataFieldType.EMAIL:
			return (
				<TextField key={dataField.id} name={dataField.id.toString()} label={dataField.name} disabled={!error.error} />
			);
		case DataFieldType.LONG_TEXT:
			return (
				<TextareaField
					key={dataField.id}
					name={dataField.id.toString()}
					label={dataField.name}
					disabled={!error.error}
				/>
			);
		case DataFieldType.URL:
			return (
				<TextField key={dataField.id} name={dataField.id.toString()} label={dataField.name} disabled={!error.error} />
			);
		case DataFieldType.PHONE:
			return (
				<TextField key={dataField.id} name={dataField.id.toString()} label={dataField.name} disabled={!error.error} />
			);
	}
};

const createInitialValues = (importError: ImportError[]) => {
	return importError.reduce((acc, current) => {
		const InitialValues: Record<string, any> =
			current.type === "dataField"
				? {
						...acc,
						[`${current.dataFieldId}`]: current.value,
				  }
				: {
						...acc,
						[current.type]: current.value,
				  };
		return InitialValues;
	}, {});
};

export const ImportErrorDrawer: FC<Props> = ({ errorId, onClose }) => {
	const { t } = useTranslation();
	const { data: dataFields } = useDataFieldsQuery();
	const { guestImportId = "" } = useParams<"guestImportId">();
	const { submitCorrectedValues } = useGuestImportErrors();
	const { data: importError, isLoading } = useGuestImportErrorsQuery(guestImportId, errorId);
	const queryClient = useQueryClient();

	const renderErrorField = useCallback(
		(error: ImportError) => {
			switch (error.type) {
				case "id":
					return <GuestSingleSelect name={"id"} label={t("Guest")} disabled={!error.error} />;
				case "wave":
					return <WaveSelectField name="wave" label={t("Wave")} disabled={!error.error} />;
				case "tenant":
					return <TenantSelectField name="tenant" label={t("Tenant")} disabled={!error.error} />;
				case "groups":
					return <GroupMultiSelect name="groups" label={t("Groups")} disabled={!error.error} />;
				case "tags":
					return <TagMultiSelect name="tags" label={t("Tags")} disabled={!error.error} />;
				case "status":
					return <StatusSelect name="status" label={t("Status")} disabled={!error.error} />;
				case "registrationForm":
					return (
						<RegistrationFormSingleSelect
							name="registrationForm"
							label={t("Registration form")}
							disabled={!error.error}
						/>
					);
				case "registrationLocale":
					return (
						<ContentLanguageImportSingleSelect
							name="registrationLocale"
							label={t("registrationLocale")}
							disabled={!error.error}
						/>
					);
				case "registrationCode":
					return <TextField name="registrationCode" label={t("registrationCode")} disabled={!error.error} />;
				default:
					return renderDataField(error, dataFields);
			}
		},
		[dataFields, t],
	);

	const getErrorMessage = useCallback(
		(error: ImportErrorKey): string => {
			switch (error) {
				case ImportErrorKey.DATA_FIELD_NOT_EXISTENT:
					return t("Data field does not exist");
				case ImportErrorKey.INVALID_DATE:
				case ImportErrorKey.INVALID_DATE_TIME:
					return t("The given date is invalid");
				case ImportErrorKey.INVALID_TIME:
					return t("The given time format is invalid");
				case ImportErrorKey.INVALID_EMAIL:
					return t("The mail address is invalid");
				case ImportErrorKey.INVALID_INPUT:
					return t("The input is invalid");
				case ImportErrorKey.GROUP_NOT_EXISTENT:
					return t("The given group does not exist");
				case ImportErrorKey.DATA_FIELD_OPTION_NOT_EXISTENT:
					return t("The given option does not exist");
				case ImportErrorKey.TAG_NOT_EXISTENT:
					return t("The given tag does not exist");
				case ImportErrorKey.STATUS_NOT_EXISTENT:
					return t("The given status does not exist");
				case ImportErrorKey.REGISTRATION_FORM_NOT_EXISTENT:
					return t("The given registration form does not exist");
				case ImportErrorKey.UNIQUE_EMAIL:
					return t("Recipient email must be unique");
				case ImportErrorKey.INVALID_URL:
					return t("The given URL is invalid");
				case ImportErrorKey.GUEST_ID_NOT_EXISTENT:
					return t("This dataset needs to be assigned to a guest id");
				case ImportErrorKey.WAVE_NOT_EXISTENT:
					return t("The given wave does not exist");
				case ImportErrorKey.TENANT_NOT_EXISTENT:
					return t("The given tenant does not exist or you don't have permission for it");
				case ImportErrorKey.WAVE_CONTINGENT:
					return t("This wave doesn't have enough contingent for the attached tenant");
				case ImportErrorKey.WAVE_TENANT_COMBINATION:
					return t("This wave is not attached to the given tenant");
				case ImportErrorKey.REGISTRATION_LOCALE:
					return t("Invalid registration language");
				case ImportErrorKey.INVALID_REGISTRATION_CODE:
					return t("Invalid registration code");
				case ImportErrorKey.UNIQUE_REGISTRATION_CODE:
					return t("Registration code must be unique");
				case ImportErrorKey.INVALID_HOTEL_INQUIRY:
					return t("Check in date must be before check out date");
			}
		},
		[t],
	);

	const handleSubmit = useCallback(
		async (values: Record<string, any>) => {
			await submitCorrectedValues(errorId, values);
			await queryClient.invalidateQueries({ queryKey: hotelInquiryService.keys.index });
			onClose();
		},
		[submitCorrectedValues, onClose, errorId, queryClient],
	);

	return (
		!isLoading && (
			<CrudDrawer
				open={!!errorId}
				onSubmit={async (values) => {
					handleSubmit(values);
					return true;
				}}
				initialValues={createInitialValues(importError)}
				onClose={onClose}
				heading={t("Correct errors")}
				translations={{ save: t("Confirm") }}
				body={
					<>
						<ErrorWrapper>
							{importError.map((error: ImportError, index) => (
								<React.Fragment key={`${index}`}>
									{error.error && (
										<Error>
											{getErrorMessage(error.error)}: {error.value}
										</Error>
									)}
									{renderErrorField(error)}
								</React.Fragment>
							))}
						</ErrorWrapper>
					</>
				}
			/>
		)
	);
};
