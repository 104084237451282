import { RadioChangeEvent, RadioControl } from "@dgs/core";
import { useField } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

interface Props {
	name: string;
}

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: max-content max-content;
	gap: ${({ theme }) => theme.spacing(4)};
`;

export const CheckboxValueField: FC<Props> = ({ name }) => {
	const { t } = useTranslation();
	const [field] = useField(name);

	const handleChange = (e: RadioChangeEvent) => field.onChange({ target: { name, value: e.target.value === "true" } });

	return (
		<Wrapper>
			<RadioControl
				label={t("Yes")}
				name={name}
				value="true"
				checked={field.value === true}
				onChange={handleChange}
				id={`${name}_yes`}
			/>
			<RadioControl
				label={t("No")}
				name={name}
				value="false"
				checked={field.value === false}
				onChange={handleChange}
				id={`${name}_no`}
			/>
		</Wrapper>
	);
};
