import { Empty, Id, SubmitButton, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ValidationRuleSelect } from "~admin/registrationForms/input/RuleSelect";
import {
	AddRuleOrActionButton,
	RemoveRuleOrActionButton,
	RuleList,
	RuleValueField,
} from "~admin/registrationForms/ruleSets";
import { registrationFormStructureService } from "~shared/api/registrationForms/registrastionFormStructureService";
import { registrationFormDataFieldService } from "~shared/api/registrationForms/registrationFormDataFieldService";
import { registrationFormSectionService } from "~shared/api/registrationForms/registrationFormSectionService";
import { getRuleValuePayload } from "~shared/registrationForms/getRuleValue";
import { IRegistrationFormSectionDataField } from "~shared/types/registrationFormChildDataField";
import { StructureSection } from "~shared/types/registrationFormTypes";
import { BaseRuleFormState, IRuleFormState, Rule, RuleActionTypes, RuleTypes } from "~shared/types/rules";
import { RuleSetType } from "~shared/types/ruleSet";

const StyledRuleList = styled(RuleList)`
	grid-template-columns: 1fr 1fr max-content;
`;

const Footer = styled.div`
	display: flex;
	justify-content: end;
	margin-top: ${({ theme }) => theme.spacing(2)};
`;

interface RuleValidation {
	rules: Rule[];
}

interface Props {
	dataField: IRegistrationFormSectionDataField;
	section: StructureSection;
	handleClose: () => void;
	stepId: Id;
	registrationFormId: string;
	ruleActionTypes: RuleActionTypes;
}

export const DataFieldValidationDrawerContent: FC<Props> = ({
	stepId,
	registrationFormId,
	dataField,
	section,
	handleClose,
	ruleActionTypes,
}) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const initialRule: IRuleFormState = useMemo(
		() => ({
			id: -1,
			type: null,
			sectionDataField: {
				dataField,
				section: {
					id: section.sectionId,
					name: section.name,
				},
			},
			value: null,
		}),
		[dataField, section],
	);
	const queryClient = useQueryClient();

	const onSubmit = useCallback(
		async ({ rules }: { rules: Rule[] }) => {
			const body = rules.map((x) => ({
				...x,
				dataFieldId: dataField.id,
				value: getRuleValuePayload(x),
			}));
			const response = await registrationFormDataFieldService.postValidationRules(
				registrationFormId,
				stepId,
				section.sectionId,
				dataField.id,
				body,
			);

			if (apiIsOK(response)) {
				showToast({
					body: t("Validation saved successfully."),
					type: "success",
					title: t("Validation"),
				});
				await queryClient.invalidateQueries({
					queryKey: registrationFormSectionService.keys.showValidation(
						registrationFormId,
						stepId,
						section.id,
						dataField.id,
					),
				});
				await queryClient.invalidateQueries({
					queryKey: registrationFormStructureService.keys.structure(registrationFormId),
				});
				void queryClient.invalidateQueries({
					queryKey: registrationFormSectionService.keys.showValidation(
						registrationFormId,
						stepId,
						section.sectionId,
						dataField.id,
					),
				});
				handleClose();
			}
		},
		[dataField.id, handleClose, queryClient, registrationFormId, section.id, section.sectionId, showToast, stepId, t],
	);

	const validateRules = useCallback(
		(rules: BaseRuleFormState[]) => {
			const ruleErrors: Record<keyof BaseRuleFormState, string>[] = [];

			rules.forEach((rule, index) => {
				const error: Record<string, string> = {};

				if (!rule.type) {
					error.type = t("Field is required");
				}

				if (
					!rule.value &&
					rule.type !== RuleTypes.IS_REQUIRED &&
					rule.type !== RuleTypes.NOT_UPDATABLE &&
					rule.type !== RuleTypes.READ_ONLY
				) {
					error.value = t("Field is required");
				}

				if (Object.keys(error).length) {
					ruleErrors[index] = error;
				} else {
					delete ruleErrors[index];
				}
			});
			return ruleErrors;
		},
		[t],
	);

	const validate = useCallback(
		(values: RuleValidation) => {
			const errors: { [K in keyof RuleValidation]?: any } = {};
			const validatedRules = validateRules(values.rules);

			if (values.rules.length && validatedRules.length) {
				errors.rules = validatedRules;
			}

			return errors;
		},
		[validateRules],
	);

	return (
		<Formik initialValues={{ rules: dataField.validationRules }} onSubmit={onSubmit} validate={validate}>
			{({ values }) => (
				<Form>
					<StyledRuleList>
						{values.rules.map((rule, index) => (
							<React.Fragment key={`rule-${index}`}>
								<ValidationRuleSelect
									name={`rules.${index}.type`}
									label={t("Rule type")}
									rulesetType={RuleSetType.Validation}
									dataField={dataField}
									index={index}
								/>
								<RuleValueField name={`rules.${index}.value`} rule={rule} />
								<RemoveRuleOrActionButton index={index} type="rules" />
							</React.Fragment>
						))}
					</StyledRuleList>
					{!values.rules.length && <Empty>{t("No rules existent")}</Empty>}
					<AddRuleOrActionButton
						initialValue={initialRule}
						type="rules"
						disabled={values.rules.length === ruleActionTypes[RuleSetType.Validation].rules[dataField.type].length}
					/>
					<Footer>
						<SubmitButton title={t("Save")} name={t("Save")}>
							{t("Save")}
						</SubmitButton>
					</Footer>
				</Form>
			)}
		</Formik>
	);
};
