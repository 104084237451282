import { CardBody, CardFooter, CheckboxField, FileInputField, SubmitButton } from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { CreateFile } from "~shared/types/file";

interface Props {
	onSubmit: (file: CreateFile) => void;
}

const initialValues: CreateFile = {
	file: null,
	isPublic: false,
};

export const FileForm: FC<Props> = ({ onSubmit }) => {
	const { t } = useTranslation();
	const handleSubmit = useCallback(
		(values: CreateFile) => {
			return onSubmit(values);
		},
		[onSubmit],
	);

	return (
		<Formik initialValues={initialValues} onSubmit={handleSubmit}>
			<Form>
				<CardBody>
					<FileInputField label={t("File")} name="file" />
					<CheckboxField label={t("Public")} name="isPublic" />
				</CardBody>
				<CardFooter>
					<SubmitButton title={t("Save")} name={t("Save")}>
						{t("Save")}
					</SubmitButton>
				</CardFooter>
			</Form>
		</Formik>
	);
};
