import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { HotelBookingsPage } from "~admin/hotels/bookings";
import { HotelInquiriesPage } from "~admin/hotels/hotelInquiries/HotelInquiriesPage";
import { RoomCreatePage } from "~admin/hotels/roomTypes/RoomTypeCreatePage";
import { RoomTypePage } from "~admin/hotels/roomTypes/RoomTypePage";
import { HotelSettings } from "~admin/hotels/settings/HotelSettings";
import { StartHotelManager } from "~admin/hotels/StartHotelManager";
import { LocalGuestViewProvider } from "~shared/providers/LocalGuestViewProvider";
import { ContingentOverviewPage } from "./contingents/ContingentOverviewPage";
import { HotelCreatePage } from "./HotelCreatePage";
import { HotelEditPage } from "./HotelEditPage";
import { HotelList } from "./HotelList";
import { HotelStepperPage } from "./stepper/HotelStepperPage";

export const HotelManagementPage: FC = () => {
	return (
		<Routes>
			<Route index element={<Navigate to="hotels" />} />
			<Route path="overview" element={<StartHotelManager />} />
			<Route path="hotels" element={<HotelList />} />
			<Route path="create-hotel/*" element={<HotelStepperPage />} />
			<Route path="hotels/new" element={<HotelCreatePage />} />
			<Route path="room-types/new/hotels/:hotelId" element={<RoomCreatePage />} />
			<Route path="hotels/:hotelId/*" element={<HotelEditPage />} />
			<Route path="room-types/*" element={<RoomTypePage />} />
			<Route path="requests/*" element={<HotelInquiriesPage />} />
			<Route path="contingents" element={<ContingentOverviewPage />} />
			<Route
				path="bookings/*"
				element={
					<LocalGuestViewProvider guestViewKey="bookingsList">
						<HotelBookingsPage />
					</LocalGuestViewProvider>
				}
			/>
			<Route path="settings" element={<HotelSettings />} />
		</Routes>
	);
};
