import { IconAnchorButton, Id, useDynamicConfig } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { GuestDetailsResource } from "~shared/types/newGuestTypes";

interface Props {
	guest: GuestDetailsResource | null;
}
export const GoToGuestRegistrationForm: FC<Props> = ({ guest }) => {
	const { frontendUrl } = useDynamicConfig();

	const registrationFormId: Id | null = useMemo(
		() => guest?.attributes.find((attribute) => attribute.entityType === "registrationForm")?.value?.id || null,
		[guest?.attributes],
	);

	if (!guest || !registrationFormId) {
		return null;
	}

	return (
		<IconAnchorButton
			title="Open registration form in frontend as guest"
			target="_blank"
			href={`${frontendUrl}/registrations/${registrationFormId}?token=${guest.id}`}
			icon="external"
		/>
	);
};
