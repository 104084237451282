import {
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerHeading,
	Id,
	SubmitButton,
	apiIsOK,
	useToasts,
} from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { guestKeys, guestService } from "~shared/api/guests";
import { GuestScreeningStatus } from "~shared/types/guest";
import { GuestScreeningStatusSelect } from "./GuestScreeningStatusSelect";

const options: GuestScreeningStatus[] = [
	GuestScreeningStatus.NEGATIVE_CONFIRMED,
	GuestScreeningStatus.POSITIVE_CONFIRMED,
];

interface Props {
	guestId: Id;
	screeningStatus: GuestScreeningStatus;
	open: boolean;
	onClose: () => void;
}

export const GuestScreeningStatusDrawer: FC<Props> = ({ guestId, open, onClose, screeningStatus }) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { showToast } = useToasts();

	const handleSubmit = async (value: { screeningStatus: GuestScreeningStatus }) => {
		const response = await guestService.changeScreeningStatus(guestId, value.screeningStatus);

		if (apiIsOK(response)) {
			showToast({
				title: t("Change screening status"),
				body: t("Changed screening status successfully."),
				type: "success",
			});
			onClose();
			void queryClient.invalidateQueries({ queryKey: guestKeys.index });
		}
	};

	const validate = (value: { screeningStatus: GuestScreeningStatus }) => {
		if (
			value.screeningStatus === GuestScreeningStatus.POSITIVE_AUTOMATIC ||
			value.screeningStatus === GuestScreeningStatus.NEGATIVE_AUTOMATIC ||
			value.screeningStatus === GuestScreeningStatus.NONE
		) {
			return { screeningStatus: t("Invalid screening status") };
		}

		return {};
	};

	return (
		<Drawer open={open} onClose={onClose}>
			<Formik initialValues={{ screeningStatus }} onSubmit={handleSubmit} validate={validate}>
				<Form>
					<DrawerHeader onClose={onClose}>
						<DrawerHeading>{t("Change screening status")}</DrawerHeading>
					</DrawerHeader>
					<DrawerBody>
						<GuestScreeningStatusSelect
							required={true}
							options={options}
							name="screeningStatus"
							label={t("screeningStatus")}
						/>
					</DrawerBody>
					<DrawerFooter>
						<SubmitButton>{t("Save")}</SubmitButton>
					</DrawerFooter>
				</Form>
			</Formik>
		</Drawer>
	);
};
