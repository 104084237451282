import { useMemo } from "react";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { IRoomTypeResource, IRoomTypeStepperResource, RoomTypeTranslatableAttributes } from "~shared/types/roomType";

export const createEmptyRoomType = (locales: string[]): IRoomTypeResource => {
	return {
		internalRemark: "",
		phone: "",
		id: -1,
		name: "",
		capacity: 0,
		email: "",
		customEmailForBookingOverview: null,
		customEmailForRequester: null,
		defaultPrices: [],
		groups: [],
		translations: locales.reduce(
			(translations, locale) => {
				return {
					...translations,
					[locale]: { guestRemark: "" },
				};
			},
			{} as Record<string, RoomTypeTranslatableAttributes>,
		),
		showOccupancyInSummary: false,
	};
};

export const useEmptyRoomType = (): IRoomTypeResource => {
	const { locales } = useContentLocale();
	return useMemo(() => createEmptyRoomType(locales), [locales]);
};

export const createEmptyStepperRoomType = (locales: string[]): IRoomTypeStepperResource => {
	return {
		id: -1,
		name: "",
		capacity: 0,
		phone: "",
		internalRemark: "",
		defaultPrices: [],
		email: "",
		customEmailForBookingOverview: null,
		customEmailForRequester: null,
		startDate: "",
		endDate: "",
		defaultContingent: 0,
		groups: [],
		overbookable: false,
		translations: locales.reduce(
			(translations, locale) => {
				return {
					...translations,
					[locale]: { guestRemark: "" },
				};
			},
			{} as Record<string, RoomTypeTranslatableAttributes>,
		),
		showOccupancyInSummary: false,
	};
};

export const useEmptyStepperRoomType = (): IRoomTypeStepperResource => {
	const { locales } = useContentLocale();
	return useMemo(() => createEmptyStepperRoomType(locales), [locales]);
};
