import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { registrationFormStructureService } from "~shared/api/registrationForms/registrastionFormStructureService";
import { registrationFormDataFieldService } from "~shared/api/registrationForms/registrationFormDataFieldService";
import { ruleSetService } from "~shared/api/registrationForms/ruleSets/ruleSetService";
import { SyncChildrenRequest } from "~shared/types/registrationFormTypes";

export const useRegistrationFormDataField = (formId: string, stepId: Id, sectionId: Id) => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const syncRegistrationFormDataField = useCallback(
		async (syncChildrenRequest: SyncChildrenRequest[]) => {
			const response = await registrationFormDataFieldService.sync(formId, stepId, sectionId, { syncChildrenRequest });
			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form data field was saved."),
					title: t("Save registration form data field"),
					type: "success",
				});

				void queryClient.invalidateQueries({ queryKey: registrationFormStructureService.keys.structure(formId) });
				return true;
			}
			return false;
		},
		[formId, stepId, sectionId, showToast, t, queryClient],
	);

	const detachRegistrationFormDataField = useCallback(
		async ({ dataFieldIds }: { dataFieldIds: Id[] }) => {
			const response = await registrationFormDataFieldService.detach(formId, stepId, sectionId, { dataFieldIds });
			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form data field was deleted."),
					title: t("Detach registration form data field"),
					type: "success",
				});
				void queryClient.invalidateQueries({ queryKey: registrationFormStructureService.keys.structure(formId) });
				void queryClient.invalidateQueries({ queryKey: ruleSetService.keys.ruleSets(formId) });
			}
		},
		[formId, stepId, sectionId, showToast, t, queryClient],
	);

	return { syncRegistrationFormDataField, detachRegistrationFormDataField };
};
