import React, { FC } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { emptyAutomation } from "~admin/shared/utils";
import { RuleSetType } from "~shared/types/ruleSet";
import { RuleSetCreatePage, RuleSetEditPage, RuleSetListPage } from "../ruleSets";

export const AutomationPage: FC = () => {
	const { registrationFormId = "" } = useParams<"registrationFormId">();

	return (
		<Routes>
			<Route index element={<RuleSetListPage ruleSetType={RuleSetType.Automation} />} />
			<Route
				path="new"
				element={<RuleSetCreatePage registrationFormId={registrationFormId} ruleSet={emptyAutomation} />}
			/>
			<Route path=":ruleSetId" element={<RuleSetEditPage ruleSetType={RuleSetType.Automation} />} />
		</Routes>
	);
};
