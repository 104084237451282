import { BaseCrudService, Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { ModelUsageResource } from "~shared/types/modelUsageResource";
import {
	IPaymentProviderCreateRequest,
	IPaymentProviderIndexResource,
	IPaymentProviderUpdateRequest,
	IPaymentProviderUpdateResource,
	TPaymentProviderType,
} from "~shared/types/paymentProvider";

export class PaymentProviderService extends BaseCrudService<
	IPaymentProviderIndexResource,
	IPaymentProviderUpdateResource,
	IPaymentProviderUpdateRequest,
	IPaymentProviderCreateRequest
> {
	public keys = {
		index: ["payment-providers"],
		list: ["payment-providers", "list"],
		details: (id: Id) => ["payment-providers", `${id}`],
		options: ["payment-providers", "options"],
		typeOptions: ["payment-providers", "type-options"],
		showUsage: (paymentProviderId: Id) => [...this.keys.details(paymentProviderId), "usage"],
	};
	protected urls = {
		index: keyToUrl(this.keys.index),
		details: (id: Id) => keyToUrl(this.keys.details(id)),
		options: keyToUrl(this.keys.options),
		typeOptions: keyToUrl(this.keys.typeOptions),
		showUsage: (paymentProviderId: Id) => keyToUrl(this.keys.showUsage(paymentProviderId)),
	};

	options = (config?: AxiosRequestConfig) => {
		return axios.get<LaravelResponse<IPaymentProviderIndexResource[]>>(this.urls.options, config);
	};

	typeOptions(config?: AxiosRequestConfig) {
		return axios.get<LaravelResponse<TPaymentProviderType[]>>(this.urls.typeOptions, config);
	}

	showUsage = (paymentProviderId: Id) =>
		axios.get<LaravelResponse<ModelUsageResource[]>>(this.urls.showUsage(paymentProviderId));
}

export const paymentProviderService = new PaymentProviderService();
