import { PagedMultiSelectField } from "@dgs/core";
import React, { FC } from "react";
import { tagKeys, tagService } from "~shared/api/tags";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
	className?: string;
}

const fetcher = (search: string, page: number) => tagService.options(search, page).then((r) => r.data);

export const TagMultiSelect: FC<Props> = ({ name, label, disabled, required, className }) => {
	return (
		<PagedMultiSelectField
			name={name}
			label={label}
			disabled={disabled}
			queryKey={tagKeys.options}
			fetcher={fetcher}
			required={required}
			className={className}
		/>
	);
};
