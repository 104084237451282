import { AutoDataList, IDataListColumn, IconButton, ListFetcher, Trigger } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { ActiveGuestFilterEntries, useGuestFilter } from "~shared/guestFilters";
import { useGuestListUtils } from "~shared/guests";
import { AttachCompanionsDrawer } from "~shared/guests/AttachCompanionsDrawer";
import { useCompanionUtils } from "~shared/guests/companion/useCompanionUtils";
import { LocalGuestViewSwitcher } from "~shared/guests/GuestViewSwitchers";
import { useGuestView } from "~shared/providers/useGuestView";
import { GuestDetailsResource, GuestIndexResource } from "~shared/types/newGuestTypes";

interface Props {
	queryKey: string[];
	fetcher: ListFetcher<GuestIndexResource, any, any>;
	guest: GuestDetailsResource;
	bulkEditActions?: React.ReactNode;
	contextMenu: (guest: GuestIndexResource) => React.ReactNode;
}

export const BaseCompanionList: FC<Props> = ({ queryKey, fetcher, guest, bulkEditActions, contextMenu }) => {
	const { t } = useTranslation();
	const { currentGuestView } = useGuestView();
	const { columns } = useGuestListUtils(currentGuestView);
	const { handleOpen } = useGuestFilter();
	const { attachCompanion } = useCompanionUtils();
	const actionColumn: IDataListColumn<GuestIndexResource> = { heading: "", type: "actions", action: contextMenu };
	const guestColumns = columns.concat(actionColumn);

	return (
		<>
			{guest && (
				<AutoDataList
					name="companion"
					heading={t("Companions")}
					headerChildren={<ActiveGuestFilterEntries />}
					headerActions={
						<>
							<LocalGuestViewSwitcher />
							<IconButton size="small" title={t("Guest filter")} onClick={handleOpen} icon="filter" />
							<Trigger
								render={(props) => (
									<AttachCompanionsDrawer
										heading={t("Attach companions")}
										open={props.open}
										onClose={props.handleClose}
										initialValues={{ companions: [] }}
										onSubmit={(companions) => attachCompanion(companions.companions, guest.id)}
									/>
								)}
							>
								{(triggerProps) => (
									<IconButton size="small" title={t("Attach companions")} {...triggerProps} icon="plus" />
								)}
							</Trigger>
						</>
					}
					empty={t("No companions available.")}
					columns={guestColumns}
					queryKey={queryKey}
					fetcher={fetcher}
					to={(value) => `../../${value.id}`}
					initialSearch={{ search: "", searchFields: ["textDataFields"] }}
					enableBulkEdit={!!bulkEditActions}
					bulkEditActions={bulkEditActions}
				/>
			)}
		</>
	);
};
