import { Id } from "@dgs/core";
import { Country } from "~shared/types/country";

export interface ITax {
	id: Id;
	type: TaxTypes;
	percent: number | null;
	notice: string | null;
}

export interface ITaxes {
	id: Id;
	tax: ITax;
	country: Country;
	isBusiness: boolean;
}

export interface ITaxesRequest {
	taxId: Id;
	countryId: Id;
	isBusiness: boolean;
}

export enum TaxTypes {
	TYPE_NORMAL = "normal",
	TYPE_REDUCED = "reduced",
	TYPE_REVERSE_CHARGE = "reverseCharge",
}

export type ITaxIndexResource = ITax;
export type ITaxUpdateResource = ITax;
export type ITaxOptionResource = ITax;
export type ITaxRequest = ITax;
