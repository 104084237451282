import { useField } from "formik";
import React, { FC, ReactElement } from "react";
import { useI18nForm, useLocaleIndex } from "./I18nFormProvider";

interface Props {
	name: string;
	children: ReactElement;
}

export const I18nFieldWrapper: FC<Props> = ({ name, children }) => {
	const { fallbackLocale, currentLocale } = useI18nForm();
	const fallbackLocaleIdx = useLocaleIndex(fallbackLocale);
	const currentLocaleIdx = useLocaleIndex(currentLocale);
	const [{ value: fallbackValue }] = useField(`translations.${fallbackLocaleIdx}.${name}`);

	if (React.Children.count(children) !== 1) {
		throw new Error("Only one child is supported");
	}

	if (children)
		if (fallbackValue && currentLocale !== fallbackLocale) {
			return React.cloneElement(children, {
				description: "Fallback: " + fallbackValue,
				key: `translations.${currentLocaleIdx}.${name}`,
			});
		}

	return children;
};
