import { SingleSelectControl } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { GuestViewSwitcher } from "~shared/guests/GuestViewSwitcher";
import { useGlobalGuestView } from "~shared/providers/GlobalGuestViewProvider";
import { useLocalGuestView } from "~shared/providers/LocalGuestViewProvider";
import { GuestViewConfigResource } from "~shared/types/guestView";

const StyledSingleSelectControl = styled(SingleSelectControl)`
	padding-bottom: 0;
`;

export const GlobalGuestViewSwitcher: FC = () => {
	const { globalGuestView, guestViews, setGlobalGuestView } = useGlobalGuestView();

	return (
		<GuestViewSwitcher currentGuestView={globalGuestView} guestViews={guestViews} setGuestView={setGlobalGuestView} />
	);
};

export const LocalGuestViewSwitcher: FC = () => {
	const { t } = useTranslation();
	const { guestViews } = useGlobalGuestView();
	const { localGuestView, setLocalGuestView } = useLocalGuestView();

	return (
		<StyledSingleSelectControl
			options={guestViews}
			onChange={(e) => setLocalGuestView(e.target.value as GuestViewConfigResource)}
			value={localGuestView}
			name="localGuestViewSelect"
			onClear={() => setLocalGuestView(null)}
			placeholder={t("Select a guest view")}
			clearable={true}
		/>
	);
};
