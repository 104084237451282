import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { ConsentsList } from "./ConsentsList";

export const ConsentsPage: FC = () => {
	return (
		<Routes>
			<Route index element={<ConsentsList />} />
		</Routes>
	);
};
