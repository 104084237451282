import { Id, PagedMultiSelectField } from "@dgs/core";
import React, { FC } from "react";
import { ordersKeys, ordersService } from "~admin/shared/api/orders";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
	orderId: Id;
}

const fetcher = (orderId: Id) => (search: string, page: number) =>
	ordersService.refundableItems(orderId, { params: { search, page } }).then((r) => r.data);

export const RefundableItemMultiSelect: FC<Props> = ({ name, label, disabled, required, orderId }) => {
	return (
		<PagedMultiSelectField
			name={name}
			queryKey={ordersKeys.refundableItems(orderId)}
			fetcher={fetcher(orderId)}
			label={label}
			disabled={disabled}
			clearable={true}
			getLabel={(option) => `${option.name}`}
			required={required}
		/>
	);
};
