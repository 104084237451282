import { Id, PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { guestService } from "~shared/api/guests/guestService";
import { Guest } from "~shared/types/guest";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
	exclude?: Id[];
}

export const getGuestString = (guest: Guest) => {
	let firstName;
	let lastName;
	let email;
	if (guest.firstName) {
		firstName = guest.firstName;
	} else {
		firstName = "NO_FIRST_NAME";
	}
	if (guest.lastName) {
		lastName = guest.lastName;
	} else {
		lastName = "NO_LAST_NAME";
	}
	if (guest.email) {
		email = `(${guest.email})`;
	} else {
		email = "(NO_EMAIL_ADDRESS)";
	}
	return [firstName, lastName, email].join(" ");
};

export const GuestSingleSelect: FC<Props> = ({ name, label, required, disabled = false, exclude = [] }) => {
	return (
		<PagedSingleSelectField
			name={name}
			label={label}
			queryKey={["guests", ...exclude]}
			disabled={disabled}
			getLabel={getGuestString}
			fetcher={(search, page) => guestService.options(exclude, { params: { search, page } }).then((r) => r.data)}
			clearable={true}
			required={required}
		/>
	);
};
