import { Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { IDataFieldContingentResource, IDataFieldIndexResource } from "~shared/types/dataField";
import { dataFieldService } from "./dataFieldService";

const dataFields: IDataFieldIndexResource[] = [];
export const useDataFieldsQuery = (params?: Record<string, string | number>) => {
	const { data, isLoading } = useQuery({
		queryKey: dataFieldService.keys.indexAll.concat(JSON.stringify(params || {})),
		queryFn: () => dataFieldService.indexAll({ params }).then((r) => r.data.data),
	});

	return {
		data: data || dataFields,
		isLoading,
	};
};

export const useDataFieldQuery = (dataFieldId: Id) => {
	const { data, isLoading } = useQuery({
		queryKey: dataFieldService.keys.details(dataFieldId),
		queryFn: () => dataFieldService.show(dataFieldId).then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};

const dataFieldContingent: IDataFieldContingentResource[] = [];
export const useDataFieldContingentQuery = (params?: Record<string, string | number>) => {
	const { data, isLoading } = useQuery({
		queryKey: dataFieldService.keys.contingentOverview.concat(JSON.stringify(params || {})),
		queryFn: () => dataFieldService.getContingentOverview({ params }).then((r) => r.data.data),
	});

	return {
		dataFieldContingents: data || dataFieldContingent,
		isLoading,
	};
};
