import { TextField } from "@dgs/core";
import { useField } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { StatusSelect } from "~shared/guests/details/StatusSelect";
import { EmailSingleSelect } from "~shared/selects/EmailSingleSelect";
import { GroupMultiSelect } from "~shared/selects/GroupMultiSelect";
import { RegistrationFormSingleSelect } from "~shared/selects/RegistrationFormSingleSelect";
import { TagMultiSelect } from "~shared/selects/TagMultiSelect";
import { IRegistrationFormSectionFormValues } from "~shared/types/section";

export const RegistrationFormSectionSettingsFields: FC = () => {
	const { t } = useTranslation();
	const [{ value: type }] = useField<IRegistrationFormSectionFormValues["type"]>("type");

	if (type === "companion")
		return (
			<>
				<StatusSelect name="companionSectionSettings.companionStatus" label={t("Companion status")} />
				<TextField name="companionSectionSettings.maxCompanionCount" label={t("Max. companion count")} type="number" />
				<EmailSingleSelect
					name="companionSectionSettings.companionConfirmationMail"
					label={t("Companion confirmation mail")}
				/>
				<RegistrationFormSingleSelect
					name="companionSectionSettings.companionRegistrationForm"
					label={t("Companion registration form")}
				/>
				<GroupMultiSelect name="companionSectionSettings.groups" label={t("Groups")} />
				<TagMultiSelect name="companionSectionSettings.tags" label={t("Tags")} />
			</>
		);

	return null;
};
