import { Active, Over, UniqueIdentifier } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import {
	DragOverStructureSteps,
	DragOverStructureStepsAndSections,
	Item,
	OptionalDragOverStructureSteps,
	OptionalDragOverStructureStepsAndSections,
	OverEvent,
	StepChildUpdate,
	StructureBlock,
	StructureDataField,
	StructureSection,
	StructureSectionChild,
	StructureStep,
	StructureStepChild,
} from "~shared/types/registrationFormTypes";

const STEP_PREFIX = "step_";
const STEP_CHILD_BLOCK_PREFIX = "stepChildBlock_";
const STEP_CHILD_SECTION_PREFIX = "section_";
const SECTION_CHILD_BLOCK_PREFIX = "sectionChildBlock_";
const SECTION_CHILD_DATA_FIELD_PREFIX = `dataField_`;

export function revertId(id: string) {
	const parts = id.split("_");
	return parts[parts.length - 1];
}

export function isStep(item: Item | null): item is StructureStep {
	return item !== null && item.id.toString().includes(STEP_PREFIX);
}

export function isStepChild(item: Item | null): item is StructureStepChild {
	return (
		item !== null &&
		(item.id.toString().includes(STEP_CHILD_SECTION_PREFIX) || item.id.toString().includes(STEP_CHILD_BLOCK_PREFIX))
	);
}

export function isSectionChild(item: Item | null): item is StructureSectionChild {
	return (
		item !== null &&
		(item.id.toString().includes(SECTION_CHILD_BLOCK_PREFIX) ||
			item.id.toString().includes(SECTION_CHILD_DATA_FIELD_PREFIX))
	);
}

export function findStepChildInSteps(steps: StructureStep[], active: Item) {
	return steps.flatMap((step) => step.children).find((section) => section.id === active.id) || null;
}

export function filterStepSections(stepChild: StructureStepChild): stepChild is StructureSection {
	return stepChild.entityType === "section";
}

export function findActiveElementsBySectionChildId(steps: StructureStep[], active: Item) {
	const step = findStepBySectionChildId(steps, active.id);

	if (!step) {
		throw new Error("step should be defined");
	}

	const section = findSectionBySectionChildId(step.children.filter(isSection), active.id);
	const sectionChild = section?.children.find((sectionChild) => sectionChild.id === active.id);

	if (!section || !sectionChild) {
		throw new Error("all active elements should be defined");
	}

	return {
		step,
		section,
		sectionChild,
	};
}

export function isBlock(item: { id: string; entityType: "block" | string }): item is StructureBlock {
	return item.entityType === "block";
}

export function isSection(item: { id: string; entityType: "section" | string }): item is StructureSection {
	return item.entityType === "section";
}

export function isDataField(item: { id: string; entityType: "dataField" | string }): item is StructureDataField {
	return item.entityType === "dataField";
}

export function activeIsStepChild(e: { active: Active; over: Over | null }): e is OverEvent {
	return isStepChild(e.active);
}

export function activeIsSectionChild(e: { active: Active; over: Over | null }): e is OverEvent {
	return isSectionChild(e.active) && (isSectionChild(e.over) || isStepChild(e.over));
}

export function isStepMove(e: { active: Active; over: Over | null }): e is OverEvent {
	return isStep(e.active) && isStep(e.over) && e.active.id !== e.over.id;
}

export function isStepChildInSameStepMove(
	steps: StructureStep[],
	e: { active: Active; over: Over | null },
): e is OverEvent {
	return (
		isStepChild(e.active) &&
		isStepChild(e.over) &&
		findStepBySectionId(steps, e.active.id)?.id === findStepBySectionId(steps, e.over.id)?.id
	);
}

export function isSectionChildInsideSameSectionMove(
	steps: StructureStep[],
	e: { active: Active; over: Over | null },
): e is OverEvent {
	return (
		isSectionChild(e.active) &&
		isSectionChild(e.over) &&
		findStepBySectionChildId(steps, e.active.id)?.id === findStepBySectionChildId(steps, e.over.id)?.id
	);
}

export function findStepById(steps: StructureStep[], id: UniqueIdentifier): StructureStep {
	const step = steps.find((step) => step.id === id);

	if (step === undefined) {
		throw new Error("step should never be undefined");
	}

	return step;
}

export function findStepBySectionId(steps: StructureStep[], sectionId: UniqueIdentifier) {
	return steps.find((step) => step.children.find((section) => section.id === sectionId));
}

export function findStepBySectionChildId(steps: StructureStep[], sectionChildId: UniqueIdentifier) {
	return steps.find((step) =>
		step.children
			.filter(filterStepSections)
			.find((section) => section.children.find((child) => child.id === sectionChildId)),
	);
}

export function findSectionBySectionChildId(sections: StructureSection[], sectionChildId: UniqueIdentifier) {
	return sections.find((section) => {
		if (section.entityType === "section") {
			return section.children.find((child) => child.id === sectionChildId);
		}
	});
}

export function isStepChildToAnotherStepMove(steps: OptionalDragOverStructureSteps): steps is DragOverStructureSteps {
	return steps.activeStep !== undefined && steps.overStep !== undefined && steps.activeStep.id !== steps.overStep.id;
}

export function isSectionChildToAnotherStepChildMove(
	parents: OptionalDragOverStructureStepsAndSections,
): parents is DragOverStructureStepsAndSections {
	return (
		parents.activeStep !== undefined &&
		parents.overStep !== undefined &&
		parents.activeSection !== undefined &&
		parents.overSection !== undefined &&
		parents.activeSection.id !== parents.overSection.id &&
		!isBlock(parents.overSection)
	);
}

export function determineParentSteps(
	e: { active: Active; over: Over | null },
	steps: StructureStep[],
): OptionalDragOverStructureSteps {
	const activeStep = findStepBySectionId(steps, e.active.id);
	const overStep = isStep(e.over) ? findStepById(steps, e.over.id) : findStepBySectionId(steps, e.over!.id);

	return {
		activeStep,
		overStep,
	};
}

export function determineParentStepChildrenAndSteps(
	e: OverEvent,
	steps: StructureStep[],
): OptionalDragOverStructureStepsAndSections {
	const activeStep = findStepBySectionChildId(steps, e.active.id);
	const overStep = isStepChild(e.over)
		? findStepBySectionId(steps, e.over.id)
		: findStepBySectionChildId(steps, e.over.id);

	if (activeStep === undefined) {
		throw new Error("activeStep should never be undefined");
	}

	const activeSection = findSectionBySectionChildId(activeStep.children.filter(isSection), e.active.id);
	const overSection = isStepChild(e.over)
		? overStep?.children.find((stepChild) => stepChild.id === e.over.id)
		: overStep?.children.find((section) => {
				if (section.entityType === "section") {
					return section.children.find((child) => child.id === e.over?.id);
				}
		  });

	return {
		activeStep,
		overStep,
		activeSection,
		overSection,
	};
}

export function handleStepChildToAnotherStepMove(
	activeStep: StructureStep,
	overStep: StructureStep,
	active: Item,
	over: Item,
) {
	const activeSection = activeStep.children.find((section) => section.id === active.id);
	const overSectionIndex = isStepChild(over)
		? overStep.children.findIndex((section) => section.id === over.id)
		: undefined;

	if (!activeSection) {
		throw new Error("should not happen");
	}

	return (steps: StructureStep[]) => {
		return steps.map((step) => {
			if (step.id === activeStep.id) {
				return {
					...step,
					children: step.children.filter((section) => section.id !== activeSection.id),
				};
			}
			if (step.id === overStep.id) {
				const tempSections = step.children.concat(activeSection);
				return {
					...step,
					children:
						overSectionIndex === undefined
							? tempSections
							: arrayMove(tempSections, tempSections.length - 1, overSectionIndex),
				};
			}

			return step;
		});
	};
}

function handleInsertIntoSectionContainer(
	section: StructureSection,
	activeSectionChild: StructureSectionChild,
	overSectionChildIndex: number | undefined,
): StructureStepChild {
	const tempSectionChildren = section.children.concat(activeSectionChild);
	return {
		...section,
		children:
			overSectionChildIndex === undefined
				? tempSectionChildren
				: arrayMove(tempSectionChildren, tempSectionChildren.length - 1, overSectionChildIndex),
	};
}

export function handleSectionChildToAnotherStepChildMove(
	{ overSection, overStep, activeSection, activeStep }: DragOverStructureStepsAndSections,
	{ active, over }: OverEvent,
) {
	const activeSectionChild = activeSection.children.find((child) => child.id === active.id);
	const overSectionChildIndex = isSectionChild(over)
		? overSection.children.findIndex((child) => child.id === over.id)
		: undefined;

	if (!activeSectionChild) {
		throw new Error("should not happen");
	}

	return (steps: StructureStep[]) => {
		return steps.map((step) => {
			if (step.id === activeStep.id) {
				return {
					...step,
					children: step.children.map((stepChild) => {
						if (isBlock(stepChild)) {
							return stepChild;
						}

						if (stepChild.id === activeSection.id) {
							return {
								...stepChild,
								children: stepChild.children.filter((child) => child.id !== activeSectionChild.id),
							};
						}

						if (stepChild.id === overSection.id) {
							return handleInsertIntoSectionContainer(stepChild, activeSectionChild, overSectionChildIndex);
						}

						return stepChild;
					}),
				};
			}
			if (step.id === overStep.id) {
				return {
					...step,
					children: step.children.map((stepChild) => {
						if (isBlock(stepChild)) {
							return stepChild;
						}

						if (stepChild.id === overSection.id) {
							return handleInsertIntoSectionContainer(stepChild, activeSectionChild, overSectionChildIndex);
						}

						return stepChild;
					}),
				};
			}

			return step;
		});
	};
}

export function handleStepMove(stepContainers: StructureStep[], event: OverEvent) {
	const oldIndex = stepContainers.findIndex((s) => s.id === event.active.id);
	const newIndex = stepContainers.findIndex((s) => s.id === event.over.id);

	return arrayMove(stepContainers, oldIndex, newIndex);
}

export function handleStepChildInSameStepMove(step: StructureStep[], event: OverEvent): StructureStep[] {
	return step.map((step) => {
		if (step.children.find((stepChild) => stepChild.id === event.active.id)) {
			const oldIndex = step.children.findIndex((s) => s.id === event.active.id);
			const newIndex = step.children.findIndex((s) => s.id === event.over.id);
			return {
				...step,
				children: arrayMove(step.children, oldIndex, newIndex),
			};
		}

		return step;
	});
}

export function createStepChildUpdate(
	currentSteps: StructureStep[],
	newSteps: StructureStep[],
	event: OverEvent,
): StepChildUpdate {
	const fromStep = findStepBySectionId(currentSteps, event.active.id);
	const toStep = findStepBySectionId(newSteps, event.active.id);
	if (fromStep === undefined || toStep === undefined) {
		throw new Error("should not happen");
	}

	return {
		from: { stepId: revertId(fromStep.id) },
		to: { stepId: revertId(toStep.id) },
		children: toStep.children,
	};
}

export function handleSectionChildInsideSameSectionMove(steps: StructureStep[], event: OverEvent) {
	return steps.map((step) => {
		const stepChildren = step.children.map((stepChild) => {
			if (isSection(stepChild) && stepChild.children.find((child) => child.id === event.active.id)) {
				const oldIndex = stepChild.children.findIndex((s) => s.id === event.active.id);
				const newIndex = stepChild.children.findIndex((s) => s.id === event.over.id);

				return {
					...stepChild,
					children: arrayMove(stepChild.children, oldIndex, newIndex),
				};
			}

			return stepChild;
		});

		return {
			...step,
			children: stepChildren,
		};
	});
}

export function mapStructureStepIds(step: StructureStep): StructureStep {
	return {
		...step,
		id: `${STEP_PREFIX}${step.id}`,
		children: step.children.map((stepChild) => {
			if (stepChild.entityType === "section") {
				return {
					...stepChild,
					id: `${STEP_CHILD_SECTION_PREFIX}${stepChild.id}`,
					children: stepChild.children.map((sectionChild) => {
						return {
							...sectionChild,
							id:
								sectionChild.entityType === "dataField"
									? `${sectionChild.dataFieldId}:${SECTION_CHILD_DATA_FIELD_PREFIX}${sectionChild.id}`
									: `${SECTION_CHILD_BLOCK_PREFIX}${sectionChild.id}`,
						};
					}),
				};
			} else {
				return {
					...stepChild,
					id: `${STEP_CHILD_BLOCK_PREFIX}${stepChild.id}`,
				};
			}
		}),
	};
}

export function getDataFieldIdFromId(id: string) {
	const parts = id.split(":");
	return parts[0];
}
