import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { systemMessageService } from "~admin/shared/api/systemMessages";
import { guestFilterKeys } from "~shared/api/guestFilters";
import { guestKeys } from "~shared/api/guests";
import { productDataKeys } from "~shared/api/productData";
import { registrationFormKeys, registrationFormService } from "~shared/api/registrationForms";
import {
	IRegistrationFormForm,
	IRegistrationFormSettingsForm,
	RegistrationVisibility,
} from "~shared/types/registrationForm";

export const useRegistrationFormForm = () => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const duplicateRegistrationForm = useCallback(
		async (id: Id, values: IRegistrationFormForm) => {
			const response = await registrationFormService.duplicate(id, values);
			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form was duplicated."),
					title: t("Save registration form"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: registrationFormKeys.list });
				return response.data.data.id;
			}
			return false;
		},
		[queryClient, showToast, t],
	);

	const createRegistrationForm = useCallback(
		async (registrationForm: IRegistrationFormForm) => {
			const response = await registrationFormService.post(registrationForm);

			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form was saved."),
					title: t("Save registration form"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: registrationFormKeys.list });
				return response.data.data.id;
			}

			return false;
		},
		[showToast, t, queryClient],
	);

	const updateRegistrationForm = useCallback(
		async (id: Id, registrationForm: IRegistrationFormForm) => {
			const response = await registrationFormService.put(id, registrationForm);
			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form was saved."),
					title: t("Save registration form"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: registrationFormKeys.list });
				await queryClient.resetQueries({ queryKey: productDataKeys.index });
				return id;
			}
			return false;
		},
		[queryClient, showToast, t],
	);

	const updateRegistrationFormSettings = useCallback(
		async (id: Id, registrationFormSettingsForm: IRegistrationFormSettingsForm) => {
			const response = await registrationFormService.putSettings(id, {
				closeAt: registrationFormSettingsForm.closeAt,
				isCancelable: registrationFormSettingsForm.isCancelable,
				isEditable: registrationFormSettingsForm.isEditable,
				isShopEnabled: registrationFormSettingsForm.isShopEnabled,
				mustApproveShopOrders: registrationFormSettingsForm.mustApproveShopOrders,
				isReplacementEnabled: registrationFormSettingsForm.isReplacementEnabled,
				visibility: registrationFormSettingsForm.visibility,
				registeredMailId: registrationFormSettingsForm.registeredMail?.id ?? null,
				editedMailId: registrationFormSettingsForm.editedMail?.id ?? null,
				declinedMailId: registrationFormSettingsForm.declinedMail?.id ?? null,
				cancelledMailId: registrationFormSettingsForm.cancelledMail?.id ?? null,
				refundConfirmationMailId: registrationFormSettingsForm.refundConfirmationMail?.id ?? null,
				invoiceMailId: registrationFormSettingsForm.invoiceMail?.id ?? null,
				paymentMailId: registrationFormSettingsForm.paymentMail?.id ?? null,
				remainingPaymentMailId: registrationFormSettingsForm.remainingPaymentMail?.id ?? null,
				replacementNominatedMailId: registrationFormSettingsForm.replacementNominatedMail?.id ?? null,
				replacementInvitationMailId: registrationFormSettingsForm.replacementInvitationMail?.id ?? null,
				closedBlockId: registrationFormSettingsForm.closedBlock?.id ?? null,
				successBlockId: registrationFormSettingsForm.successBlock?.id ?? null,
				editedBlockId: registrationFormSettingsForm.editedBlock?.id ?? null,
				declinedBlockId: registrationFormSettingsForm.declinedBlock?.id ?? null,
				cancelledBlockId: registrationFormSettingsForm.cancelledBlock?.id ?? null,
				confirmDeclinationBlockId: registrationFormSettingsForm.confirmDeclinationBlock?.id ?? null,
				confirmCancellationBlockId: registrationFormSettingsForm.confirmCancellationBlock?.id ?? null,
				privateBlockId: registrationFormSettingsForm.privateBlock?.id ?? null,
				confirmReplacementBlockId: registrationFormSettingsForm.confirmReplacementBlock?.id ?? null,
				replacedBlockId: registrationFormSettingsForm.replacedBlock?.id ?? null,
				isCodePageEnabled:
					registrationFormSettingsForm.isCodePageEnabled &&
					registrationFormSettingsForm.visibility === RegistrationVisibility.PRIVATE,
				codePageBlockId:
					registrationFormSettingsForm.isCodePageEnabled &&
					registrationFormSettingsForm.visibility === RegistrationVisibility.PRIVATE
						? registrationFormSettingsForm.codePageBlock?.id ?? null
						: null,
				contingent: registrationFormSettingsForm.contingent,
				screeningStatusPositiveBlockId: registrationFormSettingsForm.screeningStatusPositiveBlock?.id ?? null,
				screeningStatusPositiveEmailId: registrationFormSettingsForm.screeningStatusPositiveEmail?.id ?? null,
				paymentSuccessfulBlockId: registrationFormSettingsForm.paymentSuccessfulBlock?.id ?? null,
			});
			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form was saved."),
					title: t("Save registration form"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: registrationFormKeys.detail(id) });
				await queryClient.resetQueries({ queryKey: systemMessageService.keys.all });
				return id;
			}
			return false;
		},
		[queryClient, showToast, t],
	);

	const deleteRegistrationForm = useCallback(
		async (formId: string) => {
			const response = await registrationFormService.remove(formId);
			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form was successfully deleted."),
					title: t("Delete registration form"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: registrationFormKeys.list });
				await queryClient.invalidateQueries({ queryKey: guestKeys.filtered });
				await queryClient.resetQueries({ queryKey: productDataKeys.index });
				await queryClient.resetQueries({ queryKey: guestFilterKeys.index });
				return formId;
			}

			return false;
		},
		[showToast, t, queryClient],
	);

	return {
		createRegistrationForm,
		updateRegistrationForm,
		updateRegistrationFormSettings,
		deleteRegistrationForm,
		duplicateRegistrationForm,
	};
};
