import { BlockViewProps, LinkButton } from "@dgs/core";
import React, { FC } from "react";
import styled from "styled-components";
import { IEmailButtonBlockShowResource } from "./emailButtonBlockTypes";

const StyledLinkButton = styled(LinkButton)<{ $color: string; $backgroundColor: string }>`
	color: ${({ $color }) => $color};
	background-color: ${({ $backgroundColor }) => $backgroundColor};
`;

export const EmailButtonBlockView: FC<BlockViewProps<IEmailButtonBlockShowResource>> = ({ block }) => {
	return (
		<StyledLinkButton
			$color={block.value.color}
			$backgroundColor={block.value.backgroundColor}
			to={block.translatedValue.url}
		>
			{block.translatedValue.label}
		</StyledLinkButton>
	);
};
