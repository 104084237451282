import { Button, Dropdown, DropdownItem, Icon, cls } from "@dgs/core";
import React, { FC, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { GuestViewConfigResource } from "~shared/types/guestView";

const DropdownIcon = styled(Icon).attrs({ icon: "caret" })`
	width: 16px;
	height: 16px;
	transform: rotate(90deg);
`;

const View = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
	border-right: 1px solid ${({ theme }) => theme.colors.palette.grey[30]};
	padding-right: 8px;
`;

const StyledButton = styled(Button)`
	display: grid;
	grid-template-columns: max-content max-content max-content;
	margin-left: -8px;
	margin-right: -8px;
`;

const GuestViewIcon = styled(Icon)`
	width: 20px;
	height: 20px;
`;

interface Props {
	currentGuestView: GuestViewConfigResource | null;
	guestViews: GuestViewConfigResource[];
	setGuestView: (guestView: GuestViewConfigResource) => void;
}

export const GuestViewSwitcher: FC<Props> = ({ currentGuestView, guestViews, setGuestView }) => {
	const { t } = useTranslation();
	const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
	const title = t("Guest view");
	const handleToggle = (e: MouseEvent<HTMLButtonElement>) => {
		setAnchor(e.currentTarget);
	};

	return (
		<>
			<StyledButton
				title={title}
				onClick={(e) => handleToggle(e)}
				prefix={<GuestViewIcon title={title} icon="layers" />}
			>
				<View>{currentGuestView ? currentGuestView.name : t("Select a guest view")}</View>
				<DropdownIcon title={title} />
			</StyledButton>
			{anchor && (
				<Dropdown anchor={anchor} onClose={() => setAnchor(null)}>
					{guestViews.map((guestView, index) => (
						<DropdownItem
							className={cls(guestViews.findIndex((view) => view.id === currentGuestView?.id) === index && "selected")}
							onClick={() => {
								setGuestView(guestView);
								setAnchor(null);
							}}
							key={index}
						>
							{guestView.name}
						</DropdownItem>
					))}
				</Dropdown>
			)}
		</>
	);
};
