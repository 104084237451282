import { Card, CardBody } from "@dgs/core";
import React, { FC } from "react";
import { ActiveCard } from "../common/ActiveStructureCard";
import { DragIndicator } from "../common/DragIndicator";
import { StepChildHeader, StepChildHeaderProps } from "./StepChildHeader";

interface Props extends StepChildHeaderProps {
	isActive?: boolean;
	children?: React.ReactNode;
	withIndicator?: boolean;
	hideDataFieldOptions?: boolean;
}

export const StepChild: FC<Props> = ({
	registrationFormId,
	stepId,
	stepChild,
	isActive = false,
	withIndicator = false,
	children,
	hideDataFieldOptions,
}) => {
	const StepChildCard = isActive ? ActiveCard : Card;

	return (
		<StepChildCard $color="content">
			<StepChildHeader
				hideDataFieldOptions={hideDataFieldOptions}
				registrationFormId={registrationFormId}
				stepId={stepId}
				stepChild={stepChild}
				indicator={withIndicator && <DragIndicator />}
			/>
			{children && <CardBody>{children}</CardBody>}
		</StepChildCard>
	);
};
