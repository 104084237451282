import { Id, PagedMultiSelectField } from "@dgs/core";
import React, { FC } from "react";
import { tenantService } from "~shared/api/tenants/tenantService";

const fetcher = (hiddenIds: Id[]) => (search: string, page: number) =>
	tenantService.options(search, page).then((x) => ({
		...x.data,
		data: x.data.data.filter((tenant) => !hiddenIds.includes(tenant.id)),
	}));

interface Props {
	name: string;
	label: string;
	required?: boolean;
	hiddenIds?: Id[];
}
export const TenantMultiSelectField: FC<Props> = (props) => {
	return (
		<PagedMultiSelectField {...props} fetcher={fetcher(props.hiddenIds ?? [])} queryKey={tenantService.keys.options} />
	);
};
