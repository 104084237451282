import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { GuestFilterDrawer, GuestFilterProvider } from "~shared/guestFilters";
import { LogList } from "./LogList";

export const LogPage: FC = () => {
	return (
		<Routes>
			<Route index element={<Navigate to="logs" replace={true} />} />
			<Route
				path="logs"
				element={
					<GuestFilterProvider listFilterKey="locationLogsListGuestFilter">
						<LogList />
						<GuestFilterDrawer />
					</GuestFilterProvider>
				}
			/>
		</Routes>
	);
};
