import { Button, Loading, Modal, ModalBody, ModalFooter } from "@dgs/core";
import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ModelUsageResource } from "~shared/types/modelUsageResource";
import { UsagesBody } from "~shared/ui/modelUsage/UsagesBody";

interface Props {
	usagePrefix: string;
	open: boolean;
	handleClose: () => void;

	usages: ModelUsageResource[];

	loading: boolean;
	confirmationBody: ReactNode;
	deleteAction: () => Promise<void>;
}

export const TaxDeleteModelWithUsagesDialog: FC<Props> = ({
	deleteAction,
	usagePrefix,
	confirmationBody,
	handleClose,
	usages,
	loading,
	open,
}) => {
	const { t } = useTranslation();
	const canBeDeleted = usages.filter((usage) => usage.name === "shopItemsAsDefault").length === 0;

	return open ? (
		<Modal heading={t("Delete")} onClose={handleClose} className={"delete"}>
			<>
				<ModalBody>
					{loading ? (
						<Loading />
					) : canBeDeleted ? (
						usages.length > 0 ? (
							<UsagesBody usages={usages} usagePrefix={usagePrefix} confirmationBody={confirmationBody} />
						) : (
							confirmationBody
						)
					) : (
						<>
							{t("This Tax cannot be deleted due to the following Relation(s):")}
							<UsagesBody
								usages={usages.filter((usage) => usage.name === "shopItemsAsDefault")}
								usagePrefix={usagePrefix}
							/>
						</>
					)}
				</ModalBody>
				<ModalFooter>
					<Button
						type="button"
						onClick={(e) => {
							handleClose();
							e.stopPropagation();
						}}
					>
						{t("Close")}
					</Button>
					{canBeDeleted && !loading && (
						<Button
							type="button"
							color="danger"
							onClick={(e) => {
								deleteAction();
								handleClose();
								e.stopPropagation();
							}}
						>
							{t("Confirm")}
						</Button>
					)}
				</ModalFooter>
			</>
		</Modal>
	) : null;
};
