import React, { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ModelUsageResource } from "~shared/types/modelUsageResource";

interface UsageBodyProps {
	usagePrefix: string;
	usages: ModelUsageResource[];
	confirmationBody?: ReactNode;
}

const StyledList = styled.ul`
	padding-left: 1rem;
	margin: ${({ theme }) => theme.spacing(4)} 0;
`;

export const UsagesBody: FC<UsageBodyProps> = ({ usages, usagePrefix, confirmationBody }) => {
	const { t } = useTranslation();

	return (
		<>
			<StyledList>
				{usages.map(({ name, count }) => (
					<li key={name}>{t("usage_overview", { usagePrefix, count, name })}</li>
				))}
			</StyledList>
			{confirmationBody}
		</>
	);
};
