import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { RoomCreatePage } from "./RoomTypeCreatePage";
import { RoomTypeEditPage } from "./RoomTypeEditPage";
import { RoomTypeList } from "./RoomTypeList";

export const RoomTypePage: FC = () => {
	return (
		<Routes>
			<Route index element={<RoomTypeList />} />
			<Route path="new" element={<RoomCreatePage />} />
			<Route path=":roomTypeId" element={<RoomTypeEditPage />} />
		</Routes>
	);
};
