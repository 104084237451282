import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { IShopSettings, SaveShopSettings } from "~shared/types/shop";

export const shopSettingsKeys = {
	settings: ["shop-settings"],
};

export const shopSettingsUrls = {
	settings: keyToUrl(shopSettingsKeys.settings),
};

const getSettings = () => axios.get<LaravelResponse<IShopSettings>>(shopSettingsUrls.settings);

const putSettings = (settings: SaveShopSettings) =>
	axios.put<LaravelResponse<IShopSettings>>(shopSettingsUrls.settings, settings);

export const shopSettingsService = {
	getSettings,
	putSettings,
};
