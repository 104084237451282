import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { WaitingListList } from "~admin/waitingList/WaitingListList";
import { GuestFilterDrawer, GuestFilterProvider } from "~shared/guestFilters";
import { WaitingListGuestList } from "./WaitingListGuestList";

export const WaitingListPage: FC = () => {
	return (
		<Routes>
			<Route index element={<WaitingListList />} />
			<Route
				path=":id"
				element={
					<GuestFilterProvider listFilterKey="waitingListGuestFilter">
						<WaitingListGuestList />
						<GuestFilterDrawer />
					</GuestFilterProvider>
				}
			/>
		</Routes>
	);
};
