import { SingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { ConsentAudience } from "~shared/types/consent";

interface Props {
	label: string;
	name: string;
}

const consentAudienceOptions: ConsentAudience[] = ["all", "internal", "external"];

export const ConsentAudienceSelectField: FC<Props> = ({ label, name }) => {
	return (
		<SingleSelectField
			label={label}
			name={name}
			options={consentAudienceOptions}
			getLabel={String}
			getValue={String}
			clearable={false}
		/>
	);
};
