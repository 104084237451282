import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { IProductDataRequest, IProductDataResource, IProductDataShowResource } from "~shared/types/productData";
import { productDataKeys } from "./productDataKeys";

export const productDataUrl = {
	index: keyToUrl(productDataKeys.index),
	edit: keyToUrl(productDataKeys.edit),
};

const index = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<IProductDataShowResource>>(productDataUrl.index, config);
const edit = (config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<IProductDataResource>>(productDataUrl.edit, config);

const put = (values: IProductDataRequest) =>
	axios.put<LaravelResponse<IProductDataResource>>(productDataUrl.index, values);

export const productDataService = {
	index,
	edit,
	put,
};
