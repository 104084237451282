import { FCC } from "@dgs/core";
import React, { createContext, useCallback, useContext, useState } from "react";
import { useGlobalGuestView } from "~shared/providers/GlobalGuestViewProvider";
import { GuestViewConfigResource } from "~shared/types/guestView";

interface LocalGuestProviderState {
	localGuestView: GuestViewConfigResource | null;
	setLocalGuestView: (guestView: GuestViewConfigResource | null) => void;
}

const LocalGuestViewContext = createContext<LocalGuestProviderState | undefined>(undefined);

const getCurrentGuestView = (guestViews: GuestViewConfigResource[], key: string) => {
	const id = localStorage.getItem(key);

	if (!id) {
		return null;
	}

	const parsedId = Number(id);
	return guestViews.find((guestView) => guestView.id === parsedId) || guestViews[0];
};

interface Props {
	guestViewKey: string;
}

export const LocalGuestViewProvider: FCC<Props> = ({ children, guestViewKey }) => {
	const { guestViews } = useGlobalGuestView();
	const [localGuestView, _setGuestView] = useState<GuestViewConfigResource | null>(
		getCurrentGuestView(guestViews, guestViewKey),
	);

	const setLocalGuestView = useCallback(
		(guestView: GuestViewConfigResource | null) => {
			guestView ? localStorage.setItem(guestViewKey, `${guestView.id}`) : localStorage.removeItem(guestViewKey);
			_setGuestView(guestView);
		},
		[guestViewKey],
	);

	return (
		<LocalGuestViewContext.Provider value={{ localGuestView, setLocalGuestView }}>
			{children}
		</LocalGuestViewContext.Provider>
	);
};

const setLocalGuestView = () => {
	throw new Error("useLocalGuestView should be wrapped in a LocalGuestViewProvider");
};

export const useLocalGuestView = () => {
	const ctx = useContext(LocalGuestViewContext);

	return ctx ? ctx : { setLocalGuestView, localGuestView: null };
};
