import { ContextMenu, ContextMenuDeleteItem, ContextMenuLinkItem, Id } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IRoomContingentIndex } from "~shared/types/roomContingent";
import { useRoomContingentForm } from "./useRoomContingentForm";

interface Props {
	hotelId: Id;
	roomTypeId: Id;
	roomContingent: IRoomContingentIndex;
}

export const RoomContingentContextMenu: FC<Props> = ({ hotelId, roomTypeId, roomContingent: { id } }) => {
	const { t } = useTranslation();
	const { deleteRoomContingent } = useRoomContingentForm();

	return (
		<ContextMenu displayShortcutsAs="icon">
			<ContextMenuLinkItem
				label={t("Edit")}
				title={t("Edit")}
				to={`../contingent/${id}`}
				icon="edit"
				shortcut="primary"
			/>
			<ContextMenuDeleteItem
				label={t("Delete")}
				action={() => deleteRoomContingent(hotelId, roomTypeId, id)}
				title={t("Delete")}
				heading={t("Delete")}
				labels={{ close: t("Close"), confirm: t("Confirm") }}
			>
				{t("Are you sure you want to delete this room contingent?")}
			</ContextMenuDeleteItem>
		</ContextMenu>
	);
};
