import { Drawer, DrawerBody, DrawerHeader, DrawerHeading } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { CreateFile } from "~shared/types/file";
import { FileForm } from "./FileForm";
import { useFiles } from "./useFiles";

interface Props {
	open: boolean;
	handleClose: () => void;
	title: string;
}

export const FileDrawer: FC<Props> = ({ title, open, handleClose }) => {
	const { createFile } = useFiles();

	const handleSubmit = useCallback(
		async (values: CreateFile) => {
			if (values.file) {
				await createFile(values);
			}
			handleClose();
		},
		[handleClose, createFile],
	);
	return (
		<Drawer open={open} onClose={handleClose}>
			<DrawerHeader onClose={handleClose}>
				<DrawerHeading>{title}</DrawerHeading>
			</DrawerHeader>
			<DrawerBody>
				<FileForm onSubmit={handleSubmit} />
			</DrawerBody>
		</Drawer>
	);
};
