import { DrawerAutoDataList, IDrawerDataListColumn, SlateViewer, useCrudEntity } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ConsentDrawer } from "~admin/consents/ConsentDrawer";
import { ConsentsListContextMenu } from "~admin/consents/ConsentsListContextMenu";
import { useConsent } from "~admin/consents/useConsent";
import { consentService } from "~shared/api/consents";
import { guestFilterKeys } from "~shared/api/guestFilters";
import { ConsentFormValues, ConsentListItem } from "~shared/types/consent";

export const ConsentsList: FC = () => {
	const { t } = useTranslation();
	const { emptyConsent, parseTranslations } = useConsent();
	const queryClient = useQueryClient();

	const crudOperations = useCrudEntity(consentService, {
		entityName: t("Consent"),
		messages: {
			create: t("Consent was saved."),
			update: t("Consent was saved."),
			delete: t("Consent was successfully deleted."),
		},
	});
	const dataListColumns: IDrawerDataListColumn<ConsentListItem>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Consent"),
				valueKey: "consent",
				type: "value",
				render: (consent) => <SlateViewer content={consent.consent} />,
			},
			{
				heading: t("Type"),
				valueKey: "type",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				action: (consent: ConsentListItem, openDrawer) => (
					<ConsentsListContextMenu
						handleEdit={openDrawer}
						handleDelete={async () => {
							const isOk = await crudOperations.deleteEntity(consent.id);
							if (isOk) {
								await queryClient.resetQueries({ queryKey: guestFilterKeys.index });
							}
						}}
					/>
				),
			},
		],
		[t, crudOperations, queryClient],
	);

	return (
		<DrawerAutoDataList
			translations={{
				title: t("Consents"),
				empty: t("No consents available"),
			}}
			renderCreateDrawer={(drawerProps) => (
				<ConsentDrawer
					{...drawerProps}
					heading={t("Create consent")}
					initialValues={emptyConsent}
					onSubmit={async (entityFormState: ConsentFormValues) =>
						crudOperations.createEntity(parseTranslations(entityFormState))
					}
				/>
			)}
			renderUpdateDrawer={(entityResource, drawerProps) => (
				<ConsentDrawer
					{...drawerProps}
					heading={t("Edit consent")}
					initialValues={entityResource}
					onSubmit={async (entityFormState: ConsentFormValues) =>
						crudOperations.updateEntity(entityResource.id, parseTranslations(entityFormState))
					}
					handleDelete={() => crudOperations.deleteEntity(entityResource.id).then(drawerProps.onClose)}
				/>
			)}
			columns={dataListColumns}
			service={consentService}
		/>
	);
};
