import { ListProvider } from "@dgs/core";
import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { guestKeys, guestService } from "~shared/api/guests";
import { ChangelogProvider } from "~shared/providers/ChangelogProvider";
import { Changelog } from "~shared/ui/changelog";

export const GuestChangelog: FC = () => {
	const { guestId = "" } = useParams<"guestId">();

	return (
		<ListProvider queryKey={guestKeys.activities(guestId)} fetcher={guestService.activities(guestId)}>
			<ChangelogProvider mapChange={(c) => c}>
				<Changelog />
			</ChangelogProvider>
		</ListProvider>
	);
};
