import { Loading, SingleSelectField } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import React, { FC } from "react";
import { distributionListKeys, distributionListService } from "~shared/api/distributionList";
import { DistributionList } from "~shared/types/distributionList";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
}

const getValue = (list: DistributionList) => list.id;
const getLabel = (list: DistributionList) => list.name;

export const DistributionListSingleSelectField: FC<Props> = ({ name, label, disabled, required }) => {
	const { data: distributionLists, isLoading } = useQuery({
		queryKey: distributionListKeys.index,
		queryFn: () => distributionListService.index().then((r) => r.data),
	});

	if (isLoading || !distributionLists) {
		return <Loading />;
	}

	return (
		<SingleSelectField
			label={label}
			name={name}
			disabled={disabled}
			getLabel={getLabel}
			getValue={getValue}
			required={required}
			clearable={false}
			options={distributionLists.data ?? []}
		/>
	);
};
