import { ContextMenu, ContextMenuDeleteItem, useList } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { emailService } from "~shared/api/emails/emailService";
import { SendOut } from "~shared/types/email";

interface Props {
	sendOut: SendOut;
}

export const EmailSendOutContextMenu: FC<Props> = ({ sendOut }) => {
	const { t } = useTranslation();
	const { reloadList } = useList();

	const handleDelete = useCallback(async () => {
		await emailService.sendOuts.remove(sendOut);
		await reloadList();
	}, [reloadList, sendOut]);

	return (
		<ContextMenu displayShortcutsAs="icon" size="small">
			<ContextMenuDeleteItem
				heading={t("Delete")}
				action={() => handleDelete()}
				title={t("Delete")}
				label={t("Delete")}
				labels={{ close: t("Close"), confirm: t("Confirm") }}
			>
				{t("Are you sure you want to delete this send out?")}
			</ContextMenuDeleteItem>
		</ContextMenu>
	);
};
