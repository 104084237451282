import { Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import {
	IRegistrationFormStep,
	RegistrationFormStepCreateRequest,
	RegistrationFormStepUpdateRequest,
} from "~shared/types/step";

export class RegistrationFormStepService {
	public keys = {
		index: (registrationFormId: Id) => ["registration-forms", `${registrationFormId}`, "steps"],
		details: (registrationFormId: Id, stepId: Id) => [
			"registration-forms",
			`${registrationFormId}`,
			"steps",
			`${stepId}`,
		],
		landingStep: (registrationFormId: Id) => ["registration-forms", `${registrationFormId}`, "steps", "landing"],
		summaryStep: (registrationFormId: Id) => ["registration-forms", `${registrationFormId}`, "steps", "summary"],
	};

	protected urls = {
		index: (registrationFormId: Id) => keyToUrl(this.keys.index(registrationFormId)),
		details: (registrationFormId: Id, stepId: Id) => keyToUrl(this.keys.details(registrationFormId, stepId)),
		landingStep: (registrationFormId: Id) => keyToUrl(this.keys.landingStep(registrationFormId)),
		summaryStep: (registrationFormId: Id) => keyToUrl(this.keys.summaryStep(registrationFormId)),
	};

	store(registrationFormId: Id, formValues: RegistrationFormStepCreateRequest) {
		return axios.post<LaravelResponse<{ id: Id }>>(this.urls.index(registrationFormId), formValues);
	}

	show(registrationFormId: Id, stepId: Id) {
		return axios.get<LaravelResponse<IRegistrationFormStep>>(this.urls.details(registrationFormId, stepId));
	}

	update(registrationFormId: Id, stepId: Id, formValues: RegistrationFormStepUpdateRequest) {
		return axios.put<void>(this.urls.details(registrationFormId, stepId), formValues);
	}

	delete(registrationFormId: Id, stepId: Id) {
		return axios.delete<void>(this.urls.details(registrationFormId, stepId));
	}

	putLandingStep(registrationFormId: Id, blockId: Id | null) {
		return axios.put<LaravelResponse<void>>(this.urls.landingStep(registrationFormId), {
			blockId,
		});
	}

	putSummaryStep(registrationFormId: Id, blockId: Id | null) {
		return axios.put<LaravelResponse<void>>(this.urls.summaryStep(registrationFormId), {
			blockId,
		});
	}
}

export const registrationFormStepService = new RegistrationFormStepService();
