import React, { FC } from "react";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { EmailFormValues } from "~shared/types/email";
import { EmailForm } from "../EmailForm";
import { useEmailActions } from "../useEmailActions";
import { CreateEmailStepper } from "./CreateEmailStepper";

export const CreateEmailDataForm: FC = () => {
	const { createEmail } = useEmailActions();
	const { locales } = useContentLocale();
	const initialEmail: EmailFormValues = {
		name: "",
		senderName: "",
		bccEmailAddresses: [],
		changeStatusTo: null,
		personalizedDocuments: [],
		guestFileCategories: [],
		tags: [],
		translations: locales.reduce((translations, locale) => {
			return {
				...translations,
				[locale]: { subject: "", replyTo: "", files: [] },
			};
		}, {}),
	};

	return (
		<>
			<CreateEmailStepper currentStep="Email data" />
			<EmailForm initialValues={initialEmail} onSubmit={createEmail} />
		</>
	);
};
