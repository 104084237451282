import { ContextMenu, ContextMenuDeleteItem, ContextMenuItem } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
	handleEdit: () => void;
	handleDelete: () => Promise<any>;
}

export const InvoiceProviderListContextMenu: FC<Props> = ({ handleEdit, handleDelete }) => {
	const { t } = useTranslation();

	return (
		<>
			<ContextMenu displayShortcutsAs="icon">
				<ContextMenuItem label={t("Edit")} title={t("Edit")} action={handleEdit} icon="edit" shortcut="primary" />
				<ContextMenuDeleteItem
					label={t("Delete")}
					action={handleDelete}
					title={t("Delete")}
					heading={t("Delete")}
					labels={{ close: t("Close"), confirm: t("Confirm") }}
				>
					{t("Are you sure you want to delete this invoice provider?")}
				</ContextMenuDeleteItem>
			</ContextMenu>
		</>
	);
};
