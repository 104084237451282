import { LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import {
	PersonalizedDocumentSettingsRequest,
	PersonalizedDocumentSettingsResource,
} from "~admin/shared/types/personalizedDocumentSettings";

export const personalizedDocumentSettingsKeys = {
	details: ["personalized-document-settings"],
	update: ["personalized-document-settings"],
};

export const personalizedDocumentSettingsUrls = {
	details: keyToUrl(personalizedDocumentSettingsKeys.details),
	update: keyToUrl(personalizedDocumentSettingsKeys.update),
};

const get = () =>
	axios.get<LaravelResponse<PersonalizedDocumentSettingsResource>>(personalizedDocumentSettingsUrls.details);
const put = (personalizedDocumentSettings: PersonalizedDocumentSettingsRequest) =>
	axios.put<LaravelResponse<PersonalizedDocumentSettingsResource>>(
		personalizedDocumentSettingsUrls.update,
		personalizedDocumentSettings,
	);

export const personalizedDocumentSettingsService = {
	get,
	put,
};
