import { AutoDataList, FormattedDate, IDataListColumn, Loading } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
	contingentOverviewKeys,
	contingentOverviewService,
	useContingentOverviewDaysQuery,
} from "~admin/shared/api/contingentOverview";
import { IContingentOverviewIndex } from "~admin/shared/types/contingentOverview";
import { ContingentTag } from "./ContingentTag";

const Container = styled.div`
	display: grid;
	grid-template-columns: max-content max-content;
	gap: ${({ theme }) => theme.spacing(1)};
	justify-content: center;
`;

const CenterWrapper = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

const HotelName = styled.span`
	${({ theme }) => theme.fonts.weights.bold}
`;

export const ContingentOverviewPage: FC = () => {
	const { t } = useTranslation();
	const { data: dates, isLoading } = useContingentOverviewDaysQuery();

	const columns: IDataListColumn<IContingentOverviewIndex>[] = useMemo(
		() => [
			{
				heading: t("Hotel - Room type"),
				valueKey: "name",
				type: "value",
				render: (overview: IContingentOverviewIndex) => (
					<Container>
						<HotelName>{overview.hotel.name}</HotelName>
						<span>- {overview.name}</span>
					</Container>
				),
			},
			{
				heading: t("Total"),
				valueKey: "total" as any,
				type: "value",
				size: "max-content",
				render: (overview: IContingentOverviewIndex) => (
					<CenterWrapper>{`${Object.keys(overview.contingents).reduce(
						(prev, curr) => prev + overview.contingents[curr].used,
						0,
					)}/${Object.keys(overview.contingents).reduce(
						(prev, curr) => prev + overview.contingents[curr].total,
						0,
					)}`}</CenterWrapper>
				),
			},
			...(dates.map((date) => ({
				heading: (
					<FormattedDate date={date} format={{ weekday: "short", day: "2-digit", month: "2-digit", year: "numeric" }} />
				),
				valueKey: date.toISOString(),
				type: "value",
				size: "max-content",
				render: (overview: IContingentOverviewIndex) => (
					<CenterWrapper>
						<ContingentTag overview={overview} date={date} />
					</CenterWrapper>
				),
			})) as any),
		],
		[dates, t],
	);

	if (isLoading) return <Loading />;

	return (
		<AutoDataList
			heading={t("Hotel contingents overview")}
			empty={t("No overview available.")}
			columns={columns}
			queryKey={contingentOverviewKeys.overview}
			fetcher={contingentOverviewService.overview}
		/>
	);
};
