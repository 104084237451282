import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RoomTypeDetailsList } from "~admin/hotels/roomTypes/RoomTypeDetailsList";
import { useEmptyStepperRoomType } from "~admin/hotels/useEmptyRoom";
import { useHotelStepper } from "./HotelStepperProvider";

export const HotelStepperDetailsPage: FC = () => {
	const { roomType, roomContingent, setRoomContingent, submit } = useHotelStepper();
	const emptyRoomType = useEmptyStepperRoomType();
	const navigate = useNavigate();

	useEffect(() => {
		if (!roomContingent.length || JSON.stringify(roomType) === JSON.stringify(emptyRoomType)) {
			navigate("..");
		}
	}, [navigate, roomContingent.length, roomType, emptyRoomType]);

	return <RoomTypeDetailsList roomDetails={roomContingent} onSubmit={submit} setRoomDetails={setRoomContingent} />;
};
