import { DrawerAutoDataList, IDrawerDataListColumn, useCrudEntity } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { paymentProviderService } from "~admin/shared/api/paymentProvider";
import { IPaymentProviderFormState, IPaymentProviderIndexResource } from "~shared/types/paymentProvider";
import { PaymentProviderDrawer, emptyPaymentProvider } from "./PaymentProviderDrawer";
import { PaymentProviderListContextMenu } from "./PaymentProviderListContextMenu";

export const PaymentProviderList: FC = () => {
	const { t } = useTranslation();

	const crudOperations = useCrudEntity(paymentProviderService, {
		entityName: t("Payment provider"),
		messages: {
			create: t("Payment provider was saved."),
			update: t("Payment provider was saved."),
			delete: t("Payment provider was successfully deleted."),
		},
	});

	const dataListColumns: IDrawerDataListColumn<IPaymentProviderIndexResource>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Type"),
				valueKey: "type",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				action: (paymentProvider: IPaymentProviderIndexResource, openDrawer) => (
					<PaymentProviderListContextMenu
						paymentProviderId={paymentProvider.id}
						handleEdit={openDrawer}
						handleDelete={() => crudOperations.deleteEntity(paymentProvider.id)}
					/>
				),
			},
		],
		[crudOperations, t],
	);

	return (
		<DrawerAutoDataList
			translations={{
				title: t("Payment providers"),
				empty: t("No payment providers available"),
			}}
			renderCreateDrawer={(props) => (
				<PaymentProviderDrawer
					{...props}
					heading={t("Create payment provider")}
					initialValues={emptyPaymentProvider as IPaymentProviderFormState}
					onSubmit={(entity) => crudOperations.createEntity(entity)}
				/>
			)}
			renderUpdateDrawer={(resource, props) => (
				<PaymentProviderDrawer
					{...props}
					heading={t("Edit payment provider")}
					initialValues={resource}
					onSubmit={(formState) => crudOperations.updateEntity(resource.id, formState)}
				/>
			)}
			columns={dataListColumns}
			service={paymentProviderService}
		/>
	);
};
