import { Card, CardFooter, FormLabel, Loading, Pagination, useFormatDateTime, useList } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useChangelog } from "~shared/providers/ChangelogProvider";
import { Change } from "./Change";
import { ChangelogAction } from "./ChangelogAction";

const EmptyContainer = styled.div`
	display: flex;
	padding-top: ${({ theme }) => theme.spacing(6)};
	justify-content: center;
`;

const StyledCard = styled(Card)`
	display: grid;
`;

const ChangeContainer = styled.div`
	overflow: auto;
`;

const ChangeLogEntry = styled.div`
	display: grid;
	grid-template-columns: 1fr 5fr;
	grid-gap: ${({ theme }) => theme.spacing(4)};
	padding: ${({ theme }) => theme.spacing(4)};
	border-bottom: 1px solid ${({ theme }) => theme.colors.palette.grey[10]};
	font-size: ${({ theme }) => theme.fonts.sizes.small};

	&.log-header {
		top: 0;
		position: sticky;
		background-color: ${({ theme }) => theme.colors.layout.content.background};
		border-bottom: 1px solid ${({ theme }) => theme.colors.layout.content.separator};
	}
	&.log-header label {
		font-weight: ${({ theme }) => theme.fonts.weights.bold};
		text-transform: uppercase;
	}
`;

const Changes = styled.div`
	display: grid;
	grid-template-columns: 1fr 2fr 2fr;
	grid-row-gap: ${({ theme }) => theme.spacing(1)};
	grid-column-gap: ${({ theme }) => theme.spacing(4)};
`;

const ChangeDate = styled.div`
	${({ theme }) => theme.fonts.weights.bold};
	color: ${({ theme }) => theme.colors.palette.grey[80]};
`;

const HeaderRow: FC = () => {
	const { t } = useTranslation();

	return (
		<ChangeLogEntry className="log-header">
			<FormLabel />
			<Changes>
				<FormLabel>{t("Change")}</FormLabel>
				<FormLabel>{t("From")}</FormLabel>
				<FormLabel>{t("Changed to")}</FormLabel>
			</Changes>
		</ChangeLogEntry>
	);
};

export const Changelog: FC = () => {
	const { t } = useTranslation();
	const formatDateTime = useFormatDateTime();
	const { isEmpty, loading, pagination, setPage } = useList();
	const { activities } = useChangelog();
	if (loading) return <Loading />;

	if (isEmpty) return <EmptyContainer>{t("No changes for this guest.")}</EmptyContainer>;

	return (
		<StyledCard>
			<ChangeContainer>
				<HeaderRow />
				{activities.map((activity) => (
					<ChangeLogEntry key={activity.id}>
						<div>
							<ChangeDate>{formatDateTime(activity.createdAt)}</ChangeDate>
							<ChangelogAction activity={activity} />
						</div>
						<Changes>
							{activity.changes.map((change, index) => (
								<React.Fragment key={`${change.type}-${index}`}>
									<span>{change.type === "dataField" ? change.dataFieldName : t(`Change.${change.type}`)}</span>
									<Change change={change} attribute="from" />
									<Change change={change} attribute="to" />
								</React.Fragment>
							))}
						</Changes>
					</ChangeLogEntry>
				))}
			</ChangeContainer>
			<CardFooter>
				<Pagination pagination={pagination} onChange={setPage} />
			</CardFooter>
		</StyledCard>
	);
};
