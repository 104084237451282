import { Card, CardBody, CardHeader, CardHeading, LinkTab, LinkTabs, Loading } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import styled from "styled-components";
import { RoomCreatePage } from "~admin/hotels/roomTypes/RoomTypeCreatePage";
import { RoomTypeEditPage } from "~admin/hotels/roomTypes/RoomTypeEditPage";
import { RoomTypeList } from "~admin/hotels/roomTypes/RoomTypeList";
import { hotelService } from "~admin/shared/api/hotels";
import { useShowQuery } from "~shared/api/baseQueries";
import { IHotelResource } from "~shared/types/hotel";
import { FormikHelpersWithRedirect } from "~shared/ui/FormikWithRedirect";
import { HotelBreadcrumb } from "./HotelBreadcrumbs";
import { HotelEditFormFooter, HotelForm } from "./HotelForm";
import { useHotelForm } from "./useHotelForm";

const CardWrapper = styled.div`
	${({ theme }) => theme.card.container};

	${Card} {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
`;

export const HotelEditPage: FC = () => {
	const { t } = useTranslation();
	const { hotelId = "" } = useParams<"hotelId">();
	const { data: hotel, isLoading } = useShowQuery(hotelId, hotelService);
	const { updateHotel, deleteHotel } = useHotelForm();
	const handleDelete = useCallback(() => deleteHotel(hotelId, "/admin/hotel-management"), [deleteHotel, hotelId]);
	const handleSubmit = useCallback(
		(hotel: IHotelResource, helpers: FormikHelpersWithRedirect<IHotelResource>) => updateHotel(hotelId, hotel, helpers),
		[hotelId, updateHotel],
	);

	if (isLoading || !hotel) {
		return (
			<Card>
				<CardHeader>
					<CardHeading>{t("Edit hotel")}</CardHeading>
				</CardHeader>
				<CardBody>
					<Loading />
				</CardBody>
			</Card>
		);
	}

	return (
		<>
			<HotelBreadcrumb hotel={hotel} />
			<Routes>
				<Route index element={<Navigate to="details" replace />} />
				<Route
					element={
						<>
							<CardWrapper>
								<LinkTabs>
									<LinkTab to="details">{t("Details")}</LinkTab>
									<LinkTab to="room-types">{t("Room types")}</LinkTab>
								</LinkTabs>
								<Outlet />
							</CardWrapper>
						</>
					}
				>
					<Route
						path="details"
						element={
							<HotelForm
								title={t("Edit hotel")}
								hotel={hotel}
								onSubmit={handleSubmit}
								footer={<HotelEditFormFooter handleDelete={handleDelete} />}
							/>
						}
					/>
					<Route path="room-types" element={<RoomTypeList />} />
				</Route>
				<Route path="room-types/new/*" element={<RoomCreatePage />} />
				<Route path="room-types/:roomTypeId/*" element={<RoomTypeEditPage />} />
			</Routes>
		</>
	);
};
