import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardHeaderActions,
	CardHeading,
	CheckboxField,
	DateField,
	DeleteAction,
	I18nFormProvider,
	I18nLocaleSwitcher,
	I18nTextField,
	I18nTextareaField,
	LinkButton,
	Loading,
	SubmitButton,
	TextField,
	TextareaField,
} from "@dgs/core";
import React, { FC, ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { PricesField } from "~admin/hotels/roomTypes/contingent/PricesField";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { EmailSingleSelect } from "~shared/selects/EmailSingleSelect";
import { GroupMultiSelect } from "~shared/selects/GroupMultiSelect";
import { IRoomContingentPrice } from "~shared/types/roomContingent";
import { IRoomTypeStepperResource } from "~shared/types/roomType";
import { FormikHelpersWithRedirect, FormikWithRedirect } from "~shared/ui/FormikWithRedirect";

const StyledCardBody = styled(CardBody)`
	display: grid;
	grid-template-columns: 1fr;

	${({ theme }) => theme.breakpoints.tablet} {
		grid-template-columns: 1fr 2fr;
		gap: ${({ theme }) => theme.spacing(16)};
	}
`;

interface Props {
	loading?: boolean;
	roomType: IRoomTypeStepperResource;
	title: ReactNode;
	footer: ReactNode;
	onSubmit: (
		roomType: IRoomTypeStepperResource,
		helpers: FormikHelpersWithRedirect<IRoomTypeStepperResource>,
	) => Promise<boolean>;
}

export const HotelStepperRoomTypeForm: FC<Props> = ({ roomType, onSubmit, title, footer, loading = false }) => {
	const { t } = useTranslation();
	const { fallbackLocale, locales } = useContentLocale();
	const validate = useCallback(
		(values: IRoomTypeStepperResource) => {
			const errors: Record<string, any> = {};

			if (!values.name) {
				errors.name = t("Required field");
			}
			if (!values.capacity) {
				errors.capacity = t("Required field");
			} else if (values.capacity < 0) {
				errors.capacity = t("The value cannot be negative");
			}
			if (!values.startDate) {
				errors.startDate = t("Required field");
			}
			if (!values.endDate) {
				errors.endDate = t("Required field");
			}
			if (values.startDate >= values.endDate) {
				errors.endDate = t("The end date must be greater than the start date");
			}
			const priceErrors: { [K in keyof IRoomContingentPrice]?: any }[] = [];

			values.defaultPrices.forEach((price, idx) => {
				if (price.purchasePrice <= 0) {
					priceErrors[idx] = priceErrors[idx]
						? { ...priceErrors[idx], purchasePrice: t("Price needs to be larger than 0") }
						: { purchasePrice: t("Price needs to be larger than 0") };
				}
				if (price.sellingPrice <= 0) {
					priceErrors[idx] = priceErrors[idx]
						? { ...priceErrors[idx], sellingPrice: t("Price needs to be larger than 0") }
						: { sellingPrice: t("Price needs to be larger than 0") };
				}
				if (price.occupancy < 1) {
					priceErrors[idx] = priceErrors[idx]
						? { ...priceErrors[idx], occupancy: t("Occupancy needs to be larger than 0") }
						: { occupancy: t("Occupancy needs to be larger than 0") };
				} else if (price.occupancy > values.capacity) {
					priceErrors[idx] = priceErrors[idx]
						? {
								...priceErrors[idx],
								occupancy: t(
									"Occupancy needs to be smaller than or equal to the number of maximum persons the room type has.",
								),
						  }
						: {
								occupancy: t(
									"Occupancy needs to be smaller than or equal to the number of maximum persons the room type has.",
								),
						  };
				}
			});

			if (priceErrors.filter((x) => x).length > 0) {
				errors.defaultPrices = priceErrors;
			}

			return errors;
		},
		[t],
	);
	const header = (
		<CardHeader>
			<CardHeading>{title}</CardHeading>
			<CardHeaderActions>
				<I18nLocaleSwitcher />
			</CardHeaderActions>
		</CardHeader>
	);

	if (loading) {
		return (
			<Card>
				{header}
				<CardBody>
					<Loading />
				</CardBody>
			</Card>
		);
	}

	return (
		<I18nFormProvider locales={locales} fallbackLocale={fallbackLocale}>
			{({ isMainForm }) => (
				<Card>
					<FormikWithRedirect initialValues={roomType} onSubmit={onSubmit} validate={validate}>
						{header}
						{isMainForm ? (
							<StyledCardBody>
								<div>
									<TextField label={t("Name")} name="name" required />
									<TextField label={t("Maximum number of persons")} name="capacity" type="number" required />
									<CheckboxField label={t("Show occupancy in email hotel summary")} name="showOccupancyInSummary" />
									<TextField label={t("Hotel contact email")} name="email" />
									<TextField type="tel" label={t("Phone")} name="phone" />
									<TextareaField label={t("Internal remarks")} name="internalRemark" rows={5} />
									<I18nTextField name="guestRemark" label={t("Guest remark")} />
									<GroupMultiSelect label={t("Bookable for group")} name="groups" />
									<EmailSingleSelect
										label={t("Email template for booking overview to hotel contact")}
										name="customEmailForBookingOverview"
									/>
									<EmailSingleSelect
										label={t("Email template for request confirmation to guest")}
										name="customEmailForRequester"
									/>
								</div>
								<div>
									<DateField label={t("Available from")} name="startDate" required />
									<DateField label={t("Available to")} name="endDate" required />
									<PricesField name="defaultPrices" />
									<TextField label={t("Default contingent per day")} name="defaultContingent" type="number" />
									<CheckboxField label={t("Default overbookable")} name="overbookable" />
								</div>
							</StyledCardBody>
						) : (
							<CardBody>
								<I18nTextareaField name="guestRemark" label={t("Guest remark")} />
							</CardBody>
						)}
						{footer}
					</FormikWithRedirect>
				</Card>
			)}
		</I18nFormProvider>
	);
};

export const RoomTypeFormFooter: FC<{ handleDelete?: () => void }> = ({ handleDelete }) => {
	const { t } = useTranslation();

	return (
		<CardFooter>
			<LinkButton to="../../room-types">{t("Back")}</LinkButton>
			{handleDelete && (
				<DeleteAction
					action={handleDelete}
					heading={t("Delete")}
					title={t("Delete")}
					buttonLabel={t("Delete")}
					labels={{ close: t("Close"), confirm: t("Confirm") }}
				>
					{t("Are you sure you want to delete this room type?")}
				</DeleteAction>
			)}
			<SubmitButton>{t("Save")}</SubmitButton>
		</CardFooter>
	);
};
