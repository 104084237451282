import { CardHeader, CardHeading, Icon, TextControl } from "@dgs/core";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useEmailsQuery } from "~shared/api/emails";
import { EmailLink } from "./EmailLink";

const Wrapper = styled.div`
	z-index: ${({ theme }) => theme.zIndices.content.l1};
	background-color: white;
	box-shadow: ${({ theme }) => theme.shadows.l1};
	width: 280px;
	overflow: hidden;
	display: flex;
	flex-direction: column;
`;

const ScrollContainer = styled.div`
	width: 100%;
	overflow-y: auto;
`;

const StyledCardHeader = styled(CardHeader)`
	padding: 1rem 1.25rem;
	gap: 1rem;
`;

const StyledTextControl = styled(TextControl)`
	margin: 0;
`;

const SearchIcon = styled(Icon)`
	cursor: pointer;
`;

export const EmailSendOutSidebar: FC = () => {
	const { t } = useTranslation();
	const { data } = useEmailsQuery();
	const [searchValue, setSearchValue] = useState("");
	const clearSearch = () => setSearchValue("");

	return (
		<Wrapper>
			<StyledCardHeader>
				<CardHeading>{t("Emails")}</CardHeading>
				<StyledTextControl
					name={""}
					placeholder={t("Search")}
					suffix={
						<SearchIcon icon={searchValue ? "close" : "search"} onClick={searchValue ? clearSearch : undefined} />
					}
					value={searchValue}
					onChange={(e) => setSearchValue((e.target.value || "").toLowerCase())}
				/>
			</StyledCardHeader>
			<ScrollContainer>
				{data
					.filter((x) => !searchValue || x.name.toLowerCase().includes(searchValue))
					.map((email) => (
						<EmailLink key={email.id} email={email} />
					))}
			</ScrollContainer>
		</Wrapper>
	);
};
