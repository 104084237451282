import { FCC, Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { isInteger } from "formik";
import React, { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { importKeys, importService } from "~shared/api/imports";
import { mapFormValueToRequest } from "~shared/utils/mapFormValueToRequest";

interface ImportErrorContextState {
	submitCorrectedValues: (errorId: Id, values: Record<string, any>) => void;
	currentErrorId: Id | null;
	setCurrentErrorId: (id: Id | null) => void;
}

interface Props {
	guestImportId: Id;
}

const ImportErrorContext = React.createContext<ImportErrorContextState | undefined>(undefined);

export const ImportErrorListProvider: FCC<Props> = ({ children, guestImportId }) => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const [currentErrorId, setCurrentErrorId] = useState<Id | null>(null);
	const queryClient = useQueryClient();

	const submitCorrectedValues = useCallback(
		async (errorId: Id, values: Record<string, any>) => {
			const request = Object.keys(values).map((valueKey) => ({
				dataFieldId: isInteger(valueKey) ? valueKey : null,
				type: isInteger(valueKey) ? "dataField" : valueKey,
				value: mapFormValueToRequest(values[valueKey]),
			}));

			const response = await importService.guests.errors.correct(guestImportId, errorId, {
				guestValues: request,
			});
			if (apiIsOK(response)) {
				showToast({
					body: t("Import correction has been started."),
					title: t("Import correction"),
					type: "success",
				});
				setCurrentErrorId(null);
				await queryClient.invalidateQueries({ queryKey: importKeys.guests.errors.list(guestImportId) });
				await queryClient.invalidateQueries({ queryKey: importKeys.guests.list });
			}
		},
		[guestImportId, showToast, t, queryClient],
	);

	return (
		<ImportErrorContext.Provider
			value={{
				submitCorrectedValues,
				currentErrorId,
				setCurrentErrorId,
			}}
		>
			{children}
		</ImportErrorContext.Provider>
	);
};

export const useGuestImportErrors = () => {
	const context = useContext(ImportErrorContext);

	if (context === undefined) {
		throw new Error(`Context undefined. Are you missing the ImportErrorListProvider?`);
	}

	return context;
};
