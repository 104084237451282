import { CrudDrawer, EntityDrawerProps, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { PaymentProviderTypeSelectField } from "~admin/shared/selects/PaymentProviderSelectField";
import { PaymentProviderConfigField } from "~admin/shop/paymentProvider/PaymentProviderConfigField";
import {
	IBMWCreditCardPaymentProviderFormState,
	IBmwPaypalPaymentProviderFormState,
	IMolliePaymentProviderFormState,
	IPaymentProviderBaseFormState,
	IPaymentProviderFormState,
	IPaypalPaymentProviderFormState,
	IStripePaymentProviderFormState,
} from "~shared/types/paymentProvider";

export const emptyPaymentProvider: IPaymentProviderBaseFormState = {
	name: "",
	type: null,
	config: {},
};
export const PaymentProviderDrawer: FC<EntityDrawerProps<IPaymentProviderFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(formState: IPaymentProviderFormState) => {
			const errors: { [P in keyof IPaymentProviderFormState]?: any } = {};

			if (!formState.type) {
				errors.type = t("Required field");
			}
			if (!formState.name) {
				errors.name = t("Required field");
			}

			if (formState.type === "PayPal") {
				const configErrors: Partial<IPaypalPaymentProviderFormState["config"]> = {};

				if (!formState.config.clientId) {
					configErrors.clientId = t("Required field");
				}
				if (!formState.config.clientSecret) {
					configErrors.clientSecret = t("Required field");
				}
				if (Object.keys(configErrors).length) {
					errors.config = configErrors;
				}
			}
			if (formState.type === "Stripe") {
				const configErrors: Partial<IStripePaymentProviderFormState["config"]> = {};

				if (!formState.config.apiKey) {
					configErrors.apiKey = t("Required field");
				}
				if (Object.keys(configErrors).length) {
					errors.config = configErrors;
				}
			}
			if (formState.type === "Mollie") {
				const configErrors: Partial<IMolliePaymentProviderFormState["config"]> = {};

				if (!formState.config.apiKey) {
					configErrors.apiKey = t("Required field");
				}
				if (Object.keys(configErrors).length) {
					errors.config = configErrors;
				}
			}
			if (formState.type === "BMWPayPal") {
				const configErrors: Partial<IBmwPaypalPaymentProviderFormState["config"]> = {};

				if (!formState.config.apiKey) {
					configErrors.apiKey = t("Required field");
				}
				if (!formState.config.integratorId) {
					configErrors.integratorId = t("Required field");
				}
				if (!formState.config.merchantDescription) {
					configErrors.merchantDescription = t("Required field");
				}
				if (!formState.config.merchantId) {
					configErrors.merchantId = t("Required field");
				}
				if (Object.keys(configErrors).length) {
					errors.config = configErrors;
				}
			}
			if (formState.type === "BMWCreditCard") {
				const configErrors: Partial<IBMWCreditCardPaymentProviderFormState["config"]> = {};

				if (!formState.config.apiKey) {
					configErrors.apiKey = t("Required field");
				}
				if (!formState.config.integratorId) {
					configErrors.integratorId = t("Required field");
				}
				if (!formState.config.merchantDescription) {
					configErrors.merchantDescription = t("Required field");
				}
				if (!formState.config.merchantId) {
					configErrors.merchantId = t("Required field");
				}
				if (Object.keys(configErrors).length) {
					errors.config = configErrors;
				}
			}

			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<TextField name="name" label={t("Name")} />
					<PaymentProviderTypeSelectField />
					<PaymentProviderConfigField />
				</>
			}
		/>
	);
};
