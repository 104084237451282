import { ConfirmationDialog, LaravelResponse, Loading, apiIsOK, useOpen } from "@dgs/core";
import { AxiosResponse } from "axios";
import React, { FC, ReactNode, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { ModelUsageResource } from "~shared/types/modelUsageResource";
import { UsagesBody } from "./UsagesBody";

interface Props {
	usagePrefix: string;
	open: boolean;
	handleClose: () => void;

	usages: ModelUsageResource[];

	loading: boolean;
	confirmationBody: ReactNode;
	deleteAction: () => Promise<void>;
}

export const useModelUsage = (showUsageAction: () => Promise<AxiosResponse<LaravelResponse<ModelUsageResource[]>>>) => {
	const [usages, setUsages] = useState<ModelUsageResource[]>([]);
	const { open, handleClose, handleOpen } = useOpen();
	const [loading, setLoading] = useState(false);

	const handleUsage = useCallback(async () => {
		setLoading(true);
		setUsages([]);
		handleOpen();
		const response = await showUsageAction();
		if (apiIsOK(response)) {
			handleOpen();
		}
		setLoading(false);
		setUsages(response.data.data);
	}, [handleOpen, showUsageAction]);

	return {
		usages,
		open,
		handleClose,
		loading,
		handleUsage,
	};
};

export const DeleteModelWithUsagesDialog: FC<Props> = ({
	deleteAction,
	usagePrefix,
	confirmationBody,
	handleClose,
	usages,
	loading,
	open,
}) => {
	const { t } = useTranslation();

	return (
		<>
			<ConfirmationDialog
				heading={t("Delete")}
				labels={{ close: t("Close"), confirm: t("Confirm") }}
				open={open}
				onClose={handleClose}
				action={() => deleteAction()}
			>
				{loading ? (
					<Loading />
				) : usages.length > 0 ? (
					<UsagesBody usages={usages} usagePrefix={usagePrefix} confirmationBody={confirmationBody} />
				) : (
					confirmationBody
				)}
			</ConfirmationDialog>
		</>
	);
};
