import { Button, CrudDrawer, EntityDrawerProps, IconButton, Id, TextField } from "@dgs/core";
import { FieldArray } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TenantSelectField } from "~shared/selects/TenantSelectField";
import { IWaveTenantsContingentFormState, IWaveTenantsContingentsFormState } from "~shared/types/wave";

const Row = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr min-content;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(2)};
`;
export const WaveTenantContingentDrawer: FC<EntityDrawerProps<IWaveTenantsContingentsFormState>> = (props) => {
	const { t } = useTranslation();
	const validate = useCallback(
		({ waveTenantContingents }: IWaveTenantsContingentsFormState) => {
			const errors: { [K in keyof IWaveTenantsContingentFormState]?: any }[] = [];

			waveTenantContingents.forEach((x, idx) => {
				if (!x.tenant) {
					errors[idx] = { tenant: t("Required field") };
				}
				if (!x.contingent || x.contingent <= 0) {
					errors[idx] = { contingent: t("Value must be greater than 0") };
				}
			});
			if (errors.length) {
				return { waveTenantContingents: errors };
			}
			return {};
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={({ values }) => (
				<FieldArray
					name="waveTenantContingents"
					render={(arrayHelpers) => (
						<>
							{values.waveTenantContingents.map((x, idx) => (
								<Row key={idx}>
									<TenantSelectField
										name={`waveTenantContingents.${idx}.tenant`}
										label={t("Tenant")}
										hiddenIds={values.waveTenantContingents.reduce(
											(tenantIds, x) => (x.tenant ? [...tenantIds, x.tenant.id] : tenantIds),
											[] as Id[],
										)}
									/>
									<TextField name={`waveTenantContingents.${idx}.contingent`} label={t("Contingent")} type="number" />
									<IconButton
										type="button"
										onClick={() => arrayHelpers.remove(idx)}
										title={t("Remove")}
										icon="delete"
									/>
								</Row>
							))}
							<Button type="button" onClick={() => arrayHelpers.push({ tenant: null, contingent: 0 })}>
								{t("Add")}
							</Button>
						</>
					)}
				/>
			)}
		/>
	);
};
