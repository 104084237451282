import { BlockSingleSelectField, CrudDrawer, IBaseBlockIndexResource, Id } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useRegistrationFormBlock } from "./useRegistrationFormBlock";

interface Props {
	open: boolean;
	onClose: () => void;
	registrationFormId: string;
	registrationFormStepId: Id;
	registrationFormSectionId?: Id;
}

const initialValues: { block: IBaseBlockIndexResource | null } = { block: null };
export const CreateRegistrationFormBlockDrawer: FC<Props> = ({
	registrationFormId,
	registrationFormStepId,
	registrationFormSectionId,
	open,
	onClose,
}) => {
	const { t } = useTranslation();
	const { attachRegistrationFormBlock } = useRegistrationFormBlock(
		registrationFormId,
		registrationFormStepId,
		registrationFormSectionId,
	);
	const onSubmit = useCallback(
		(blockId: Id) => attachRegistrationFormBlock(blockId).then(onClose),
		[attachRegistrationFormBlock, onClose],
	);

	const validate = useCallback(
		({ block }: { block: IBaseBlockIndexResource | null }) => {
			if (!block) {
				return { block: t("Required field") };
			}
			return {};
		},
		[t],
	);

	return (
		<CrudDrawer
			open={open}
			onSubmit={async ({ block }) => {
				if (block) {
					await onSubmit(block.id);
				}
				return true;
			}}
			body={<BlockSingleSelectField name="block" label={t("Content block")} />}
			initialValues={initialValues}
			onClose={onClose}
			heading={t("Attach registration form block")}
			validate={validate}
		/>
	);
};
