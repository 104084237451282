import { CrudDrawer, EntityDrawerProps, TextField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { TaxTypeSelect } from "~admin/shop/taxes/TaxTypeSelect";
import { ITax } from "~shared/types/tax";

export const TaxDrawer: FC<EntityDrawerProps<ITax>> = (props) => {
	const { t } = useTranslation();

	return (
		<CrudDrawer
			{...props}
			body={
				<>
					<TaxTypeSelect name={"type"} label={t("Type")} required />
					<TextField name="percent" label={t("Percentage")} type="number" required />
					<TextField name="notice" label={t("Notice")} />
				</>
			}
		/>
	);
};
