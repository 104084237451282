import { CrudDrawer, FileInputField, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useImportGuests } from "~shared/import/ImportGuestProvider";
import { ImportProviderSingleSelectField } from "~shared/selects/ImportProviderSingleSelectField";
import { ZohoModuleSelectField } from "~shared/selects/ZohoModuleSelect";
import { GuestFileFormValues } from "./ImportGuestFileForm";

interface Props {
	open: boolean;
	onClose: () => void;
}

const SideBySide = styled.div`
	display: grid;

	grid-template-columns: 1fr;

	${({ theme }) => theme.breakpoints.desktop} {
		grid-template-columns: 1fr 1fr;
		gap: ${({ theme }) => theme.spacing(4)};
	}
`;

const initialValues: GuestFileFormValues = {
	importProvider: null,
	headerStartColumn: "A",
	headerRow: 1,
	module: "Contacts",
};

export const ImportFileDrawer: FC<Props> = ({ open, onClose }) => {
	const { t } = useTranslation();
	const { handleUpload } = useImportGuests();

	const validate = useCallback(
		(values: GuestFileFormValues) => {
			const errors: { [K in keyof GuestFileFormValues]?: any } = {};
			if (!values.importProvider) {
				errors.importProvider = t("Required field");
			} else if (values.importProvider.type === "excel") {
				if (!values.file) {
					errors.file = t("Required field");
				}
				if (!values.headerStartColumn) {
					errors.headerStartColumn = t("Required field");
				}
				if (!values.headerRow) {
					errors.headerRow = t("Required field");
				}
			} else if (values.importProvider.type === "zoho") {
				if (!values.module) {
					errors.module = t("Required field");
				}
			}
			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			open={open}
			onSubmit={async (values) => {
				await handleUpload(values);
				return true;
			}}
			validate={validate}
			initialValues={initialValues}
			onClose={onClose}
			heading={t("Participant Import")}
			body={({ values }) => {
				return (
					<>
						<ImportProviderSingleSelectField label={t("Import provider")} name="importProvider" required={true} />
						{values.importProvider?.type === "excel" && (
							<>
								<FileInputField label={t("File")} name="file" accept=".xlsx" />
								<SideBySide>
									<TextField label={t("First header column")} name="headerStartColumn" />
									<TextField label={t("Header row")} name="headerRow" type="number" />
								</SideBySide>
							</>
						)}
						{values.importProvider?.type === "zoho" && <ZohoModuleSelectField />}
					</>
				);
			}}
			translations={{ save: t("Upload") }}
		/>
	);
};
