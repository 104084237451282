import { Button, CrudDrawer, Icon, IconButton, Id, TextField, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { FieldArray } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ordersKeys, ordersService } from "~admin/shared/api/orders";
import { invoiceService } from "~admin/shared/api/shop/invoices/invoiceService";
import { PaymentProviderSingleSelect } from "~admin/shared/selects/PaymentProviderSelectField";
import { ShopItemSingleSelect } from "~admin/shared/selects/ShopItemSelects";
import { CreateOrderFormValue } from "~admin/shared/types/orders";
import { GuestSingleSelect } from "~shared/selects/GuestSingleSelect";

interface Props {
	open: boolean;
	onClose: () => void;
}

const initialValues: CreateOrderFormValue = {
	guest: null,
	paymentProvider: null,
	shopItems: [{ shopItem: null, quantity: 0 }],
};

const InputContainer = styled.div`
	margin: 1rem 0;
`;

const TaxRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr max-content max-content;
	gap: 1rem;
	align-items: center;
`;

export const CreateOrderDrawer: FC<Props> = ({ open, onClose }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();

	const validate = useCallback(
		(values: CreateOrderFormValue) => {
			const errors: Record<string, any> = {};

			if (!values.guest) {
				errors.guest = t("Required field");
			}
			if (!values.paymentProvider) {
				errors.paymentProvider = t("Required field");
			}

			const shopItemErrors: Record<string, any> = {};
			values.shopItems.forEach((shopItem, index) => {
				if (!shopItem.shopItem) {
					shopItemErrors[index] = { ...shopItemErrors[index], shopItem: t("Required field") };
				}
				if (shopItem.quantity <= 0) {
					shopItemErrors[index] = { ...shopItemErrors[index], quantity: t("Required field") };
				}
				if (shopItem.shopItem?.minPerGuest && shopItem.quantity < shopItem.shopItem?.minPerGuest) {
					shopItemErrors[index] = {
						...shopItemErrors[index],
						quantity: t("Min {{count}} allowed", { count: shopItem.shopItem.minPerGuest }),
					};
				}
				if (shopItem.shopItem?.maxPerGuest && shopItem.quantity > shopItem.shopItem?.maxPerGuest) {
					shopItemErrors[index] = {
						...shopItemErrors[index],
						quantity: t("Max {{count}} allowed", { count: shopItem.shopItem?.maxPerGuest }),
					};
				}
				if (values.shopItems.filter((item) => item.shopItem?.id === shopItem.shopItem?.id).length > 1) {
					shopItemErrors[index] = {
						...shopItemErrors[index],
						shopItem: t("The shop item can't be selected more then once"),
					};
				}
			});

			if (Object.keys(shopItemErrors).length > 0) {
				errors.shopItems = shopItemErrors;
			}

			return errors;
		},
		[t],
	);

	const onSubmit = useCallback(
		async (order: CreateOrderFormValue) => {
			const response = await ordersService.createOrder({
				guestId: order.guest?.id as Id,
				paymentProviderId: order.paymentProvider?.id as Id,
				shopItems: order.shopItems.map((shopItem) => ({
					shopItemId: shopItem.shopItem?.id as Id,
					quantity: shopItem.quantity,
				})),
			});
			if (apiIsOK(response)) {
				showToast({
					body: t("Order was saved."),
					title: t("Order"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: ordersKeys.index });
				await queryClient.invalidateQueries({ queryKey: invoiceService.keys.index });
				return true;
			}

			return false;
		},
		[queryClient, showToast, t],
	);

	return (
		<CrudDrawer
			open={open}
			onSubmit={onSubmit}
			body={({ values }) => (
				<>
					<GuestSingleSelect name={"guest"} label={t("Guest")} required />
					<PaymentProviderSingleSelect name="paymentProvider" label={t("Payment providers")} required />
					<FieldArray
						name="shopItems"
						render={(arrayHelpers) => (
							<InputContainer>
								{values.shopItems.map((_, index) => (
									<TaxRow key={index}>
										<ShopItemSingleSelect label={t("Shop item")} name={`shopItems[${index}]shopItem`} required />
										<TextField name={`shopItems[${index}]quantity`} label={t("Quantity")} type="number" required />
										<IconButton
											type="button"
											size="small"
											title={t("Remove Shop Item")}
											onClick={() => arrayHelpers.remove(index)}
											icon="delete"
										/>
									</TaxRow>
								))}
								<Button
									type="button"
									color="default"
									size="small"
									prefix={<Icon icon="plus" />}
									onClick={() =>
										arrayHelpers.push({
											shopItem: null,
											quantity: 0,
										})
									}
								>
									{t("Add Shop Item")}
								</Button>
							</InputContainer>
						)}
					/>
				</>
			)}
			initialValues={initialValues}
			onClose={onClose}
			heading={t("Create order")}
			validate={validate}
		/>
	);
};
