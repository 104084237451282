import { Id } from "@dgs/core";
import { useField } from "formik";
import React, { FC } from "react";
import { GuestMultiSelect } from "~shared/selects/GuestMultiSelect";

interface Props {
	name: string;
	label: string;
	required?: boolean;
	disabled?: boolean;
	exclude?: Id[];
}

export const GuestMultiSelectField: FC<Props> = ({ name, label, required = false, disabled = false, exclude }) => {
	const [field, meta] = useField(name);

	return (
		<GuestMultiSelect
			name={name}
			label={label}
			onChange={field.onChange}
			error={meta.touched ? meta.error : undefined}
			value={field.value}
			disabled={disabled}
			required={required}
			exclude={exclude}
		/>
	);
};
