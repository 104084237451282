import { UserFormValues } from "~admin/shared/types/user";

export const emptyUserValues: UserFormValues = {
	id: "",
	firstName: "",
	lastName: "",
	email: "",
	company: "",
	position: "",
	image: null,
	roles: [],
	tenants: [],
};
