import {
	ContextMenu,
	ContextMenuDeleteItem,
	ContextMenuItem,
	DrawerAutoDataList,
	IDrawerDataListColumn,
	Id,
	useCrudEntity,
} from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { guestFileCategoryService } from "~shared/api/guestFileCategory/guestFileCategoryService";
import { guestKeys } from "~shared/api/guests";
import { IGuestFileCategoryFormState, IGuestFileCategoryIndexResource } from "~shared/types/guestFileCategory";
import { GuestFileCategoryDrawer } from "./GuestFileCategoryDrawer";

export const GuestFileCategoryList: FC = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const crudOperations = useCrudEntity(guestFileCategoryService, {
		entityName: t("Guest file category"),
		messages: {
			create: t("Guest file category was saved."),
			update: t("Guest file category was saved."),
			delete: t("Guest file category was deleted."),
		},
	});
	const emptyGuestFileCategory: IGuestFileCategoryFormState = {
		name: "",
	};

	const editGuestFileCategory = useCallback(
		async (id: Id, formState: IGuestFileCategoryFormState) => {
			const response = await crudOperations.updateEntity(id, formState);
			await queryClient.invalidateQueries({ queryKey: guestKeys.index });
			return response;
		},
		[crudOperations, queryClient],
	);

	const deleteGuestFileCategory = useCallback(
		async (id: Id) => {
			const response = await crudOperations.deleteEntity(id);
			await queryClient.invalidateQueries({ queryKey: guestKeys.index });
			return response;
		},
		[crudOperations, queryClient],
	);

	const dataListColumns: IDrawerDataListColumn<IGuestFileCategoryIndexResource>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: ({ id }, openDrawer) => (
					<ContextMenu displayShortcutsAs="icon">
						<ContextMenuItem
							title={t("Edit guest file category")}
							label={t("Edit guest file category")}
							action={openDrawer}
							icon="edit"
							shortcut="primary"
						/>
						<ContextMenuDeleteItem
							action={() => deleteGuestFileCategory(id)}
							title={t("Delete guest file category")}
							label={t("Delete guest file category")}
							heading={t("Delete")}
							labels={{ close: t("Close"), confirm: t("Confirm") }}
						>
							{t("Are you sure you want to delete this guest file category?")}
						</ContextMenuDeleteItem>
					</ContextMenu>
				),
			},
		],
		[deleteGuestFileCategory, t],
	);

	return (
		<DrawerAutoDataList
			translations={{
				title: t("Guest file categories"),
				empty: t("No guest file categories available"),
			}}
			renderCreateDrawer={(drawerProps) => (
				<GuestFileCategoryDrawer
					{...drawerProps}
					heading={t("Add guest file category")}
					initialValues={emptyGuestFileCategory}
					onSubmit={(entityFormState) => crudOperations.createEntity(entityFormState)}
				/>
			)}
			renderUpdateDrawer={(entityResource, drawerProps) => (
				<GuestFileCategoryDrawer
					{...drawerProps}
					heading={t("Edit guest file category")}
					initialValues={entityResource}
					onSubmit={(entityFormState) => editGuestFileCategory(entityResource.id, entityFormState)}
				/>
			)}
			columns={dataListColumns}
			service={guestFileCategoryService}
		/>
	);
};
