import { IconButton, Id, apiIsOK, useToasts } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { hotelService } from "~admin/shared/api/hotels";
import { IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";

interface props {
	guestViewId: Id;
	start: string;
	end: string;
	hotelId: Id | null;
	guestFilter: IGuestFilterFormValueEntry[];
}

export const BookingsExportButton: FC<props> = ({ guestViewId, start, end, hotelId, guestFilter }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();

	const createExport = useCallback(async () => {
		const response = await hotelService.export(
			{
				guestViewId: guestViewId,
				start: start,
				end: end,
				hotelId: hotelId,
			},
			guestFilter,
		);
		if (apiIsOK(response)) {
			showToast({
				body: t("An export is being created, please wait."),
				title: t("Export"),
				type: "success",
			});
		}
	}, [guestViewId, start, end, hotelId, guestFilter, showToast, t]);

	return (
		<IconButton
			type="button"
			color="primary"
			size="small"
			onClick={() => createExport()}
			title={t("Download")}
			icon="file_download"
		/>
	);
};
