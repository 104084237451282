import { CardBody, CardFooter, CardHeader, CardHeading, SubmitButton } from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { I18nDiscardLocaleButton } from "~admin/i18n_forms/I18nDiscardLocaleButton";
import { I18nErrorToast } from "~admin/i18n_forms/I18nErrorToast";
import { I18nFormProvider } from "~admin/i18n_forms/I18nFormProvider";
import { I18nLocaleSwitcher } from "~admin/i18n_forms/I18nLocaleSwitcher";
import { validateEachLocale } from "~admin/i18n_forms/i18nUtils";
import { ProductTranslationField } from "~admin/settings/productTranslations/ProductTranslationField";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { IProductTranslation, IProductTranslationFormValues } from "~shared/types/productTranslation";

interface Props {
	translations: IProductTranslation;
	handleSave: (settings: IProductTranslationFormValues) => Promise<void>;
}

export const ProductTranslationsForm: FC<Props> = ({ translations, handleSave }) => {
	const { t } = useTranslation();
	const { fallbackLocale, locales } = useContentLocale();
	const formState = {
		translations: translations.translations,
	};

	const validate = useCallback(
		(values: IProductTranslationFormValues) => {
			return {
				...validateEachLocale(values.translations, (_, values, errors) => {
					if (!values.value) {
						errors.value = t("Required field");
					}
					return errors;
				}),
			};
		},
		[t],
	);

	return (
		<I18nFormProvider fallbackLocale={fallbackLocale} locales={locales}>
			<Formik initialValues={formState} validate={validate} onSubmit={handleSave}>
				<Form>
					<CardHeader>
						<CardHeading>{t("Edit product translations")}</CardHeading>
						<I18nLocaleSwitcher emptyLocale={{ value: "" }} />
					</CardHeader>
					<CardBody>
						<ProductTranslationField />
					</CardBody>
					<CardFooter>
						<I18nDiscardLocaleButton />
						<SubmitButton title={t("Save")} name={t("Save")}>
							{t("Save")}
						</SubmitButton>
					</CardFooter>
					<I18nErrorToast />
				</Form>
			</Formik>
		</I18nFormProvider>
	);
};
