import { ListProvider } from "@dgs/core";
import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { importKeys, importService } from "~shared/api/imports";
import { ImportErrorList } from "./ImportErrorList";
import { ImportErrorListProvider } from "./ImportErrorListProvider";

export const ImportErrorPage: FC = () => {
	const { guestImportId = "" } = useParams<"guestImportId">();

	return (
		<ListProvider
			queryKey={importKeys.guests.errors.list(guestImportId)}
			fetcher={importService.guests.errors.index(guestImportId)}
			enableBulkEdit
		>
			<ImportErrorListProvider guestImportId={guestImportId}>
				<ImportErrorList />
			</ImportErrorListProvider>
		</ListProvider>
	);
};
