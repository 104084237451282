import { BaseCrudOptionService, Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { ModelUsageResource } from "~shared/types/modelUsageResource";
import { ITagIndexResource, ITagOptionResource, ITagRequest, ITagUpdateResource } from "~shared/types/tag";
import { tagKeys } from "./tagKeys";

class TagService extends BaseCrudOptionService<ITagIndexResource, ITagUpdateResource, ITagOptionResource, ITagRequest> {
	public keys = tagKeys;

	protected urls = {
		index: keyToUrl(tagKeys.index),
		options: keyToUrl(tagKeys.options),
		details: (tagId: Id) => keyToUrl(tagKeys.details(tagId)),
		showUsage: (tagId: Id) => keyToUrl(tagKeys.showUsage(tagId)),
	};

	showUsage = (tagId: Id) => axios.get<LaravelResponse<ModelUsageResource[]>>(this.urls.showUsage(tagId));
}

export const tagService = new TagService();
