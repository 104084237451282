import { Button, Icon, Trigger } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { AssignRoomMatesToGuestContingentDrawer } from "./AssignRoomMatesToGuestContingentDrawer";

export const AssignRoomMatesToGuestContingentAction: FC = () => {
	const { t } = useTranslation();

	return (
		<Trigger render={(props) => <AssignRoomMatesToGuestContingentDrawer {...props} />}>
			{(props) => (
				<Button {...props} prefix={<Icon icon="plus" />} title={t("Add room mates")} size="small">
					{t("Add room mates")}
				</Button>
			)}
		</Trigger>
	);
};
