import { Card, CardBody, CardHeader, CardHeaderActions, CardHeading, Loading, useIsAddonEnabled } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useGuestDetails } from "~shared/guests/details/GuestDetailProvider";
import { GuestScreeningStatusView } from "~shared/guests/screeningStatus/GuestScreeningStatusView";
import { useGlobalGuestView } from "~shared/providers/GlobalGuestViewProvider";
import { GoToGuestRegistrationForm } from "./GoToGuestRegistrationForm";
import { GoToParent } from "./GoToParent";
import { GuestForm } from "./GuestForm";

const LoadingWrapper = styled(CardBody)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Wrapper = styled(Card)`
	overflow: auto;
`;

const HeaderWrapper = styled.div`
	gap: 2rem;
	display: grid;
	grid-auto-flow: column;
`;

const Grid = styled.div`
	display: grid;
	grid-auto-flow: row;
	gap: ${({ theme }) => theme.spacing(4)};
`;

export const GuestDetails: FC = () => {
	const {
		isNew,
		dataFields,
		dataFieldSettings,
		isLoading,
		guest,
		requiredGuestAttributeIds,
		handleDelete,
		handleSubmit,
	} = useGuestDetails();
	const { globalGuestView } = useGlobalGuestView();
	const { t } = useTranslation();
	const isGuestScreeningEnabled = useIsAddonEnabled("guestScreening");

	return (
		<Grid>
			{isGuestScreeningEnabled && <GuestScreeningStatusView guest={guest} />}
			<Wrapper>
				<CardHeader>
					<CardHeading>{isNew ? t("New guest") : t("Edit guest")}</CardHeading>
					<CardHeaderActions>
						<HeaderWrapper>
							{guest?.companionParent && (
								<GoToParent parent={guest.companionParent} parentType={t("companionParent")} />
							)}
							{guest?.replacementParent && (
								<GoToParent parent={guest.replacementParent} parentType={t("replacementParent")} />
							)}
							<GoToGuestRegistrationForm guest={guest} />
						</HeaderWrapper>
					</CardHeaderActions>
				</CardHeader>
				{!guest || isLoading ? (
					<LoadingWrapper>
						<Loading />
					</LoadingWrapper>
				) : (
					<GuestForm
						guest={guest}
						dataFields={dataFields}
						guestView={globalGuestView}
						requiredGuestAttributeIds={requiredGuestAttributeIds}
						onSubmit={handleSubmit}
						dataFieldSettings={dataFieldSettings}
						onDelete={!isNew && (() => handleDelete(guest.id))}
					/>
				)}
			</Wrapper>
		</Grid>
	);
};
