import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useGuestAttributesQuery } from "~shared/api/guestAttributes/guestAttributeQueries";
import { useImportableGuestAttributeOptions } from "./importableGuestAttributeOption";
import { ImportDataPage } from "./ImportDataPage";
import { ImportErrorPage } from "./ImportErrorPage";
import { ImportGuestProvider } from "./ImportGuestProvider";
import { ImportOverview } from "./ImportOverview";

export const ImportPage: FC = () => {
	const { guestAttributes } = useGuestAttributesQuery();
	const importableGuestAttributeOptions = useImportableGuestAttributeOptions({
		attributes: guestAttributes,
	});

	return (
		<ImportGuestProvider importableGuestAttributeOptions={importableGuestAttributeOptions}>
			<Routes>
				<Route index element={<Navigate to="overview" />} />
				<Route path="overview" element={<ImportOverview />} />
				<Route path="data" element={<ImportDataPage />} />
				<Route path="errors/:guestImportId" element={<ImportErrorPage />} />
			</Routes>
		</ImportGuestProvider>
	);
};
