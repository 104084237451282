import { Card, Loading, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useProductTranslationsQuery } from "~admin/shared/api/productTranslations/productTranslationsQueries";
import {
	productTranslationsKeys,
	productTranslationsService,
} from "~admin/shared/api/productTranslations/productTranslationsService";
import { IProductTranslationFormValues } from "~shared/types/productTranslation";
import { ProductTranslationsForm } from "./ProductTranslationsForm";

export const ProductTranslationsPage: FC = () => {
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	const { data: translations, isLoading } = useProductTranslationsQuery();

	const updateTranslations = useCallback(
		async (values: IProductTranslationFormValues) => {
			const response = await productTranslationsService.put(values);

			if (apiIsOK(response)) {
				showToast({
					body: t("Product translations were saved."),
					title: t("Save product translations"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: productTranslationsKeys.details });
			}
		},
		[queryClient, showToast, t],
	);

	return (
		<Card>
			{isLoading && <Loading />}
			{!isLoading && translations && (
				<ProductTranslationsForm translations={translations} handleSave={updateTranslations} />
			)}
		</Card>
	);
};
