import { useFormikContext } from "formik";
import React, { FC, createContext, useContext, useMemo, useState } from "react";

export interface I18nFormState {
	locales: string[];
	fallbackLocale: string;
	currentLocale: string;
	setCurrentLocale: React.Dispatch<React.SetStateAction<string>>;
	isMainForm: boolean;
}

const I18nFormContext = createContext<I18nFormState | undefined>(undefined);

interface Props {
	fallbackLocale: string;
	locales: string[];
	initialLocale?: string;
	children: React.ReactNode | ((state: I18nFormState) => React.ReactNode);
}

export const I18nFormProvider: FC<Props> = ({ children, initialLocale, fallbackLocale, locales }) => {
	const [currentLocale, setCurrentLocale] = useState(initialLocale || fallbackLocale);

	const value = {
		locales,
		fallbackLocale,
		setCurrentLocale,
		isMainForm: currentLocale === fallbackLocale,
		currentLocale,
	};

	return (
		<I18nFormContext.Provider value={value}>
			{typeof children === "function" ? children(value) : children}
		</I18nFormContext.Provider>
	);
};

export const useI18nForm = () => {
	const ctx = useContext(I18nFormContext);

	if (ctx === undefined) {
		throw new Error("useI18nForm needs to be wrapped inside a I18nFormProvider.");
	}

	return ctx;
};

export const useLocaleIndex = (locale: string) => {
	const {
		values: { translations },
	} = useFormikContext<{ translations: Array<{ locale: string }> }>();

	return useMemo(() => translations.findIndex((x) => x.locale === locale), [locale, translations]);
};
