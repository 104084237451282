import { BlockProvider, FCC } from "@dgs/core";
import React from "react";
import { pmBlockFactory } from "./pmFactoryExtension";

interface Props {
	blockPageBaseUrl?: string;
}

export const PMBlockProvider: FCC<Props> = ({ blockPageBaseUrl, children }) => {
	return (
		<BlockProvider factoryExtension={pmBlockFactory} blockPageBaseUrl={blockPageBaseUrl}>
			{children}
		</BlockProvider>
	);
};
