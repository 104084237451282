import { BaseCrudOptionService, Id, keyToUrl } from "@dgs/core";
import { IPlaceholderIndex, IPlaceholderResource } from "~admin/shared/types/placeholder";

class PlaceholderService extends BaseCrudOptionService<
	IPlaceholderIndex,
	IPlaceholderResource,
	IPlaceholderResource,
	IPlaceholderResource
> {
	public keys = {
		index: ["placeholders"],
		options: ["placeholders", "options"],
		list: ["placeholders", "list"],
		details: (placeHolderId: Id) => ["placeholders", `${placeHolderId}`],
	};
	protected urls = {
		index: keyToUrl(this.keys.index),
		options: keyToUrl(this.keys.options),
		details: (placeholderId: Id) => keyToUrl(this.keys.details(placeholderId)),
	};
}

export const placeholderService = new PlaceholderService();
