import { Id } from "@dgs/core";
import { Group } from "~shared/types/group";
import { GuestViewAttributeResource } from "~shared/types/guestView";
import { IRegistrationFormIndex } from "~shared/types/registrationForm";
import { Tag } from "~shared/types/tag";
import { ITenantOptionResource } from "~shared/types/tenant";

interface GuestListBulkEditValueOption {
	id: Id;
}

export interface GuestListBulkEditValues {
	guestAttribute: GuestViewAttributeResource | null;
	operator: GuestListBulkEditOperators | null;
	value: GuestListBulkEditValueOption | GuestListBulkEditValueOption[] | string | null;
}

export interface GuestListBulkEditRequest {
	guestAttributeId: Id;
	operator: string | null;
	value: Id[] | Id | null;
}

export interface Guest {
	id: Id;
	status: GuestStatus;
	groups: Group[];
	tags: Tag[];
	dataFieldValues: DataFieldValue[];
	firstName?: string;
	lastName?: string;
	email?: string;
	createdAt: string;
	updatedAt: string;
	registrationForm: IRegistrationFormIndex | null;
	tenant: ITenantOptionResource | null;
}

export interface Recipient {
	id: Id;
	firstName?: string;
	lastName?: string;
	email?: string;
}

export interface DataFieldValue {
	id: Id;
	value: any;
}

export interface GuestFormValues {
	[key: Id]: any;
}

export interface GuestAttributeSaveRequest {
	id: Id;
	value: any;
}

export enum GuestStatus {
	NONE = "None",
	INVITED = "Invited",
	INVITATION_OPENED = "InvitationOpened",
	INVITATION_DECLINED = "InvitationDeclined",
	REMINDED = "Reminded",
	REMINDER_OPENED = "ReminderOpened",
	WAITING_LIST = "WaitingList",
	REGISTERED = "Registered",
	REGISTRATION_CHANGED = "RegistrationChanged",
	REGISTRATION_CANCELLED = "RegistrationCancelled",
	CONFIRMATION_SENT = "ConfirmationSent",
	CHECKED_IN = "CheckedIn",
	NO_SHOW = "NoShow",
	APP_RECEIVED = "AppReceived",
	PENDING = "Pending",
}

export enum GuestScreeningStatus {
	NONE = "NONE",
	NEGATIVE_AUTOMATIC = "NEGATIVE_AUTOMATIC",
	NEGATIVE_CONFIRMED = "NEGATIVE_CONFIRMED",
	POSITIVE_AUTOMATIC = "POSITIVE_AUTOMATIC",
	POSITIVE_CONFIRMED = "POSITIVE_CONFIRMED",
}

export interface IGuestDataField {
	id: Id;
	status: string;
	groups: Group[];
	tags: Tag[];
	dataFieldValues: DataFieldValue[];
	createdAt: string;
	updatedAt: string;
	registrationForm: IRegistrationFormIndex | null;
}

export interface IGuestHotelData {
	id: Id;
	checkInDate: string;
	checkOutDate: string;
	hotelComment: string;
}

export interface IUpdateGuestRoomContingentRequest {
	roomIds: Id[];
	roomMateIds?: Id[];
	detachAll: boolean;
	newMainGuestId?: Id;
}

export type GuestAttributeName = "status" | "registrationForm" | "groups" | "tags";
export type GuestTimestampName = "createdAt" | "updatedAt";
export type GuestListBulkEditOperators = "DELETE" | "REPLACE" | "ADD";
