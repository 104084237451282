import { Id, LaravelResponse, PagedSingleSelectField } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import React, { FC } from "react";
import styled from "styled-components";
import { ISectionDataFieldOption } from "~shared/types/registrationFormChildDataField";

interface SectionDataFieldSelectProps {
	label: string;
	name: string;
	formId: Id;
	onChange?: (e: {
		target: {
			name: string;
			value: ISectionDataFieldOption | null;
		};
	}) => void;
	isAction: boolean;
}

const getSectionDataFieldOptions = (formId: Id, isAction: boolean, config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<ISectionDataFieldOption[]>>(`registration-forms/${formId}/section-data-field/options`, {
		...config,
		params: { ...config?.params, isAction: isAction ? 1 : 0 },
	});

export const SectionDataFieldSelect: FC<SectionDataFieldSelectProps> = ({
	name,
	formId,
	label,
	onChange,
	isAction,
}) => {
	return (
		<PagedSingleSelectField<ISectionDataFieldOption>
			name={name}
			queryKey={["registration-forms", formId, "section-data-field", "options"]}
			fetcher={(search, page) =>
				getSectionDataFieldOptions(formId, isAction, { params: { search, page } }).then((r) => r.data)
			}
			renderOption={(o) => <SectionDataFieldOption option={o} />}
			label={label}
			getLabel={(x) => `${x.dataField.name} (${x.section.name})`}
			compare={(first, second) => first.dataField.id === second.dataField.id && first.section.id === second.section.id}
			onChange={onChange}
		/>
	);
};

const SectionDataFieldOptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;
const SectionDataFieldOptionSection = styled.span`
	font-size: 14px;
	color: ${({ theme }) => theme.colors.palette.grey[40]};
`;

const SectionDataFieldOption: FC<{ option: ISectionDataFieldOption }> = ({ option }) => (
	<SectionDataFieldOptionWrapper>
		<span>{option.dataField.name}</span>
		<SectionDataFieldOptionSection>{option.section.name}</SectionDataFieldOptionSection>
	</SectionDataFieldOptionWrapper>
);
