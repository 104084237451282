import { AxiosConfig, FCC, ImageLibraryProvider, UserPermissionProvider, useDynamicConfig } from "@dgs/core";
import React from "react";
import { PMAddonsProvider } from "~shared/addons/PMAddonsProvider";
import { PMBlockProvider } from "~shared/blocks/PMBlockProvider";
import { ContentLocaleProvider } from "~shared/providers/ContentLocaleProvider";
import { GlobalGuestViewProvider } from "~shared/providers/GlobalGuestViewProvider";
import { useNominationManagerConfig } from "./useNominationManagerConfig";

export const NominationManagerContentProviders: FCC = ({ children }) => {
	const { apiUrl } = useDynamicConfig();
	const { settings } = useNominationManagerConfig();

	return (
		<GlobalGuestViewProvider guestViewKey="nominationGuestViewKey" initialGuestViewId={settings.guestView.id}>
			<UserPermissionProvider>
				<AxiosConfig apiUrl={apiUrl} basePath="/nomination-manager/auth" />
				<PMAddonsProvider>
					<PMBlockProvider blockPageBaseUrl="/nomination-manager/blocks">
						<ImageLibraryProvider moduleUrl={`${apiUrl}/images`}>
							<ContentLocaleProvider>{(initialized) => initialized && children}</ContentLocaleProvider>
						</ImageLibraryProvider>
					</PMBlockProvider>
				</PMAddonsProvider>
			</UserPermissionProvider>
		</GlobalGuestViewProvider>
	);
};
