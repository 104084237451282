import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEmptyRoomType } from "~admin/hotels/useEmptyRoom";
import { IRoomTypeResource } from "~shared/types/roomType";
import { FormikHelpersWithRedirect } from "~shared/ui/FormikWithRedirect";
import { RoomTypeFormFooter } from "../stepper/HotelStepperRoomTypeForm";
import { RoomTypeForm } from "./RoomTypeForm";
import { useRoomTypeForm } from "./useRoomTypeForm";

export const RoomCreatePage: FC = () => {
	const { t } = useTranslation();
	const { createRoomType } = useRoomTypeForm();
	const emptyRoomType = useEmptyRoomType();
	const { hotelId = "" } = useParams<"hotelId">();
	const onSubmit = useCallback(
		async (roomType: IRoomTypeResource, helpers: FormikHelpersWithRedirect<IRoomTypeResource>) => {
			if (!hotelId) {
				return false;
			}
			return createRoomType(hotelId, roomType, helpers);
		},
		[createRoomType, hotelId],
	);

	return (
		<RoomTypeForm
			title={t("Create room type")}
			roomType={emptyRoomType}
			onSubmit={onSubmit}
			footer={<RoomTypeFormFooter />}
		/>
	);
};
