import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { BaseGuestImportForm } from "./BaseGuestImportForm";

export const ZohoGuestImportForm = () => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: { filterField: string; filterValue: string }) => {
			const errors: Record<string, string> = {};

			if (!values.filterField) {
				errors.filterField = t("Required field");
			}
			if (!values.filterValue) {
				errors.filterValue = t("Required field");
			}
			return errors;
		},
		[t],
	);

	return (
		<BaseGuestImportForm
			additionalValues={{ filterField: "", filterValue: "", importOnlyDifferences: true }}
			validateAdditionalInformation={validate}
			filterEnabled={true}
			importProviderType="zoho"
		/>
	);
};
