import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { filesKeys, filesService } from "~admin/shared/api/files";
import { CreateFile } from "~shared/types/file";

export const useFiles = () => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const createFile = useCallback(
		async (values: CreateFile) => {
			const response = await filesService.post(values);
			if (apiIsOK(response)) {
				showToast({
					body: t("File was saved."),
					title: t("Save file"),
					type: "success",
				});
			} else {
				showToast({
					body: response.statusText,
					title: t("Could not save file."),
					type: "error",
				});
			}
			await queryClient.invalidateQueries({ queryKey: filesKeys.options });
			await queryClient.invalidateQueries({ queryKey: filesKeys.list });
		},
		[showToast, t, queryClient],
	);

	const deleteFile = useCallback(
		async (fileId: Id) => {
			const response = await filesService.remove(fileId);
			if (apiIsOK(response)) {
				showToast({
					body: t("File was deleted."),
					title: t("Delete file"),
					type: "success",
				});
			} else {
				showToast({
					body: response.statusText,
					title: t("Could not delete file."),
					type: "error",
				});
			}
			await queryClient.invalidateQueries({ queryKey: filesKeys.options });
			await queryClient.invalidateQueries({ queryKey: filesKeys.list });
			await queryClient.invalidateQueries({ predicate: (query) => query.queryKey.includes("guest-files") });
		},
		[showToast, t, queryClient],
	);

	return { createFile, deleteFile };
};
