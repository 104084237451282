import { Icon, Id } from "@dgs/core";
import React, { CSSProperties, Ref, forwardRef } from "react";
import styled from "styled-components";

const DragIndicator = styled(Icon).attrs({ icon: "drag_indicator" })`
	width: 20px;
	height: 20px;
	color: ${({ theme }) => theme.colors.palette.grey[50]};
`;

export const Wrapper = styled.div<{ $active?: boolean }>`
	user-select: none;
	display: grid;
	grid-auto-flow: column;
	grid-auto-columns: max-content;
	${({ theme }) => theme.borderRadius.medium};
	border: 1px solid ${({ theme }) => theme.colors.palette.grey[40]};
	background-color: ${({ theme }) => theme.colors.palette.grey[10]};
	cursor: pointer;
	overflow: hidden;
	box-shadow: ${({ $active, theme }) => ($active ? theme.shadows.l2 : "none")};
	transform: scale(${({ $active }) => ($active ? 1.1 : 1)});
	padding: ${({ theme }) => theme.spacing(2)};
	gap: ${({ theme }) => theme.spacing(2)};
`;

export interface ISortableItem {
	id: Id;
	isRestrictedToVerticalAxis?: boolean;
}

interface ItemProps {
	renderLabel: () => React.ReactNode;
	style?: CSSProperties;
	active?: boolean;
}

export const SortableListItem = forwardRef(function ItemFunction(
	{ renderLabel, active, ...props }: ItemProps,
	ref: Ref<HTMLDivElement>,
) {
	return (
		<Wrapper ref={ref} $active={active} {...props}>
			<DragIndicator />
			{renderLabel()}
		</Wrapper>
	);
});
