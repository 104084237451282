import { Card, CardBody, CardHeader, CardHeading, LinkButton } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const StyledLinkButton = styled(LinkButton)`
	text-align: center;
	padding: 0 ${({ theme }) => theme.spacing(5)};

	${({ theme }) => theme.breakpoints.tablet} {
		padding: 0 ${({ theme }) => theme.spacing(30)};
	}
`;

const Content = styled.div`
	display: flex;
	justify-content: center;
	padding: ${({ theme }) => theme.spacing(20)} 0;
`;

export const StartHotelManager: FC = () => {
	const { t } = useTranslation();

	return (
		<Card>
			<CardHeader>
				<CardHeading>{t("Welcome to the Hotel Manager!")}</CardHeading>
			</CardHeader>
			<CardBody>
				{t("Please configure a hotel first to use this module.")}
				<Content>
					<StyledLinkButton to={"../create-hotel"} title={t("Start Hotel Manager")} color={"primary"}>
						{t("Start Hotel Manager")}
					</StyledLinkButton>
				</Content>
			</CardBody>
		</Card>
	);
};
