import {
	Button,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerHeading,
	EntityFetcher,
	I18nFormProvider,
	I18nLocaleSwitcherBase,
	Icon,
	Id,
	Trigger,
	useCrudEntity,
	useEditDrawer,
} from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DataFieldOptionService, dataFieldService } from "~shared/api/dataFields";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import {
	DataFieldOptionTranslatableAttributes,
	IDataFieldOptionFormState,
	IDataFieldOptionUpdateResource,
} from "~shared/types/dataFieldOption";
import { DataFieldOptionDrawer } from "./DataFieldOptionDrawer";
import { DataFieldOptionSortableList } from "./DataFieldOptionSortableList";

export const DataFieldOptionsDrawer: FC<ReturnType<typeof useEditDrawer>> = (props) => {
	return props.editEntityId && <DataFieldOptionsDrawerContent dataFieldId={props.editEntityId} {...props} />;
};

interface DataFieldOptionsDrawerFormProps extends Omit<ReturnType<typeof useEditDrawer>, "editEntityId"> {
	dataFieldId: Id;
}

const DataFieldOptionsDrawerContent: FC<DataFieldOptionsDrawerFormProps> = ({ dataFieldId, ...rest }) => {
	const { t } = useTranslation();
	const { fallbackLocale, locales } = useContentLocale();
	const service = useMemo(() => new DataFieldOptionService(dataFieldId.toString()), [dataFieldId]);
	const crudOperations = useCrudEntity(
		service,
		{
			entityName: t("Data field option"),
			messages: {
				create: t("Data field option was saved."),
				update: t("Data field option was saved."),
				delete: t("Data field option was successfully deleted."),
			},
		},
		[service.keys.index, dataFieldService.keys.contingentOverview],
	);

	const emptyDataFieldOption: IDataFieldOptionFormState = useMemo(
		() => ({
			key: "",
			order: 0,
			dataFieldId: dataFieldId,
			contingent: null,
			translations: locales.reduce(
				(translations, locale) => ({
					...translations,
					[locale]: {
						value: "",
					},
				}),
				{} as Record<string, DataFieldOptionTranslatableAttributes>,
			),
		}),
		[dataFieldId, locales],
	);
	const drawerProps = useEditDrawer();

	return (
		<I18nFormProvider fallbackLocale={fallbackLocale} locales={locales}>
			<Drawer {...rest} key={`${rest.open}`}>
				<DrawerHeader {...rest}>
					<DrawerHeading>{t("Data field options")}</DrawerHeading>
				</DrawerHeader>
				<DrawerBody>
					<I18nLocaleSwitcherBase />
					<DataFieldOptionSortableList
						dataFieldId={dataFieldId}
						onEditClick={drawerProps.handleOpen}
						onDeleteClick={crudOperations.deleteEntity}
					/>

					{drawerProps.editEntityId && (
						<EntityFetcher<IDataFieldOptionUpdateResource>
							id={drawerProps.editEntityId}
							service={service}
							renderDrawer={(entityResource, isLoading, reset) =>
								entityResource &&
								!isLoading && (
									<DataFieldOptionDrawer
										{...drawerProps}
										onClosed={() => {
											drawerProps.onClosed();
											reset();
										}}
										onSubmit={(formState) => crudOperations.updateEntity(entityResource.id, formState)}
										heading={t("Edit data field option")}
										initialValues={entityResource}
									/>
								)
							}
						/>
					)}
				</DrawerBody>
				<DrawerFooter>
					<Trigger
						render={(props) => (
							<DataFieldOptionDrawer
								open={props.open}
								onClose={props.handleClose}
								onClosed={() => void 0}
								onSubmit={crudOperations.createEntity}
								heading={t("Add data field option")}
								initialValues={emptyDataFieldOption}
							/>
						)}
					>
						{(props) => (
							<Button {...props} type="button" color="primary" prefix={<Icon icon="plus" />}>
								{t("Add data field option")}
							</Button>
						)}
					</Trigger>
				</DrawerFooter>
			</Drawer>
		</I18nFormProvider>
	);
};
