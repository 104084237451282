import React, { FC } from "react";
import { Link, useMatch } from "react-router-dom";
import styled from "styled-components";
import { EmailListResource } from "~shared/types/email";

const StyledLink = styled(Link)`
	display: block;
	color: inherit;
	text-decoration: none;
	padding: 0.5rem 0;
`;

const LinkWrapper = styled.span`
	padding: 0 0.75rem 0 1.25rem;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr max-content;
	gap: 1rem;

	:hover,
	&.active {
		background: ${({ theme }) => theme.colors.sidebar.itemHover};
	}

	&.active {
		color: ${({ theme }) => theme.colors.sidebar.itemActive};
	}
`;

interface Props {
	email: EmailListResource;
}

export const EmailLink: FC<Props> = ({ email }) => {
	const match = useMatch({ path: `/admin/emails/send-outs/send/${email.id}`, end: false });

	return (
		<LinkWrapper className={match ? "active" : ""}>
			<StyledLink to={email.id.toString()}>{email.name}</StyledLink>
		</LinkWrapper>
	);
};
