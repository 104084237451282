import { ContextMenu, ContextMenuItem } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
	handleEdit: () => void;
	handleDelete: () => void;
}

export const WaitingListListContextMenu: FC<Props> = ({ handleEdit, handleDelete }) => {
	const { t } = useTranslation();

	return (
		<ContextMenu displayShortcutsAs="icon" hideIconOnOnlyShortcuts={true}>
			<ContextMenuItem label={t("Edit")} action={handleEdit} title={t("Edit")} icon="edit" shortcut="primary" />
			<ContextMenuItem action={handleDelete} title={t("Delete")} label={t("Delete")} icon="delete" shortcut={true} />
		</ContextMenu>
	);
};
