import { useFormatDate, useFormatDateTime } from "@dgs/core";
import { parseISO } from "date-fns";
import { IDataFieldOptionOptionResource } from "~shared/types/dataFieldOption";
import { DataFieldType } from "~shared/types/dataFieldType";
import { Rule, RuleTypes } from "~shared/types/rules";

const getNameTranslationValues = (rule: Rule) => ({ name: rule.sectionDataField.dataField.name });
const getNameAndValueTranslationValues = (rule: Rule) => ({
	name: rule.sectionDataField.dataField.name,
	value: rule.value,
});

const getMinMaxTranslationValues = (rule: Rule) => ({
	name: rule.sectionDataField.dataField.name,
	value:
		rule.sectionDataField.dataField.type === DataFieldType.DATE ||
		rule.sectionDataField.dataField.type === DataFieldType.DATETIME
			? parseISO(rule.value as string)
			: rule.value,
});

const useGetHasValueTranslationValues = () => {
	const formatDate = useFormatDate();
	const formatDateTime = useFormatDateTime();

	return (rule: Rule) => {
		const values = { name: rule.sectionDataField.dataField.name, value: "" };

		switch (rule.sectionDataField.dataField.type) {
			case DataFieldType.RADIO:
				const value = rule.value as IDataFieldOptionOptionResource;
				values.value = rule.sectionDataField.dataField.options.find((x) => x.id === value.id)?.value ?? "";
				break;
			case DataFieldType.SELECT_FIELD: {
				const value = rule.value as IDataFieldOptionOptionResource;
				values.value = rule.sectionDataField.dataField.options.find((x) => x.id === value.id)?.value ?? "";
				break;
			}
			case DataFieldType.CHECKBOX_GROUP:
			case DataFieldType.MULTI_SELECT_FIELD: {
				const value = rule.value as IDataFieldOptionOptionResource[];
				values.value = rule.sectionDataField.dataField.options
					.filter((x) => value.some((y) => y.id === x.id))
					.map((x) => x.value)
					.join(", ");
				break;
			}
			case DataFieldType.CHECKBOX:
				values.value = rule.value ? "true" : "false";
				break;
			case DataFieldType.DATE:
				values.value = formatDate(rule.value as string);
				break;
			case DataFieldType.DATETIME:
				values.value = formatDateTime(rule.value as string);
				break;
			default:
				values.value = rule.value as string;
		}

		return values;
	};
};
export const useGetRuleTranslation = () => {
	const getHasValueTranslationValues = useGetHasValueTranslationValues();

	return (rule: Rule) => {
		switch (rule.type) {
			case RuleTypes.IS_REQUIRED:
				return {
					key: "data field {{name}} is required",
					values: getNameTranslationValues(rule),
				};
			case RuleTypes.NOT_UPDATABLE:
				return {
					key: "data field {{name}} is not updatable",
					values: getNameTranslationValues(rule),
				};
			case RuleTypes.READ_ONLY:
				return {
					key: "data field {{name}} is read only",
					values: getNameTranslationValues(rule),
				};
			case RuleTypes.HAS_VALUE:
				return {
					key: "data field {{name}} has value {{value}}",
					values: getHasValueTranslationValues(rule),
				};
			case RuleTypes.HAS_NOT_VALUE:
				return {
					key: "data field {{name}} has not value {{value}}",
					values: getHasValueTranslationValues(rule),
				};
			case RuleTypes.HAS_MAX_CHARS:
				return {
					key: "data field {{name}} has maximum {{value}} characters",
					values: getNameAndValueTranslationValues(rule),
				};
			case RuleTypes.HAS_MIN_CHARS:
				return {
					key: "data field {{name}} has minimum {{value}} characters",
					values: getNameAndValueTranslationValues(rule),
				};
			case RuleTypes.MAX:
				return {
					key: "data field {{name}} has maximum value {{value}}",
					values: getMinMaxTranslationValues(rule),
				};
			case RuleTypes.MIN:
				return {
					key: "data field {{name}} has minimum value {{value}}",
					values: getMinMaxTranslationValues(rule),
				};
			case RuleTypes.MUST_CONTAIN:
				return {
					key: "data field {{name}} contains {{value}}",
					values: getHasValueTranslationValues(rule),
				};
			case RuleTypes.MUST_NOT_CONTAIN:
				return {
					key: "data field {{name}} contains not {{value}}",
					values: getHasValueTranslationValues(rule),
				};
			case RuleTypes.CONTAINS_ONE:
				return {
					key: "field must contain one of {{value}}",
					values: { name: rule.sectionDataField.dataField.name, value: (rule.value as string[]).join(", ") },
				};
			case RuleTypes.NOT_CONTAINS_ONE:
				return {
					key: "field cannot contain one of {{value}}",
					values: { name: rule.sectionDataField.dataField.name, value: (rule.value as string[]).join(", ") },
				};
		}
	};
};
