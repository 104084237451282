import { Protected } from "@dgs/core";
import * as Sentry from "@sentry/react";
import React, { FC } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { AdminPage } from "~admin/AdminPage";
import { AdminProviders, AppProviders } from "~admin/AppProviders";
import { PMAuthPages } from "~admin/auth";
import { NominationManager } from "./nominationManager/NominationManager";

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const App: FC = () => {
	return (
		<AppProviders>
			<SentryRoutes>
				<Route path="nomination-manager/*" element={<NominationManager />} />
				<Route
					path="admin"
					element={
						<AdminProviders>
							<Outlet />
						</AdminProviders>
					}
				>
					<Route path="auth/*" element={<PMAuthPages />} />
					<Route
						path="*"
						element={
							<Protected fallback={<Navigate to="auth/login" />}>
								<AdminPage />
							</Protected>
						}
					/>
					<Route index element={<Navigate to="dashboard" replace />} />
				</Route>
				<Route index element={<Navigate to="admin" replace />} />
			</SentryRoutes>
		</AppProviders>
	);
};
