import { Drawer, DrawerBody, DrawerHeader, DrawerHeading, Id, Loading } from "@dgs/core";
import React, { FC } from "react";
import { useRegistrationFormSectionDataField } from "~shared/api/registrationForms/registrationFormQueries";
import { useRuleActionTypeQuery } from "~shared/api/registrationForms/ruleSets/ruleSetQueries";
import { StructureDataField, StructureSection } from "~shared/types/registrationFormTypes";
import { DataFieldValidationDrawerContent } from "./DataFieldValidationDrawerContent";

interface Props {
	registrationFormId: string;
	open: boolean;
	handleClose: () => void;
	stepId: Id;
	section: StructureSection;
	structureDataField: StructureDataField;
}

export const DataFieldValidationDrawer: FC<Props> = ({
	registrationFormId,
	open,
	stepId,
	section,
	handleClose,
	structureDataField,
}) => {
	const { dataField } = useRegistrationFormSectionDataField(
		registrationFormId,
		stepId,
		section.sectionId,
		structureDataField.dataFieldId,
		open,
	);
	const { ruleActionTypes } = useRuleActionTypeQuery();

	return (
		<Drawer open={open} onClose={handleClose}>
			<DrawerHeader onClose={handleClose}>
				<DrawerHeading>{`${structureDataField.name} (${structureDataField.type})`}</DrawerHeading>
			</DrawerHeader>
			<DrawerBody>
				{dataField && ruleActionTypes ? (
					<DataFieldValidationDrawerContent
						registrationFormId={registrationFormId}
						stepId={stepId}
						section={section}
						dataField={dataField}
						handleClose={handleClose}
						ruleActionTypes={ruleActionTypes}
					/>
				) : (
					<Loading />
				)}
			</DrawerBody>
		</Drawer>
	);
};
