import { Id, LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios from "axios";
import { IOptionService } from "~shared/api/baseService";
import { mapFetcherConfigToAxiosConfig } from "~shared/api/indexMapper";
import { IRoomTypeIndex, IRoomTypeRequest, IRoomTypeResource } from "~shared/types/roomType";

export class RoomTypeService extends IOptionService<IRoomTypeIndex, IRoomTypeResource, IRoomTypeRequest> {
	public keys = {
		index: ["room-types"],
		list: ["room-types", "list"],
		indexByHotel: (hotelId: Id) => ["hotels", `${hotelId}`, "room-types"],
		options: ["room-types", "options"],
		details: (id: Id) => ["room-types", `${id}`],
		post: (hotelId: Id) => ["hotels", `${hotelId}`, "room-types"],
	};
	protected urls = {
		index: keyToUrl(this.keys.index),
		indexByHotel: (hotelId: Id) => keyToUrl(this.keys.indexByHotel(hotelId)),
		options: keyToUrl(this.keys.options),
		details: (id: Id) => keyToUrl(this.keys.details(id)),
		post: (hotelId: Id) => keyToUrl(this.keys.post(hotelId)),
	};

	indexByHotel = (hotelId?: Id) => (config: ListFetcherConfig<any>) => {
		return axios.get<LaravelResponse<IRoomTypeIndex[]>>(
			hotelId ? this.urls.indexByHotel(hotelId) : this.urls.index,
			mapFetcherConfigToAxiosConfig(config),
		);
	};
	createRoomType = (hotelId: Id, entity: IRoomTypeRequest) => {
		return axios.post<LaravelResponse<IRoomTypeIndex>>(this.urls.post(hotelId), entity);
	};
}

export const roomTypeService = new RoomTypeService();
