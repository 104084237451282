import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IPaymentProviderIndexResource } from "~shared/types/paymentProvider";
import { RegistrationFormShopList } from "./RegistrationFormShopList";

interface Props {
	paymentProviders: IPaymentProviderIndexResource[];
}

export const RegistrationFormPaymentProviders: FC<Props> = ({ paymentProviders }) => {
	const { t } = useTranslation();

	return (
		<RegistrationFormShopList
			items={paymentProviders}
			empty={t("This step will be omitted because no payment providers are configured for this form.")}
			heading={t("Payment providers")}
		>
			{(item) => item.name}
		</RegistrationFormShopList>
	);
};
