import { Id, LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { ordersKeys } from "~admin/shared/api/orders/ordersKeys";
import {
	CreateOrderRequest,
	OrderDetailsResource,
	OrderItemsIndex,
	OrderRefundRequest,
	OrderRequest,
	OrdersIndex,
} from "~admin/shared/types/orders";
import { IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";
import { GuestIndexResource } from "~shared/types/newGuestTypes";

export const orderUrls = {
	index: keyToUrl(ordersKeys.index),
	bulkSendRemainingPaymentMail: keyToUrl(ordersKeys.bulkSendRemainingPaymentMail),
	details: (orderId: Id) => keyToUrl(ordersKeys.details(orderId)),
	items: (orderId: Id) => keyToUrl(ordersKeys.items(orderId)),
	refund: (orderId: Id) => keyToUrl(ordersKeys.refund(orderId)),
	refundableItems: (orderId: Id) => keyToUrl(ordersKeys.refundableItems(orderId)),
	createOrder: keyToUrl(ordersKeys.createOrder),
	sendRemainingPaymentMail: (orderId: Id) => keyToUrl(ordersKeys.sendRemainingPaymentMail(orderId)),
	approveOrder: (orderId: Id) => keyToUrl(ordersKeys.approveOrder(orderId)),
	createExport: keyToUrl(ordersKeys.createExport),
};

const index = (guestViewId: Id, config?: AxiosRequestConfig) => {
	return axios.get<LaravelResponse<OrdersIndex[]>>(orderUrls.index, {
		...config,
		params: { ...config?.params, guestViewId },
	});
};

const details = (orderId: Id) => {
	return axios.get<LaravelResponse<OrderDetailsResource>>(orderUrls.details(orderId));
};

const filtered = (guestViewId: Id, filter: IGuestFilterFormValueEntry[], listFetcherConfig: ListFetcherConfig<any>) =>
	axios.post<LaravelResponse<OrdersIndex[]>>(
		orderUrls.index,
		{
			guestViewId,
			filters: filter,
			sort: listFetcherConfig.sortParams
				? {
						guestAttributeId: listFetcherConfig.sortParams.column,
						direction: listFetcherConfig.sortParams.direction,
				  }
				: null,
		},
		{
			params: {
				...listFetcherConfig.pageParams,
				...listFetcherConfig.searchParams,
			},
		},
	);

const update = (order: OrderRequest) => axios.put<LaravelResponse<OrdersIndex>>(orderUrls.details(order.id), order);

const items = (orderId: Id, config?: AxiosRequestConfig) => {
	return axios.get<LaravelResponse<OrderItemsIndex[]>>(orderUrls.items(orderId), config);
};

const refundableItems = (orderId: Id, config?: AxiosRequestConfig) => {
	return axios.get<LaravelResponse<OrderItemsIndex[]>>(orderUrls.refundableItems(orderId), config);
};

const sendRemainingPaymentMail = (orderId: Id) => {
	return axios.get<LaravelResponse<void>>(orderUrls.sendRemainingPaymentMail(orderId));
};

const approveOrder = (orderId: Id) => {
	return axios.get<LaravelResponse<void>>(orderUrls.approveOrder(orderId));
};

const refund = (orderId: Id, order: OrderRefundRequest) =>
	axios.post<LaravelResponse<OrdersIndex>>(orderUrls.refund(orderId), order);

const createOrder = (order: CreateOrderRequest) =>
	axios.put<LaravelResponse<OrdersIndex>>(orderUrls.createOrder, order);

const bulkSendRemainingPaymentMail = (orderIds: Id[], all = false) =>
	axios.post<LaravelResponse<GuestIndexResource[]>>(orderUrls.bulkSendRemainingPaymentMail, {
		orderIds,
		all,
	});

const createExport = (search: string | null, filters: IGuestFilterFormValueEntry[], guestViewId?: Id | null) =>
	axios.post<LaravelResponse<void>>(orderUrls.createExport, {
		search,
		filters,
		guestViewId,
	});

export const ordersService = {
	index,
	update,
	items,
	filtered,
	details,
	refund,
	refundableItems,
	bulkSendRemainingPaymentMail,
	sendRemainingPaymentMail,
	sendPaymentMail: approveOrder,
	createOrder,
	createExport,
};
