import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { invoiceProviderService } from "~admin/shared/api/invoices";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
}

const fetcher = (search: string, page: number) => invoiceProviderService.options(search, page).then((r) => r.data);

export const InvoiceProviderSingleSelect: FC<Props> = ({ name, label, disabled }) => {
	return (
		<PagedSingleSelectField
			name={name}
			label={label}
			disabled={disabled}
			fetcher={fetcher}
			queryKey={invoiceProviderService.keys.options}
			compare={(first, second) => first.id === second.id}
			required={true}
		/>
	);
};
