import { ContextMenu, ContextMenuItem } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { groupService } from "~shared/api/groups";
import { Group } from "~shared/types/group";
import { DeleteModelWithUsagesDialog, useModelUsage } from "~shared/ui/modelUsage/DeleteModelWithUsagesDialog";

interface Props {
	group: Group;
	handleEdit: () => void;
	handleDelete: () => Promise<any>;
}

export const GroupListContextMenu: FC<Props> = ({ group, handleEdit, handleDelete }) => {
	const { t } = useTranslation();
	const showUsageAction = useCallback(() => groupService.showUsage(group.id), [group.id]);
	const { handleUsage, ...usageModalProps } = useModelUsage(showUsageAction);

	return (
		<>
			<ContextMenu displayShortcutsAs="icon">
				<ContextMenuItem label={t("Edit")} action={handleEdit} title={t("Edit")} icon="edit" shortcut="primary" />
				<ContextMenuItem action={handleUsage} title={t("Delete")} label={t("Delete")} icon="delete" />
			</ContextMenu>
			<DeleteModelWithUsagesDialog
				usagePrefix={t("This group")}
				confirmationBody={t("Are you sure you want to delete this group?")}
				deleteAction={handleDelete}
				{...usageModalProps}
			/>
		</>
	);
};
