import { DrawerAutoDataList, IDrawerDataListColumn, Id, useCrudEntity } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { groupService } from "~shared/api/groups";
import { guestFilterKeys } from "~shared/api/guestFilters";
import { guestKeys } from "~shared/api/guests";
import { Group } from "~shared/types/group";
import { GroupDrawer } from "./GroupDrawer";
import { GroupListContextMenu } from "./GroupListContextMenu";

export const GroupList: FC = () => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const crudOperations = useCrudEntity(groupService, {
		entityName: t("Group"),
		messages: {
			create: t("Group was saved."),
			update: t("Consent was saved."),
			delete: t("Group was deleted."),
		},
	});

	const editGroup = useCallback(
		async (id: Id, formState: Group) => {
			const response = await crudOperations.updateEntity(id, formState);
			await queryClient.invalidateQueries({ queryKey: guestKeys.index });
			return response;
		},
		[crudOperations, queryClient],
	);

	const deleteGroup = useCallback(
		async (id: Id) => {
			const response = await crudOperations.deleteEntity(id);
			await queryClient.invalidateQueries({ queryKey: guestKeys.index });
			await queryClient.resetQueries({ queryKey: guestFilterKeys.index });
			return response;
		},
		[crudOperations, queryClient],
	);

	const dataListColumns: IDrawerDataListColumn<Group>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (group: Group, openDrawer) => (
					<GroupListContextMenu group={group} handleEdit={openDrawer} handleDelete={() => deleteGroup(group.id)} />
				),
			},
		],
		[deleteGroup, t],
	);

	const emptyGroup: Group = {
		name: "",
		id: "-1",
	};

	return (
		<DrawerAutoDataList
			initialSearch={{ search: "", searchFields: ["name"] }}
			translations={{
				title: t("Groups"),
				empty: t("No groups available"),
			}}
			renderCreateDrawer={(drawer) => (
				<GroupDrawer
					{...drawer}
					heading={t("Create group")}
					initialValues={emptyGroup}
					onSubmit={(entityFormState) => crudOperations.createEntity(entityFormState)}
				/>
			)}
			renderUpdateDrawer={(entity: Group, drawer) => (
				<GroupDrawer
					{...drawer}
					heading={t("Edit group")}
					initialValues={entity}
					onSubmit={(entityFormState: Group) => editGroup(entity.id, entityFormState)}
				/>
			)}
			columns={dataListColumns}
			service={groupService}
		/>
	);
};
