import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	LinkButton,
	LinkTab,
	LinkTabs,
	Loading,
	SubmitButton,
} from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import styled from "styled-components";
import { ContingentBreadcrumb } from "~admin/hotels/HotelBreadcrumbs";
import { RoomList } from "~admin/hotels/roomTypes/contingent/rooms/RoomList";
import { useRoomContingentQuery } from "~shared/api/contingent/roomQueries";
import { IRoomContingentForm } from "~shared/types/roomContingent";
import { IRoomTypeResource } from "~shared/types/roomType";
import { RoomContingentForm, RoomContingentFormFields } from "./RoomContingentForm";
import { useRoomContingentForm } from "./useRoomContingentForm";

const CardWrapper = styled.div`
	${({ theme }) => theme.card.container};

	${Card} {
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
`;

interface Props {
	roomType: IRoomTypeResource;
}

export const RoomContingentEditPage: FC<Props> = ({ roomType }) => {
	const { hotelId = "", roomTypeId = "", roomContingentId = "" } = useParams();
	const { data, isLoading } = useRoomContingentQuery(hotelId, roomTypeId, roomContingentId);
	const { updateRoomContingent } = useRoomContingentForm();
	const { t } = useTranslation();
	const handleSubmit = useCallback(
		(roomContingent: IRoomContingentForm) =>
			updateRoomContingent(hotelId, roomTypeId, roomContingentId, roomContingent),
		[hotelId, roomContingentId, roomTypeId, updateRoomContingent],
	);
	const header = <CardHeader>{t("Edit room contingent")}</CardHeader>;

	if (!data || isLoading) {
		return (
			<Card>
				{header}
				<CardBody>
					<Loading />
				</CardBody>
			</Card>
		);
	}

	return (
		<>
			<ContingentBreadcrumb hotelId={hotelId} roomTypeId={roomTypeId} roomContingent={data} />
			<Routes>
				<Route index element={<Navigate to="details" replace />} />
				<Route
					element={
						<CardWrapper>
							<LinkTabs>
								<LinkTab to="details">{t("Details")}</LinkTab>
								<LinkTab to="rooms">{t("Rooms")}</LinkTab>
							</LinkTabs>
							<Outlet />
						</CardWrapper>
					}
				>
					<Route
						path="details"
						element={
							<Card>
								<CardHeader>{t("Edit room contingent")}</CardHeader>
								<RoomContingentForm roomContingent={data} roomType={roomType} onSubmit={handleSubmit}>
									<CardBody>
										<RoomContingentFormFields disabledDate={true} />
									</CardBody>
									<CardFooter>
										<LinkButton to="./../..">{t("Back")}</LinkButton>
										<SubmitButton>{t("Save")}</SubmitButton>
									</CardFooter>
								</RoomContingentForm>
							</Card>
						}
					/>
					<Route path="rooms" element={<RoomList />} />
				</Route>
			</Routes>
		</>
	);
};
