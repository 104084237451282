import { IconButton, SelectedRows, apiIsOK, useBulkEdit, useToasts } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { invoiceService } from "~admin/shared/api/shop/invoices/invoiceService";

interface Props {
	setDrawerValues: (selectedRows: SelectedRows) => void;
}

export const InvoiceListBulkEditAction: FC<Props> = ({ setDrawerValues }) => {
	const { t } = useTranslation();
	const { selectedRows, availableItemIds } = useBulkEdit();
	const { showToast } = useToasts();

	const exportInvoices = useCallback(async () => {
		const response = await invoiceService.export({
			invoiceIds: selectedRows === "all" ? [] : selectedRows,
		});

		if (apiIsOK(response)) {
			showToast({ type: "success", title: t("Export"), body: t("An export is being created, please wait.") });
		}
	}, [selectedRows, showToast, t]);

	return (
		<>
			<IconButton
				title={t("Send email")}
				size="small"
				onClick={() => setDrawerValues(selectedRows)}
				disabled={selectedRows.length === 0 || availableItemIds.length === 0}
				icon="send"
			/>
			<IconButton
				onClick={exportInvoices}
				title={t("Download invoices")}
				size="small"
				icon="file_download"
				disabled={selectedRows.length === 0 || availableItemIds.length === 0}
			/>
		</>
	);
};
