import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { UserCreate } from "~admin/users/UserCreate";
import { UserEdit } from "./UserEdit";
import { UserList } from "./UserList";

export const UsersPage: FC = () => {
	return (
		<Routes>
			<Route index element={<UserList />} />
			<Route path="new" element={<UserCreate />} />
			<Route path=":userId" element={<UserEdit />} />
		</Routes>
	);
};
