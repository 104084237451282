import { PagedMultiSelectField } from "@dgs/core";
import React, { FC } from "react";
import { personalizedDocumentKeys, personalizedDocumentService } from "~shared/api/personalizedDocuments";

interface Props {
	name: string;
	label?: string;
	disabled?: boolean;
	required?: boolean;
	className?: string;
	placeholder?: string;
}

const fetcher = (search: string, page: number) => personalizedDocumentService.options(search, page).then((r) => r.data);

export const PersonalizedDocumentMultiSelect: FC<Props> = ({
	name,
	label = "",
	disabled,
	required,
	className,
	placeholder,
}) => {
	return (
		<PagedMultiSelectField
			label={label}
			name={name}
			disabled={disabled}
			fetcher={fetcher}
			queryKey={personalizedDocumentKeys.options}
			getLabel={(o) => o.name}
			required={required}
			className={className}
			clearable={true}
			placeholder={placeholder}
		/>
	);
};
