import React, { FC, useCallback } from "react";
import { Link, useMatch, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IRegistrationFormIndex } from "~shared/types/registrationForm";
import { RegistrationFormSidebarContextMenu } from "./RegistrationFormSidebarContextMenu";

const StyledLink = styled(Link)`
	display: block;
	color: inherit;
	text-decoration: none;
	padding: 0.5rem 0;
	overflow-wrap: anywhere;
`;

const LinkWrapper = styled.span`
	padding: 0 0.75rem 0 1.25rem;
	display: grid;
	align-items: center;
	grid-template-columns: 1fr max-content;
	gap: 1rem;

	:hover,
	&.active {
		background: ${({ theme }) => theme.colors.sidebar.itemHover};
	}

	&.active {
		color: ${({ theme }) => theme.colors.sidebar.itemActive};
	}
`;

interface RegistrationFormLinkProps {
	registrationForm: IRegistrationFormIndex;
}

export const RegistrationFormLink: FC<RegistrationFormLinkProps> = ({ registrationForm }) => {
	const match = useMatch({ path: `/admin/registration-forms/${registrationForm.id}`, end: false });
	const navigate = useNavigate();
	const onAfterDelete = useCallback(() => navigate("/admin/registration-forms"), [navigate]);

	return (
		<LinkWrapper className={match ? "active" : ""}>
			<StyledLink to={registrationForm.id}>{registrationForm.name}</StyledLink>
			{match ? (
				<RegistrationFormSidebarContextMenu registrationForm={registrationForm} onAfterDelete={onAfterDelete} />
			) : (
				<RegistrationFormSidebarContextMenu registrationForm={registrationForm} />
			)}
		</LinkWrapper>
	);
};
