import { Card, CardBody, CardFooter, CardHeader, LinkButton, SubmitButton } from "@dgs/core";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IRoomContingentForm } from "~shared/types/roomContingent";
import { IRoomTypeResource } from "~shared/types/roomType";
import { toISODateOnlyString } from "~shared/utils/dateUtils";
import { RoomContingentForm, RoomContingentFormFields } from "./RoomContingentForm";
import { useRoomContingentForm } from "./useRoomContingentForm";

interface Props {
	roomType: IRoomTypeResource;
}

export const RoomContingentCreatePage: FC<Props> = ({ roomType }) => {
	const { t } = useTranslation();
	const { hotelId = "", roomTypeId = "" } = useParams();
	const { createRoomContingent } = useRoomContingentForm();
	const handleSubmit = useCallback(
		(roomContingent: IRoomContingentForm) => createRoomContingent(hotelId, roomTypeId, roomContingent),
		[hotelId, roomTypeId, createRoomContingent],
	);

	const emptyRoomContingent: IRoomContingentForm = useMemo(
		() => ({
			date: toISODateOnlyString(new Date()),
			overbookable: false,
			contingent: 0,
			prices: Array.from({ length: roomType.capacity }, (_, index) => index + 1).map((occupancy) => ({
				occupancy,
				purchasePrice: 0,
				sellingPrice: 0,
			})),
		}),
		[roomType.capacity],
	);

	return (
		<Card>
			<RoomContingentForm onSubmit={handleSubmit} roomContingent={emptyRoomContingent} roomType={roomType}>
				<CardHeader>{t("Create room contingent")}</CardHeader>
				<CardBody>
					<RoomContingentFormFields />
				</CardBody>
				<CardFooter>
					<LinkButton to="./..">{t("Back")}</LinkButton>
					<SubmitButton>{t("Save")}</SubmitButton>
				</CardFooter>
			</RoomContingentForm>
		</Card>
	);
};
