import { DrawerAutoDataList, IDrawerDataListColumn, useCrudEntity } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { invoiceProviderService } from "~admin/shared/api/invoices";
import { InvoiceProviderDrawer, emptyInvoiceProvider } from "~admin/shop/invoiceProviders/InvoiceProviderDrawer";
import { InvoiceProviderListContextMenu } from "~admin/shop/invoiceProviders/InvoiceProviderListContextMenu";
import {
	IInvoiceProviderFormState,
	IInvoiceProviderIndexResource,
	IInvoiceProviderUpdateRequest,
} from "~shared/types/invoiceProvider";

const mapFormStateToRequest = (invoiceProviderFormState: IInvoiceProviderFormState): IInvoiceProviderUpdateRequest =>
	({
		...invoiceProviderFormState,
		merchantId: invoiceProviderFormState.merchant?.id,
		countryIds: invoiceProviderFormState.countries?.map((i) => i.id),
	}) as IInvoiceProviderUpdateRequest;

export const InvoiceProviderList: FC = () => {
	const { t } = useTranslation();

	const crudOperations = useCrudEntity(invoiceProviderService, {
		entityName: t("Invoice provider"),
		messages: {
			create: t("Invoice provider was saved."),
			update: t("Invoice provider was saved."),
			delete: t("Invoice provider was successfully deleted."),
		},
	});

	const dataListColumns: IDrawerDataListColumn<IInvoiceProviderIndexResource>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: t("Type"),
				valueKey: "type",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				action: (paymentProvider: IInvoiceProviderIndexResource, openDrawer) => (
					<InvoiceProviderListContextMenu
						handleEdit={openDrawer}
						handleDelete={() => crudOperations.deleteEntity(paymentProvider.id)}
					/>
				),
			},
		],
		[crudOperations, t],
	);

	return (
		<DrawerAutoDataList
			translations={{
				title: t("Invoice providers"),
				empty: t("No invoice providers available"),
			}}
			renderCreateDrawer={(props) => (
				<InvoiceProviderDrawer
					{...props}
					heading={t("Create invoice provider")}
					initialValues={emptyInvoiceProvider as IInvoiceProviderFormState}
					onSubmit={(entity) => crudOperations.createEntity(mapFormStateToRequest(entity))}
				/>
			)}
			renderUpdateDrawer={(resource, props) => (
				<InvoiceProviderDrawer
					{...props}
					heading={t("Edit invoice provider")}
					initialValues={resource}
					onSubmit={(formState) => crudOperations.updateEntity(resource.id, mapFormStateToRequest(formState))}
				/>
			)}
			columns={dataListColumns}
			service={invoiceProviderService}
		/>
	);
};
