import { ContextMenu, ContextMenuItem, Id, useCrudEntity } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { guestFilterKeys } from "~shared/api/guestFilters";
import { waveService } from "~shared/api/waves/waveService";
import { DeleteModelWithUsagesDialog, useModelUsage } from "~shared/ui/modelUsage/DeleteModelWithUsagesDialog";

interface Props {
	id: Id;
	openEditDrawer: (id: Id) => void;
}

export const WaveContextMenu: FC<Props> = ({ id, openEditDrawer }) => {
	const { handleUsage, ...usageModalProps } = useModelUsage(() => waveService.showUsage(id));
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const crudOperations = useCrudEntity(
		waveService,
		{
			entityName: t("Wave"),
			messages: {
				create: t("Wave was saved."),
				update: t("Wave was saved."),
				delete: t("Wave was deleted."),
			},
		},
		[waveService.keys.getContingentOverview, waveService.keys.list],
	);

	return (
		<>
			<ContextMenu displayShortcutsAs="icon">
				<ContextMenuItem
					title={t("Edit wave")}
					label={t("Edit wave")}
					action={() => openEditDrawer(id)}
					icon="edit"
					shortcut="primary"
				/>
				<ContextMenuItem action={handleUsage} title={t("Delete wave")} label={t("Delete wave")} icon="delete" />
			</ContextMenu>
			<DeleteModelWithUsagesDialog
				{...usageModalProps}
				usagePrefix={t("This wave")}
				confirmationBody={t("Are you sure you want to delete this wave?")}
				deleteAction={async () => {
					const isOk = await crudOperations.deleteEntity(id);
					if (isOk) {
						await queryClient.resetQueries({ queryKey: guestFilterKeys.index });
					}
				}}
			/>
		</>
	);
};
