import { Id, MultiChangeEvent, PagedMultiSelectControl } from "@dgs/core";
import React, { FC } from "react";
import { guestService } from "~shared/api/guests/guestService";
import { Guest } from "~shared/types/guest";

interface Props {
	name: string;
	label: string;
	value: Guest[];
	onChange: (e: MultiChangeEvent<Guest>) => void;
	error?: string;
	disabled?: boolean;
	required?: boolean;
	exclude?: Id[];
}

const getLabel = (guest: Guest) => `${guest.firstName} ${guest.lastName} (${guest.email})`;

export const GuestMultiSelect: FC<Props> = ({
	name,
	label,
	value,
	onChange,
	error,
	disabled = false,
	required = false,
	exclude = [],
}) => {
	return (
		<PagedMultiSelectControl
			name={name}
			label={label}
			queryKey={["guests", ...exclude]}
			value={value}
			onChange={onChange}
			error={error}
			required={required}
			disabled={disabled}
			getLabel={getLabel}
			fetcher={(search, page) => guestService.options(exclude, { params: { search, page } }).then((r) => r.data)}
			clearable={true}
		/>
	);
};
