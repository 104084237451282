import { AutoDataList, Id } from "@dgs/core";
import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { hotelInquiryService } from "~shared/api/hotelInquiries";
import { useHotelInquiriesListColumns } from "./HotelInquiriesListColumns";
import { HotelInquiryConflictDrawer } from "./HotelInquiryConflictDrawer";

export const ConflictsHotelInquiriesList: FC = () => {
	const { t } = useTranslation();
	const columns = useHotelInquiriesListColumns();
	const [hotelInquiryId, setHotelInquiryId] = useState<Id | null>(null);

	return (
		<>
			<AutoDataList
				heading={t("Conflicting hotel inquiries")}
				empty={t("No hotel requests available.")}
				columns={columns}
				queryKey={hotelInquiryService.keys.conflicts}
				fetcher={hotelInquiryService.conflicts}
				onRowClick={({ id }) => setHotelInquiryId(id)}
			/>
			{hotelInquiryId && (
				<HotelInquiryConflictDrawer
					key={hotelInquiryId}
					hotelInquiryId={hotelInquiryId}
					onClose={() => setHotelInquiryId(null)}
				/>
			)}
		</>
	);
};
