import { Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { ImportError } from "~shared/types/guestImport";
import { importKeys } from "./importKeys";
import { importService } from "./importService";

const initialData: ImportError[] = [];
export const useGuestImportErrorsQuery = (guestImportId: Id, guestImportErrorId: Id) => {
	const { data, isLoading } = useQuery({
		staleTime: 0,
		queryKey: importKeys.guests.errors.details(guestImportId, guestImportErrorId),
		queryFn: () => importService.guests.errors.details(guestImportId, guestImportErrorId).then((r) => r.data.data),
	});

	return {
		data: data || initialData,
		isLoading,
	};
};
