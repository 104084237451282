import { useDynamicConfig } from "@dgs/core";
import React, { FC, createContext, useContext, useEffect, useState } from "react";

export interface ContentLocaleState {
	fallbackLocale: string;
	locales: string[];
}

export const ContentLocaleContext = createContext<ContentLocaleState | undefined>(undefined);

interface Props {
	children: (initialized: boolean) => React.ReactNode;
}

export const ContentLocaleProvider: FC<Props> = ({ children }) => {
	const [initialized, setInitialized] = useState(false);
	const [fallbackLocale, setFallbackLocale] = useState<string>("");
	const [locales, setLocales] = useState<string[]>([]);
	const config = useDynamicConfig();

	useEffect(() => {
		if (config.language !== "") {
			setLocales(config.contentLocales);
			setFallbackLocale(config.language);
			setInitialized(true);
		}
	}, [config.contentLocales, config.language]);

	return (
		<ContentLocaleContext.Provider
			value={{
				fallbackLocale,
				locales: locales,
			}}
		>
			{children(initialized && fallbackLocale !== null)}
		</ContentLocaleContext.Provider>
	);
};

export const useContentLocale = () => {
	const context = useContext(ContentLocaleContext);

	if (context === undefined) {
		throw new Error(`ContentLocaleContext undefined. Are you missing a Provider?`);
	}

	return context;
};
