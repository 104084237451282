import {
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerHeading,
	Id,
	RenderProps,
	SubmitButton,
	apiIsOK,
	useToasts,
} from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { personalizedDocumentService } from "~shared/api/personalizedDocuments";
import { LocaleSelect } from "~shared/selects/LocaleSelect";
import { PersonalizedDocumentSingleSelect } from "~shared/selects/PersonalizedDocumentSingleSelect";
import { PersonalizedDocumentOptionsResource } from "~shared/types/personalizedDocument";

interface Props extends RenderProps {
	guestIds: Id[];
}

interface Values {
	personalizedDocument: PersonalizedDocumentOptionsResource | null;
	locale: string;
}

const initialValues: Values = {
	personalizedDocument: null,
	locale: "",
};

export const ExportPersonalizedDocumentsDrawer: FC<Props> = ({ guestIds, handleClose, open }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();

	const onSubmit = useCallback(
		async (values: Values) => {
			const response = await personalizedDocumentService.exportDocuments({
				guestIds,
				personalizedDocumentId: values.personalizedDocument?.id as Id,
				locale: values.locale,
			});

			if (apiIsOK(response)) {
				showToast({ type: "success", title: t("Export"), body: t("An export is being created, please wait.") });
				handleClose();
			}
		},
		[guestIds, handleClose, showToast, t],
	);

	const validate = useCallback(
		async (values: Values) => {
			const errors: { [K in keyof Values]?: string } = {};

			if (!values.personalizedDocument) {
				errors.personalizedDocument = t("Required field");
			}

			if (!values.locale) {
				errors.locale = t("Required field");
			}

			return errors;
		},
		[t],
	);

	return (
		<Drawer open={open} onClose={handleClose}>
			<DrawerHeader onClose={handleClose}>
				<DrawerHeading>{t("Export personalized documents")}</DrawerHeading>
			</DrawerHeader>
			<Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
				<Form>
					<DrawerBody>
						<PersonalizedDocumentSingleSelect name="personalizedDocument" label={t("Personalized document")} />
						<LocaleSelect name="locale" label={t("Language")} />
					</DrawerBody>
					<DrawerFooter>
						<SubmitButton>{t("Export")}</SubmitButton>
					</DrawerFooter>
				</Form>
			</Formik>
		</Drawer>
	);
};
