import { ContextMenu, ContextMenuDeleteItem, ContextMenuItem } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
	handleEdit: () => void;
	handleDelete: () => void;
}

export const MerchantContextMenu: FC<Props> = ({ handleEdit, handleDelete }) => {
	const { t } = useTranslation();

	return (
		<ContextMenu displayShortcutsAs="icon">
			<ContextMenuItem label={t("Edit")} action={handleEdit} title={t("Edit")} icon="edit" shortcut="primary" />
			<ContextMenuDeleteItem
				action={handleDelete}
				label={t("Delete")}
				title={t("Delete")}
				heading={t("Delete")}
				labels={{ close: t("Close"), confirm: t("Confirm") }}
			>
				{t("Are you sure you want top delete this merchant?")}
			</ContextMenuDeleteItem>
		</ContextMenu>
	);
};
