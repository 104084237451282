import { BlockViewProps, H1, H2, H3, H4, H5, H6 } from "@dgs/core";
import React, { ElementType, FC } from "react";
import { IEmailHeadlineBlockShowResource } from "./emailHeadlineBlockTypes";

const sizeToHeadline: Record<number, ElementType> = {
	1: H1,
	2: H2,
	3: H3,
	4: H4,
	5: H5,
	6: H6,
};

export const EmailHeadlineBlockView: FC<BlockViewProps<IEmailHeadlineBlockShowResource>> = ({ block }) => {
	const Component = sizeToHeadline[block.value.size];
	if (!Component) {
		return null;
	}

	return <Component>{block.translatedValue.headline}</Component>;
};
