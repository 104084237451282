import { Card, CardBody, CardHeader, CardHeading, Empty, Loading } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { DataFieldContingentOverviewDataList } from "~admin/dataFields/dataFieldOptions/DataFieldContingentOverviewDataList";
import { useDataFieldContingentQuery } from "~shared/api/dataFields/dataFieldQueries";

const Wrapper = styled.div`
	display: grid;
	grid-auto-rows: max-content;
	gap: ${({ theme }) => theme.spacing(5)};
`;

const EmptyBody = styled(CardBody)`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 300px;
`;

export const DataFieldContingentOverview: FC = () => {
	const { t } = useTranslation();
	const { dataFieldContingents, isLoading } = useDataFieldContingentQuery();

	if (isLoading) {
		return (
			<Card>
				<CardBody>
					<Loading />
				</CardBody>
			</Card>
		);
	}

	if (!dataFieldContingents.length) {
		return (
			<Card>
				<CardHeader>
					<CardHeading>{t("Data field contingent overview")}</CardHeading>
				</CardHeader>
				<EmptyBody>
					<Empty>{t("No data fields with contingent found.")}</Empty>
				</EmptyBody>
			</Card>
		);
	}

	return (
		<Wrapper>
			{dataFieldContingents.map((contingent) => (
				<DataFieldContingentOverviewDataList key={contingent.id} dataFieldContingent={contingent} />
			))}
		</Wrapper>
	);
};
