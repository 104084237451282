import { Card, CardBody, CheckboxField, DateField, DateTimeField, TextField, TimeField } from "@dgs/core";
import { useFormikContext } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { SectionDataFieldSelect } from "~admin/shared/selects/DataFieldPagedSingleSelect";
import { emptyRule } from "~admin/shared/utils";
import { ActionFormState } from "~shared/types/actions";
import { DataFieldType } from "~shared/types/dataFieldType";
import { IRegistrationFormDataField } from "~shared/types/registrationFormChildDataField";
import { RuleFormState, RuleTypes } from "~shared/types/rules";
import { RuleSet, RuleSetType } from "~shared/types/ruleSet";
import { RuleSelect } from "../input/RuleSelect";
import { DataFieldOptionMultiSelect, DataFieldOptionSingleSelect } from "./DataFieldOptionSelect";
import { AddRuleOrActionButton } from "./rules/AddRuleOrActionButton";
import { RemoveRuleOrActionButton } from "./rules/RemoveRuleButton";
import { RuleAndOrIndicator } from "./rules/RuleAndOrIndicator";
import { RuleList, RuleSetFooter } from "./ruleSetFormComponents";
import { ValueCreatableMultiSelect } from "./ValueCreatableMultiSelect";

interface Props {
	registrationFormId: string;
	ruleSetType: RuleSetType;
}

export const RuleSetFormRules: FC<Props> = ({ registrationFormId, ruleSetType }) => {
	const { t } = useTranslation();
	const { values, setFieldValue } = useFormikContext<RuleSet<RuleFormState, ActionFormState>>();

	return (
		<Card $color="content">
			<CardBody>
				<RuleList>
					{values.rules.map((rule, index) => (
						<React.Fragment key={`rule-${index}`}>
							<RuleAndOrIndicator index={index} isAndLinked={values.isAndLinked} />
							<SectionDataFieldSelect
								name={`rules.${index}.sectionDataField`}
								label={t("Data field")}
								formId={registrationFormId}
								onChange={(e) => {
									void setFieldValue(`rules.${index}.type`, null);
									void setFieldValue(
										`rules.${index}.value`,
										ResolveInitialValue(e.target.value?.dataField ?? null, rule.type),
									);
								}}
								isAction={false}
							/>
							<RuleSelect
								name={`rules.${index}.type`}
								dataField={values.rules[index].sectionDataField?.dataField ?? null}
								label={t("Rule type")}
								rulesetType={ruleSetType}
								onChange={(e) => {
									void setFieldValue(
										`rules.${index}.value`,
										ResolveInitialValue(rule.sectionDataField?.dataField ?? null, e.target.value),
									);
								}}
							/>
							<RuleValueField name={`rules.${index}.value`} rule={rule} />
							<RemoveRuleOrActionButton index={index} type="rules" />
						</React.Fragment>
					))}
					{!values.rules.length && <span>{t("No rules existent")}</span>}
				</RuleList>
			</CardBody>
			<RuleSetFooter>
				<AddRuleOrActionButton initialValue={emptyRule} type="rules" />
			</RuleSetFooter>
		</Card>
	);
};

export const RuleValueField: FC<{ name: string; rule: RuleFormState }> = ({ name, rule }) => {
	const { t } = useTranslation();

	switch (rule.type) {
		case RuleTypes.IS_REQUIRED:
		case RuleTypes.NOT_UPDATABLE:
		case RuleTypes.READ_ONLY:
			return <div />;
		case RuleTypes.HAS_MAX_CHARS:
		case RuleTypes.HAS_MIN_CHARS:
			return <TextField name={name} label={t("Value")} type="number" />;
		case RuleTypes.NOT_CONTAINS_ONE:
		case RuleTypes.CONTAINS_ONE:
			return <ValueCreatableMultiSelect rule={rule} name={name} label={t("Value")} />;
		case RuleTypes.HAS_VALUE:
		case RuleTypes.HAS_NOT_VALUE:
		case RuleTypes.MIN:
		case RuleTypes.MAX:
		case RuleTypes.MUST_CONTAIN:
		case RuleTypes.MUST_NOT_CONTAIN: {
			switch (rule.sectionDataField?.dataField.type) {
				case DataFieldType.SELECT_FIELD:
				case DataFieldType.RADIO:
					return (
						<DataFieldOptionSingleSelect
							name={name}
							label={t("Value")}
							dataFieldId={rule.sectionDataField?.dataField.id}
						/>
					);
				case DataFieldType.CHECKBOX_GROUP:
				case DataFieldType.MULTI_SELECT_FIELD:
					if ([RuleTypes.MIN, RuleTypes.MAX].includes(rule.type)) {
						return <TextField name={name} label={t("Value")} type="number" />;
					}
					return (
						<DataFieldOptionMultiSelect
							name={name}
							label={t("Value")}
							dataFieldId={rule.sectionDataField?.dataField.id}
						/>
					);
				case DataFieldType.CHECKBOX:
					return <CheckboxField name={name} label={t("Value")} />;
				case DataFieldType.DATE:
					return <DateField label={t("Value")} name={name} />;
				case DataFieldType.DATETIME:
					return <DateTimeField label={t("Value")} name={name} />;
				case DataFieldType.TIME:
					return <TimeField label={t("Value")} name={name} />;
			}
			return <TextField name={name} label={t("Value")} />;
		}
	}
	return <div />;
};

const ResolveInitialValue = (dataField: IRegistrationFormDataField | null, ruleType: RuleTypes | null) => {
	if (dataField === null) {
		return "";
	}

	switch (ruleType) {
		case RuleTypes.IS_REQUIRED:
		case RuleTypes.NOT_UPDATABLE:
		case RuleTypes.READ_ONLY:
			return "";
		case RuleTypes.HAS_MAX_CHARS:
		case RuleTypes.HAS_MIN_CHARS:
			return null;
		case RuleTypes.NOT_CONTAINS_ONE:
		case RuleTypes.CONTAINS_ONE:
			return [];
	}

	switch (dataField.type) {
		case DataFieldType.CHECKBOX_GROUP:
		case DataFieldType.MULTI_SELECT_FIELD:
			return [];
		case DataFieldType.SELECT_FIELD:
		case DataFieldType.DATE:
		case DataFieldType.DATETIME:
			return null;
	}

	return "";
};
