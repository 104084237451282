import { Id, MultiSelectField, SingleSelectField } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import React, { FC, useMemo } from "react";
import { DataFieldOptionService } from "~shared/api/dataFields";
import { IDataFieldOptionOptionResource } from "~shared/types/dataFieldOption";

interface Props {
	name: string;
	label: string;
	dataFieldId: Id;
}

const getLabel = (dataFieldOption: IDataFieldOptionOptionResource) => dataFieldOption.value;

const useAllOptionsQuery = (dataFieldId: Id) => {
	const service = useMemo(() => new DataFieldOptionService(dataFieldId.toString()), [dataFieldId]);

	return useQuery({
		queryKey: service.keys.index,
		queryFn: () => service.indexAll().then((response) => response.data.data),
	});
};

export const DataFieldOptionSingleSelect: FC<Props> = ({ name, label, dataFieldId }) => {
	const { data: allOptions } = useAllOptionsQuery(dataFieldId);

	return <SingleSelectField name={name} label={label} getLabel={getLabel} options={allOptions || []} />;
};

export const DataFieldOptionMultiSelect: FC<Props> = ({ name, label, dataFieldId }) => {
	const { data: allOptions } = useAllOptionsQuery(dataFieldId);

	return <MultiSelectField name={name} label={label} getLabel={getLabel} options={allOptions || []} />;
};
