import { Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { IRegistrationFormSectionDataField } from "~shared/types/registrationFormChildDataField";
import { IRegistrationFormSection, IRegistrationFormSectionRequest } from "~shared/types/section";

export class RegistrationFormSectionService {
	public keys = {
		index: (registrationFormId: Id, stepId: Id) => [
			"registration-forms",
			`${registrationFormId}`,
			"steps",
			`${stepId}`,
			"sections",
		],
		details: (registrationFormId: Id, stepId: Id, sectionId: Id) => [
			"registration-forms",
			`${registrationFormId}`,
			"steps",
			`${stepId}`,
			"sections",
			`${sectionId}`,
		],
		showValidation: (registrationFormId: Id, stepId: Id, sectionId: Id, dataFieldId: Id) => [
			"registration-forms",
			`${registrationFormId}`,
			"steps",
			`${stepId}`,
			"sections",
			`${sectionId}`,
			"validations",
			`${dataFieldId}`,
		],
	};

	protected urls = {
		index: (registrationFormId: Id, stepId: Id) => keyToUrl(this.keys.index(registrationFormId, stepId)),
		details: (registrationFormId: Id, stepId: Id, sectionId: Id) =>
			keyToUrl(this.keys.details(registrationFormId, stepId, sectionId)),
		showValidation: (registrationFormId: Id, stepId: Id, sectionId: Id, dataFieldId: Id) =>
			keyToUrl(this.keys.showValidation(registrationFormId, stepId, sectionId, dataFieldId)),
	};

	store(registrationFormId: Id, stepId: Id, formValues: IRegistrationFormSectionRequest) {
		return axios.post<LaravelResponse<{ id: Id }>>(this.urls.index(registrationFormId, stepId), formValues);
	}

	show(registrationFormId: Id, stepId: Id, sectionId: Id) {
		return axios.get<LaravelResponse<IRegistrationFormSection>>(
			this.urls.details(registrationFormId, stepId, sectionId),
		);
	}

	update(registrationFormId: Id, stepId: Id, sectionId: Id, formValues: IRegistrationFormSectionRequest) {
		return axios.put<void>(this.urls.details(registrationFormId, stepId, sectionId), formValues);
	}

	delete(registrationFormId: Id, stepId: Id, sectionId: Id) {
		return axios.delete<void>(this.urls.details(registrationFormId, stepId, sectionId));
	}

	showValidation(registrationFormId: Id, stepId: Id, sectionId: Id, dataFieldId: Id) {
		return axios.get<LaravelResponse<IRegistrationFormSectionDataField>>(
			this.urls.showValidation(registrationFormId, stepId, sectionId, dataFieldId),
		);
	}
}

export const registrationFormSectionService = new RegistrationFormSectionService();
