import {
	DynamicConfigProvider,
	FCC,
	RootContainer,
	ToastList,
	ToastProvider,
	createTenantComponentsColors,
	createTenantComponentsTheme,
	defaultRemoteConfigKey,
	getDefaultTheme,
} from "@dgs/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createGlobalStyle } from "styled-components";
import { PMAuthProvider } from "~admin/auth";
import { config } from "~shared/config";
import { PMLocaleProvider } from "~shared/providers/PMLocaleProvider";

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { staleTime: 100000, retry: false },
	},
});

const GlobalStyles = createGlobalStyle`
  ${({ theme }) => theme.global}

  ;
`;

export const AdminProviders: FCC = ({ children }) => {
	const baseTheme = getDefaultTheme(config.mode);
	const theme = createTenantComponentsTheme(baseTheme, createTenantComponentsColors(baseTheme.colors));

	return (
		<QueryClientProvider client={queryClient}>
			<DynamicConfigProvider remoteConfigKey={defaultRemoteConfigKey}>
				<PMAuthProvider emailRedirectBaseUrl={`${window.origin}/admin`}>
					<ThemeProvider theme={theme}>
						<ToastProvider>
							<GlobalStyles />
							<RootContainer>
								<ToastList />
								{children}
							</RootContainer>
						</ToastProvider>
					</ThemeProvider>
				</PMAuthProvider>
			</DynamicConfigProvider>
		</QueryClientProvider>
	);
};

export const AppProviders: FCC = ({ children }) => {
	return (
		<BrowserRouter>
			<PMLocaleProvider>{children}</PMLocaleProvider>
		</BrowserRouter>
	);
};
