import { Loading } from "@dgs/core";
import React, { FC } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useEmailLogDetailsQuery } from "~shared/api/emails/emailQueries";
import { EmailLogDetailsContent } from "./EmailLogDetailsContent";

const LoadingWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export const EmailLogDetails: FC = () => {
	const { logId = "" } = useParams<"logId">();
	const { data, isLoading } = useEmailLogDetailsQuery(logId);

	if (isLoading)
		return (
			<LoadingWrapper>
				<Loading />
			</LoadingWrapper>
		);

	if (!data) return null;

	return <EmailLogDetailsContent emailLog={data} />;
};
