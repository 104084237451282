import {
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	CardHeading,
	CheckboxField,
	PagedSingleSelectField,
	SubmitButton,
} from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { dataFieldService } from "~shared/api/dataFields";
import { DataFieldSettings } from "~shared/types/dataField";
import { DataFieldType } from "~shared/types/dataFieldType";
import { FormikWithRedirect } from "~shared/ui/FormikWithRedirect";

interface Props {
	initialData: DataFieldSettings;
	onSubmit: (settings: DataFieldSettings) => Promise<boolean>;
}

export const DataFieldSettingsForm: FC<Props> = ({ initialData, onSubmit }) => {
	const { t } = useTranslation();

	const dataFieldFetcher = (type: DataFieldType) => (search: string, page: number) =>
		dataFieldService.options(search, page, { type }).then((r) => r.data);

	const validate = useCallback(
		(values: DataFieldSettings) => {
			const errors: { [K in keyof DataFieldSettings]?: any } = {};

			if (!values.email) {
				errors.email = t("Required field");
			}
			if (!values.firstName) {
				errors.firstName = t("Required field");
			}
			if (!values.lastName) {
				errors.lastName = t("Required field");
			}
			if (!values.salutation) {
				errors.salutation = t("Required field");
			}
			if (values.email && values.ccEmail && values.email.id === values.ccEmail.id) {
				errors.email = t("Email and CC email field cannot contain the same value");
				errors.ccEmail = t("Email and CC email field cannot contain the same value");
			}

			return errors;
		},
		[t],
	);

	return (
		<FormikWithRedirect initialValues={initialData} onSubmit={onSubmit} validate={validate}>
			<Card>
				<CardHeader>
					<CardHeading>{t("Settings")}</CardHeading>
				</CardHeader>
				<CardBody>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.EMAIL)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.EMAIL)}
						label={t("Recipient email")}
						name="email"
						required
					/>
					<CheckboxField label={t("Recipient email must be unique")} name="uniqueEmail"></CheckboxField>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.TEXT)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.TEXT)}
						label={t("Recipient first name")}
						name="firstName"
						required
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.TEXT)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.TEXT)}
						label={t("Recipient last name")}
						name="lastName"
						required
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.SELECT_FIELD)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.SELECT_FIELD)}
						label={t("Recipient salutation")}
						name="salutation"
						required
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.SELECT_FIELD)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.SELECT_FIELD)}
						label={t("Academic title data field")}
						name="academicTitle"
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.EMAIL)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.EMAIL)}
						label={t("CC email field")}
						name="ccEmail"
					/>
					<PagedSingleSelectField
						fetcher={dataFieldFetcher(DataFieldType.DATE)}
						queryKey={dataFieldService.keys.options.concat(DataFieldType.DATE)}
						label={t("Date of birth")}
						name="dateOfBirth"
					/>
				</CardBody>
				<CardFooter>
					<SubmitButton>{t("Save")}</SubmitButton>
				</CardFooter>
			</Card>
		</FormikWithRedirect>
	);
};
