import { CardFooter, LinkButton, SubmitButton } from "@dgs/core";
import React, { FC, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEmptyHotel } from "~admin/hotels/useEmptyHotel";
import { IRoomTypeStepperResource } from "~shared/types/roomType";
import { FormikHelpersWithRedirect } from "~shared/ui/FormikWithRedirect";
import { useHotelStepper } from "./HotelStepperProvider";
import { HotelStepperRoomTypeForm } from "./HotelStepperRoomTypeForm";

export const HotelStepperRoomTypePage: FC = () => {
	const { hotel, roomType, setRoomType } = useHotelStepper();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const emptyHotel = useEmptyHotel();
	const onSubmit = useCallback(
		async (roomType: IRoomTypeStepperResource, helpers: FormikHelpersWithRedirect<IRoomTypeStepperResource>) => {
			setRoomType(roomType);
			helpers.setTo("../details");
			return true;
		},
		[setRoomType],
	);

	useEffect(() => {
		if (hotel === emptyHotel) {
			navigate("..");
		}
	}, [emptyHotel, hotel, navigate]);

	return (
		<HotelStepperRoomTypeForm
			title={t("Room type master data")}
			onSubmit={onSubmit}
			roomType={roomType}
			footer={<RoomTypeFormFooter />}
		/>
	);
};

const RoomTypeFormFooter: FC = () => {
	const { t } = useTranslation();

	return (
		<CardFooter>
			<LinkButton type="button" to="..">
				{t("Back")}
			</LinkButton>
			<SubmitButton>{t("Next")}</SubmitButton>
		</CardFooter>
	);
};
