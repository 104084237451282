import { Card, CardBody } from "@dgs/core";
import React, { FC } from "react";
import { StructureStep } from "~shared/types/registrationFormTypes";
import { ActiveCard } from "../common/ActiveStructureCard";
import { DragIndicator } from "../common/DragIndicator";
import { StepHeader } from "./StepHeader";

interface Props {
	registrationFormId: string;
	step: StructureStep;
	children: React.ReactNode;
	isActive?: boolean;
	withIndicator?: boolean;
}

export const Step: FC<Props> = ({ registrationFormId, step, children, isActive = false, withIndicator = false }) => {
	const StepCard = isActive ? ActiveCard : Card;

	return (
		<StepCard $color="background">
			<StepHeader registrationFormId={registrationFormId} step={step} indicator={withIndicator && <DragIndicator />} />
			{<CardBody>{children}</CardBody>}
		</StepCard>
	);
};
