import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { emptyUserValues } from "~admin/users/user";
import { UserForm } from "./UserForm";
import { useUserForm } from "./useUserForm";

export const UserCreate: FC = () => {
	const { t } = useTranslation();

	const { createUser } = useUserForm();

	return <UserForm title={t("Create user")} user={emptyUserValues} handleSubmit={createUser} />;
};
