import { Id, SingleSelectControl } from "@dgs/core";
import { useFormikContext } from "formik";
import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useGuestFilter } from "./GuestFilterProvider";
import { GuestFilterFormValues, GuestViewAttributeOption } from "./guestFilterTypes";

interface Props {
	onSelect: (id: Id) => void;
}

export const GuestFilterAttributeSelect: FC<Props> = ({ onSelect }) => {
	const { t } = useTranslation();
	const [search, setSearch] = useState("");
	const { options } = useGuestFilter();
	const { values } = useFormikContext<GuestFilterFormValues>();
	const usedAttributes = useMemo(() => values.filters.map((x) => x.guestAttributeId), [values.filters]);
	const filteredOptions = useMemo(() => {
		return options.filter((o) => o.name.toLowerCase().includes(search.toLowerCase()) && !usedAttributes.includes(o.id));
	}, [options, search, usedAttributes]);

	return (
		<SingleSelectControl
			onChange={(e) => e.target.value && onSelect(e.target.value.id)}
			label={t("Guest attribute")}
			value={null as GuestViewAttributeOption | null}
			name="attributeSelect"
			onClear={() => void 0}
			options={filteredOptions}
			getLabel={(o: GuestViewAttributeOption) => o.name}
			search={search}
			onSearch={setSearch}
		/>
	);
};
