import { AutoDataList, IDataListColumn, IconButton, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { waitingListKeys, waitingListService } from "~admin/shared/api/waitingList";
import { guestKeys, guestService } from "~shared/api/guests";
import { ActiveGuestFilterEntries, useGuestFilter } from "~shared/guestFilters";
import { useGuestListUtils } from "~shared/guests";
import { LocalGuestViewSwitcher } from "~shared/guests/GuestViewSwitchers";
import { useGuestView } from "~shared/providers/useGuestView";
import { GuestIndexResource } from "~shared/types/newGuestTypes";

export const WaitingListGuestList: FC = () => {
	const { id = "" } = useParams<"id">();
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { currentGuestView } = useGuestView();
	const { columns: guestColumns } = useGuestListUtils(currentGuestView);
	const { filters, handleOpen, mapGuestFilterToGuestFilterRequest } = useGuestFilter();

	const registerGuest = useCallback(
		async (guest: GuestIndexResource) => {
			const response = await guestService.register(guest.id);

			if (apiIsOK(response)) {
				await queryClient.invalidateQueries({ queryKey: waitingListKeys.guests(id) });
				await queryClient.invalidateQueries({ queryKey: guestKeys.filtered });
				await queryClient.invalidateQueries({ queryKey: guestKeys.list });
				await queryClient.invalidateQueries({ queryKey: guestKeys.options });
				await queryClient.invalidateQueries({ queryKey: guestKeys.recipients });
				showToast({
					body: t("The guest has been registered."),
					title: t("Registration complete"),
					type: "success",
				});
			}
		},
		[id, queryClient, showToast, t],
	);

	const columns = useMemo<IDataListColumn<GuestIndexResource>[]>(() => {
		return guestColumns.concat([
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (guest) => (
					<IconButton
						size="small"
						type="button"
						color="primary"
						title={t("Register guest")}
						onClick={() => registerGuest(guest)}
						icon="check"
					/>
				),
			},
		]);
	}, [guestColumns, registerGuest, t]);

	return (
		<>
			<AutoDataList
				name="Waiting guests"
				heading={t("Waiting guests")}
				headerChildren={<ActiveGuestFilterEntries />}
				headerActions={
					<>
						<LocalGuestViewSwitcher />
						<IconButton size="small" title={t("Guest filter")} onClick={handleOpen} icon="filter" />
					</>
				}
				empty={t("No guests available.")}
				columns={columns}
				queryKey={waitingListKeys.filteredGuests(id, currentGuestView.id, filters)}
				fetcher={(config) =>
					waitingListService.getGuests(id, currentGuestView.id, mapGuestFilterToGuestFilterRequest(filters), config)
				}
				initialSearch={{ search: "", searchFields: ["textDataFields"] }}
			/>
		</>
	);
};
