import {
	Button,
	Card,
	CardFooter,
	CardHeader,
	DataList,
	DataListActionsItem,
	DataListColumnTitle,
	DataListItem,
	IconButton,
	LinkButton,
	Trigger,
	useFormatDate,
} from "@dgs/core";
import { isEqual, parseISO } from "date-fns";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IRoomContingentIndex, IRoomContingentResource } from "~shared/types/roomContingent";
import { useHotelStepper } from "../stepper/HotelStepperProvider";
import { RoomTypeDayDetailsDrawer } from "./RoomTypeDayDetailsDrawer";

const StyledDataList = styled(DataList)`
	grid-template-columns: repeat(3, 1fr) max-content;
`;

interface Props {
	roomDetails: IRoomContingentIndex[];
	setRoomDetails: (roomDetails: IRoomContingentResource[]) => void;
	onSubmit: () => void;
}

export const RoomTypeDetailsList: FC<Props> = ({ roomDetails, setRoomDetails, onSubmit }) => {
	const { t } = useTranslation();
	const { hotel, roomType } = useHotelStepper();
	const formatDate = useFormatDate();

	const onSubmitDayDetails = useCallback(
		(roomDayDetails: IRoomContingentResource) => {
			setRoomDetails(
				roomDetails.map((x) => {
					if (isEqual(parseISO(x.date), parseISO(roomDayDetails.date))) {
						return roomDayDetails;
					}
					return x;
				}),
			);
		},
		[roomDetails, setRoomDetails],
	);

	return (
		<Card>
			<CardHeader>
				{hotel.name} - {roomType.name}
			</CardHeader>
			<StyledDataList isEmpty={roomDetails.length < 1} empty={t("No room type details available")}>
				<DataListColumnTitle>{t("Day")}</DataListColumnTitle>
				<DataListColumnTitle>{t("Contingent")}</DataListColumnTitle>
				<DataListColumnTitle>{t("Overbookable")}</DataListColumnTitle>
				<DataListColumnTitle>{t("Action")}</DataListColumnTitle>
				{roomDetails.map((roomDayDetails) => (
					<React.Fragment key={roomDayDetails.date}>
						<DataListItem>{formatDate(roomDayDetails.date)}</DataListItem>
						<DataListItem>{roomDayDetails.contingent}</DataListItem>
						<DataListItem>{roomDayDetails.overbookable ? t("Yes") : t("No")}</DataListItem>
						<DataListActionsItem>
							<Trigger
								render={(props) => (
									<RoomTypeDayDetailsDrawer
										{...props}
										roomContingent={roomDayDetails}
										roomType={roomType}
										onSubmit={onSubmitDayDetails}
									/>
								)}
							>
								{(props) => <IconButton {...props} title={t("Edit")} icon="edit" />}
							</Trigger>
						</DataListActionsItem>
					</React.Fragment>
				))}
			</StyledDataList>
			<CardFooter>
				<LinkButton type="button" to="../room-types">
					{t("Back")}
				</LinkButton>
				<Button type="button" onClick={onSubmit} color="primary">
					{t("Submit")}
				</Button>
			</CardFooter>
		</Card>
	);
};
