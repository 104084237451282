import {
	CheckboxGroupField,
	DateField,
	DateTimeField,
	MultiSelectField,
	SingleSelectControl,
	SingleSelectField,
	TimeField,
} from "@dgs/core";
import { useField } from "formik";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GuestViewAttributeOption } from "~shared/guestFilters/guestFilterTypes";
import { useResolveInfoForGuestAttribute } from "~shared/guestFilters/guestFilterUtils";
import { GuestListBulkEditAttributeSelect } from "~shared/guests/GuestListBulkEditAttributeSelectField";
import { GuestScreeningStatusSelect } from "~shared/guests/screeningStatus/GuestScreeningStatusSelect";
import { GroupMultiSelect } from "~shared/selects/GroupMultiSelect";
import { LocaleSelect } from "~shared/selects/LocaleSelect";
import { RegistrationFormSingleSelect } from "~shared/selects/RegistrationFormSingleSelect";
import { TagMultiSelect } from "~shared/selects/TagMultiSelect";
import { TenantSelectField } from "~shared/selects/TenantSelectField";
import { WaveSelectField } from "~shared/selects/WaveSelectField";
import { IDataFieldIndexResource } from "~shared/types/dataField";
import { DataFieldType } from "~shared/types/dataFieldType";
import { GuestListBulkEditOperators, GuestScreeningStatus } from "~shared/types/guest";
import { GuestViewAttributeResource } from "~shared/types/guestView";

const guestAttributeName = "guestAttribute";
const operatorName = "operator";
const valueName = "value";

const deleteAndReplace: GuestListBulkEditOperators[] = ["DELETE", "REPLACE"];
const deleteReplaceAndAdd: GuestListBulkEditOperators[] = [...deleteAndReplace, "ADD"];

const screeningOptions: GuestScreeningStatus[] = [
	GuestScreeningStatus.NEGATIVE_CONFIRMED,
	GuestScreeningStatus.POSITIVE_CONFIRMED,
];

export const GuestListBulkEditFormFields: FC = () => {
	const { t } = useTranslation();
	const [guestAttributeField] = useField<GuestViewAttributeOption>(guestAttributeName);
	const [operatorField] = useField<string>(operatorName);
	const [valueField] = useField(valueName);

	const operatorNullValue = { target: { name: operatorName, value: null } };
	const valueNullValue = { target: { name: valueName, value: null } };
	const valueEmptyArrayValue = { target: { name: valueName, value: [] } };

	const guestAttributeInfo = useResolveInfoForGuestAttribute(guestAttributeField.value?.id);

	const operators = useMemo(() => {
		if (!guestAttributeInfo) {
			return [];
		}

		if (guestAttributeInfo.dataField) {
			switch (guestAttributeInfo.dataField.type) {
				case DataFieldType.SELECT_FIELD:
				case DataFieldType.RADIO:
				case DataFieldType.DATETIME:
				case DataFieldType.DATE:
				case DataFieldType.TIME:
					return deleteAndReplace;
				case DataFieldType.MULTI_SELECT_FIELD:
				case DataFieldType.CHECKBOX_GROUP:
					return deleteReplaceAndAdd;
				default:
					return [];
			}
		}

		switch (guestAttributeInfo.type) {
			case "registrationForm":
			case "registrationLocale":
			case "tenant":
			case "wave":
				return deleteAndReplace;
			case "tags":
			case "groups":
				return deleteReplaceAndAdd;
			case "screeningStatus":
				return ["REPLACE"];
		}

		return [];
	}, [guestAttributeInfo]);

	return (
		<>
			<GuestListBulkEditAttributeSelect name={guestAttributeName} />
			{guestAttributeField.value && (
				<SingleSelectControl
					options={operators}
					label={t("Operator")}
					name={operatorName}
					onClear={() => operatorField.onChange(operatorNullValue)}
					value={operatorField.value}
					onChange={(e) => {
						operatorField.onChange(e);
						valueField.onChange(operators.includes("ADD") ? valueEmptyArrayValue : valueNullValue);
					}}
					getLabel={(o) => t(o)}
				/>
			)}
			{guestAttributeInfo?.attribute && operatorField.value && operatorField.value !== "DELETE" && (
				<DataFieldValueField guestAttribute={guestAttributeInfo?.attribute} dataField={guestAttributeInfo?.dataField} />
			)}
		</>
	);
};

const DataFieldValueField: FC<{ guestAttribute: GuestViewAttributeResource; dataField?: IDataFieldIndexResource }> = ({
	guestAttribute,
	dataField,
}) => {
	const { t } = useTranslation();
	const props = { name: valueName, label: t("Value") };

	if (dataField) {
		switch (dataField.type) {
			case DataFieldType.RADIO:
			case DataFieldType.SELECT_FIELD:
				return <SingleSelectField options={dataField.options} {...props} getLabel={(o) => o.value} />;
			case DataFieldType.MULTI_SELECT_FIELD:
				return <MultiSelectField options={dataField.options} {...props} getLabel={(o) => o.value} />;
			case DataFieldType.CHECKBOX_GROUP:
				return (
					<CheckboxGroupField
						{...props}
						options={dataField.options.map((x) => ({
							name: x.value,
							value: x.id,
						}))}
					/>
				);
			case DataFieldType.DATE:
				return <DateField {...props} />;
			case DataFieldType.DATETIME:
				return <DateTimeField {...props} />;
			case DataFieldType.TIME:
				return <TimeField {...props} />;
		}
	}

	switch (guestAttribute.entityType) {
		case "registrationForm":
			return <RegistrationFormSingleSelect {...props} />;
		case "groups":
			return <GroupMultiSelect {...props} />;
		case "tags":
			return <TagMultiSelect {...props} />;
		case "wave":
			return <WaveSelectField {...props} />;
		case "tenant":
			return <TenantSelectField {...props} />;
		case "registrationLocale":
			return <LocaleSelect {...props} />;
		case "screeningStatus":
			return <GuestScreeningStatusSelect {...props} options={screeningOptions} />;
		default:
			return null;
	}
};
