import { BlockViewProps } from "@dgs/core";
import React, { FC } from "react";
import styled from "styled-components";
import { IEmailImageBlockShowResource } from "./emailImageBlockTypes";

const Image = styled.img`
	width: 100%;
	display: flex;
`;
export const EmailImageBlockView: FC<BlockViewProps<IEmailImageBlockShowResource>> = ({ block }) => {
	return <Image src={block.entity?.url} alt={block.name} />;
};
