import { SingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { ConsentType } from "~shared/types/consent";

interface Props {
	label: string;
	name: string;
}

const consentTypeOptions: ConsentType[] = ["checkbox", "notice", "radio"];

export const ConsentTypeSelectField: FC<Props> = ({ label, name }) => {
	return (
		<SingleSelectField
			label={label}
			name={name}
			options={consentTypeOptions}
			getLabel={String}
			getValue={String}
			clearable={false}
		/>
	);
};
