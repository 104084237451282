import { DynamicConfigProvider, FCC, RootContainer, ToastList, ToastProvider } from "@dgs/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { createGlobalStyle } from "styled-components";
import { nominationManagerKeys } from "./api/nominationManagerService";
import { NominationManagerThemeProvider } from "./NominationManagerThemeProvider";
import { PMNominationManagerAuthProvider } from "./PMNominationManagerAuthProvider";

const GlobalStyles = createGlobalStyle`
  ${({ theme }) => theme.global};
`;

const queryClient = new QueryClient({
	defaultOptions: {
		queries: { staleTime: 100000, retry: false },
	},
});

export const NominationManagerProviders: FCC = ({ children }) => {
	return (
		<QueryClientProvider client={queryClient}>
			<DynamicConfigProvider remoteConfigKey={nominationManagerKeys.config}>
				<PMNominationManagerAuthProvider emailRedirectBaseUrl={`${window.origin}/nomination-manager`}>
					<NominationManagerThemeProvider>
						<ToastProvider>
							<GlobalStyles />
							<RootContainer>
								<ToastList />
								{children}
							</RootContainer>
						</ToastProvider>
					</NominationManagerThemeProvider>
				</PMNominationManagerAuthProvider>
			</DynamicConfigProvider>
		</QueryClientProvider>
	);
};
