import { CardHeader } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { DashboardLocation } from "~shared/types/dashboard";
import { DashboardCardBody, DashboardCardGrid, LinkCard } from "./DashboardCard";
import { Labeled } from "./Labeled";

interface Props {
	locations: DashboardLocation[];
}

export const DashboardLocations: FC<Props> = ({ locations }) => {
	const { t } = useTranslation();

	return (
		<DashboardCardGrid>
			{locations.map((location) => (
				<LinkCard
					key={location.id}
					$clickable={true}
					to={`../checkInApp/locations/${location.id}`}
					aria-label={location.name}
				>
					<CardHeader>{location.name}</CardHeader>
					<DashboardCardBody>
						<Labeled label={t("Check-in")}>{location.checkInCount}</Labeled>
						<Labeled label={t("Check-out")}>{location.checkOutCount}</Labeled>
						<Labeled label={t("Currently checked in")}>{location.currentlyCheckedInCount}</Labeled>
					</DashboardCardBody>
				</LinkCard>
			))}
		</DashboardCardGrid>
	);
};
