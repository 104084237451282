import { Id } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import { guestKeys } from "~shared/api/guests/guestKeys";
import { guestService } from "~shared/api/guests/guestService";
import { IActiveGuestFilterEntry } from "~shared/guestFilters/guestFilterTypes";
import { RecipientType } from "~shared/types/email";
import { GuestDetailsResource } from "~shared/types/newGuestTypes";

const emptyGuest: GuestDetailsResource = {
	id: 0,
	attributes: [],
	companionParent: null,
	replacementParent: null,
	companionIds: [],
};

export const useGuestQuery = (guestId: Id, guestViewId: Id) => {
	const isNew = guestId === "new";
	const { data, isLoading } = useQuery({
		enabled: !isNew,
		queryKey: guestKeys.details(guestId).concat(guestViewId.toString()),
		queryFn: () => guestService.details(guestId, guestViewId).then((r) => r.data.data),
	});

	return {
		data: isNew ? emptyGuest : data,
		isLoading,
	};
};

export const useRecipientCountQuery = (
	filters: IActiveGuestFilterEntry[],
	guestIds: Id[],
	recipientType: RecipientType,
) => {
	const { data, isLoading } = useQuery({
		enabled: recipientType !== RecipientType.NONE,
		queryKey: guestKeys.filteredRecipientCount(filters, guestIds, recipientType),
		queryFn: () => guestService.getRecipientCount(filters, guestIds, recipientType).then((r) => r.data.data),
	});

	return {
		data: data,
		isLoading,
	};
};

export const useGuestBookingQuery = (guestId: Id | null) => {
	const { data, isLoading } = useQuery({
		enabled: !!guestId,
		queryKey: guestKeys.bookingDetails(guestId),
		queryFn: () => guestService.bookingDetails(guestId).then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};
