import { Id } from "@dgs/core";
import { Action } from "~shared/types/actions";
import { IDataFieldUpdateResource } from "~shared/types/dataField";
import { IRegistrationFormSection } from "~shared/types/section";
import { IRegistrationFormStep } from "~shared/types/step";
import { Rule } from "./rules";

export enum RuleSetType {
	Condition = "condition",
	Automation = "automation",
	Validation = "validation",
}
export interface RuleSet<TRule = Rule, TAction = Action> {
	id: Id;
	type: RuleSetType;
	isAndLinked: boolean;
	description: string | null;
	color: string | null;
	registrationFormId: Id;
	rules: TRule[];
	actions: TAction[];
}

export type Condition<TRule = Rule, TAction = Action> = RuleSet<TRule, TAction>;
export type Automation<TRule = Rule, TAction = Action> = RuleSet<TRule, TAction>;

interface RuleSetDataFieldFilterItem<TDataField = IDataFieldUpdateResource> {
	type: "showDataField";
	value: TDataField;
}
interface RuleSetStepFilterItem<TRegistrationFormStep = IRegistrationFormStep> {
	type: "showStep";
	value: TRegistrationFormStep;
}
interface RuleSetSectionFilterItem<TRegistrationFormSection = IRegistrationFormSection> {
	type: "showSection";
	value: TRegistrationFormSection;
}

export interface ColorFilter {
	type: "color";
	value: string;
}
export interface RuleFilter {
	type: "rule";
	filterItem: RuleSetDataFieldFilterItem;
}
interface RuleFilterFormState {
	type: "rule";
	filterItem: RuleSetDataFieldFilterItem<IDataFieldUpdateResource | null>;
}

export interface ActionFilter {
	type: "action";
	filterItem: RuleSetDataFieldFilterItem | RuleSetStepFilterItem | RuleSetSectionFilterItem;
}

interface ActionFilterFormState {
	type: "action";
	filterItem:
		| RuleSetDataFieldFilterItem<IDataFieldUpdateResource | null>
		| RuleSetStepFilterItem<IRegistrationFormStep | null>
		| RuleSetSectionFilterItem<IRegistrationFormSection | null>
		| null;
}

export type AnyRulesetFilterItem = ColorFilter | RuleFilter | ActionFilter;

export type AnyRulesetFilterItemFormState = ColorFilter | RuleFilterFormState | ActionFilterFormState;
