import { AutoDataList, CardBody, IDataListColumn, IconAnchorButton, Loading, useDynamicConfig } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { personalizedDocumentKeys, personalizedDocumentService } from "~shared/api/personalizedDocuments";
import { useGuestDetails } from "~shared/guests/details/GuestDetailProvider";
import { GuestDetailsResource } from "~shared/types/newGuestTypes";
import { PersonalizedDocumentIndexResource } from "~shared/types/personalizedDocument";

const LoadingWrapper = styled(CardBody)`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Wrapper = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing(2)};
`;

export const GuestPersonalizedDocumentsPage: FC = () => {
	const { guest, isLoading } = useGuestDetails();

	if (!guest || isLoading)
		return (
			<LoadingWrapper>
				<Loading />
			</LoadingWrapper>
		);

	return <GuestPersonalizedDocumentsList guest={guest} />;
};

const GuestPersonalizedDocumentsList: FC<{ guest: GuestDetailsResource }> = ({ guest }) => {
	const { t } = useTranslation();
	const { apiUrl } = useDynamicConfig();

	const dataListColumns: IDataListColumn<PersonalizedDocumentIndexResource>[] = useMemo(
		() => [
			{
				heading: t("Name"),
				valueKey: "name",
				type: "value",
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (item) => (
					<Wrapper>
						<IconAnchorButton
							size="small"
							color="primary"
							href={`${apiUrl}/personalized-documents/${item.id}/guests/${guest.id}/view`}
							target="_blank"
							title={t("View personalized document")}
							label={t("View personalized document")}
							icon="visibility"
						/>
						<IconAnchorButton
							size="small"
							color="primary"
							href={`${apiUrl}/personalized-documents/${item.id}/guests/${guest.id}/download`}
							target="_blank"
							title={t("Download")}
							label={t("Download")}
							icon="file_download"
						/>
					</Wrapper>
				),
			},
		],
		[t, apiUrl, guest.id],
	);

	return (
		<AutoDataList
			heading={t("Personalized Documents")}
			empty={t("No personalized documents available")}
			columns={dataListColumns}
			queryKey={personalizedDocumentKeys.list}
			fetcher={personalizedDocumentService.index}
		/>
	);
};
