import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { hotelService } from "~admin/shared/api/hotels";
import { IHotelResource } from "~shared/types/hotel";
import { FormikHelpersWithRedirect } from "~shared/ui/FormikWithRedirect";

export const useHotelForm = (reload?: () => void) => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();

	const createHotel = useCallback(
		async ({ country, currency, ...hotel }: IHotelResource, helpers: FormikHelpersWithRedirect<IHotelResource>) => {
			const response = await hotelService.store({
				...hotel,
				countryId: country?.id ?? null,
				currencyId: currency?.id ?? null,
			});

			if (apiIsOK(response)) {
				showToast({
					body: t("Hotel was successfully created."),
					title: t("Hotel"),
					type: "success",
				});
				helpers.setTo(-1);
				await queryClient.invalidateQueries({ queryKey: hotelService.keys.index });
				return true;
			}

			return false;
		},
		[showToast, t, queryClient],
	);

	const updateHotel = useCallback(
		async (
			id: Id,
			{ country, currency, ...hotel }: IHotelResource,
			helpers: FormikHelpersWithRedirect<IHotelResource>,
		) => {
			const response = await hotelService.update(id, {
				...hotel,
				countryId: country?.id ?? null,
				currencyId: currency?.id ?? null,
			});
			if (apiIsOK(response)) {
				showToast({
					body: t("Hotel was successfully updated."),
					title: t("Hotel"),
					type: "success",
				});
				if (reload) {
					reload();
				}
				helpers.setTo(-1);
				await queryClient.invalidateQueries({ queryKey: hotelService.keys.index });
				await queryClient.invalidateQueries({ queryKey: hotelService.keys.details(hotel.id) });
				return true;
			}
			return false;
		},
		[queryClient, reload, showToast, t],
	);

	const deleteHotel = useCallback(
		async (hotelId: Id, shouldGoBackTo?: string) => {
			const response = await hotelService.destroy(hotelId);
			if (apiIsOK(response)) {
				showToast({
					body: t("Hotel was successfully deleted."),
					title: t("Hotel"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: hotelService.keys.index });
				if (shouldGoBackTo) {
					navigate(shouldGoBackTo, { replace: true });
				}
			}
		},
		[navigate, queryClient, showToast, t],
	);

	return { createHotel, updateHotel, deleteHotel };
};
