import {
	Card,
	CardBody,
	CardHeader,
	CardHeaderActions,
	CardHeading,
	CheckboxField,
	I18nFormProvider,
	I18nLocaleSwitcher,
	I18nOnlyOnMainForm,
	I18nTextareaField,
	Loading,
	TextField,
	TextareaField,
} from "@dgs/core";
import React, { FC, ReactNode, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { PricesField } from "~admin/hotels/roomTypes/contingent/PricesField";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { EmailSingleSelect } from "~shared/selects/EmailSingleSelect";
import { GroupMultiSelect } from "~shared/selects/GroupMultiSelect";
import { IRoomContingentPrice } from "~shared/types/roomContingent";
import { IRoomTypeResource } from "~shared/types/roomType";
import { FormikHelpersWithRedirect, FormikWithRedirect } from "~shared/ui/FormikWithRedirect";

interface HeaderProps {
	title: ReactNode;
}

const Header: FC<HeaderProps> = ({ title }) => {
	return (
		<CardHeader>
			<CardHeading>{title}</CardHeading>
			<CardHeaderActions>
				<I18nLocaleSwitcher />
			</CardHeaderActions>
		</CardHeader>
	);
};

interface Props {
	loading?: boolean;
	roomType: IRoomTypeResource;
	title: ReactNode;
	footer: ReactNode;
	onSubmit: (roomType: IRoomTypeResource, helpers: FormikHelpersWithRedirect<IRoomTypeResource>) => Promise<boolean>;
}

export const RoomTypeForm: FC<Props> = ({ roomType, onSubmit, title, footer, loading = false }) => {
	const { t } = useTranslation();
	const { fallbackLocale, locales } = useContentLocale();
	const validate = useCallback(
		(values: IRoomTypeResource) => {
			const errors: Record<string, any> = {};

			if (!values.name) {
				errors.name = t("Required field");
			}
			if (!values.capacity) {
				errors.capacity = t("Required field");
			} else if (values.capacity < 0) {
				errors.capacity = t("The value cannot be negative");
			}

			const priceErrors: { [K in keyof IRoomContingentPrice]?: any }[] = [];
			values.defaultPrices.forEach((price, idx) => {
				if (price.purchasePrice <= 0) {
					priceErrors[idx] = priceErrors[idx]
						? { ...priceErrors[idx], purchasePrice: t("Price needs to be larger than 0") }
						: { purchasePrice: t("Price needs to be larger than 0") };
				}
				if (price.sellingPrice <= 0) {
					priceErrors[idx] = priceErrors[idx]
						? { ...priceErrors[idx], sellingPrice: t("Price needs to be larger than 0") }
						: { sellingPrice: t("Price needs to be larger than 0") };
				}
				if (price.occupancy < 1) {
					priceErrors[idx] = priceErrors[idx]
						? { ...priceErrors[idx], occupancy: t("Occupancy needs to be larger than 0") }
						: { occupancy: t("Occupancy needs to be larger than 0") };
				} else if (price.occupancy > values.capacity) {
					priceErrors[idx] = priceErrors[idx]
						? {
								...priceErrors[idx],
								occupancy: t(
									"Occupancy needs to be smaller than or equal to the number of maximum persons the room type has.",
								),
						  }
						: {
								occupancy: t(
									"Occupancy needs to be smaller than or equal to the number of maximum persons the room type has.",
								),
						  };
				}
			});
			if (priceErrors.filter((x) => x).length > 0) {
				errors.defaultPrices = priceErrors;
			}

			return errors;
		},
		[t],
	);

	if (loading) {
		return (
			<Card>
				<Header title={title} />
				<CardBody>
					<Loading />
				</CardBody>
			</Card>
		);
	}

	return (
		<I18nFormProvider locales={locales} fallbackLocale={fallbackLocale}>
			<Card>
				<FormikWithRedirect initialValues={roomType} onSubmit={onSubmit} validate={validate}>
					<Header title={title} />
					<CardBody>
						<I18nOnlyOnMainForm>
							<TextField label={t("Name")} name="name" required />
							<TextField label={t("Maximum number of persons")} name="capacity" type="number" required />
							<CheckboxField label={t("Show occupancy in email hotel summary")} name="showOccupancyInSummary" />
							<TextField label={t("Hotel contact email")} name="email" />
							<TextField type="tel" label={t("Phone")} name="phone" />
							<TextareaField label={t("Internal remarks")} name="internalRemark" rows={5} />
							<PricesField name="defaultPrices" />
						</I18nOnlyOnMainForm>
						<I18nTextareaField name="guestRemark" label={t("Guest remark")} />
						<I18nOnlyOnMainForm>
							<EmailSingleSelect
								label={t("Email template for booking overview to hotel contact")}
								name="customEmailForBookingOverview"
							/>
							<EmailSingleSelect
								label={t("Email template for request confirmation to guest")}
								name="customEmailForRequester"
							/>
							<GroupMultiSelect label={t("Bookable for group")} name="groups" />
						</I18nOnlyOnMainForm>
					</CardBody>
					{footer}
				</FormikWithRedirect>
			</Card>
		</I18nFormProvider>
	);
};
