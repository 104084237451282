import React, { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { HotelStepper } from "./HotelStepper";
import { HotelStepperDetailsPage } from "./HotelStepperDetailsPage";
import { HotelStepperMasterDataPage } from "./HotelStepperMasterDataPage";
import { HotelStepperProvider } from "./HotelStepperProvider";
import { HotelStepperRoomTypePage } from "./HotelStepperRoomTypePage";

export const HotelStepperPage: FC = () => {
	return (
		<HotelStepperProvider>
			<Routes>
				<Route element={<HotelStepper />}>
					<Route index element={<HotelStepperMasterDataPage />} />
					<Route path="room-types" element={<HotelStepperRoomTypePage />} />
					<Route path="details" element={<HotelStepperDetailsPage />} />
				</Route>
			</Routes>
		</HotelStepperProvider>
	);
};
