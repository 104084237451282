import { AnchorButton, Icon, Loading, TextButton, useToasts } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useQrCodeQuery } from "~admin/shared/api/qrCode";

const Container = styled.div`
	width: fit-content;
`;

const Heading = styled.div`
	font-size: 1.25rem;
	margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

const Img = styled.img`
	margin: ${({ theme }) => theme.spacing(2)};
	display: block;
	height: 100px;
	width: auto;
`;

const DownloadButton = styled(AnchorButton)`
	width: fit-content;
`;

export const QrCode: FC<{ value: string }> = ({ value }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const { data: qrCode, isLoading } = useQrCodeQuery(value);

	return (
		<Container>
			<Heading>{t("Public link")}</Heading>
			{isLoading && <Loading />}
			{qrCode && (
				<>
					<DownloadButton
						title={t("Download qr code")}
						size="small"
						color="default"
						href={qrCode.image}
						prefix={<Icon icon="file_download" />}
						download
					>
						{t("Download qr code")}
					</DownloadButton>
					<Img src={qrCode.image} alt={t("QR code")} />
					<TextButton
						title={t("Copy public link")}
						size="small"
						type="button"
						prefix={<Icon icon="copy" />}
						onClick={() => {
							navigator.clipboard.writeText(qrCode.value);
							showToast({ body: t("Public link successfully copied"), type: "success", title: t("Public link") });
						}}
					>
						{qrCode.value}
					</TextButton>
				</>
			)}
		</Container>
	);
};
