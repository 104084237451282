import { CrudDrawer, EntityDrawerProps, TextField } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IGuestFileCategoryFormState } from "~shared/types/guestFileCategory";

export const GuestFileCategoryDrawer: FC<EntityDrawerProps<IGuestFileCategoryFormState>> = (props) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: IGuestFileCategoryFormState) => {
			const errors: { [K in keyof IGuestFileCategoryFormState]?: any } = {};

			if (!values.name) {
				errors.name = t("Required field");
			}
			return errors;
		},
		[t],
	);

	return (
		<CrudDrawer
			{...props}
			validate={validate}
			body={
				<>
					<TextField name="name" label={t("Name")} required />
				</>
			}
		/>
	);
};
