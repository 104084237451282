import { Id, LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { mapFetcherConfigToAxiosConfig } from "~shared/api/indexMapper";
import { CreateFile, IFile } from "~shared/types/file";
import { filesKeys } from "./filesKeys";

export const filesFormUrls = {
	index: keyToUrl(filesKeys.index),
	file: (fileId: Id) => keyToUrl(filesKeys.file(fileId)),
	options: keyToUrl(filesKeys.options),
};

const index = (config: ListFetcherConfig<any>) =>
	axios.get<LaravelResponse<IFile[]>>(filesFormUrls.index, mapFetcherConfigToAxiosConfig(config));

const post = (createFile: CreateFile) => {
	const formData = new FormData();
	createFile.file && formData.append("file", createFile.file);
	formData.append("isPublic", createFile.isPublic ? "1" : "0");
	return axios.post<LaravelResponse<IFile>>(filesFormUrls.index, formData);
};

const remove = (fileId: Id) => axios.delete<LaravelResponse<IFile>>(filesFormUrls.file(fileId));

const options = (config?: AxiosRequestConfig) => axios.get<LaravelResponse<IFile[]>>(filesFormUrls.options, config);

export const filesService = {
	index,
	post,
	remove,
	options,
};
