import { Button, CheckboxField, CrudDrawer, DateField, FormattedDate, Id, Text, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { differenceInDays } from "date-fns";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { guestKeys, guestService } from "~shared/api/guests";
import { hotelInquiryService } from "~shared/api/hotelInquiries";
import { ContingentFormFields } from "~shared/guests/hotels/ContingentFormFields";
import { IRoomAllocation, ISelectDateRangeFormState, RoomContingentFormState } from "~shared/types/roomContingent";
import { ValueViewer } from "~shared/ui/ValueViewer";
import { parseDate } from "~shared/utils/dateUtils";

const Range = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
`;

const Values = styled.div`
	display: grid;
	gap: ${({ theme }) => theme.spacing(4)};
	padding-bottom: ${({ theme }) => theme.spacing(4)};
	border-bottom: 1px solid ${({ theme }) => theme.colors.palette.grey[30]};
	margin-bottom: ${({ theme }) => theme.spacing(4)};
`;

styled(CheckboxField)`
	margin-bottom: ${({ theme }) => theme.spacing(3)};
`;
styled(Text)`
	text-align: center;
	width: 100%;
	padding: ${({ theme }) => theme.spacing(3)};
`;

interface Props {
	guestId: Id;
	open: boolean;
	handleClose: () => void;
	handleOpen: () => void;
}

interface ISelectDateRangeStep {
	type: "SELECT_DATE_RANGE";
	startDate: string | null;
	endDate: string | null;
}

interface ISelectContingentStep {
	type: "SELECT_CONTINGENT";
	startDate: string;
	endDate: string;
}

type IStep = ISelectDateRangeStep | ISelectContingentStep;

const initialStep: IStep = {
	type: "SELECT_DATE_RANGE",
	startDate: null,
	endDate: null,
};

const initialValues: RoomContingentFormState = {
	roomAllocation: [],
	roomMatesForAllDays: null,
	roomTypeForAllDays: null,
	openSpecificRoomSelect: false,
};

export const GuestRoomContingentAttachDrawer: FC<Props> = ({ guestId, open, handleClose, handleOpen }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const [step, setStep] = useState<IStep>(initialStep);
	const [preventClose, setPreventClose] = useState(false);
	const queryClient = useQueryClient();

	const validate = useCallback(
		({ startDate, endDate }: ISelectDateRangeFormState) => {
			const errors: { [K in keyof ISelectDateRangeFormState]?: any } = {};

			if (!startDate) {
				errors.startDate = t("Required field");
			}
			if (!endDate) {
				errors.endDate = t("Required field");
			}

			if (startDate && endDate) {
				const start = parseDate(startDate);
				const end = parseDate(endDate);
				if (differenceInDays(end, start) < 1) {
					errors.endDate = t("The end date has to be at least one day after start date");
				}
				if (differenceInDays(end, start) > 365) {
					errors.endDate = t("The end date should be max. 1 year after start date");
				}
			}

			return errors;
		},
		[t],
	);

	const validateContingent = useCallback(
		({ roomAllocation }: RoomContingentFormState) => {
			const errors: { [K in keyof RoomContingentFormState]?: any } = {};
			const allocationErrors: { [K in keyof IRoomAllocation]?: any }[] = [];

			roomAllocation.forEach((allocation, index) => {
				if (allocation.roomMates && allocation.roomMates.length > allocation.roomContingent.roomType.capacity) {
					allocationErrors[index].roomContingent = t(
						"This room only has {{capacity}} beds. Choose another room or reduce the amount of room mates.",
						{ capacity: allocation.roomContingent.roomType.capacity },
					);
				}
			});

			if (allocationErrors.length) {
				errors.roomAllocation = allocationErrors;
			}

			return errors;
		},
		[t],
	);

	const onSubmit = useCallback(
		async (roomAllocation: IRoomAllocation[]) => {
			const res = await guestService.attachRoomContingents(
				guestId,
				roomAllocation.map((allocation) => ({
					roomContingentId: allocation.roomContingent.id,
					roomMateIds: allocation.roomMates ? allocation.roomMates.map((roomMate) => roomMate.id) : [],
				})),
			);

			if (apiIsOK(res)) {
				showToast({ body: t("Room booking was saved."), title: t("Save room booking"), type: "success" });
				await queryClient.invalidateQueries({ queryKey: guestKeys.rooms(guestId) });
				await queryClient.invalidateQueries({ queryKey: hotelInquiryService.keys.index });
				handleClose();
			}
			return true;
		},
		[guestId, handleClose, queryClient, showToast, t],
	);
	const onSubmitDateRange = useCallback(async ({ startDate, endDate }: ISelectDateRangeFormState) => {
		if (startDate && endDate) {
			setStep({
				type: "SELECT_CONTINGENT",
				startDate,
				endDate,
			});
		}
		return true;
	}, []);
	const onBackClick = useCallback(() => {
		setPreventClose(false);
		handleOpen();
		setStep({ ...step, type: "SELECT_DATE_RANGE" });
	}, [step, handleOpen]);

	if (step.type === "SELECT_DATE_RANGE") {
		return (
			<CrudDrawer
				key={step.type}
				open={open}
				onSubmit={(values) => {
					setPreventClose(true);
					return onSubmitDateRange(values);
				}}
				body={
					<>
						<DateField name="startDate" label={t("Start date")} />
						<DateField name="endDate" label={t("End date")} />
					</>
				}
				validate={validate}
				initialValues={step}
				onClose={handleClose}
				heading={t("Add room booking")}
				translations={{ save: t("Next") }}
			/>
		);
	}
	return (
		<CrudDrawer
			key={step.type}
			open={preventClose}
			onSubmit={(values) => {
				return onSubmit(values.roomAllocation);
			}}
			initialValues={initialValues}
			onClose={() => {
				setPreventClose(false);
				setStep(initialStep);
				handleClose();
			}}
			heading={t("Add room booking")}
			validate={validateContingent}
			footer={
				<Button type="button" onClick={onBackClick}>
					{t("Back")}
				</Button>
			}
			body={(formikProps) => (
				<>
					<Values>
						<Range>
							<ValueViewer label={t("Start")}>
								<FormattedDate date={step.startDate} />
							</ValueViewer>
							<ValueViewer label={t("End")}>
								<FormattedDate date={step.endDate} />
							</ValueViewer>
						</Range>
					</Values>
					<ContingentFormFields
						startDate={step.startDate}
						endDate={step.endDate}
						guestId={guestId}
						formikProps={formikProps}
					/>
				</>
			)}
		/>
	);
};
