import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { eachDayOfInterval, endOfDay, isSameDay, isWithinInterval, parseISO, subDays } from "date-fns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { hotelInquiryService } from "~shared/api/hotelInquiries";
import {
	IHotelInquiryConflict,
	IHotelInquiryMissingContingentType,
	IHotelInquiryOkType,
	IHotelInquiryOutOfRangeType,
	IHotelInquiryResource,
} from "~shared/types/hotelInquiry";

export type ToAdd = (IHotelInquiryOkType | IHotelInquiryMissingContingentType)[];

export const useResolveHotelInquiryConflicts = () => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();

	const resolveConflicts = useCallback(
		async (hotelInquiryId: Id, values: { roomAllocation: ToAdd }, afterResolve: () => void) => {
			const response = await hotelInquiryService.resolveConflicts(
				hotelInquiryId,
				values.roomAllocation
					.filter((allocation) => allocation.roomContingent !== null)
					.map((allocation) => {
						if (allocation.roomContingent) {
							return {
								roomContingentId: allocation.roomContingent.id,
								roomMateIds: allocation.roomMates.map((roomMate) => roomMate.id),
							};
						}

						return { roomContingentId: "", roomMateIds: [] };
					}),
			);

			if (apiIsOK(response)) {
				if (response.status === 200) {
					showToast({
						type: "success",
						title: t("Resolved conflicts successfully"),
						body: t("The conflicts were successfully resolved."),
					});
				} else {
					showToast({
						type: "warning",
						title: t("No Conflict could be resolved."),
						body: t("No changes have been made to the conflicting inquiry."),
					});
				}
				afterResolve();
				await queryClient.invalidateQueries({ queryKey: hotelInquiryService.keys.conflicts });
				await queryClient.invalidateQueries({
					queryKey: hotelInquiryService.keys.details(hotelInquiryId),
					refetchType: "all",
				});
				return true;
			}
			return false;
		},
		[queryClient, showToast, t],
	);

	const getConflicts = useCallback(
		(
			checkIn: Date | null,
			checkOut: Date | null,
			hotelInquiry?: IHotelInquiryResource,
		): {
			toAdd: ToAdd;
			toRemove: IHotelInquiryOutOfRangeType[];
		} => {
			if (!hotelInquiry) {
				return {
					toRemove: [],
					toAdd: [],
				};
			}

			if (!checkIn || !checkOut) {
				const allocationEntries: IHotelInquiryOutOfRangeType[] = hotelInquiry.roomAllocation.map((allocation) => {
					return {
						type: "outOfRange",
						date: parseISO(allocation.roomContingent.date),
						roomContingent: allocation.roomContingent,
						roomMates: allocation.roomMates || [],
					};
				});
				return {
					toRemove: allocationEntries,
					toAdd: [],
				};
			}
			const interval = { start: checkIn, end: endOfDay(subDays(checkOut, 1)) };
			const range = eachDayOfInterval(interval);

			const allocationEntries: IHotelInquiryConflict[] = hotelInquiry.roomAllocation.map((allocation) => {
				const contingentDate = parseISO(allocation.roomContingent.date);
				if (isWithinInterval(contingentDate, interval)) {
					return {
						type: "ok",
						date: contingentDate,
						roomContingent: allocation.roomContingent,
						roomMates: allocation.roomMates || [],
					};
				} else {
					return {
						type: "outOfRange",
						date: contingentDate,
						roomContingent: allocation.roomContingent,
						roomMates: allocation.roomMates || [],
					};
				}
			});

			const missingAllocationEntries: IHotelInquiryConflict[] = range
				.filter((date) => !allocationEntries.find((e) => isSameDay(e.date, date)))
				.map((date) => ({
					type: "missingContingent",
					date,
					roomContingent: null,
					roomMates: [],
				}));

			return {
				toRemove: allocationEntries.filter((e) => e.type === "outOfRange") as IHotelInquiryOutOfRangeType[],
				toAdd: allocationEntries
					.filter((e) => e.type === "ok")
					.concat(missingAllocationEntries)
					.sort((e1, e2) => e1.date.getTime() - e2.date.getTime()) as ToAdd,
			};
		},
		[],
	);

	return {
		resolveConflicts,
		getConflicts,
	};
};
