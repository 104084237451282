import {
	BaseCrudService,
	IDataListSortParams,
	Id,
	LaravelResponse,
	ListFetcherConfig,
	keyToUrl,
	mapFetcherConfigToAxiosConfig,
} from "@dgs/core";
import axios from "axios";
import { CheckInOutLog, Location, LocationRequest } from "~admin/shared/types/location";
import { IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";

class LocationsService extends BaseCrudService<Location, Location, LocationRequest> {
	public keys = {
		index: ["locations"],
		list: ["locations", "list"],
		details: (locationId: Id) => [...this.keys.index, `${locationId}`],
		logs: (locationId: Id) => [...this.keys.details(locationId), "logs"],
		logsExport: (locationId: Id) => [...this.keys.logs(locationId), "export"],
		logsFilter: (locationId: Id, guestViewId: Id, filter: IGuestFilterFormValueEntry[]) => [
			...this.keys.logs(locationId),
			`${guestViewId}`,
			JSON.stringify(filter),
		],
	};

	protected urls = {
		index: keyToUrl(this.keys.index),
		details: (locationId: Id) => keyToUrl(this.keys.details(locationId)),
		logs: (locationId: Id) => keyToUrl(this.keys.logs(locationId)),
		logsExport: (locationId: Id) => keyToUrl(this.keys.logsExport(locationId)),
	};

	logs = (locationId: Id, guestViewId: Id, filters: IGuestFilterFormValueEntry[], config: ListFetcherConfig<any>) =>
		axios.post<LaravelResponse<CheckInOutLog[]>>(
			this.urls.logs(locationId),
			{
				guestViewId,
				filters,
				sort: config.sortParams
					? {
							guestAttributeId: config.sortParams.column,
							direction: config.sortParams.direction,
					  }
					: null,
			},
			mapFetcherConfigToAxiosConfig(config),
		);

	exportLogs = (
		locationId: Id,
		guestViewId: Id,
		filters: IGuestFilterFormValueEntry[],
		search: string | null,
		sort: IDataListSortParams<any> | null,
	) =>
		axios.post<LaravelResponse<CheckInOutLog[]>>(this.urls.logsExport(locationId), {
			guestViewId,
			filters,
			search,
			sort: sort
				? {
						guestAttributeId: sort.column,
						direction: sort.direction,
				  }
				: null,
		});
}

export const locationService = new LocationsService();
