import { BaseProfileEdit, Card, CardBody, CardHeader, CardHeading, UserService, useDynamicConfig } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const ProfilePage: FC = () => {
	const { t } = useTranslation();
	const { userUrl } = useDynamicConfig();
	const userService = useMemo(() => new UserService(userUrl), [userUrl]);

	return (
		<Card>
			<CardHeader>
				<CardHeading>{t("My profile")}</CardHeading>
			</CardHeader>
			<CardBody>
				<BaseProfileEdit userService={userService} />
			</CardBody>
		</Card>
	);
};
