import { IUser, Id } from "@dgs/core";
import { IActiveGuestFilterEntry } from "~shared/guestFilters/guestFilterTypes";
import { IFile } from "~shared/types/file";
import { Guest } from "~shared/types/guest";
import { IGuestFileCategoryOptionResource } from "~shared/types/guestFileCategory";
import { PersonalizedDocumentOptionsResource } from "~shared/types/personalizedDocument";
import { TagOption } from "~shared/types/tag";

export enum RecipientType {
	NONE = "none",
	ALL = "all",
	FILTER = "filter",
	GUEST_SELECT = "guestSelect",
}

export interface EmailListResource {
	id: Id;
	name: string;
	attachmentCount: number;
}

export interface EmailFormValues {
	name: string;
	senderName: string | null;
	changeStatusTo: string | null;
	bccEmailAddresses: string[];
	translations: Record<string, { subject: string; replyTo: string; files: IFile[] }>;
	personalizedDocuments: PersonalizedDocumentOptionsResource[];
	guestFileCategories: IGuestFileCategoryOptionResource[];
	tags: TagOption[];
}
export interface EmailSaveRequest {
	name: string;
	senderName: string | null;
	changeStatusTo: string | null;
	bccEmailAddresses: string[];
	translations: Record<string, { subject: string; replyTo: string; files: IFile[] }>;
	personalizedDocumentIds: Id[];
	guestFileCategoryIds: Id[];
	tagIds: Id[];
}

export interface EmailSaveResource extends Omit<EmailFormValues, "guestFileCategories"> {
	id: Id;
	blockId: Id;
	guestFileCategories: IGuestFileCategoryOptionResource[];
}

export interface EmailResponse {
	id: Id;
	name: string;
	blockId: Id;
}

export interface SendOutRequest {
	customEmailId: Id;
	sendAt: string | null;
	locale: string;
	receivers: {
		filters: IActiveGuestFilterEntry[];
		guestIds: Id[];
		recipientType: string;
	};
}

export interface SendOut {
	id: Id;
	status: string;
	failedReceiverCount: number;
	successfulReceiverCount: number;
	creator: IUser;
	sendAt: string;
	finishedAt: string;
	customEmail: EmailResponse;
}

export interface BaseEmailLog {
	id: Id;
	date: string;
	to: string;
	cc: string;
	bcc: string;
	subject: string;
	hasAttachments: boolean;
}

export interface DetailedEmailLog extends BaseEmailLog {
	guest: Guest;
	from: string;
	body: string;
	headers: string;
	attachments: EmailLogAttachment[];
}

export interface EmailLogAttachment {
	id: Id | null;
	name: string;
}

export interface DashboardEmailLog {
	id: Id;
	date: string;
	to: string;
	subject: string;
	hasAttachments: boolean;
}

export interface SendEmailFormValues {
	locale: string;
	customEmail: EmailListResource | null;
}

export interface EmailSendOutFormState {
	showSendAt: boolean;
	sendAt: string | null;
	locale: string;
}
