import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { SectionDataFieldSelect } from "~admin/shared/selects/DataFieldPagedSingleSelect";
import { EmailSingleSelect } from "~shared/selects/EmailSingleSelect";
import { GroupMultiSelect } from "~shared/selects/GroupMultiSelect";
import { GroupSingleSelect } from "~shared/selects/GroupSingleSelect";
import { TagMultiSelect } from "~shared/selects/TagMultiSelect";
import { TagSingleSelect } from "~shared/selects/TagSingleSelect";
import { ActionFormState, ActionTypes } from "~shared/types/actions";
import { SectionPagedSingleSelect } from "./SectionPagedSingleSelect";
import { StepPagedSingleSelect } from "./StepPagedSingleSelect";

interface Props {
	registrationFormId: string;
	name: string;
	action: ActionFormState;
}

export const ActionValueSelectField: FC<Props> = ({ registrationFormId, name, action }) => {
	const { t } = useTranslation();

	switch (action.type) {
		case ActionTypes.SHOW_DATA_FIELD:
			return <SectionDataFieldSelect formId={registrationFormId} name={name} label={t("Data field")} isAction={true} />;
		case ActionTypes.SHOW_STEP:
			return <StepPagedSingleSelect registrationFormId={registrationFormId} name={name} label={t("Step")} />;
		case ActionTypes.SHOW_SECTION:
			return <SectionPagedSingleSelect registrationFormId={registrationFormId} name={name} label={t("Section")} />;
		case ActionTypes.ATTACH_GROUP:
			return <GroupSingleSelect name={name} label={t("Group")} />;
		case ActionTypes.ATTACH_TAG:
			return <TagSingleSelect name={name} label={t("Tag")} />;
		case ActionTypes.OVERWRITE_GROUPS:
			return <GroupMultiSelect name={name} label={t("Groups")} />;
		case ActionTypes.OVERWRITE_TAGS:
			return <TagMultiSelect name={name} label={t("Tags")} />;
		case ActionTypes.SEND_EMAIL:
			return <EmailSingleSelect name={name} label={t("Email")} />;
	}
};
