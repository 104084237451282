import { Card, CardHeader, CardHeading, Loading, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { HotelSettingsForm } from "~admin/hotels/settings/HotelSettingsForm";
import { hotelSettingsKeys } from "~admin/shared/api/hotelSettings/hotelSettingsKeys";
import { useHotelSettingsQuery } from "~admin/shared/api/hotelSettings/hotelSettingsQueries";
import { hotelSettingsService } from "~admin/shared/api/hotelSettings/hotelSettingsService";
import { IHotelSettingsFormValues, IHotelSettingsRequest } from "~admin/shared/types/hotelSettings";
import { PMBlockMetaProvider } from "~shared/blocks/PMBlockMetaProvider";

export const HotelSettings: FC = () => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { data: settings } = useHotelSettingsQuery();

	const handleSubmit = useCallback(
		async (values: IHotelSettingsFormValues) => {
			const request: IHotelSettingsRequest = {
				checkInDataFieldId: values.checkInDataField ? values.checkInDataField.id : null,
				checkOutDataFieldId: values.checkOutDataField ? values.checkOutDataField.id : null,
				hotelRemarkDataFieldId: values.hotelRemarkDataField ? values.hotelRemarkDataField.id : null,
			};

			const response = await hotelSettingsService.update(request);

			if (apiIsOK(response)) {
				showToast({ body: t("Settings were saved."), title: t("Save settings"), type: "success" });
				await queryClient.invalidateQueries({ queryKey: hotelSettingsKeys.index });
				return true;
			}

			return false;
		},
		[queryClient, showToast, t],
	);

	if (!settings) return <Loading />;

	return (
		<PMBlockMetaProvider>
			<Card>
				<CardHeader>
					<CardHeading>{t("Settings")}</CardHeading>
				</CardHeader>
				<HotelSettingsForm settings={settings} onSubmit={handleSubmit} />
			</Card>
		</PMBlockMetaProvider>
	);
};
