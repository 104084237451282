import { BlocksPage, Empty } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import { EmailSendOutDetailsPage } from "~admin/emails/sendOut/details/EmailSendOutDetailsPage";
import { GuestFilterDrawer, GuestFilterProvider } from "~shared/guestFilters";
import { LocalGuestViewProvider } from "~shared/providers/LocalGuestViewProvider";
import { EmailLogDetails, EmailLogs } from "../../shared/emails/logs";
import { EmailCreatePage } from "./create/EmailCreatePage";
import { EmailEditPage } from "./edit/EmailEditPage";
import { EmailsList } from "./EmailsList";
import { EmailSendOutList, EmailSendOutPage, EmailSendOutPreview } from "./sendOut";

export const EmailsPage: FC = () => {
	const { t } = useTranslation();

	return (
		<Routes>
			<Route index element={<Navigate to="list" />} />
			<Route path="list" element={<EmailsList />} />
			<Route path="blocks/*" element={<BlocksPage />} />
			<Route path="new/*" element={<EmailCreatePage />} />
			<Route path="send-outs/*">
				<Route path="list" element={<EmailSendOutList />} />
				<Route
					path=":sendOutId/*"
					element={
						<LocalGuestViewProvider guestViewKey={"sendOutGuestList"}>
							<GuestFilterProvider listFilterKey="sendOutGuestListFilter">
								<EmailSendOutDetailsPage />
								<GuestFilterDrawer />
							</GuestFilterProvider>
						</LocalGuestViewProvider>
					}
				/>
				<Route path="send/*" element={<EmailSendOutPage />}>
					<Route index element={<Empty>{t("Please choose an email to send.")}</Empty>} />
					<Route path=":mailId" element={<EmailSendOutPreview />} />
				</Route>
			</Route>
			<Route path="logs/*">
				<Route path="list" element={<EmailLogs />} />
				<Route path=":logId" element={<EmailLogDetails />} />
			</Route>
			<Route path=":emailId/*" element={<EmailEditPage />} />
		</Routes>
	);
};
