import { PagedSingleSelectField, filesKeys, filesService, useI18nForm } from "@dgs/core";
import { useField } from "formik";
import React, { FC, ReactElement } from "react";

interface Props {
	name: string;
	label?: string;
	disabled?: boolean;
	required?: boolean;
	className?: string;
	placeholder?: string;
}

const fetcher = () => filesService.options().then((r) => r.data);

export const I18nFileSingleSelectField: FC<Props> = ({
	name,
	label = "",
	disabled,
	required,
	className,
	placeholder,
}) => {
	const { currentLocale } = useI18nForm();

	return (
		<I18nSelectFieldWrapper name={name}>
			<PagedSingleSelectField
				key={`translations.${currentLocale}.${name}`}
				name={`translations.${currentLocale}.${name}`}
				label={label}
				disabled={disabled}
				fetcher={fetcher}
				queryKey={filesKeys.options}
				getLabel={(o) => o.filename}
				required={required}
				className={className}
				clearable={true}
				placeholder={placeholder}
			/>
		</I18nSelectFieldWrapper>
	);
};

interface WrapperProps {
	name: string;
	children: ReactElement;
}

const I18nSelectFieldWrapper: FC<WrapperProps> = ({ name, children }) => {
	const { fallbackLocale, currentLocale } = useI18nForm();
	const [{ value: fallbackValue }] = useField(`translations.${fallbackLocale}.${name}`);

	if (React.Children.count(children) !== 1) {
		throw new Error("Only one child is supported");
	}

	if (children)
		if (fallbackValue && currentLocale !== fallbackLocale) {
			return React.cloneElement(children, {
				description: "Fallback: " + fallbackValue?.filename,
				key: `translations.${currentLocale}.${name}`,
			});
		}

	return children;
};
