import { ContextMenu, ContextMenuItem, Id, useCrudEntity } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { guestFilterKeys } from "~shared/api/guestFilters";
import { tenantService } from "~shared/api/tenants/tenantService";
import { waveService } from "~shared/api/waves/waveService";
import { DeleteModelWithUsagesDialog, useModelUsage } from "~shared/ui/modelUsage/DeleteModelWithUsagesDialog";

export const TenantContextMenu: FC<{ id: Id; openDrawer: (id: Id) => void }> = ({ id, openDrawer }) => {
	const { handleUsage, ...usageModalProps } = useModelUsage(() => tenantService.showUsage(id));
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const crudOperations = useCrudEntity(
		tenantService,
		{
			entityName: t("Tenant"),
			messages: {
				create: t("Tenant was saved."),
				update: t("Tenant was saved."),
				delete: t("Tenant was deleted."),
			},
		},
		[tenantService.keys.list, waveService.keys.getContingentOverview],
	);

	return (
		<>
			<ContextMenu displayShortcutsAs="icon">
				<ContextMenuItem
					title={t("Edit tenant")}
					label={t("Edit tenant")}
					action={() => openDrawer(id)}
					icon="edit"
					shortcut="primary"
				/>
				<ContextMenuItem action={handleUsage} title={t("Delete tenant")} label={t("Delete tenant")} icon="delete" />
			</ContextMenu>
			<DeleteModelWithUsagesDialog
				{...usageModalProps}
				usagePrefix={t("This tenant")}
				confirmationBody={t("Are you sure you want to delete this tenant?")}
				deleteAction={async () => {
					const isOk = await crudOperations.deleteEntity(id);
					if (isOk) {
						await queryClient.resetQueries({ queryKey: guestFilterKeys.index });
					}
				}}
			/>
		</>
	);
};
