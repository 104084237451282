import { BaseCrudOptionService, Id, keyToUrl } from "@dgs/core";
import {
	IConsentIndexResource,
	IConsentOptionResource,
	IConsentRequest,
	IConsentUpdateResource,
} from "~shared/types/consent";
import { consentKeys } from "./consentKeys";

class ConsentService extends BaseCrudOptionService<
	IConsentIndexResource,
	IConsentUpdateResource,
	IConsentOptionResource,
	IConsentRequest
> {
	public keys = consentKeys;

	protected urls = {
		index: keyToUrl(consentKeys.index),
		details: (consentId: Id) => keyToUrl(consentKeys.details(consentId)),
		options: keyToUrl(consentKeys.options),
	};
}

export const consentService = new ConsentService();
