import { ColorSelectField, IconButton, SingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ActionTypes } from "~shared/types/actions";
import { IRegistrationFormDataField } from "~shared/types/registrationFormChildDataField";
import { AnyRulesetFilterItem, RuleSet } from "~shared/types/ruleSet";
import { IRegistrationFormSection } from "~shared/types/section";
import { IRegistrationFormStep } from "~shared/types/step";

const Separator = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.palette.grey[20]};
	margin-bottom: 1rem;
`;

const NameRow = styled.div`
	font-weight: bold;
	align-items: center;
	display: grid;
	grid-template-columns: 1fr max-content;
	gap: ${({ theme }) => theme.spacing(4)};
`;

interface Props {
	filterItem: AnyRulesetFilterItem;
	ruleSets: RuleSet[];
	name: string;
	onDelete: () => void;
}
export const RuleSetFilterItemFormField: FC<Props> = ({ filterItem, name, ruleSets, onDelete }) => {
	const { t } = useTranslation();
	const deleteButton = (
		<IconButton type="button" size="small" title={t("Delete filter item")} onClick={onDelete} icon="delete" />
	);

	if (filterItem.type === "color") {
		return (
			<div>
				<Separator />
				<NameRow>
					{t("Color")}
					{deleteButton}
				</NameRow>
				<ColorSelectField name={`${name}.value`} label={t("Choose a color")} />
			</div>
		);
	}
	if (filterItem.type === "rule") {
		return (
			<div>
				<Separator />
				<NameRow>
					<span>{t("Rule")}</span>
					{deleteButton}
				</NameRow>
				<SingleSelectField
					name={`${name}.filterItem.value`}
					label={t("Choose a data field")}
					options={ruleSets
						.flatMap((x) => x.rules)
						.reduce((dataFields, rule) => {
							if (!dataFields.find((x) => x.id === rule.sectionDataField.dataField.id)) {
								return [...dataFields, rule.sectionDataField.dataField];
							}
							return dataFields;
						}, [] as IRegistrationFormDataField[])}
					getLabel={(x) => x.name}
					getValue={(x) => x}
				/>
			</div>
		);
	}
	if (filterItem.type === "action") {
		return (
			<div>
				<Separator />
				<NameRow>
					<span>{t("Action") + " - " + t(`ActionType.${filterItem.filterItem.type}`)}</span>
					{deleteButton}
				</NameRow>
				{filterItem.filterItem.type === "showDataField" && (
					<SingleSelectField
						name={`${name}.filterItem.value`}
						label={t("Choose a data field")}
						options={ruleSets
							.flatMap((x) => x.actions)
							.reduce((dataFields, action) => {
								if (
									action.type === ActionTypes.SHOW_DATA_FIELD &&
									!dataFields.find((x) => x.id === action.value.dataField.id)
								) {
									return [...dataFields, action.value.dataField];
								}
								return dataFields;
							}, [] as IRegistrationFormDataField[])}
						getLabel={(x) => x.name}
						getValue={(x) => x}
					/>
				)}
				{filterItem.filterItem.type === "showSection" && (
					<SingleSelectField
						name={`${name}.filterItem.value`}
						label={t("Choose a section")}
						options={ruleSets
							.flatMap((x) => x.actions)
							.reduce((dataFields, action) => {
								if (action.type === ActionTypes.SHOW_SECTION && !dataFields.find((x) => x.id === action.value.id)) {
									return [...dataFields, action.value];
								}
								return dataFields;
							}, [] as IRegistrationFormSection[])}
						getLabel={(x) => x.name}
						getValue={(x) => x}
					/>
				)}
				{filterItem.filterItem.type === "showStep" && (
					<SingleSelectField
						name={`${name}.filterItem.value`}
						label={t("Choose a step")}
						options={ruleSets
							.flatMap((x) => x.actions)
							.reduce((dataFields, action) => {
								if (action.type === ActionTypes.SHOW_STEP && !dataFields.find((x) => x.id === action.value.id)) {
									return [...dataFields, action.value];
								}
								return dataFields;
							}, [] as IRegistrationFormStep[])}
						getLabel={(x) => x.name}
						getValue={(x) => x}
					/>
				)}
			</div>
		);
	}
	return null;
};
