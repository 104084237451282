import { LaravelResponse } from "@dgs/core";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { QrCode } from "~admin/shared/types/qrCode";

export const useQrCodeQuery = (value: string) => {
	const { data, isLoading } = useQuery({
		queryKey: ["qr-code", value],
		queryFn: () =>
			axios.get<LaravelResponse<QrCode>>("qr-code", { params: { qrCodeValue: value } }).then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};
