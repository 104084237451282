import React, { FC, ReactNode } from "react";
import { StructureStepChild } from "~shared/types/registrationFormTypes";
import { BlockIcon } from "../blocks/BlockIcon";
import { RegistrationFormBlockContextMenu } from "../blocks/RegistrationFormBlockContextMenu";
import { StructureHeader } from "../common/StructureHeader";
import { StructureHeaderSpacer } from "../common/StructureHeaderSpacer";
import { isBlock, isSection, revertId } from "../registrationFormDndUtils";
import { RegistrationFormSectionContextMenu } from "../sections/RegistrationFormSectionContextMenu";

export interface StepChildHeaderProps {
	registrationFormId: string;
	stepId: string;
	stepChild: StructureStepChild;
	hideDataFieldOptions?: boolean;
}

interface Props extends StepChildHeaderProps {
	indicator: ReactNode;
}

export const StepChildHeader: FC<Props> = ({
	hideDataFieldOptions,
	stepChild,
	indicator,
	registrationFormId,
	stepId,
}) => {
	return (
		<StructureHeader>
			{indicator}
			{isBlock(stepChild) && <BlockIcon />}
			{stepChild.name}
			<StructureHeaderSpacer />
			{isSection(stepChild) && (
				<RegistrationFormSectionContextMenu
					hideDataFieldOptions={hideDataFieldOptions}
					registrationFormId={registrationFormId}
					registrationFormStepId={revertId(stepId)}
					registrationFormSection={{
						...stepChild,
						id: revertId(stepChild.id),
						children: stepChild.children.map((child) => ({ ...child, id: revertId(child.id) })),
					}}
				/>
			)}
			{isBlock(stepChild) && (
				<RegistrationFormBlockContextMenu
					registrationFormId={registrationFormId}
					registrationFormStepId={revertId(stepId)}
					registrationFormBlockIdentifier={revertId(stepChild.id)}
				/>
			)}
		</StructureHeader>
	);
};
