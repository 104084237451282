import { Factory } from "@dgs/core";
import { emailBlock } from "./emailBlock";
import { emailButtonBlock } from "./emailButtonBlock";
import { emailHeadlineBlock } from "./emailHeadlineBlock";
import { emailImageBlock } from "./emailImageBlock";
import { emailOrderSummaryBlock } from "./emailOrderSummaryBlock";
import { emailPersonalizedButtonBlock } from "./emailPersonalizedButtonBlock";
import { emailSummaryBlock } from "./emailSummaryBlock";
import { emailTextBlock } from "./emailTextBlock";

export const pmBlockFactory: Factory = (type) => {
	switch (type) {
		case "emailTextBlock":
			return emailTextBlock;
		case "emailSummaryBlock":
			return emailSummaryBlock;
		case "emailPersonalizedButtonBlock":
			return emailPersonalizedButtonBlock;
		case "emailButtonBlock":
			return emailButtonBlock;
		case "emailHeadlineBlock":
			return emailHeadlineBlock;
		case "emailBlock":
			return emailBlock;
		case "emailImageBlock":
			return emailImageBlock;
		case "emailOrderSummaryBlock":
			return emailOrderSummaryBlock;

		default:
			throw new Error(`Unknown BlockType in Factory Extension: ${type}`);
	}
};
