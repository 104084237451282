import { IconButton } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { GuestListBulkEditAction } from "~admin/guests/GuestListBulkEditAction";
import { emailKeys, emailService } from "~shared/api/emails";
import { useGuestFilter } from "~shared/guestFilters";
import { BaseGuestList } from "~shared/guests";
import { BulkDeleteCompanionAction } from "~shared/guests/BulkDeleteCompanionAction";
import { LocalGuestViewSwitcher } from "~shared/guests/GuestViewSwitchers";
import { useGuestView } from "~shared/providers/useGuestView";

interface Props {
	status: "successful" | "failed";
	heading: string;
}

export const EmailSendOutGuestsList: FC<Props> = ({ status, heading }) => {
	const { t } = useTranslation();
	const { currentGuestView } = useGuestView();
	const { filters, mapGuestFilterToGuestFilterRequest, handleOpen } = useGuestFilter();
	const { sendOutId = "" } = useParams<"sendOutId">();
	const guestFilterRequest = useMemo(
		() => mapGuestFilterToGuestFilterRequest(filters),
		[mapGuestFilterToGuestFilterRequest, filters],
	);

	return (
		<BaseGuestList
			queryKey={emailKeys.sendOuts.filteredGuests(status, sendOutId, currentGuestView.id, filters)}
			fetcher={emailService.sendOuts.sendOutFilteredGuests(status, sendOutId, currentGuestView.id, guestFilterRequest)}
			headerActions={
				<>
					<LocalGuestViewSwitcher />
					<IconButton size="small" title={t("Guest filter")} onClick={handleOpen} icon="filter" />
				</>
			}
			bulkEditActions={
				<>
					<GuestListBulkEditAction filters={guestFilterRequest} />
					<BulkDeleteCompanionAction filters={guestFilterRequest} />
				</>
			}
			baseUrl={"../../../../guests/"}
			name={`${status}Recipients`}
			heading={heading}
		/>
	);
};
