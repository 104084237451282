import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { merchantService } from "~admin/shared/api/shop/merchants";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
	required?: boolean;
	clearable?: boolean;
}

const fetcher = (search: string, page: number) => merchantService.options(search, page).then((r) => r.data);

export const MerchantSingleSelectField: FC<Props> = ({ name, label, disabled, required, clearable = true }) => {
	return (
		<PagedSingleSelectField
			name={name}
			queryKey={merchantService.keys.options}
			fetcher={fetcher}
			label={label}
			disabled={disabled}
			clearable={clearable}
			required={required}
		/>
	);
};
