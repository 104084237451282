import { parse } from "date-fns";
import format from "date-fns/format";

export const parseDate = (date: string) => {
	return parse(date, "yyyy-MM-dd", new Date());
};

export const toISODateOnlyString = (date: Date) => {
	return format(date, "yyyy-MM-dd");
};
