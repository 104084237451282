import { BlockSingleSelectField, CardBody, CardFooter, CheckboxField, SubmitButton } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GuestViewSelect } from "~admin/shared/selects/GuestViewSelect";
import { INominationModuleSettings } from "~shared/types/nominationModuleSettings";
import { FormikWithRedirect } from "~shared/ui/FormikWithRedirect";
import { GuestViewAttributeMultiSelect } from "./GuestViewAttributeMultiSelect";

interface Props {
	settings: INominationModuleSettings;
	onSubmit: (values: INominationModuleSettings) => Promise<boolean>;
}

export const SettingsForm: FC<Props> = ({ settings, onSubmit }) => {
	const { t } = useTranslation();

	const validate = useCallback(
		(values: INominationModuleSettings) => {
			const errors: { [K in keyof INominationModuleSettings]?: any } = {};

			if (!values.guestView) {
				errors.guestView = t("Required field");
			}

			return errors;
		},
		[t],
	);

	return (
		<FormikWithRedirect initialValues={settings} onSubmit={onSubmit} validate={validate} enableReinitialize={true}>
			{(formik) => (
				<>
					<CardBody>
						<GuestViewSelect
							name="guestView"
							label={t("Guest view")}
							onChange={() => formik.setFieldValue("requiredGuestAttributeIds", [])}
						/>
						<GuestViewAttributeMultiSelect name="requiredGuestAttributeIds" label={t("Required guest attributes")} />
						<BlockSingleSelectField name="startPage" label={t("Start page")} blockType="pageBlock" />
						<CheckboxField name="showEmails" label={t("Display Email Tab")} />
					</CardBody>
					<CardFooter>
						<SubmitButton>{t("Save")}</SubmitButton>
					</CardFooter>
				</>
			)}
		</FormikWithRedirect>
	);
};
