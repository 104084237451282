import { Button, Icon, apiIsOK, useEditDrawer, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { DataFieldDrawer } from "~admin/dataFields/DataFieldDrawer";
import { DataFieldOptionsDrawer } from "~admin/dataFields/dataFieldOptions/DataFieldOptionsDrawer";
import { dataFieldService } from "~shared/api/dataFields";
import { guestAttributeKeys } from "~shared/api/guestAttributes";
import { guestKeys } from "~shared/api/guests";
import { guestViewService } from "~shared/api/guestViews";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import {
	DataFieldTranslatableAttributes,
	IDataFieldFormState,
	IDataFieldUpdateResource,
	hasDataFieldOptions,
} from "~shared/types/dataField";
import { DataFieldType } from "~shared/types/dataFieldType";

export const CreateDataFieldButton: FC = () => {
	const { t } = useTranslation();
	const drawerProps = useEditDrawer();
	const optionsDrawerProps = useEditDrawer();
	const { locales } = useContentLocale();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();

	const createDataField = useCallback(
		async (dataField: IDataFieldUpdateResource) => {
			const dataFieldResponse = await dataFieldService.store(dataField);

			if (apiIsOK(dataFieldResponse)) {
				showToast({ body: t("Data field was saved."), title: t("Save data field"), type: "success" });
				await queryClient.resetQueries({ queryKey: dataFieldService.keys.index });
				await queryClient.resetQueries({ queryKey: guestAttributeKeys.index });
				await queryClient.resetQueries({ queryKey: guestViewService.keys.list });
				await queryClient.resetQueries({ queryKey: guestKeys.index });
				if (hasDataFieldOptions(dataField.type)) {
					optionsDrawerProps.handleOpen(dataFieldResponse.data.data.id);
				}
				return true;
			}
			return false;
		},
		[optionsDrawerProps, queryClient, showToast, t],
	);

	const emptyDataField: IDataFieldFormState = {
		id: -1,
		name: "",
		type: DataFieldType.TEXT,
		translations: locales.reduce(
			(translations, locale) => ({
				...translations,
				[locale]: {
					label: "",
					placeholder: "",
					description: "",
					dataPrivacyInfo: "",
				},
			}),
			{} as Record<string, DataFieldTranslatableAttributes>,
		),
	};

	return (
		<>
			<Button
				type="button"
				onClick={() => drawerProps.handleOpen(-1)}
				color="primary"
				title={t("Create data field")}
				prefix={<Icon icon="plus" title={t("Create data field")} />}
			>
				{t("Data field")}
			</Button>
			<DataFieldDrawer
				{...drawerProps}
				heading={t("Add data field")}
				initialValues={emptyDataField}
				onSubmit={(entityFormState) => createDataField(entityFormState)}
			/>
			<DataFieldOptionsDrawer {...optionsDrawerProps} />
		</>
	);
};
