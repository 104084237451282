import {
	CheckboxControl,
	ConfirmationDialog,
	ContextMenu,
	ContextMenuItem,
	Id,
	SingleSelectControl,
	apiIsOK,
	useToasts,
} from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { guestKeys, guestService } from "~shared/api/guests";
import { hotelInquiryService } from "~shared/api/hotelInquiries";
import { IRoomGuest } from "~shared/types/room";
import { IRoomWithAllRelationsResource } from "~shared/types/roomContingent";

interface Props {
	guestId: Id;
	room: IRoomWithAllRelationsResource;
	isMain: boolean;
}

export const GuestHotelsListContextMenu: FC<Props> = ({ room, guestId, isMain }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const [detachAllGuests, setDetachAllGuests] = useState<boolean>(false);
	const roomMates: IRoomGuest[] = room.guests.filter((guest) => guest.id !== guestId);
	const [newMainGuestId, setNewMainGuestId] = useState<Id | null>(roomMates.length ? roomMates[0].id : null);
	const [open, setOpen] = useState<boolean>(false);

	const onDeleteClick = useCallback(async () => {
		const res = await guestService.detachRooms(guestId, {
			roomIds: [room.id],
			detachAll: detachAllGuests,
			newMainGuestId: newMainGuestId ?? undefined,
		});
		if (apiIsOK(res)) {
			showToast({ body: t("Room booking was deleted."), title: t("Delete room booking"), type: "success" });
			await queryClient.invalidateQueries({ queryKey: guestKeys.index });
			await queryClient.invalidateQueries({ queryKey: hotelInquiryService.keys.index });
		}
	}, [detachAllGuests, guestId, newMainGuestId, queryClient, room.id, showToast, t]);

	return (
		<>
			<ContextMenu displayShortcutsAs="icon">
				<ContextMenuItem action={() => setOpen(true)} title={t("Delete")} label={t("Delete")} icon="delete" />
			</ContextMenu>
			<ConfirmationDialog
				heading={t("Delete")}
				labels={{ close: t("Close"), confirm: t("Confirm") }}
				open={open}
				onClose={() => setOpen(false)}
				action={() => onDeleteClick()}
			>
				{isMain && (
					<>
						<CheckboxControl
							label={t("Delete all guest from this booking")}
							name={"deleteAll"}
							value={detachAllGuests}
							onChange={(e) => setDetachAllGuests(e.target.value)}
						/>
						{!detachAllGuests && (
							<>
								<SingleSelectControl
									label={t("Select New Main Guest")}
									name="mainSelect"
									onChange={(e) => setNewMainGuestId(e.target.value)}
									getLabel={(guest) => guest.firstName}
									value={newMainGuestId}
									options={roomMates}
									getValue={(guest) => guest.id}
									clearable={false}
									required
								/>
							</>
						)}
					</>
				)}
				{detachAllGuests
					? t("Are you sure you want to delete this room booking for all guests?")
					: t("Are you sure you want to delete this room booking?")}
			</ConfirmationDialog>
		</>
	);
};
