import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { StatusSelect } from "~shared/guests/details/StatusSelect";
import { LocaleSelect } from "~shared/selects/LocaleSelect";
import { GuestViewColumnAttribute } from "~shared/types/guestView";

interface Props {
	attribute: GuestViewColumnAttribute;
	required: boolean;
}

export const GuestAttributeFieldColumn: FC<Props> = ({ attribute, required }) => {
	const { t } = useTranslation();

	switch (attribute.entityType) {
		case "status":
			return <StatusSelect name={attribute.id.toString()} label={t("Status")} clearable={false} required={required} />;
		case "registrationLocale":
			return <LocaleSelect name={attribute.id.toString()} label={t("Registration locale")} required={required} />;
		default:
			return null;
	}
};
