import { FormattedDate, IDataListColumn } from "@dgs/core";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { IHotelInquiryIndex } from "~shared/types/hotelInquiry";

export const useHotelInquiriesListColumns = () => {
	const { t } = useTranslation();
	const dataListColumns: IDataListColumn<IHotelInquiryIndex>[] = useMemo(
		() => [
			{
				heading: t("Status"),
				valueKey: "guestStatus",
				type: "value",
				render: (hotelInquiry) => t(hotelInquiry.guestStatus),
			},
			{
				heading: t("First name"),
				valueKey: "guestFirstName",
				type: "value",
			},
			{
				heading: t("Last name"),
				valueKey: "guestLastName",
				type: "value",
			},
			{
				heading: t("Email"),
				valueKey: "guestEmail",
				type: "value",
			},
			{
				heading: t("Tags"),
				valueKey: "guestTags",
				type: "value",
				render: (hotelInquiry) => hotelInquiry.guestTags.map(($i) => $i.name),
			},
			{
				heading: t("Groups"),
				valueKey: "guestGroups",
				type: "value",
				render: (hotelInquiry) => hotelInquiry.guestGroups.map(($i) => $i.name),
			},
			{
				heading: t("Desired hotel"),
				valueKey: "hotelName",
				type: "value",
			},
			{
				heading: t("Desired room type"),
				valueKey: "desiredRoomType",
				type: "value",
				render: (hotelInquiry) => hotelInquiry.desiredRoomType?.name,
			},
			{
				heading: t("Check-in"),
				valueKey: "checkIn",
				type: "value",
				render: (hotelInquiry) => <FormattedDate date={hotelInquiry.checkIn} />,
			},
			{
				heading: t("Check-out"),
				valueKey: "checkOut",
				type: "value",
				render: (hotelInquiry) => <FormattedDate date={hotelInquiry.checkOut} />,
			},
			{
				heading: t("Comment"),
				valueKey: "comment",
				type: "value",
			},
			{
				heading: t("Processed by"),
				valueKey: "processedBy",
				type: "value",
			},
		],
		[t],
	);
	return dataListColumns;
};
