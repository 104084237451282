import { BaseCrudOptionService, Id, keyToUrl } from "@dgs/core";
import axios from "axios";
import {
	PersonalizedDocument,
	PersonalizedDocumentExportRequest,
	PersonalizedDocumentIndexResource,
	PersonalizedDocumentOptionsResource,
	PersonalizedDocumentRequest,
} from "~shared/types/personalizedDocument";
import { personalizedDocumentKeys } from "./personalizedDocumentKeys";

class PersonalizedDocumentService extends BaseCrudOptionService<
	PersonalizedDocumentIndexResource,
	PersonalizedDocument,
	PersonalizedDocumentOptionsResource,
	PersonalizedDocumentRequest
> {
	public keys = { ...personalizedDocumentKeys, export: ["personalized-documents", "export"] };

	protected urls = {
		index: keyToUrl(personalizedDocumentKeys.index),
		details: (consentId: Id) => keyToUrl(personalizedDocumentKeys.details(consentId)),
		options: keyToUrl(personalizedDocumentKeys.options),
		export: keyToUrl(this.keys.export),
	};

	exportDocuments = (request: PersonalizedDocumentExportRequest) => axios.post(this.urls.export, request);
}

export const personalizedDocumentService = new PersonalizedDocumentService();
