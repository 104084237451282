import {
	AutoSidebar,
	SidebarItem,
	SystemMessagesAccordion,
	VersionAnchor,
	useDynamicConfig,
	useIsAddonEnabled,
	useUserPermission,
} from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useSystemMessages } from "~admin/shared/api/systemMessages";
import { PermissionName } from "~admin/shared/types/permission";
import { useReleaseVersion } from "~admin/shared/version/useReleaseVersion";
import { SidebarHeader } from "./SidebarHeader";

const StyledAutoSidebar = styled(AutoSidebar)`
	position: relative;
	z-index: ${({ theme }) => theme.zIndices.content.l2};
`;

export const Sidebar: FC = () => {
	const { t } = useTranslation();
	const { permissions } = useUserPermission();
	const showWaitingListBanner = !useIsAddonEnabled("waitingList");
	const showHotelBanner = !useIsAddonEnabled("hotel");
	const showShopBanner = !useIsAddonEnabled("shop");
	const showNominationModuleBanner = !useIsAddonEnabled("nominationModule");
	const showCheckInAppBanner = !useIsAddonEnabled("checkInApp");
	const cmsEnabled = useIsAddonEnabled("cms");
	const cmsProEnabled = useIsAddonEnabled("cmsPro");

	const { showReleaseNotice, setShowReleaseNotice } = useReleaseVersion();
	const { systemMessages } = useSystemMessages(
		showReleaseNotice
			? [
					{
						id: "showReleaseNotice",
						type: "warning",
						text: t("A new update is available! Curious? Click here."),
						href: "https://dgs.zendesk.com/hc/articles/12789744622098",
						target: "_blank",
						onClick: () => setShowReleaseNotice(false),
					},
			  ]
			: [],
	);
	const { release } = useDynamicConfig();

	const sidebarItems = useMemo<SidebarItem<PermissionName>[]>(
		() => [
			{
				icon: "menu",
				key: t("Dashboard"),
				permissions: ["dashboard.read"],
				to: "dashboard",
			},
			{
				icon: "people",
				key: t("Guests"),
				permissions: ["guest.read"],
				to: "guests",
			},
			{
				icon: "layers",
				key: t("Guest views"),
				permissions: ["guest.read"],
				to: "guest-views",
			},
			{
				icon: "filter",
				key: t("Guest filters"),
				permissions: ["guest.read"],
				to: "guest-filters",
			},
			{
				icon: "people",
				key: t("Waiting Lists"),
				permissions: ["waitingList.read"],
				showAddonBanner: showWaitingListBanner,
				to: "waiting-list",
			},
			{
				icon: "sidebar_group",
				key: t("Grouping"),
				permissions: ["group.read", "tag.read"],
				to: "grouping",
				children: [
					{
						icon: "group",
						key: t("Groups"),
						permissions: ["group.read"],
						to: "grouping/groups",
					},
					{
						icon: "tags",
						key: t("Tags"),
						permissions: ["tag.read"],
						to: "grouping/tags",
					},

					{
						icon: "tags",
						key: t("Guest file categories"),
						permissions: ["guestFileCategory.read"],
						to: "grouping/file-categories",
					},
				],
			},
			{
				icon: "list",
				key: t("Data fields"),
				permissions: ["dataField.read", "dataFieldSettings.read"],
				to: "data-fields",
				children: [
					{
						icon: "list",
						key: t("Overview"),
						permissions: ["dataField.read"],
						to: "data-fields/overview",
					},
					{
						icon: "contingent",
						key: t("Contingent overview"),
						permissions: ["dataField.read"],
						to: "data-fields/contingent-overview",
					},
					{
						icon: "settings",
						key: t("Settings"),
						permissions: ["dataFieldSettings.read"],
						to: "data-fields/settings",
					},
				],
			},
			{
				src: "/icons/placeholder/paste_text.svg",
				key: t("Placeholders"),
				permissions: ["placeholder.read"],
				to: "placeholders",
			},
			{
				icon: "pop_over",
				key: t("Emails"),
				permissions: ["customEmail.read", "sendOut.create", "sendOut.read"],
				to: "emails",
				children: [
					{
						icon: "list",
						key: t("Email list"),
						permissions: ["customEmail.read"],
						to: "emails/list",
					},
					{
						icon: "inventory",
						key: t("Email blocks"),
						permissions: ["block.read"],
						to: "emails/blocks",
					},
					{
						icon: "list",
						key: t("Email logs"),
						permissions: ["emailLog.read"],
						to: "emails/logs/list",
					},
					{
						icon: "send",
						key: t("Email send out"),
						permissions: ["sendOut.create"],
						to: "emails/send-outs/send",
					},
					{
						icon: "send",
						key: t("Email send outs"),
						permissions: ["sendOut.read"],
						to: "emails/send-outs/list",
					},
				],
			},
			{
				icon: "layers",
				key: t("Content management"),
				permissions: ["block.read", "image.read"],
				showAddonBanner: !cmsEnabled && !cmsProEnabled,
				children: [
					{
						icon: "layers",
						key: t("Pages"),
						permissions: ["block.read"],
						to: "content-management/blocks/pages",
					},
					{
						icon: "inventory",
						key: t("Blocks"),
						permissions: ["block.read"],
						to: "content-management/blocks/all",
					},
					{
						icon: "images",
						key: t("Images"),
						permissions: ["image.read"],
						to: "content-management/images",
					},
					{
						icon: "files",
						key: t("Files"),
						permissions: ["file.read"],
						to: "content-management/files",
					},
				],
			},
			{
				icon: "inventory",
				key: t("Registration forms"),
				permissions: ["registrationForm.read"],
				to: "registration-forms",
			},
			{
				icon: "swap_vertical",
				key: t("Export & Import"),
				permissions: ["import.read", "export.read"],
				to: "export-import",
				children: [
					{
						icon: "file_download",
						key: t("Exports"),
						permissions: ["export.read"],
						to: "export-import/exports",
					},
					{
						icon: "file_upload",
						key: t("Import"),
						permissions: ["import.read"],
						to: "export-import/import",
					},
				],
			},
			{
				src: "/icons/hotel/hotelmanager.svg",
				key: t("Hotel manager"),
				permissions: ["hotel.read"],
				to: "hotel-management",
				showAddonBanner: showHotelBanner,
				children: [
					{
						icon: "plus",
						key: t("Create hotel"),
						permissions: ["hotel.read"],
						to: "hotel-management/create-hotel",
					},
					{
						src: "/icons/hotel/hotels.svg",
						key: t("Hotels"),
						permissions: ["hotel.read"],
						to: "hotel-management/hotels",
					},
					{
						src: "/icons/hotel/rooms.svg",
						key: t("Room types"),
						permissions: ["roomType.read"],
						to: "hotel-management/room-types",
					},
					{
						src: "/icons/hotel/requests.svg",
						key: t("Requests"),
						permissions: ["hotel.read"],
						to: "hotel-management/requests",
					},
					{
						src: "/icons/hotel/bookings.svg",
						key: t("Bookings"),
						permissions: ["hotel.read"],
						to: "hotel-management/bookings",
					},
					{
						src: "/icons/hotel/contingent.svg",
						key: t("Contingent"),
						permissions: ["hotel.read"],
						to: "hotel-management/contingents",
					},
					{
						icon: "settings",
						key: t("Settings"),
						permissions: ["hotelSettings.read"],
						to: "hotel-management/settings",
					},
				],
			},
			{
				icon: "menu",
				key: "Menu",
				permissions: ["menuItem.read"],
				to: "menu",
			},
			{
				src: "/icons/shop/credit_card.svg",
				key: t("Shop"),
				permissions: ["shopSettings.read"],
				showAddonBanner: showShopBanner,
				to: "shop",
				children: [
					{
						icon: "layers",
						key: t("Shop items"),
						permissions: ["shopItem.read", "shopItem.update"],
						to: "shop/items",
					},
					{
						src: "/icons/shop/shop.svg",
						key: t("Payment provider"),
						permissions: ["paymentProvider.read", "paymentProvider.update"],
						to: "shop/payment-provider",
					},
					{
						src: "/icons/shop/shop.svg",
						key: t("Invoice provider"),
						permissions: ["invoiceProvider.read", "invoiceProvider.update"],
						to: "shop/invoice-provider",
					},
					{
						src: "/icons/shop/store_front.svg",
						key: t("Merchants"),
						permissions: ["merchant.read"],
						to: "shop/merchants",
					},
					{
						src: "/icons/shop/receipt_long.svg",
						key: t("Taxes"),
						permissions: ["tax.read", "tax.update"],
						to: "shop/taxes",
					},
					{
						src: "/icons/hotel/bookings.svg",
						key: t("Orders"),
						permissions: ["hotel.read"],
						to: "shop/orders",
					},
					{
						src: "/icons/shop/receipt_long.svg",
						key: t("Invoices"),
						permissions: ["invoice.read"],
						to: "shop/invoices",
					},
					{
						icon: "settings",
						key: t("Settings"),
						permissions: ["shopSettings.read", "shopSettings.update"],
						to: "shop/settings",
					},
				],
			},
			{
				src: "/icons/checkInApp/app.svg",
				key: t("Check In App"),
				permissions: ["location.read"],
				showAddonBanner: showCheckInAppBanner,
				to: "checkInApp",
				children: [
					{
						src: "/icons/checkInApp/locations.svg",
						key: t("Locations"),
						permissions: ["location.read"],
						to: "checkInApp/locations",
					},
				],
			},
			{
				icon: "warning",
				key: t("Users & Roles"),
				permissions: ["user.read", "role.read"],
				to: "users-roles",
				children: [
					{
						icon: "person",
						key: t("Users"),
						permissions: ["user.read"],
						to: "users-roles/users",
					},
					{
						icon: "lock_open",
						key: t("Roles"),
						permissions: ["role.read"],
						to: "users-roles/roles",
					},
				],
			},
			{
				icon: "info",
				key: t("Consents"),
				permissions: ["consent.read"],
				to: "consents",
			},
			{
				icon: "swap_vertical",
				key: t("Nomination Module"),
				permissions: ["tenant.read"],
				showAddonBanner: showNominationModuleBanner,
				children: [
					{
						icon: "file_download",
						key: t("Tenants"),
						permissions: ["tenant.read"],
						to: "nomination/tenants",
					},
					{
						icon: "file_download",
						key: t("Waves"),
						permissions: ["wave.read"],
						to: "nomination/waves/list",
					},
					{
						icon: "contingent",
						key: t("Contingent overview"),
						permissions: ["wave.read"],
						to: "nomination/waves/overview",
					},
					{
						icon: "settings",
						key: t("Settings"),
						permissions: ["nominationModuleSettings.read"],
						to: "nomination/settings",
					},
				],
			},
			{
				icon: "settings",
				key: t("Settings"),
				permissions: ["productData.read"],
				children: [
					{
						icon: "settings",
						key: t("General settings"),
						permissions: ["productData.read"],
						to: "settings/general",
					},
					{
						icon: "settings",
						key: t("Document settings"),
						permissions: ["personalizedDocumentSettings.read", "personalizedDocumentSettings.update"],
						to: "settings/personalized-documents",
					},
					{
						icon: "files",
						key: t("Personalized documents"),
						permissions: ["personalizedDocument.create", "personalizedDocument.update"],
						to: "settings/personalized-documents-overview",
					},
					{
						src: "/icons/translate.svg",
						key: t("Product translations"),
						permissions: ["productTranslations.read"],
						to: "settings/product-translations",
					},
				],
			},
		],
		[
			cmsEnabled,
			cmsProEnabled,
			showCheckInAppBanner,
			showHotelBanner,
			showNominationModuleBanner,
			showShopBanner,
			showWaitingListBanner,
			t,
		],
	);
	return (
		<StyledAutoSidebar
			header={<SidebarHeader />}
			sidebarItems={sidebarItems}
			permissions={permissions}
			systemMessages={!!systemMessages?.length && <SystemMessagesAccordion messages={systemMessages} />}
			footer={
				<VersionAnchor target="_blank" href="https://dgs.zendesk.com/hc/articles/12789744622098">
					{t("Version {{version}}", { version: release })}
				</VersionAnchor>
			}
		/>
	);
};
