import { Button, CrudDrawer, DrawerFooterActions, EntityDrawerProps, TextField } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { roomContingentKeys } from "~shared/api/contingent";
import { IRoomFormValues } from "~shared/types/room";
import { ITenantFormState } from "~shared/types/tenant";

const WarningBlock = styled.div`
	height: 100%;
	position: absolute;
	top: ${({ theme }) => theme.spacing(12)};
	z-index: ${({ theme }) => theme.zIndices.content.l4};
	background-color: ${({ theme }) => theme.colors.layout.content.background};
`;

const WarningText = styled.span`
	display: block;
	padding: ${({ theme }) => theme.spacing(2)} 0;
	margin-right: ${({ theme }) => theme.spacing(8)};
`;

interface Props extends EntityDrawerProps<IRoomFormValues> {
	confirmationAccepted: boolean;
}

export const RoomDrawer: FC<Props> = (props) => {
	const { t } = useTranslation();
	const queryClient = useQueryClient();
	const { hotelId = "", roomTypeId = "", roomContingentId = "" } = useParams();
	const [accepted, setAccepted] = useState<boolean>(props.confirmationAccepted);

	const validate = useCallback(
		(values: IRoomFormValues) => {
			const errors: { [K in keyof ITenantFormState]?: any } = {};

			if (!values.name) {
				errors.name = t("Required field");
			}

			return errors;
		},
		[t],
	);

	const handleSubmit = useCallback(
		async (values: IRoomFormValues) => {
			const res = await props.onSubmit(values);
			await queryClient.resetQueries({ queryKey: roomContingentKeys.details(hotelId, roomTypeId, roomContingentId) });
			return res;
		},
		[hotelId, roomTypeId, roomContingentId, queryClient, props],
	);

	return (
		<CrudDrawer
			{...props}
			onSubmit={handleSubmit}
			validate={validate}
			body={
				accepted ? (
					<TextField name="name" label={t("Room number")} required />
				) : (
					<WarningBlock>
						<WarningText>
							{t("This action will increment the contingent. Are you sure you want to add new room?")}
						</WarningText>
						<DrawerFooterActions>
							<Button
								type="button"
								onClick={(e) => {
									props.onClose();
									e.stopPropagation();
								}}
							>
								{t("Close")}
							</Button>
							<Button
								type="button"
								color="danger"
								onClick={(e) => {
									setAccepted(true);
									e.stopPropagation();
								}}
							>
								{t("Add")}
							</Button>
						</DrawerFooterActions>
					</WarningBlock>
				)
			}
		/>
	);
};
