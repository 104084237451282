import { Card } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useEmptyHotel } from "~admin/hotels/useEmptyHotel";
import { HotelCreateFormFooter, HotelForm } from "./HotelForm";
import { useHotelForm } from "./useHotelForm";

export const HotelCreatePage: FC = () => {
	const { t } = useTranslation();
	const { createHotel } = useHotelForm();
	const emptyHotel = useEmptyHotel();

	return (
		<Card>
			<HotelForm
				title={t("Create hotel")}
				hotel={emptyHotel}
				onSubmit={createHotel}
				footer={<HotelCreateFormFooter />}
			/>
		</Card>
	);
};
