import {
	DndContext,
	DragEndEvent,
	DragOverEvent,
	DragOverlay,
	DragStartEvent,
	KeyboardSensor,
	PointerSensor,
	closestCorners,
	useSensor,
	useSensors,
} from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import React, { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { StructureStep, StructureStepChild } from "~shared/types/registrationFormTypes";
import { RowContainer } from "./common/RowContainer";
import {
	activeIsStepChild,
	createStepChildUpdate,
	determineParentSteps,
	findStepChildInSteps,
	handleStepChildInSameStepMove,
	handleStepChildToAnotherStepMove,
	handleStepMove,
	isStep,
	isStepChild,
	isStepChildInSameStepMove,
	isStepChildToAnotherStepMove,
	isStepMove,
} from "./registrationFormDndUtils";
import { useRegistrationFormStructure } from "./RegistrationFormStructureProvider";
import { SortableStepChild } from "./stepChildren/SortableStepChild";
import { StepChild } from "./stepChildren/StepChild";
import { CreateStepAction } from "./steps/CreateStepAction";
import { SortableStep } from "./steps/SortableStep";
import { Step } from "./steps/Step";

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-auto-rows: max-content;
	gap: ${({ theme }) => theme.spacing(4)};
`;

interface Props {
	initialSteps: StructureStep[];
}

export const RegistrationFormOrderStructure: FC<Props> = ({ initialSteps }) => {
	const { registrationFormId, moveStep, moveStepChild } = useRegistrationFormStructure();
	const [activeStep, setActiveStep] = useState<StructureStep | null>(null);
	const [activeStepChild, setActiveStepChild] = useState<StructureStepChild | null>(null);
	const [steps, setSteps] = useState(initialSteps);
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		}),
	);

	const isDraggingStep = !!activeStep;

	useEffect(() => {
		setSteps(initialSteps);
	}, [initialSteps]);

	const handleDragStart = ({ active }: DragStartEvent) => {
		if (isStep(active)) {
			setActiveStep(steps.find((s) => s.id === active.id) || null);
		} else if (isStepChild(active)) {
			setActiveStepChild(findStepChildInSteps(steps, active));
		}
	};

	const handleDragOver = (e: DragOverEvent) => {
		if (activeIsStepChild(e)) {
			const parents = determineParentSteps(e, steps);

			if (isStepChildToAnotherStepMove(parents)) {
				setSteps(handleStepChildToAnotherStepMove(parents.activeStep, parents.overStep, e.active, e.over));
			}
		}
	};

	const handleDragEnd = (event: DragEndEvent) => {
		if (isStepMove(event)) {
			setSteps((currentSteps) => {
				const newSteps = handleStepMove(currentSteps, event);

				moveStep(newSteps);

				return newSteps;
			});
		} else if (isStepChildInSameStepMove(steps, event)) {
			setSteps((currentSteps) => {
				const newSteps = handleStepChildInSameStepMove(currentSteps, event);

				moveStepChild(createStepChildUpdate(currentSteps, newSteps, event));

				return newSteps;
			});
		}

		setActiveStep(null);
		setActiveStepChild(null);
	};

	return (
		<Wrapper>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCorners}
				onDragEnd={handleDragEnd}
				onDragOver={handleDragOver}
				onDragStart={handleDragStart}
			>
				<SortableContext items={steps} strategy={verticalListSortingStrategy}>
					{steps.map((step) => (
						<SortableStep key={step.id} registrationFormId={registrationFormId} step={step}>
							<SortableContext items={step.children} strategy={verticalListSortingStrategy}>
								<RowContainer>
									{step.children.map((stepChild) =>
										isDraggingStep ? (
											<StepChild
												key={stepChild.id}
												registrationFormId={registrationFormId}
												stepId={step.id}
												stepChild={stepChild}
												withIndicator={true}
												hideDataFieldOptions={true}
											/>
										) : (
											<SortableStepChild
												key={stepChild.id}
												registrationFormId={registrationFormId}
												stepId={step.id}
												stepChild={stepChild}
												hideDataFieldOptions={true}
											/>
										),
									)}
								</RowContainer>
							</SortableContext>
						</SortableStep>
					))}
				</SortableContext>
				<DragOverlay>
					{activeStep ? (
						<Step step={activeStep} registrationFormId={registrationFormId} isActive={true} withIndicator={true}>
							<RowContainer>
								{activeStep.children.map((stepChild) => (
									<StepChild
										key={`active_${stepChild.id}`}
										registrationFormId={registrationFormId}
										stepId={activeStep.id}
										stepChild={stepChild}
										withIndicator={true}
									/>
								))}
							</RowContainer>
						</Step>
					) : null}

					{activeStepChild ? (
						<StepChild
							registrationFormId={registrationFormId}
							stepId={""}
							stepChild={activeStepChild}
							isActive={true}
							withIndicator={true}
							hideDataFieldOptions={true}
						/>
					) : null}
				</DragOverlay>
			</DndContext>
			<CreateStepAction />
		</Wrapper>
	);
};
