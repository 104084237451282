import {
	DateField,
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerHeading,
	RenderProps,
	SubmitButton,
	apiIsOK,
	useToasts,
} from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { invoiceService } from "~admin/shared/api/shop/invoices/invoiceService";
interface Values {
	start: string | null;
	end: string | null;
}

const initialValues: Values = {
	start: null,
	end: null,
};

export const InvoiceDownloadDrawer: FC<RenderProps> = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const { showToast } = useToasts();

	const onSubmit = useCallback(
		async (values: Values) => {
			const response = await invoiceService.export({
				invoiceIds: [],
				start: values.start as string,
				end: values.end as string,
			});

			if (apiIsOK(response)) {
				showToast({ type: "success", title: t("Export"), body: t("An export is being created, please wait.") });
				handleClose();
			}
		},
		[handleClose, showToast, t],
	);

	const validate = useCallback(
		(values: Values) => {
			const errors: { [K in keyof Values]?: string } = {};

			(["start", "end"] as (keyof Values)[]).forEach((k) => {
				if (!values[k]) {
					errors[k] = t("Field is required");
				}
			});

			if (values.start && values.end && values.end < values.start) {
				errors.start = t("Start has to be before end");
			}

			return errors;
		},
		[t],
	);

	return (
		<Drawer open={open} onClose={handleClose}>
			<DrawerHeader onClose={handleClose}>
				<DrawerHeading>{t("Download invoices")}</DrawerHeading>
			</DrawerHeader>
			<Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
				<Form>
					<DrawerBody>
						<DateField name="start" label={t("Start date")} />
						<DateField name="end" label={t("End date")} />
					</DrawerBody>
					<DrawerFooter>
						<SubmitButton>{t("Download")}</SubmitButton>
					</DrawerFooter>
				</Form>
			</Formik>
		</Drawer>
	);
};
