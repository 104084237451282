import { FCC, Id, apiIsOK, useToasts } from "@dgs/core";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ordersKeys } from "~admin/shared/api/orders";
import { dashboardKeys } from "~shared/api/dashboard";
import { dataFieldService, useDataFieldsQuery } from "~shared/api/dataFields";
import { emailKeys } from "~shared/api/emails";
import { guestFilterKeys } from "~shared/api/guestFilters";
import { guestKeys, guestService, useGuestQuery } from "~shared/api/guests";
import { hotelInquiryService } from "~shared/api/hotelInquiries";
import { waveService } from "~shared/api/waves/waveService";
import { useGlobalGuestView } from "~shared/providers/GlobalGuestViewProvider";
import { DataFieldSettings, IDataFieldIndexResource } from "~shared/types/dataField";
import { IDataFieldOptionIndexResource } from "~shared/types/dataFieldOption";
import { DataFieldType } from "~shared/types/dataFieldType";
import { Group } from "~shared/types/group";
import { GuestAttributeSaveRequest, GuestFormValues } from "~shared/types/guest";
import { GuestDetailsResource } from "~shared/types/newGuestTypes";
import { Tag } from "~shared/types/tag";
import { FormikHelpersWithRedirect } from "~shared/ui/FormikWithRedirect";

interface GuestDetailsContextState {
	isNew: boolean;
	isLoading: boolean;
	guest: GuestDetailsResource | null;
	dataFields: IDataFieldIndexResource[];
	dataFieldSettings: DataFieldSettings | null;
	requiredGuestAttributeIds: Id[];
	handleSubmit: (
		guest: GuestFormValues,
		helpers: FormikHelpersWithRedirect<GuestFormValues>,
		shouldGoBack: boolean,
	) => Promise<boolean>;
	handleDelete: (guestId: Id) => Promise<void>;
}

const excludedAttributeTypes = [
	"updatedAt",
	"createdAt",
	"registrationTimestamp",
	"registrationCode",
	"registrationId",
];

interface Props {
	requiredGuestAttributeIds: Id[];
}

const GuestDetailsContext = React.createContext<GuestDetailsContextState | undefined>(undefined);
export const GuestDetailsProvider: FCC<Props> = ({ requiredGuestAttributeIds, children }) => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const { guestId = "" } = useParams<"guestId">();
	const isNew = guestId === "new";
	const { globalGuestView } = useGlobalGuestView();
	const { data: dataFields, isLoading: dataFieldsLoading } = useDataFieldsQuery();
	const { data: guest, isLoading } = useGuestQuery(guestId, globalGuestView.id);
	const { data: dataFieldSettings, isLoading: dataFieldSettingsLoading } = useQuery({
		queryKey: dataFieldService.keys.settings,
		queryFn: () => dataFieldService.getSettings().then((x) => x.data.data),
	});

	const mapFormValuesToRequest = useCallback(
		(formValues: GuestFormValues): GuestAttributeSaveRequest[] =>
			Object.keys(formValues)
				.filter((attributeId) => {
					const guestAttribute = globalGuestView.guestAttributes.find(
						(a) => a.id.toString() === attributeId.toString(),
					);

					if (!guestAttribute) {
						throw new Error("Unknown attribute");
					}

					return !excludedAttributeTypes.includes(guestAttribute.entityType.toString());
				})
				.map((attributeId) => {
					const guestAttribute = globalGuestView.guestAttributes.find(
						(a) => a.id.toString() === attributeId.toString(),
					);

					if (!guestAttribute) {
						throw new Error("Unknown attribute");
					}

					const dataField =
						guestAttribute.type === "dataField" ? dataFields.find((d) => d.id === guestAttribute.entityType) : null;

					if (!dataField) {
						switch (guestAttribute.entityType) {
							case "registrationForm":
							case "tenant":
							case "wave":
								return { id: attributeId, value: formValues[attributeId] ? formValues[attributeId].id : null };
							case "tags":
								return { id: attributeId, value: formValues[attributeId].map((tag: Tag) => tag.id) };
							case "groups":
								return { id: attributeId, value: formValues[attributeId].map((group: Group) => group.id) };
							default:
								return { id: attributeId, value: formValues[attributeId] };
						}
					} else {
						switch (dataField.type) {
							case DataFieldType.SELECT_FIELD:
								return {
									id: attributeId,
									value: formValues[attributeId] ? formValues[attributeId] : null,
								};
							case DataFieldType.RADIO:
								return {
									id: attributeId,
									value: formValues[attributeId] ? parseInt(formValues[attributeId]) : null,
								};
							case DataFieldType.MULTI_SELECT_FIELD:
								return {
									id: attributeId,
									value: formValues[attributeId].map((option: IDataFieldOptionIndexResource) => option.id),
								};
							case DataFieldType.CHECKBOX_GROUP:
								return {
									id: attributeId,
									value: formValues[attributeId],
								};
							default:
								return {
									id: attributeId,
									value: formValues[attributeId],
								};
						}
					}
				}),
		[globalGuestView.guestAttributes, dataFields],
	);

	const queryClient = useQueryClient();

	const handlePost = useCallback(
		async (
			guestRequest: GuestFormValues,
			helpers: FormikHelpersWithRedirect<GuestFormValues>,
			shouldGoBack: boolean,
		) => {
			const response = await guestService.post(globalGuestView.id, guestRequest);

			if (apiIsOK(response)) {
				helpers.setTo(shouldGoBack ? -1 : `../../${response.data.data.id}/details`);
				showToast({ body: t("Guest was saved."), title: t("Guest"), type: "success" });
				void queryClient.invalidateQueries({ queryKey: guestKeys.index });
				void queryClient.invalidateQueries({ queryKey: dashboardKeys.index });
				void queryClient.invalidateQueries({ queryKey: hotelInquiryService.keys.index });
				void queryClient.invalidateQueries({ queryKey: dataFieldService.keys.contingentOverview });
				void queryClient.invalidateQueries({ queryKey: waveService.keys.getContingentOverview });
				return true;
			}

			return false;
		},
		[globalGuestView.id, queryClient, showToast, t],
	);

	const handlePut = useCallback(
		async (
			guestId: Id,
			guestRequest: GuestFormValues,
			helpers: FormikHelpersWithRedirect<GuestFormValues>,
			shouldGoBack: boolean,
		) => {
			const response = await guestService.put(guestId, globalGuestView.id, guestRequest);

			if (apiIsOK(response)) {
				shouldGoBack && helpers.setTo(-1);
				showToast({ body: t("Guest was saved."), title: t("Guest"), type: "success" });
				void queryClient.invalidateQueries({ queryKey: guestKeys.index });
				void queryClient.invalidateQueries({ queryKey: dashboardKeys.index });
				void queryClient.invalidateQueries({ queryKey: hotelInquiryService.keys.index });
				void queryClient.invalidateQueries({ queryKey: emailKeys.sendOuts.index });
				void queryClient.invalidateQueries({ queryKey: dataFieldService.keys.contingentOverview });
				void queryClient.invalidateQueries({ queryKey: waveService.keys.getContingentOverview });
				void queryClient.invalidateQueries({ queryKey: ordersKeys.index });
				return true;
			}

			return false;
		},
		[globalGuestView.id, queryClient, showToast, t],
	);

	const handleSubmit = useCallback(
		async (formValues: GuestFormValues, helpers: FormikHelpersWithRedirect<GuestFormValues>, shouldGoBack: boolean) => {
			const guestRequest: GuestFormValues = {
				attributes: mapFormValuesToRequest(formValues),
			};

			if (isNew) {
				return await handlePost(guestRequest, helpers, shouldGoBack);
			} else {
				return await handlePut(guestId, guestRequest, helpers, shouldGoBack);
			}
		},
		[guestId, handlePost, handlePut, isNew, mapFormValuesToRequest],
	);

	const handleDelete = useCallback(
		async (guestId: Id) => {
			const response = await guestService.remove(guestId);
			if (apiIsOK(response)) {
				navigate("..", { replace: true });
				showToast({ body: t("Guest was deleted."), title: t("Guest"), type: "success" });
				void queryClient.invalidateQueries({ queryKey: guestKeys.index });
				void queryClient.invalidateQueries({ queryKey: dashboardKeys.index });
				void queryClient.invalidateQueries({ queryKey: hotelInquiryService.keys.index });
				void queryClient.invalidateQueries({ queryKey: emailKeys.sendOuts.index });
				void queryClient.invalidateQueries({ queryKey: dataFieldService.keys.contingentOverview });
				void queryClient.invalidateQueries({ queryKey: waveService.keys.getContingentOverview });
				void queryClient.resetQueries({ queryKey: guestFilterKeys.index });
			}
		},
		[navigate, queryClient, showToast, t],
	);

	return (
		<GuestDetailsContext.Provider
			value={{
				isNew,
				guest: guest || null,
				dataFields,
				dataFieldSettings: dataFieldSettings ?? null,
				handleDelete,
				handleSubmit,
				requiredGuestAttributeIds,
				isLoading: isNew
					? dataFieldsLoading || dataFieldSettingsLoading
					: isLoading || dataFieldsLoading || dataFieldSettingsLoading,
			}}
		>
			{children}
		</GuestDetailsContext.Provider>
	);
};

export const useGuestDetails = () => {
	const context = useContext(GuestDetailsContext);

	if (context === undefined) {
		throw new Error(`Context undefined. Are you missing the GuestDetailsProvider?`);
	}

	return context;
};
