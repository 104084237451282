import { useQuery } from "@tanstack/react-query";
import {
	personalizedDocumentSettingsKeys,
	personalizedDocumentSettingsService,
} from "./personalizedDocumentSettingsService";

export const usePersonalizedDocumentSettingsQuery = () => {
	const { data, isLoading } = useQuery({
		queryKey: personalizedDocumentSettingsKeys.details,
		queryFn: () => personalizedDocumentSettingsService.get().then((r) => r.data.data),
	});

	return {
		data,
		isLoading,
	};
};
