import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { tagKeys, tagService } from "~shared/api/tags";

interface Props {
	name: string;
	label: string;
	disabled?: boolean;
}

const fetcher = (search: string, page: number) => tagService.options(search, page).then((r) => r.data);

export const TagSingleSelect: FC<Props> = ({ name, label, disabled }) => {
	return (
		<PagedSingleSelectField
			name={name}
			label={label}
			queryKey={tagKeys.options}
			fetcher={fetcher}
			disabled={disabled}
		/>
	);
};
