import { RadioGroupField, RadioOption } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { IDataFieldIndexResource } from "~shared/types/dataField";

interface Props {
	dataField: IDataFieldIndexResource;
	disabled?: boolean;
	required?: boolean;
	name: string;
}

export const RadioDataField: FC<Props> = ({ name, dataField, disabled, required }) => {
	const options: RadioOption[] = useMemo(
		() =>
			dataField.options.map(({ id, value }) => ({
				value: String(id),
				name: value,
			})),
		[dataField.options],
	);

	return (
		<RadioGroupField name={name} disabled={disabled} required={required} label={dataField.name} options={options} />
	);
};
