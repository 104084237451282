import { Card, CardBody, Id, Loading, RenderView, useBlockQueryShowById } from "@dgs/core";
import React, { FC, useState } from "react";
import styled from "styled-components";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { EmailSendOutPreviewLocaleSelect } from "./EmailSendOutPreviewLocaleSelect";
import { useEmailSendOut } from "./EmailSendOutProvider";

const MailContainer = styled.div`
	overflow: scroll;
`;

const HeaderCard = styled(Card)`
	margin: 0 ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(4)};
`;

const LocaleSelectInHeader = styled(EmailSendOutPreviewLocaleSelect)`
	max-width: 250px;
`;

export const EmailSendOutPreview: FC = () => {
	const { currentMail } = useEmailSendOut();
	const { fallbackLocale } = useContentLocale();
	const [emailLocale, setEmailLocale] = useState(fallbackLocale);
	if (!currentMail) {
		return null;
	}

	return (
		<EmailSendOutPreviewContent blockId={currentMail.blockId} locale={emailLocale} onChangeLocale={setEmailLocale} />
	);
};

interface Props {
	blockId: Id;
	locale: string;
	onChangeLocale: (locale: string) => void;
}

const EmailSendOutPreviewContent: FC<Props> = ({ blockId, locale, onChangeLocale }) => {
	const { locales } = useContentLocale();
	const { block, isLoading } = useBlockQueryShowById(blockId, true, {
		"Content-Language": locale,
	});

	if (!block || isLoading) return <Loading />;

	return (
		<div>
			{locales.length > 1 && (
				<HeaderCard>
					<CardBody>
						<LocaleSelectInHeader value={locale} onChange={onChangeLocale} />
					</CardBody>
				</HeaderCard>
			)}
			<MailContainer>
				<RenderView block={block} />
			</MailContainer>
		</div>
	);
};
