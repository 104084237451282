import { Drawer, DrawerBody, DrawerHeader, DrawerHeading, Id } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { IRegistrationFormForm, IRegistrationFormIndex } from "~shared/types/registrationForm";
import { FormikHelpersWithRedirect } from "~shared/ui/FormikWithRedirect";
import { RegistrationFormForm } from "./RegistrationFormForm";

interface Props {
	registrationForm: IRegistrationFormIndex;
	open: boolean;
	onClose: () => void;
	duplicateRegistrationForm: (id: Id, values: IRegistrationFormForm) => Promise<any>;
}

export const DuplicateRegistrationFormDrawer: FC<Props> = ({
	registrationForm,
	open,
	onClose,
	duplicateRegistrationForm,
}) => {
	const { t } = useTranslation();

	const handleSubmit = useCallback(
		async (values: IRegistrationFormForm, helpers: FormikHelpersWithRedirect<IRegistrationFormForm>) => {
			const registrationFormId = await duplicateRegistrationForm(registrationForm.id, values);

			if (registrationFormId === false) {
				return false;
			}
			helpers.setTo(`../registration-forms/${registrationFormId}`);
			onClose();
			return true;
		},
		[duplicateRegistrationForm, onClose, registrationForm.id],
	);

	return (
		<Drawer open={open} onClose={onClose}>
			<DrawerHeader onClose={onClose}>
				<DrawerHeading>{t("Duplicate registration form")}</DrawerHeading>
			</DrawerHeader>
			<DrawerBody>
				<RegistrationFormForm initialValues={{ name: "" }} onSubmit={handleSubmit} />
			</DrawerBody>
		</Drawer>
	);
};
