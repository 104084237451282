import { AutoDataList, FormattedDate, IDataListColumn, IconButton, Trigger } from "@dgs/core";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { guestKeys, guestService } from "~shared/api/guests";
import { AssignRoomMatesToGuestContingentAction } from "~shared/guests/hotels/AssignRoomMatesToGuestContingentAction";
import { GuestDetailsResource } from "~shared/types/newGuestTypes";
import { IRoomGuest } from "~shared/types/room";
import { IRoomWithAllRelationsResource } from "~shared/types/roomContingent";
import { GuestHotelsListContextMenu } from "./GuestHotelsListContextMenu";
import { GuestRoomContingentAttachDrawer } from "./GuestRoomContingentAttachDrawer";

const getLabel = (guest: IRoomGuest) => `${guest.firstName} ${guest.lastName} (${guest.email})`;

export const GuestRoomContingentsList: FC<{ guest: GuestDetailsResource }> = ({ guest }) => {
	const { t } = useTranslation();
	const dataListColumns: IDataListColumn<IRoomWithAllRelationsResource>[] = useMemo(
		() => [
			{
				heading: t("Hotel"),
				valueKey: "hotel",
				type: "value",
				size: "max-content",
				render: ({ hotel }) => hotel.name,
			},
			{
				heading: t("Room type"),
				valueKey: "roomType",
				type: "value",
				size: "max-content",
				render: ({ roomType }) => roomType.name,
			},
			{
				heading: t("Date"),
				valueKey: "roomContingent.date",
				type: "value",
				size: "max-content",
				render: ({ roomContingent }) => <FormattedDate date={roomContingent.date} />,
			},
			{
				heading: t("Room mates"),
				valueKey: "guests",
				type: "value",
				render: ({ guests }) =>
					guests
						.filter((x) => x.id !== guest.id)
						.map((x) => getLabel(x))
						.join(", "),
			},
			{
				heading: "",
				type: "actions",
				size: "max-content",
				action: (room: IRoomWithAllRelationsResource) => (
					<GuestHotelsListContextMenu
						room={room}
						guestId={guest.id}
						isMain={guest.id === room.guests.find((guest) => guest.isMain)?.id && room.guests.length > 1}
					/>
				),
			},
		],
		[guest.id, t],
	);

	return (
		<AutoDataList
			heading={t("Room bookings")}
			headerActions={
				<Trigger render={(props) => <GuestRoomContingentAttachDrawer {...props} guestId={guest.id} />}>
					{(props) => <IconButton {...props} title={t("Add")} color="primary" size="small" icon="plus" />}
				</Trigger>
			}
			empty={t("No room bookings yet")}
			columns={dataListColumns}
			queryKey={guestKeys.rooms(guest.id)}
			fetcher={guestService.rooms(guest.id)}
			enableBulkEdit={true}
			bulkEditActions={<AssignRoomMatesToGuestContingentAction />}
		/>
	);
};
