import { IUser, Id } from "@dgs/core";
import { ImportErrorListBulkEditEntityType } from "~shared/import/importErrorListBulkEditTypes";
import { Group } from "~shared/types/group";
import { GuestViewAttributeResource } from "~shared/types/guestView";
import { TImportProviderType } from "~shared/types/importProvider";
import { Tag } from "~shared/types/tag";

export interface ImportableGuestAttributeOption {
	id: Id;
	name: string;
	required: boolean;
	attribute: GuestViewAttributeResource;
}

export type ImportableAttributeOption = ImportableGuestAttributeOption | "guest-id";
export interface GuestImport {
	id: Id;
	user: IUser;
	headers: TImportHeader[];
	type: TImportProviderType;
	errors: number;
	successfulImports: number;
	status: GuestImportStatus;
	createdAt: string;
}

export interface TImportHeader {
	externalField: string;
	label: string;
}

export enum GuestImportStatus {
	OPEN = "OPEN",
	IN_PROGRESS = "IN_PROGRESS",
	STATUS_FAILED = "STATUS_FAILED",
	STATUS_FINISHED = "STATUS_FINISHED",
}

export interface GuestImportError {
	id: Id;
	data: ImportError[];
}

export interface ImportError {
	type: ImportErrorListBulkEditEntityType;
	value: any;
	dataFieldId: number | null;
	error: ImportErrorKey | null;
}

export enum ImportErrorKey {
	WAVE_NOT_EXISTENT = "WAVE_NOT_EXISTENT",
	TENANT_NOT_EXISTENT = "TENANT_NOT_EXISTENT",
	GROUP_NOT_EXISTENT = "GROUP_NOT_EXISTENT",
	TAG_NOT_EXISTENT = "TAG_NOT_EXISTENT",
	STATUS_NOT_EXISTENT = "STATUS_NOT_EXISTENT",
	REGISTRATION_FORM_NOT_EXISTENT = "REGISTRATION_FORM_NOT_EXISTENT",
	DATA_FIELD_NOT_EXISTENT = "DATA_FIELD_NOT_EXISTENT",
	INVALID_INPUT = "INVALID_INPUT",
	DATA_FIELD_OPTION_NOT_EXISTENT = "DATA_FIELD_OPTION_NOT_EXISTENT",
	INVALID_URL = "INVALID_URL",
	INVALID_EMAIL = "INVALID_EMAIL",
	INVALID_DATE = "INVALID_DATE",
	INVALID_DATE_TIME = "INVALID_DATE_TIME",
	INVALID_TIME = "INVALID_TIME",
	UNIQUE_EMAIL = "UNIQUE_EMAIL",
	GUEST_ID_NOT_EXISTENT = "GUEST_ID_NOT_EXISTENT",
	WAVE_CONTINGENT = "WAVE_CONTINGENT",
	WAVE_TENANT_COMBINATION = "WAVE_TENANT_COMBINATION",
	REGISTRATION_LOCALE = "INVALID_REGISTRATION_LOCALE",
	INVALID_REGISTRATION_CODE = "INVALID_REGISTRATION_CODE",
	UNIQUE_REGISTRATION_CODE = "UNIQUE_REGISTRATION_CODE",
	INVALID_HOTEL_INQUIRY = "INVALID_HOTEL_INQUIRY",
}

export interface TImportMapping {
	dgsEntity: string | null;
	dataFieldId: Id | null;
	externalField: string;
	header: string;
}

export interface BaseGuestImportRequest {
	mapping: TImportMapping[];
}

export type ExcelGuestImportRequest = BaseGuestImportRequest;

export interface ZohoGuestImportRequest extends BaseGuestImportRequest {
	filterField: string;
	filterValue: string;
	tagIds: Id[] | null;
	groupIds: Id[] | null;
	importOnlyDifferences: boolean;
}

export type AnyGuestImportRequest = ExcelGuestImportRequest | ZohoGuestImportRequest;

export interface BaseGuestImportFormValues {
	mapping: Record<string, ImportableAttributeOption | null>;
	tags: Tag[];
	groups: Group[];
}

export type ExcelGuestImportFormValues = BaseGuestImportFormValues;

export interface ZohoGuestImportFormValues extends BaseGuestImportFormValues {
	filterField: string;
	filterValue: string;
	importOnlyDifferences: boolean;
}

export type AnyGuestImportFormValues = ExcelGuestImportFormValues | ZohoGuestImportFormValues;
