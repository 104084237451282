import { Button } from "@dgs/core";
import { useFormikContext } from "formik";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ActionFormState } from "~shared/types/actions";
import { IRuleFormState } from "~shared/types/rules";
import { RuleSet } from "~shared/types/ruleSet";

export const AddRuleOrActionButton: FC<{
	initialValue: IRuleFormState | ActionFormState;
	type: "rules" | "actions";
	disabled?: boolean;
}> = ({ initialValue, disabled, type }) => {
	const { values, setFieldValue } = useFormikContext<RuleSet<any, any>>();
	const { t } = useTranslation();
	const title = type === "actions" ? t("New action") : t("New rule");
	const addNewRule = useCallback(() => {
		void setFieldValue(type, [...values[type], initialValue]);
	}, [setFieldValue, type, values, initialValue]);

	return (
		<Button type="button" title={title} color="primary" size="small" onClick={addNewRule} disabled={disabled}>
			{title}
		</Button>
	);
};
