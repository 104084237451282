import {
	I18nNameField,
	I18nTextField,
	RadioGroupField,
	TAllBlockTypes,
	TextField,
	useBlockMeta,
	useI18nForm,
} from "@dgs/core";
import { useField } from "formik";
import React, { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";

export const EmailPersonalizedButtonBlockFormFields: FC<{ blockType: TAllBlockTypes }> = ({ blockType }) => {
	const { t } = useTranslation();
	const {
		frontendTheme: { colors },
	} = useBlockMeta();
	const { isMainForm } = useI18nForm();
	const [typeField] = useField(`value.type`);
	const [, meta, colorHelpers] = useField(`value.color`);
	const [, backgroundMeta, backgroundColorHelpers] = useField(`value.backgroundColor`);
	const typeRadioOptions = [
		{ value: "registrationLink", name: t("Registration") },
		{ value: "declinationLink", name: t("Declination") },
		{ value: "cancellationLink", name: t("Cancellation") },
		{ value: "deepLink", name: t("DEEP Login") },
		{ value: "appLink", name: t("App Login") },
	];
	const colorRadioOptions = [
		{ value: "primary", name: t("Primary") },
		{ value: "secondary", name: t("Secondary") },
		{ value: "custom", name: t("Custom") },
	];

	useEffect(
		() => {
			if (typeField.value === "primary") {
				void colorHelpers.setValue(colors.palette.primary.main.contrast);
				void backgroundColorHelpers.setValue(colors.palette.primary.main.base);
			} else if (typeField.value === "secondary") {
				void colorHelpers.setValue(colors.palette.secondary.main.contrast);
				void backgroundColorHelpers.setValue(colors.palette.secondary.main.base);
			} else {
				void colorHelpers.setValue(meta.initialValue || "");
				void backgroundColorHelpers.setValue(backgroundMeta.initialValue || "");
			}
		},
		// Formik in React 18 has an issue regarding the consistency of its helpers
		// See https://github.com/jaredpalmer/formik/issues/3602 for more info
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			colors.palette.primary.main.base,
			colors.palette.primary.main.contrast,
			colors.palette.secondary.main.base,
			colors.palette.secondary.main.contrast,
			typeField.value,
		],
	);

	return (
		<>
			<I18nNameField blockType={blockType} />
			<I18nTextField label={t("Label")} name={`translatedValue.label`} />
			{isMainForm && <RadioGroupField name="value.linkType" label={t("Type")} options={typeRadioOptions} />}
			{isMainForm && <RadioGroupField name="value.type" label={t("Color")} options={colorRadioOptions} />}
			{isMainForm && typeField.value === "custom" && (
				<>
					<TextField label={t("Color")} name={`value.color`} type="color" />
					<TextField label={t("Background color")} name={`value.backgroundColor`} type="color" />
				</>
			)}
		</>
	);
};
