import { FormProps, I18nBlockForm, IBaseBlockEditResource } from "@dgs/core";
import React, { FC } from "react";
import { emailTextBlockForm } from "./EmailTextBlock";
import { EmailTextBlockFormFields } from "./EmailTextBlockFormFields";

export const EmailTextBlockForm: FC<FormProps<IBaseBlockEditResource>> = ({ block, onSubmit }) => {
	return (
		<I18nBlockForm block={block} onSubmit={onSubmit} blockForm={emailTextBlockForm}>
			<EmailTextBlockFormFields blockType={block.type} />
		</I18nBlockForm>
	);
};
