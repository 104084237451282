import { MultiSelectField } from "@dgs/core";
import React, { FC, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

interface CreatableSelectProps {
	label: string;
	name: string;
}

export const FilterCreatableMultiSelect: FC<CreatableSelectProps> = (props) => {
	const { t } = useTranslation();
	const [values, setValues] = useState<string[]>([]);

	const createNewOption = useCallback(
		(search: string, selectNewOption: (value: string) => void) => {
			setValues([...values, search]);
			selectNewOption(search);
		},
		[values],
	);

	return (
		<MultiSelectField
			options={values}
			getLabel={(value: string) => value}
			onCreate={createNewOption}
			placeholder={t("Add a text snippet for filtering")}
			{...props}
		/>
	);
};
