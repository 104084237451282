import { ContextMenu, ContextMenuDeleteItem, ContextMenuItem } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";

interface Props {
	handleEdit: () => void;
	handleDelete: () => void;
}

export const GuestViewContextMenu: FC<Props> = ({ handleDelete, handleEdit }) => {
	const { t } = useTranslation();

	return (
		<ContextMenu displayShortcutsAs="icon">
			<ContextMenuItem label={t("Edit")} title={t("Edit")} icon="edit" shortcut="primary" action={handleEdit} />
			<ContextMenuDeleteItem
				action={handleDelete}
				label={t("Delete")}
				title={t("Delete")}
				heading={t("Delete")}
				labels={{ close: t("Close"), confirm: t("Confirm") }}
			>
				{t("Are you sure you want to delete this guest view?")}
			</ContextMenuDeleteItem>
		</ContextMenu>
	);
};
