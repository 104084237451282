import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { registrationFormStructureService } from "~shared/api/registrationForms/registrastionFormStructureService";
import { registrationFormSectionService } from "~shared/api/registrationForms/registrationFormSectionService";
import { ruleSetService } from "~shared/api/registrationForms/ruleSets/ruleSetService";
import { GuestStatus } from "~shared/types/guest";
import {
	IRegistrationFormCompanionSectionFormValues,
	IRegistrationFormSectionFormValues,
	IRegistrationFormSectionRequest,
} from "~shared/types/section";

const mapSectionFormValuesToRequest = (values: IRegistrationFormSectionFormValues): IRegistrationFormSectionRequest => {
	if (values.type === "default") {
		return values;
	}
	return {
		...values,
		companionSectionSettings: {
			maxCompanionCount: values.companionSectionSettings.maxCompanionCount,
			companionConfirmationMailId: values.companionSectionSettings.companionConfirmationMail?.id ?? null,
			companionRegistrationFormId: values.companionSectionSettings.companionRegistrationForm?.id ?? null,
			groupIds: values.companionSectionSettings.groups.map((x) => x.id),
			tagIds: values.companionSectionSettings.tags.map((x) => x.id),
			companionStatus: values.companionSectionSettings.companionStatus ?? GuestStatus.NONE,
		},
	};
};
export const useRegistrationFormSection = (formId: Id, stepId: Id) => {
	const { showToast } = useToasts();
	const { t } = useTranslation();
	const queryClient = useQueryClient();

	const createRegistrationFormSection = useCallback(
		async (values: IRegistrationFormSectionFormValues) => {
			const response = await registrationFormSectionService.store(
				formId,
				stepId,
				mapSectionFormValuesToRequest(values),
			);
			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form section was saved."),
					title: t("Save registration form section"),
					type: "success",
				});
				void queryClient.invalidateQueries({ queryKey: registrationFormStructureService.keys.structure(formId) });

				return response.data.data.id;
			}

			return false;
		},
		[formId, stepId, showToast, t, queryClient],
	);

	const updateRegistrationFormSection = useCallback(
		async (sectionId: Id, values: IRegistrationFormSectionFormValues) => {
			const response = await registrationFormSectionService.update(
				formId,
				stepId,
				sectionId,
				mapSectionFormValuesToRequest(values),
			);

			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form section was saved."),
					title: t("Save registration form section"),
					type: "success",
				});

				void queryClient.invalidateQueries({
					queryKey: registrationFormSectionService.keys.details(formId, stepId, sectionId),
				});
				void queryClient.invalidateQueries({ queryKey: registrationFormStructureService.keys.structure(formId) });
				void queryClient.invalidateQueries({ queryKey: ruleSetService.keys.ruleSets(formId) });
				return true;
			}

			return false;
		},
		[formId, queryClient, showToast, stepId, t],
	);

	const deleteRegistrationFormSection = useCallback(
		async (sectionId: Id) => {
			const response = await registrationFormSectionService.delete(formId, stepId, sectionId);

			if (apiIsOK(response)) {
				showToast({
					body: t("Registration form section was deleted."),
					title: t("Delete registration form section"),
					type: "success",
				});
				void queryClient.invalidateQueries({ queryKey: registrationFormStructureService.keys.structure(formId) });
				await queryClient.invalidateQueries({ queryKey: ruleSetService.keys.ruleSets(formId) });
			}
		},
		[formId, stepId, showToast, t, queryClient],
	);

	const validate = (values: IRegistrationFormSectionFormValues) => {
		const errors: { [K in keyof IRegistrationFormSectionFormValues]?: any } = {};

		if (!values.name) {
			errors.name = t("Required field");
		}

		if (values.type === "companion" && values.companionSectionSettings) {
			const settingsErrors: {
				[K in keyof IRegistrationFormCompanionSectionFormValues["companionSectionSettings"]]?: any;
			} = {};

			if (values.companionSectionSettings.maxCompanionCount < 0) {
				settingsErrors.maxCompanionCount = t("This value must not be negative");
			}
			if (Object.keys(settingsErrors).length) {
				(errors as { [K in keyof IRegistrationFormCompanionSectionFormValues]?: any }).companionSectionSettings =
					settingsErrors;
			}
		}

		return errors;
	};

	return {
		createRegistrationFormSection,
		updateRegistrationFormSection,
		deleteRegistrationFormSection,
		validate,
	};
};
