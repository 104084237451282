import { LinkTab, LinkTabs } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { useHotelInquiryCountQuery } from "~shared/api/hotelInquiries";
import { ConfirmedHotelInquiriesList } from "./ConfirmedHotelInquiriesList";
import { ConflictsHotelInquiriesList } from "./HotelInquiryConflictsList";
import { PendingHotelInquiriesList } from "./PendingHotelInquiriesList";

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: row;
	grid-auto-rows: max-content;
	gap: ${({ theme }) => theme.spacing(4)};
`;

export const HotelInquiriesPage: FC = () => {
	const { t } = useTranslation();
	const { data: inquiryCounts } = useHotelInquiryCountQuery();

	return (
		<Wrapper>
			<LinkTabs>
				<LinkTab to="pending">
					{t("Pending")} {inquiryCounts ? `(${inquiryCounts.pending})` : ""}
				</LinkTab>
				<LinkTab to="confirmed">
					{t("Confirmed")} {inquiryCounts ? `(${inquiryCounts.confirmed})` : ""}
				</LinkTab>
				<LinkTab to="conflicts">
					{t("Conflicts")} {inquiryCounts ? `(${inquiryCounts.conflicts})` : ""}
				</LinkTab>
			</LinkTabs>
			<Routes>
				<Route index element={<Navigate to="pending" />} />
				<Route path="pending" element={<PendingHotelInquiriesList />} />
				<Route path="confirmed" element={<ConfirmedHotelInquiriesList />} />
				<Route path="conflicts" element={<ConflictsHotelInquiriesList />} />
			</Routes>
		</Wrapper>
	);
};
