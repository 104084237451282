import { LinkTab, LinkTabs, useIsAddonEnabled } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { useGuestDetails } from "~shared/guests/details/GuestDetailProvider";
import { GuestDetails } from "~shared/guests/details/GuestDetails";
import { GuestEmailsList } from "~shared/guests/emails/GuestEmailList";
import { GuestChangelog } from "~shared/guests/GuestChangelog";
import { GuestFileList } from "~shared/guests/guestFiles/GuestFileList";
import { GuestPersonalizedDocumentsPage } from "~shared/guests/GuestPersonalizedDocumentsPage";
import { GuestHotelsPage } from "~shared/guests/hotels/GuestHotelsPage";
import { CompanionList } from "./CompanionList";

const Wrapper = styled.div`
	max-height: 100%;
	display: grid;
	grid-auto-rows: max-content 1fr;
	gap: ${({ theme }) => theme.spacing(4)};
`;

interface Props {
	showEmails?: boolean;
}

export const GuestDetailsPage: FC<Props> = ({ showEmails = true }) => {
	const { t } = useTranslation();
	const { isNew } = useGuestDetails();
	const isPersonalizedDocumentsEnabled = useIsAddonEnabled("personalizedDocuments");

	let tabs;
	if (!isNew) {
		tabs = (
			<LinkTabs>
				<LinkTab to="details">{t("Details")}</LinkTab>
				<LinkTab to="hotel">{t("Hotel")}</LinkTab>
				<LinkTab to="log">{t("Log")}</LinkTab>
				<LinkTab to="companions">{t("Companions")}</LinkTab>
				{showEmails && <LinkTab to="emails">{t("Emails")}</LinkTab>}
				{isPersonalizedDocumentsEnabled && <LinkTab to="personalized-documents">{t("Personalized Documents")}</LinkTab>}
				<LinkTab to="guest-files">{t("Guest files")}</LinkTab>
			</LinkTabs>
		);
	}

	return (
		<Wrapper>
			{tabs}
			<Routes>
				<Route index element={<Navigate to="details" replace={true} />} />
				<Route path="details" element={<GuestDetails />} />
				<Route path="hotel" element={<GuestHotelsPage />} />
				{showEmails && <Route path="emails" element={<GuestEmailsList module="nomination-manager" />} />}
				<Route path="log" element={<GuestChangelog />} />
				{isPersonalizedDocumentsEnabled && (
					<Route path="personalized-documents" element={<GuestPersonalizedDocumentsPage />} />
				)}
				<Route path="companions" element={<CompanionList />} />
				<Route path="guest-files" element={<GuestFileList />} />
			</Routes>
		</Wrapper>
	);
};
