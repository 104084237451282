import { BaseCrudOptionService, Id, LaravelResponse, keyToUrl } from "@dgs/core";
import axios from "axios";
import { taxKeys } from "~admin/shared/api/tax/taxKeys";
import { ModelUsageResource } from "~shared/types/modelUsageResource";
import { ITaxIndexResource, ITaxOptionResource, ITaxRequest, ITaxUpdateResource } from "~shared/types/tax";

class TaxService extends BaseCrudOptionService<ITaxIndexResource, ITaxUpdateResource, ITaxOptionResource, ITaxRequest> {
	public keys = taxKeys;

	protected urls = {
		options: keyToUrl(this.keys.options),
		index: keyToUrl(this.keys.index),
		details: (taxId: Id) => keyToUrl(this.keys.details(taxId)),
		showUsage: (taxId: Id) => keyToUrl(this.keys.showUsage(taxId)),
	};

	showUsage = (taxId: Id) => axios.get<LaravelResponse<ModelUsageResource[]>>(this.urls.showUsage(taxId));
}

export const taxService = new TaxService();
