import {
	ContextMenu,
	ContextMenuAnchorItem,
	ContextMenuItem,
	Id,
	SplitButton,
	useDynamicConfig,
	useOpen,
} from "@dgs/core";
import React, { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { registrationFormService } from "~shared/api/registrationForms";
import { DeleteModelWithUsagesDialog, useModelUsage } from "~shared/ui/modelUsage/DeleteModelWithUsagesDialog";
import { CreateRegistrationFormBlockDrawer } from "../blocks/CreateRegistrationFormBlockDrawer";
import { CreateRegistrationFormSectionDrawer } from "../sections/CreateRegistrationFormSectionDrawer";
import { EditRegistrationFormStepDrawer } from "./EditRegistrationFormStepDrawer";
import { useRegistrationFormStep } from "./useRegistrationFormStep";

interface Props {
	registrationFormId: string;
	stepId: Id;
}

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: ${({ theme }) => theme.spacing(3)};
`;

export const RegistrationFormStepContextMenu: FC<Props> = ({ registrationFormId, stepId }) => {
	const { t } = useTranslation();
	const { frontendUrl } = useDynamicConfig();
	const { open, handleOpen, handleClose } = useOpen();
	const {
		open: openRegistrationFormSection,
		handleOpen: handleOpenRegistrationFormSection,
		handleClose: handleCloseRegistrationFormSection,
	} = useOpen();

	const {
		open: openStepBlockDrawer,
		handleOpen: handleOpenStepBlockDrawer,
		handleClose: handleCloseStepBlockDrawer,
	} = useOpen();

	const showUsageAction = useCallback(
		() => registrationFormService.showStepUsage(registrationFormId, stepId),
		[registrationFormId, stepId],
	);
	const { handleUsage, ...usageModalProps } = useModelUsage(showUsageAction);

	const { deleteRegistrationFormStep } = useRegistrationFormStep(registrationFormId);

	const options = useMemo(() => {
		return [
			{
				title: t("Create section"),
				onClick: handleOpenRegistrationFormSection,
			},
			{
				title: t("Add block"),
				onClick: handleOpenStepBlockDrawer,
			},
		];
	}, [handleOpenRegistrationFormSection, handleOpenStepBlockDrawer, t]);

	return (
		<>
			<Wrapper>
				<SplitButton size="small" options={options} />
				<ContextMenu displayShortcutsAs="icon" size="small" flat={true}>
					<ContextMenuItem
						label={t("Create registration form section")}
						title={t("Create registration form section")}
						action={handleOpenRegistrationFormSection}
						icon="plus"
					/>
					<ContextMenuItem
						label={t("Edit registration form step")}
						title={t("Edit registration form step")}
						action={handleOpen}
						icon="edit"
					/>
					<ContextMenuItem
						action={handleUsage}
						title={t("Delete registration form step")}
						label={t("Delete registration form step")}
						icon="delete"
					/>
					<ContextMenuAnchorItem
						href={`${frontendUrl}/registrations/preview/${registrationFormId}/${stepId}`}
						title={t("Preview")}
						icon="eye"
						label={t("Preview")}
					/>
				</ContextMenu>
				<DeleteModelWithUsagesDialog
					usagePrefix={t("This step or the content")}
					confirmationBody={t("Are you sure you want to delete this step?")}
					deleteAction={() => deleteRegistrationFormStep(stepId, handleClose)}
					{...usageModalProps}
				/>
			</Wrapper>

			<EditRegistrationFormStepDrawer
				registrationFormId={registrationFormId}
				stepId={stepId}
				open={open}
				onClose={handleClose}
			/>

			<CreateRegistrationFormSectionDrawer
				registrationFormId={registrationFormId}
				stepId={stepId}
				open={openRegistrationFormSection}
				onClose={handleCloseRegistrationFormSection}
			/>

			<CreateRegistrationFormBlockDrawer
				open={openStepBlockDrawer}
				onClose={handleCloseStepBlockDrawer}
				registrationFormId={registrationFormId}
				registrationFormStepId={stepId}
			/>
		</>
	);
};
