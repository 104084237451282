import { SubmitButton, TextField } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { IRegistrationFormForm } from "~shared/types/registrationForm";
import { FormikHelpersWithRedirect, FormikWithRedirect } from "~shared/ui/FormikWithRedirect";

interface Props {
	initialValues: IRegistrationFormForm;
	onSubmit: (
		values: IRegistrationFormForm,
		helpers: FormikHelpersWithRedirect<IRegistrationFormForm>,
	) => Promise<boolean>;
}

export const RegistrationFormForm: FC<Props> = ({ initialValues, onSubmit }) => {
	const { t } = useTranslation();

	const validate = (values: IRegistrationFormForm) => {
		const errors: { [K in keyof IRegistrationFormForm]?: any } = {};

		if (!values.name) {
			errors.name = t("Required field");
		}

		return errors;
	};

	return (
		<FormikWithRedirect initialValues={initialValues} validate={validate} onSubmit={onSubmit}>
			<TextField name="name" label={t("Name")} />
			<SubmitButton>{t("Save")}</SubmitButton>
		</FormikWithRedirect>
	);
};
