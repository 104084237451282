import { Button, ButtonGroup, Dropdown, DropdownItem, IconButton, PaletteColor, cls } from "@dgs/core";
import { FieldArray, useFormikContext } from "formik";
import React, { FC, MouseEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { useI18nForm } from "./I18nFormProvider";
import { TouchedTranslations, TranslationObject } from "./i18nTypes";
import { hasTouchedField } from "./i18nUtils";

const StyledDropdownItem = styled(DropdownItem)<{ $color: PaletteColor | "default" }>`
	color: ${({ theme, $color }) => $color !== "default" && theme.colors.palette[$color].main.base};
	display: flex;
	justify-content: space-between;
`;

const StyledButton = styled(Button)`
	cursor: default;
`;

type ColorGetter = (locale: string) => PaletteColor | "default";

export const I18nLocaleSwitcher = <Values,>({ emptyLocale }: { emptyLocale: Values }) => {
	const { fallbackLocale, locales, currentLocale, setCurrentLocale } = useI18nForm();
	const { values, touched, errors } = useFormikContext<{ translations: TranslationObject[] }>();
	const [anchor, setAnchor] = useState<HTMLElement | null>(null);
	const { t } = useTranslation();
	const getColor = (locale: string) => {
		const idx = values.translations.findIndex((x) => x.locale === locale);
		const hasIdxErrors = errors.translations?.at(idx);
		const touchedLocale = ((touched.translations as TouchedTranslations) ?? [])[idx];

		if (hasIdxErrors && touchedLocale && hasTouchedField(touchedLocale)) return "danger";
		return "default";
	};
	const handleToggle = (e: MouseEvent<HTMLButtonElement>) => {
		setAnchor((prev) => (prev ? null : (e.target as HTMLElement)));
	};

	return (
		<FieldArray
			name="translations"
			render={({ push }) => (
				<ButtonGroup>
					<StyledButton key={currentLocale} title={currentLocale} type="button" size="small">
						{currentLocale}
						{currentLocale === fallbackLocale && "*"}
					</StyledButton>
					<IconButton
						size="small"
						title={t("Select option")}
						type="button"
						onClick={(e) => handleToggle(e)}
						color={locales.some((x) => getColor(x) === "danger") ? "danger" : "default"}
						icon="caret_down"
					/>
					{anchor && (
						<Dropdown anchor={anchor} onClose={() => setAnchor(null)}>
							{locales.map((locale) => {
								if (!values.translations.find((x) => x.locale === locale)) {
									return (
										<StyledDropdownItem
											$color="default"
											key={locale}
											onClick={() => {
												push({
													...emptyLocale,
													locale,
												});
												setCurrentLocale(locale);
												setAnchor(null);
											}}
										>
											{t('Add "{{locale}}"', { locale })}
										</StyledDropdownItem>
									);
								}
								return (
									<StyledDropdownItem
										$color={getColor(locale)}
										className={cls(currentLocale === locale && "selected")}
										onClick={() => {
											setCurrentLocale(locale);
											setAnchor(null);
										}}
										key={locale}
									>
										{locale}
										{fallbackLocale === locale && "*"}
									</StyledDropdownItem>
								);
							})}
						</Dropdown>
					)}
				</ButtonGroup>
			)}
		/>
	);
};

export const I18nLocaleSwitcherBase: FC<{ getColor?: ColorGetter; onChange?: (locale: string) => void }> = (props) => {
	const { fallbackLocale, locales, currentLocale, setCurrentLocale } = useI18nForm();
	const getColor: ColorGetter = (locale: string) => {
		if (props.getColor) return props.getColor(locale);
		return "default";
	};
	const { t } = useTranslation();
	const [anchor, setAnchor] = useState<HTMLElement | null>(null);

	const handleToggle = (e: MouseEvent<HTMLButtonElement>) => {
		setAnchor((prev) => (prev ? null : (e.target as HTMLElement)));
	};

	return (
		<ButtonGroup>
			<StyledButton key={currentLocale} title={currentLocale} type="button" size="small">
				{currentLocale}
				{currentLocale === fallbackLocale && "*"}
			</StyledButton>
			<IconButton
				size="small"
				title={t("Select option")}
				type="button"
				onClick={(e) => handleToggle(e)}
				color={locales.some((x) => getColor(x) === "danger") ? "danger" : "default"}
				icon="caret_down"
			/>
			{anchor && (
				<Dropdown anchor={anchor} onClose={() => setAnchor(null)}>
					{locales.map((locale) => (
						<StyledDropdownItem
							$color={getColor(locale)}
							className={cls(currentLocale === locale && "selected")}
							onClick={() => {
								props.onChange && props.onChange(locale);
								setCurrentLocale(locale);
								setAnchor(null);
							}}
							key={locale}
						>
							{locale}
							{fallbackLocale === locale && "*"}
						</StyledDropdownItem>
					))}
				</Dropdown>
			)}
		</ButtonGroup>
	);
};
