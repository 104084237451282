import { LinkTab, LinkTabs } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import { AutomationPage } from "./automations/AutomationPage";
import { ConditionPage } from "./conditions/ConditionPage";
import { RegistrationFormSettingsPage } from "./settings/RegistrationFormSettingsPage";
import { RegistrationFormStructurePage } from "./structure/RegistrationFormStructurePage";

const Wrapper = styled.div`
	height: 100%;
	overflow: hidden;
	display: grid;
	grid-template-rows: max-content 1fr;
`;

const RegistrationFormWrapper = styled.div`
	overflow: auto;
	padding: ${({ theme }) => theme.spacing(4)};
`;

const LinkTabsWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.palette.content.light.base};
	box-shadow: ${({ theme }) => theme.shadows.l1};
`;

export const RegistrationFormDetailsPage: FC = () => {
	const { t } = useTranslation();

	return (
		<Wrapper>
			<LinkTabsWrapper>
				<LinkTabs>
					<LinkTab to="structure/overview">{t("Overview")}</LinkTab>
					<LinkTab to="structure/order">{t("Structure")}</LinkTab>
					<LinkTab to="conditions">{t("Conditions")}</LinkTab>
					<LinkTab to="automations">{t("Automations")}</LinkTab>
					<LinkTab to="settings">{t("Settings")}</LinkTab>
				</LinkTabs>
			</LinkTabsWrapper>
			<Routes>
				<Route
					path="*"
					element={
						<RegistrationFormWrapper>
							<RegistrationFormContent />
						</RegistrationFormWrapper>
					}
				>
					<Route index element={<Navigate to="structure" />} />
					<Route path="structure/*" element={<RegistrationFormStructurePage />} />
					<Route path="conditions/*" element={<ConditionPage />} />
					<Route path="automations/*" element={<AutomationPage />} />
					<Route path="settings/*" element={<RegistrationFormSettingsPage />} />
				</Route>
			</Routes>
		</Wrapper>
	);
};
export const RegistrationFormContent = () => {
	return <Outlet />;
};
