import { Id, LaravelResponse, ListFetcherConfig, SelectedRows, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { mapFetcherConfigToAxiosConfig } from "~shared/api/indexMapper";
import { ImportErrorListBulkEditRequest } from "~shared/import/importErrorListBulkEditTypes";
import { Guest } from "~shared/types/guest";
import {
	AnyGuestImportRequest,
	GuestImport,
	GuestImportError,
	ImportError,
	TImportHeader,
} from "~shared/types/guestImport";
import { TImportProviderType } from "~shared/types/importProvider";
import { importKeys } from "./importKeys";

interface GuestHeaderRow {
	id: Id;
	type: TImportProviderType;
	headers: TImportHeader[];
}

interface GuestValues {
	guestValues: { dataFieldId: Id | null; type: string; value: any }[];
}

export const importUrls = {
	index: keyToUrl(importKeys.index),
	guests: {
		index: keyToUrl(importKeys.guests.index),
		details: (guestImportId: Id) => keyToUrl(importKeys.guests.details(guestImportId)),
		headerRow: keyToUrl(importKeys.guests.headerRow),
		errors: {
			index: (guestImportId: Id) => keyToUrl(importKeys.guests.errors.index(guestImportId)),
			details: (guestImportId: Id, guestImportErrorId: Id) =>
				keyToUrl(importKeys.guests.errors.details(guestImportId, guestImportErrorId)),
			correct: (guestImportId: Id, guestImportErrorId: Id) =>
				keyToUrl(importKeys.guests.errors.correct(guestImportId, guestImportErrorId)),
			bulkCorrection: (guestImportId: Id) => keyToUrl(importKeys.guests.errors.bulkCorrection(guestImportId)),
		},
	},
};

const index = (config?: AxiosRequestConfig) => axios.get<LaravelResponse<GuestImport[]>>(importUrls.index, config);

const guestImportIndex = (config: ListFetcherConfig<any>) =>
	axios.get<LaravelResponse<GuestImport[]>>(importUrls.guests.index, mapFetcherConfigToAxiosConfig(config));

const guestImportPost = (guestImportId: Id, guestImport: AnyGuestImportRequest) =>
	axios.post<LaravelResponse<void>>(importUrls.guests.details(guestImportId), guestImport);

const guestImportErrors = (guestImportId: Id) => (config: ListFetcherConfig<any>) =>
	axios.get<LaravelResponse<GuestImportError[]>>(
		importUrls.guests.errors.index(guestImportId),
		mapFetcherConfigToAxiosConfig(config),
	);

const correctGuestImportError = (guestImportId: Id, guestImportErrorId: Id, guestValues: GuestValues) =>
	axios.post<LaravelResponse<GuestImportError[]>>(
		importUrls.guests.errors.correct(guestImportId, guestImportErrorId),
		guestValues,
	);

const showGuestImportError = (guestImportId: Id, guestImportErrorId: Id) =>
	axios.get<LaravelResponse<ImportError[]>>(importUrls.guests.errors.details(guestImportId, guestImportErrorId));

const guestHeaderRow = (formData: FormData) =>
	axios.post<LaravelResponse<GuestHeaderRow>>(importUrls.guests.headerRow, formData);

const bulkCorrection = (
	guestImportId: Id,
	guestImportErrorIds: SelectedRows | null,
	values: ImportErrorListBulkEditRequest,
) =>
	axios.post<LaravelResponse<Guest[]>>(importUrls.guests.errors.bulkCorrection(guestImportId), {
		...values,
		guestImportErrorIds: guestImportErrorIds,
	});

const deleteImport = (guestImportId: Id) =>
	axios.delete<LaravelResponse<GuestImport>>(importUrls.guests.details(guestImportId));

export const importService = {
	index,
	guests: {
		index: guestImportIndex,
		post: guestImportPost,
		remove: deleteImport,
		headerRow: guestHeaderRow,
		errors: {
			index: guestImportErrors,
			details: showGuestImportError,
			correct: correctGuestImportError,
			bulkCorrection: bulkCorrection,
		},
	},
};
