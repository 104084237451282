import {
	DeleteAction,
	EntityDrawerProps,
	I18NCrudDrawer,
	I18nOnlyOnMainForm,
	I18nTextField,
	I18nTextareaField,
	SingleSelectField,
	mapErrors,
} from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { I18nFileSingleSelectField } from "~admin/shared/selects/I18nFileSingleSelectField";
import { useContentLocale } from "~shared/providers/ContentLocaleProvider";
import { PersonalizedDocument, PersonalizedDocumentTypes } from "~shared/types/personalizedDocument";

const personalizedDocumentTypes = [
	PersonalizedDocumentTypes.CR80_LANDSCAPE,
	PersonalizedDocumentTypes.CR80_PORTRAIT,
	PersonalizedDocumentTypes.A4_LANDSCAPE,
	PersonalizedDocumentTypes.A4_PORTRAIT,
	PersonalizedDocumentTypes.A5_LANDSCAPE,
	PersonalizedDocumentTypes.A5_PORTRAIT,
	PersonalizedDocumentTypes.A6_LANDSCAPE,
	PersonalizedDocumentTypes.A6_PORTRAIT,
];

type Props = EntityDrawerProps<PersonalizedDocument> & { handleDelete?: () => void };

export const PersonalizedDocumentDrawer: FC<Props> = ({ ...props }) => {
	const { t } = useTranslation();
	const { locales, fallbackLocale } = useContentLocale();

	const validate = useCallback(
		(values: PersonalizedDocument) => {
			let errors: Record<string, any> = {};

			if (!values.type) {
				errors.type = t("Required field");
			}

			const translations = values.translations[fallbackLocale] || {};

			if (!translations.name) {
				errors = mapErrors(errors, fallbackLocale, "name", t);
			}
			if (!translations.html) {
				errors = mapErrors(errors, fallbackLocale, "html", t);
			}
			return errors;
		},
		[fallbackLocale, t],
	);

	return (
		<I18NCrudDrawer
			{...props}
			fallbackLocale={fallbackLocale}
			locales={locales}
			validate={validate}
			body={
				<>
					<I18nOnlyOnMainForm>
						<SingleSelectField
							name="type"
							label={t("Type")}
							options={personalizedDocumentTypes}
							getLabel={(option) => option}
							getValue={(option) => option}
							clearable={false}
							required={true}
						/>
					</I18nOnlyOnMainForm>
					<I18nTextField name="name" label={t("Name")} required="onlyOnMainForm" />
					<I18nTextareaField name="html" label="HTML" required="onlyOnMainForm" />
					<I18nFileSingleSelectField name="backgroundImage" label={t("Background image")} />
				</>
			}
			footer={
				props.handleDelete && (
					<DeleteAction
						heading={t("Delete")}
						title={t("Delete")}
						buttonLabel={t("Delete")}
						labels={{ close: t("Close"), confirm: t("Confirm") }}
						action={props.handleDelete}
					>
						{t("Are you sure you want to delete this personalized document?")}
					</DeleteAction>
				)
			}
		/>
	);
};
