import { Card, CardBody } from "@dgs/core";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import React, { FC } from "react";
import { StructureStep } from "~shared/types/registrationFormTypes";
import { ActiveCard } from "../common/ActiveStructureCard";
import { DragIndicator } from "../common/DragIndicator";
import { StepHeader } from "./StepHeader";

interface Props {
	registrationFormId: string;
	step: StructureStep;
	isActive?: boolean;
	children: React.ReactNode;
}

export const SortableStep: FC<Props> = ({ step, children, registrationFormId }) => {
	const { transform, transition, setNodeRef, listeners, attributes, isDragging } = useSortable({ id: step.id });

	const ItemCard = isDragging ? ActiveCard : Card;

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
		opacity: isDragging ? 0.8 : 1,
	};

	return (
		<div ref={setNodeRef} style={style}>
			<ItemCard $color="background">
				<StepHeader
					registrationFormId={registrationFormId}
					step={step}
					indicator={<DragIndicator {...listeners} {...attributes} />}
				/>
				<CardBody>{children}</CardBody>
			</ItemCard>
		</div>
	);
};
