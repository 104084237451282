import { SingleSelectField } from "@dgs/core";
import { useField, useFormikContext } from "formik";
import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useRuleActionTypeQuery } from "~shared/api/registrationForms/ruleSets/ruleSetQueries";
import { DataFieldType } from "~shared/types/dataFieldType";
import { Rule, RuleTypes } from "~shared/types/rules";
import { RuleSetType } from "~shared/types/ruleSet";

interface RuleSelectProps {
	name: string;
	label: string;
	rulesetType: RuleSetType;
	dataField: { type: DataFieldType } | null;
	onChange?: (e: {
		target: {
			name: string;
			value: RuleTypes | null;
		};
	}) => void;
}

export const RuleSelect: FC<RuleSelectProps> = ({ name, label, rulesetType, dataField, onChange }) => {
	const { ruleActionTypes } = useRuleActionTypeQuery();
	const { t } = useTranslation();

	return (
		<SingleSelectField
			options={dataField ? ruleActionTypes[rulesetType].rules[dataField.type] : []}
			getLabel={(option) => t(`RuleType.${option}`)}
			getValue={(option) => option}
			name={name}
			label={label}
			onChange={onChange}
		/>
	);
};

interface ValidationRuleSelectProps extends RuleSelectProps {
	index: number;
}

export const ValidationRuleSelect: FC<ValidationRuleSelectProps> = ({ name, label, dataField, rulesetType, index }) => {
	const { ruleActionTypes } = useRuleActionTypeQuery();
	const { t } = useTranslation();
	const options = useMemo(
		() => (dataField ? ruleActionTypes[rulesetType].rules[dataField.type] : []),
		[dataField, ruleActionTypes, rulesetType],
	);
	const [field] = useField<RuleTypes | null>(name);
	const { values, setFieldValue } = useFormikContext<{ rules: Rule[] }>();
	const availableOptions = useMemo(() => {
		const used = values.rules.filter((x) => x.type !== field.value).map((x) => x.type);
		return options.filter((x) => !used.includes(x));
	}, [field.value, options, values.rules]);

	return (
		<SingleSelectField
			options={availableOptions}
			getLabel={(option) => t(`RuleType.${option}`)}
			getValue={(option) => option}
			name={name}
			label={label}
			onChange={() => {
				void setFieldValue(`rules.${index}.value`, null);
			}}
		/>
	);
};
