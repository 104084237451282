import { useQuery } from "@tanstack/react-query";
import { nominationModuleSettingsKeys } from "./nominationModuleSettingsKeys";
import { nominationModuleSettingsService } from "./nominationModuleSettingsService";

export const useNominationModuleSettingsQuery = () => {
	const { data, isLoading } = useQuery({
		queryKey: nominationModuleSettingsKeys.index,
		queryFn: () => nominationModuleSettingsService.index().then(({ data }) => data.data),
	});

	return {
		data,
		isLoading,
	};
};
