import { IDataListSortParams, Id, LaravelResponse, ListFetcherConfig, keyToUrl } from "@dgs/core";
import axios, { AxiosRequestConfig } from "axios";
import { mapFetcherConfigToAxiosConfig, mapSortParamsToGuestListSortParams } from "~shared/api/indexMapper";
import { IActiveGuestFilterEntry, IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";
import { Activity } from "~shared/types/activity";
import { BaseEmailLog, RecipientType } from "~shared/types/email";
import {
	Guest,
	GuestFormValues,
	GuestListBulkEditRequest,
	IGuestHotelData,
	IUpdateGuestRoomContingentRequest,
	Recipient,
} from "~shared/types/guest";
import { ModelUsageResource } from "~shared/types/modelUsageResource";
import { GuestBookingsResource, GuestDetailsResource, GuestIndexResource } from "~shared/types/newGuestTypes";
import {
	AttachRoomMateToRoomsRequest,
	IRoomAllocationRequest,
	IRoomContingentDetailsResource,
	IRoomWithAllRelationsResource,
} from "~shared/types/roomContingent";
import { nominationManagerGuestKeys } from "./nominationManagerGuestKeys";

export const nominationManagerGuestUrls = {
	index: keyToUrl(nominationManagerGuestKeys.index),
	options: keyToUrl(nominationManagerGuestKeys.options),
	details: (guestId: Id) => keyToUrl(nominationManagerGuestKeys.details(guestId)),
	hotelData: (guestId: Id) => keyToUrl(nominationManagerGuestKeys.hotelData(guestId)),
	rooms: (guestId: Id) => keyToUrl(nominationManagerGuestKeys.rooms(guestId)),
	attachRoomMateToRooms: (guestId: Id) => keyToUrl(nominationManagerGuestKeys.attachRoomMateToRooms(guestId)),
	attachRoomContingents: (guestId: Id) => keyToUrl(nominationManagerGuestKeys.attachRoomContingents(guestId)),
	detachRooms: (guestId: Id) => keyToUrl(nominationManagerGuestKeys.detachRooms(guestId)),
	activities: (guestId: Id) => keyToUrl(nominationManagerGuestKeys.activities(guestId)),
	filtered: keyToUrl(nominationManagerGuestKeys.filtered),
	recipients: keyToUrl(nominationManagerGuestKeys.recipients),
	recipientCount: keyToUrl(nominationManagerGuestKeys.recipientCount),
	bulkDelete: keyToUrl(nominationManagerGuestKeys.bulkDelete),
	bulkEdit: keyToUrl(nominationManagerGuestKeys.bulkEdit),
	remove: (guestId: Id) => keyToUrl(nominationManagerGuestKeys.remove(guestId)),
	register: (guestId: Id) => keyToUrl(nominationManagerGuestKeys.register(guestId)),
	emails: (guestId: Id) => keyToUrl(nominationManagerGuestKeys.emails(guestId)),
	sendEmail: (guestId: Id) => keyToUrl(nominationManagerGuestKeys.sendEmail(guestId)),
	generateExport: keyToUrl(nominationManagerGuestKeys.generateExport),
	showUsage: (guestId: Id) => keyToUrl(nominationManagerGuestKeys.showUsage(guestId)),
	validateEmail: keyToUrl(nominationManagerGuestKeys.validateEmail),
	bookingDetails: (guestId: Id | null) => keyToUrl(nominationManagerGuestKeys.bookingDetails(guestId)),
};

const options = (exclude: Id[], config?: AxiosRequestConfig) =>
	axios.get<LaravelResponse<Guest[]>>(nominationManagerGuestUrls.options, {
		...config,
		params: { ...config?.params, exclude },
	});

const details = (guestId: Id, guestViewId: Id) =>
	axios.get<LaravelResponse<GuestDetailsResource>>(nominationManagerGuestUrls.details(guestId), {
		params: { guestViewId },
	});

const rooms = (guestId: Id) => (config: ListFetcherConfig) =>
	axios.get<LaravelResponse<IRoomWithAllRelationsResource[]>>(
		nominationManagerGuestUrls.rooms(guestId),
		mapFetcherConfigToAxiosConfig(config),
	);

const attachRoomMateToRooms = (guestId: Id, request: AttachRoomMateToRoomsRequest) =>
	axios.put<LaravelResponse<IRoomContingentDetailsResource>>(
		nominationManagerGuestUrls.attachRoomMateToRooms(guestId),
		request,
	);
const attachRoomContingents = (guestId: Id, allocation: IRoomAllocationRequest[]) =>
	axios.put<LaravelResponse<IRoomContingentDetailsResource>>(
		nominationManagerGuestUrls.attachRoomContingents(guestId),
		{
			roomAllocation: allocation,
		},
	);
const detachRooms = (guestId: Id, request: IUpdateGuestRoomContingentRequest) =>
	axios.put<LaravelResponse<IRoomContingentDetailsResource>>(nominationManagerGuestUrls.detachRooms(guestId), request);

const hotelData = (guestId: Id) =>
	axios.get<LaravelResponse<IGuestHotelData>>(nominationManagerGuestUrls.hotelData(guestId));

const filtered = (guestViewId: Id, filters: IGuestFilterFormValueEntry[]) => (config: ListFetcherConfig) =>
	axios.post<LaravelResponse<GuestIndexResource[]>>(
		nominationManagerGuestUrls.filtered,
		{ guestViewId, filters, ...mapSortParamsToGuestListSortParams(config.sortParams) },
		mapFetcherConfigToAxiosConfig(config),
	);

const getRecipients = (
	filters: IActiveGuestFilterEntry[],
	guestIds: Id[],
	recipientType: RecipientType,
	config?: AxiosRequestConfig,
) =>
	axios.post<LaravelResponse<Recipient[]>>(
		nominationManagerGuestUrls.recipients,
		{ filters, guestIds, recipientType },
		config,
	);

const getRecipientCount = (filters: IActiveGuestFilterEntry[], guestIds: Id[], recipientType: RecipientType) =>
	axios.post<LaravelResponse<{ guestCount: number }>>(nominationManagerGuestUrls.recipientCount, {
		filters,
		guestIds,
		recipientType,
	});

const activities = (guestId: Id) => (config: ListFetcherConfig) =>
	axios.get<LaravelResponse<Activity[]>>(
		nominationManagerGuestUrls.activities(guestId),
		mapFetcherConfigToAxiosConfig(config),
	);

const put = (guestId: Id, guestViewId: Id, guest: GuestFormValues) =>
	axios.put<LaravelResponse<Guest>>(nominationManagerGuestUrls.details(guestId), {
		guestViewId,
		attributes: guest.attributes,
	});

const post = (guestViewId: Id, guest: GuestFormValues) =>
	axios.post<LaravelResponse<Guest>>(nominationManagerGuestUrls.index, { attributes: guest.attributes, guestViewId });

const bulkDelete = (guestIds: Id[], filters: IGuestFilterFormValueEntry[], search: string | null, allGuests = false) =>
	axios.post<LaravelResponse<GuestIndexResource[]>>(nominationManagerGuestUrls.bulkDelete, {
		guestIds,
		filters,
		search,
		allGuests,
	});

const bulkEdit = (
	guestIds: Id[],
	filters: IGuestFilterFormValueEntry[],
	search: string | null,
	change: GuestListBulkEditRequest,
	allGuests = false,
) =>
	axios.post<LaravelResponse<GuestIndexResource[]>>(nominationManagerGuestUrls.bulkEdit, {
		guestIds,
		filters,
		search,
		change,
		allGuests,
	});

const remove = (guestId: Id) => axios.delete<LaravelResponse<Guest>>(nominationManagerGuestUrls.details(guestId));
const register = (guestId: Id) => axios.put<LaravelResponse<Guest>>(nominationManagerGuestUrls.register(guestId));

const emails = (guestId: Id, config: ListFetcherConfig) =>
	axios.get<LaravelResponse<BaseEmailLog[]>>(
		nominationManagerGuestUrls.emails(guestId),
		mapFetcherConfigToAxiosConfig(config),
	);

const sendEmail = (guestId: Id, locale: string, customEmailId: Id) =>
	axios.post<LaravelResponse<BaseEmailLog>>(nominationManagerGuestUrls.sendEmail(guestId), { locale, customEmailId });

const generateExport = (
	search: string | null,
	sort: IDataListSortParams<any> | null,
	filters: IGuestFilterFormValueEntry[],
	guestViewId?: Id | null,
) =>
	axios.post<LaravelResponse<void>>(nominationManagerGuestUrls.generateExport, {
		search,
		sort: sort
			? {
					guestAttributeId: sort.column,
					direction: sort.direction,
			  }
			: null,
		filters,
		guestViewId,
	});

const showUsage = (id: Id) =>
	axios.get<LaravelResponse<ModelUsageResource[]>>(nominationManagerGuestUrls.showUsage(id));
const validateEmail = (email: string) =>
	axios.put<LaravelResponse<{ guest: GuestIndexResource | null }>>(nominationManagerGuestUrls.validateEmail, { email });

const bookingDetails = (guestId: Id | null) => {
	return axios.get<LaravelResponse<GuestBookingsResource>>(nominationManagerGuestUrls.bookingDetails(guestId));
};

export const nominationManagerGuestService = {
	options,
	details,
	hotelData,
	rooms,
	attachRoomMateToRooms,
	attachRoomContingents,
	detachRooms,
	activities,
	put,
	post,
	bulkDelete,
	bulkEdit,
	filtered,
	getRecipients,
	getRecipientCount,
	remove,
	register,
	emails,
	sendEmail,
	generateExport,
	showUsage,
	validateEmail,
	bookingDetails,
};
