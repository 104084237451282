import { Id, apiIsOK, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { ordersKeys, ordersService } from "~admin/shared/api/orders";
import { invoiceService } from "~admin/shared/api/shop/invoices/invoiceService";
import { OrderFormValue, OrderRefundFormState, OrderRefundRequest } from "~admin/shared/types/orders";

const mapOrderRefundFormStateToRequest = (orderRefund: OrderRefundFormState): OrderRefundRequest => {
	switch (orderRefund.type) {
		case "order":
			return { type: orderRefund.type };
		case "orderItems":
			return {
				type: orderRefund.type,
				orderItemIds: orderRefund.orderItems.map((x) => x.id),
			};
		case "percent":
		case "fixAmount":
			return {
				type: orderRefund.type,
				orderItemIds: orderRefund.orderItems.map((x) => x.id),
				amount: orderRefund.amount,
			};
	}
};

export const useOrderForm = () => {
	const { t } = useTranslation();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();

	const updateOrderStatus = useCallback(
		async (order: OrderFormValue, afterSuccess: () => void) => {
			const response = await ordersService.update({ id: order.id, orderStatus: order.orderStatus });

			if (apiIsOK(response)) {
				showToast({
					body: t("Order status was successfully saved."),
					title: t("Order"),
					type: "success",
				});
				afterSuccess && afterSuccess();
				await queryClient.invalidateQueries({ queryKey: ordersKeys.index });
			}
		},
		[showToast, t, queryClient],
	);

	const refundOrder = useCallback(
		async (orderId: Id, orderRefund: OrderRefundFormState, afterSuccess: () => void) => {
			const response = await ordersService.refund(orderId, mapOrderRefundFormStateToRequest(orderRefund));

			if (apiIsOK(response)) {
				showToast({
					body: t("Refund was successfully saved."),
					title: t("Order"),
					type: "success",
				});
				await queryClient.invalidateQueries({ queryKey: invoiceService.keys.index });
				await queryClient.invalidateQueries({ queryKey: ordersKeys.index });
				afterSuccess && afterSuccess();
			}
		},
		[queryClient, showToast, t],
	);

	return { updateOrderStatus, refundOrder };
};
