import { Icon, LinkButton } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { CompanionOptionResource } from "~shared/types/newGuestTypes";

interface Props {
	parent: CompanionOptionResource;
	parentType: string;
}

const Wrapper = styled.div`
	display: grid;
	grid-auto-flow: column;
`;
export const GoToParent: FC<Props> = ({ parent, parentType }) => {
	const { t } = useTranslation();

	const companionParentInfo =
		parent.firstName && parent.lastName ? `${parent.firstName} ${parent.lastName}` : parent.email;

	return (
		<Wrapper>
			<LinkButton
				title={t("Open parent")}
				type="button"
				size="small"
				prefix={<Icon icon="external" />}
				to={`../../${parent.id}`}
			>
				{`${parentType}: ${companionParentInfo}`}
			</LinkButton>
		</Wrapper>
	);
};
