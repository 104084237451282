import { ContextMenu, ContextMenuItem, Id, useOpen } from "@dgs/core";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { registrationFormService } from "~shared/api/registrationForms";
import { StructureDataField, StructureSection } from "~shared/types/registrationFormTypes";
import { DeleteModelWithUsagesDialog, useModelUsage } from "~shared/ui/modelUsage/DeleteModelWithUsagesDialog";
import { useRegistrationFormDataField } from "./useRegistrationFormDataField";
import { DataFieldValidationDrawer } from "./validation/DataFieldValidationDrawer";

interface Props {
	registrationFormId: string;
	registrationFormStepId: Id;
	section: StructureSection;
	registrationFormDataField: StructureDataField;
}

export const RegistrationFormDataFieldContextMenu: FC<Props> = ({
	registrationFormId,
	registrationFormStepId,
	section,
	registrationFormDataField,
}) => {
	const { t } = useTranslation();
	const { detachRegistrationFormDataField } = useRegistrationFormDataField(
		registrationFormId,
		registrationFormStepId,
		section.sectionId,
	);
	const { open, handleOpen, handleClose } = useOpen();

	const showUsageAction = useCallback(
		() =>
			registrationFormService.showDataFieldUsage(
				registrationFormId,
				registrationFormStepId,
				section.sectionId,
				registrationFormDataField.dataFieldId,
			),
		[registrationFormDataField.dataFieldId, registrationFormId, registrationFormStepId, section.sectionId],
	);
	const { handleUsage, ...usageModalProps } = useModelUsage(showUsageAction);

	return (
		<>
			<ContextMenu displayShortcutsAs="icon" size="small" flat={true}>
				<ContextMenuItem
					label={t("Validation")}
					title={t("Validation")}
					action={handleOpen}
					icon="check"
					shortcut={true}
				/>
				<ContextMenuItem action={handleUsage} title={t("Detach")} label={t("Detach")} icon="delete" />
			</ContextMenu>
			<DeleteModelWithUsagesDialog
				usagePrefix={t("This data field")}
				confirmationBody={t("Are you sure you want to detach this data field from this section?")}
				deleteAction={() => detachRegistrationFormDataField({ dataFieldIds: [registrationFormDataField.dataFieldId] })}
				{...usageModalProps}
			/>
			<DataFieldValidationDrawer
				registrationFormId={registrationFormId}
				open={open}
				handleClose={handleClose}
				stepId={registrationFormStepId}
				section={section}
				structureDataField={registrationFormDataField}
			/>
		</>
	);
};
