import React, { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ActionFormState } from "~shared/types/actions";
import { RuleFormState } from "~shared/types/rules";
import { Automation, RuleSet } from "~shared/types/ruleSet";
import { RuleSetForm } from "./RuleSetForm";
import { RuleSetFormActions } from "./RuleSetFormActions";
import { RuleSetFormRules } from "./RuleSetFormRules";
import { useRuleSet } from "./useRuleSet";

interface Props {
	registrationFormId: string;
	ruleSet: RuleSet<RuleFormState, ActionFormState>;
}

export const RuleSetCreatePage: FC<Props> = ({ registrationFormId, ruleSet }) => {
	const navigate = useNavigate();
	const { createRuleSet } = useRuleSet(registrationFormId);

	const onSubmit = useCallback(
		async (ruleSet: Automation<RuleFormState, ActionFormState>) => {
			const res = await createRuleSet(ruleSet);
			if (res) {
				navigate("..");
			}
		},
		[navigate, createRuleSet],
	);

	const prefilledRuleSet: RuleSet<RuleFormState, ActionFormState> = useMemo(
		() => ({
			...ruleSet,
			registrationFormId,
		}),
		[registrationFormId, ruleSet],
	);

	return (
		<RuleSetForm ruleSet={prefilledRuleSet} onSubmit={onSubmit}>
			<RuleSetFormRules registrationFormId={registrationFormId} ruleSetType={ruleSet.type} />
			<RuleSetFormActions registrationFormId={registrationFormId} ruleSetType={ruleSet.type} />
		</RuleSetForm>
	);
};
