import { PagedSingleSelectField } from "@dgs/core";
import React, { FC } from "react";
import { waveService } from "~shared/api/waves/waveService";

const fetcher = (search: string, page: number) => waveService.options(search, page).then((x) => x.data);

interface Props {
	name: string;
	label: string;
	required?: boolean;
	disabled?: boolean;
}
export const WaveSelectField: FC<Props> = (props) => {
	return <PagedSingleSelectField {...props} fetcher={fetcher} queryKey={waveService.keys.options} />;
};
