import { ContextMenu, ContextMenuDeleteItem, ContextMenuItem, ContextMenuLinkItem, Id } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useLocationForm } from "~admin/checkInApp/locations/useLocationForm";

interface Props {
	locationId: Id;
	handleEdit: () => void;
}

export const LocationsListContextMenu: FC<Props> = ({ locationId, handleEdit }) => {
	const { t } = useTranslation();
	const { deleteLocation } = useLocationForm();

	return (
		<>
			<ContextMenu displayShortcutsAs="icon">
				<ContextMenuItem
					label={t("Edit")}
					action={() => handleEdit()}
					title={t("Edit")}
					icon="edit"
					shortcut="primary"
				/>
				<ContextMenuLinkItem
					label={t("Check-in/out logs")}
					title={t("Check-in/out logs")}
					to={`${locationId}/logs`}
					icon="eye"
				/>
				<ContextMenuDeleteItem
					action={() => deleteLocation(locationId)}
					label={t("Delete location")}
					title={t("Delete location")}
					heading={t("Delete")}
					labels={{ close: t("Close"), confirm: t("Confirm") }}
				>
					{t("Are you sure you want to delete this location?")}
				</ContextMenuDeleteItem>
			</ContextMenu>
		</>
	);
};
