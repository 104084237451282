import { BlockDetailsPage } from "@dgs/core";
import React, { FC } from "react";
import { CreateEmailStepper } from "./CreateEmailStepper";

export const CreateEmailContent: FC = () => {
	return (
		<>
			<CreateEmailStepper currentStep="Email content" />
			<BlockDetailsPage />
		</>
	);
};
