import { Icon } from "@dgs/core";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

export const DragIcon = styled(Icon).attrs({ icon: "drag_indicator" })`
	width: 20px;
	height: 20px;
	cursor: grab;
`;

export const DragIndicator: FC = (props) => {
	const { t } = useTranslation();

	return <DragIcon title={t("Drag handle")} {...props} />;
};
