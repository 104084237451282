import { Tag } from "@dgs/core";
import { format } from "date-fns";
import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { IContingentOverviewIndex } from "~admin/shared/types/contingentOverview";

interface Props {
	date: Date;
	overview: IContingentOverviewIndex;
}

const getTagColor = (used: number, total: number): "success" | "warning" | "danger" => {
	if (used > total) {
		return "danger";
	} else if (used === total) {
		return "warning";
	}
	return "success";
};

const StyledTag = styled(Tag)`
	min-width: 75px;
`;
export const ContingentTag: FC<Props> = ({ date, overview }) => {
	const navigate = useNavigate();

	if (!overview.contingents[format(date, "yyyy-MM-dd")]) {
		return <>-</>;
	}

	return (
		<StyledTag
			onClick={() =>
				navigate(
					`/admin/hotel-management/hotels/${overview.hotel.id}/room-types/${overview.id}/contingent/${
						overview.contingents[format(date, "yyyy-MM-dd")].id
					}`,
				)
			}
			color={getTagColor(
				overview.contingents[format(date, "yyyy-MM-dd")].used,
				overview.contingents[format(date, "yyyy-MM-dd")].total,
			)}
		>
			{`${overview.contingents[format(date, "yyyy-MM-dd")].used}/${
				overview.contingents[format(date, "yyyy-MM-dd")].total
			}`}
		</StyledTag>
	);
};
