import { CrudDrawer, IconButton, Id, Trigger, apiIsOK, useBulkEdit, useList, useOpen, useToasts } from "@dgs/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { FC, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GuestListBulkEditFormFields } from "~admin/guests/GuestListBulkEditFormFields";
import { dashboardKeys } from "~shared/api/dashboard";
import { dataFieldService } from "~shared/api/dataFields";
import { emailKeys } from "~shared/api/emails";
import { guestKeys, guestService } from "~shared/api/guests";
import { companionService } from "~shared/api/guests/companionService";
import { hotelInquiryService } from "~shared/api/hotelInquiries";
import { IGuestFilterFormValueEntry } from "~shared/guestFilters/guestFilterTypes";
import { ExportPersonalizedDocumentsDrawer } from "~shared/guests/ExportPersonalizedDocumentsDrawer";
import { GuestListBulkEditRequest, GuestListBulkEditValues } from "~shared/types/guest";

import { mapFormValueToRequest } from "~shared/utils/mapFormValueToRequest";

export interface Props {
	filters: IGuestFilterFormValueEntry[];
	companionParentId?: Id;
}

const initialValues: GuestListBulkEditValues = { operator: null, guestAttribute: null, value: null };

export const GuestListBulkEditAction: FC<Props> = ({ filters, companionParentId }) => {
	const { t } = useTranslation();
	const { open, handleClose, handleOpen } = useOpen();
	const { selectedRows, setSelectedRow, setBulkEditing, selectedRowsCount } = useBulkEdit();
	const { showToast } = useToasts();
	const queryClient = useQueryClient();
	const { searchParams } = useList();

	const validate = (values: GuestListBulkEditValues) => {
		const errors: {
			value?: string;
			operator?: string;
		} = {};

		if (values.guestAttribute && !values.operator) {
			errors["operator"] = t("Field is required");
		}

		if (values.guestAttribute && values.operator && values.operator !== "DELETE" && !values.value) {
			errors["value"] = t("Field is required");
		}

		return errors;
	};

	const handleEdit = useCallback(
		async (values: GuestListBulkEditValues) => {
			const editAll = selectedRows === "all";
			const request: GuestListBulkEditRequest = {
				guestAttributeId: values.guestAttribute?.id || "",
				value: mapFormValueToRequest(values.value),
				operator: values.operator,
			};

			let response;
			if (companionParentId) {
				response = await companionService.companionBulkEdit(
					editAll ? [] : selectedRows,
					filters,
					searchParams?.search ?? null,
					request,
					companionParentId,
					editAll,
				);
			} else {
				response = await guestService.bulkEdit(
					editAll ? [] : selectedRows,
					filters,
					searchParams?.search ?? null,
					request,
					editAll,
				);
			}
			if (apiIsOK(response)) {
				showToast({
					body: t("{{count}} guest was updated", { count: selectedRowsCount }),
					title: t("Guests"),
					type: "success",
				});
				void queryClient.invalidateQueries({ queryKey: guestKeys.index });
				void queryClient.invalidateQueries({ queryKey: dashboardKeys.index });
				void queryClient.invalidateQueries({ queryKey: emailKeys.sendOuts.index });
				void queryClient.invalidateQueries({ queryKey: hotelInquiryService.keys.index });
				void queryClient.invalidateQueries({ queryKey: dataFieldService.keys.contingentOverview });
				setSelectedRow("none");
				setBulkEditing(false);

				handleClose();
				return true;
			}
			return false;
		},
		[
			selectedRows,
			companionParentId,
			filters,
			searchParams?.search,
			showToast,
			t,
			selectedRowsCount,
			queryClient,
			setSelectedRow,
			setBulkEditing,
			handleClose,
		],
	);

	const title = t("Edit {{count}} guests", { count: selectedRowsCount });

	return (
		<>
			<IconButton title={title} size="small" onClick={handleOpen} disabled={selectedRows.length === 0} icon="edit" />
			<Trigger
				render={(props) => (
					<ExportPersonalizedDocumentsDrawer guestIds={selectedRows === "all" ? [] : selectedRows} {...props} />
				)}
			>
				{(triggerProps) => (
					<IconButton
						{...triggerProps}
						title={t("Export personalized documents")}
						size="small"
						disabled={selectedRows.length === 0}
						icon="file_download"
					/>
				)}
			</Trigger>
			<CrudDrawer
				open={open}
				onSubmit={handleEdit}
				body={<GuestListBulkEditFormFields />}
				initialValues={initialValues}
				onClose={handleClose}
				heading={title}
				validate={validate}
			/>
		</>
	);
};
