import {
	Drawer,
	DrawerBody,
	DrawerFooter,
	DrawerHeader,
	DrawerHeading,
	SingleSelectField,
	SubmitButton,
} from "@dgs/core";
import { Form, Formik } from "formik";
import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { OrderFormValue, OrderStatus } from "~admin/shared/types/orders";
import { useOrderForm } from "~admin/shop/orders/useOrderForm";

interface Props {
	order: OrderFormValue | null;
	onClose: () => void;
}

const orderStatusOptions: OrderStatus[] = Object.values(OrderStatus);

export const OrderDrawer: FC<Props> = ({ order, onClose }) => {
	const { t } = useTranslation();
	const enabled = order?.paymentProvider.type === "Invoice";
	const { updateOrderStatus } = useOrderForm();

	const validate = (values: OrderFormValue) => {
		const errors: { [K in keyof OrderFormValue]?: any } = {};
		if (!values.orderStatus) {
			errors.orderStatus = t("Required field");
		}
		return errors;
	};

	return (
		<Drawer onClose={onClose} open={!!order}>
			{order && (
				<>
					<DrawerHeader onClose={onClose}>
						<DrawerHeading>{t("Edit order status")}</DrawerHeading>
					</DrawerHeader>
					<Formik
						initialValues={order}
						onSubmit={(updatedOrder: OrderFormValue) => updateOrderStatus(updatedOrder, onClose)}
						validate={validate}
					>
						<Form>
							<DrawerBody>
								<SingleSelectField
									options={orderStatusOptions}
									getLabel={(option) => t(option)}
									getValue={(option) => option}
									name={"orderStatus"}
									label={t("Order status")}
									required={true}
									disabled={!enabled}
									className={"orderStatus"}
									clearable={false}
								/>
								{!enabled && t("The order status only can be edited for invoices.")}
							</DrawerBody>
							<DrawerFooter>
								<SubmitButton disabled={!enabled}>{t("Save")}</SubmitButton>
							</DrawerFooter>
						</Form>
					</Formik>
				</>
			)}
		</Drawer>
	);
};
