import { FCC, getFrontendTheme, useBrandTheme } from "@dgs/core";
import React, { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import { config } from "~shared/config";
import { useNominationManagerConfig } from "./useNominationManagerConfig";

export const NominationManagerThemeProvider: FCC = ({ children }) => {
	const { brand } = useNominationManagerConfig();
	const theme = getFrontendTheme(config.mode);
	const brandTheme = useBrandTheme(config.frontendThemeName, theme, brand);

	useEffect(() => {
		const faviconElement = document.getElementById("favicon") as HTMLLinkElement;

		if (brand && brand?.faviconFile) {
			faviconElement.href = brand?.faviconFile.path;
		}
	}, [brand]);

	return <ThemeProvider theme={brandTheme}>{children}</ThemeProvider>;
};
